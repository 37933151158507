import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_List_Interview_User, get_Job_Action_Id } from '../../../../Store/Actions/Job.Action'
import { get_Account_Action_Id } from '../../../../Store/Actions/Account.Action'
import { get_Jobdesk_Action_Id } from '../../../../Store/Actions/Jobdesk.Action'

import { Collapse } from '@mui/material'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import GenericModals from '../../../Containers/GenericModals'
import CoTable from '../../../Containers/Table'
import DetailPenilianKandidat from './Modal/Interview/DetailPenilianKandidat'
import FinalPenilianKandidat from './Modal/Interview/FinalPenilianKandidat'
import ReviewPenilianKandidatUser from './Modal/Interview/ReviewPenilianKandidatUser'

import { isLevelQualified } from '../../../../Global'

class InterviewUser extends React.Component {
    state = {
        MenungguOpen: true,
        AssessedOpen: false,
        MenungguKonfirmOpen: true,
        SelesaiOpen: false,
    }

    componentDidMount() {
        this.props.get_List_Interview_User()
    }

    handleAssessedOpen = () => {
        this.setState({ AssessedOpen: !this.state.AssessedOpen })
    }

    handleMenungguOpen = () => {
        this.setState({ MenungguOpen: !this.state.MenungguOpen })
    }

    handleMenungguKonfirmOpen = () => {
        this.setState({ MenungguKonfirmOpen: !this.state.MenungguKonfirmOpen })
    }

    handleSelesaiOpen = () => {
        this.setState({ SelesaiOpen: !this.state.SelesaiOpen })
    }

    onClickActionId = (JobId, AccountId, JobdeskId) => {
        this.props.get_Job_Action_Id(JobId)
        this.props.get_Account_Action_Id(AccountId)
        this.props.get_Jobdesk_Action_Id(JobdeskId)
    }

    render() {
        // const JobActionId = this.props.JobActionId
        const isListInterviewUserLoading = this.props.isListInterviewUserLoading
        const ListInterviewUser = this.props.ListInterviewUser
        const User = this.props.User
        const isAdmin = User?.isAdmin ? User.isAdmin : false
        const Department = User?.Department ? User.Department : []
        const Level = User?.Level ? User.Level : ''

        const UserRole = User?.Role ? User.Role : []
        const ApplicationApproval = '86210'
        const isApplicationApproval = UserRole.includes(ApplicationApproval)

        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Registration Number',
                width: '10%',
            },
            {
                Nama: 'Candidate Name',
                width: '10%',
            },
            {
                Nama: 'Position',
                width: '10%',
            },
            {
                Nama: 'Submission Details',
                width: '10%',
            },
        ]

        const FilterdtInterviewUser = ListInterviewUser ? ListInterviewUser.filter((item, index) => (
            (item.UsICount === null || item.UsICount === 0)
            && (isAdmin || Department.find((newItem) => newItem.DepartemenId === item.DepartmenIdPemohon))
            && (isAdmin || isLevelQualified(Level, item?.LevelId ? item.LevelId : ''))
        )) : []

        const BodyData = FilterdtInterviewUser.map((item, index) => {
            return {
                index: index + 1,
                Nomor: item.Id,
                Nama: item.BiodataNama,
                Posisi: item.JobListNama,
                Detail: (
                    <GenericModals
                        size='xl'
                        header='Candidate Assessment Details'
                        body={<DetailPenilianKandidat />}
                        Buttonvariant='contained'
                        Buttoncolor='primary'
                        ButtononClickeven={() => this.onClickActionId(item.JobListId, item.AccountId, item.JobdeskId)}
                        Buttonlabel={'submit'}
                    />
                )
            }
        })

        const FilterdtInterviewUser2 = ListInterviewUser ? ListInterviewUser.filter((item, index) => (
            (item.UsICount !== null && item.UsICount > 0)
            && item.UsIFId === null && (isAdmin || Department.find((newItem) => newItem.DepartemenId === item.DepartmenIdPemohon))
            && (isAdmin || isLevelQualified(Level, item?.LevelId ? item.LevelId : ''))
        )) : []

        const BodyData2 = FilterdtInterviewUser2.map((item, index) => {
            return {
                index: index + 1,
                Nomor: item.Id,
                Nama: item.BiodataNama,
                Posisi: item.JobListNama,
                Detail: (
                    <GenericModals
                        size='xl'
                        header='Finalisasi Penilian Kandidat'
                        body={<FinalPenilianKandidat />}
                        Buttonvariant='contained'
                        Buttoncolor='primary'
                        ButtononClickeven={() => this.onClickActionId(item.JobListId, item.AccountId, item.JobdeskId)}
                        Buttonlabel={'Final'}
                    />
                )
            }
        })

        const BodyData25 = FilterdtInterviewUser2.map((item, index) => {
            return {
                index: index + 1,
                Nomor: item.Id,
                Nama: item.BiodataNama,
                Posisi: item.JobListNama,
                Detail: (
                    <GenericModals
                        size='xl'
                        header='Candidate Assessment Details'
                        body={<DetailPenilianKandidat />}
                        Buttonvariant='contained'
                        Buttoncolor='primary'
                        ButtononClickeven={() => this.onClickActionId(item.JobListId, item.AccountId, item.JobdeskId)}
                        Buttonlabel={'Add'}
                    />
                )
            }
        })

        const HeadData3 = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Registration Number',
                width: '10%',
            },
            {
                Nama: 'Candidate Name',
                width: '10%',
            },
            {
                Nama: 'Position',
                width: '10%',
            },
            {
                Nama: 'Status',
                width: '10%',
            },
            {
                Nama: 'Submission Details',
                width: '10%',
            },
        ]

        const FilterdtInterviewUser3 = ListInterviewUser ? ListInterviewUser.filter((item, index) => (
            item.UsIFId !== null
            && (isAdmin || Department.find((newItem) => newItem.DepartemenId === item.DepartmenIdPemohon))
            && (isAdmin || isLevelQualified(Level, item?.LevelId ? item.LevelId : ''))
        )) : []

        const BodyData3 = FilterdtInterviewUser3.map((item, index) => {
            return {
                index: index + 1,
                Nomor: item.Id,
                Nama: item.BiodataNama,
                Posisi: item.JobListNama,
                Status: (
                    <span
                        style={{ color: item?.UsIFStatus ? item.UsIFStatus === 'Accept' ? 'green' : item.UsIFStatus === 'Reject' ? 'red' : 'orange' : 'black' }}
                    >
                        {item.UsIFStatus}
                    </span>
                ),
                Detail: (
                    <GenericModals
                        size='xl'
                        header='Detail Penilian Kandidat'
                        body={<ReviewPenilianKandidatUser />}
                        Buttoncolor='primary'
                        ButtononClickeven={() => this.onClickActionId(item.JobListId, item.AccountId, item.JobdeskId)}
                        Buttonlabel={'Detail'}
                    />
                )
            }
        })

        const {
            MenungguOpen,
            AssessedOpen,
            MenungguKonfirmOpen,
            SelesaiOpen,
        } = this.state

        const RowDataInOnePage = 5

        return (
            <Fragment>
                <h2>
                    User Interview List
                </h2>
                <h3
                    onClick={this.handleMenungguOpen}
                    style={{ display: 'flex', alignContent: 'center', verticalAlign: 'center', cursor: 'pointer' }}
                >
                    Waiting for Assessment{MenungguOpen ? <ExpandLess /> : <ExpandMore />}
                </h3>
                <Collapse in={MenungguOpen} timeout="auto" unmountOnExit>
                    <CoTable
                        TableId={'InterviewUser_Menunggu'}
                        HeadData={HeadData}
                        Data={BodyData}
                        isHeadLoading={isListInterviewUserLoading}
                        isBodyLoading={isListInterviewUserLoading}
                        isPagination={BodyData.length > RowDataInOnePage ? true : false}
                        MaxRowOnPage={RowDataInOnePage}
                        isPaginationLoading={isListInterviewUserLoading}
                        PaginationCounter={
                            BodyData.length / RowDataInOnePage > Math.floor(BodyData.length / RowDataInOnePage) ?
                                Math.floor(BodyData.length / RowDataInOnePage) + 1 :
                                BodyData.length / RowDataInOnePage
                        }
                    />
                </Collapse>
                <h3
                    onClick={this.handleAssessedOpen}
                    style={{ display: 'flex', alignContent: 'center', verticalAlign: 'center', cursor: 'pointer' }}
                >
                    Assessed{AssessedOpen ? <ExpandLess /> : <ExpandMore />}
                </h3>
                <Collapse in={AssessedOpen} timeout="auto" unmountOnExit>
                    <CoTable
                        TableId={'InterviewUser_Menunggu'}
                        HeadData={HeadData}
                        Data={BodyData25}
                        isHeadLoading={isListInterviewUserLoading}
                        isBodyLoading={isListInterviewUserLoading}
                        isPagination={BodyData25.length > RowDataInOnePage ? true : false}
                        MaxRowOnPage={RowDataInOnePage}
                        isPaginationLoading={isListInterviewUserLoading}
                        PaginationCounter={
                            BodyData25.length / RowDataInOnePage > Math.floor(BodyData25.length / RowDataInOnePage) ?
                                Math.floor(BodyData25.length / RowDataInOnePage) + 1 :
                                BodyData25.length / RowDataInOnePage
                        }
                    />
                </Collapse>
                {isAdmin || isApplicationApproval ?
                    <Fragment>
                        <h3
                            onClick={this.handleMenungguKonfirmOpen}
                            style={{ display: 'flex', alignContent: 'center', verticalAlign: 'center', cursor: 'pointer' }}
                        >
                            Waiting for Confirmation{MenungguKonfirmOpen ? <ExpandLess /> : <ExpandMore />}
                        </h3>
                        <Collapse in={MenungguKonfirmOpen} timeout="auto" unmountOnExit>
                            <CoTable
                                TableId={'InterviewUser_Menunggu_Konfirmasi'}
                                HeadData={HeadData}
                                Data={BodyData2}
                                isHeadLoading={isListInterviewUserLoading}
                                isBodyLoading={isListInterviewUserLoading}
                                isPagination={BodyData2.length > RowDataInOnePage ? true : false}
                                MaxRowOnPage={RowDataInOnePage}
                                isPaginationLoading={isListInterviewUserLoading}
                                PaginationCounter={
                                    BodyData2.length / RowDataInOnePage > Math.floor(BodyData2.length / RowDataInOnePage) ?
                                        Math.floor(BodyData2.length / RowDataInOnePage) + 1 :
                                        BodyData2.length / RowDataInOnePage
                                }
                            />
                        </Collapse>
                    </Fragment> :
                    <Fragment></Fragment>
                }
                <div
                    style={{ margin: '0 0 5% 0' }}
                >
                    <h3
                        onClick={this.handleSelesaiOpen}
                        style={{ display: 'flex', alignContent: 'center', verticalAlign: 'center', cursor: 'pointer' }}
                    >
                        Done{SelesaiOpen ? <ExpandLess /> : <ExpandMore />}
                    </h3>
                    <Collapse in={SelesaiOpen} timeout="auto" unmountOnExit>
                        <CoTable
                            TableId={'InterviewUser_Selesai'}
                            HeadData={HeadData3}
                            Data={BodyData3}
                            isHeadLoading={isListInterviewUserLoading}
                            isBodyLoading={isListInterviewUserLoading}
                            isPagination={BodyData3.length > RowDataInOnePage ? true : false}
                            MaxRowOnPage={RowDataInOnePage}
                            isPaginationLoading={isListInterviewUserLoading}
                            PaginationCounter={
                                BodyData3.length / RowDataInOnePage > Math.floor(BodyData3.length / RowDataInOnePage) ?
                                    Math.floor(BodyData3.length / RowDataInOnePage) + 1 :
                                    BodyData3.length / RowDataInOnePage
                            }
                        />
                    </Collapse>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    User: state.Auth.User,
    isListInterviewUserLoading: state.Job.isListInterviewUserLoading,
    ListInterviewUser: state.Job.ListInterviewUser,
})

export default connect(mapStateToProps, { get_List_Interview_User, get_Job_Action_Id, get_Account_Action_Id, get_Jobdesk_Action_Id })(InterviewUser)
