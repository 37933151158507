import axios from "axios";

import {
  AUTH_RELOADE_PAGE,
  //* TIMELINE
  TIMELINE_LOADING,
  TIMELINE_LOADED,
  TIMELINE_LOADING_DONE,
  TIMELINE_DETAIL_LOADING,
  TIMELINE_DETAIL_LOADED,
  TIMELINE_DETAIL_LOADING_DONE,
  //*
  JOB_LIST_LOADING,
  JOB_LIST_LOADED,
  JOB_LIST_LOADING_DONE,
  JOB_FULL_LIST_LOADING,
  JOB_FULL_LIST_LOADED,
  JOB_FULL_LIST_LOADING_DONE,
  JOB_ACTION_GET_ID,
  JOB_ACTION_GET_DATA_LOADING,
  JOB_ACTION_GET_DATA_LOADED,
  JOB_ACTION_GET_DATA_LOADING_DONE,
  JOB_IS_JOB_SAVE_LOADING,
  JOB_IS_JOB_SAVE_LOADED,
  JOB_IS_JOB_SAVE_LOADING_DONE,
  JOB_IS_JOB_APPLY_LOADING,
  JOB_IS_JOB_APPLY_LOADED,
  JOB_IS_JOB_APPLY_LOADING_DONE,
  JOB_LIST_PENDAFTAR_LOADING,
  JOB_LIST_PENDAFTAR_LOADED,
  JOB_LIST_PENDAFTAR_LOADING_DONE,
  JOB_LIST_PENDAFTAR_DETAIL_LOADING,
  JOB_LIST_PENDAFTAR_DETAIL_LOADED,
  JOB_LIST_PENDAFTAR_DETAIL_LOADING_DONE,
  JOB_LIST_PENDAFTAR_DIAJUKAN_LOADING,
  JOB_LIST_PENDAFTAR_DIAJUKAN_LOADED,
  JOB_LIST_PENDAFTAR_DIAJUKAN_LOADING_DONE,
  JOB_LIST_PENDAFTAR_SUCCESS,
  JOB_LIST_REKOMENDASI_LOADING,
  JOB_LIST_REKOMENDASI_LOADED,
  JOB_LIST_REKOMENDASI_LOADING_DONE,
  //* PERSONALITYTEST
  JOB_LIST_PERSONALITYTEST_LOADING,
  JOB_LIST_PERSONALITYTEST_LOADED,
  JOB_LIST_PERSONALITYTEST_LOADING_DONE,
  JOB_LIST_PERSONALITYTEST_DETAIL_LOADING,
  JOB_LIST_PERSONALITYTEST_DETAIL_LOADED,
  JOB_LIST_PERSONALITYTEST_DETAIL_LOADING_DONE,
  //* Interview
  JOB_LIST_INTERVIEW_JADWAL_LOADING,
  JOB_LIST_INTERVIEW_JADWAL_LOADED,
  JOB_LIST_INTERVIEW_JADWAL_LOADING_DONE,
  JOB_LIST_INTERVIEW_JADWAL_DETAIL_LOADING,
  JOB_LIST_INTERVIEW_JADWAL_DETAIL_LOADED,
  JOB_LIST_INTERVIEW_JADWAL_DETAIL_LOADING_DONE,
  JOB_LIST_INTERVIEW_USER_LOADING,
  JOB_LIST_INTERVIEW_USER_LOADED,
  JOB_LIST_INTERVIEW_USER_LOADING_DONE,
  JOB_LIST_INTERVIEW_USER_DETAIL_LOADING,
  JOB_LIST_INTERVIEW_USER_DETAIL_LOADED,
  JOB_LIST_INTERVIEW_USER_DETAIL_LOADING_DONE,
  JOB_LIST_INTERVIEW_HR_LOADING,
  JOB_LIST_INTERVIEW_HR_LOADED,
  JOB_LIST_INTERVIEW_HR_LOADING_DONE,
  JOB_LIST_INTERVIEW_HR_DETAIL_LOADING,
  JOB_LIST_INTERVIEW_HR_DETAIL_LOADED,
  JOB_LIST_INTERVIEW_HR_DETAIL_LOADING_DONE,
  //* OFFERING
  JOB_LIST_OFFERING_LOADING,
  JOB_LIST_OFFERING_LOADED,
  JOB_LIST_OFFERING_LOADING_DONE,
  JOB_LIST_OFFERING_DETAIL_LOADING,
  JOB_LIST_OFFERING_DETAIL_LOADED,
  JOB_LIST_OFFERING_DETAIL_LOADING_DONE,
  //* MCU
  JOB_LIST_MCU_LOADING,
  JOB_LIST_MCU_LOADED,
  JOB_LIST_MCU_LOADING_DONE,
  JOB_LIST_MCU_DETAIL_LOADING,
  JOB_LIST_MCU_DETAIL_LOADED,
  JOB_LIST_MCU_DETAIL_LOADING_DONE,
  //* EQUIPMENT
  JOB_LIST_EQUIPMENT_LOADING,
  JOB_LIST_EQUIPMENT_LOADED,
  JOB_LIST_EQUIPMENT_LOADING_DONE,
  JOB_LIST_EQUIPMENT_DETAIL_LOADING,
  JOB_LIST_EQUIPMENT_DETAIL_LOADED,
  JOB_LIST_EQUIPMENT_DETAIL_LOADING_DONE,
  //* PREBOARDING
  JOB_LIST_PREBOARDING_LOADING,
  JOB_LIST_PREBOARDING_LOADED,
  JOB_LIST_PREBOARDING_LOADING_DONE,
  JOB_LIST_PREBOARDING_DETAIL_LOADING,
  JOB_LIST_PREBOARDING_DETAIL_LOADED,
  JOB_LIST_PREBOARDING_DETAIL_LOADING_DONE,
  //* PKWT
  JOB_LIST_PKWT_LOADING,
  JOB_LIST_PKWT_LOADED,
  JOB_LIST_PKWT_LOADING_DONE,
  JOB_LIST_PKWT_DETAIL_LOADING,
  JOB_LIST_PKWT_DETAIL_LOADED,
  JOB_LIST_PKWT_DETAIL_LOADING_DONE,
  //* BPJS
  JOB_LIST_BPJS_LOADING,
  JOB_LIST_BPJS_LOADED,
  JOB_LIST_BPJS_LOADING_DONE,
  JOB_LIST_BPJS_DETAIL_LOADING,
  JOB_LIST_BPJS_DETAIL_LOADED,
  JOB_LIST_BPJS_DETAIL_LOADING_DONE,
  //* INDUSTRY
  JOB_LIST_INDUSTRY_LOADING,
  JOB_LIST_INDUSTRY_LOADED,
  JOB_LIST_INDUSTRY_LOADING_DONE,
  JOB_LIST_INDUSTRY_DETAIL_LOADING,
  JOB_LIST_INDUSTRY_DETAIL_LOADED,
  JOB_LIST_INDUSTRY_DETAIL_LOADING_DONE,
  //* GREETDAY
  JOB_LIST_GREETDAY_LOADING,
  JOB_LIST_GREETDAY_LOADED,
  JOB_LIST_GREETDAY_LOADING_DONE,
  JOB_LIST_GREETDAY_DETAIL_LOADING,
  JOB_LIST_GREETDAY_DETAIL_LOADED,
  JOB_LIST_GREETDAY_DETAIL_LOADING_DONE,
  //* KONTRAK
  JOB_LIST_KONTRAK_LOADING,
  JOB_LIST_KONTRAK_LOADED,
  JOB_LIST_KONTRAK_LOADING_DONE,
  JOB_LIST_KONTRAK_DETAIL_LOADING,
  JOB_LIST_KONTRAK_DETAIL_LOADED,
  JOB_LIST_KONTRAK_DETAIL_LOADING_DONE,
  //* ONBOARDING
  JOB_LIST_ONBOARDING_LOADING,
  JOB_LIST_ONBOARDING_LOADED,
  JOB_LIST_ONBOARDING_LOADING_DONE,
  JOB_LIST_ONBOARDING_DETAIL_LOADING,
  JOB_LIST_ONBOARDING_DETAIL_LOADED,
  JOB_LIST_ONBOARDING_DETAIL_LOADING_DONE,
  //* APPLICATIONS
  JOB_LIST_APPLICATIONS_APPLY_LOADING,
  JOB_LIST_APPLICATIONS_APPLY_LOADED,
  JOB_LIST_APPLICATIONS_APPLY_LOADING_DONE,
  JOB_LIST_APPLICATIONS_SAVE_LOADING,
  JOB_LIST_APPLICATIONS_SAVE_LOADED,
  JOB_LIST_APPLICATIONS_SAVE_LOADING_DONE,
  //* PREREGISTER
  JOB_PREREGISTER_LOADING,
  JOB_PREREGISTER_LOADED,
  JOB_PREREGISTER_LOADING_DONE,
  //* MOVE
  JOB_MOVE_LIST_LOADING,
  JOB_MOVE_LIST_LOADED,
  JOB_MOVE_LIST_LOADING_DONE,
  JOB_MOVE_DETAIL_LOADING,
  JOB_MOVE_DETAIL_LOADED,
  JOB_MOVE_DETAIL_LOADING_DONE,
} from "../Actions/Type.Action";

import {
  // Create_Error_Messages,
  Create_Success_Messages,
} from "./Messages.Actions";

import { Get_IpAddres, tokenConfig, ErrorHandler } from "./Base.Action";

import { Error_Messages_Standardization } from "../../Global";

//* TIMELINE
export const get_List_TimeLine = () => async (dispatch, getState) => {
  try {
    dispatch({ type: TIMELINE_LOADING });

    const IpAddres = Get_IpAddres();
    const Data = await axios.get(
      `${IpAddres}/timeline/`,
      tokenConfig(getState)
    );

    if (Data.status === 200) {
      dispatch({
        type: TIMELINE_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: TIMELINE_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: TIMELINE_LOADING_DONE });
    ErrorHandler(err, "get_List_TimeLine");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else if (err.response.data.msg) {
    //         dispatch(Create_Error_Messages(400, err.response.data.msg))
    //         // if (err.response.data.msg === 'Data Sudah Ada') {
    //         if (false) {
    //             // dispatch({
    //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
    //             //     payload: true
    //             // })
    //         }
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const get_TimeLine_Detail = (Id) => async (dispatch, getState) => {
  try {
    dispatch({ type: TIMELINE_DETAIL_LOADING });

    const IpAddres = Get_IpAddres();
    const Data = await axios.get(
      `${IpAddres}/timeline/${Id}/detail`,
      tokenConfig(getState)
    );

    if (Data.status === 200) {
      dispatch({
        type: TIMELINE_DETAIL_LOADED,
        payload: Data.data,
      });
      dispatch({ type: TIMELINE_DETAIL_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: TIMELINE_DETAIL_LOADING_DONE });
    ErrorHandler(err, "get_TimeLine_Detail");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else if (err.response.data.msg) {
    //         dispatch(Create_Error_Messages(400, err.response.data.msg))
    //         // if (err.response.data.msg === 'Data Sudah Ada') {
    //         if (false) {
    //             // dispatch({
    //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
    //             //     payload: true
    //             // })
    //         }
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
    //     }
    // } catch (err2) {
    // }
  }
};

//*
export const Get_Job_List = () => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_LIST_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.get(`${IpAddres}/job/list`, tokenConfig(getState));
    if (Data.status === 200) {
      dispatch({
        type: JOB_LIST_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: JOB_LIST_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: JOB_LIST_LOADING_DONE });
    ErrorHandler(err, "get_TimeLine_Detail");
  }
};

export const get_Job_List_Full = (param) => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_FULL_LIST_LOADING });
    const IpAddres = Get_IpAddres();
    const body = JSON.stringify({ param });

    const Data = await axios.post(
      `${IpAddres}/job/fullist`,
      body,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch({
        type: JOB_FULL_LIST_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: JOB_FULL_LIST_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: JOB_FULL_LIST_LOADING_DONE });
    ErrorHandler(err, "get_Job_List_Full");
  }
};

export const get_Job_Action_Id = (Id) => (dispatch) => {
  dispatch({
    type: JOB_ACTION_GET_ID,
    payload: Id,
  });
};

export const get_Job_Data = (Id) => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_ACTION_GET_DATA_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.get(
      `${IpAddres}/job/detail/${Id}`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch({
        type: JOB_ACTION_GET_DATA_LOADED,
        payload: Data.data,
      });
      dispatch({ type: JOB_ACTION_GET_DATA_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: JOB_ACTION_GET_DATA_LOADING_DONE });
    ErrorHandler(err, "get_Job_Data");
  }
};

export const get_Job_Data_URL = (Id) => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_ACTION_GET_DATA_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.get(
      `${IpAddres}/job/url/detail/${Id}`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch({
        type: JOB_ACTION_GET_DATA_LOADED,
        payload: Data.data,
      });
      dispatch({ type: JOB_ACTION_GET_DATA_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: JOB_ACTION_GET_DATA_LOADING_DONE });
    ErrorHandler(err, "get_Job_Data_URL");
  }
};

export const update_Job_Data =
  (
    Id,
    JobId,
    Nama,
    DepartmenId,
    LevelId,
    LokasiId,
    About,
    Status,
    OpenDate,
    DeskripsiList,
    PersyaratanList
  ) =>
  async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({
        JobId,
        Nama,
        DepartmenId,
        LevelId,
        LokasiId,
        About,
        Status,
        OpenDate,
        DeskripsiList,
        PersyaratanList,
      });
      const Data = await axios.post(
        `${IpAddres}/job/update/${Id}`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `Job Berhasil diupdate`));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "update_Job_Data");
    }
  };

export const is_Job_Applied = (JobListId) => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_IS_JOB_APPLY_LOADING });

    const IpAddres = Get_IpAddres();
    const body = JSON.stringify({ JobListId });
    const Data = await axios.post(
      `${IpAddres}/job/is/apply`,
      body,
      tokenConfig(getState)
    );

    if (Data.status === 200) {
      dispatch({
        type: JOB_IS_JOB_APPLY_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: JOB_IS_JOB_APPLY_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: JOB_IS_JOB_APPLY_LOADING_DONE });
    ErrorHandler(err, "is_Job_Applied");
  }
};

export const get_PreRegisterData =
  (JobListId) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_PREREGISTER_LOADING });

      const IpAddres = Get_IpAddres();
      const Data = await axios.get(
        `${IpAddres}/job/preapply/${JobListId}`,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({
          type: JOB_PREREGISTER_LOADED,
          payload: Data.data,
        });
        dispatch({ type: JOB_PREREGISTER_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: JOB_PREREGISTER_LOADING_DONE });
      ErrorHandler(err, "get_PreRegisterData");
    }
  };

export const User_Apply_Job =
  (JobListId, PreRegisterDataSkill) => async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobListId, PreRegisterDataSkill });
      const Data = await axios.post(
        `${IpAddres}/job/apply`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `Berhasil apply job`));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "User_Apply_Job");
    }
  };

export const is_Job_Saved = (JobListId) => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_IS_JOB_SAVE_LOADING });

    const IpAddres = Get_IpAddres();
    const body = JSON.stringify({ JobListId });
    const Data = await axios.post(
      `${IpAddres}/job/is/save`,
      body,
      tokenConfig(getState)
    );

    if (Data.status === 200) {
      dispatch({
        type: JOB_IS_JOB_SAVE_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: JOB_IS_JOB_SAVE_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: JOB_IS_JOB_SAVE_LOADING_DONE });
    ErrorHandler(err, "is_Job_Saved");
  }
};

export const User_Save_Job = (JobListId) => async (dispatch, getState) => {
  try {
    const IpAddres = Get_IpAddres();
    const body = JSON.stringify({ JobListId });
    const Data = await axios.post(
      `${IpAddres}/job/save`,
      body,
      tokenConfig(getState)
    );

    if (Data.status === 200) {
      dispatch({ type: AUTH_RELOADE_PAGE });
      dispatch(Create_Success_Messages(200, `Berhasil save job`));
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    ErrorHandler(err, "User_Save_Job");
  }
};

export const get_List_Pendaftar = (JobId) => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_LIST_PENDAFTAR_LOADING });

    const IpAddres = Get_IpAddres();
    const body = JSON.stringify({ JobId });
    const Data = await axios.post(
      `${IpAddres}/job/pendaftar`,
      body,
      tokenConfig(getState)
    );

    if (Data.status === 200) {
      dispatch({
        type: JOB_LIST_PENDAFTAR_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: JOB_LIST_PENDAFTAR_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: JOB_LIST_PENDAFTAR_LOADING_DONE });
    ErrorHandler(err, "get_List_Pendaftar");
  }
};

export const get_List_Pendaftar_Detail =
  (JobId, AccountId) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_LIST_PENDAFTAR_DETAIL_LOADING });

      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobId, AccountId });
      const Data = await axios.post(
        `${IpAddres}/job/pendaftar/detail`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({
          type: JOB_LIST_PENDAFTAR_DETAIL_LOADED,
          payload: Data.data.Data,
        });
        dispatch({ type: JOB_LIST_PENDAFTAR_DETAIL_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: JOB_LIST_PENDAFTAR_DETAIL_LOADING_DONE });
      ErrorHandler(err, "get_List_Pendaftar_Detail");
    }
  };

export const get_List_Pendaftar_Diajukan =
  (JobId) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_LIST_PENDAFTAR_DIAJUKAN_LOADING });

      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobId });
      const Data = await axios.post(
        `${IpAddres}/job/pendaftardiajukan`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({
          type: JOB_LIST_PENDAFTAR_DIAJUKAN_LOADED,
          payload: Data.data.Data,
        });
        dispatch({ type: JOB_LIST_PENDAFTAR_DIAJUKAN_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: JOB_LIST_PENDAFTAR_DIAJUKAN_LOADING_DONE });
      ErrorHandler(err, "get_List_Pendaftar_Diajukan");
    }
  };

export const Konfirm_Pendaftar =
  (JobId, AccountId, Status, Keterangan, ListPendaftar) =>
  async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobId, AccountId, Status, Keterangan });
      const Data = await axios.post(
        `${IpAddres}/job/konfirmpendaftar`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        // if (true) {
        // dispatch({ type: AUTH_RELOADE_PAGE })
        dispatch(Create_Success_Messages(200, `Berhasil save `));
        dispatch({
          type: JOB_LIST_PENDAFTAR_SUCCESS,
          payload: { JobId, AccountId, ListPendaftar },
        });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "Konfirm_Pendaftar");
    }
  };

export const get_List_Rekomendasi = (JobId) => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_LIST_REKOMENDASI_LOADING });

    const IpAddres = Get_IpAddres();
    const body = JSON.stringify({ JobId });
    const Data = await axios.post(
      `${IpAddres}/job/rekomendasi`,
      body,
      tokenConfig(getState)
    );

    if (Data.status === 200) {
      dispatch({
        type: JOB_LIST_REKOMENDASI_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: JOB_LIST_REKOMENDASI_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: JOB_LIST_REKOMENDASI_LOADING_DONE });
    ErrorHandler(err, "get_List_Rekomendasi");
  }
};

export const Konfirm_Rekomendasi =
  (JobId, AccountId, Status, Keterangan, Jadwal) =>
  async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({
        JobId,
        AccountId,
        Status,
        Keterangan,
        Jadwal,
      });
      const Data = await axios.post(
        `${IpAddres}/job/konfirmrekomendasi`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `Berhasil save `));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "Konfirm_Rekomendasi");
    }
  };

export const get_List_Personality_Test =
  (param) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_LIST_PERSONALITYTEST_LOADING });
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ param });

      const Data = await axios.post(
        `${IpAddres}/job/personalitytest`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({
          type: JOB_LIST_PERSONALITYTEST_LOADED,
          payload: Data.data.Data,
        });
        dispatch({ type: JOB_LIST_PERSONALITYTEST_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: JOB_LIST_PERSONALITYTEST_LOADING_DONE });
      ErrorHandler(err, "get_List_Personality_Test");
    }
  };

export const Konfirm_Personality_Test =
  (
    JobActionId,
    FirstPersonalty,
    SecondPersonalty,
    Keterangan,
    StressManagement,
    SensitivityLevel,
    CompassionLevel,
    PatienceLevel,
    CreativeAbilities,
    ArtisticAbilities,
    OutgoingTraits,
    InteractionLevel,
    Analytical,
    DetailedTraits,
    DecisionMaking,
    Independent,
    PersuasiveLevel,
    AggressiveLevel,
    StubbornLevel,
    GoalSetting,
    TimeManagement,
    ConfidenceLevel
  ) =>
  async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({
        JobActionId,
        FirstPersonalty,
        SecondPersonalty,
        Keterangan,
        StressManagement,
        SensitivityLevel,
        CompassionLevel,
        PatienceLevel,
        CreativeAbilities,
        ArtisticAbilities,
        OutgoingTraits,
        InteractionLevel,
        Analytical,
        DetailedTraits,
        DecisionMaking,
        Independent,
        PersuasiveLevel,
        AggressiveLevel,
        StubbornLevel,
        GoalSetting,
        TimeManagement,
        ConfidenceLevel,
      });
      const Data = await axios.post(
        `${IpAddres}/job/konfirmpersonalitytest`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `Berhasil save `));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "Konfirm_Personality_Test");
    }
  };

export const get_List_Personality_Test_Detail =
  (Id) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_LIST_PERSONALITYTEST_DETAIL_LOADING });

      const IpAddres = Get_IpAddres();
      const Data = await axios.get(
        `${IpAddres}/job/personalitytest/${Id}/detail`,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({
          type: JOB_LIST_PERSONALITYTEST_DETAIL_LOADED,
          payload: Data.data.Data,
        });
        dispatch({ type: JOB_LIST_PERSONALITYTEST_DETAIL_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: JOB_LIST_PERSONALITYTEST_DETAIL_LOADING_DONE });
      ErrorHandler(err, "get_List_Personality_Test_Detail");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const get_List_Personality_Test_Detail_JobList =
  (JobListId, AccountId) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_LIST_PERSONALITYTEST_DETAIL_LOADING });

      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobListId, AccountId });
      const Data = await axios.post(
        `${IpAddres}/job/personalitytest/joblist/detail`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({
          type: JOB_LIST_PERSONALITYTEST_DETAIL_LOADED,
          payload: Data.data.Data,
        });
        dispatch({ type: JOB_LIST_PERSONALITYTEST_DETAIL_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: JOB_LIST_PERSONALITYTEST_DETAIL_LOADING_DONE });
      ErrorHandler(err, "get_List_Personality_Test_Detail_JobList");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
      //     }
      // } catch (err2) {
      // }
    }
  };

//* Interview

export const get_List_Interview_Jadwal =
  (param) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_LIST_INTERVIEW_JADWAL_LOADING });

      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ param });
      const Data = await axios.post(
        `${IpAddres}/job/interview/jadwal`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({
          type: JOB_LIST_INTERVIEW_JADWAL_LOADED,
          payload: Data.data.Data,
        });
        dispatch({ type: JOB_LIST_INTERVIEW_JADWAL_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: JOB_LIST_INTERVIEW_JADWAL_LOADING_DONE });
      ErrorHandler(err, "get_List_Interview_Jadwal");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const get_List_Interview_Jadwal_Detail =
  (JobId, AccountId) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_LIST_INTERVIEW_JADWAL_DETAIL_LOADING });

      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobId, AccountId });
      const Data = await axios.post(
        `${IpAddres}/job/interview/jadwal/detail`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({
          type: JOB_LIST_INTERVIEW_JADWAL_DETAIL_LOADED,
          payload: Data.data.Data,
        });
        dispatch({ type: JOB_LIST_INTERVIEW_JADWAL_DETAIL_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: JOB_LIST_INTERVIEW_JADWAL_DETAIL_LOADING_DONE });
      ErrorHandler(err, "get_List_Interview_Jadwal_Detail");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const get_List_Interview_User = () => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_LIST_INTERVIEW_USER_LOADING });

    const IpAddres = Get_IpAddres();
    const Data = await axios.get(
      `${IpAddres}/job/interview/user`,
      tokenConfig(getState)
    );

    if (Data.status === 200) {
      dispatch({
        type: JOB_LIST_INTERVIEW_USER_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: JOB_LIST_INTERVIEW_USER_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: JOB_LIST_INTERVIEW_USER_LOADING_DONE });
    ErrorHandler(err, "get_List_Interview_User");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else if (err.response.data.msg) {
    //         dispatch(Create_Error_Messages(400, err.response.data.msg))
    //         // if (err.response.data.msg === 'Data Sudah Ada') {
    //         if (false) {
    //             // dispatch({
    //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
    //             //     payload: true
    //             // })
    //         }
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const Konfirm_Interview_User =
  (
    JobListId,
    AccountId,
    InterviewDate,
    Status,
    Keterangan,
    ListData,
    PertanyaanPerilaku,
    PertanyaanTeknik
  ) =>
  async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({
        JobListId,
        AccountId,
        InterviewDate,
        Status,
        Keterangan,
        ListData,
        PertanyaanPerilaku,
        PertanyaanTeknik,
      });
      const Data = await axios.post(
        `${IpAddres}/job/interview/user/konfirm`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `Berhasil save `));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "Konfirm_Interview_User");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa konfirmpendaftar'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const Konfirm_Final_Interview_User =
  (JobListId, AccountId, Status, Keterangan, ListData, AlternativeTest) =>
  async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({
        JobListId,
        AccountId,
        Status,
        Keterangan,
        ListData,
        AlternativeTest,
      });
      const Data = await axios.post(
        `${IpAddres}/job/interview/user/final/konfirm`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `Berhasil save `));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "Konfirm_Final_Interview_User");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa konfirmpendaftar'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const get_List_Interview_User_Detail =
  (JobListId, AccountId) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_LIST_INTERVIEW_USER_DETAIL_LOADING });

      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobListId, AccountId });
      const Data = await axios.post(
        `${IpAddres}/job/interview/user/detail`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({
          type: JOB_LIST_INTERVIEW_USER_DETAIL_LOADED,
          payload: Data.data,
        });
        dispatch({ type: JOB_LIST_INTERVIEW_USER_DETAIL_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: JOB_LIST_INTERVIEW_USER_DETAIL_LOADING_DONE });
      ErrorHandler(err, "get_List_Interview_User_Detail");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const get_List_Interview_HR = (param) => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_LIST_INTERVIEW_HR_LOADING });

    const IpAddres = Get_IpAddres();
    const body = JSON.stringify({ param });

    const Data = await axios.post(
      `${IpAddres}/job/interview/hr`,
      body,
      tokenConfig(getState)
    );

    if (Data.status === 200) {
      dispatch({
        type: JOB_LIST_INTERVIEW_HR_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: JOB_LIST_INTERVIEW_HR_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    ErrorHandler(err, "get_List_Interview_HR");
    dispatch({ type: JOB_LIST_INTERVIEW_HR_LOADING_DONE });
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else if (err.response.data.msg) {
    //         dispatch(Create_Error_Messages(400, err.response.data.msg))
    //         // if (err.response.data.msg === 'Data Sudah Ada') {
    //         if (false) {
    //             // dispatch({
    //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
    //             //     payload: true
    //             // })
    //         }
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const Konfirm_Interview_HR =
  (
    JobListId,
    AccountId,
    InterviewDate,
    Status,
    Keterangan,
    ListData,
    PertanyaanPerilaku,
    PertanyaanTeknik
  ) =>
  async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({
        JobListId,
        AccountId,
        InterviewDate,
        Status,
        Keterangan,
        ListData,
        PertanyaanPerilaku,
        PertanyaanTeknik,
      });
      const Data = await axios.post(
        `${IpAddres}/job/interview/hr/konfirm`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `Berhasil save `));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "Konfirm_Interview_HR");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa konfirmpendaftar'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const get_List_Interview_HR_Detail =
  (JobListId, AccountId) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_LIST_INTERVIEW_HR_DETAIL_LOADING });

      const IpAddres = Get_IpAddres();

      const body = JSON.stringify({ JobListId, AccountId });
      const Data = await axios.post(
        `${IpAddres}/job/interview/hr/detail`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({
          type: JOB_LIST_INTERVIEW_HR_DETAIL_LOADED,
          payload: Data.data,
        });
        dispatch({ type: JOB_LIST_INTERVIEW_HR_DETAIL_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: JOB_LIST_INTERVIEW_HR_DETAIL_LOADING_DONE });
      ErrorHandler(err, "get_List_Interview_HR_Detail");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const Edit_Interview_HR =
  (JobListId, AccountId, Status, Keterangan) => async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobListId, AccountId, Status, Keterangan });
      const Data = await axios.post(
        `${IpAddres}/job/interview/hr/edit`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `Berhasil save `));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "Edit_Interview_HR");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa konfirmpendaftar'))
      //     }
      // } catch (err2) {
      // }
    }
  };

//* Offering

export const get_List_Offering = () => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_LIST_OFFERING_LOADING });

    const IpAddres = Get_IpAddres();
    const Data = await axios.get(
      `${IpAddres}/job/offering`,
      tokenConfig(getState)
    );

    if (Data.status === 200) {
      dispatch({
        type: JOB_LIST_OFFERING_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: JOB_LIST_OFFERING_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: JOB_LIST_OFFERING_LOADING_DONE });
    ErrorHandler(err, "get_List_Offering");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else if (err.response.data.msg) {
    //         dispatch(Create_Error_Messages(400, err.response.data.msg))
    //         // if (err.response.data.msg === 'Data Sudah Ada') {
    //         if (false) {
    //             // dispatch({
    //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
    //             //     payload: true
    //             // })
    //         }
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const Konfirm_Offering =
  (
    JobListId,
    AccountId,
    Status,
    Keterangan,
    AdjustGolongan,
    AdjustLevel,
    AdjustSub,
    AdjustContractDuration
  ) =>
  async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({
        JobListId,
        AccountId,
        Status,
        Keterangan,
        AdjustGolongan,
        AdjustLevel,
        AdjustSub,
        AdjustContractDuration,
      });
      const Data = await axios.post(
        `${IpAddres}/job/offering/konfirm`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `Berhasil save `));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "Konfirm_Offering");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa Konfirm_Offering'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const get_List_Offering_Detail =
  (JobListId, AccountId) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_LIST_OFFERING_DETAIL_LOADING });

      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobListId, AccountId });
      const Data = await axios.post(
        `${IpAddres}/job/offering/detail`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({
          type: JOB_LIST_OFFERING_DETAIL_LOADED,
          payload: Data.data.Data,
        });
        dispatch({ type: JOB_LIST_OFFERING_DETAIL_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: JOB_LIST_OFFERING_DETAIL_LOADING_DONE });
      ErrorHandler(err, "get_List_Offering_Detail");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
      //     }
      // } catch (err2) {
      // }
    }
  };

//* MCU

export const get_List_MCU = () => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_LIST_MCU_LOADING });

    const IpAddres = Get_IpAddres();
    const Data = await axios.get(`${IpAddres}/job/mcu`, tokenConfig(getState));

    if (Data.status === 200) {
      dispatch({
        type: JOB_LIST_MCU_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: JOB_LIST_MCU_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: JOB_LIST_MCU_LOADING_DONE });
    ErrorHandler(err, "get_List_MCU");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else if (err.response.data.msg) {
    //         dispatch(Create_Error_Messages(400, err.response.data.msg))
    //         // if (err.response.data.msg === 'Data Sudah Ada') {
    //         if (false) {
    //             // dispatch({
    //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
    //             //     payload: true
    //             // })
    //         }
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const Assign_MCU =
  (JobListId, AccountId, AssignDate) => async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobListId, AccountId, AssignDate });
      const Data = await axios.post(
        `${IpAddres}/job/mcu/assign`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `Berhasil save `));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "Assign_MCU");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa Konfirm_MCU'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const Konfirm_Assign_MCU =
  (JobListId, AccountId, Status, Keterangan) => async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobListId, AccountId, Status, Keterangan });
      const Data = await axios.post(
        `${IpAddres}/job/mcu/assign/konfirm`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `Berhasil save `));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "Konfirm_Assign_MCU");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa Konfirm_Assign_MCU'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const Konfirm_MCU =
  (JobListId, AccountId, Status, Keterangan) => async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobListId, AccountId, Status, Keterangan });
      const Data = await axios.post(
        `${IpAddres}/job/mcu/konfirm`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `Berhasil save `));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "Konfirm_MCU");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa Konfirm_MCU'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const get_List_MCU_Detail =
  (JobListId, AccountId) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_LIST_MCU_DETAIL_LOADING });

      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobListId, AccountId });
      const Data = await axios.post(
        `${IpAddres}/job/mcu/detail`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({
          type: JOB_LIST_MCU_DETAIL_LOADED,
          payload: Data.data.Data,
        });
        dispatch({ type: JOB_LIST_MCU_DETAIL_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: JOB_LIST_MCU_DETAIL_LOADING_DONE });
      ErrorHandler(err, "get_List_MCU_Detail");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
      //     }
      // } catch (err2) {
      // }
    }
  };

//* Equipment

export const get_List_Equipment = (param) => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_LIST_EQUIPMENT_LOADING });

    const IpAddres = Get_IpAddres();
    const body = JSON.stringify({ param });
    const Data = await axios.post(
      `${IpAddres}/job/equipment`,
      body,
      tokenConfig(getState)
    );

    if (Data.status === 200) {
      dispatch({
        type: JOB_LIST_EQUIPMENT_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: JOB_LIST_EQUIPMENT_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: JOB_LIST_EQUIPMENT_LOADING_DONE });
    ErrorHandler(err, "get_List_Equipment");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else if (err.response.data.msg) {
    //         dispatch(Create_Error_Messages(400, err.response.data.msg))
    //         // if (err.response.data.msg === 'Data Sudah Ada') {
    //         if (false) {
    //             // dispatch({
    //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
    //             //     payload: true
    //             // })
    //         }
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const get_List_Equipment_Detail =
  (JobListId, AccountId) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_LIST_EQUIPMENT_DETAIL_LOADING });

      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobListId, AccountId });
      const Data = await axios.post(
        `${IpAddres}/job/equipment/detail`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({
          type: JOB_LIST_EQUIPMENT_DETAIL_LOADED,
          payload: Data.data,
        });
        dispatch({ type: JOB_LIST_EQUIPMENT_DETAIL_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "get_List_Equipment_Detail");

      dispatch({ type: JOB_LIST_EQUIPMENT_DETAIL_LOADING_DONE });
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const Konfirm_Equipment =
  (JobListId, AccountId, Status, Email, Keterangan) =>
  async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({
        JobListId,
        AccountId,
        Status,
        Email,
        Keterangan,
      });
      const Data = await axios.post(
        `${IpAddres}/job/equipment/konfirm`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `Berhasil save `));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "Konfirm_Equipment");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa Konfirm_GreetDay'))
      //     }
      // } catch (err2) {
      // }
    }
  };

//* PreBoarding

export const get_List_PreBoarding = (param) => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_LIST_PREBOARDING_LOADING });

    const IpAddres = Get_IpAddres();
    const body = JSON.stringify({ param });
    const Data = await axios.post(
      `${IpAddres}/job/preboarding`,
      body,
      tokenConfig(getState)
    );

    if (Data.status === 200) {
      dispatch({
        type: JOB_LIST_PREBOARDING_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: JOB_LIST_PREBOARDING_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: JOB_LIST_PREBOARDING_LOADING_DONE });
    ErrorHandler(err, "get_List_PreBoarding");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else if (err.response.data.msg) {
    //         dispatch(Create_Error_Messages(400, err.response.data.msg))
    //         // if (err.response.data.msg === 'Data Sudah Ada') {
    //         if (false) {
    //             // dispatch({
    //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
    //             //     payload: true
    //             // })
    //         }
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const get_List_PreBoarding_Detail =
  (JobListId, AccountId) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_LIST_PREBOARDING_DETAIL_LOADING });

      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobListId, AccountId });
      const Data = await axios.post(
        `${IpAddres}/job/preboarding/detail`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({
          type: JOB_LIST_PREBOARDING_DETAIL_LOADED,
          payload: Data.data,
        });
        dispatch({ type: JOB_LIST_PREBOARDING_DETAIL_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: JOB_LIST_PREBOARDING_DETAIL_LOADING_DONE });
      ErrorHandler(err, "get_List_PreBoarding_Detail");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const Konfirm_PreBoarding =
  (
    JobListId,
    AccountId,
    Status,
    KTP,
    KTPKet,
    KK,
    KKKet,
    NPWP,
    NPWPKet,
    BCA,
    BCAKet,
    BPJSKesehatan,
    BPJSKesehatanKet,
    BPJSKetenagakerjaan,
    BPJSKetenagakerjaanKet
  ) =>
  async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({
        JobListId,
        AccountId,
        Status,
        KTP,
        KTPKet,
        KK,
        KKKet,
        NPWP,
        NPWPKet,
        BCA,
        BCAKet,
        BPJSKesehatan,
        BPJSKesehatanKet,
        BPJSKetenagakerjaan,
        BPJSKetenagakerjaanKet,
      });
      const Data = await axios.post(
        `${IpAddres}/job/preboarding/konfirm`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `Berhasil save `));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "Konfirm_PreBoarding");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa Konfirm_GreetDay'))
      //     }
      // } catch (err2) {
      // }
    }
  };

//* PKWT

export const get_List_PKWT = () => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_LIST_PKWT_LOADING });

    const IpAddres = Get_IpAddres();
    const Data = await axios.get(`${IpAddres}/job/pkwt`, tokenConfig(getState));

    if (Data.status === 200) {
      dispatch({
        type: JOB_LIST_PKWT_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: JOB_LIST_PKWT_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: JOB_LIST_PKWT_LOADING_DONE });
    ErrorHandler(err, "get_List_PKWT");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else if (err.response.data.msg) {
    //         dispatch(Create_Error_Messages(400, err.response.data.msg))
    //         // if (err.response.data.msg === 'Data Sudah Ada') {
    //         if (false) {
    //             // dispatch({
    //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
    //             //     payload: true
    //             // })
    //         }
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const Konfirm_PKWT =
  (JobListId, AccountId, Status, JoinDate, EndDate, Keterangan, AdjCoDur) =>
  async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({
        JobListId,
        AccountId,
        Status,
        JoinDate,
        EndDate,
        Keterangan,
        AdjCoDur,
      });
      const Data = await axios.post(
        `${IpAddres}/job/pkwt/konfirm`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `Berhasil save `));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "Konfirm_PKWT");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa Konfirm_PKWT'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const get_List_PKWT_Detail =
  (JobListId, AccountId) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_LIST_PKWT_DETAIL_LOADING });

      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobListId, AccountId });
      const Data = await axios.post(
        `${IpAddres}/job/pkwt/detail`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({
          type: JOB_LIST_PKWT_DETAIL_LOADED,
          payload: Data.data.Data,
        });
        dispatch({ type: JOB_LIST_PKWT_DETAIL_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: JOB_LIST_PKWT_DETAIL_LOADING_DONE });
      ErrorHandler(err, "get_List_PKWT_Detail");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
      //     }
      // } catch (err2) {
      // }
    }
  };

//* BPJS

export const get_List_BPJS = () => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_LIST_BPJS_LOADING });

    const IpAddres = Get_IpAddres();
    const Data = await axios.get(`${IpAddres}/job/BPJS`, tokenConfig(getState));

    if (Data.status === 200) {
      dispatch({
        type: JOB_LIST_BPJS_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: JOB_LIST_BPJS_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: JOB_LIST_BPJS_LOADING_DONE });
    ErrorHandler(err, "get_List_BPJS");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else if (err.response.data.msg) {
    //         dispatch(Create_Error_Messages(400, err.response.data.msg))
    //         // if (err.response.data.msg === 'Data Sudah Ada') {
    //         if (false) {
    //             // dispatch({
    //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
    //             //     payload: true
    //             // })
    //         }
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const Konfirm_BPJS =
  (
    JobListId,
    AccountId,
    Status,
    Keterangan,
    BPJSKesehatan,
    BPJSKetenagakerjaan
  ) =>
  async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({
        JobListId,
        AccountId,
        Status,
        Keterangan,
        BPJSKesehatan,
        BPJSKetenagakerjaan,
      });
      const Data = await axios.post(
        `${IpAddres}/job/BPJS/konfirm`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `Berhasil save `));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "Konfirm_BPJS");
    }
  };

export const get_List_BPJS_Detail =
  (JobListId, AccountId) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_LIST_BPJS_DETAIL_LOADING });

      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobListId, AccountId });
      const Data = await axios.post(
        `${IpAddres}/job/BPJS/detail`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({
          type: JOB_LIST_BPJS_DETAIL_LOADED,
          payload: Data.data.Data,
        });
        dispatch({ type: JOB_LIST_BPJS_DETAIL_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: JOB_LIST_BPJS_DETAIL_LOADING_DONE });
      ErrorHandler(err, "get_List_BPJS_Detail");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
      //     }
      // } catch (err2) {
      // }
    }
  };

//* INDUSTRY

export const get_List_Industry = () => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_LIST_INDUSTRY_LOADING });

    const IpAddres = Get_IpAddres();
    const Data = await axios.get(
      `${IpAddres}/job/Industry`,
      tokenConfig(getState)
    );

    if (Data.status === 200) {
      dispatch({
        type: JOB_LIST_INDUSTRY_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: JOB_LIST_INDUSTRY_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: JOB_LIST_INDUSTRY_LOADING_DONE });
    ErrorHandler(err, "get_List_Industry");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else if (err.response.data.msg) {
    //         dispatch(Create_Error_Messages(400, err.response.data.msg))
    //         // if (err.response.data.msg === 'Data Sudah Ada') {
    //         if (false) {
    //             // dispatch({
    //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
    //             //     payload: true
    //             // })
    //         }
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const Konfirm_Industry =
  (JobListId, AccountId, Status, Keterangan) => async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobListId, AccountId, Status, Keterangan });
      const Data = await axios.post(
        `${IpAddres}/job/Industry/konfirm`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `Berhasil save `));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "Konfirm_Industry");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa Konfirm_INDUSTRY'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const get_List_Industry_Detail =
  (JobListId, AccountId) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_LIST_INDUSTRY_DETAIL_LOADING });

      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobListId, AccountId });
      const Data = await axios.post(
        `${IpAddres}/job/Industry/detail`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({
          type: JOB_LIST_INDUSTRY_DETAIL_LOADED,
          payload: Data.data.Data,
        });
        dispatch({ type: JOB_LIST_INDUSTRY_DETAIL_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: JOB_LIST_INDUSTRY_DETAIL_LOADING_DONE });
      ErrorHandler(err, "get_List_Industry_Detail");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
      //     }
      // } catch (err2) {
      // }
    }
  };

//* GREETDAY

export const get_List_GreetDay = () => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_LIST_GREETDAY_LOADING });

    const IpAddres = Get_IpAddres();
    const Data = await axios.get(
      `${IpAddres}/job/greetday`,
      tokenConfig(getState)
    );

    if (Data.status === 200) {
      dispatch({
        type: JOB_LIST_GREETDAY_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: JOB_LIST_GREETDAY_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: JOB_LIST_GREETDAY_LOADING_DONE });
    ErrorHandler(err, "get_List_GreetDay");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else if (err.response.data.msg) {
    //         dispatch(Create_Error_Messages(400, err.response.data.msg))
    //         // if (err.response.data.msg === 'Data Sudah Ada') {
    //         if (false) {
    //             // dispatch({
    //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
    //             //     payload: true
    //             // })
    //         }
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const Konfirm_GreetDay =
  (JobListId, AccountId, Status, Keterangan) => async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobListId, AccountId, Status, Keterangan });
      const Data = await axios.post(
        `${IpAddres}/job/greetday/konfirm`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `Berhasil save `));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "Konfirm_GreetDay");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa Konfirm_GreetDay'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const get_List_GreetDay_Detail =
  (JobListId, AccountId) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_LIST_GREETDAY_DETAIL_LOADING });

      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobListId, AccountId });
      const Data = await axios.post(
        `${IpAddres}/job/greetday/detail`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({
          type: JOB_LIST_GREETDAY_DETAIL_LOADED,
          payload: Data.data.Data,
        });
        dispatch({ type: JOB_LIST_GREETDAY_DETAIL_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: JOB_LIST_GREETDAY_DETAIL_LOADING_DONE });
      ErrorHandler(err, "get_List_GreetDay_Detail");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
      //     }
      // } catch (err2) {
      // }
    }
  };

//* KONTRAK

export const get_List_Kontrak = () => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_LIST_KONTRAK_LOADING });

    const IpAddres = Get_IpAddres();
    const Data = await axios.get(
      `${IpAddres}/job/kontrak`,
      tokenConfig(getState)
    );

    if (Data.status === 200) {
      dispatch({
        type: JOB_LIST_KONTRAK_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: JOB_LIST_KONTRAK_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: JOB_LIST_KONTRAK_LOADING_DONE });
    ErrorHandler(err, "get_List_Kontrak");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else if (err.response.data.msg) {
    //         dispatch(Create_Error_Messages(400, err.response.data.msg))
    //         // if (err.response.data.msg === 'Data Sudah Ada') {
    //         if (false) {
    //             // dispatch({
    //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
    //             //     payload: true
    //             // })
    //         }
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const Konfirm_Kontrak =
  (JobListId, AccountId, Status, Keterangan) => async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobListId, AccountId, Status, Keterangan });
      const Data = await axios.post(
        `${IpAddres}/job/kontrak/konfirm`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `Berhasil save `));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "Konfirm_Kontrak");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa Konfirm_Kontrak'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const get_List_Kontrak_Detail =
  (JobListId, AccountId) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_LIST_KONTRAK_DETAIL_LOADING });

      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobListId, AccountId });
      const Data = await axios.post(
        `${IpAddres}/job/kontrak/detail`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({
          type: JOB_LIST_KONTRAK_DETAIL_LOADED,
          payload: Data.data.Data,
        });
        dispatch({ type: JOB_LIST_KONTRAK_DETAIL_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: JOB_LIST_KONTRAK_DETAIL_LOADING_DONE });
      ErrorHandler(err, "get_List_Kontrak_Detail");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
      //     }
      // } catch (err2) {
      // }
    }
  };

//* ONBOARDING

export const get_List_OnBoarding = () => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_LIST_ONBOARDING_LOADING });

    const IpAddres = Get_IpAddres();
    const Data = await axios.get(
      `${IpAddres}/job/onboarding`,
      tokenConfig(getState)
    );

    if (Data.status === 200) {
      dispatch({
        type: JOB_LIST_ONBOARDING_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: JOB_LIST_ONBOARDING_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: JOB_LIST_ONBOARDING_LOADING_DONE });
    ErrorHandler(err, "get_List_OnBoarding");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else if (err.response.data.msg) {
    //         dispatch(Create_Error_Messages(400, err.response.data.msg))
    //         // if (err.response.data.msg === 'Data Sudah Ada') {
    //         if (false) {
    //             // dispatch({
    //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
    //             //     payload: true
    //             // })
    //         }
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const Konfirm_OnBoarding =
  (JobListId, AccountId, Status, Keterangan) => async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobListId, AccountId, Status, Keterangan });
      const Data = await axios.post(
        `${IpAddres}/job/onboarding/konfirm`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `Berhasil save `));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "Konfirm_OnBoarding");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa Konfirm_OnBoarding'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const get_List_OnBoarding_Detail =
  (JobListId, AccountId) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_LIST_ONBOARDING_DETAIL_LOADING });

      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobListId, AccountId });
      const Data = await axios.post(
        `${IpAddres}/job/onboarding/detail`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({
          type: JOB_LIST_ONBOARDING_DETAIL_LOADED,
          payload: Data.data.Data,
        });
        dispatch({ type: JOB_LIST_ONBOARDING_DETAIL_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: JOB_LIST_ONBOARDING_DETAIL_LOADING_DONE });
      ErrorHandler(err, "get_List_OnBoarding_Detail");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         if (false) {
      //             // dispatch({
      //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
      //             //     payload: true
      //             // })
      //         }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
      //     }
      // } catch (err2) {
      // }
    }
  };

//* APPLICATIONS

export const get_List_Applications_Apply = () => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_LIST_APPLICATIONS_APPLY_LOADING });

    const IpAddres = Get_IpAddres();
    const Data = await axios.get(
      `${IpAddres}/job/applications/apply`,
      tokenConfig(getState)
    );

    if (Data.status === 200) {
      dispatch({
        type: JOB_LIST_APPLICATIONS_APPLY_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: JOB_LIST_APPLICATIONS_APPLY_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: JOB_LIST_APPLICATIONS_APPLY_LOADING_DONE });
    ErrorHandler(err, "get_List_Applications_Apply");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else if (err.response.data.msg) {
    //         dispatch(Create_Error_Messages(400, err.response.data.msg))
    //         // if (err.response.data.msg === 'Data Sudah Ada') {
    //         if (false) {
    //             // dispatch({
    //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
    //             //     payload: true
    //             // })
    //         }
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const get_List_Applications_Save = () => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_LIST_APPLICATIONS_SAVE_LOADING });

    const IpAddres = Get_IpAddres();
    const Data = await axios.get(
      `${IpAddres}/job/applications/save`,
      tokenConfig(getState)
    );

    if (Data.status === 200) {
      dispatch({
        type: JOB_LIST_APPLICATIONS_SAVE_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: JOB_LIST_APPLICATIONS_SAVE_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    ErrorHandler(err, "get_List_Applications_Save");
    dispatch({ type: JOB_LIST_APPLICATIONS_SAVE_LOADING_DONE });
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else if (err.response.data.msg) {
    //         dispatch(Create_Error_Messages(400, err.response.data.msg))
    //         // if (err.response.data.msg === 'Data Sudah Ada') {
    //         if (false) {
    //             // dispatch({
    //             //     type: DEPARTMENT_ACTION_ADD_ERROR,
    //             //     payload: true
    //             // })
    //         }
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Job Tida Bisa diupdate'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const get_Move_List = () => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_MOVE_LIST_LOADING });

    const IpAddres = Get_IpAddres();
    const Data = await axios.get(`${IpAddres}/job/move`, tokenConfig(getState));

    if (Data.status === 200) {
      dispatch({
        type: JOB_MOVE_LIST_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: JOB_MOVE_LIST_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    ErrorHandler(err, "get_Move_List");
    dispatch({ type: JOB_MOVE_LIST_LOADING_DONE });
  }
};

export const get_Move_Detail =
  (JobListId, AccountId) => async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_MOVE_DETAIL_LOADING });

      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({ JobListId, AccountId });
      const Data = await axios.post(
        `${IpAddres}/job/move/detail`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({
          type: JOB_MOVE_DETAIL_LOADED,
          payload: Data.data.Data,
        });
        dispatch({ type: JOB_MOVE_DETAIL_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "get_List_Move_Detail");
      dispatch({ type: JOB_MOVE_DETAIL_LOADING_DONE });
    }
  };

export const Move_Application =
  (JobListId, AccountId, NewJobListId, Keterangan) =>
  async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();
      const body = JSON.stringify({
        JobListId,
        AccountId,
        NewJobListId,
        Keterangan,
      });
      const Data = await axios.post(
        `${IpAddres}/job/move/move`,
        body,
        tokenConfig(getState)
      );

      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `Berhasil save `));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "Move_Application");
    }
  };
