import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Tabs, Tab } from '@mui/material'

import CoJob from './Sub/Job/Job'
import ListPendaftar from './Sub/Job/ListPendaftar'
import Recommended from './Sub/Job/Recommended'

class Job extends React.Component {
    state = {
        TabValue: 0
    }
    handleTabChange = (e, newTabValue) => {
        this.setState({ TabValue: newTabValue })
    }
    render() {
        const User = this.props.User
        const isAdmin = User.isAdmin
        const UserRole = User.Role
        const JobReferee = '86374'
        const JobRefereeIntern = '10008'
        const isJobReferee = UserRole.includes(JobReferee)
        const isJobRefereeIntern = UserRole.includes(JobRefereeIntern)

        const {
            TabValue
        } = this.state

        const TabDataOrigin = [
            { TabLabel: 'Job', TabPanel: <CoJob /> },
            { TabLabel: 'ListPendaftar', TabPanel: <ListPendaftar /> },
            { TabLabel: 'Recommended', TabPanel: <Recommended /> },
        ]

        const TabData = isAdmin ? TabDataOrigin : isJobRefereeIntern ? TabDataOrigin.slice(1, 2) : isJobReferee ? TabDataOrigin.slice(0, 2) : TabDataOrigin.slice(2, 3)
        // const TabData = TabDataOrigin

        // const TabData = Role === 'HeadHR' || Role === 'HR' ? TabDataOrigin.slice(0, 2) :
        //     Role === 'HeadDepartemen' || Role === 'Departemen' ? TabDataOrigin.slice(2, 3) : TabDataOrigin
        return (
            <Fragment>
                <h2>
                    Jobs
                </h2>
                <Tabs
                    value={TabValue}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={TabData.length > 6 ? "scrollable" : null}
                    scrollButtons="auto"
                // centered={TabData.length > 6 ? false : true}
                >
                    {TabData.map((item, index) => (
                        <Tab key={`Job_Tabs${index}`} label={item.TabLabel} />
                    ))}
                </Tabs>
                <div
                    style={{ margin: '5% 0 5% 0' }}
                >
                    {TabValue === 0 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 1 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 2 ? TabData[TabValue].TabPanel : null}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    User: state.Auth.User,
})

export default connect(mapStateToProps, {})(Job)