import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Create_Warning_Messages } from '../../../../../../Store/Actions/Messages.Actions'
import { get_Jobdesk_Data, get_Jobdesk_Master_User, Update_Jobdesk } from '../../../../../../Store/Actions/Jobdesk.Action'

import { Skeleton, Checkbox, TextField, FormControl, InputLabel, Select, Button, Autocomplete, CircularProgress } from '@mui/material'

import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'

import CoTable from '../../../../../Containers/Table'

class Jobdesk extends React.Component {
    state = {
        NamaJabatan: '',
        Level: '',
        Departemen: '',
        StatusKaryawan: '',
        BulanBuka: '',
        AtasanLangsung: null,
        AtasanTakLangsung: null,
        Tujuan: '',
        Wewenang: '',
        TanggungJawab: '',
        UraianTugas: '',
        JumlahBawahanLangsung: 0,
        PosisiBawahanLangsung: '',
        JumlahBawahanTakLangsung: 0,
        PosisiBawahanTakLangsung: '',
        Internal: '',
        Eksternal: '',
        Pendidikan: '',
        Jurusan: '',
        UsiaMax: '',
        UsiaMin: '',
        KeterampilanUmum: '',
        PengetahuanKhusus: '',
        KeterampilanKhusus: '',
        Sertifikasi: '',
        PengalamanKerja: '',
        DalamKantor: '',
        DalamLapangan: '',
        LokasiLainnya: '',
        KompetensiPrilaku: [
            {
                Uraian: 'Pengetahuan Bisnis & Fungsional',
                Point: 0
            },
            {
                Uraian: 'Orientasi Pada Hasil',
                Point: 0
            },
            {
                Uraian: 'Kerjasama',
                Point: 0
            },
            {
                Uraian: 'Fokus Pada Customer',
                Point: 0
            },
            {
                Uraian: 'Jiwa Kepemimpinan',
                Point: 0
            },
        ],
        KompetensiTeknik: [
            {
                Uraian: '',
                Point: 0
            },
            {
                Uraian: '',
                Point: 0
            },
            {
                Uraian: '',
                Point: 0
            },
            {
                Uraian: '',
                Point: 0
            },
            {
                Uraian: '',
                Point: 0
            },
        ],
    }

    componentDidMount() {
        const { JobdeskActionId } = this.props
        if (JobdeskActionId !== null) {
            this.props.get_Jobdesk_Data(JobdeskActionId)
        }
        this.props.get_Jobdesk_Master_User()
    }

    componentDidUpdate(prevProps) {
        if (this.props.JobdeskActionId !== prevProps.JobdeskActionId) {
            this.props.get_Jobdesk_Data(this.props.JobdeskActionId)
        }
        if (this.props.JobdeskData !== prevProps.JobdeskData) {
            const { JobdeskData, JobdeskListPrilaku, JobdeskListTeknik } = this.props

            this.setState({
                NamaJabatan: JobdeskData?.NamaJabatan ? JobdeskData.NamaJabatan : '',
                Level: JobdeskData?.LevelNama ? JobdeskData.LevelNama : '',
                Departemen: JobdeskData?.DepartemenNama ? JobdeskData.DepartemenNama : '',
                StatusKaryawan: JobdeskData?.StatusKaryawan ? JobdeskData.StatusKaryawan : '',
                BulanBuka: JobdeskData ? JobdeskData.BulanBuka : '',
                AtasanLangsung: JobdeskData?.AtasanLangsungNama ? JobdeskData.AtasanLangsungNama : '',
                AtasanTakLangsung: JobdeskData?.AtasanTakLangsungNama ? JobdeskData.AtasanTakLangsungNama : '',
                Tujuan: JobdeskData?.Tujuan ? JobdeskData.Tujuan : '',
                Wewenang: JobdeskData?.Wewenang ? JobdeskData.Wewenang : '',
                TanggungJawab: JobdeskData?.TanggungJawab ? JobdeskData.TanggungJawab : '',
                UraianTugas: JobdeskData?.UraianTugas ? JobdeskData.UraianTugas : '',
                JumlahBawahanLangsung: JobdeskData?.JumlahBawahanLangsung ? JobdeskData.JumlahBawahanLangsung : 0,
                PosisiBawahanLangsung: JobdeskData?.PosisiBawahanLangsung ? JobdeskData.PosisiBawahanLangsung : '',
                JumlahBawahanTakLangsung: JobdeskData?.JumlahBawahanTakLangsung ? JobdeskData.JumlahBawahanTakLangsung : 0,
                PosisiBawahanTakLangsung: JobdeskData?.PosisiBawahanTakLangsung ? JobdeskData.PosisiBawahanTakLangsung : '',
                Internal: JobdeskData?.Internal ? JobdeskData.Internal : '',
                Eksternal: JobdeskData?.Eksternal ? JobdeskData.Eksternal : '',
                Pendidikan: JobdeskData?.Pendidikan ? JobdeskData.Pendidikan : '',
                Jurusan: JobdeskData?.Jurusan ? JobdeskData.Jurusan : '',
                UsiaMax: JobdeskData?.UsiaMax ? JobdeskData.UsiaMax : '',
                UsiaMin: JobdeskData?.UsiaMin ? JobdeskData.UsiaMin : '',
                KeterampilanUmum: JobdeskData?.KeterampilanUmum ? JobdeskData.KeterampilanUmum : '',
                PengetahuanKhusus: JobdeskData?.PengetahuanKhusus ? JobdeskData.PengetahuanKhusus : '',
                KeterampilanKhusus: JobdeskData?.KeterampilanKhusus ? JobdeskData.KeterampilanKhusus : '',
                Sertifikasi: JobdeskData?.Sertifikasi ? JobdeskData.Sertifikasi : '',
                PengalamanKerja: JobdeskData?.PengalamanKerja ? JobdeskData.PengalamanKerja : '',
                DalamKantor: JobdeskData?.DalamKantor ? JobdeskData.DalamKantor : '',
                DalamLapangan: JobdeskData?.DalamLapangan ? JobdeskData.DalamLapangan : '',
                LokasiLainnya: JobdeskData?.LokasiLainnya ? JobdeskData.LokasiLainnya : '',
                KompetensiPrilaku: JobdeskListPrilaku,
                KompetensiTeknik: JobdeskListTeknik,
            })
        }
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    setAtasanLangsung = (NewData) => {
        this.setState({ AtasanLangsung: NewData })
    }

    setAtasanTakLangsung = (NewData) => {
        this.setState({ AtasanTakLangsung: NewData })
    }

    AddDetailList = () => {
        const KompetensiPrilaku = this.state.KompetensiPrilaku

        const isThereZero = KompetensiPrilaku.find(item => item.Uraian === '')
        const isThereZero2 = KompetensiPrilaku.find(item => item.Point === 0)

        if (KompetensiPrilaku.length === 0 ||
            (KompetensiPrilaku[KompetensiPrilaku.length - 1].Uraian &&
                KompetensiPrilaku[KompetensiPrilaku.length - 1].Point !== 0 &&
                !isThereZero && !isThereZero2)
        ) {
            const newData = { Uraian: '', Point: 0 }
            KompetensiPrilaku.push(newData)
            this.setState({ KompetensiPrilaku: KompetensiPrilaku })
        } else {
            this.props.Create_Warning_Messages(null, 'mohon lengkapi proses pengisian terlebih dahulu')
        }
    }

    DeleteDetailList = (Index) => {
        const KompetensiPrilaku = this.state.KompetensiPrilaku
        if (KompetensiPrilaku.length > 5) {
            if (Index > 4) {
                KompetensiPrilaku.splice(Index, 1)
                this.setState({ KompetensiPrilaku: KompetensiPrilaku })
            } else {
                this.props.Create_Warning_Messages(null, 'point 1-5 tidak bisa dihapus')
            }
        } else {
            this.props.Create_Warning_Messages(null, 'Minimal 5')
        }
    }

    OnChangeMapItem = (e, Index, Order) => {
        const KompetensiPrilaku = this.state.KompetensiPrilaku
        if (Order === 1) {
            KompetensiPrilaku[Index].Uraian = e.target.value
        } else {

        }
        this.setState({ KompetensiPrilaku: KompetensiPrilaku })
    }

    onChangeCheckboxMap = (e, index, value) => {
        const KompetensiPrilaku = this.state.KompetensiPrilaku
        KompetensiPrilaku[index].Point = value
        this.setState({ KompetensiPrilaku: KompetensiPrilaku })
    }

    AddDetailListTeknik = () => {
        const KompetensiTeknik = this.state.KompetensiTeknik

        const isThereZero = KompetensiTeknik.find(item => item.Uraian === '')
        const isThereZero2 = KompetensiTeknik.find(item => item.Point === 0)

        if (KompetensiTeknik.length === 0 ||
            (KompetensiTeknik[KompetensiTeknik.length - 1].Uraian &&
                KompetensiTeknik[KompetensiTeknik.length - 1].Point !== 0 &&
                !isThereZero && !isThereZero2)
        ) {
            const newData = { Uraian: '', Point: 0 }
            KompetensiTeknik.push(newData)
            this.setState({ KompetensiTeknik: KompetensiTeknik })
        } else {
            this.props.Create_Warning_Messages(null, 'mohon lengkapi proses pengisian terlebih dahulu')
        }
    }

    DeleteDetailListTeknik = (Index) => {
        const KompetensiTeknik = this.state.KompetensiTeknik
        if (KompetensiTeknik.length > 5) {
            KompetensiTeknik.splice(Index, 1)
            this.setState({ KompetensiTeknik: KompetensiTeknik })
        } else {
            this.props.Create_Warning_Messages(null, 'Minimal 5')
        }
    }

    OnChangeMapItemTeknik = (e, Index, Order) => {
        const KompetensiTeknik = this.state.KompetensiTeknik
        if (Order === 1) {
            KompetensiTeknik[Index].Uraian = e.target.value
        } else {

        }
        this.setState({ KompetensiTeknik: KompetensiTeknik })
    }

    onChangeCheckboxMapTeknik = (e, index, value) => {
        const KompetensiTeknik = this.state.KompetensiTeknik
        KompetensiTeknik[index].Point = value
        this.setState({ KompetensiTeknik: KompetensiTeknik })
    }


    formOnSubmit = (Status) => {

        const KompetensiPrilaku = this.state.KompetensiPrilaku
        const KompetensiTeknik = this.state.KompetensiTeknik
        const isThereZero = KompetensiPrilaku.find(item => item.Point === 0)
        const isThereZero2 = KompetensiTeknik.find(item => item.Point === 0)

        if (isThereZero || isThereZero2) {
            this.props.Create_Warning_Messages(null, 'tidak boleh ada yang kosong')
        } else {
            const { JobdeskData } = this.props

            this.props.Update_Jobdesk(
                this.props.JobdeskActionId,
                this.state.NamaJabatan,
                JobdeskData.LevelId,
                JobdeskData.DepartemenId,
                this.state.StatusKaryawan,
                this.state.BulanBuka,
                this.state.AtasanLangsung ? typeof this.state.AtasanLangsung === 'object' ? this.state.AtasanLangsung.Value : JobdeskData.AtasanLangsung : '',
                this.state.AtasanTakLangsung ? typeof this.state.AtasanTakLangsung === 'object' ? this.state.AtasanTakLangsung.Value : JobdeskData.AtasanTakLangsung : '',
                this.state.Tujuan,
                this.state.Wewenang,
                this.state.TanggungJawab,
                this.state.UraianTugas,
                this.state.JumlahBawahanLangsung,
                this.state.PosisiBawahanLangsung,
                this.state.JumlahBawahanTakLangsung,
                this.state.PosisiBawahanTakLangsung,
                this.state.Internal,
                this.state.Eksternal,
                this.state.Pendidikan,
                this.state.Jurusan,
                this.state.UsiaMax,
                this.state.UsiaMin,
                this.state.KeterampilanUmum,
                this.state.PengetahuanKhusus,
                this.state.KeterampilanKhusus,
                this.state.Sertifikasi,
                this.state.PengalamanKerja,
                this.state.DalamKantor,
                this.state.DalamLapangan,
                this.state.LokasiLainnya,
                Status,
                KompetensiPrilaku,
                KompetensiTeknik,
            )
        }
    }

    render() {

        // const JobdeskData = this.props.JobdeskData
        // const JobdeskListPrilaku = this.props.JobdeskListPrilaku
        // const JobdeskListTeknik = this.props.JobdeskListTeknik
        const isJobdeskActionGetDataLoading = this.props.isJobdeskActionGetDataLoading
        const isJobdeskGetMasterUserLoading = this.props.isJobdeskGetMasterUserLoading
        const JobdeskGetMasterUser = this.props.JobdeskGetMasterUser

        const {
            NamaJabatan,
            Level,
            Departemen,
            StatusKaryawan,
            // BulanBuka,
            AtasanLangsung,
            AtasanTakLangsung,
            Tujuan,
            Wewenang,
            TanggungJawab,
            UraianTugas,
            JumlahBawahanLangsung,
            PosisiBawahanLangsung,
            JumlahBawahanTakLangsung,
            PosisiBawahanTakLangsung,
            Internal,
            Eksternal,
            Pendidikan,
            Jurusan,
            UsiaMax,
            UsiaMin,
            KeterampilanUmum,
            PengetahuanKhusus,
            KeterampilanKhusus,
            Sertifikasi,
            PengalamanKerja,
            // DalamKantor,
            // DalamLapangan,
            LokasiLainnya,
            KompetensiPrilaku,
            KompetensiTeknik,
        } = this.state

        const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }
        const st_textfield_disable = { marginTop: '10px', marginBottom: '10px', width: '100%', backgroundColor: '#E0E0E0' }
        const st_TextSmall = { width: '40%', margin: '0 1% 0 0', marginTop: '10px', marginBottom: '10px' }
        // const st_TextSmall_disable = { width: '20%', margin: '0 1% 0 0', backgroundColor: '#E0E0E0' }
        const st_icon = { color: 'grey', fontSize: '180%', margin: '0 0 0 1%', cursor: 'pointer' }

        const HeadData1 = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Description',
                width: '35%',
            },
            {
                Nama: '1',
                width: '5%',
            },
            {
                Nama: '2',
                width: '5%',
            },
            {
                Nama: '3',
                width: '5%',
            },
            {
                Nama: 'Add',
                width: '10%',
            },
            {
                Nama: 'Delete',
                width: '10%',
            },
        ]

        const BodyData1 = KompetensiTeknik.map((item, index) => {
            return {
                index: index + 1,
                Pertanyaan: (
                    <TextField
                        value={KompetensiTeknik[index].Uraian}
                        style={{ width: '90%' }}
                        required
                        label={`Description ${index + 1}`}
                        onChange={(e) => this.OnChangeMapItemTeknik(e, index, 1)}
                    />
                ),
                Point1: (
                    <Checkbox
                        checked={KompetensiTeknik[index].Point === 1 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMapTeknik(e, index, 1)}
                    />
                ),
                Point2: (
                    <Checkbox
                        checked={KompetensiTeknik[index].Point === 2 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMapTeknik(e, index, 2)}
                    />
                ),
                Point3: (
                    <Checkbox
                        checked={KompetensiTeknik[index].Point === 3 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMapTeknik(e, index, 3)}
                    />
                ),
                Tambah: (
                    <AddCircleRoundedIcon
                        onClick={this.AddDetailListTeknik}
                        style={st_icon}
                    />
                ),
                Hapus: (
                    <DeleteOutlineRoundedIcon
                        onClick={(e) => this.DeleteDetailListTeknik(index)}
                        style={st_icon}
                    />
                ),
            }
        })

        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Description',
                width: '35%',
            },
            {
                Nama: '1',
                width: '5%',
            },
            {
                Nama: '2',
                width: '5%',
            },
            {
                Nama: '3',
                width: '5%',
            },
            {
                Nama: 'Add',
                width: '10%',
            },
            {
                Nama: 'Delete',
                width: '10%',
            },
        ]

        const BodyData = KompetensiPrilaku.map((item, index) => {
            return {
                index: index + 1,
                Pertanyaan: (
                    <TextField
                        value={KompetensiPrilaku[index].Uraian}
                        style={{ width: '90%', backgroundColor: index < 5 ? '#E0E0E0' : 'white' }}
                        disabled={index < 5 ? true : false}
                        required={index < 5 ? false : true}
                        label={`Description ${index + 1}`}
                        onChange={(e) => this.OnChangeMapItem(e, index, 1)}
                    />
                ),
                Point1: (
                    <Checkbox
                        checked={KompetensiPrilaku[index].Point === 1 ? true : false}
                        disabled={index < 5 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap(e, index, 1)}
                    />
                ),
                Point2: (
                    <Checkbox
                        checked={KompetensiPrilaku[index].Point === 2 ? true : false}
                        disabled={index < 5 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap(e, index, 2)}
                    />
                ),
                Point3: (
                    <Checkbox
                        checked={KompetensiPrilaku[index].Point === 3 ? true : false}
                        disabled={index < 5 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap(e, index, 3)}
                    />
                ),
                Tambah: (
                    <AddCircleRoundedIcon
                        onClick={this.AddDetailList}
                        style={st_icon}
                    />
                ),
                Hapus: (
                    <DeleteOutlineRoundedIcon
                        onClick={(e) => this.DeleteDetailList(index)}
                        style={st_icon}
                    />
                ),
            }
        })




        const OpPendidikan = [
            {
                Option: 'SMA',
                value: 'SMA',
            },
            {
                Option: 'D3',
                value: 'D3',
            },
            {
                Option: 'D4',
                value: 'D4',
            },
            {
                Option: 'S1',
                value: 'S1'
            },
            {
                Option: 'S2',
                value: 'S2'
            },
            {
                Option: 'S3',
                value: 'S3'
            },
        ]

        return (
            <Fragment>
                {isJobdeskActionGetDataLoading || isJobdeskGetMasterUserLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5, 6, 7].map((item) =>
                            <Skeleton
                                height={'8vh'}
                                key={`PRForm_Skeleton_${item}`}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <h2>
                            Job Description
                        </h2>

                        <h3>
                            Position Identity
                        </h3>
                        < TextField
                            style={st_textfield_disable}
                            variant='outlined'
                            type='text'
                            label='Name of Position'
                            name='NamaJabatan'
                            disabled
                            value={NamaJabatan}
                        />
                        < TextField
                            style={st_textfield_disable}
                            variant='outlined'
                            type='text'
                            label='Level'
                            name='Level'
                            disabled
                            value={Level}
                        // onChange={this.onChangeField}
                        />
                        < TextField
                            style={st_textfield_disable}
                            variant='outlined'
                            type='text'
                            label='Department'
                            name='Departemen'
                            disabled
                            value={Departemen}
                        // value={Departemen}
                        // onChange={this.onChangeField}
                        />
                        < TextField
                            style={st_textfield_disable}
                            variant='outlined'
                            type='text'
                            label='Employee Type'
                            name='TipePegawai'
                            disabled
                            value={StatusKaryawan}
                        // value={Level}
                        // onChange={this.onChangeField}
                        />
                        {/* < TextField
                                style={st_textfield_disable}
                                variant='outlined'
                                type='text'
                                label='Month'
                                name='Bulan'
                                disabled
                                value={BulanBuka}
                            // value={Level}
                            // onChange={this.onChangeField}
                            /> */}
                        {/* < TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Direct Supervisor'
                                name='AtasanLangsung'
                                value={AtasanLangsung}
                                onChange={this.onChangeField}
                            /> */}

                        <Autocomplete
                            value={AtasanLangsung}
                            onChange={(event, newParticipan) => {
                                this.setAtasanLangsung(newParticipan ? newParticipan : null)
                            }}

                            options={JobdeskGetMasterUser}
                            // getOptionLabel={option => option.Name}
                            getOptionLabel={option => typeof option === 'string' ? option : option.Name}
                            style={st_textfield}
                            disabled={isJobdeskGetMasterUserLoading ? true : false}
                            renderInput={(params) => (
                                <TextField
                                    value={AtasanLangsung ? AtasanLangsung.Value : ''}
                                    name='Participan'
                                    label={isJobdeskGetMasterUserLoading ? <CircularProgress /> : 'Direct Supervisor'}
                                    variant="outlined"
                                    disabled={isJobdeskGetMasterUserLoading ? true : false}
                                    {...params}
                                />
                            )}
                        />
                        {/* < TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Indirect Supervisor'
                                name='AtasanTakLangsung'
                                value={AtasanTakLangsung}
                                onChange={this.onChangeField}
                            /> */}
                        <Autocomplete
                            value={AtasanTakLangsung}
                            onChange={(event, newParticipan) => {
                                this.setAtasanTakLangsung(newParticipan ? newParticipan : null)
                            }}

                            options={JobdeskGetMasterUser}
                            // getOptionLabel={option => option.Name}
                            getOptionLabel={option => typeof option === 'string' ? option : option.Name}
                            style={st_textfield}
                            disabled={isJobdeskGetMasterUserLoading ? true : false}
                            renderInput={(params) => (
                                <TextField
                                    value={AtasanTakLangsung ? AtasanTakLangsung.Value : ''}
                                    name='Participan'
                                    label={isJobdeskGetMasterUserLoading ? <CircularProgress /> : 'Indirect Supervisor'}
                                    variant="outlined"
                                    disabled={isJobdeskGetMasterUserLoading ? true : false}
                                    {...params}
                                />
                            )}
                        />
                        <h3>
                            Job Purpose
                        </h3>
                        < TextField
                            style={st_textfield}
                            variant='outlined'
                            multiline
                            rows={8}
                            type='text'
                            label='Job Purpose'
                            name='Tujuan'
                            value={Tujuan}
                            required
                            onChange={this.onChangeField}
                        />
                        <h3>
                            Authority & Main Responsibility
                        </h3>
                        < TextField
                            style={st_textfield}
                            variant='outlined'
                            multiline
                            rows={8}
                            type='text'
                            label='Authority'
                            name='Wewenang'
                            value={Wewenang}
                            onChange={this.onChangeField}
                        />
                        < TextField
                            style={st_textfield}
                            variant='outlined'
                            multiline
                            rows={8}
                            type='text'
                            label='Main Responsibility'
                            name='TanggungJawab'
                            value={TanggungJawab}
                            required
                            onChange={this.onChangeField}
                        />
                        <h3>
                            Task Description
                        </h3>
                        < TextField
                            style={st_textfield}
                            variant='outlined'
                            multiline
                            rows={12}
                            type='text'
                            label='Task Description'
                            name='UraianTugas'
                            value={UraianTugas}
                            required
                            onChange={this.onChangeField}
                        />
                        <h3>
                            Subordinate
                        </h3>
                        < TextField
                            style={st_TextSmall}
                            variant='outlined'
                            type='number'
                            label='Number of Direct Subordinate'
                            name='JumlahBawahanLangsung'
                            value={JumlahBawahanLangsung}
                            onChange={this.onChangeField}
                        />
                        < TextField
                            style={st_TextSmall}
                            variant='outlined'
                            type='text'
                            label='Direct Subordinate Position'
                            name='PosisiBawahanLangsung'
                            value={PosisiBawahanLangsung}
                            onChange={this.onChangeField}
                        />
                        < TextField
                            style={st_TextSmall}
                            variant='outlined'
                            type='number'
                            label='Number of Indirect Subordinate'
                            name='JumlahBawahanTakLangsung'
                            value={JumlahBawahanTakLangsung}
                            onChange={this.onChangeField}
                        />
                        < TextField
                            style={st_TextSmall}
                            variant='outlined'
                            type='text'
                            label='Indirect Subordinate Position'
                            name='PosisiBawahanTakLangsung'
                            value={PosisiBawahanTakLangsung}
                            onChange={this.onChangeField}
                        />
                        <h2>
                            Working Relationship
                        </h2>
                        < TextField
                            style={st_textfield}
                            variant='outlined'
                            type='text'
                            label='Internal Party'
                            name='Internal'
                            value={Internal}
                            onChange={this.onChangeField}
                        />
                        < TextField
                            style={st_textfield}
                            variant='outlined'
                            type='text'
                            label='External Party'
                            name='Eksternal'
                            value={Eksternal}
                            onChange={this.onChangeField}
                        />
                        <h3>
                            Minimum Of Job Qualification
                        </h3>
                        <FormControl
                            style={st_textfield}
                            variant="filled"
                            required
                        >
                            <InputLabel
                                shrink
                            >
                                Educational
                            </InputLabel>
                            <Select
                                native
                                style={{ backgroundColor: 'white' }}
                                onChange={(e) => this.onChangeField(e)}
                                label="Educational"
                                name='Pendidikan'
                                value={Pendidikan}
                            >
                                <option value="" disabled> -- select an option -- </option>
                                {OpPendidikan.map((option, index) =>
                                    <option key={`Pendidikan_${index}`} value={option.value}>{option.Option}</option>
                                )}
                            </Select>
                        </FormControl>
                        < TextField
                            style={st_textfield}
                            variant='outlined'
                            type='text'
                            label='Major/ Faculty'
                            name='Jurusan'
                            value={Jurusan}
                            onChange={this.onChangeField}
                        />
                        < TextField
                            style={st_TextSmall}
                            variant='outlined'
                            type='number'
                            label='Minimum Age'
                            name='UsiaMin'
                            value={UsiaMin}
                            onChange={this.onChangeField}
                        />
                        < TextField
                            style={st_TextSmall}
                            variant='outlined'
                            type='number'
                            label='Maximum Age'
                            name='UsiaMax'
                            value={UsiaMax}
                            onChange={this.onChangeField}
                        />
                        < TextField
                            style={st_textfield}
                            variant='outlined'
                            type='text'
                            label='General Skill'
                            name='KeterampilanUmum'
                            value={KeterampilanUmum}
                            onChange={this.onChangeField}
                        />
                        < TextField
                            style={st_textfield}
                            variant='outlined'
                            type='text'
                            label='Special Knowledge'
                            name='PengetahuanKhusus'
                            value={PengetahuanKhusus}
                            onChange={this.onChangeField}
                        />
                        < TextField
                            style={st_textfield}
                            variant='outlined'
                            multiline
                            rows={8}
                            type='text'
                            label='Special Skill'
                            name='KeterampilanKhusus'
                            value={KeterampilanKhusus}
                            onChange={this.onChangeField}
                        />
                        < TextField
                            style={st_textfield}
                            variant='outlined'
                            type='text'
                            label='Certification'
                            name='Sertifikasi'
                            value={Sertifikasi}
                            onChange={this.onChangeField}
                        />
                        < TextField
                            style={st_textfield}
                            variant='outlined'
                            type='number'
                            label='Minimum Working Experience (Year)'
                            name='PengalamanKerja'
                            value={PengalamanKerja}
                            onChange={this.onChangeField}
                        />
                        <h3>
                            Work Location
                        </h3>
                        <FormControl
                            style={st_textfield}
                            variant="filled"
                        >
                            <InputLabel
                                shrink
                            >
                                Work Location
                            </InputLabel>
                            <Select
                                native
                                style={{ backgroundColor: 'white' }}
                                onChange={(e) => this.onChangeField(e)}
                                label="Work Location"
                                name='LokasiLainnya'
                                value={LokasiLainnya}
                            >
                                <option value="" disabled> -- select an option -- </option>
                                {['Head Office', 'Branch',].map((option, index) =>
                                    <option key={`WorkLocation_${index}`} value={option}>{option}</option>
                                )}
                            </Select>
                        </FormControl>
                        {/* < TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Inside Office'
                                name='DalamKantor'
                                value={DalamKantor}
                                onChange={this.onChangeField}
                            />
                            < TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='In the Field'
                                name='DalamLapangan'
                                value={DalamLapangan}
                                onChange={this.onChangeField}
                            />
                            < TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Other Locations'
                                name='LokasiLainnya'
                                value={LokasiLainnya}
                                onChange={this.onChangeField}
                            /> */}
                        <h3>
                            Behaviour Competency
                        </h3>
                        {KompetensiPrilaku.length === 0 ?
                            <AddCircleRoundedIcon
                                onClick={this.AddDetailList}
                                style={st_icon}
                            /> :
                            <Fragment></Fragment>}
                        <CoTable
                            TableId={'Jobdesk_KompetensiPrilaku'}
                            HeadData={HeadData}
                            Data={BodyData}
                        // isHeadLoading={isPertanyaanLoading}
                        // isBodyLoading={isPertanyaanLoading}
                        />

                        <h3>
                            Technical Competency
                        </h3>
                        {KompetensiTeknik.length === 0 ?
                            <AddCircleRoundedIcon
                                onClick={this.AddDetailListTeknik}
                                style={st_icon}
                            /> :
                            <Fragment></Fragment>}

                        <CoTable
                            TableId={'Jobdesk_KompetensiTeknik'}
                            HeadData={HeadData1}
                            Data={BodyData1}
                        // isHeadLoading={isPertanyaanLoading}
                        // isBodyLoading={isPertanyaanLoading}
                        />
                        <center>
                            <Button
                                variant='contained'
                                color='secondary'
                                style={{ width: '60%', margin: '2% 0 0 0' }}
                                onClick={() => this.formOnSubmit(false)}
                            >
                                Save as Draft
                            </Button>
                            <Button
                                variant='contained'
                                style={{ width: '60%', margin: '2% 0 5% 0' }}
                                onClick={() => this.formOnSubmit(true)}
                            >
                                Save
                            </Button>
                        </center>
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    User: state.Auth.User,
    JobdeskActionId: state.Jobdesk.JobdeskActionId,
    isJobdeskActionGetDataLoading: state.Jobdesk.isJobdeskActionGetDataLoading,
    JobdeskData: state.Jobdesk.JobdeskData,
    JobdeskListPrilaku: state.Jobdesk.JobdeskListPrilaku,
    JobdeskListTeknik: state.Jobdesk.JobdeskListTeknik,
    isJobdeskGetMasterUserLoading: state.Jobdesk.isJobdeskGetPertanyaanLoading,
    JobdeskGetMasterUser: state.Jobdesk.JobdeskGetMasterUser,
})

export default connect(mapStateToProps, { Create_Warning_Messages, get_Jobdesk_Data, get_Jobdesk_Master_User, Update_Jobdesk })(Jobdesk)