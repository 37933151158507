import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Load_Lokasi_List, get_Lokasi_Action_Id } from '../../../../Store/Actions/Setting.Action'

import CoTable from '../../../Containers/Table'
import GenericModals from '../../../Containers/GenericModals'

import Add from './Modal/Lokasi/Add'
import View from './Modal/Lokasi/View'
import Delete from './Modal/Lokasi/Delete'

class SettingLokasi extends React.Component {
    componentDidMount() {
        this.props.Load_Lokasi_List()
    }
    onClickActionId(Id) {
        this.props.get_Lokasi_Action_Id(Id)
    }
    render() {
        const LokasiList = this.props.LokasiList
        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Id',
                width: '15%',
            },
            {
                Nama: 'Nama',
                width: '30%',
            },
            {
                Nama: 'Deskripsi',
                width: '30%',
            },
            {
                Nama: 'View',
                width: '10%',
            },
            {
                Nama: 'Delete',
                width: '10%',
            },
        ]
        const BodyData = LokasiList ? LokasiList.map((item, index) => {
            return {
                index: index + 1,
                Id: item.Id,
                Name: item.Nama,
                Deskripsi: item.Deskripsi,
                View: (
                    <GenericModals
                        size='xl'
                        header='View Lokasi'
                        body={<View />}
                        Buttoncolor='primary'
                        ButtononClickeven={() => this.onClickActionId(item.Id)}
                        Buttonlabel={'view'}
                    />
                ),
                Delete: (
                    <GenericModals
                        size='m'
                        header='Delete Form'
                        body={<Delete />}
                        Buttoncolor='error'
                        ButtononClickeven={() => this.onClickActionId(item.Id)}
                        Buttonlabel={'Delete'}
                    />
                )
            }
        }) : []
        const isLokasiLoading = this.props.isLokasiLoading
        return (
            <Fragment>
                <h2>
                    Lokasi Setting
                </h2>

                <center>
                    <GenericModals
                        size='m'
                        header='Tambah Lokasi'
                        body={<Add />}
                        Buttoncolor='primary'
                        Buttonstyle={{ width: '90%' }}
                        ButtononClickeven={() => null}
                        Buttonlabel={'+ Lokasi'}
                    />
                </center>

                <CoTable
                    TableId='LokasiSetting'
                    HeadData={HeadData}
                    isHeadLoading={HeadData ? false : true}
                    Data={BodyData}
                    isBodyLoading={isLokasiLoading}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isLokasiLoading: state.Setting.isLokasiLoading,
    LokasiList: state.Setting.LokasiList,
})

export default connect(mapStateToProps, { Load_Lokasi_List, get_Lokasi_Action_Id })(SettingLokasi)
