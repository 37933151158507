import React, { Fragment } from "react";

import { connect } from "react-redux";

import {
  get_List_Personality_Test,
  get_Job_Action_Id,
} from "../../../../Store/Actions/Job.Action";

import { Collapse } from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import GenericModals from "../../../Containers/GenericModals";
import CoTable from "../../../Containers/Table";
import SubmitPersonaltyTest from "./Modal/PersonaltyTest/SubmitPersonaltyTest";
import Detail from "./Modal/PersonaltyTest/Detail";

class PersonaltyTest extends React.Component {
  state = {
    MenungguOpen: true,
    SelesaiOpen: false,
  };

  componentDidMount() {
    this.props.get_List_Personality_Test(this.props.User.Email);
  }

  onClickActionId(Id) {
    this.props.get_Job_Action_Id(Id);
  }

  handleMenungguOpen = () => {
    this.setState({ MenungguOpen: !this.state.MenungguOpen });
  };
  handleSelesaiOpen = () => {
    this.setState({ SelesaiOpen: !this.state.SelesaiOpen });
  };

  render() {
    const isListPersonalityTestLoading =
      this.props.isListPersonalityTestLoading;
    const ListPersonalityTest = this.props.ListPersonalityTest;

    const HeadData = [
      {
        Nama: "No",
        width: "5%",
        KeyName: "index",
        SortingType: "Number",
      },
      {
        Nama: "No Registrasi",
        width: "10%",
        KeyName: "Id",
        SortingType: "Number",
      },
      {
        Nama: "Nama",
        width: "10%",
        KeyName: "BiodataNama",
      },
      {
        Nama: "Posisi",
        width: "10%",
        KeyName: "JobListNama",
      },
      {
        Nama: "Submit",
        width: "10%",
      },
    ];

    const FilterdListPersonalityTest = ListPersonalityTest
      ? ListPersonalityTest.filter(
          (item, index) => item.PTId === null
          // true
        )
      : [];

    const BodyData = FilterdListPersonalityTest.map((item, index) => {
      return {
        index: {
          value: index + 1,
        },
        Id: {
          value: item.Id,
        },
        BiodataNama: {
          value: item.BiodataNama,
        },
        JobListNama: {
          value: item.JobListNama,
        },
        Detail: (
          <GenericModals
            size="s"
            Buttonvariant="contained"
            header="Submit Personality Test"
            body={<SubmitPersonaltyTest />}
            Buttoncolor="primary"
            ButtononClickeven={() => this.onClickActionId(item.Id)}
            Buttonlabel={"Submit"}
          />
        ),
      };
    });

    const HeadData1 = [
      {
        Nama: "No",
        width: "5%",
        KeyName: "index",
        SortingType: "Number",
      },
      {
        Nama: "No Registrasi",
        width: "10%",
        KeyName: "Id",
        SortingType: "Number",
      },
      {
        Nama: "Nama",
        width: "10%",
        KeyName: "BiodataNama",
      },
      {
        Nama: "Posisi",
        width: "10%",
        KeyName: "JobListNama",
      },
      {
        Nama: "Detail",
        width: "10%",
      },
    ];

    const FilterdListPersonalityTest1 = ListPersonalityTest
      ? ListPersonalityTest.filter((item, index) => item.PTId !== null)
      : [];

    const BodyData1 = FilterdListPersonalityTest1.map((item, index) => {
      return {
        index: {
          value: index + 1,
        },
        Id: {
          value: item.Id,
        },
        BiodataNama: {
          value: item.BiodataNama,
        },
        JobListNama: {
          value: item.JobListNama,
        },
        Detail: (
          <GenericModals
            size="l"
            header="Detail"
            body={<Detail />}
            Buttoncolor="primary"
            ButtononClickeven={() => this.onClickActionId(item.Id)}
            Buttonlabel={"Detail"}
          />
        ),
      };
    });

    const { MenungguOpen, SelesaiOpen } = this.state;

    const RowDataInOnePage = 5;

    return (
      <Fragment>
        <h2>Personality Test</h2>
        <h3
          onClick={this.handleMenungguOpen}
          style={{
            display: "flex",
            alignContent: "center",
            verticalAlign: "center",
            cursor: "pointer",
          }}
        >
          Menunggu{MenungguOpen ? <ExpandLess /> : <ExpandMore />}
        </h3>
        <Collapse in={MenungguOpen} timeout="auto" unmountOnExit>
          <CoTable
            TableId={"PersonaltyTest_Menunggu"}
            HeadData={HeadData}
            Data={BodyData}
            isHeadLoading={isListPersonalityTestLoading}
            isBodyLoading={isListPersonalityTestLoading}
            isPagination={BodyData.length > RowDataInOnePage ? true : false}
            MaxRowOnPage={RowDataInOnePage}
            isPaginationLoading={isListPersonalityTestLoading}
            PaginationCounter={
              BodyData.length / RowDataInOnePage >
              Math.floor(BodyData.length / RowDataInOnePage)
                ? Math.floor(BodyData.length / RowDataInOnePage) + 1
                : BodyData.length / RowDataInOnePage
            }
            isHeading={true}
          />
        </Collapse>
        <div style={{ margin: "0 0 5% 0" }}>
          <h3
            onClick={this.handleSelesaiOpen}
            style={{
              display: "flex",
              alignContent: "center",
              verticalAlign: "center",
              cursor: "pointer",
            }}
          >
            Selesai{SelesaiOpen ? <ExpandLess /> : <ExpandMore />}
          </h3>
          <Collapse in={SelesaiOpen} timeout="auto" unmountOnExit>
            <CoTable
              TableId={"PersonaltyTest_Selesai"}
              HeadData={HeadData1}
              Data={BodyData1}
              isHeadLoading={isListPersonalityTestLoading}
              isBodyLoading={isListPersonalityTestLoading}
              isPagination={BodyData1.length > RowDataInOnePage ? true : false}
              MaxRowOnPage={RowDataInOnePage}
              isPaginationLoading={isListPersonalityTestLoading}
              PaginationCounter={
                BodyData1.length / RowDataInOnePage >
                Math.floor(BodyData1.length / RowDataInOnePage)
                  ? Math.floor(BodyData1.length / RowDataInOnePage) + 1
                  : BodyData1.length / RowDataInOnePage
              }
              isHeading={true}
            />
          </Collapse>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isListPersonalityTestLoading: state.Job.isListPersonalityTestLoading,
  ListPersonalityTest: state.Job.ListPersonalityTest,
  User: state.Auth.User,
});

export default connect(mapStateToProps, {
  get_List_Personality_Test,
  get_Job_Action_Id,
})(PersonaltyTest);
