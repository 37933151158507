import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_List_Personality_Test_Detail, get_List_Personality_Test_Detail_JobList } from '../../../../../../Store/Actions/Job.Action'

import { Skeleton } from '@mui/material'

class Detail extends React.Component {
    componentDidMount() {
        const isJobListId = this.props.isJobListId ? true : false

        const { JobActionId, AccountActionId } = this.props
        if (isJobListId) {
            this.props.get_List_Personality_Test_Detail_JobList(JobActionId, AccountActionId)
        } else {
            this.props.get_List_Personality_Test_Detail(JobActionId)
        }
    }
    render() {
        const isListPersonalityTestDetailLoading = this.props.isListPersonalityTestDetailLoading
        const ListPersonalityTestDetail = this.props.ListPersonalityTestDetail

        const st_Detail = { margin: '0 0 0 2vw' }
        return (
            <Fragment>
                {isListPersonalityTestDetailLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5,].map((item) =>
                            <Skeleton
                                key={`DetailMenunggu_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <h2>PersonaltyTest</h2>
                        <h3>
                            Dominan Personality:
                        </h3>
                        <center>
                            <h1>
                                {ListPersonalityTestDetail?.Nilai1 ? ListPersonalityTestDetail.Nilai1 : ''}
                            </h1>
                        </center>
                        <h3>
                            Secondary Personality:
                        </h3>
                        <center>
                            <h1>
                                {ListPersonalityTestDetail?.Nilai2 ? ListPersonalityTestDetail.Nilai2 : ''}
                            </h1>
                        </center>
                        <h3>
                            Notes:
                        </h3>
                        <h4>
                            {ListPersonalityTestDetail?.PTKet ? ListPersonalityTestDetail.PTKet : ''}

                        </h4>
                        <h3>
                            Details:
                        </h3>

                        <span
                            style={st_Detail}
                        >
                            <label>
                                Stress Management:
                            </label>
                            {ListPersonalityTestDetail?.StressManagement ? ListPersonalityTestDetail.StressManagement : ''}
                        </span><br />
                        <span
                            style={st_Detail}
                        >
                            <label>
                                Sensitivity Level:
                            </label>
                            {ListPersonalityTestDetail?.SensitivityLevel ? ListPersonalityTestDetail.SensitivityLevel : ''}
                        </span><br />
                        <span
                            style={st_Detail}
                        >
                            <label>
                                Compassion Level:
                            </label>
                            {ListPersonalityTestDetail?.CompassionLevel ? ListPersonalityTestDetail.CompassionLevel : ''}
                        </span><br />
                        <span
                            style={st_Detail}
                        >
                            <label>
                                Patience Level:
                            </label>
                            {ListPersonalityTestDetail?.PatienceLevel ? ListPersonalityTestDetail.PatienceLevel : ''}
                        </span><br />
                        <span
                            style={st_Detail}
                        >
                            <label>
                                Creative Abilities:
                            </label>
                            {ListPersonalityTestDetail?.CreativeAbilities ? ListPersonalityTestDetail.CreativeAbilities : ''}
                        </span><br />
                        <span
                            style={st_Detail}
                        >
                            <label>
                                Artistic Abilities:
                            </label>
                            {ListPersonalityTestDetail?.ArtisticAbilities ? ListPersonalityTestDetail.ArtisticAbilities : ''}
                        </span><br />
                        <span
                            style={st_Detail}
                        >
                            <label>
                                Outgoing Traits:
                            </label>
                            {ListPersonalityTestDetail?.OutgoingTraits ? ListPersonalityTestDetail.OutgoingTraits : ''}
                        </span><br />
                        <span
                            style={st_Detail}
                        >
                            <label>
                                Interaction Level:
                            </label>
                            {ListPersonalityTestDetail?.InteractionLevel ? ListPersonalityTestDetail.InteractionLevel : ''}
                        </span><br />
                        <span
                            style={st_Detail}
                        >
                            <label>
                                Analytical:
                            </label>
                            {ListPersonalityTestDetail?.Analytical ? ListPersonalityTestDetail.Analytical : ''}
                        </span><br />
                        <span
                            style={st_Detail}
                        >
                            <label>
                                Detailed Traits:
                            </label>
                            {ListPersonalityTestDetail?.DetailedTraits ? ListPersonalityTestDetail.DetailedTraits : ''}
                        </span><br />
                        <span
                            style={st_Detail}
                        >
                            <label>
                                Decision Making:
                            </label>
                            {ListPersonalityTestDetail?.DecisionMaking ? ListPersonalityTestDetail.DecisionMaking : ''}
                        </span><br />
                        <span
                            style={st_Detail}
                        >
                            <label>
                                Independent:
                            </label>
                            {ListPersonalityTestDetail?.Independent ? ListPersonalityTestDetail.Independent : ''}
                        </span><br />
                        <span
                            style={st_Detail}
                        >
                            <label>
                                Persuasive Level:
                            </label>
                            {ListPersonalityTestDetail?.PersuasiveLevel ? ListPersonalityTestDetail.PersuasiveLevel : ''}
                        </span><br />
                        <span
                            style={st_Detail}
                        >
                            <label>
                                Aggressive Level:
                            </label>
                            {ListPersonalityTestDetail?.AggressiveLevel ? ListPersonalityTestDetail.AggressiveLevel : ''}
                        </span><br />
                        <span
                            style={st_Detail}
                        >
                            <label>
                                Stubborn Level:
                            </label>
                            {ListPersonalityTestDetail?.StubbornLevel ? ListPersonalityTestDetail.StubbornLevel : ''}
                        </span><br />
                        <span
                            style={st_Detail}
                        >
                            <label>
                                Goal Setting:
                            </label>
                            {ListPersonalityTestDetail?.GoalSetting ? ListPersonalityTestDetail.GoalSetting : ''}
                        </span><br />
                        <span
                            style={st_Detail}
                        >
                            <label>
                                Time Management:
                            </label>
                            {ListPersonalityTestDetail?.TimeManagement ? ListPersonalityTestDetail.TimeManagement : ''}
                        </span><br />
                        <span
                            style={st_Detail}
                        >
                            <label>
                                Confidence Level:
                            </label>
                            {ListPersonalityTestDetail?.ConfidenceLevel ? ListPersonalityTestDetail.ConfidenceLevel : ''}
                        </span><br />
                        <h3>
                            Submited By:
                        </h3>
                        <h3>
                            {ListPersonalityTestDetail?.BiodataNama ? ListPersonalityTestDetail.BiodataNama : ''} On {ListPersonalityTestDetail?.PTTime ? new Date(ListPersonalityTestDetail.PTTime).toLocaleDateString(['ban', 'id']) : ''}
                        </h3>

                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
    isListPersonalityTestDetailLoading: state.Job.isListPersonalityTestDetailLoading,
    ListPersonalityTestDetail: state.Job.ListPersonalityTestDetail,
})

export default connect(mapStateToProps, { get_List_Personality_Test_Detail, get_List_Personality_Test_Detail_JobList })(Detail)