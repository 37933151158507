import {
    //* TIMELINE
    TIMELINE_LOADING,
    TIMELINE_LOADED,
    TIMELINE_LOADING_DONE,
    TIMELINE_DETAIL_LOADING,
    TIMELINE_DETAIL_LOADED,
    TIMELINE_DETAIL_LOADING_DONE,
    //* 
    JOB_LIST_LOADING,
    JOB_LIST_LOADED,
    JOB_LIST_LOADING_DONE,
    JOB_FULL_LIST_LOADING,
    JOB_FULL_LIST_LOADED,
    JOB_FULL_LIST_LOADING_DONE,
    JOB_ACTION_GET_ID,
    JOB_ACTION_GET_DATA_LOADING,
    JOB_ACTION_GET_DATA_LOADED,
    JOB_ACTION_GET_DATA_LOADING_DONE,
    JOB_IS_JOB_SAVE_LOADING,
    JOB_IS_JOB_SAVE_LOADED,
    JOB_IS_JOB_SAVE_LOADING_DONE,
    JOB_IS_JOB_APPLY_LOADING,
    JOB_IS_JOB_APPLY_LOADED,
    JOB_IS_JOB_APPLY_LOADING_DONE,
    JOB_LIST_PENDAFTAR_LOADING,
    JOB_LIST_PENDAFTAR_LOADED,
    JOB_LIST_PENDAFTAR_LOADING_DONE,
    JOB_LIST_PENDAFTAR_DETAIL_LOADING,
    JOB_LIST_PENDAFTAR_DETAIL_LOADED,
    JOB_LIST_PENDAFTAR_DETAIL_LOADING_DONE,
    JOB_LIST_PENDAFTAR_DIAJUKAN_LOADING,
    JOB_LIST_PENDAFTAR_DIAJUKAN_LOADED,
    JOB_LIST_PENDAFTAR_DIAJUKAN_LOADING_DONE,
    JOB_LIST_PENDAFTAR_SUCCESS,
    JOB_LIST_REKOMENDASI_LOADING,
    JOB_LIST_REKOMENDASI_LOADED,
    JOB_LIST_REKOMENDASI_LOADING_DONE,
    //* PERSONALITYTEST
    JOB_LIST_PERSONALITYTEST_LOADING,
    JOB_LIST_PERSONALITYTEST_LOADED,
    JOB_LIST_PERSONALITYTEST_LOADING_DONE,
    JOB_LIST_PERSONALITYTEST_DETAIL_LOADING,
    JOB_LIST_PERSONALITYTEST_DETAIL_LOADED,
    JOB_LIST_PERSONALITYTEST_DETAIL_LOADING_DONE,
    //* Interview
    JOB_LIST_INTERVIEW_JADWAL_LOADING,
    JOB_LIST_INTERVIEW_JADWAL_LOADED,
    JOB_LIST_INTERVIEW_JADWAL_LOADING_DONE,
    JOB_LIST_INTERVIEW_JADWAL_DETAIL_LOADING,
    JOB_LIST_INTERVIEW_JADWAL_DETAIL_LOADED,
    JOB_LIST_INTERVIEW_JADWAL_DETAIL_LOADING_DONE,
    JOB_LIST_INTERVIEW_USER_LOADING,
    JOB_LIST_INTERVIEW_USER_LOADED,
    JOB_LIST_INTERVIEW_USER_LOADING_DONE,
    JOB_LIST_INTERVIEW_USER_DETAIL_LOADING,
    JOB_LIST_INTERVIEW_USER_DETAIL_LOADED,
    JOB_LIST_INTERVIEW_USER_DETAIL_LOADING_DONE,
    JOB_LIST_INTERVIEW_HR_LOADING,
    JOB_LIST_INTERVIEW_HR_LOADED,
    JOB_LIST_INTERVIEW_HR_LOADING_DONE,
    JOB_LIST_INTERVIEW_HR_DETAIL_LOADING,
    JOB_LIST_INTERVIEW_HR_DETAIL_LOADED,
    JOB_LIST_INTERVIEW_HR_DETAIL_LOADING_DONE,
    //* OFFERING
    JOB_LIST_OFFERING_LOADING,
    JOB_LIST_OFFERING_LOADED,
    JOB_LIST_OFFERING_LOADING_DONE,
    JOB_LIST_OFFERING_DETAIL_LOADING,
    JOB_LIST_OFFERING_DETAIL_LOADED,
    JOB_LIST_OFFERING_DETAIL_LOADING_DONE,
    //* MCU
    JOB_LIST_MCU_LOADING,
    JOB_LIST_MCU_LOADED,
    JOB_LIST_MCU_LOADING_DONE,
    JOB_LIST_MCU_DETAIL_LOADING,
    JOB_LIST_MCU_DETAIL_LOADED,
    JOB_LIST_MCU_DETAIL_LOADING_DONE,
    //* EQUIPMENT
    JOB_LIST_EQUIPMENT_LOADING,
    JOB_LIST_EQUIPMENT_LOADED,
    JOB_LIST_EQUIPMENT_LOADING_DONE,
    JOB_LIST_EQUIPMENT_DETAIL_LOADING,
    JOB_LIST_EQUIPMENT_DETAIL_LOADED,
    JOB_LIST_EQUIPMENT_DETAIL_LOADING_DONE,
    //* PREBOARDING
    JOB_LIST_PREBOARDING_LOADING,
    JOB_LIST_PREBOARDING_LOADED,
    JOB_LIST_PREBOARDING_LOADING_DONE,
    JOB_LIST_PREBOARDING_DETAIL_LOADING,
    JOB_LIST_PREBOARDING_DETAIL_LOADED,
    JOB_LIST_PREBOARDING_DETAIL_LOADING_DONE,
    //* PKWT
    JOB_LIST_PKWT_LOADING,
    JOB_LIST_PKWT_LOADED,
    JOB_LIST_PKWT_LOADING_DONE,
    JOB_LIST_PKWT_DETAIL_LOADING,
    JOB_LIST_PKWT_DETAIL_LOADED,
    JOB_LIST_PKWT_DETAIL_LOADING_DONE,
    //* BPJS
    JOB_LIST_BPJS_LOADING,
    JOB_LIST_BPJS_LOADED,
    JOB_LIST_BPJS_LOADING_DONE,
    JOB_LIST_BPJS_DETAIL_LOADING,
    JOB_LIST_BPJS_DETAIL_LOADED,
    JOB_LIST_BPJS_DETAIL_LOADING_DONE,
    //* INDUSTRY
    JOB_LIST_INDUSTRY_LOADING,
    JOB_LIST_INDUSTRY_LOADED,
    JOB_LIST_INDUSTRY_LOADING_DONE,
    JOB_LIST_INDUSTRY_DETAIL_LOADING,
    JOB_LIST_INDUSTRY_DETAIL_LOADED,
    JOB_LIST_INDUSTRY_DETAIL_LOADING_DONE,
    //* GREETDAY
    JOB_LIST_GREETDAY_LOADING,
    JOB_LIST_GREETDAY_LOADED,
    JOB_LIST_GREETDAY_LOADING_DONE,
    JOB_LIST_GREETDAY_DETAIL_LOADING,
    JOB_LIST_GREETDAY_DETAIL_LOADED,
    JOB_LIST_GREETDAY_DETAIL_LOADING_DONE,
    //* KONTRAK
    JOB_LIST_KONTRAK_LOADING,
    JOB_LIST_KONTRAK_LOADED,
    JOB_LIST_KONTRAK_LOADING_DONE,
    JOB_LIST_KONTRAK_DETAIL_LOADING,
    JOB_LIST_KONTRAK_DETAIL_LOADED,
    JOB_LIST_KONTRAK_DETAIL_LOADING_DONE,
    //* ONBOARDING
    JOB_LIST_ONBOARDING_LOADING,
    JOB_LIST_ONBOARDING_LOADED,
    JOB_LIST_ONBOARDING_LOADING_DONE,
    JOB_LIST_ONBOARDING_DETAIL_LOADING,
    JOB_LIST_ONBOARDING_DETAIL_LOADED,
    JOB_LIST_ONBOARDING_DETAIL_LOADING_DONE,
    //* APPLICATIONS
    JOB_LIST_APPLICATIONS_APPLY_LOADING,
    JOB_LIST_APPLICATIONS_APPLY_LOADED,
    JOB_LIST_APPLICATIONS_APPLY_LOADING_DONE,
    JOB_LIST_APPLICATIONS_SAVE_LOADING,
    JOB_LIST_APPLICATIONS_SAVE_LOADED,
    JOB_LIST_APPLICATIONS_SAVE_LOADING_DONE,
    //* PREREGISTER
    JOB_PREREGISTER_LOADING,
    JOB_PREREGISTER_LOADED,
    JOB_PREREGISTER_LOADING_DONE,
    //* MOVE
    JOB_MOVE_LIST_LOADING,
    JOB_MOVE_LIST_LOADED,
    JOB_MOVE_LIST_LOADING_DONE,
    JOB_MOVE_DETAIL_LOADING,
    JOB_MOVE_DETAIL_LOADED,
    JOB_MOVE_DETAIL_LOADING_DONE,
} from '../Actions/Type.Action'

const initialState = {
    //* TIMELINE
    isTimeLineLoading: false,
    TimeLine: [],
    isTimeLineDetailLoading: false,
    TimeLineDetail: null,
    //* 
    isJobListLoading: false,
    JobList: [],
    isJobFullListLoading: false,
    JobFullList: [],
    JobActionId: null,
    isJobActionDataLoading: false,
    JobActionData: null,
    JobActionDeskripsi: [],
    JobActionPersyaratan: [],
    isJobSaveLoading: false,
    isJobSave: false,
    isJobApplyLoading: false,
    isJobApply: false,
    isListPendaftarLoading: false,
    ListPendaftar: [],
    isListPendaftarDetailLoading: false,
    ListPendaftarDetail: [],
    isListPendaftarDiajukanLoading: false,
    ListPendaftarDiajukan: [],
    isListRekomendasiLoading: false,
    ListRekomendasi: [],
    //* PersonalityTest
    isListPersonalityTestLoading: false,
    ListPersonalityTest: [],
    isListPersonalityTestDetailLoading: false,
    ListPersonalityTestDetail: [],
    //* Interview
    isListInterviewJadwalLoading: false,
    ListInterviewJadwal: [],
    isListInterviewJadwalDetailLoading: false,
    ListInterviewJadwalDetail: [],
    isListInterviewUserLoading: false,
    ListInterviewUser: [],
    isListInterviewUserDetailLoading: false,
    ListInterviewUserDetail: null,
    isListInterviewHRLoading: false,
    ListInterviewHR: [],
    isListInterviewHRDetailLoading: false,
    ListInterviewHRDetail: [],
    //* OFFERING
    isListOfferingLoading: false,
    ListOffering: [],
    isListOfferingDetailLoading: false,
    ListOfferingDetail: [],
    //* MCU
    isListMCULoading: false,
    ListMCU: [],
    isListMCUDetailLoading: false,
    //* EQUIPMENT
    isListEquipmentLoading: false,
    ListEquipment: [],
    isListEquipmentDetailLoading: false,
    ListEquipmentDetail: [],
    //* PREBOARDING
    isListPreBoardingLoading: false,
    ListPreBoarding: [],
    isListPreBoardingDetailLoading: false,
    ListPreBoardingDetail: [],
    //* PKWT
    isListPKWTLoading: false,
    ListPKWT: [],
    isListPKWTDetailLoading: false,
    ListPKWTDetail: [],
    //* BPJS
    isListBPJSLoading: false,
    ListBPJS: [],
    isListBPJSDetailLoading: false,
    ListBPJSDetail: [],
    //* Industry
    isListIndustryLoading: false,
    ListIndustry: [],
    isListIndustryDetailLoading: false,
    ListIndustryDetail: [],
    //* GREETDAY
    isListGreetDayLoading: false,
    ListGreetDay: [],
    isListGreetDayDetailLoading: false,
    ListGreetDayDetail: [],
    //* KONTRAK
    isListKontrakLoading: false,
    ListKontrak: [],
    isListKontrakDetailLoading: false,
    ListKontrakDetail: [],
    //* ONBOARDING
    isListOnBoardingLoading: false,
    ListOnBoarding: [],
    isListOnBoardingDetailLoading: false,
    ListOnBoardingDetail: [],
    //* APPLICATIONS
    isListApplicationsApplyLoading: false,
    ListApplicationsApply: [],
    isListApplicationsSaveLoading: false,
    ListApplicationsSave: [],
    //* PREREGISTER
    isPreRegisterLoading: false,
    PreRegisterData: null,
    //* MOVE
    isMoveListLoading: false,
    MoveList: [],
    isMoveDetailLoading: false,
    MoveDetail: null,
}

// eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        //* TIMELINE
        case TIMELINE_LOADING:
            return {
                ...state,
                isTimeLineLoading: false,
            }
        case TIMELINE_LOADED:
            return {
                ...state,
                TimeLine: action.payload,
            }
        case TIMELINE_LOADING_DONE:
            return {
                ...state,
                isTimeLineLoading: false,
            }
        case TIMELINE_DETAIL_LOADING:
            return {
                ...state,
                isTimeLineDetailLoading: false,
            }
        case TIMELINE_DETAIL_LOADED:
            return {
                ...state,
                TimeLineDetail: action.payload,
            }
        case TIMELINE_DETAIL_LOADING_DONE:
            return {
                ...state,
                isTimeLineDetailLoading: false,
            }
        //* 
        case JOB_LIST_LOADING:
            return {
                ...state,
                isJobListLoading: true,
            }
        case JOB_LIST_LOADED:
            return {
                ...state,
                JobList: action.payload,
            }
        case JOB_LIST_LOADING_DONE:
            return {
                ...state,
                isJobListLoading: false,
            }
        case JOB_FULL_LIST_LOADING:
            return {
                ...state,
                isJobFullListLoading: true,
            }
        case JOB_FULL_LIST_LOADED:
            return {
                ...state,
                JobFullList: action.payload,
            }
        case JOB_FULL_LIST_LOADING_DONE:
            return {
                ...state,
                isJobFullListLoading: false,
            }
        case JOB_ACTION_GET_ID:
            return {
                ...state,
                JobActionId: action.payload
            }
        case JOB_ACTION_GET_DATA_LOADING:
            return {
                ...state,
                isJobActionDataLoading: true
            }
        case JOB_ACTION_GET_DATA_LOADED:
            return {
                ...state,
                JobActionData: action.payload.Data,
                JobActionDeskripsi: action.payload.Deskripsi,
                JobActionPersyaratan: action.payload.Persyaratan,
            }
        case JOB_ACTION_GET_DATA_LOADING_DONE:
            return {
                ...state,
                isJobActionDataLoading: false
            }
        case JOB_IS_JOB_SAVE_LOADING:
            return {
                ...state,
                isJobSaveLoading: true,
            }
        case JOB_IS_JOB_SAVE_LOADED:
            return {
                ...state,
                isJobSave: action.payload
            }
        case JOB_IS_JOB_SAVE_LOADING_DONE:
            return {
                ...state,
                isJobSaveLoading: false,
            }
        case JOB_IS_JOB_APPLY_LOADING:
            return {
                ...state,
                isJobApplyLoading: true,
            }
        case JOB_IS_JOB_APPLY_LOADED:
            return {
                ...state,
                isJobApply: action.payload
            }
        case JOB_IS_JOB_APPLY_LOADING_DONE:
            return {
                ...state,
                isJobApplyLoading: false,
            }
        case JOB_LIST_PENDAFTAR_LOADING:
            return {
                ...state,
                isListPendaftarLoading: true
            }
        case JOB_LIST_PENDAFTAR_LOADED:
            return {
                ...state,
                ListPendaftar: action.payload
            }
        case JOB_LIST_PENDAFTAR_LOADING_DONE:
            return {
                ...state,
                isListPendaftarLoading: false
            }
        case JOB_LIST_PENDAFTAR_DETAIL_LOADING:
            return {
                ...state,
                isListPendaftarDetailLoading: true
            }
        case JOB_LIST_PENDAFTAR_DETAIL_LOADED:
            return {
                ...state,
                ListPendaftarDetail: action.payload
            }
        case JOB_LIST_PENDAFTAR_DETAIL_LOADING_DONE:
            return {
                ...state,
                isListPendaftarDetailLoading: false
            }
        case JOB_LIST_PENDAFTAR_DIAJUKAN_LOADING:
            return {
                ...state,
                isListPendaftarDiajukanLoading: true
            }
        case JOB_LIST_PENDAFTAR_DIAJUKAN_LOADED:
            return {
                ...state,
                ListPendaftarDiajukan: action.payload
            }
        case JOB_LIST_PENDAFTAR_DIAJUKAN_LOADING_DONE:
            return {
                ...state,
                isListPendaftarDiajukanLoading: false
            }
        case JOB_LIST_PENDAFTAR_SUCCESS:
            // const { JobId, AccountId, ListPendaftar } = action.payload
            const { AccountId, ListPendaftar } = action.payload

            const newListPendaftar = ListPendaftar.map((item, index) => item.AccountId === AccountId ? ({ ...item, StatusSubmission: true }) : ({ ...item }))
            return {
                ...state,
                ListPendaftar: newListPendaftar
            }
        case JOB_LIST_REKOMENDASI_LOADING:
            return {
                ...state,
                isListRekomendasiLoading: true,
            }
        case JOB_LIST_REKOMENDASI_LOADED:
            return {
                ...state,
                ListRekomendasi: action.payload
            }
        case JOB_LIST_REKOMENDASI_LOADING_DONE:
            return {
                ...state,
                isListRekomendasiLoading: false,
            }
        //* PersonalityTest
        case JOB_LIST_PERSONALITYTEST_LOADING:
            return {
                ...state,
                isListPersonalityTestLoading: true,
            }
        case JOB_LIST_PERSONALITYTEST_LOADED:
            return {
                ...state,
                ListPersonalityTest: action.payload
            }
        case JOB_LIST_PERSONALITYTEST_LOADING_DONE:
            return {
                ...state,
                isListPersonalityTestLoading: false,
            }
        case JOB_LIST_PERSONALITYTEST_DETAIL_LOADING:
            return {
                ...state,
                isListPersonalityTestDetailLoading: true,
            }
        case JOB_LIST_PERSONALITYTEST_DETAIL_LOADED:
            return {
                ...state,
                ListPersonalityTestDetail: action.payload
            }
        case JOB_LIST_PERSONALITYTEST_DETAIL_LOADING_DONE:
            return {
                ...state,
                isListPersonalityTestDetailLoading: false,
            }
        //* Interview
        case JOB_LIST_INTERVIEW_JADWAL_LOADING:
            return {
                ...state,
                isListInterviewJadwalLoading: true
            }
        case JOB_LIST_INTERVIEW_JADWAL_LOADED:
            return {
                ...state,
                ListInterviewJadwal: action.payload
            }
        case JOB_LIST_INTERVIEW_JADWAL_LOADING_DONE:
            return {
                ...state,
                isListInterviewJadwalLoading: false
            }
        case JOB_LIST_INTERVIEW_JADWAL_DETAIL_LOADING:
            return {
                ...state,
                isListInterviewJadwalDetailLoading: true
            }
        case JOB_LIST_INTERVIEW_JADWAL_DETAIL_LOADED:
            return {
                ...state,
                ListInterviewJadwalDetail: action.payload
            }
        case JOB_LIST_INTERVIEW_JADWAL_DETAIL_LOADING_DONE:
            return {
                ...state,
                isListInterviewJadwalDetailLoading: false
            }
        case JOB_LIST_INTERVIEW_USER_LOADING:
            return {
                ...state,
                isListInterviewUserLoading: true
            }
        case JOB_LIST_INTERVIEW_USER_LOADED:
            return {
                ...state,
                ListInterviewUser: action.payload
            }
        case JOB_LIST_INTERVIEW_USER_LOADING_DONE:
            return {
                ...state,
                isListInterviewUserLoading: false
            }
        case JOB_LIST_INTERVIEW_USER_DETAIL_LOADING:
            return {
                ...state,
                isListInterviewUserDetailLoading: true
            }
        case JOB_LIST_INTERVIEW_USER_DETAIL_LOADED:
            return {
                ...state,
                ListInterviewUserDetail: action.payload
            }
        case JOB_LIST_INTERVIEW_USER_DETAIL_LOADING_DONE:
            return {
                ...state,
                isListInterviewUserDetailLoading: false
            }
        case JOB_LIST_INTERVIEW_HR_LOADING:
            return {
                ...state,
                isListInterviewHRLoading: true
            }
        case JOB_LIST_INTERVIEW_HR_LOADED:
            return {
                ...state,
                ListInterviewHR: action.payload
            }
        case JOB_LIST_INTERVIEW_HR_LOADING_DONE:
            return {
                ...state,
                isListInterviewHRLoading: false
            }
        case JOB_LIST_INTERVIEW_HR_DETAIL_LOADING:
            return {
                ...state,
                isListInterviewHRDetailLoading: true
            }
        case JOB_LIST_INTERVIEW_HR_DETAIL_LOADED:
            return {
                ...state,
                ListInterviewHRDetail: action.payload
            }
        case JOB_LIST_INTERVIEW_HR_DETAIL_LOADING_DONE:
            return {
                ...state,
                isListInterviewHRDetailLoading: false
            }
        //* OFFERING
        case JOB_LIST_OFFERING_LOADING:
            return {
                ...state,
                isListOfferingLoading: true,
            }
        case JOB_LIST_OFFERING_LOADED:
            return {
                ...state,
                ListOffering: action.payload
            }
        case JOB_LIST_OFFERING_LOADING_DONE:
            return {
                ...state,
                isListOfferingLoading: false,
            }
        case JOB_LIST_OFFERING_DETAIL_LOADING:
            return {
                ...state,
                isListOfferingDetailLoading: true,
            }
        case JOB_LIST_OFFERING_DETAIL_LOADED:
            return {
                ...state,
                ListOfferingDetail: action.payload
            }
        case JOB_LIST_OFFERING_DETAIL_LOADING_DONE:
            return {
                ...state,
                isListOfferingDetailLoading: false,
            }
        //* MCU
        case JOB_LIST_MCU_LOADING:
            return {
                ...state,
                isListMCULoading: true,
            }
        case JOB_LIST_MCU_LOADED:
            return {
                ...state,
                ListMCU: action.payload
            }
        case JOB_LIST_MCU_LOADING_DONE:
            return {
                ...state,
                isListMCULoading: false,
            }
        case JOB_LIST_MCU_DETAIL_LOADING:
            return {
                ...state,
                isListMCUDetailLoading: true,
            }
        case JOB_LIST_MCU_DETAIL_LOADED:
            return {
                ...state,
                ListMCUDetail: action.payload
            }
        case JOB_LIST_MCU_DETAIL_LOADING_DONE:
            return {
                ...state,
                isListMCUDetailLoading: false,
            }
        //* EQUIPMENT
        case JOB_LIST_EQUIPMENT_LOADING:
            return {
                ...state,
                isListEquipmentLoading: true,
            }
        case JOB_LIST_EQUIPMENT_LOADED:
            return {
                ...state,
                ListEquipment: action.payload
            }
        case JOB_LIST_EQUIPMENT_LOADING_DONE:
            return {
                ...state,
                isListEquipmentLoading: false,
            }
        case JOB_LIST_EQUIPMENT_DETAIL_LOADING:
            return {
                ...state,
                isListEquipmentDetailLoading: true,
            }
        case JOB_LIST_EQUIPMENT_DETAIL_LOADED:
            return {
                ...state,
                ListEquipmentDetail: action.payload
            }
        case JOB_LIST_EQUIPMENT_DETAIL_LOADING_DONE:
            return {
                ...state,
                isListEquipmentDetailLoading: false,
            }
        //* PREBOARDING
        case JOB_LIST_PREBOARDING_LOADING:
            return {
                ...state,
                isListPreBoardingLoading: true,
            }
        case JOB_LIST_PREBOARDING_LOADED:
            return {
                ...state,
                ListPreBoarding: action.payload,
            }
        case JOB_LIST_PREBOARDING_LOADING_DONE:
            return {
                ...state,
                isListPreBoardingLoading: false,
            }
        case JOB_LIST_PREBOARDING_DETAIL_LOADING:
            return {
                ...state,
                isListPreBoardingDetailLoading: false
            }
        case JOB_LIST_PREBOARDING_DETAIL_LOADED:
            return {
                ...state,
                ListPreBoardingDetail: action.payload,
            }
        case JOB_LIST_PREBOARDING_DETAIL_LOADING_DONE:
            return {
                ...state,
                isListPreBoardingDetailLoading: false
            }
        //* PKWT
        case JOB_LIST_PKWT_LOADING:
            return {
                ...state,
                isListPKWTLoading: true,
            }
        case JOB_LIST_PKWT_LOADED:
            return {
                ...state,
                ListPKWT: action.payload,
            }
        case JOB_LIST_PKWT_LOADING_DONE:
            return {
                ...state,
                isListPKWTLoading: false,
            }
        case JOB_LIST_PKWT_DETAIL_LOADING:
            return {
                ...state,
                isListPKWTDetailLoading: true,
            }
        case JOB_LIST_PKWT_DETAIL_LOADED:
            return {
                ...state,
                ListPKWTDetail: action.payload
            }
        case JOB_LIST_PKWT_DETAIL_LOADING_DONE:
            return {
                ...state,
                isListPKWTDetailLoading: false,
            }
        //* BPJS
        case JOB_LIST_BPJS_LOADING:
            return {
                ...state,
                isListBPJSLoading: true,
            }
        case JOB_LIST_BPJS_LOADED:
            return {
                ...state,
                ListBPJS: action.payload,
            }
        case JOB_LIST_BPJS_LOADING_DONE:
            return {
                ...state,
                isListBPJSLoading: false,
            }
        case JOB_LIST_BPJS_DETAIL_LOADING:
            return {
                ...state,
                isListBPJSDetailLoading: true,
            }
        case JOB_LIST_BPJS_DETAIL_LOADED:
            return {
                ...state,
                ListBPJSDetail: action.payload
            }
        case JOB_LIST_BPJS_DETAIL_LOADING_DONE:
            return {
                ...state,
                isListBPJSDetailLoading: false,
            }
        //* INDUSTRY
        case JOB_LIST_INDUSTRY_LOADING:
            return {
                ...state,
                isListIndustryLoading: true,
            }
        case JOB_LIST_INDUSTRY_LOADED:
            return {
                ...state,
                ListIndustry: action.payload,
            }
        case JOB_LIST_INDUSTRY_LOADING_DONE:
            return {
                ...state,
                isListIndustryLoading: false,
            }
        case JOB_LIST_INDUSTRY_DETAIL_LOADING:
            return {
                ...state,
                isListIndustryDetailLoading: true,
            }
        case JOB_LIST_INDUSTRY_DETAIL_LOADED:
            return {
                ...state,
                ListIndustryDetail: action.payload
            }
        case JOB_LIST_INDUSTRY_DETAIL_LOADING_DONE:
            return {
                ...state,
                isListIndustryDetailLoading: false,
            }
        //* GREETDAY
        case JOB_LIST_GREETDAY_LOADING:
            return {
                ...state,
                isListGreetDayLoading: true,
            }
        case JOB_LIST_GREETDAY_LOADED:
            return {
                ...state,
                ListGreetDay: action.payload
            }
        case JOB_LIST_GREETDAY_LOADING_DONE:
            return {
                ...state,
                isListGreetDayLoading: false,
            }
        case JOB_LIST_GREETDAY_DETAIL_LOADING:
            return {
                ...state,
                isListGreetDayDetailLoading: true,
            }
        case JOB_LIST_GREETDAY_DETAIL_LOADED:
            return {
                ...state,
                ListGreetDayDetail: action.payload
            }
        case JOB_LIST_GREETDAY_DETAIL_LOADING_DONE:
            return {
                ...state,
                isListGreetDayDetailLoading: false,
            }
        //* KONTRAK
        case JOB_LIST_KONTRAK_LOADING:
            return {
                ...state,
                isListKontrakLoading: true,
            }
        case JOB_LIST_KONTRAK_LOADED:
            return {
                ...state,
                ListKontrak: action.payload
            }
        case JOB_LIST_KONTRAK_LOADING_DONE:
            return {
                ...state,
                isListKontrakLoading: false,
            }
        case JOB_LIST_KONTRAK_DETAIL_LOADING:
            return {
                ...state,
                isListKontrakDetailLoading: true,
            }
        case JOB_LIST_KONTRAK_DETAIL_LOADED:
            return {
                ...state,
                ListKontrakDetail: action.payload
            }
        case JOB_LIST_KONTRAK_DETAIL_LOADING_DONE:
            return {
                ...state,
                isListKontrakDetailLoading: false,
            }
        //* ONBOARDING
        case JOB_LIST_ONBOARDING_LOADING:
            return {
                ...state,
                isListOnBoardingLoading: true,
            }
        case JOB_LIST_ONBOARDING_LOADED:
            return {
                ...state,
                ListOnBoarding: action.payload
            }
        case JOB_LIST_ONBOARDING_LOADING_DONE:
            return {
                ...state,
                isListOnBoardingLoading: false,
            }
        case JOB_LIST_ONBOARDING_DETAIL_LOADING:
            return {
                ...state,
                isListOnBoardingDetailLoading: true,
            }
        case JOB_LIST_ONBOARDING_DETAIL_LOADED:
            return {
                ...state,
                ListOnBoardingDetail: action.payload
            }
        case JOB_LIST_ONBOARDING_DETAIL_LOADING_DONE:
            return {
                ...state,
                isListOnBoardingDetailLoading: false,
            }
        //* APPLICATIONS
        case JOB_LIST_APPLICATIONS_APPLY_LOADING:
            return {
                ...state,
                isListApplicationsApplyLoading: true
            }
        case JOB_LIST_APPLICATIONS_APPLY_LOADED:
            return {
                ...state,
                ListApplicationsApply: action.payload
            }
        case JOB_LIST_APPLICATIONS_APPLY_LOADING_DONE:
            return {
                ...state,
                isListApplicationsApplyLoading: false
            }
        case JOB_LIST_APPLICATIONS_SAVE_LOADING:
            return {
                ...state,
                isListApplicationsSaveLoading: true
            }
        case JOB_LIST_APPLICATIONS_SAVE_LOADED:
            return {
                ...state,
                ListApplicationsSave: action.payload
            }
        case JOB_LIST_APPLICATIONS_SAVE_LOADING_DONE:
            return {
                ...state,
                isListApplicationsSaveLoading: false
            }
        //* PREREGISTER
        case JOB_PREREGISTER_LOADING:
            return {
                ...state,
                isPreRegisterLoading: true
            }
        case JOB_PREREGISTER_LOADED:
            return {
                ...state,
                PreRegisterData: action.payload
            }
        case JOB_PREREGISTER_LOADING_DONE:
            return {
                ...state,
                isPreRegisterLoading: false
            }
        //* MOVE
        case JOB_MOVE_LIST_LOADING:
            return {
                ...state,
                isMoveListLoading: true
            }
        case JOB_MOVE_LIST_LOADED:
            return {
                ...state,
                MoveList: action.payload
            }
        case JOB_MOVE_LIST_LOADING_DONE:
            return {
                ...state,
                isMoveListLoading: false
            }
        case JOB_MOVE_DETAIL_LOADING:
            return {
                ...state,
                isMoveDetailLoading: true
            }
        case JOB_MOVE_DETAIL_LOADED:
            return {
                ...state,
                MoveDetail: action.payload
            }
        case JOB_MOVE_DETAIL_LOADING_DONE:
            return {
                ...state,
                isMoveDetailLoading: false
            }
        default:
            return state
    }
}