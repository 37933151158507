import React, { Fragment } from "react";

import { connect } from "react-redux";

import { Konfirm_Offering } from "../../../../../../Store/Actions/Job.Action";
import {
  Load_Level_List,
  Load_Golongan_List,
} from "../../../../../../Store/Actions/Setting.Action";

import {
  FormControl,
  InputLabel,
  Select,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";

class SubmitOffering extends React.Component {
  state = {
    Status: "",
    Keterangan: "",
    isAdjust: false,
    AdjustGolongan: "",
    AdjustLevel: "",
    AdjustSub: "",
    AdjustContractDuration: "",
  };

  componentDidMount() {
    const { LevelId, Sub, ContractDuration } = this.props;
    this.props.Load_Golongan_List();
    this.props.Load_Level_List();
    console.log(this.props);
    if (LevelId) {
      this.setState({ AdjustLevel: LevelId });
    }
    if (Sub) {
      this.setState({ AdjustSub: Sub });
    }
    if (ContractDuration) {
      this.setState({ AdjustContractDuration: ContractDuration });
    }
  }

  // componentDidUpdate(prevProps) {
  //     const { LevelId, Sub } = this.props
  //     if (LevelId !== prevProps.LevelId) {
  //         this.setState({ AdjustLevel: LevelId })
  //     }
  //     if (Sub !== prevProps.Sub) {
  //         this.setState({ AdjustSub: Sub })
  //     }
  // }

  onChangeField = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  OnChangeCheckbox = (e) => {
    this.setState({ isAdjust: !this.state.isAdjust });
  };

  formOnSubmit = (e) => {
    e.preventDefault();

    const JobActionId = this.props.JobActionId;
    const AccountActionId = this.props.AccountActionId;

    this.props.Konfirm_Offering(
      JobActionId,
      AccountActionId,
      this.state.Status,
      this.state.Keterangan,
      this.state.AdjustGolongan,
      this.state.AdjustLevel,
      this.state.AdjustSub,
      this.state.AdjustContractDuration
    );
  };

  render() {
    const st_textfield = { width: "100%", margin: "1% 0 1% 0" };

    const {
      Status,
      Keterangan,
      isAdjust,
      AdjustGolongan,
      AdjustLevel,
      AdjustSub,
      AdjustContractDuration,
    } = this.state;

    const OpLevelList = this.props.LevelList;
    const OpGolonganList = this.props.GolonganList;
    console.log(OpGolonganList);
    // const isLevelLoading = this.props.isLevelLoading

    return (
      <Fragment>
        <form onSubmit={this.formOnSubmit}>
          <FormControl style={st_textfield} variant="filled" required>
            <InputLabel shrink>Status</InputLabel>
            <Select
              style={{ backgroundColor: "white" }}
              native
              onChange={(e) => this.onChangeField(e)}
              label="Status"
              name="Status"
              value={Status}
            >
              <option value="" disabled>
                {" "}
                -- select an option --{" "}
              </option>
              {["Accept", "Reject"].map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>

          <TextField
            style={st_textfield}
            variant="outlined"
            type="text"
            label="Keterangan"
            name="Keterangan"
            value={Keterangan}
            onChange={this.onChangeField}
          />

          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={this.OnChangeCheckbox}
                  name="isAdjust"
                  value={isAdjust}
                />
              }
              label="Adjust Data"
            />
          </FormGroup>

          {isAdjust ? (
            <Fragment>
              <FormControl style={st_textfield} variant="filled" required>
                <InputLabel shrink>Golongan</InputLabel>
                <Select
                  style={{ backgroundColor: "white" }}
                  native
                  onChange={(e) => this.onChangeField(e)}
                  label="AdjustGolongan"
                  name="AdjustGolongan"
                  value={AdjustGolongan}
                >
                  <option value="" disabled>
                    {" "}
                    -- select an option --{" "}
                  </option>
                  {
                    // OptionalChoice?.Golongan
                    // ? OptionalChoice.Golongan
                    // : [{}]
                    (OpGolonganList ? OpGolonganList : [{}]).map(
                      (option, index) => (
                        <option key={index} value={option.Code}>
                          {option.Code}
                        </option>
                      )
                    )
                  }
                </Select>
              </FormControl>

              <FormControl style={st_textfield} variant="filled" required>
                <InputLabel shrink>Level</InputLabel>
                <Select
                  native
                  style={{ backgroundColor: "white" }}
                  onChange={(e) => this.onChangeField(e)}
                  label="Level"
                  name="AdjustLevel"
                  value={AdjustLevel}
                >
                  <option value="" disabled>
                    {" "}
                    -- select an option --{" "}
                  </option>
                  {OpLevelList.map((option, index) => (
                    <option key={`LevelList_${index}`} value={option.Id}>
                      {option.Nama}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <TextField
                style={st_textfield}
                variant="outlined"
                type="text"
                label="Posisi"
                name="AdjustSub"
                value={AdjustSub}
                onChange={this.onChangeField}
                required
              />
              <TextField
                style={st_textfield}
                variant="outlined"
                type="number"
                label="Contract Duration (in Month)"
                name="AdjustContractDuration"
                value={AdjustContractDuration}
                onChange={this.onChangeField}
                required
              />
            </Fragment>
          ) : (
            <Fragment></Fragment>
          )}

          <center>
            <Button
              variant="contained"
              style={{ margin: "10% 0 5% 0", width: "60%" }}
              type="submit"
            >
              Submit
            </Button>
          </center>
        </form>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  JobActionId: state.Job.JobActionId,
  AccountActionId: state.Account.AccountActionId,
  LevelList: state.Setting.LevelList,
  isLevelLoading: state.Setting.isLevelLoading,
  GolonganList: state.Setting.GolonganList,
  isGolonganLoading: state.Setting.isGolonganLoading,
});

export default connect(mapStateToProps, {
  Konfirm_Offering,
  Load_Level_List,
  Load_Golongan_List,
})(SubmitOffering);
