import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Account_Data } from '../../../../../../Store/Actions/Setting.Action'

import Detail from '../../../../../Containers/Dashboard/Setting/User/Detail'

class SettingItemsView extends React.Component {
    componentDidMount() {
        const { AccountActionId } = this.props
        if (AccountActionId !== null) {
            this.props.get_Account_Data(AccountActionId)
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.AccountActionId !== prevProps.AccountActionId) {
            this.props.get_Account_Data(this.props.AccountActionId)
        }
    }

    render() {
        const isAccountDetailLoading = this.props.isAccountDetailLoading
        const AccountDetail = this.props.AccountDetail

        return (
            <Fragment>
                <Detail Data={AccountDetail} isLoading={isAccountDetailLoading} />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isAccountDetailLoading: state.Setting.isAccountDetailLoading,
    AccountDetail: state.Setting.AccountDetail,
    AccountActionId: state.Account.AccountActionId,
})

export default connect(mapStateToProps, { get_Account_Data })(SettingItemsView)
