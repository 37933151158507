import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Grid, Paper, List, Fade, Slide } from '@mui/material'

import Menu from '../../Containers/Dashboard/Menu'

import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'

class Dashboard extends React.Component {
    state = {
        isOpen: true,
    }

    setIsOpen() {
        this.setState({ isOpen: !this.state.isOpen })
        if (!this.state.isOpen) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }

    render() {
        const Data = [
            // {
            //     MenuTitle: 'Notification',
            //     Privacry: 'Privacry',
            //     Link: '/dashboard/notification',
            //     MenuTitleVariant: 'standard',
            //     MenuTitleVertical: 'top',
            //     MenuTitleHorizontal: 'right',
            //     MenuTitlebadgeContent: 1
            // },
            {
                MenuTitle: 'Timeline',
                Link: '/dashboard/timeline',
                Privacry: 'Privacry',
            },
            {
                MenuTitle: 'MPP',
                Privacry: 'Privacry',
                MenuCollapse: [
                    {
                        MenuTitle: 'Submission',
                        Link: '/dashboard/mpp/pengajuan',
                        Privacry: 'Privacry',
                        AllowedRole: ["48428"]
                    },
                    {
                        MenuTitle: 'Approval',
                        Link: '/dashboard/mpp/penerimaan',
                        Privacry: 'Privacry',
                        AllowedRole: ["1105"]
                    },
                    {
                        MenuTitle: 'View MPP List',
                        Link: '/dashboard/mpp',
                        Privacry: 'Privacry',
                        AllowedRole: ["1105", "48428"]
                    },
                    // {
                    //     MenuTitle: 'View MPP Item',
                    //     Link: '/dashboard/mpp/item',
                    //     Privacry: 'Privacry',
                    //     AllowedRole: ["1105"]
                    // },
                ]
            },
            {
                MenuTitle: 'Job Description',
                Privacry: 'Privacry',
                MenuCollapse: [
                    {
                        MenuTitle: 'Submission',
                        Link: '/dashboard/jobdesk/pengajuan',
                        Privacry: 'Privacry',
                        AllowedRole: ["36617"]
                    },
                    // {
                    //     MenuTitle: 'Approval',
                    //     Link: '/dashboard/jobdesk/penerimaan',
                    //     Privacry: 'Privacry',
                    //     AllowedRole: ["33553"]
                    // },
                ]
            },
            {
                MenuTitle: 'PR',
                Privacry: 'Privacry',
                MenuCollapse: [
                    {
                        MenuTitle: 'Submission',
                        Link: '/dashboard/pr/pengajuan',
                        Privacry: 'Privacry',
                        AllowedRole: ["25354"]
                    },
                    {
                        MenuTitle: 'Approval',
                        Link: '/dashboard/pr/penerimaan',
                        Privacry: 'Privacry',
                        AllowedRole: ["33553"]
                    },
                ]
            },
            {
                MenuTitle: 'Selection Process',
                Privacry: 'Privacry',
                MenuCollapse: [
                    {
                        MenuTitle: 'Candidate Sourcing',
                        Link: '/dashboard/job/job',
                        Privacry: 'Privacry',
                        // AllowedRole: ["86374"] // JobReferee
                    },
                    {
                        MenuTitle: 'Personalty Tes',
                        Link: '/dashboard/job/personaltytest',
                        Privacry: 'Privacry',
                        AllowedRole: ["15591"]
                    },
                    {
                        MenuTitle: 'Interview HRGA',
                        Link: '/dashboard/job/inthrga',
                        Privacry: 'Privacry',
                        AllowedRole: ["51203"]
                    },
                    {
                        MenuTitle: 'Interview User Schedule',
                        Link: '/dashboard/job/jadwalinterview',
                        Privacry: 'Privacry',
                        AllowedRole: ["15591"]
                    },
                    {
                        MenuTitle: 'Interview User',
                        Link: '/dashboard/job/intuser',
                        Privacry: 'Privacry',
                        AllowedRole: ["77741"]
                    },
                    {
                        MenuTitle: 'Offering',
                        Link: '/dashboard/job/offering',
                        Privacry: 'Privacry',
                        AllowedRole: ["77131"]
                    },
                    {
                        MenuTitle: 'MCU',
                        Link: '/dashboard/job/mcu',
                        Privacry: 'Privacry',
                        AllowedRole: ["27454"]
                    },
                    {
                        MenuTitle: 'MCU Approval',
                        Link: '/dashboard/job/mcu/approval',
                        Privacry: 'Privacry',
                        AllowedRole: ["77131"]
                    },
                ]
            },
            {
                MenuTitle: 'Pre-Boarding',
                Privacry: 'Privacry',
                MenuCollapse: [
                    {
                        MenuTitle: 'Document Check List',
                        Link: '/dashboard/job/preboarding',
                        Privacry: 'Privacry',
                        AllowedRole: ["86374", "99281"]
                    },
                    {
                        MenuTitle: 'PKWT/PKWTT',
                        Link: '/dashboard/job/pkwt',
                        Privacry: 'Privacry',
                        AllowedRole: ["77131"]
                    },
                    {
                        MenuTitle: 'Equipment',
                        Link: '/dashboard/job/equipment',
                        Privacry: 'Privacry',
                        AllowedRole: ["49858", "51203"]
                    },
                    {
                        MenuTitle: 'Great Day',
                        Link: '/dashboard/job/greetday',
                        Privacry: 'Privacry',
                        AllowedRole: ["99281"]
                    },
                    {
                        MenuTitle: 'Contract',
                        Link: '/dashboard/job/kontrak',
                        Privacry: 'Privacry',
                        AllowedRole: ["71850"]
                    },
                    {
                        MenuTitle: 'BPJS',
                        Link: '/dashboard/job/bpjs',
                        Privacry: 'Privacry',
                        AllowedRole: ["29059"]
                    },
                ]
            },
            {
                MenuTitle: 'ON Boarding ',
                Privacry: 'Privacry',
                MenuCollapse: [
                    {
                        MenuTitle: 'OnBoarding',
                        Link: '/dashboard/job/onboarding',
                        Privacry: 'Privacry',
                        AllowedRole: ["8677"]
                    },
                    {
                        MenuTitle: 'Industry',
                        Link: '/dashboard/job/Industry',
                        Privacry: 'Privacry',
                        AllowedRole: ["82507"]
                    },
                ]
            },
            {
                MenuTitle: 'Report',
                Privacry: 'Privacry',
                MenuCollapse: [
                    {
                        MenuTitle: 'MPP',
                        Link: '/dashboard/report/mpp',
                        Privacry: 'Privacry',
                        AllowedDepartment: ['53661'],
                    },
                    {
                        MenuTitle: 'Job Description',
                        Link: '/dashboard/report/jobdesc',
                        Privacry: 'Privacry',
                        AllowedDepartment: ['53661'],
                    },
                    {
                        MenuTitle: 'PR',
                        Link: '/dashboard/report/pr',
                        Privacry: 'Privacry',
                        AllowedDepartment: ['53661'],
                    },
                    {
                        MenuTitle: 'Selection Process',
                        Link: '/dashboard/report/hr',
                        Privacry: 'Privacry',
                        AllowedDepartment: ['53661'],
                    },
                    {
                        MenuTitle: 'Department Report',
                        Link: '/dashboard/report/department',
                        Privacry: 'Privacry',
                    },
                ]
            },
            {
                MenuTitle: 'Setting',
                Privacry: 'SuperPrivacry',
                MenuCollapse: [
                    {
                        MenuTitle: 'User',
                        Link: '/dashboard/setting/user',
                        Privacry: 'SuperPrivacry',
                    },
                    {
                        MenuTitle: 'Role',
                        Link: '/dashboard/setting/role',
                        Privacry: 'SuperPrivacry',
                    },
                    {
                        MenuTitle: 'Division',
                        Link: '/dashboard/setting/division',
                        Privacry: 'SuperPrivacry',
                    },
                    {
                        MenuTitle: 'Department',
                        Link: '/dashboard/setting/department',
                        Privacry: 'SuperPrivacry',
                    },
                    {
                        MenuTitle: 'Level',
                        Link: '/dashboard/setting/level',
                        Privacry: 'SuperPrivacry',
                    },
                    {
                        MenuTitle: 'MPP',
                        Link: '/dashboard/setting/mpp',
                        Privacry: 'SuperPrivacry',
                    },
                    {
                        MenuTitle: 'Items',
                        Link: '/dashboard/setting/items',
                        Privacry: 'SuperPrivacry',
                    },
                    {
                        MenuTitle: 'Location',
                        Link: '/dashboard/setting/lokasi',
                        Privacry: 'SuperPrivacry',
                    },
                    {
                        MenuTitle: 'Interview Questions',
                        Link: '/dashboard/setting/pertanyaan_interview',
                        Privacry: 'SuperPrivacry',
                    },
                    {
                        MenuTitle: 'Move Applicant',
                        Link: '/dashboard/job/move',
                        Privacry: 'Privacry',
                        AllowedRole: ["25120"]
                    },
                    // {
                    //     MenuTitle: 'Biodata Questions Point',
                    //     Link: '/dashboard/setting/pertanyaan_biodata_jawaban',
                    //     Privacry: 'SuperPrivacry',
                    // },
                    // {
                    //     MenuTitle: 'Biodata Questions Explanation',
                    //     Link: '/dashboard/setting/pertanyaan_biodata_penjelasan',
                    //     Privacry: 'SuperPrivacry',
                    // },
                ]
            },
        ]

        const {
            isOpen
        } = this.state
        // const isOpen = false

        const User = this.props.User

        return (
            <Fragment>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    {isOpen ?
                        <Slide
                            in={isOpen}
                            direction="right"
                            {...(isOpen ? { timeout: 500 } : {})}
                        >
                            <Paper
                                style={{ border: '0px solid red', width: '20%', minHeight: '90vh', marginRight: '5%', fontFamily: 'Montserrat, Segoe UI' }}
                            >
                                {isOpen ?
                                    <div
                                        onClick={() => this.setIsOpen()}
                                        style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', justifyItems: 'center', border: '0px solid red', cursor: 'pointer', padding: '0 0 0 1%' }}
                                    >
                                        <span>
                                            <h2>
                                                Navigation
                                            </h2>
                                        </span>
                                        <span
                                            style={{ margin: '0 0 0 5%' }}
                                        >
                                            <MenuOpenIcon />
                                        </span>
                                    </div>
                                    : <Fragment></Fragment>}

                                <div
                                    style={{ border: '0px solid red', padding: '1%' }}
                                >
                                    <List>
                                        {Data.map((item, index) => (
                                            <div
                                                key={index}
                                            >
                                                <Menu
                                                    User={User}
                                                    MenuTitle={item.MenuTitle}
                                                    Privacry={item.Privacry}
                                                    AllowedRole={item.AllowedRole}
                                                    AllowedDepartment={item.AllowedDepartment}
                                                    Link={item.Link}
                                                    MenuTitlebadgeContent={item.MenuTitlebadgeContent}
                                                    MenuTitleColor={item.MenuTitleColor}
                                                    MenuTitleVariant={item.MenuTitleVariant}
                                                    MenuTitleVertical={item.MenuTitleVertical}
                                                    MenuTitleHorizontal={item.MenuTitleHorizontal}
                                                    MenuCollapse={item.MenuCollapse}
                                                />
                                            </div>
                                        ))}
                                    </List>
                                </div>
                            </Paper >
                        </Slide>
                        : <Paper
                            style={{ position: 'fixed', zIndex: '100', border: '0px solid white', textTransform: 'none', color: 'white', backgroundColor: '#00a3df', boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px', fontFamily: 'Montserrat, Segoe UI', }}
                        >
                            <div
                                onClick={() => this.setIsOpen()}
                                style={{ cursor: 'pointer', padding: '10px 10px 10px 10px' }}
                            >
                                <MenuIcon />
                            </div>
                        </Paper>}

                    {isOpen ? <Paper
                        style={{ border: '0px solid red', width: '72%', padding: '0', minHeight: '80vh', overflowX: 'auto' }}
                    >
                        {this.props.Component ? this.props.Component : null}
                    </Paper> :
                        <Fade
                            in={!isOpen}
                            {...(!isOpen ? { timeout: 800 } : {})}
                        >
                            <Paper
                                style={{ border: '0px solid red', width: '95%', margin: '0 2% 0 2%', padding: '0', minHeight: '80vh', overflowX: 'auto' }}
                            >
                                {this.props.Component ? this.props.Component : null}
                            </Paper>
                        </Fade>}
                </Grid >
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    // Job: state.Job,
    Auth: state.Auth,
    User: state.Auth.User,
})

export default connect(mapStateToProps, {})(Dashboard)


