import React, { Fragment } from "react"

import { useMediaQuery, Collapse } from '@mui/material'
import { InitState } from '../../../Style'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import Example1 from '../../../Img/PhotoGuidance/Example1.JPG'
import Example2 from '../../../Img/PhotoGuidance/Example2.JPG'
import Example3 from '../../../Img/PhotoGuidance/Example3.JPG'
import Example4 from '../../../Img/PhotoGuidance/Example4.JPG'


const PhotoGuidance = ({ ...props }) => {
    const MinWindowFirst = useMediaQuery(`(min-width:${InitState.Units.minWidth_first}px)`)
    const [isOpen, setOpen] = React.useState(true)

    return (
        <Fragment>
            <h3
                style={{ cursor: 'pointer' }}
                onClick={(e) => setOpen(!isOpen)}
            >
                Photo Guidance {isOpen ? <ExpandLess /> : <ExpandMore />}
            </h3>

            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <center>
                    <div
                        style={{ display: MinWindowFirst ? 'flex' : 'block', justifyContent: 'center', alignItems: 'center', }}
                    >
                        <div
                            style={{ border: '0px solid red', width: MinWindowFirst ? '25vw' : '60vw', height: '35vh', margin: '0 0 5% 0', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                        >
                            <img
                                style={{ border: '0px solid red', width: '100%', height: '100%', objectFit: 'cover', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                src={Example1}
                                alt='Example1'
                            />
                        </div>

                        <div
                            style={{ border: '0px solid red', width: MinWindowFirst ? '25vw' : '60vw', height: '35vh', margin: '0 0 5% 0', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                        >
                            <img
                                style={{ border: '0px solid red', width: '100%', height: '100%', objectFit: 'cover', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                src={Example2}
                                alt='Example2'
                            />
                        </div>

                        <div
                            style={{ border: '0px solid red', width: MinWindowFirst ? '25vw' : '60vw', height: '35vh', margin: '0 0 5% 0', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                        >
                            <img
                                style={{ border: '0px solid red', width: '100%', height: '100%', objectFit: 'cover', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                src={Example3}
                                alt='Example3'
                            />
                        </div>

                        <div
                            style={{ border: '0px solid red', width: MinWindowFirst ? '25vw' : '60vw', height: '35vh', margin: '0 0 5% 0', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                        >
                            <img
                                style={{ border: '0px solid red', width: '100%', height: '100%', objectFit: 'cover', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                src={Example4}
                                alt='Example4'
                            />
                        </div>
                    </div>
                </center>
            </Collapse>

            <ul
                style={{ margin: '2vh 0 5vh 0', fontFamily: 'Montserrat, Segoe UI', fontSize: MinWindowFirst ? '1.2vw' : '16px' }}
            >
                <li>
                    Wear formal clothing
                </li>
                <li>
                    Preferably white background color photo
                </li>
                <li>
                    Smile pose
                </li>
                <li>
                    Pose just like the example
                </li>
            </ul>
            <hr />
        </Fragment >
    )
}

export default PhotoGuidance