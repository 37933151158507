import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_MPP_User_Pengajuan, get_MPP_Action_Id } from '../../../../Store/Actions/MPP.Action'

import GenericModals from '../../../Containers/GenericModals'
import CoTable from '../../../Containers/Table'
import Tooltip from '@mui/material/Tooltip';
import PengajuanMPPForm from './Modal/PengajuanMPP/PengajuanMPP'
import PengajuanMPPAdditional from './Modal/PengajuanMPP/PengajuanMPPAdditional'
import EditMPP from './Modal/EditMPP/EditMPP'
import DetailMPP from './Modal/DetailMPP'

class PengajuanMPP extends React.Component {
    componentDidMount() {
        this.props.get_MPP_User_Pengajuan()
    }

    onClickActionId(Id) {
        this.props.get_MPP_Action_Id(Id)
    }

    render() {
        const User = this.props.User
        const Department = User?.Department ? User.Department : []

        const isMPPUserMPPLoading = this.props.isMPPUserMPPLoading
        const MPPData = this.props.MPPUserMP.MPPData

        const MPPUserMPAsli = this.props.MPPUserMP?.Data ? this.props.MPPUserMP.Data : []
        const MPPUserMP = MPPUserMPAsli.filter((item) => Department.find((newItem) => newItem.DepartemenNama === item.DepNama) ? true : false)
        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
                KeyName: 'index',
                SortingType: 'Number',
            },
            {
                Nama: 'Submission Number',
                width: '10%',
                KeyName: 'Id',
                SortingType: 'Number',
            },
            {
                Nama: 'Year',
                width: '10%',
                KeyName: 'Tahun',
                SortingType: 'Number',
            },
            {
                Nama: 'Submission Date',
                width: '10%',
                KeyName: 'TanggalPengajuan',
                SortingType: 'Date',
            },
            {
                Nama: 'Department',
                width: '10%',
                KeyName: 'Departemen',
            },
            {
                Nama: 'Divisi',
                width: '10%',
                KeyName: 'Divisi',
            },
            {
                Nama: 'Level',
                width: '10%',
                KeyName: 'Level',
            },
            {
                Nama: 'Job Title',
                width: '10%',
                KeyName: 'Sub',
            },
            {
                Nama: 'MPP Type',
                width: '10%',
                KeyName: 'Jenis',
            },
            {
                Nama: 'Status',
                width: '10%',
                KeyName: 'Status',
            },
            {
                Nama: 'Details',
                width: '10%',
            },
            {
                Nama: 'Edit',
                width: '10%',
            },
        ]
        console.log(MPPUserMP);
        const BodyData = MPPUserMP ? MPPUserMP.map((item, index) => {
            const TanggalPengajuan = new Date(item.TanggalPembuatan).toLocaleString(['ban', 'id'])
            return {
                index: {
                    value: index + 1,
                },
                Id: {
                    value: item.Id,
                },
                Tahun: {
                    value: item.Tahun,
                },
                TanggalPengajuan: {
                    value: item.TanggalPembuatan,
                    display: TanggalPengajuan,
                },
                Departemen: {
                    value: item.DepNama,
                },
                Divisi: {
                    value: item.NamaDivisi,
                },
                Level: {
                    value: item.Levnama,
                },
                Sub: {
                    value: item.Sub
                },
                Jenis: {
                    value: item.Jenis === 'Additional' ?
                        <div class="tooltip">{item.Jenis}
                            <span class="tooltiptext">{item.Reason ? item.Reason : 'None'}</span>
                        </div>
                        : item.Jenis,
                },
                Status: {
                    value: item.Status,
                    display: (
                        <span
                            style={{
                                color: `${item?.Status ? item.Status === 'Submitted' || item.Status === 'Submitted' ? 'Orange' :
                                    item.Status === 'Accept' || item.Status === 'Accept' ? 'Green' : 'Red' : 'black'}`
                            }}
                        >
                            {/* {item?.Status ? item.Status : 'Status'} */}
                            {item?.Status === 'Submitted' ? 'Pending Approval' : item?.Status === 'Accept' ? 'Approved' : item?.Status === 'Reject' ? 'Reject' : ''}
                        </span>),
                },
                Detail: {
                    value: item.Id,
                    display: (
                        <GenericModals
                            size='xl'
                            header='Detail MPP Submission'
                            body={<DetailMPP />}
                            Buttoncolor='primary'
                            ButtononClickeven={() => this.onClickActionId(item.Id)}
                            Buttonlabel={'Detail'}
                        />
                    )
                },
                Edit: {
                    value: item.Id,
                    display: (
                        <GenericModals
                            size='xl'
                            header='Edit MPP Submission'
                            body={<EditMPP />}
                            Buttoncolor='secondary'
                            Buttondisabled={item?.Status ? item.Status === 'Submitted' || item.Status === 'Submitted' ? false : true : true}
                            ButtononClickeven={() => this.onClickActionId(item.Id)}
                            Buttonlabel={'Edit'}
                        />
                    )
                }
            }
        }) : []

        const RowDataInOnePage = 10
        const Now = new Date()
        const TanggalBuka = MPPData ? new Date(MPPData.TanggalBuka) : new Date()
        const TanggalTutup = MPPData ? new Date(MPPData.TanggalTutup) : new Date()
        const MPPCondition = MPPData ?
            Now.getTime() >= TanggalBuka.getTime() && Now <= TanggalTutup.getTime() ? 1 :
                Now < TanggalBuka.getTime() ? 2 : 3 : 4

        return (
            <Fragment>
                <h2>
                    MPP Submission
                </h2>
                <center>
                    <GenericModals
                        size='xl'
                        header='Create MPP Submission'
                        body={<PengajuanMPPForm MPPJenis={MPPData ? MPPData.Jenis : 'Additional'} Year={MPPData ? MPPData.Tahun ? MPPData.Tahun : new Date().getFullYear() : new Date().getFullYear()} />}
                        Buttondisabled={MPPCondition === 1 ? false : true}
                        Buttoncolor='primary'
                        Buttonstyle={{ width: '90%', margin: '1%' }}
                        ButtononClickeven={() => null}
                        Buttonlabel={'+ Add MPP Submission'}
                    />
                </center>
                <center>
                    <h2
                        style={{
                            margin: '1% 0 1% 0', padding: '1%',
                            color: MPPCondition === 1 ? 'green' :
                                MPPCondition === 2 ? 'blue' :
                                    MPPCondition === 3 ? 'orange' :
                                        'black',
                            border: MPPCondition === 1 ? '1px solid green' :
                                MPPCondition === 2 ? '1px solid blue' :
                                    MPPCondition === 3 ? '1px solid orange' :
                                        '1px solid black'
                        }}
                    >
                        {MPPCondition === 1 ? `MPP ${MPPData.Tahun}, ${MPPData.Jenis ? MPPData.Jenis : ''}, ${new Date(MPPData.TanggalBuka).toLocaleDateString(['ban', 'id'])} - ${new Date(new Date(MPPData.TanggalTutup) - (24 * 60 * 60 * 1000)).toLocaleDateString(['ban', 'id'])}.` :
                            MPPCondition === 2 ? `MPP ${MPPData.Tahun}, ${MPPData.Jenis ? MPPData.Jenis : ''}, Will Open On ${new Date(MPPData.TanggalBuka).toLocaleDateString(['ban', 'id'])} - ${new Date(new Date(MPPData.TanggalTutup) - (24 * 60 * 60 * 1000)).toLocaleDateString(['ban', 'id'])}.` :
                                MPPCondition === 3 ? `MPP ${MPPData.Tahun} Closed, ${new Date(MPPData.TanggalBuka).toLocaleDateString(['ban', 'id'])} - ${new Date(new Date(MPPData.TanggalTutup) - (24 * 60 * 60 * 1000)).toLocaleDateString(['ban', 'id'])}.` :
                                    `MPP Details Not Found`
                        }
                    </h2>
                </center>
                <center>
                    {/* MPP Additional */}
                    <GenericModals
                        size='xl'
                        header='Create MPP Additional Submission'
                        body={<PengajuanMPPAdditional MPPJenis={'Additional'} />}
                        Buttoncolor='primary'
                        Buttonstyle={{ width: '90%', margin: '1%' }}
                        ButtononClickeven={() => null}
                        Buttonlabel={'+ Add MPP Additional Submission'}
                    />
                </center>
                <CoTable
                    TableId={'MPPPengajuan'}
                    HeadData={HeadData}
                    Data={BodyData}
                    isHeadLoading={isMPPUserMPPLoading}
                    isBodyLoading={isMPPUserMPPLoading}
                    isPagination={BodyData.length > RowDataInOnePage ? true : false}
                    isPaginationLoading={isMPPUserMPPLoading}
                    PaginationCounter={
                        BodyData.length / RowDataInOnePage > Math.floor(BodyData.length / RowDataInOnePage) ?
                            Math.floor(BodyData.length / RowDataInOnePage) + 1 :
                            BodyData.length / RowDataInOnePage
                    }
                    isHeading={true}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    User: state.Auth.User,
    AccountId: state.Auth.Id,
    isMPPUserMPPLoading: state.MPP.isMPPUserMPPLoading,
    MPPUserMP: state.MPP.MPPUserMP,
})

export default connect(mapStateToProps, { get_MPP_User_Pengajuan, get_MPP_Action_Id })(PengajuanMPP)
