import React, { Fragment } from "react"

import useMediaQuery from '@mui/material/useMediaQuery'
import { InitState } from '../../../../../Style'

import FullBiodata from './Biodata/FullBiodata'
import HalfBiodata from './Biodata/HalfBiodata'

const MoBiodata = ({ isOneStep, ...props }) => {
    const MinWindowFirst = useMediaQuery(`(min-width:${InitState.Units.minWidth_first}px)`)
    return (
        <Fragment>
            {MinWindowFirst ?
                <Fragment>
                    <FullBiodata isOneStep={isOneStep} />
                </Fragment>
                : <Fragment>
                    <HalfBiodata isOneStep={isOneStep} />
                </Fragment>}
        </Fragment>
    )
}

export default MoBiodata