import React, { Fragment } from 'react'

import { useDispatch, connect } from 'react-redux'
import { get_Activation_Code, Activation_Code } from '../../Store/Actions/Auth.Action'

import { Modal, TextField, Button } from '@mui/material'

const ActivationModal = ({ isModalOpen, Email, SendFunction, GetMailFunction, isOneStep, ...props }) => {
    const dispatch = useDispatch()

    const [ActivationInput, setActivationInput] = React.useState('')
    const [isAsk, setisAsk] = React.useState(false)

    const UseMailFunction = () => {
        dispatch(get_Activation_Code())
        setisAsk(true)
    }
    const goToLogOut = () => {
        window.location.href = '/logout'
        isModalOpen = false
    }

    return (
        <Fragment>
            {/* .center-block {
            display: block;
            margin-right: auto;
            margin-left: auto;
            } */}
            {/* text-align:center */}
            <Modal
                open={isModalOpen ? true : false}
                style={{
                    border: '0px solid red', backgroundImage: 'linear-gradient(to Left, #90e0ef, #00a3df', opacity: '1'
                }}
            >
                <div
                    style={{ border: '0px solid red', width: '100vw', height: '100vh', position: 'fixed' }}
                >
                    <h2
                        style={{ position: 'absolute', right: '0px', top: '0px', margin: '3vh 3vw 0 0', color: 'white', cursor: 'pointer', fontFamily: 'Montserrat, Segoe UI', }}
                        onClick={() => goToLogOut()}
                    >
                        Logout
                    </h2>
                    <center>
                        <h1
                            style={{ color: 'white', margin: '25vh 0 5vh 0', fontFamily: 'Montserrat, Segoe UI', }}
                        >
                            Please Check {Email ? Email : 'mail'} for activation code, {isAsk ? <Fragment>Confirmation Code Already Sent, Please Wait</Fragment> : <Fragment>or click <span style={{ cursor: 'pointer', color: 'whitesmoke' }} onClick={(e) => UseMailFunction()}><u>here</u></span> to send new one</Fragment>}
                        </h1>
                        <TextField
                            label='Activation Code'
                            variant='standard'
                            value={ActivationInput}
                            onChange={(e) => setActivationInput(e.target.value)}
                            style={{ margin: '2vh 0 5vh 0', color: 'white', borderColor: 'white', width: '20%' }}
                            InputLabelProps={{
                                style: { color: 'white', }
                            }}
                            InputProps={{
                                disableUnderline: true,
                                style: { color: 'white', borderBottom: '1px solid white' }
                            }}
                        /><br />
                        <Button
                            variant='outlined'
                            size='large'
                            style={{ color: 'white', borderColor: 'white', width: '60%', margin: '2vh 0 5vh 0', fontFamily: 'Montserrat, Segoe UI', boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px', }}
                            onClick={(e) => dispatch(Activation_Code(ActivationInput, isOneStep, props.User))}
                        >
                            <b>
                                Confirm
                            </b>
                        </Button>
                    </center>
                </div>
            </Modal>
        </Fragment >
    )
}

const mapStateToProps = (state) => ({
    User: state.Auth.User
})

export default connect(mapStateToProps)(ActivationModal)