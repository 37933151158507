import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Tabs, Tab } from '@mui/material'

import JobApplications from './Sub/JobApplications'
import JobSave from './Sub/JobSave'
// import Notification from './Sub/Notification'

class Applications extends React.Component {
    state = {
        TabValue: 0
    }

    handleTabChange = (e, newTabValue) => {
        this.setState({ TabValue: newTabValue })
    }

    render() {

        const TabData = [
            { TabLabel: 'Job Apply', TabPanel: <JobApplications /> },
            { TabLabel: 'Save Job', TabPanel: <JobSave /> },
            // { TabLabel: 'Notification', TabPanel: <Notification /> },
        ]

        const {
            TabValue
        } = this.state

        return (
            <Fragment>
                <Tabs
                    value={TabValue}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={TabData.length > 6 ? "scrollable" : null}
                    scrollButtons="auto"
                    centered={TabData.length > 6 ? false : true}
                >
                    {TabData.map((item, index) => (
                        <Tab key={`Tabs${index}`} label={item.TabLabel} />
                    ))}
                </Tabs>
                {TabValue === 0 ? TabData[TabValue].TabPanel : null}
                {TabValue === 1 ? TabData[TabValue].TabPanel : null}
                {TabValue === 2 ? TabData[TabValue].TabPanel : null}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    // Job: state.Job,
    // JobApplyData: state.Job.JobApply,
    // JobSaveData: state.Job.JobSave
})

export default connect(mapStateToProps, {})(Applications)
