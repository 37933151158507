import React from "react"

import InstagramLogo from '../../../Img/InstagramIcon.svg'
import LinkedInLogo from '../../../Img/LinkedInIcon.svg'

const Footer = () => {
    const EtanaLink = 'https://www.id.etanabiotech.com'
    const LinkedInLink = 'https://id.linkedin.com/company/pt-etana-biotechnologies-indonesia'
    const InstagramLink = 'https://www.instagram.com/etanabiotech.id'
    const goToLink = (e, LinkLocation) => {
        // window.location.href = LinkLocation
        window.open(
            `${LinkLocation}`,
            '_blank'
        )
    }
    return (
        <footer
            style={{ backgroundImage: 'linear-gradient(to Left, #90e0ef, #00a3df', border: '0px solid red', bottom: '0px', left: '0px', height: '20vh', margin: ' 5vh 0 0 0', padding: '2vh 0 0 0' }}
        >
            <center>

                <div
                    style={{ border: '0px solid red', color: 'white', height: '6vh', margin: '2vh 0 0 0', paddingTop: '1vh', fontFamily: 'Montserrat, Segoe UI', cursor: 'pointer', fontSize: '90%' }}
                    onClick={(e) => goToLink(e, EtanaLink)}
                >
                    © 2022, <b>PT. Etana Biotechnologies Indonesia</b>
                </div>


                <div
                    style={{ border: '0px solid blue', height: '8vh', marginTop: '0' }}
                >
                    <div
                        style={{ border: '0px solid red', height: '100%' }}
                    >
                        <img
                            style={{ width: '10%', height: '100%', cursor: 'pointer' }}
                            alt='LinkedInLogo'
                            src={LinkedInLogo}
                            onClick={(e) => goToLink(e, LinkedInLink)}
                        />
                        <img
                            style={{ width: '10%', height: '100%', cursor: 'pointer', border: '0px solid red' }}
                            alt='InstagramLogo'
                            src={InstagramLogo}
                            onClick={(e) => goToLink(e, InstagramLink)}
                        />
                    </div>
                </div>
            </center>
        </footer >
    )
}

export default Footer