import React, { Fragment } from 'react'

import { Skeleton, useMediaQuery } from '@mui/material'
import { InitState } from '../../../Style'
import { ImageURL } from '../../../Global'
import NoRecord from '../../Layouts/Error/NoRecord'

const Documents = ({ isLoading, Data }) => {
    const DocumentsData = Data.Data

    const DocumentFoto = DocumentsData?.FileFoto ? `${ImageURL.Base}/${DocumentsData.FileFoto}` : ''
    const DocumentKTP = DocumentsData?.FileKTP ? `${ImageURL.Base}/${DocumentsData.FileKTP}` : ''
    const DocumentBPJSKesehatan = DocumentsData?.FileBPJSKesehatan ? `${ImageURL.Base}/${DocumentsData.FileBPJSKesehatan}` : ''
    const DocumentBPJSKetenagakerjaan = DocumentsData?.FileBPJSKetenagakerjaan ? `${ImageURL.Base}/${DocumentsData.FileBPJSKetenagakerjaan}` : ''
    const DocumentBCA = DocumentsData?.FileRekeningBCA ? `${ImageURL.Base}/${DocumentsData.FileRekeningBCA}` : ''
    const DocumentNPWP = DocumentsData?.FileNPWP ? `${ImageURL.Base}/${DocumentsData.FileNPWP}` : ''
    const DocumentKartuKeluarga = DocumentsData?.FileKartuKeluarga ? `${ImageURL.Base}/${DocumentsData.FileKartuKeluarga}` : ''
    const DocumentIjazah = DocumentsData?.FileIjazah ? `${ImageURL.Base}/${DocumentsData.FileIjazah}` : ''
    const DocumentSertifikasi = DocumentsData?.FileSertifikasi ? `${ImageURL.Base}/${DocumentsData.FileSertifikasi}` : ''

    // const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%', backgroundColor: '#E0E0E0' }
    const isPCView = useMediaQuery(`(min-width:${InitState.Units.minWidth_first}px)`)

    return (
        <Fragment>
            {isLoading ?
                <Fragment>
                    {[1, 2, 3].map((item) =>
                        <Skeleton
                            key={`Account_Skeleton_${item}`}
                            height={'5vh'}
                        />
                    )}
                </Fragment> :
                <div
                    style={{ fontFamily: 'Montserrat, Segoe UI', fontSize: isPCView ? '1.2vw' : '16px' }}
                >
                    <div>
                        <h2>
                            Foto
                        </h2>
                        <center>
                            {DocumentFoto ?
                                <div
                                    style={{ border: '0px solid red', width: '60%', height: '40%', margin: '0 0 5% 0', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                >
                                    <img
                                        style={{ border: '0px solid red', width: '100%', height: '100%', objectFit: 'contain', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                        src={DocumentFoto}
                                        alt='Foto'
                                    />
                                </div> :
                                <NoRecord />}
                        </center>
                    </div>

                    <div>
                        <h2>
                            KTP
                        </h2>
                        <center>
                            {DocumentKTP ?
                                <div
                                    style={{ border: '0px solid red', width: '60%', height: '40%', margin: '0 0 5% 0', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                >
                                    <iframe
                                        src={DocumentKTP}
                                        // height="1000"
                                        // width="1000"
                                        title='DocumentKTP'
                                        style={{ width: '30vw', height: '50vh', }}
                                    />
                                </div> :
                                <NoRecord />}
                        </center>
                    </div>

                    <div>
                        <h2>
                            BPJS Kesehatan
                        </h2>
                        <center>
                            {DocumentBPJSKesehatan ?
                                <div
                                    style={{ border: '0px solid red', width: '60%', height: '40%', margin: '0 0 5% 0', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                >
                                    <iframe
                                        src={DocumentBPJSKesehatan}
                                        // height="1000"
                                        // width="1000"
                                        title='BPJS Kesehatan'
                                        style={{ width: '30vw', height: '50vh', }}
                                    />
                                </div> :
                                <NoRecord />}
                        </center>
                    </div>

                    <div>
                        <h2>
                            BPJS Ketenagakerjaan
                        </h2>
                        <center>
                            {DocumentBPJSKetenagakerjaan ?
                                <div
                                    style={{ border: '0px solid red', width: '60%', height: '40%', margin: '0 0 5% 0', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                >
                                    <iframe
                                        src={DocumentBPJSKetenagakerjaan}
                                        // height="1000"
                                        // width="1000"
                                        title='BPJS Ketenagakerjaan'
                                        style={{ width: '30vw', height: '50vh', }}
                                    />
                                </div> :
                                <NoRecord />}
                        </center>
                    </div>

                    <div>
                        <h2>
                            BCA
                        </h2>
                        <center>
                            {DocumentBCA ?
                                <div
                                    style={{ border: '0px solid red', width: '60%', height: '40%', margin: '0 0 5% 0', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                >
                                    <iframe
                                        src={DocumentBCA}
                                        // height="1000"
                                        // width="1000"
                                        title='BCA'
                                        style={{ width: '30vw', height: '50vh', }}
                                    />
                                </div> :
                                <NoRecord />}
                        </center>
                    </div>

                    <div>
                        <h2>
                            NPWP
                        </h2>
                        <center>
                            {DocumentNPWP ?
                                <div
                                    style={{ border: '0px solid red', width: '60%', height: '40%', margin: '0 0 5% 0', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                >
                                    <iframe
                                        src={DocumentNPWP}
                                        // height="1000"
                                        // width="1000"
                                        title='NPWP'
                                        style={{ width: '30vw', height: '50vh', }}
                                    />
                                </div> :
                                <NoRecord />}
                        </center>
                    </div>

                    <div>
                        <h2>
                            Kartu Keluarga
                        </h2>
                        <center>
                            {DocumentKartuKeluarga ?
                                <div
                                    style={{ border: '0px solid red', width: '60%', height: '40%', margin: '0 0 5% 0', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                >
                                    <iframe
                                        src={DocumentKartuKeluarga}
                                        // height="1000"
                                        // width="1000"
                                        title='Kartu Keluarga'
                                        style={{ width: '30vw', height: '50vh', }}
                                    />
                                </div> :
                                <NoRecord />}
                        </center>
                    </div>

                    <div>
                        <h2>
                            Ijazah
                        </h2>
                        <center>
                            {DocumentIjazah ?
                                <div
                                    style={{ border: '0px solid red', width: '60%', height: '40%', margin: '0 0 5% 0', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                >
                                    <iframe
                                        src={DocumentIjazah}
                                        // height="1000"
                                        // width="1000"
                                        title='Ijazah'
                                        style={{ width: '30vw', height: '50vh', }}
                                    />
                                </div> :
                                <NoRecord />}
                        </center>
                    </div>

                    <div>
                        <h2>
                            Sertifikat
                        </h2>
                        <center>
                            {DocumentSertifikasi ?
                                <div
                                    style={{ border: '0px solid red', width: '60%', height: '40%', margin: '0 0 5% 0', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                >
                                    <iframe
                                        src={DocumentSertifikasi}
                                        // height="1000"
                                        // width="1000"
                                        title='Sertifikat'
                                        style={{ width: '30vw', height: '50vh', }}
                                    />
                                </div> :
                                <NoRecord />}
                        </center>
                    </div>
                </div>}
        </Fragment>
    )
}

export default Documents