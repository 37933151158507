import React, { Fragment, useEffect } from "react";

import { Create_Excel_File } from "../../Store/Actions/Base.Action";

import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Skeleton,
  Pagination,
  TextField,
  Button,
} from "@mui/material";

import ComponentsError from "../Layouts/Error/ComponentsError";
import NoRecord from "../Layouts/Error/NoRecord";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
// import EditIcon from '@mui/icons-material/Edit'
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const CoTable = ({
  TableId,
  HeadData,
  isHeadLoading,
  Data,
  isBodyLoading,
  isPagination,
  isPaginationLoading,
  PaginationCounter,
  MaxRowOnPage = 10,
  MessageTitle,
  Messages,
  isHorizontalScroll = false,
  isDebugging = false,
  isHeading = false,
  CustomFilter,
  isDownload = false,
  DownloadFileName,
  ComplexDataDownload,
  ...props
}) => {
  const [BodyData, setBodyData] = React.useState([]);
  const [ColumnData, setColumnData] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [MaxRow, setMaxRow] = React.useState(10);

  const [isSearchActive, setSearchActive] = React.useState(false);
  const [SearchKeyword, setSearchKeyword] = React.useState("");

  // const [isEditActive, setEditActive] = React.useState(false)
  const [isEditActive] = React.useState(false);

  const [isFilterActive, setFilterActive] = React.useState(false);
  const [FilterData, setFilterData] = React.useState([]);

  const [SortingStatus, setSortingStatus] = React.useState(true);
  const [SortingName, setSortingName] = React.useState("");

  useEffect(() => {
    setBodyData(Data);
    setColumnData(HeadData);
    setMaxRow(MaxRowOnPage);
    const newFilterData = [];
    for (const x in HeadData) {
      const Item = HeadData[x];
      const defaultFilterData = {
        Nama: Item.KeyName ? Item.KeyName : Item.Nama,
        Keyword: "",
      };
      newFilterData.push(defaultFilterData);
    }
    setFilterData(CustomFilter ? CustomFilter : newFilterData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Data]);

  if (!HeadData || !Data) {
    console.error(`🚀 ~ file: Table.js ~ line 9 ~ CoTable ~ Data`, Data);
    console.error(
      `🚀 ~ file: Table.js ~ line 9 ~ CoTable ~ HeadData`,
      HeadData
    );
    return <ComponentsError />;
  }

  if (Data.length < 1) {
    return <NoRecord MessageTitle={MessageTitle} Messages={Messages} />;
  }

  const UsedBodyData = isPagination
    ? MaxRow !== "∞"
      ? BodyData.slice(page * MaxRow - MaxRow, page * MaxRow)
      : BodyData
    : BodyData;

  const handleSetMaxRow = (Number) => {
    setMaxRow(Number);
    setPage(1);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const createSearchData = (SearchKeyword) => {
    if (SearchKeyword) {
      const newData = [];
      for (const x in Data) {
        const Item = CustomFilter ? Data[x].Detail.value : Data[x];
        for (const Key in Item) {
          const Value = CustomFilter
            ? Item[Key]
            : Item[Key]?.value
            ? Item[Key].value
            : "";
          const stValue = String(Value).toLowerCase();
          const isThere = stValue.includes(String(SearchKeyword).toLowerCase());
          if (isThere) {
            newData.push(CustomFilter ? Data[x] : Item);
            break;
          }
        }
      }
      return newData;
    } else {
      return Data;
    }
  };

  const createFilterData = (FilterData) => {
    const newData = [];

    for (const i in FilterData) {
      const newFilterData = FilterData[i];
      const newFilterDataNama = newFilterData.Nama;
      const newFilterDataKeyword = newFilterData.Keyword;

      if (newFilterDataKeyword) {
        const newNewData = Data.filter((item) => {
          const newItem = CustomFilter
            ? item.Detail.value[`${newFilterDataNama}`]
            : item[`${newFilterDataNama}`];
          const newString = CustomFilter
            ? newItem
            : newItem?.value
            ? newItem.value
            : "";
          const newStringLo = String(newString).toLowerCase();
          const FilterDataKeywordLo =
            String(newFilterDataKeyword).toLowerCase();
          const isInList = newStringLo.includes(FilterDataKeywordLo);
          return isInList;
        });
        newData.push(newNewData);
      }
    }

    const FinalData =
      newData.length > 0
        ? newData.reduce((p, c) => p.filter((e) => c.includes(e)))
        : Data;

    return FinalData;
  };

  const ModifyTableData = (TableSearchData, TableFilterData) => {
    const SearchBodyData = TableSearchData
      ? createSearchData(TableSearchData)
      : Data;
    const FilterBodyData = TableFilterData
      ? createFilterData(TableFilterData)
      : Data;

    const BodyDataList = [SearchBodyData, FilterBodyData].reduce((p, c) =>
      p.filter((e) => c.includes(e))
    );

    // const BodyDataKeys = []
    // const BodyDataListKeys = []

    // if (BodyData.length > 0) {
    //     const ObjectData = BodyData[0]
    //     Object.keys(ObjectData).map((item, index) =>
    //         BodyDataKeys.push(item)
    //     )
    // }

    // if (BodyDataList.length > 0) {
    //     const ObjectData = BodyDataList[0]
    //     Object.keys(ObjectData).map((item, index) =>
    //         BodyDataListKeys.push(item)
    //     )
    // }

    // const FinalBodyDataDif = BodyDataListKeys.filter((item) => !BodyDataKeys.some((newItem) => item === newItem))

    // if (FinalBodyDataDif.length > 0) {
    //     const FinalBodyData = BodyDataList.map((item) => {
    //         const newItem = { ...item }
    //         for (const i in FinalBodyDataDif) {
    //             const newDif = FinalBodyDataDif[i]
    //             delete newItem[`${newDif}`]
    //         }
    //         return newItem
    //     })
    //     setBodyData(FinalBodyData)
    //     return FinalBodyData
    // } else {
    //     setBodyData(BodyDataList)
    //     return BodyDataList
    // }

    setBodyData(BodyDataList);
    return BodyDataList;
  };

  const OnChangeSetSearchActive = (Condition) => {
    setSearchActive(Condition);
    if (Condition === false) {
      setSearchKeyword("");
      ModifyTableData("", FilterData);
    }
  };

  const OnChangeSearchKeyword = (e) => {
    const newValue = e.target.value;
    setSearchKeyword(newValue);

    ModifyTableData(newValue, FilterData);
  };

  const OnChangeFilter = (e, index) => {
    const newValue = e.target.value;
    const newFilterData = { ...FilterData };
    newFilterData[index].Keyword = newValue;
    setFilterData(newFilterData);

    ModifyTableData(SearchKeyword, newFilterData);
    setPage(1);
  };

  const SortData = (ColumnName, Condition, SortingType) => {
    if (ColumnName === SortingName) {
      setSortingStatus(!Condition);
    } else {
      setSortingName(ColumnName);
      setSortingStatus(true);
    }

    Condition = ColumnName === SortingName ? !Condition : true;
    if (!Condition) {
      BodyData.sort((a, b) =>
        SortingType === "Number"
          ? Number(a[ColumnName].value) > Number(b[ColumnName].value)
            ? 1
            : Number(b[ColumnName].value) > Number(a[ColumnName].value)
            ? -1
            : 0
          : SortingType === "Date"
          ? new Date(a[ColumnName].value) > new Date(b[ColumnName].value)
            ? 1
            : new Date(b[ColumnName].value) > new Date(a[ColumnName].value)
            ? -1
            : 0
          : a[ColumnName].value > b[ColumnName].value
          ? 1
          : b[ColumnName].value > a[ColumnName].value
          ? -1
          : 0
      );
    } else {
      BodyData.sort((a, b) =>
        SortingType === "Number"
          ? Number(a[ColumnName].value) < Number(b[ColumnName].value)
            ? 1
            : Number(b[ColumnName].value) < Number(a[ColumnName].value)
            ? -1
            : 0
          : SortingType === "Date"
          ? new Date(a[ColumnName].value) < new Date(b[ColumnName].value)
            ? 1
            : new Date(b[ColumnName].value) < new Date(a[ColumnName].value)
            ? -1
            : 0
          : a[ColumnName].value < b[ColumnName].value
          ? 1
          : b[ColumnName].value < a[ColumnName].value
          ? -1
          : 0
      );
    }
  };

  const onClickReset = () => {
    // Column Reset
    setColumnData(HeadData);

    // Data Reset
    setBodyData(Data);

    // Search Reset
    setSearchActive(false);

    // Filter Reset
    setFilterActive(false);
    const newFilterData = [];
    for (const x in FilterData) {
      const Item = HeadData[x];
      const defaultFilterData = CustomFilter
        ? {}
        : { Nama: Item.KeyName ? Item.KeyName : Item.Nama, Keyword: "" };
      newFilterData.push(defaultFilterData);
    }
    setFilterData(CustomFilter ? CustomFilter : newFilterData);

    setMaxRow(10);
    setPage(1);
    setSortingName("");
    setSortingStatus(true);
  };

  //* Loading
  const LoadingTableHead = () => {
    return (
      <TableRow hover>
        <TableCell>
          <Skeleton variant="string" height={"10vh"} />
        </TableCell>
      </TableRow>
    );
  };

  const LoadingTableBody = () => {
    return (
      <Fragment>
        {[0, 1, 2, 3, 4].map((item) => (
          <TableRow
            hover
            key={
              TableId
                ? `${TableId}_BodyData_${item}_Skeleton`
                : `TableId_BodyData_${item}_Skeleton`
            }
          >
            <TableCell>
              <Skeleton
                variant="string"
                height={"5vh"}
                style={{ margin: "1% 0 1% 0" }}
              />
            </TableCell>
          </TableRow>
        ))}
      </Fragment>
    );
  };

  //* Table
  // const DefaultTable = () => {
  //     return (
  //         <Fragment>
  //             <Table
  //                 id={TableId ? TableId : 'TableId'}
  //                 style={{ overflowX: 'auto', border: '1px solid red' }}
  //                 stickyHeader
  //             >
  //                 <TableHead>
  //                     {isHeadLoading ?
  //                         <LoadingTableHead /> :
  //                         <Fragment>
  //                             <TableRow
  //                                 hover
  //                             // selected
  //                             >
  //                                 {ColumnData.map((item, index) =>
  //                                     <TableCell
  //                                         key={TableId ? `${TableId}_HeadData_${index}` : `TableId_HeadData_${index}`}
  //                                         align={item.align ? item.align : 'center'}
  //                                         style={item.style ? item.style : { border: isDebugging ? '1px solid red' : '', width: item?.width ? item.width : 'auto', minWidth: item?.width ? item.width : 'auto' }}
  //                                     >
  //                                         <span
  //                                             style={{ cursor: item.KeyName ? !isEditActive ? 'pointer' : 'default' : 'default' }}
  //                                             onClick={() => item.KeyName ? !isEditActive ? SortData(item.KeyName ? item.KeyName : '', SortingStatus, item.SortingType ? item.SortingType : '') : console.log('') : console.log('')}
  //                                         >
  //                                             {item.Nama ? item.Nama : ''}
  //                                             {item.KeyName === SortingName ? SortingStatus ? <Fragment><br /> <ArrowUpwardIcon /></Fragment> : <Fragment><br /> <ArrowDownwardIcon /> </Fragment> : <Fragment></Fragment>}
  //                                         </span>
  //                                         {
  //                                             item.KeyName === SortingName ?
  //                                                 <Fragment>
  //                                                     {FilterData[index]?.Keyword ?
  //                                                         <FilterAltIcon style={{ margin: '0 0 0 0', color: 'black' }} />
  //                                                         : <Fragment></Fragment>}
  //                                                 </Fragment> :
  //                                                 <Fragment>
  //                                                     <br />
  //                                                     {FilterData[index]?.Keyword ?
  //                                                         <FilterAltIcon style={{ margin: '0 0 0 0', color: 'black' }} />
  //                                                         : <Fragment></Fragment>}
  //                                                 </Fragment>
  //                                         }

  //                                     </TableCell>
  //                                 )}
  //                             </TableRow>

  //                             {isFilterActive ?
  //                                 <TableRow
  //                                 >
  //                                     {ColumnData.map((item, index) =>
  //                                         <TableCell
  //                                             key={TableId ? `${TableId}_HeadData_${index}_Filter` : `TableId_HeadData_${index}__Filter`}
  //                                             align={item.align ? item.align : 'center'}
  //                                             style={item.style ? item.style : { border: isDebugging ? '1px solid red' : '', width: item?.width ? item.width : 'auto', minWidth: item?.width ? item.width : 'auto' }}
  //                                         >
  //                                             <TextField
  //                                                 type="text"
  //                                                 className="form-control"
  //                                                 placeholder={`Search In ${item.Nama}`}
  //                                                 value={FilterData[index]?.Keyword ? FilterData[index].Keyword : ''}
  //                                                 onChange={(e) => OnChangeFilter(e, index)}
  //                                             />
  //                                         </TableCell>
  //                                     )}
  //                                 </TableRow>
  //                                 : <Fragment></Fragment>}
  //                         </Fragment>
  //                     }
  //                 </TableHead>
  //                 <TableBody>
  //                     {isBodyLoading ?
  //                         <LoadingTableBody /> :
  //                         <Fragment>
  //                             <Fragment>
  //                                 {UsedBodyData.map((item, index) =>
  //                                     <TableRow
  //                                         hover
  //                                         // selected
  //                                         key={TableId ? `${TableId}_BodyData_${index}` : `TableId_BodyData_${index}`}
  //                                     >
  //                                         {Object.keys(item).map((item_item, index_index) =>
  //                                             <TableCell
  //                                                 align={ColumnData[index_index]?.BodyAlign ? ColumnData[index_index].BodyAlign : 'left'}
  //                                                 style={ColumnData[index_index]?.BodyStyle ? ColumnData[index_index].BodyStyle : {}}
  //                                                 key={TableId ? `${TableId}_BodyData_${index}_${index_index}` : `TableId_BodyData_${index}_${index_index}`}
  //                                             >
  //                                                 {item[item_item].display ? item[item_item].display
  //                                                     : item[item_item].value ? item[item_item].value
  //                                                         : ''}
  //                                             </TableCell>
  //                                         )}
  //                                     </TableRow>
  //                                 )}
  //                             </Fragment>
  //                         </Fragment>}
  //                 </TableBody>
  //             </Table>
  //         </Fragment>
  //     )
  // }

  //* Main
  return (
    <Fragment>
      {/* Heading */}
      {isHeadLoading ? (
        <Fragment></Fragment>
      ) : isHeading ? (
        <Paper
          style={{
            boxShadow:
              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
            border: "0px solid red",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            margin: "1vh 0 1vh 0",
            padding: "1% 1% 1% 1%",
          }}
        >
          <div style={{ margin: isSearchActive ? "0 1vw 0 0" : "0 17vw 0 0" }}>
            {isDownload ? (
              <Fragment>
                <Button
                  variant="contained"
                  style={{ textTransform: "none", margin: "0 5px 0 5px" }}
                  disabled={!isDownload ? true : false}
                  onClick={() =>
                    Create_Excel_File(
                      UsedBodyData,
                      DownloadFileName,
                      ComplexDataDownload
                    )
                  }
                >
                  Download Table
                </Button>

                <Button
                  variant="contained"
                  style={{ textTransform: "none", margin: "0 5px 0 5px" }}
                  disabled={!isDownload ? true : false}
                  onClick={() =>
                    Create_Excel_File(
                      BodyData,
                      DownloadFileName,
                      ComplexDataDownload
                    )
                  }
                >
                  Download All Data
                </Button>
              </Fragment>
            ) : (
              <Fragment></Fragment>
            )}
          </div>

          <div>
            <FilterAltIcon
              style={{
                color: isFilterActive ? "green" : "black",
                margin: "0 0 0 1vw",
                cursor: "pointer",
              }}
              onClick={(e) => setFilterActive(!isFilterActive)}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 1vw 0 1vw",
            }}
          >
            {isSearchActive ? (
              <input
                type="text"
                className="form-control form-small"
                placeholder="Search In Table"
                name="SearchKeyword"
                style={{
                  width: "15vw",
                  padding: "4px",
                  borderColor: "green",
                  borderRadius: "6px",
                }}
                size={"small"}
                value={SearchKeyword}
                onChange={(e) => OnChangeSearchKeyword(e)}
              />
            ) : (
              <Fragment></Fragment>
            )}

            <SearchIcon
              style={{
                color: isSearchActive ? "green" : "black",
                margin: "0 0 0 1vw",
                cursor: "pointer",
              }}
              onClick={(e) => OnChangeSetSearchActive(!isSearchActive)}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Rows per Page:
            <select
              className="form-select"
              style={{
                border: "0px solid red",
                width: "7vw",
                margin: "0 0 0 5px",
              }}
              value={isPagination ? MaxRow : "∞"}
              name="MaxRow"
              onChange={(e) => handleSetMaxRow(e.target.value)}
              disabled={isPagination ? false : true}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="∞">∞</option>
            </select>
          </div>

          {/* <div
                        style={{ margin: '0 2vw 0 2vw' }}
                    >
                        <EditIcon
                            style={{ cursor: 'pointer', color: isEditActive ? 'green' : 'black' }}
                            onClick={() => setEditActive(!isEditActive)}
                        />
                    </div> */}
          <div>
            <RestartAltIcon
              style={{ cursor: "pointer" }}
              onClick={onClickReset}
            />
          </div>
        </Paper>
      ) : (
        <Fragment></Fragment>
      )}

      {/* Body */}
      {/* {true ?
                <Fragment>
                    <Paper
                        style={{ overflowX: 'auto' }}
                    >
                        <DefaultTable />
                    </Paper>
                </Fragment> :
                <DefaultTable />
            } */}
      <Fragment>
        <Table id={TableId ? TableId : "TableId"} stickyHeader>
          <TableHead>
            {isHeadLoading ? (
              <LoadingTableHead />
            ) : (
              <Fragment>
                <TableRow
                  hover
                  // selected
                >
                  {ColumnData.map((item, index) => (
                    <TableCell
                      key={
                        TableId
                          ? `${TableId}_HeadData_${index}`
                          : `TableId_HeadData_${index}`
                      }
                      align={item.align ? item.align : "center"}
                      style={
                        item.style
                          ? item.style
                          : {
                              border: isDebugging ? "1px solid red" : "",
                              width: item?.width ? item.width : "auto",
                              minWidth: item?.width ? item.width : "auto",
                            }
                      }
                    >
                      <span
                        style={{
                          cursor: item.KeyName
                            ? !isEditActive
                              ? "pointer"
                              : "default"
                            : "default",
                        }}
                        onClick={() =>
                          item.KeyName
                            ? !isEditActive
                              ? SortData(
                                  item.KeyName ? item.KeyName : "",
                                  SortingStatus,
                                  item.SortingType ? item.SortingType : ""
                                )
                              : console.log("")
                            : console.log("")
                        }
                      >
                        {item.Nama ? item.Nama : ""}
                        {item.KeyName === SortingName ? (
                          SortingStatus ? (
                            <Fragment>
                              <br /> <ArrowUpwardIcon />
                            </Fragment>
                          ) : (
                            <Fragment>
                              <br /> <ArrowDownwardIcon />{" "}
                            </Fragment>
                          )
                        ) : (
                          <Fragment></Fragment>
                        )}
                      </span>
                      {item.KeyName === SortingName ? (
                        <Fragment>
                          {FilterData[index]?.Keyword ? (
                            <FilterAltIcon
                              style={{ margin: "0 0 0 0", color: "black" }}
                            />
                          ) : (
                            <Fragment></Fragment>
                          )}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <br />
                          {FilterData[index]?.Keyword ? (
                            <FilterAltIcon
                              style={{ margin: "0 0 0 0", color: "black" }}
                            />
                          ) : (
                            <Fragment></Fragment>
                          )}
                        </Fragment>
                      )}
                    </TableCell>
                  ))}
                </TableRow>

                {isFilterActive ? (
                  <TableRow>
                    {ColumnData.map((item, index) => (
                      <Fragment
                        key={
                          TableId
                            ? `${TableId}_HeadData_${index}_Filter`
                            : `TableId_HeadData_${index}__Filter`
                        }
                      >
                        {CustomFilter ? (
                          <Fragment>
                            <TableCell
                            // align={item.align ? item.align : 'center'}
                            // style={item.style ? item.style : { border: isDebugging ? '1px solid red' : '', width: item?.width ? item.width : 'auto', minWidth: item?.width ? item.width : 'auto' }}
                            >
                              <center>
                                {CustomFilter.map((newItem, newIndex) => (
                                  <Fragment
                                    key={
                                      TableId
                                        ? `${TableId}_HeadData_${index}_Filter_${newIndex}`
                                        : `TableId_HeadData_${index}__Filter_${newIndex}`
                                    }
                                  >
                                    <TextField
                                      type="text"
                                      className="form-control"
                                      label={newItem.Nama}
                                      placeholder={`Search In ${newItem.Nama}`}
                                      style={{ margin: "1% 1% 1% 1%" }}
                                      value={
                                        FilterData[newIndex]?.Keyword
                                          ? FilterData[newIndex].Keyword
                                          : ""
                                      }
                                      onChange={(e) =>
                                        OnChangeFilter(e, newIndex)
                                      }
                                    />
                                  </Fragment>
                                ))}
                              </center>
                            </TableCell>
                          </Fragment>
                        ) : (
                          <TableCell
                            key={
                              TableId
                                ? `${TableId}_HeadData_${index}_Filter`
                                : `TableId_HeadData_${index}__Filter`
                            }
                            align={item.align ? item.align : "center"}
                            style={
                              item.style
                                ? item.style
                                : {
                                    border: isDebugging ? "1px solid red" : "",
                                    width: item?.width ? item.width : "auto",
                                    minWidth: item?.width ? item.width : "auto",
                                  }
                            }
                          >
                            <TextField
                              type="text"
                              className="form-control"
                              placeholder={`Search In ${item.Nama}`}
                              value={
                                FilterData[index]?.Keyword
                                  ? FilterData[index].Keyword
                                  : ""
                              }
                              onChange={(e) => OnChangeFilter(e, index)}
                            />
                          </TableCell>
                        )}
                      </Fragment>
                    ))}
                  </TableRow>
                ) : (
                  <Fragment></Fragment>
                )}
              </Fragment>
            )}
          </TableHead>

          <TableBody>
            {isBodyLoading ? (
              <LoadingTableBody />
            ) : (
              <Fragment>
                <Fragment>
                  {UsedBodyData.map((item, index) => (
                    <TableRow
                      hover
                      // selected
                      key={
                        TableId
                          ? `${TableId}_BodyData_${index}`
                          : `TableId_BodyData_${index}`
                      }
                    >
                      {Object.keys(item).map((item_item, index_index) => (
                        <TableCell
                          align={
                            ColumnData[index_index]?.BodyAlign
                              ? ColumnData[index_index].BodyAlign
                              : "left"
                          }
                          style={
                            ColumnData[index_index]?.BodyStyle
                              ? ColumnData[index_index].BodyStyle
                              : {}
                          }
                          key={
                            TableId
                              ? `${TableId}_BodyData_${index}_${index_index}`
                              : `TableId_BodyData_${index}_${index_index}`
                          }
                        >
                          {item[item_item]?.display
                            ? item[item_item].display
                            : item[item_item]?.value
                            ? item[item_item].value
                            : item[item_item]
                            ? item[item_item] //! TODO
                            : " "}
                          {/* {typeof item[item_item] === 'object' ?
                                                        item[item_item]?.display ? item[item_item].display
                                                            : item[item_item]?.value ? item[item_item].value
                                                                : ''
                                                        : item[item_item] ? item[item_item] //! TODO
                                                            : ''} */}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </Fragment>
              </Fragment>
            )}
          </TableBody>
        </Table>
      </Fragment>

      {/* Pagination */}
      {isPagination ? (
        <div
          style={{
            margin: "1% 0 0 0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isPaginationLoading ? (
            <Fragment>
              <Skeleton variant="string" height={"10vh"} width={"40vw"} />
            </Fragment>
          ) : (
            <Pagination
              count={
                MaxRow === "∞"
                  ? 1
                  : BodyData.length / MaxRow >
                    Math.floor(BodyData.length / MaxRow)
                  ? Math.floor(BodyData.length / MaxRow) + 1
                  : BodyData.length / MaxRow
              }
              page={page}
              onChange={handleChange}
              color="primary"
              variant="outlined"
              shape="rounded"
            />
          )}
        </div>
      ) : (
        <Fragment></Fragment>
      )}
    </Fragment>
  );
};

export default CoTable;
