import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_MPP_Data, Update_MPP } from '../../../../../../Store/Actions/MPP.Action'
import { Load_Level_List, Load_Items_List } from '../../../../../../Store/Actions/Setting.Action'
import { Create_Warning_Messages } from '../../../../../../Store/Actions/Messages.Actions'

import { Button, TextField, FormControl, InputLabel, Select, CircularProgress, FormGroup, FormControlLabel, Checkbox } from '@mui/material'

import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import LaptopIcon from '@mui/icons-material/Laptop'

class PengajuanMPPForm extends React.Component {
    state = {
        Department: '',
        Level: '',
        Sub: '',
        Tahun: new Date().getFullYear(),
        DetailJumlahMpp: [
            {
                Bulan: '',
                StatusKaryawan: '',
                Jumlah: '',
                Items: [],
            }
        ],
        isConfirm: false,
        Keterangan: '',
    }

    componentDidMount() {
        const { MPPActionId } = this.props
        if (MPPActionId !== null) {
            this.props.get_MPP_Data(MPPActionId)
            this.props.Load_Items_List()
            this.props.Load_Level_List()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.MPPActionId !== prevProps.MPPActionId) {
            this.props.get_MPP_Data(this.props.MPPActionId)
        }

        if (this.props.MPPData !== prevProps.MPPData) {
            const { MPPData } = this.props

            this.setState({
                Department: MPPData.DepartemenId ? MPPData.DepartemenId : '',
                Level: MPPData.LevelId ? MPPData.LevelId : '',
                Sub: MPPData.Sub ? MPPData.Sub : '',
                Tahun: MPPData.Tahun ? MPPData.Tahun : '',
                Keterangan: MPPData.Keterangan ? MPPData.Keterangan : '',
            })
        }

        if (this.props.MPPList !== prevProps.MPPList) {
            const { MPPList } = this.props

            const newMPPList = []

            MPPList.forEach((item) => {
                const Bulan = item.BulanBuka || item.BulanBuka === 0 ? item.BulanBuka : ''
                const StatusKaryawan = item.StatusKaryawan ? item.StatusKaryawan : ''
                const Jumlah = item.Jumlah ? item.Jumlah : ''
                const MPPListItems = item.Items ? item.Items : []

                const Items = []

                for (const x of MPPListItems) {
                    const BulanItems = x.BulanBuka || x.BulanBuka === 0 ? x.BulanBuka : ''
                    const KebutuhanItems = x.Item ? x.Item : ''
                    const JumlahItems = x.Jumlah ? x.Jumlah : ''
                    const newItems = { Bulan: BulanItems, Kebutuhan: KebutuhanItems, Jumlah: JumlahItems }
                    Items.push(newItems)
                }

                const MPPListComp = { Bulan: Bulan, StatusKaryawan: StatusKaryawan, Jumlah: Jumlah, Items: Items }
                newMPPList.push(MPPListComp)
            })

            this.setState({ DetailJumlahMpp: newMPPList })
        }
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onChangeFieldMinMax = (e, Min, Max) => {
        if (Number(e.target.value) <= Max) {
            this.setState({ [e.target.name]: e.target.value })
        } else {
            this.props.Create_Warning_Messages(null, `Year Max ${Max}`)
        }
    }

    OnChangeCheckbox = E => {
        this.setState({ [E.target.name]: !this.state[E.target.name] })
    }

    SelectFormOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    OnChangeMapMPP = (e, Index, Order) => {
        const DetailJumlahMpp = this.state.DetailJumlahMpp
        if (Order === 1) {
            DetailJumlahMpp[Index].Bulan = e.target.value
            const DetailJumlahMpp_Items = DetailJumlahMpp[Index].Items

            DetailJumlahMpp_Items.forEach((element, index) => {
                DetailJumlahMpp_Items[index].Bulan = e.target.value
            })
        } else if (Order === 2) {
            DetailJumlahMpp[Index].StatusKaryawan = e.target.value
        } else if (Order === 3) {
            const DetailJumlahMpp_Items = DetailJumlahMpp[Index].Items
            DetailJumlahMpp[Index].Jumlah = e.target.value

            DetailJumlahMpp_Items.forEach((element, index) => {
                DetailJumlahMpp_Items[index].Jumlah = e.target.value
            })
        } else {

        }
        this.setState({ DetailJumlahMpp: DetailJumlahMpp })
    }

    AddDetailJumlahMPP = () => {
        const DetailJumlahMpp = this.state.DetailJumlahMpp
        if ((DetailJumlahMpp[DetailJumlahMpp.length - 1].Bulan || DetailJumlahMpp[DetailJumlahMpp.length - 1].Bulan === 0) && DetailJumlahMpp[DetailJumlahMpp.length - 1].StatusKaryawan && DetailJumlahMpp[DetailJumlahMpp.length - 1].Jumlah > 0) {
            const newDetailJumlahMpp = { Bulan: '', StatusKaryawan: '', Jumlah: '', Items: [] }
            DetailJumlahMpp.push(newDetailJumlahMpp)
            this.setState({ DetailJumlahMpp: DetailJumlahMpp })
        } else {
            this.props.Create_Warning_Messages(null, 'Please Fill in the Blank Box First')
        }
    }

    DeleteDetailJumlahMPP = (Index) => {
        if (Index > 0) {
            const DetailJumlahMpp = this.state.DetailJumlahMpp
            DetailJumlahMpp.splice(Index, 1)
            this.setState({ DetailJumlahMpp: DetailJumlahMpp })
        }
    }

    OnChangeMapItem = (e, Index, Index_Index, Order) => {
        const DetailJumlahMpp = this.state.DetailJumlahMpp
        const DetailJumlahMppItems = DetailJumlahMpp[Index].Items

        if (Order === 1) {
            DetailJumlahMppItems[Index_Index].Bulan = e.target.value
        } else if (Order === 2) {
            DetailJumlahMppItems[Index_Index].Kebutuhan = e.target.value
        } else if (Order === 3) {
            DetailJumlahMppItems[Index_Index].Jumlah = e.target.value
        } else {

        }
        this.setState({ DetailJumlahMpp: DetailJumlahMpp })
    }

    AddDetailJumlahMPPItem = (Index) => {
        const DetailJumlahMpp = this.state.DetailJumlahMpp
        const DetailJumlahMppItems = DetailJumlahMpp[Index].Items

        if (!((DetailJumlahMpp[DetailJumlahMpp.length - 1].Bulan || DetailJumlahMpp[DetailJumlahMpp.length - 1].Bulan === 0) && DetailJumlahMpp[DetailJumlahMpp.length - 1].StatusKaryawan && DetailJumlahMpp[DetailJumlahMpp.length - 1].Jumlah > 0)) {
            this.props.Create_Warning_Messages(null, 'Please complete the MPP detail filling process first')
        } else {
            if (DetailJumlahMppItems.length === 0 || ((DetailJumlahMppItems[DetailJumlahMppItems.length - 1].Bulan || DetailJumlahMppItems[DetailJumlahMppItems.length - 1].Bulan === 0) && DetailJumlahMppItems[DetailJumlahMppItems.length - 1].Kebutuhan && DetailJumlahMppItems[DetailJumlahMppItems.length - 1].Jumlah > 0)) {
                // const newDetailJumlahMppItem = { Bulan: '', Kebutuhan: '', Jumlah: '' }
                const newDetailJumlahMppItem = { Bulan: DetailJumlahMpp[Index].Bulan, Kebutuhan: '', Jumlah: DetailJumlahMpp[Index].Jumlah }
                DetailJumlahMppItems.push(newDetailJumlahMppItem)
                this.setState({ DetailJumlahMppItems: DetailJumlahMppItems })
            } else {
                this.props.Create_Warning_Messages(null, 'Please complete the MPP Items detail filling process first')
            }
        }
    }

    DeleteDetailJumlahMPPItem = (Index, Index_Index) => {
        const DetailJumlahMpp = this.state.DetailJumlahMpp
        const DetailJumlahMppItems = DetailJumlahMpp[Index].Items

        DetailJumlahMppItems.splice(Index_Index, 1)
        this.setState({ DetailJumlahMpp: DetailJumlahMpp })
    }

    formOnSubmit = (e) => {
        e.preventDefault()
        const MPPData = this.props.MPPData
        const User = this.props.User
        const DepartmentOption = User.Department ? User.Department : []
        this.props.Update_MPP(
            MPPData.Id,
            DepartmentOption.length > 1 ? this.state.Department : DepartmentOption[0].DepartemenId,
            this.state.Level,
            this.state.Sub,
            this.state.Tahun,
            this.state.DetailJumlahMpp,
            this.state.Keterangan,
        )
    }

    render() {
        const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }
        const st_divaddtextfield = { display: 'flex', alignItems: 'center', margin: '0 0 2% 2%' }
        const st_TextSmall = { width: '20%', margin: '0 1% 0 0' }
        const st_icon = { color: 'grey', fontSize: '180%', margin: '0 0 0 1%', cursor: 'pointer' }

        const {
            Department,
            Level,
            Sub,
            Tahun,
            DetailJumlahMpp,
            isConfirm,
            Keterangan,
        } = this.state

        const OpBulan = [
            {
                Value: 0,
                Item: 'Januari'
            },
            {
                Value: 1,
                Item: 'Februari'
            },
            {
                Value: 2,
                Item: 'Maret'
            },
            {
                Value: 3,
                Item: 'April'
            },
            {
                Value: 4,
                Item: 'Mei'
            },
            {
                Value: 5,
                Item: 'Juni'
            },
            {
                Value: 6,
                Item: 'Juli'
            }, {
                Value: 7,
                Item: 'Agustus'
            },
            {
                Value: 8,
                Item: 'September'
            },
            {
                Value: 9,
                Item: 'Oktober'
            },
            {
                Value: 10,
                Item: 'November'
            },
            {
                Value: 11,
                Item: 'Desember'
            }
        ]

        const OpJenisKaryawan = [
            'Permanent',
            'Contract',
            // 'Intern',
            // 'Outsource'
        ]

        const User = this.props.User
        const DepartmentOption = User.Department ? User.Department : []
        const LevelList = this.props.LevelList
        // const OpLevelList = LevelList ? LevelList.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0)) : []
        const ItemsList = this.props.ItemsList
        const isLevelLoading = this.props.isLevelLoading
        const isItemsLoading = this.props.isItemsLoading
        const isMppAddLoading = this.props.isMppAddLoading
        const isMPPActionGetDataLoading = this.props.isMPPActionGetDataLoading

        return (
            <Fragment>
                {isLevelLoading || isItemsLoading || isMPPActionGetDataLoading ?
                    <Fragment>
                        <center>
                            <CircularProgress />
                        </center>
                    </Fragment> :
                    <form
                        onSubmit={this.formOnSubmit}
                    >
                        <h3>
                            Edit MPP Submission
                        </h3>
                        {DepartmentOption.length > 1 ?
                            <FormControl
                                style={st_textfield}
                                variant="filled"
                                required
                            >
                                <InputLabel
                                    shrink
                                >
                                    Department
                                </InputLabel>
                                <Select
                                    native
                                    style={{ backgroundColor: 'white' }}
                                    onChange={(e) => this.SelectFormOnChange(e)}
                                    label="Department"
                                    name='Department'
                                    value={Department}
                                >
                                    <option value="" disabled> -- select an option -- </option>
                                    {DepartmentOption.map((option, index) =>
                                        <option key={`department_${index}`} value={option.DepartemenId}>{option.DepartemenNama}</option>
                                    )}
                                </Select>
                            </FormControl> :
                            < TextField
                                style={{ ...st_textfield, backgroundColor: '#E0E0E0' }}
                                variant='outlined'
                                type='text'
                                label='Department'
                                name='Department'
                                required
                                value={DepartmentOption[0].DepartemenNama}
                                disabled
                            />}
                        <FormControl
                            style={st_textfield}
                            variant="filled"
                            required
                        >
                            <InputLabel
                                shrink
                            >
                                Level
                            </InputLabel>
                            <Select
                                native
                                style={{ backgroundColor: 'white' }}
                                onChange={(e) => this.SelectFormOnChange(e)}
                                label="Level"
                                name='Level'
                                value={Level}
                            >
                                <option value="" disabled> -- select an option -- </option>
                                {LevelList.map((option, index) =>
                                    <option key={`LevelList_${index}`} value={option.Id}>{option.Nama}</option>
                                )}
                            </Select>
                        </FormControl>
                        <TextField
                            style={st_textfield}
                            variant='outlined'
                            type='text'
                            label='Job Title'
                            name='Sub'
                            required
                            value={Sub ? Sub : ''}
                            onChange={this.onChangeField}
                        />
                        <TextField
                            // style={{ ...st_textfield, backgroundColor: '#E0E0E0' }}
                            style={{ ...st_textfield }}
                            variant='outlined'
                            InputLabelProps={{ shrink: true }}
                            type='number'
                            label='Year'
                            name='Tahun'
                            required
                            value={Tahun ? Tahun : ''}
                            // disabled
                            onChange={(e) => this.onChangeFieldMinMax(e, new Date().getFullYear(), new Date().getFullYear() + 1)}
                        />

                        <h3>
                            MPP Details
                        </h3>
                        {DetailJumlahMpp.map((item, Index) => (
                            <Fragment
                                key={`DetailJumlahMpp_${Index}`}
                            >
                                <div
                                    style={st_divaddtextfield}
                                >
                                    <span
                                        style={{ marginRight: '1%' }}
                                    >
                                        {`MPP ${Index + 1}`}
                                    </span>
                                    <FormControl
                                        style={st_TextSmall}
                                        variant="filled"
                                        required
                                    >
                                        <InputLabel
                                            shrink
                                        >
                                            Month
                                        </InputLabel>
                                        <Select
                                            native
                                            style={{ backgroundColor: 'white' }}
                                            onChange={(e) => this.OnChangeMapMPP(e, Index, 1)}
                                            label="Month"
                                            name='WaktuPembukaan'
                                            value={DetailJumlahMpp[Index].Bulan}
                                        >
                                            <option value="" disabled> -- select an option -- </option>
                                            {OpBulan.map((option, index) =>
                                                <option key={`OpBulan_${Index}_${index}`} value={option.Value}>{option.Item}</option>
                                            )}
                                        </Select>
                                    </FormControl>
                                    <FormControl
                                        style={st_TextSmall}
                                        variant="filled"
                                        required
                                    >
                                        <InputLabel
                                            shrink
                                        >
                                            Employee Status
                                        </InputLabel>
                                        <Select
                                            native
                                            style={{ backgroundColor: 'white' }}
                                            onChange={(e) => this.OnChangeMapMPP(e, Index, 2)}
                                            label="Employee Status"
                                            name='StatusKaryawan'
                                            value={DetailJumlahMpp[Index].StatusKaryawan}
                                        >
                                            <option value="" disabled> -- select an option -- </option>
                                            {OpJenisKaryawan.map((option, index) =>
                                                <option key={`OpJenisKaryawan_${Index}_${index}`} value={option}>{option}</option>
                                            )}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        style={st_TextSmall}
                                        variant='outlined'
                                        type='number'
                                        label='Quantity'
                                        name='Jumlah'
                                        required
                                        value={DetailJumlahMpp[Index].Jumlah}
                                        onChange={(e) => this.OnChangeMapMPP(e, Index, 3)}
                                    />
                                    {Index === 0 ?
                                        <Fragment>
                                        </Fragment> :
                                        <Fragment>
                                            <DeleteOutlineRoundedIcon
                                                onClick={(e) => this.DeleteDetailJumlahMPP(Index)}
                                                style={st_icon}
                                            />
                                        </Fragment>}
                                    <LaptopIcon
                                        onClick={(e) => this.AddDetailJumlahMPPItem(Index)}
                                        style={st_icon}
                                    />
                                    <AddCircleRoundedIcon
                                        onClick={this.AddDetailJumlahMPP}
                                        style={st_icon}
                                    />
                                </div>
                                {DetailJumlahMpp[Index].Items.map((item_item, Index_Index) => (
                                    <Fragment
                                        key={`DetailJumlahMpp_Items_${Index}_${Index_Index}`}>
                                        <div
                                            style={{ ...st_divaddtextfield, marginLeft: '10%' }}
                                        >
                                            <span
                                                style={{ marginRight: '1%' }}
                                            >
                                                {`MPP ${Index + 1} Item ${Index_Index + 1}`}
                                            </span>
                                            {/* <FormControl
                                                style={st_TextSmall}
                                                variant="filled"
                                                required
                                            >
                                                <InputLabel
                                                    shrink
                                                >
                                                    Month
                                                </InputLabel>
                                                <Select
                                                    native
                                                    style={{ backgroundColor: 'white' }}
                                                    onChange={(e) => this.OnChangeMapItem(e, Index, Index_Index, 1)}
                                                    label="Month"
                                                    name='WaktuPembukaan'
                                                    value={DetailJumlahMpp[Index].Items[Index_Index].Bulan}
                                                >
                                                    <option value="" disabled> -- select an option -- </option>
                                                    {OpBulan.map((option, index) =>
                                                        <option key={`OpBulan_${Index}_${index}`} value={option.Value}>{option.Item}</option>
                                                    )}
                                                </Select>
                                            </FormControl> */}
                                            <FormControl
                                                style={{ ...st_TextSmall }}
                                                variant="filled"
                                                disabled
                                            >
                                                <InputLabel
                                                    shrink
                                                >
                                                    Month
                                                </InputLabel>
                                                <Select
                                                    native
                                                    style={{ backgroundColor: '#E0E0E0' }}
                                                    label="Month"
                                                    name='Month'
                                                    value={DetailJumlahMpp[Index].Items[Index_Index].Bulan}
                                                >
                                                    <option value="" disabled> -- select an option -- </option>
                                                    {OpBulan.map((option, index) =>
                                                        <option key={`OpBulan_${Index}_${index}`} value={option.Value}>{option.Item}</option>
                                                    )}
                                                </Select>
                                            </FormControl>
                                            <FormControl
                                                style={st_TextSmall}
                                                variant="filled"
                                                required
                                            >
                                                <InputLabel
                                                    shrink
                                                >
                                                    Item
                                                </InputLabel>
                                                <Select
                                                    native
                                                    style={{ backgroundColor: 'white' }}
                                                    onChange={(e) => this.OnChangeMapItem(e, Index, Index_Index, 2)}
                                                    label="Item"
                                                    name='Kebutuhan'
                                                    value={DetailJumlahMpp[Index].Items[Index_Index].Kebutuhan}
                                                >
                                                    <option value="" disabled> -- select an option -- </option>
                                                    {ItemsList.map((option, index) =>
                                                        <option key={`ItemsList_${Index}_${index}`} value={option.Id}>{option.Nama}</option>
                                                    )}
                                                </Select>
                                            </FormControl>
                                            {/* <TextField
                                                style={st_TextSmall}
                                                variant='outlined'
                                                type='number'
                                                label='Quantity'
                                                name='Jumlah'
                                                required
                                                value={DetailJumlahMpp[Index].Items[Index_Index].Jumlah}
                                                onChange={(e) => this.OnChangeMapItem(e, Index, Index_Index, 3)}
                                            /> */}
                                            <TextField
                                                style={{ ...st_TextSmall, backgroundColor: '#E0E0E0' }}
                                                variant='outlined'
                                                type='number'
                                                label='Quantity'
                                                name='Jumlah'
                                                disabled
                                                value={DetailJumlahMpp[Index].Items[Index_Index].Jumlah}
                                            />

                                            <Fragment>
                                                <DeleteOutlineRoundedIcon
                                                    onClick={(e) => this.DeleteDetailJumlahMPPItem(Index, Index_Index)}
                                                    style={st_icon}
                                                />
                                            </Fragment>
                                            <LaptopIcon
                                                onClick={(e) => this.AddDetailJumlahMPPItem(Index, Index_Index)}
                                                style={st_icon}
                                            />
                                        </div>
                                    </Fragment>
                                ))}
                            </Fragment>
                        ))}

                        <h3>
                            Additional Notes
                        </h3>
                        < TextField
                            style={st_textfield}
                            variant='outlined'
                            type='text'
                            label='Note'
                            name='Keterangan'
                            value={Keterangan ? Keterangan : ''}
                            onChange={this.onChangeField}
                        />
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox color='primary' onChange={this.OnChangeCheckbox} name="isConfirm" value={isConfirm} disabled={isMppAddLoading ? true : false} />}
                                label="The Data That I Entered is Correct"
                            />
                        </FormGroup>
                        <center>
                            <Button
                                variant='contained'
                                style={{ width: '90%', margin: '5% 0 5% 0' }}
                                type='submit'
                                disabled={isMppAddLoading || !isConfirm ? true : false}
                            >
                                {isMppAddLoading ? <CircularProgress /> : 'Create MPP Submission'}
                            </Button>
                        </center>
                    </form>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    MPPActionId: state.MPP.MPPActionId,
    isMPPActionGetDataLoading: state.MPP.isMPPActionGetDataLoading,
    MPPData: state.MPP.MPPData,
    MPPList: state.MPP.MPPList,
    User: state.Auth.User,
    LevelList: state.Setting.LevelList,
    ItemsList: state.Setting.ItemsList,
    isLevelLoading: state.Setting.isLevelLoading,
    isItemsLoading: state.Setting.isItemsLoading,
    isMppAddLoading: state.MPP.isMppAddLoading,
})

export default connect(mapStateToProps, { Load_Level_List, Load_Items_List, Create_Warning_Messages, get_MPP_Data, Update_MPP })(PengajuanMPPForm)