import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Account_Data, Update_Account } from '../../../../../../Store/Actions/Setting.Action'
import { Create_Warning_Messages } from '../../../../../../Store/Actions/Messages.Actions'

import { TextField, Switch, Button, CircularProgress } from '@mui/material'

class Edit extends React.Component {
    state = {
        isActive: false,
        isActiveted: false,
        isPelamar: false,
        isKaryawan: false,
        isAdmin: false,
        isBiodataComplete: false,
        isBiodataExtend: false,
    }

    componentDidMount() {
        const { AccountActionId } = this.props
        if (AccountActionId !== null) {
            this.props.get_Account_Data(AccountActionId)
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.AccountActionId !== prevProps.AccountActionId) {
            this.props.get_Account_Data(this.props.AccountActionId)
        }
        if (this.props.AccountDetail !== prevProps.AccountDetail) {
            const AccountDetail = this.props.AccountDetail
            this.setState({
                isActive: AccountDetail?.isActive ? AccountDetail.isActive : false,
                isActiveted: AccountDetail?.isActiveted ? AccountDetail.isActiveted : false,
                isPelamar: AccountDetail?.isPendaftar ? AccountDetail.isPendaftar : false,
                isKaryawan: AccountDetail?.isKaryawan ? AccountDetail.isKaryawan : false,
                isAdmin: AccountDetail?.isAdmin ? AccountDetail.isAdmin : false,
                isBiodataComplete: AccountDetail?.isBiodataComplete ? AccountDetail.isBiodataComplete : false,
                isBiodataExtend: AccountDetail?.isBiodataExtend ? AccountDetail.isBiodataExtend : false,
            })
        }
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onChangeSwitch = (e) => {
        if (e.target.name === 'isPelamar') {
            this.setState({
                isKaryawan: false,
                isAdmin: false
            })
        } else if (e.target.name === 'isKaryawan' && this.state.isKaryawan === true) {
            this.setState({
                isPelamar: false,
                isAdmin: false
            })
        } else if (e.target.name === 'isKaryawan') {
            this.setState({ isPelamar: false })
        }
        this.setState({ [e.target.name]: !this.state[e.target.name] })
    }

    formOnSubmit = (e) => {
        e.preventDefault()
        if (this.state.isPelamar || this.state.isKaryawan || this.state.isAdmin) {
            const AccountDetail = this.props.AccountDetail
            this.props.Update_Account(
                AccountDetail.Id,
                this.state.isActive,
                this.state.isPelamar,
                this.state.isAdmin,
                this.state.isKaryawan,
                this.state.isActiveted,
                this.state.isBiodataComplete,
                this.state.isBiodataExtend,
            )
        } else {
            this.props.Create_Warning_Messages(null, 'Role Harus diinput')
        }
    }

    render() {
        const isAccountDetailLoading = this.props.isAccountDetailLoading
        const AccountDetail = this.props.AccountDetail

        const {
            isActive,
            isActiveted,
            isPelamar,
            isKaryawan,
            isAdmin,
            isBiodataComplete,
            isBiodataExtend,
        } = this.state

        // const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }
        const st_textfield_disable = { marginTop: '10px', marginBottom: '10px', width: '100%', backgroundColor: '#E0E0E0' }

        return (
            <Fragment>
                <form
                    onSubmit={this.formOnSubmit}
                >
                    <TextField
                        style={st_textfield_disable}
                        variant='outlined'
                        type='text'
                        label='Id'
                        name='Id'
                        disabled
                        value={AccountDetail?.Id ? AccountDetail.Id : ''}
                    />
                    <TextField
                        style={st_textfield_disable}
                        variant='outlined'
                        type='email'
                        label='Email'
                        name='Email'
                        disabled
                        value={AccountDetail?.Email ? AccountDetail.Email : ''}
                    />
                    <TextField
                        style={st_textfield_disable}
                        variant='outlined'
                        type='text'
                        label='User Name'
                        name='UserName'
                        disabled
                        value={AccountDetail?.UserName ? AccountDetail.UserName : ''}
                    />
                    <label>
                        <b>
                            isActive?
                        </b>
                    </label><br />
                    <label>
                        Tidak
                    </label>
                    <Switch
                        style={{ color: isActive ? 'green' : 'red' }}
                        name='isActive'
                        checked={isActive}
                        onClick={this.onChangeSwitch}
                    />
                    <label>
                        Ya
                    </label><br /><br />
                    <label>
                        <b>
                            isActiveted?
                        </b>
                    </label><br />
                    <label>
                        Tidak
                    </label>
                    <Switch
                        style={{ color: isActiveted ? 'green' : 'red' }}
                        name='isActiveted'
                        checked={isActiveted}
                        onClick={this.onChangeSwitch}
                    />
                    <label>
                        Ya
                    </label><br /><br />
                    <label>
                        <b>
                            Akun Pelamar?
                        </b>
                    </label><br />
                    <label>
                        Tidak
                    </label>
                    <Switch
                        style={{ color: isPelamar ? 'green' : 'red' }}
                        name='isPelamar'
                        checked={isPelamar}
                        onClick={this.onChangeSwitch}
                    />
                    <label>
                        Ya
                    </label><br /><br />
                    <label>
                        <b>
                            Akun Karyawan?
                        </b>
                    </label><br />
                    <label>
                        Tidak
                    </label>
                    <Switch
                        style={{ color: isKaryawan ? 'green' : 'red' }}
                        name='isKaryawan'
                        checked={isKaryawan}
                        onClick={this.onChangeSwitch}
                    />
                    <label>
                        Ya
                    </label><br /><br />
                    <label>
                        <b>
                            Akun Admin?
                        </b>
                    </label><br />
                    <label>
                        Tidak
                    </label>
                    <Switch
                        style={{ color: isAdmin ? 'green' : 'red' }}
                        name='isAdmin'
                        disabled={isKaryawan ? false : true}
                        checked={isAdmin}
                        onClick={this.onChangeSwitch}
                    />
                    <label>
                        Ya
                    </label><br /><br />
                    <label>
                        <b>
                            isBiodataComplete?
                        </b>
                    </label><br />
                    <label>
                        Tidak
                    </label>
                    <Switch
                        style={{ color: isBiodataComplete ? 'green' : 'red' }}
                        name='isBiodataComplete'
                        checked={isBiodataComplete}
                        onClick={this.onChangeSwitch}
                    />
                    <label>
                        Ya
                    </label><br /><br />
                    <label>
                        <b>
                            isBiodataExtend?
                        </b>
                    </label><br />
                    <label>
                        Tidak
                    </label>
                    <Switch
                        style={{ color: isBiodataExtend ? 'green' : 'red' }}
                        name='isBiodataExtend'
                        checked={isBiodataExtend}
                        onClick={this.onChangeSwitch}
                    />
                    <label>
                        Ya
                    </label><br /><br />
                    <TextField
                        style={st_textfield_disable}
                        variant='outlined'
                        type='text'
                        label='BiodataId'
                        name='BiodataId'
                        disabled
                        value={AccountDetail?.BiodataId ? AccountDetail.BiodataId : ''}
                    />
                    <center>
                        <Button
                            variant='contained'
                            style={{ width: '90%', margin: '5% 0 5% 0' }}
                            type='submit'
                            disabled={isAccountDetailLoading ? true : false}
                        >
                            {isAccountDetailLoading ? <CircularProgress /> : 'Update'}
                        </Button>
                    </center>
                </form>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    AccountActionId: state.Account.AccountActionId,
    isAccountDetailLoading: state.Setting.isAccountDetailLoading,
    AccountDetail: state.Setting.AccountDetail,
})

export default connect(mapStateToProps, { Create_Warning_Messages, get_Account_Data, Update_Account })(Edit)