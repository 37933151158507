import axios from 'axios'

import {
    REPORT_MPP_LOADING,
    REPORT_MPP_LOADED,
    REPORT_MPP_LOADING_DONE,
    REPORT_MPP_ITEM_LOADING,
    REPORT_MPP_ITEM_LOADED,
    REPORT_MPP_ITEM_LOADING_DONE,
    REPORT_JOBDESC_LOADING,
    REPORT_JOBDESC_LOADED,
    REPORT_JOBDESC_LOADING_DONE,
    REPORT_PR_LOADING,
    REPORT_PR_LOADED,
    REPORT_PR_LOADING_DONE,
    REPORT_PR_REALIZATION_LOADING,
    REPORT_PR_REALIZATION_LOADED,
    REPORT_PR_REALIZATION_LOADING_DONE,
    REPORT_MPP_DONE_LOADING,
    REPORT_MPP_DONE_LOADED,
    REPORT_MPP_DONE_LOADING_DONE,
    REPORT_MPP_DETAILED_LOADING,
    REPORT_MPP_DETAILED_LOADED,
    REPORT_MPP_DETAILED_LOADING_DONE,
    REPORT_HR_LOADING,
    REPORT_HR_LOADED,
    REPORT_HR_LOADING_DONE,
    REPORT_DEPARTMENT_LOADING,
    REPORT_DEPARTMENT_LOADED,
    REPORT_DEPARTMENT_LOADING_DONE,
} from './Type.Action'

import {
    // Create_Error_Messages,
    // Create_Success_Messages
} from './Messages.Actions'

import { Get_IpAddres, tokenConfig, ErrorHandler } from './Base.Action'

import { Error_Messages_Standardization } from '../../Global'

export const get_Report_MPP = () => async (dispatch, getState) => {
    try {
        dispatch({ type: REPORT_MPP_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/report/mpp/approval`, tokenConfig(getState))
        if (Data) {
            dispatch({
                type: REPORT_MPP_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: REPORT_MPP_LOADING_DONE })
        } else {
            dispatch({ type: REPORT_MPP_LOADING_DONE })
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'get_Report_MPP')
    }
}

export const get_Report_MPP_Item = () => async (dispatch, getState) => {
    try {
        dispatch({ type: REPORT_MPP_ITEM_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/report/mpp/approval/item`, tokenConfig(getState))
        if (Data) {
            dispatch({
                type: REPORT_MPP_ITEM_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: REPORT_MPP_ITEM_LOADING_DONE })
        } else {
            dispatch({ type: REPORT_MPP_ITEM_LOADING_DONE })
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'get_Report_MPP_Item')
    }
}

export const get_Report_Done = () => async (dispatch, getState) => {
    try {
        dispatch({ type: REPORT_MPP_DONE_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/report/mpp/approval/done`, tokenConfig(getState))
        if (Data) {
            dispatch({
                type: REPORT_MPP_DONE_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: REPORT_MPP_DONE_LOADING_DONE })
        } else {
            dispatch({ type: REPORT_MPP_DONE_LOADING_DONE })
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'get_Report_MPP')
    }
}

export const get_Report_MPP_Detailed = () => async (dispatch, getState) => {
    try {
        dispatch({ type: REPORT_MPP_DETAILED_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/report/mpp/detailed`, tokenConfig(getState))
        if (Data) {
            dispatch({
                type: REPORT_MPP_DETAILED_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: REPORT_MPP_DETAILED_LOADING_DONE })
        } else {
            dispatch({ type: REPORT_MPP_DETAILED_LOADING_DONE })
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'get_Report_MPP_Detailed')
    }
}

export const get_Report_JobDesc = () => async (dispatch, getState) => {
    try {
        dispatch({ type: REPORT_JOBDESC_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/report/jobdesc/`, tokenConfig(getState))
        if (Data) {
            dispatch({
                type: REPORT_JOBDESC_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: REPORT_JOBDESC_LOADING_DONE })
        } else {
            dispatch({ type: REPORT_JOBDESC_LOADING_DONE })
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'get_Report_JobDesc')
    }
}

export const get_Report_PR = () => async (dispatch, getState) => {
    try {
        dispatch({ type: REPORT_PR_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/report/pr/`, tokenConfig(getState))
        if (Data) {
            dispatch({
                type: REPORT_PR_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: REPORT_PR_LOADING_DONE })
        } else {
            dispatch({ type: REPORT_PR_LOADING_DONE })
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'get_Report_PR')
    }
}

export const get_Report_PR_Realization = () => async (dispatch, getState) => {
    try {
        dispatch({ type: REPORT_PR_REALIZATION_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/report/pr/realization`, tokenConfig(getState))
        if (Data) {
            dispatch({
                type: REPORT_PR_REALIZATION_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: REPORT_PR_REALIZATION_LOADING_DONE })
        } else {
            dispatch({ type: REPORT_PR_REALIZATION_LOADING_DONE })
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'get_Report_PR')
    }
}

export const get_Report_HR = () => async (dispatch, getState) => {
    try {
        dispatch({ type: REPORT_HR_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/report/hr/`, tokenConfig(getState))
        if (Data) {
            dispatch({
                type: REPORT_HR_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: REPORT_HR_LOADING_DONE })
        } else {
            dispatch({ type: REPORT_HR_LOADING_DONE })
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'get_Report_HR')
    }
}

export const get_Report_Department = () => async (dispatch, getState) => {
    try {
        dispatch({ type: REPORT_DEPARTMENT_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/report/department/`, tokenConfig(getState))
        if (Data) {
            dispatch({
                type: REPORT_DEPARTMENT_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: REPORT_DEPARTMENT_LOADING_DONE })
        } else {
            dispatch({ type: REPORT_DEPARTMENT_LOADING_DONE })
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'get_Report_Department')
    }
}