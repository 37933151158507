import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_List_OnBoarding_Detail } from '../../../../../../Store/Actions/Job.Action'

import { Skeleton } from '@mui/material'

class DetailOnBoarding extends React.Component {
    componentDidMount() {
        const { JobActionId, AccountActionId } = this.props
        this.props.get_List_OnBoarding_Detail(JobActionId, AccountActionId)
    }
    render() {
        const isListOnBoardingDetailLoading = this.props.isListOnBoardingDetailLoading
        const ListOnBoardingDetail = this.props.ListOnBoardingDetail
        
        return (
            <Fragment>
                {isListOnBoardingDetailLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5,].map((item) =>
                            <Skeleton
                                key={`DetailMenunggu_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <h2>OnBoarding</h2>
                        <div
                            style={{
                                margin: '1% 0 1% 0', padding: '1%', border: `1px solid ${ListOnBoardingDetail?.OBStatus ? ListOnBoardingDetail.OBStatus === 'Selesai' ? 'Green' :
                                    'orange' : 'black'}`
                            }}
                        >
                            <center>
                                <h2
                                    style={{
                                        color: ListOnBoardingDetail?.OBStatus ? ListOnBoardingDetail.OBStatus === 'Selesai' ? 'Green' :
                                            'orange' : 'black'
                                    }}
                                >
                                    Status : {ListOnBoardingDetail?.OBStatus ? ListOnBoardingDetail.OBStatus : 'Status'}
                                </h2>
                            </center>
                            <h4>
                                Diupdate Pada:<br />
                                {ListOnBoardingDetail?.OBDate ? new Date(ListOnBoardingDetail.OBDate).toLocaleString(['ban', 'id']) : ''}<br /><br />
                                Oleh:<br />
                                {ListOnBoardingDetail?.BiodataNama ? ListOnBoardingDetail.BiodataNama : ''}<br /><br />
                                {/* Keterangan:<br /> */}
                                {/* {ListOnBoardingDetail?.OBKet ? ListOnBoardingDetail.OBKet : ''}<br /> */}
                            </h4>
                        </div>
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isListOnBoardingDetailLoading: state.Job.isListOnBoardingDetailLoading,
    ListOnBoardingDetail: state.Job.ListOnBoardingDetail,
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
})

export default connect(mapStateToProps, { get_List_OnBoarding_Detail })(DetailOnBoarding)