import React, { Fragment } from "react"

import { connect } from "react-redux"

import { get_Account_Action_Id } from '../../../Store/Actions/Account.Action'

import { Link as GoTo, Redirect } from 'react-router-dom'

import { Button, Badge, Drawer } from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'

import Logo from '../../../Img/Etana_logo.png'

class Header extends React.Component {
    state = {
        userOpen: false,
        isGoToLogIn: false,
        isGoToLogOut: false,
        isGoToLogProfile: false,
        isGoToProfileExtend: false,
        isDrawerOpen: false,
    }

    handleUserOpen = () => {
        this.setState({ userOpen: !this.state.userOpen })
    }

    handleImgClick = () => {
        window.location.href = '/'
    }

    goToEtana = () => {
        window.location.href = 'https://www.id.etanabiotech.com/'
    }

    goToHome = () => {
        window.location.href = '/'
    }

    goToLogin = () => {
        this.setState({ isGoToLogIn: !this.state.isGoToLogIn })
        // History.push('/login')
    }

    goToLogout = () => {
        this.handleUserOpen(false)
        this.setState({ isGoToLogOut: !this.state.isGoToLogOut })
        // History.push('/logout')
    }

    goToProfile = () => {
        const User = this.props.User
        const UserId = User.Id
        this.props.get_Account_Action_Id(UserId)
        this.handleUserOpen(false)
        this.setState({ isGoToProfile: !this.state.isGoToProfile })
        // this.props.history.push("/profile")
        // History.push('/profile')
    }

    goToProfileExtend = () => {
        const User = this.props.User
        const UserId = User.Id
        this.props.get_Account_Action_Id(UserId)
        this.handleUserOpen(false)
        this.setState({ isGoToProfileExtend: !this.state.isGoToProfileExtend })
        // this.props.history.push("/profile")
        // History.push('/profile')
    }

    toggleDrawer = (Condition) => {
        const newState = Condition ? Condition : !this.state.isDrawerOpen
        this.setState({ isDrawerOpen: newState })
    }

    render() {
        const isAuth = this.props.isAuth
        const User = this.props.User
        const UserName = User?.UserName ? User.UserName : 'User404'
        const isKaryawan = User?.isKaryawan ? User.isKaryawan : false
        // const isPendaftar = User?.isPendaftar ? User.isPendaftar : false
        const isAdmin = User?.isAdmin ? User.isAdmin : false
        const isBiodataExtend = User?.isBiodataExtend ? User.isBiodataExtend : false

        const {
            // userOpen,
            isGoToLogIn,
            isGoToLogOut,
            isGoToProfile,
            isGoToProfileExtend,
            isDrawerOpen,
        } = this.state

        const StyleMenu = { margin: '0 1% 0 0%', border: '0px solid red', fontSize: '18px', fontWeight: 'inherit', display: 'flex' }
        const defaultStyle = { border: '0px solid red', margin: ' 10px', color: 'black', fontSize: '120%', textDecoration: 'none', fontFamily: 'Montserrat, Segoe UI' }

        return (
            <Fragment>
                {isGoToLogIn ? <Redirect to="/login" /> : <Fragment></Fragment>}
                {isGoToLogOut ? <Redirect to="/logout" /> : <Fragment></Fragment>}
                {isGoToProfileExtend ? <Redirect to="/profileextend" /> : <Fragment></Fragment>}
                {isGoToProfile ? <Redirect to="/profile" /> : <Fragment></Fragment>}

                <div
                    style={{ ...StyleMenu, width: '100vw', }}
                >
                    <div
                        // style={{ border: '0px solid red', width:'20vw',  margin:'2vh 65vw 0 3vw'}}
                        style={{ border: '0px solid red', width: '20vw', maxWidth: '20vw', margin: '2% 65% 2% 3%', zIndex: '5' }}
                    >
                        <img
                            style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                            src={Logo}
                            alt="EtanaLogo"
                            onClick={this.handleImgClick}
                        />
                    </div>

                    <MenuIcon
                        style={{ height: '5vh', width: '10vw', maxWidth: '5vh', border: '2px solid black', borderRadius: '15px', padding: '1px', margin: '2% 2% 2% 0%', backgroundColor: 'white', zIndex: '5' }}
                        onClick={() => this.toggleDrawer(true)}
                    />
                </div>
                <Drawer
                    anchor="right"
                    open={isDrawerOpen}
                    onClick={() => this.toggleDrawer(false)}
                >
                    <div
                        style={{ width: '50vw', padding: '5%', fontFamily: 'Montserrat, Segoe UI' }}
                    >
                        <div
                            style={StyleMenu}
                        >
                            {isAuth ?
                                <Fragment>
                                    Hi,  {UserName}
                                </Fragment>
                                : <Fragment>
                                </Fragment>
                            }
                        </div>

                        <div
                            style={StyleMenu}
                        >
                            {isAuth ?
                                <div>
                                </div> :
                                <GoTo
                                    to=''
                                    // id='Navbar_etana.com'
                                    style={{ ...defaultStyle }}
                                    // onMouseOver={(e) => MouseHover(e)}
                                    // onMouseOut={(e) => MouseOut(e)}
                                    // onClick={this.goToEtana}
                                    onClick={this.goToHome}
                                >
                                    About Us
                                </GoTo>
                            }
                        </div>

                        <div
                            style={StyleMenu}
                        >
                            <GoTo
                                to='/Joblist'
                                // id='Navbar_JobList'
                                style={defaultStyle}
                            // onMouseOver={(e) => MouseHover(e)}
                            // onMouseOut={(e) => MouseOut(e)}
                            >
                                Vacancy
                            </GoTo>
                        </div>

                        {isAuth ?
                            <div
                                style={StyleMenu}
                            >
                                <GoTo
                                    to={isAuth ? isKaryawan || isAdmin ? '/dashboard' : '/applications' : '/'}
                                    // id='Navbar_JobList'
                                    // style={defaultStyle}
                                    style={{ ...defaultStyle, border: '0px solid red' }}
                                // onMouseOver={(e) => MouseHover(e)}
                                // onMouseOut={(e) => MouseOut(e)}
                                >
                                    {false ?
                                        <Badge
                                            badgeContent={10}
                                            color="primary"
                                            variant="standard"
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                        >
                                            {isAuth ? isKaryawan || isAdmin ? 'Dashboard' : 'Applications' : ''}
                                        </Badge> :
                                        <Fragment>
                                            {isAuth ? isKaryawan || isAdmin ? 'Dashboard' : 'Applications' : ''}
                                        </Fragment>
                                    }
                                </GoTo>
                            </div>
                            : <div></div>
                        }

                        {isAuth ?
                            <Fragment>
                                {!isBiodataExtend || isAdmin ?
                                    <div
                                        style={StyleMenu}
                                    >
                                        <GoTo
                                            to='/profile'
                                            // id='Navbar_JobList'
                                            style={defaultStyle}
                                        // onMouseOver={(e) => MouseHover(e)}
                                        // onMouseOut={(e) => MouseOut(e)}
                                        >
                                            Manage Account
                                        </GoTo>
                                    </div> :
                                    <Fragment>
                                    </Fragment>}

                                {isBiodataExtend || isAdmin ?
                                    <div
                                        style={StyleMenu}
                                    >
                                        <GoTo
                                            to='/profileextend'
                                            // id='Navbar_JobList'
                                            style={defaultStyle}
                                        // onMouseOver={(e) => MouseHover(e)}
                                        // onMouseOut={(e) => MouseOut(e)}
                                        >
                                            Manage Account Extend
                                        </GoTo>
                                    </div> :
                                    <Fragment>
                                    </Fragment>}

                                <div
                                    style={StyleMenu}
                                >
                                    <GoTo
                                        to='/logout'
                                        // id='Navbar_JobList'
                                        style={defaultStyle}
                                    // onMouseOver={(e) => MouseHover(e)}
                                    // onMouseOut={(e) => MouseOut(e)}
                                    >
                                        Log Out
                                    </GoTo>
                                </div>
                            </Fragment> :
                            <Fragment>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    style={{ width: '25vw', borderRadius: '10px', margin: '10px', fontFamily: 'Montserrat, Segoe UI' }}
                                    onClick={this.goToLogin}
                                >
                                    <b>
                                        Login
                                    </b>
                                </Button>
                            </Fragment>
                        }
                    </div>
                </Drawer>
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    isAuth: state.Auth.isAuth,
    User: state.Auth.User,
})

export default connect(mapStateToProps, { get_Account_Action_Id })(Header)