import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_PR_UnConfirm, get_PR_Confirm, get_PR_Action_Id } from '../../../../Store/Actions/PR.Action'

import { Collapse } from '@mui/material'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import GenericModals from '../../../Containers/GenericModals'
import CoTable from '../../../Containers/Table'

import { convertToMonthName } from '../../../../Global'

import DetailPR from './Modal/DetailPR'
import KonfirmasiPengajuanPR from './Modal/ACCPR/KonfirmasiPengajuanPR'

class ACCPR extends React.Component {
    state = {
        isUnConfimOpen: true,
        isConfimOpen: false,
    }
    componentDidMount() {
        this.props.get_PR_UnConfirm()
        this.props.get_PR_Confirm()
    }

    onClickActionId(Id) {
        this.props.get_PR_Action_Id(Id)
    }

    render() {
        const {
            isUnConfimOpen,
            isConfimOpen,
        } = this.state
        const RowDataInOnePage = 5

        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
                KeyName: 'index',
                SortingType: 'Number',
            },
            {
                Nama: 'Submission Number',
                width: '10%',
                KeyName: 'Id',
                SortingType: 'Number',
            },
            {
                Nama: 'Date',
                width: '10%',
                KeyName: 'TanggalPengajuan',
                SortingType: 'Date',
            },
            {
                Nama: 'Job Title',
                width: '10%',
                KeyName: 'NamaPosisi',
            },
            {
                Nama: 'Department',
                width: '10%',
                KeyName: 'Departemen',
            },
            {
                Nama: 'Level',
                width: '10%',
                KeyName: 'Level',
            },
            {
                Nama: 'Join Date',
                width: '10%',
                KeyName: 'BulanBuka',
            },
            {
                Nama: 'Detail',
                width: '10%',
            },
        ]

        const isPRGetUnConfirmLoading = this.props.isPRGetUnConfirmLoading
        const PRUnConfirm = this.props.PRUnConfirm
        console.log(PRUnConfirm);
        const BodyData = PRUnConfirm ? PRUnConfirm.map((item, index) => {
            const TanggalPengajuan = new Date(item.TanggalPengajuan).toLocaleString(['ban', 'id'])
            return {
                index: {
                    value: index + 1
                },
                Id: {
                    value: item.Id
                },
                TanggalPengajuan: {
                    value: item.TanggalPengajuan,
                    display: TanggalPengajuan
                },
                NamaPosisi: {
                    value: item.NamaPosisi
                },
                Departemen: {
                    value: item.DepNama
                },
                Level: {
                    value: item.LevelNama
                },
                BulanBuka: {
                    value: item.BulanBuka,
                    // display: convertToMonthName(item.BulanBuka) ? convertToMonthName(item.BulanBuka) : ' '
                    display: item.ExpectedMonth && item.ExpectedWeek ? `${convertToMonthName(item.ExpectedMonth)}/0${item.ExpectedWeek}`
                        : convertToMonthName(item.BulanBuka) ? convertToMonthName(item.BulanBuka) : ' '
                },
                Detail: {
                    value: item.Id,
                    display: (
                        <GenericModals
                            size='xl'
                            header='PR Submission Detail'
                            body={<KonfirmasiPengajuanPR />}
                            Buttonvariant='contained'
                            Buttoncolor='primary'
                            ButtononClickeven={() => this.onClickActionId(item.Id)}
                            Buttonlabel={'Detail'}
                        />
                    )
                }
            }
        }) : []

        const HeadData1 = [
            {
                Nama: 'No',
                width: '5%',
                KeyName: 'index',
                SortingType: 'Number',
            },
            {
                Nama: 'Submission Number',
                width: '10%',
                KeyName: 'Id',
                SortingType: 'Number',
            },
            {
                Nama: 'Date',
                width: '10%',
                KeyName: 'TanggalPengajuan',
                SortingType: 'Date',
            },
            {
                Nama: 'Job Title',
                width: '10%',
                KeyName: 'NamaPosisi',
            },
            {
                Nama: 'Department',
                width: '10%',
                KeyName: 'Departemen',
            },
            {
                Nama: 'Level',
                width: '10%',
                KeyName: 'Level',
            },
            {
                Nama: 'Join Date',
                width: '10%',
                KeyName: 'BulanBuka',
            },
            {
                Nama: 'Status',
                width: '10%',
                KeyName: 'Status',
            },
            {
                Nama: 'Detail',
                width: '10%',
            },
            {
                Nama: 'Edit',
                width: '10%',
            },
        ]

        const isPRGetConfirmLoading = this.props.isPRGetConfirmLoading
        const PRConfirm = this.props.PRConfirm
        const BodyData1 = PRConfirm ? PRConfirm.map((item, index) => {
            const TanggalPengajuan = new Date(item.TanggalPengajuan).toLocaleString(['ban', 'id'])
            return {
                index: {
                    value: index + 1
                },
                Id: {
                    value: item.Id
                },
                TanggalPengajuan: {
                    value: item.TanggalPengajuan,
                    display: TanggalPengajuan,
                },
                NamaPosisi: {
                    value: item.NamaPosisi
                },
                Departemen: {
                    value: item.DepNama
                },
                Level: {
                    value: item.LevelNama
                },
                BulanBuka: {
                    value: item.BulanBuka,
                    display: item.ExpectedMonth && item.ExpectedWeek ? `${convertToMonthName(item.ExpectedMonth)}/0${item.ExpectedWeek}`
                        : convertToMonthName(item.BulanBuka) ? convertToMonthName(item.BulanBuka) : ' '
                },
                Status: {
                    value: item.Status,
                    display: (
                        <span
                            style={{ color: item?.Status ? item.Status === 'Accept' ? 'green' : 'red' : 'black' }}
                        >
                            {item.Status}
                        </span>
                    )
                },
                Detail: {
                    value: item.Id,
                    display: (
                        <GenericModals
                            size='xl'
                            header='PR Detail'
                            body={<DetailPR />}
                            Buttoncolor='primary'
                            ButtononClickeven={() => this.onClickActionId(item.Id)}
                            Buttonlabel={'Detail'}
                        />
                    )
                },
                Edit: {
                    value: item.Id,
                    display: (
                        <GenericModals
                            size='xl'
                            header='PR Edit'
                            body={<DetailPR />}
                            Buttondisabled={true}
                            Buttoncolor='secondary'
                            ButtononClickeven={() => this.onClickActionId(item.Id)}
                            Buttonlabel={'Edit'}
                        />
                    )
                }
            }
        }) : []

        return (
            <Fragment>
                <h2>
                    PR Approval
                </h2>
                <h3
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => this.setState({ isUnConfimOpen: !this.state.isUnConfimOpen })}
                >
                    Pending Approval {isUnConfimOpen ? <ExpandLess /> : <ExpandMore />}
                </h3>
                <Collapse in={isUnConfimOpen} timeout="auto" unmountOnExit>
                    <CoTable
                        TableId={'ACCPR'}
                        HeadData={HeadData}
                        Data={BodyData}
                        isHeadLoading={isPRGetUnConfirmLoading}
                        isBodyLoading={isPRGetUnConfirmLoading}
                        isPagination={BodyData.length > RowDataInOnePage ? true : false}
                        MaxRowOnPage={RowDataInOnePage}
                        isPaginationLoading={isPRGetUnConfirmLoading}
                        PaginationCounter={
                            BodyData.length / RowDataInOnePage > Math.floor(BodyData.length / RowDataInOnePage) ?
                                Math.floor(BodyData.length / RowDataInOnePage) + 1 :
                                BodyData.length / RowDataInOnePage
                        }
                        isHeading={true}
                    />
                </Collapse>
                <h3
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => this.setState({ isConfimOpen: !this.state.isConfimOpen })}
                >
                    Approved {isConfimOpen ? <ExpandLess /> : <ExpandMore />}
                </h3>
                <Collapse in={isConfimOpen} timeout="auto" unmountOnExit>
                    <CoTable
                        TableId={'ACCPR'}
                        HeadData={HeadData1}
                        Data={BodyData1}
                        isHeadLoading={isPRGetConfirmLoading}
                        isBodyLoading={isPRGetConfirmLoading}
                        isPagination={BodyData1.length > RowDataInOnePage ? true : false}
                        MaxRowOnPage={RowDataInOnePage}
                        isPaginationLoading={isPRGetConfirmLoading}
                        PaginationCounter={
                            BodyData1.length / RowDataInOnePage > Math.floor(BodyData1.length / RowDataInOnePage) ?
                                Math.floor(BodyData1.length / RowDataInOnePage) + 1 :
                                BodyData1.length / RowDataInOnePage
                        }
                        isHeading={true}
                    />
                </Collapse>
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    isPRGetUnConfirmLoading: state.PR.isPRGetUnConfirmLoading,
    PRUnConfirm: state.PR.PRUnConfirm,
    isPRGetConfirmLoading: state.PR.isPRGetConfirmLoading,
    PRConfirm: state.PR.PRConfirm,
})

export default connect(mapStateToProps, { get_PR_UnConfirm, get_PR_Confirm, get_PR_Action_Id })(ACCPR)