import React, { Fragment } from "react"

import useMediaQuery from '@mui/material/useMediaQuery'
import { InitState } from '../../Style'

import FullHome from './Home/FullHome'
import HalfHome from './Home/HalfHome'

const Home = () => {
    const MinWindowFirst = useMediaQuery(`(min-width:${InitState.Units.minWidth_first}px)`)
    return (
        <Fragment>
            {MinWindowFirst ?
                <Fragment>
                    <FullHome />
                </Fragment>
                : <Fragment>
                    <HalfHome />
                </Fragment>}
        </Fragment>
    )
}

export default Home