import React, { Fragment } from "react"

import { Skeleton, Paper, Checkbox, TextField } from '@mui/material'

import CoTable from "../../../Table"

const ReviewInterviewUser = ({ isLoading, Data, AssessorData, ListAdditional, ListAlternative }) => {
    // console.log(`🚀 ~ file: ReviewInterviewUser.js ~ line 600 ~ ReviewInterviewUser ~ AssessorData`, AssessorData)

    const isAssesor = AssessorData ? AssessorData.UsIFId ? true : false : false
    const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%', backgroundColor: '#E0E0E0' }

    const HeadData4 = [
        {
            Nama: 'No',
            width: '5%',
        },
        {
            Nama: 'Interview Point',
            width: '35%',
        },
        {
            Nama: '1',
            width: '5%',
        },
        {
            Nama: '2',
            width: '5%',
        },
        {
            Nama: '3',
            width: '5%',
        },
        {
            Nama: '4',
            width: '5%',
        },
        {
            Nama: '5',
            width: '5%',
        },
        {
            Nama: 'Note',
            width: '20%',
        },
    ]

    const BodyData4 = ListAdditional.map((item, index) => {
        return {
            index: index + 1,
            Pertanyaan: item.Pertanyaan,
            Point1: (
                <Checkbox
                    checked={ListAdditional[index].Point === 1 || ListAdditional[index].Point === '1' ? true : false}
                    disabled
                />
            ),
            Point2: (
                <Checkbox
                    checked={ListAdditional[index].Point === 2 || ListAdditional[index].Point === '2' ? true : false}
                    disabled
                />
            ),
            Point3: (
                <Checkbox
                    checked={ListAdditional[index].Point === 3 || ListAdditional[index].Point === '3' ? true : false}
                    disabled
                />
            ),
            Point4: (
                <Checkbox
                    checked={ListAdditional[index].Point === 4 || ListAdditional[index].Point === '4' ? true : false}
                    disabled
                />
            ),
            Point5: (
                <Checkbox
                    checked={ListAdditional[index].Point === 5 || ListAdditional[index].Point === '5' ? true : false}
                    disabled
                />
            ),
            Keterangan: item.Keterangan
        }
    })

    const HeadData5 = [
        {
            Nama: 'No',
            width: '5%',
        },
        {
            Nama: 'Question',
            width: '35%',
        },
        {
            Nama: '1',
            width: '5%',
        },
        {
            Nama: '2',
            width: '5%',
        },
        {
            Nama: '3',
            width: '5%',
        },
        {
            Nama: '4',
            width: '5%',
        },
        {
            Nama: '5',
            width: '5%',
        },
        {
            Nama: 'Note',
            width: '20%',
        },
    ]

    const BodyData5 = ListAlternative.map((item, index) => {
        return {
            index: index + 1,
            Pertanyaan: item.Pertanyaan,
            Point1: (
                <Checkbox
                    checked={ListAlternative[index].Point === 1 || ListAlternative[index].Point === '1' ? true : false}
                    disabled
                />
            ),
            Point2: (
                <Checkbox
                    checked={ListAlternative[index].Point === 2 || ListAlternative[index].Point === '2' ? true : false}
                    disabled
                />
            ),
            Point3: (
                <Checkbox
                    checked={ListAlternative[index].Point === 3 || ListAlternative[index].Point === '3' ? true : false}
                    disabled
                />
            ),
            Point4: (
                <Checkbox
                    checked={ListAlternative[index].Point === 4 || ListAlternative[index].Point === '4' ? true : false}
                    disabled
                />
            ),
            Point5: (
                <Checkbox
                    checked={ListAlternative[index].Point === 5 || ListAlternative[index].Point === '5' ? true : false}
                    disabled
                />
            ),
            Keterangan: item.Keterangan
        }
    })

    return (
        <Fragment>
            {isLoading ?
                <Fragment>
                    {[1, 2, 3, 4, 5,].map((item) =>
                        <Skeleton
                            key={`PenilaianInterviewUser_ReviewInterviewUser_Skeleton_${item}`}
                            height={'5vh'}
                        />
                    )}
                </Fragment> :
                <Fragment>
                    {Data ? Data.map((item, index) => {
                        const Detail = item.Detail ? item.Detail : {}
                        const List = item.List ? item.List : []
                        const ListPerilaku = item.ListPerilaku ? item.ListPerilaku : []
                        const ListTeknik = item.ListTeknik ? item.ListTeknik : []

                        const HeadData = [
                            {
                                Nama: 'No',
                                width: '5%',
                            },
                            {
                                Nama: 'Question',
                                width: '35%',
                            },
                            {
                                Nama: '1',
                                width: '5%',
                            },
                            {
                                Nama: '2',
                                width: '5%',
                            },
                            {
                                Nama: '3',
                                width: '5%',
                            },
                            {
                                Nama: '4',
                                width: '5%',
                            },
                            {
                                Nama: '5',
                                width: '5%',
                            },
                            {
                                Nama: 'Note',
                                width: '20%',
                            },
                        ]

                        const BodyData = List.map((item, index) => {
                            return {
                                index: index + 1,
                                Pertanyaan: item.Nama,
                                Point1: (
                                    <Checkbox
                                        checked={List[index].Point === 1 || List[index].Point === '1' ? true : false}
                                        disabled
                                    />
                                ),
                                Point2: (
                                    <Checkbox
                                        checked={List[index].Point === 2 || List[index].Point === '2' ? true : false}
                                        disabled
                                    />
                                ),
                                Point3: (
                                    <Checkbox
                                        checked={List[index].Point === 3 || List[index].Point === '3' ? true : false}
                                        disabled
                                    />
                                ),
                                Point4: (
                                    <Checkbox
                                        checked={List[index].Point === 4 || List[index].Point === '4' ? true : false}
                                        disabled
                                    />
                                ),
                                Point5: (
                                    <Checkbox
                                        checked={List[index].Point === 5 || List[index].Point === '5' ? true : false}
                                        disabled
                                    />
                                ),
                                Keterangan: item.Keterangan
                            }
                        })

                        const HeadData2 = [
                            {
                                Nama: 'No',
                                width: '5%',
                            },
                            {
                                Nama: 'Question',
                                width: '35%',
                            },
                            {
                                Nama: 'Benchmark Point 1',
                                width: '5%',
                            },
                            {
                                Nama: 'Benchmark Point 2',
                                width: '5%',
                            },
                            {
                                Nama: 'Benchmark Point 3',
                                width: '5%',
                            },
                            {
                                Nama: ' ',
                                width: '10%',
                            },
                            {
                                Nama: '1',
                                width: '5%',
                            },
                            {
                                Nama: '2',
                                width: '5%',
                            },
                            {
                                Nama: '3',
                                width: '5%',
                            },
                            {
                                Nama: 'Note',
                                width: '20%',
                            },
                        ]

                        const BodyData2 = ListPerilaku.map((item, index) => {
                            return {
                                index: index + 1,
                                Pertanyaan: item.Nama,
                                PatokanPoint1: (
                                    <Checkbox
                                        checked={ListPerilaku[index].PatokanPoint === 1 ? true : false}
                                        disabled
                                    />
                                ),
                                PatokanPoint2: (
                                    <Checkbox
                                        checked={ListPerilaku[index].PatokanPoint === 2 ? true : false}
                                        disabled
                                    />
                                ),
                                PatokanPoint3: (
                                    <Checkbox
                                        checked={ListPerilaku[index].PatokanPoint === 3 ? true : false}
                                        disabled
                                    />
                                ),
                                Space: ' ',
                                Point1: (
                                    <Checkbox
                                        checked={ListPerilaku[index].Point === 1 || ListPerilaku[index].Point === '1' ? true : false}
                                        disabled
                                    />
                                ),
                                Point2: (
                                    <Checkbox
                                        checked={ListPerilaku[index].Point === 2 || ListPerilaku[index].Point === '2' ? true : false}
                                        disabled
                                    />
                                ),
                                Point3: (
                                    <Checkbox
                                        checked={ListPerilaku[index].Point === 3 || ListPerilaku[index].Point === '3' ? true : false}
                                        disabled
                                    />
                                ),
                                Keterangan: (
                                    ListPerilaku[index].Keterangan
                                ),
                            }
                        })

                        const HeadData3 = [
                            {
                                Nama: 'No',
                                width: '5%',
                            },
                            {
                                Nama: 'Question',
                                width: '35%',
                            },
                            {
                                Nama: 'Benchmark Point 1',
                                width: '5%',
                            },
                            {
                                Nama: 'Benchmark Point 2',
                                width: '5%',
                            },
                            {
                                Nama: 'Benchmark Point 3',
                                width: '5%',
                            },
                            {
                                Nama: ' ',
                                width: '10%',
                            },
                            {
                                Nama: '1',
                                width: '5%',
                            },
                            {
                                Nama: '2',
                                width: '5%',
                            },
                            {
                                Nama: '3',
                                width: '5%',
                            },
                            {
                                Nama: 'Note',
                                width: '20%',
                            },
                        ]

                        const BodyData3 = ListTeknik.map((item, index) => {
                            return {
                                index: index + 1,
                                Pertanyaan: item.Nama,
                                PatokanPoint1: (
                                    <Checkbox
                                        checked={ListTeknik[index].PatokanPoint === 1 ? true : false}
                                        disabled
                                    />
                                ),
                                PatokanPoint2: (
                                    <Checkbox
                                        checked={ListTeknik[index].PatokanPoint === 2 ? true : false}
                                        disabled
                                    />
                                ),
                                PatokanPoint3: (
                                    <Checkbox
                                        checked={ListTeknik[index].PatokanPoint === 3 ? true : false}
                                        disabled
                                    />
                                ),
                                Space: ' ',
                                Point1: (
                                    <Checkbox
                                        checked={ListTeknik[index].Point === 1 || ListTeknik[index].Point === '1' ? true : false}
                                        disabled
                                    />
                                ),
                                Point2: (
                                    <Checkbox
                                        checked={ListTeknik[index].Point === 2 || ListTeknik[index].Point === '2' ? true : false}
                                        disabled
                                    />
                                ),
                                Point3: (
                                    <Checkbox
                                        checked={ListTeknik[index].Point === 3 || ListTeknik[index].Point === '3' ? true : false}
                                        disabled
                                    />
                                ),
                                Keterangan: (
                                    ListTeknik[index].Keterangan
                                ),
                            }
                        })



                        return (
                            <Fragment
                                key={`PaperInterview_${index}`}
                            >
                                <h2>
                                    Interview {index + 1}
                                </h2>
                                <Paper
                                    style={{ padding: '0.5vh 3vw 1vh 3vw' }}
                                >
                                    <h3>
                                        Interview Date & Time
                                    </h3>
                                    <h4
                                        style={{ marginLeft: '2%' }}
                                    >
                                        {Detail?.InterviewDate ? new Date(Detail.InterviewDate).toLocaleString(['ban', 'id']) : ''}
                                    </h4>

                                    <h3>
                                        Assessment Form
                                    </h3>
                                    <CoTable
                                        TableId={`PenilaianInterviewUser_Penilaian_${index}`}
                                        HeadData={HeadData}
                                        Data={BodyData}
                                        isHeadLoading={isLoading}
                                        isBodyLoading={isLoading}
                                    />

                                    <h3>
                                        Job Profile Assessment
                                    </h3>
                                    <h4>
                                        Behavior
                                    </h4>
                                    <CoTable
                                        TableId={`PenilaianInterviewUser_Penilaian_Perilaku_${index}`}
                                        HeadData={HeadData2}
                                        Data={BodyData2}
                                        isHeadLoading={isLoading}
                                        isBodyLoading={isLoading}
                                    />
                                    <h4>
                                        Technique
                                    </h4>
                                    <CoTable
                                        TableId={`PenilaianInterviewUser_Penilaian_Teknik_${index}`}
                                        HeadData={HeadData3}
                                        Data={BodyData3}
                                        isHeadLoading={isLoading}
                                        isBodyLoading={isLoading}
                                    />

                                    <h3>
                                        Recommendation
                                    </h3>
                                    <TextField
                                        style={st_textfield}
                                        variant='outlined'
                                        disabled
                                        type='text'
                                        label='Recommendation'
                                        name='KeputusanHasil'
                                        value={Detail?.Status ? Detail.Status : ''}
                                    />
                                    <TextField
                                        style={st_textfield}
                                        variant='outlined'
                                        type='text'
                                        label='Note'
                                        name='Keterangan'
                                        disabled
                                        value={Detail?.Keterangan ? Detail.Keterangan : ''}
                                    />
                                    <TextField
                                        style={st_textfield}
                                        variant='outlined'
                                        type='text'
                                        label='Name'
                                        name='Asesor'
                                        disabled
                                        value={Detail?.RekomendatorNama ? Detail.RekomendatorNama : ''}
                                    />
                                    <TextField
                                        style={st_textfield}
                                        variant='outlined'
                                        type='text'
                                        label='Input Time'
                                        name='WaktuInput'
                                        disabled
                                        value={Detail?.InputTime ? new Date(Detail.InputTime).toLocaleString(['ban', 'id']) : ''}
                                    />
                                </Paper>
                            </Fragment>)
                    })
                        : <Fragment>
                        </Fragment>}
                    {isAssesor ?
                        <Fragment>
                            <h3>
                                Additional Interview
                            </h3>
                            <CoTable
                                TableId={'PenilaianInterviewUser_Penilaian_AdditionalInterview'}
                                HeadData={HeadData4}
                                Data={BodyData4}
                                isHeadLoading={isLoading}
                                isBodyLoading={isLoading}
                                MessageTitle='There is No Additional Interview'
                                Messages=' '
                            />

                            <h3>
                                Alternative Test
                            </h3>
                            <CoTable
                                TableId={'PenilaianInterviewUser_Penilaian_AlternativeTest'}
                                HeadData={HeadData5}
                                Data={BodyData5}
                                isHeadLoading={isLoading}
                                isBodyLoading={isLoading}
                                MessageTitle='There is No Alternative Test'
                                Messages=' '
                            />
                            <h2>
                                Final Decision
                            </h2>
                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                disabled
                                type='text'
                                label='Final Decision'
                                name='KeputusanHasil'
                                value={AssessorData?.UsIFStatus ? AssessorData.UsIFStatus : ''}
                            />
                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Note'
                                name='Keterangan'
                                disabled
                                value={AssessorData?.UsIFKet ? AssessorData.UsIFKet : ''}
                            />
                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Name'
                                name='Asesor'
                                disabled
                                value={AssessorData?.AssessorNama ? AssessorData.AssessorNama : ''}
                            />
                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Input Time'
                                name='WaktuInput'
                                disabled
                                value={AssessorData?.UsIFTime ? new Date(AssessorData.UsIFTime).toLocaleString(['ban', 'id']) : ''}
                            />
                        </Fragment>
                        : <Fragment>
                        </Fragment>}
                </Fragment >
            }
        </Fragment >
    )
}

export default ReviewInterviewUser