import React, { Fragment } from 'react'

import { connect } from 'react-redux'
import { Update_Account_UserName } from '../../../../../Store/Actions/Account.Action'

import { TextField, Button } from '@mui/material'


class Account extends React.Component {
    state = {
        UserName: '',
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    componentDidMount() {
        const { Account } = this.props

        if (Account) {
            this.setState({
                UserName: Account.UserName
            })
        }
    }

    formOnSubmit = (e) => {
        e.preventDefault()

        const BiodataData = this.props.Biodata.Data

        this.props.Update_Account_UserName(
            BiodataData.Id,
            this.state.UserName,
        )
    }
    render() {
        // const isAccountLoading = this.props.isAccountLoading

        const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }
        // const st_divaddtextfield = { display: 'flex', alignItems: 'center', margin: '0 0 2% 2%' }
        // const st_TextSmall = { width: '20%', margin: '0 1% 0 0' }
        // const st_icon = { color: 'grey', fontSize: '180%', margin: '0 0 0 1%', cursor: 'pointer' }

        const {
            UserName,
        } = this.state

        return (
            <Fragment>
                <form
                    onSubmit={this.formOnSubmit}
                >
                    <div
                        style={{ margin: '5% 0 5% 0' }}
                    >

                        <TextField
                            style={st_textfield}
                            variant='outlined'
                            type='text'
                            label='User Name'
                            name='UserName'
                            required
                            value={UserName}
                            onChange={this.onChangeField}
                            InputProps={{
                                style: { fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }
                            }}
                        />
                        {/* <TextField
                            style={st_textfield}
                            variant='outlined'
                            type='email'
                            label='Email'
                            name='Email'
                            required
                            value={Email}
                            onChange={this.onChangeField}
                        /> */}

                    </div >
                    <center>
                        <Button
                            variant='contained'
                            style={{ width: '60%', margin: '2% 0 5% 0', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                            type='submit'
                        >
                            Save
                        </Button>
                    </center>
                </form>
            </Fragment >
        )
    }

}

const mapStateToProps = state => ({
    Biodata: state.Account.Biodata,
    isAccountLoading: state.Account.isAccountLoading,
    Account: state.Account.Account,
})

export default connect(mapStateToProps, { Update_Account_UserName })(Account)