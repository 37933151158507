import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Lokasi_Data } from '../../../../../../Store/Actions/Setting.Action'

import Detail from '../../../../../Containers/Dashboard/Setting/Lokasi/Detail'

class SettingLokasiView extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.LokasiActionId !== prevProps.LokasiActionId) {
            this.props.get_Lokasi_Data(this.props.LokasiActionId)
        }
        // if (this.props.LokasiActionCounter !== prevProps.LokasiActionCounter) {
        //     this.props.get_Lokasi_Data(this.props.LokasiActionId)
        // }
    }
    componentDidMount() {
        const { LokasiActionId } = this.props
        if (LokasiActionId !== null) {
            this.props.get_Lokasi_Data(LokasiActionId)
        }
    }

    render() {
        const isLokasiActionGetDetailLoading = this.props.isLokasiActionGetDetailLoading
        const LokasiDetail = this.props.LokasiDetail

        return (
            <Fragment>
                <Detail Data={LokasiDetail} isLoading={isLokasiActionGetDetailLoading} />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isLokasiActionGetDetailLoading: state.Setting.isLokasiActionGetDetailLoading,
    LokasiActionId: state.Setting.LokasiActionId,
    LokasiDetail: state.Setting.LokasiDetail,
})

export default connect(mapStateToProps, { get_Lokasi_Data })(SettingLokasiView)
