
import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_TimeLine_Detail } from '../../../../../Store/Actions/Job.Action'

import { CircularProgress } from '@mui/material'

import CoTimeline from '../../../../Containers/Dashboard/Timeline/Timeline'
class TimeLineCom extends React.Component {

  componentDidMount() {
    const { JobActionId } = this.props
    this.props.get_TimeLine_Detail(JobActionId)
  }

  render() {
    const isTimeLineDetailLoading = this.props.isTimeLineDetailLoading
    const TimeLineDetail = this.props.TimeLineDetail

    // const isInPendaftar = TimeLineDetail ? TimeLineDetail.ListReferee.length = 0 ? true : false : false
    // const LastUpdate = TimeLineDetail ? true : false
    // const LastLocation = TimeLineDetail ?
    //   TimeLineDetail.ListReferee.length = 0 ? 'Total'
    //     : TimeLineDetail.ListConfirm.length = 0 ? 'ListReferee'
    //       : TimeLineDetail.PersonalityTest.length = 0 ? 'ListConfirm'
    //         : TimeLineDetail.HRInterview.length = 0 ? 'PersonalityTest'
    //           : TimeLineDetail.DepartemenInterview.length = 0 ? 'HRInterview'
    //             : TimeLineDetail.DepartemenFinal.length = 0 ? 'DepartemenInterview'
    //               : TimeLineDetail.Offering.length = 0 ? 'DepartemenFinal'
    //                 : TimeLineDetail.MCU.length = 0 ? 'Offering'
    //                   : TimeLineDetail.Preboarding.length = 0 ? 'MCU'
    //                     : TimeLineDetail.PKWT.length = 0 ? 'Preboarding'
    //                       : TimeLineDetail.BPJS.length = 0 ? 'PKWT'
    //                         : TimeLineDetail.GreetDay.length = 0 ? 'BPJS'
    //                           : TimeLineDetail.Contrak.length = 0 ? 'GreetDay'
    //                             : TimeLineDetail.OnBoarding.length = 0 ? 'Contrak'
    //                               : TimeLineDetail.OnBoarding.length > 0 ? 'OnBoarding'
    //                                 : true
    //   : true
    // const LastLocation = true

    return (
      <Fragment>
        {isTimeLineDetailLoading ?
          <Fragment>
            <center>
              <CircularProgress
                style={{ marginTop: '10%' }}
                size={200}
              />
            </center>
          </Fragment> :
          <div
            style={{ margin: '0 0 5% 0' }}
          >
            <CoTimeline
              TimeLineDetail={TimeLineDetail}
            />
          </div>}
      </Fragment >
    )
  }
}

const mapStateToProps = state => ({
  JobActionId: state.Job.JobActionId,
  isTimeLineDetailLoading: state.Job.isTimeLineDetailLoading,
  TimeLineDetail: state.Job.TimeLineDetail,
})

export default connect(mapStateToProps, { get_TimeLine_Detail })(TimeLineCom)