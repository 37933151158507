import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Job_Data } from '../../../../../../../Store/Actions/Job.Action'

import { Skeleton, Grid, Paper } from '@mui/material'

import * as Style from '../../../../../../../Style'

class JobView extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.JobActionId !== prevProps.JobActionId) {
            this.props.get_Job_Data(this.props.JobActionId)
        }
    }
    componentDidMount() {
        const { JobActionId } = this.props
        if (JobActionId !== null) {
            this.props.get_Job_Data(JobActionId)
        }
    }
    render() {
        const isJobActionDataLoading = this.props.isJobActionDataLoading
        const JobActionData = this.props.JobActionData
        const JobActionDeskripsi = this.props.JobActionDeskripsi
        const JobActionPersyaratan = this.props.JobActionPersyaratan
       
        return (
            <Fragment>
                {isJobActionDataLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((item) =>
                            <div
                                style={{ display: 'flex', border: '0px solid red', margin: '0', padding: '0', justifyContent: 'center' }}
                                key={`isJobActionDataLoading_${item}`}
                            >
                                <Skeleton
                                    height={item === 1 ? '20vh' : item === 2 || item === 6 || item === 10 ? '15vh' : '5vh'}
                                    width={'100%'}
                                    style={{ display: 'flex' }}
                                />
                            </div>
                        )}
                    </Fragment> :
                    <Fragment>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <div
                                style={{ border: '0px solid red', width: '60vw', padding: '1px' }}
                            >
                                <h1>{JobActionData ? JobActionData.Nama : 'Nama'}</h1>
                            </div>
                            <div>
                            </div>
                        </Grid>
                        <Paper
                            style={{ border: '0 px solid red', boxShadow: 'none', backgroundColor: "rgb(249, 249, 249)", padding: '10px 0px 10px 50px', height: '8vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <div
                                style={{ padding: '0 1% 0 1%', borderRight: '1px solid black' }}
                            >
                                Department: {JobActionData ? JobActionData.DepartemenNama : 'DepartemenNama'}
                            </div>
                            <div
                                style={{ padding: '0 1% 0 1%', borderRight: '1px solid black' }}
                            >
                                Level: {JobActionData ? JobActionData.LevelNama : 'LevelNama'}
                            </div>
                            <div
                                style={{ padding: '0 1% 0 1%' }}
                            >
                                Location:  {JobActionData ? JobActionData.LokasiNama : 'LokasiNama'}
                            </div>
                        </Paper>
                        <p
                            style={{ textAlign: 'center', fontSize: '18px', color: Style.Color.Text.secondary }}
                        >{JobActionData ? JobActionData.About : ''}</p>
                        <h2>Job Description:</h2>
                        <ul>
                            {JobActionDeskripsi.map((item, index) => (
                                <li
                                    key={(`Deskripsi ${index}`)}
                                    style={{ color: Style.Color.Text.secondary }}
                                >
                                    <p>
                                        {item.Deskripsi}
                                    </p>
                                </li>
                            ))}
                        </ul>
                        <h2>Requirements:</h2>
                        <ul>
                            {JobActionPersyaratan.map((item, index) => (
                                <li
                                    key={(`Persyaratan ${index}`)}
                                    style={{ color: Style.Color.Text.secondary }}
                                >
                                    <p>
                                        {item.Persyaratan}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </Fragment>}
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    JobActionId: state.Job.JobActionId,
    isJobActionDataLoading: state.Job.isJobActionDataLoading,
    JobActionData: state.Job.JobActionData,
    JobActionDeskripsi: state.Job.JobActionDeskripsi,
    JobActionPersyaratan: state.Job.JobActionPersyaratan,
})

export default connect(mapStateToProps, { get_Job_Data })(JobView)