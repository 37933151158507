import React, { Fragment } from "react"

import { connect } from 'react-redux'

import { BrowserRouter as Router } from "react-router-dom"
import BaseRouter from "./Router"

import { ThemeProvider } from '@mui/material'
import Theme from './Theme'
import { InitState } from './Style'

import { Load_User, get_Activation_Code, Activation_Code } from './Store/Actions/Auth.Action'
import { Create_Warning_Messages } from './Store/Actions/Messages.Actions'

import LoadingModal from './Components/Containers/LoadingModal'
import ActivationModal from './Components/Containers/ActivationModal'
import Header from './Components/Containers/Header'
import Messages from './Components/Containers/Messages'
import Footer from './Components/Containers/Footer'

class App extends React.Component {
  state = {
    isMobileView: false,
  }
  componentDidMount() {
    const Token = this.props.Token
    const isAuth = this.props.isAuth
    const User = this.props.User

    if ((Token || isAuth) && (User === null)) {
      this.props.Load_User()
    }

    window.addEventListener("resize", this.CheckWindowWidth.bind(this))
    this.CheckWindowWidth()
    document.addEventListener("wheel", (event) => {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.User !== prevProps.User) {
      const { User } = this.props

      const isApplyVacancy = User?.isApplyVacancy ? true : false
      const isActiveted = User?.isActiveted ? true : false
      const isBiodataComplete = User?.isBiodataComplete ? true : false
      const isPendaftar = User?.isPendaftar ? true : false

      if (isActiveted && isPendaftar && !isBiodataComplete) {
        this.props.Create_Warning_Messages(null, 'Please complete your biodata in the "Manage Account" menu', 60000)
      } else if (isActiveted && isPendaftar && !isApplyVacancy) {
        // } else if (true) {
        this.props.Create_Warning_Messages(null, 'Please complete your application by registering on the available vacancy, make sure your application appears on the "Applications" menu', 60000)
      }
    }
  }

  CheckWindowWidth() {
    this.setState({ isMobileView: window.innerWidth < InitState.Units.minWidth_first })
  }

  render() {
    // const URLLocation = window.location.href
    // const LoginLocation = '/login'
    // const LoginLocation = false
    // const isAuth = this.props.isAuth
    // const User = this.props.User
    const isUserLoading = this.props.isUserLoading

    const User = this.props.User
    const UserEmail = User?.Email ? User.Email : null

    const isNeedActivation = this.props.OSConfirmationAccount === true ? false : User ? User.isActiveted === false ? true : false : false
    // const isNeedActivation = false

    const { isMobileView } = this.state
    // const isActiveMobileView = isMobileView
    const isActiveMobileView = false

    return (
      <Fragment>
        <ThemeProvider
          theme={Theme}
        >
          <Router>
            {isActiveMobileView ?
              <Fragment>
                <center>
                  <h1>
                    Mobile View Under Development Please Use PC, Laptop or Desktop
                  </h1>
                </center>
                <LoadingModal isModalOpen={isUserLoading} />
                <ActivationModal isModalOpen={isNeedActivation} Email={UserEmail} SendFunction={this.props.Activation_Code} GetMailFunction={this.props.get_Activation_Code} isOneStep={this.props.OSCreateAccount} />
              </Fragment>

              : <Fragment>
                <Header />
                <Messages isMobileView={isMobileView} />
                <LoadingModal isModalOpen={isUserLoading} />
                <ActivationModal isModalOpen={isNeedActivation} Email={UserEmail} SendFunction={this.props.Activation_Code} GetMailFunction={this.props.get_Activation_Code} isOneStep={this.props.OSCreateAccount} />
                <BaseRouter isMobileView={isMobileView} />
                <Footer />
              </Fragment>}
          </Router>
        </ThemeProvider>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  Token: state.Auth.Token,
  isAuth: state.Auth.isAuth,
  isUserLoading: state.Auth.isUserLoading,
  User: state.Auth.User,
  OSCreateAccount: state.Messages.CreateAccount,
  OSConfirmationAccount: state.Messages.ConfirmationAccount,
})

export default connect(mapStateToProps, { Load_User, get_Activation_Code, Activation_Code, Create_Warning_Messages })(App)