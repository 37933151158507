import React, { Fragment } from 'react'

import { Skeleton, TextField } from '@mui/material'

const SettingRoleDetail = ({ Data, isLoading = false, ...props }) => {
    const TextFieldStyle = { width: '95%', margin: '1%', backgroundColor: '#E0E0E0' }
    return (
        <Fragment>
            {isLoading ?
                <Fragment>
                    <Skeleton
                        height={'10vh'}
                    />
                    <Skeleton
                        height={'10vh'}
                    />
                    <Skeleton
                        height={'10vh'}
                    />
                </Fragment> :
                <Fragment>
                    <h3>
                        Role Detail
                    </h3>
                    <center>
                        <TextField
                            label='Id'
                            value={Data?.Id ? Data.Id : ''}
                            disabled
                            style={TextFieldStyle}
                        />
                        <TextField
                            label='Nama'
                            value={Data?.Nama ? Data.Nama : ''}
                            disabled
                            style={TextFieldStyle}
                        />
                        <TextField
                            label='Deskripsi'
                            value={Data?.Deskripsi ? Data.Deskripsi : ''}
                            disabled
                            style={TextFieldStyle}
                        />
                    </center>
                </Fragment>}
        </Fragment>
    )
}

export default SettingRoleDetail