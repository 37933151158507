import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Load_MPP_List, get_MPP_Action_Id } from '../../../../Store/Actions/Setting.Action'

import CoTable from '../../../Containers/Table'
import GenericModals from '../../../Containers/GenericModals'

import Add from './Modal/MPP/Add'
import View from './Modal/MPP/View'
import Delete from './Modal/MPP/Delete'

class SettingMPP extends React.Component {
    componentDidMount() {
        this.props.Load_MPP_List()
    }
    onClickActionId(Id) {
        this.props.get_MPP_Action_Id(Id)
    }
    render() {
        const MPPList = this.props.MPPList
        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Id',
                width: '10%',
            },
            {
                Nama: 'Year',
                width: '10%',
            },
            {
                Nama: 'Type',
                width: '10%',
            },
            {
                Nama: 'Open Date',
                width: '10%',
            },
            {
                Nama: 'Close Date',
                width: '10%',
            },
            {
                Nama: 'Notes',
                width: '25%',
            },
            {
                Nama: 'View',
                width: '10%',
            },
            {
                Nama: 'Delete',
                width: '10%',
            },
        ]
        const BodyData = MPPList ? MPPList.map((item, index) => {
            return {
                index: index + 1,
                Id: item.Id,
                Tahun: item.Tahun,
                Jenis: item.Jenis,
                TanggalBuka: item.TanggalBuka ? new Date(item.TanggalBuka).toLocaleDateString() : '',
                TanggalTutup: item.TanggalTutup ? new Date(item.TanggalTutup).toLocaleDateString() : '',
                Keterangan: item.Keterangan,
                View: (
                    <GenericModals
                        size='xl'
                        header='View MPP'
                        body={<View />}
                        Buttoncolor='primary'
                        ButtononClickeven={() => this.onClickActionId(item.Id)}
                        Buttonlabel={'view'}
                    />
                ),
                Delete: (
                    <GenericModals
                        size='m'
                        header='Delete Form'
                        body={<Delete />}
                        Buttoncolor='error'
                        ButtononClickeven={() => this.onClickActionId(item.Id)}
                        Buttonlabel={'Delete'}
                    />
                )
            }
        }) : []
        const isMPPLoading = this.props.isMPPLoading
        return (
            <Fragment>
                <h2>
                    MPP Setting
                </h2>

                <center>
                    <GenericModals
                        size='m'
                        header='Add MPP'
                        body={<Add />}
                        Buttoncolor='primary'
                        Buttonstyle={{ width: '90%' }}
                        ButtononClickeven={() => null}
                        Buttonlabel={'+ MPP'}
                    />
                </center>

                <CoTable
                    TableId='MPPSetting'
                    HeadData={HeadData}
                    isHeadLoading={HeadData ? false : true}
                    Data={BodyData}
                    isBodyLoading={isMPPLoading}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isMPPLoading: state.Setting.isMPPLoading,
    MPPList: state.Setting.MPPList,
})

export default connect(mapStateToProps, { Load_MPP_List, get_MPP_Action_Id })(SettingMPP)
