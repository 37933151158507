import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_List_BPJS_Detail } from '../../../../../../Store/Actions/Job.Action'

import { Skeleton } from '@mui/material'

class DetailBPJS extends React.Component {
    componentDidMount() {
        const { JobActionId, AccountActionId } = this.props
        this.props.get_List_BPJS_Detail(JobActionId, AccountActionId)
    }
    render() {
        const isListBPJSDetailLoading = this.props.isListBPJSDetailLoading
        const ListBPJSDetail = this.props.ListBPJSDetail

        return (
            <Fragment>
                {isListBPJSDetailLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5,].map((item) =>
                            <Skeleton
                                key={`DetailMenunggu_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <div
                        style={{ fontFamily: 'Montserrat, Segoe UI', }}
                    >
                        <h2>
                            BPJS
                        </h2>
                        <div
                            style={{
                                margin: '1% 0 1% 0', padding: '1%', border: `1px solid ${ListBPJSDetail?.BPStatus ? ListBPJSDetail.BPStatus === 'Done' ? 'Green' :
                                    'blue' : 'black'}`
                            }}
                        >
                            <center>
                                <h2
                                    style={{
                                        color: ListBPJSDetail?.BPStatus ? ListBPJSDetail.BPStatus === 'Done' ? 'Green' :
                                            'blue' : 'black'
                                    }}
                                >
                                    Status : {ListBPJSDetail?.BPStatus ? ListBPJSDetail.BPStatus : 'Status'}
                                </h2>
                            </center>
                            <p>
                                Updated On:<br />
                                {ListBPJSDetail?.BPDate ? new Date(ListBPJSDetail.BPDate).toLocaleString(['ban', 'id']) : ''}<br />
                                By:<br />
                                {ListBPJSDetail?.BiodataNama ? ListBPJSDetail.BiodataNama : ''}<br /><br />
                                No BPJS Kesehatan:<br />
                                <b>{ListBPJSDetail?.BPKesNo ? ListBPJSDetail.BPKesNo : ''}</b><br /><br />
                                No BPJS Ketenagakerjaan:<br />
                                <b>{ListBPJSDetail?.BPKetenagakerjaanNo ? ListBPJSDetail.BPKetenagakerjaanNo : ''}</b><br /><br />
                                Notes:<br />
                                {ListBPJSDetail?.BPKet ? ListBPJSDetail.BPKet : ''}<br />
                            </p>
                        </div>
                    </div>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isListBPJSDetailLoading: state.Job.isListBPJSDetailLoading,
    ListBPJSDetail: state.Job.ListBPJSDetail,
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
})

export default connect(mapStateToProps, { get_List_BPJS_Detail })(DetailBPJS)