import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Create_Warning_Messages } from '../../../../../../Store/Actions/Messages.Actions'
import { Konfirm_Personality_Test } from '../../../../../../Store/Actions/Job.Action'

import { FormControl, InputLabel, Select, TextField, Button } from '@mui/material'

class SubmitPersonaltyTest extends React.Component {
    state = {
        FirstPersonalty: '',
        SecondPersonalty: '',
        Keterangan: '',
        StressManagement: '',
        SensitivityLevel: '',
        CompassionLevel: '',
        PatienceLevel: '',
        CreativeAbilities: '',
        ArtisticAbilities: '',
        OutgoingTraits: '',
        InteractionLevel: '',
        Analytical: '',
        DetailedTraits: '',
        DecisionMaking: '',
        Independent: '',
        PersuasiveLevel: '',
        AggressiveLevel: '',
        StubbornLevel: '',
        GoalSetting: '',
        TimeManagement: '',
        ConfidenceLevel: '',
    }

    SelectFormOnChange = (e) => {
        if (e.target.name === 'FirstPersonalty') {
            if (e.target.value === this.state.SecondPersonalty) {
                this.props.Create_Warning_Messages(null, ' tidak bisa sama')
            } else {
                this.setState({ [e.target.name]: e.target.value })
            }
        } else {
            if (e.target.value === this.state.FirstPersonalty) {
                this.props.Create_Warning_Messages(null, ' tidak bisa sama')
            } else {
                this.setState({ [e.target.name]: e.target.value })
            }
        }
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onChangeFieldMinMax = (e, Min, Max) => {
        if (Number(e.target.value) <= Max && Number(e.target.value) >= Min) {
            this.setState({ [e.target.name]: e.target.value })
        } else {
            this.props.Create_Warning_Messages(null, `Max value are ${Max}, Min value are ${Min} `)
        }

    }


    formOnSubmit = (e) => {
        e.preventDefault()
        const JobActionId = this.props.JobActionId
        this.props.Konfirm_Personality_Test(
            JobActionId,
            this.state.FirstPersonalty,
            this.state.SecondPersonalty,
            this.state.Keterangan,
            this.state.StressManagement,
            this.state.SensitivityLevel,
            this.state.CompassionLevel,
            this.state.PatienceLevel,
            this.state.CreativeAbilities,
            this.state.ArtisticAbilities,
            this.state.OutgoingTraits,
            this.state.InteractionLevel,
            this.state.Analytical,
            this.state.DetailedTraits,
            this.state.DecisionMaking,
            this.state.Independent,
            this.state.PersuasiveLevel,
            this.state.AggressiveLevel,
            this.state.StubbornLevel,
            this.state.GoalSetting,
            this.state.TimeManagement,
            this.state.ConfidenceLevel,
        )
    }

    render() {
        const {
            FirstPersonalty,
            SecondPersonalty,
            Keterangan,
            StressManagement,
            SensitivityLevel,
            CompassionLevel,
            PatienceLevel,
            CreativeAbilities,
            ArtisticAbilities,
            OutgoingTraits,
            InteractionLevel,
            Analytical,
            DetailedTraits,
            DecisionMaking,
            Independent,
            PersuasiveLevel,
            AggressiveLevel,
            StubbornLevel,
            GoalSetting,
            TimeManagement,
            ConfidenceLevel,
        } = this.state

        const OpPersonalty = ['A (Leader)', 'B (Networker)', 'C (Thinker)', 'D (Supporter)', 'E (Equal Personalities)']

        const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }

        return (
            <Fragment>
                <form
                    onSubmit={this.formOnSubmit}
                >
                    <FormControl
                        style={st_textfield}
                        variant="filled"
                        required
                    >
                        <InputLabel
                            shrink
                        >
                            First Personalty
                        </InputLabel>
                        <Select
                            native
                            style={{ backgroundColor: 'white' }}
                            onChange={(e) => this.SelectFormOnChange(e)}
                            label="First Personalty"
                            name='FirstPersonalty'
                            value={FirstPersonalty}
                        >
                            <option value="" disabled> -- select an option -- </option>
                            {OpPersonalty.map((option, index) =>
                                <option key={`OpPersonalty_${index}`} value={option}>{option}</option>
                            )}
                        </Select>
                    </FormControl>

                    <FormControl
                        style={st_textfield}
                        variant="filled"
                        required
                    >
                        <InputLabel
                            shrink
                        >
                            Second Personalty
                        </InputLabel>
                        <Select
                            native
                            style={{ backgroundColor: 'white' }}
                            onChange={(e) => this.SelectFormOnChange(e)}
                            label="Second Personalty"
                            name='SecondPersonalty'
                            value={SecondPersonalty}
                        >
                            <option value="" disabled> -- select an option -- </option>
                            {OpPersonalty.map((option, index) =>
                                <option key={`OpPersonalty_${index}`} value={option}>{option}</option>
                            )}
                        </Select>
                    </FormControl>

                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='number'
                        label='Stress Management'
                        name='StressManagement'
                        required
                        value={StressManagement}
                        onChange={(e) => this.onChangeFieldMinMax(e, 0, 15)}
                    />

                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='number'
                        label='Sensitivity Level'
                        name='SensitivityLevel'
                        required
                        value={SensitivityLevel}
                        onChange={(e) => this.onChangeFieldMinMax(e, 0, 15)}
                    />

                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='number'
                        label='Compassion Level'
                        name='CompassionLevel'
                        required
                        value={CompassionLevel}
                        onChange={(e) => this.onChangeFieldMinMax(e, 0, 15)}
                    />

                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='number'
                        label='Patience Level'
                        name='PatienceLevel'
                        required
                        value={PatienceLevel}
                        onChange={(e) => this.onChangeFieldMinMax(e, 0, 15)}
                    />

                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='number'
                        label='Creative Abilities'
                        name='CreativeAbilities'
                        required
                        value={CreativeAbilities}
                        onChange={(e) => this.onChangeFieldMinMax(e, 0, 15)}
                    />

                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='number'
                        label='Artistic Abilities'
                        name='ArtisticAbilities'
                        required
                        value={ArtisticAbilities}
                        onChange={(e) => this.onChangeFieldMinMax(e, 0, 15)}
                    />

                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='number'
                        label='Outgoing Traits'
                        name='OutgoingTraits'
                        required
                        value={OutgoingTraits}
                        onChange={(e) => this.onChangeFieldMinMax(e, 0, 15)}
                    />

                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='number'
                        label='Interaction Level'
                        name='InteractionLevel'
                        required
                        value={InteractionLevel}
                        onChange={(e) => this.onChangeFieldMinMax(e, 0, 15)}
                    />

                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='number'
                        label='Analytical'
                        name='Analytical'
                        required
                        value={Analytical}
                        onChange={(e) => this.onChangeFieldMinMax(e, 0, 15)}
                    />

                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='number'
                        label='Detailed Traits'
                        name='DetailedTraits'
                        required
                        value={DetailedTraits}
                        onChange={(e) => this.onChangeFieldMinMax(e, 0, 15)}
                    />

                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='number'
                        label='Decision Making'
                        name='DecisionMaking'
                        required
                        value={DecisionMaking}
                        onChange={(e) => this.onChangeFieldMinMax(e, 0, 15)}
                    />

                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='number'
                        label='Independent'
                        name='Independent'
                        required
                        value={Independent}
                        onChange={(e) => this.onChangeFieldMinMax(e, 0, 15)}
                    />

                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='number'
                        label='Persuasive Level'
                        name='PersuasiveLevel'
                        required
                        value={PersuasiveLevel}
                        onChange={(e) => this.onChangeFieldMinMax(e, 0, 15)}
                    />

                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='number'
                        label='Aggressive Level'
                        name='AggressiveLevel'
                        required
                        value={AggressiveLevel}
                        onChange={(e) => this.onChangeFieldMinMax(e, 0, 15)}
                    />

                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='number'
                        label='Stubborn Level'
                        name='StubbornLevel'
                        required
                        value={StubbornLevel}
                        onChange={(e) => this.onChangeFieldMinMax(e, 0, 15)}
                    />

                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='number'
                        label='Goal Setting'
                        name='GoalSetting'
                        required
                        value={GoalSetting}
                        onChange={(e) => this.onChangeFieldMinMax(e, 0, 15)}
                    />

                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='number'
                        label='Time Management'
                        name='TimeManagement'
                        required
                        value={TimeManagement}
                        onChange={(e) => this.onChangeFieldMinMax(e, 0, 15)}
                    />

                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='number'
                        label='Confidence Level'
                        name='ConfidenceLevel'
                        required
                        value={ConfidenceLevel}
                        onChange={(e) => this.onChangeFieldMinMax(e, 0, 15)}
                    />

                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        label='Note'
                        name='Keterangan'
                        value={Keterangan}
                        onChange={this.onChangeField}
                    />

                    <center>
                        <Button
                            variant='contained'
                            style={{ margin: '10% 0 5% 0', width: '60%' }}
                            type='submit'
                        >
                            Submit
                        </Button>
                    </center>
                </form>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    JobActionId: state.Job.JobActionId,
})

export default connect(mapStateToProps, { Create_Warning_Messages, Konfirm_Personality_Test })(SubmitPersonaltyTest)