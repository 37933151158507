import React from 'react'

import { connect } from 'react-redux'

import { Clean_Messages } from '../../Store/Actions/Messages.Actions'

import { Snackbar, Slide } from '@mui/material'
import MuiAlert from '@mui/material/Alert'

export class Messages extends React.Component {
    handleClick = () => {
        this.setState({ isSnackbarOpen: true })
    }

    handleClose = (event, reason) => {
        this.props.Clean_Messages()
    }
    render() {
        const isMobileView = this.props.isMobileView ? this.props.isMobileView : false
        const vertical = 'top'
        const horizontal = 'center'
        const { isMessages, MessagesType, MessagesCode, Messages, HideDuration } = this.props.messages
        return (
            <Snackbar open={isMessages} autoHideDuration={HideDuration ? Number(HideDuration) : 2400} onClose={this.handleClose} anchorOrigin={{ vertical, horizontal }}>
                <Slide
                    direction='up'
                >
                    <MuiAlert onClose={this.handleClose} severity={MessagesType ? MessagesType : 'info'} elevation={10} variant="filled" style={{ fontSize: isMobileView ? '16px' : '1.2vw' }}>
                        {MessagesCode ? `${MessagesCode} :` : ''}
                        {Messages ? ` ${Messages}` : ''}
                    </MuiAlert>
                </Slide>
            </Snackbar>
        )
    }
}
const mapStateToProps = (state) => ({
    messages: state.Messages
})

export default connect(mapStateToProps, { Clean_Messages })(Messages)
