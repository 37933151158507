import React, { Fragment } from "react"

import useMediaQuery from '@mui/material/useMediaQuery'
import { InitState } from '../../Style'

import FullHeader from './Header/FullHeader'
import HalfHeader from './Header/HalfHeader'

const Header = () => {
    const MinWindowFirst = useMediaQuery(`(min-width:${InitState.Units.minWidth_first}px)`)
    return (
        <Fragment>
            {MinWindowFirst ?
                <Fragment>
                    <FullHeader />
                </Fragment>
                : <Fragment>
                    <HalfHeader />
                </Fragment>}
        </Fragment>
    )
}

export default Header