import React from 'react'

import { connect } from 'react-redux'
import { Add_Lokasi } from '../../../../../../Store/Actions/Setting.Action'

import { TextField, Button, CircularProgress } from '@mui/material'

class SettingLokasiAdd extends React.Component {
    state = {
        Nama: '',
        isNamaError: false,
        Deskripsi: ''
    }
    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    changeToError = (e, value) => {
        this.setState({ isNamaError: value })
    }
    formOnSubmit = (e) => {
        e.preventDefault()
        this.props.Add_Lokasi(this.state.Nama, this.state.Deskripsi)
    }
    render() {
        const {
            Nama,
            isNamaError,
            Deskripsi,
        } = this.state

        const isLokasiActionAddLoading = this.props.isLokasiActionAddLoading
        const isLokasiExist = this.props.isLokasiExist

        return (

            <form
                onSubmit={this.formOnSubmit}
            >
                <div
                    style={{ margin: '0 5% 0 5%' }}
                >
                    <TextField
                        label='Nama'
                        type='text'
                        name='Nama'
                        value={Nama}
                        required
                        onInvalid={(e) => this.changeToError(e, true)}
                        onChange={this.onChangeField}
                        style={{ width: '100%', margin: '1% 0 1% 0' }}
                        error={isLokasiExist || isNamaError ? true : false}
                        helperText={isLokasiExist ? 'Lokasi Sudah Ada' : isNamaError ? 'Nama Harus diisi' : ''}
                    />
                    <TextField
                        label='Deskripsi'
                        type='text'
                        name='Deskripsi'
                        value={Deskripsi}
                        onChange={this.onChangeField}
                        style={{ width: '100%', margin: '1% 0 1% 0' }}
                    />
                </div>
                <center>
                    <Button
                        variant='contained'
                        size='large'
                        style={{ border: '0px solid red', width: "85%", margin: '3% 0 2% 0' }}
                        type='submit'
                        disabled={isLokasiActionAddLoading ? true : false}
                    >
                        {isLokasiActionAddLoading ? <CircularProgress /> : 'Add Lokasi'}
                    </Button>
                </center>
            </form>
        )
    }
}

const mapStateToProps = state => ({
    isLokasiActionAddLoading: state.Setting.isLokasiActionAddLoading,
    isLokasiExist: state.Setting.isLokasiExist,
})

export default connect(mapStateToProps, { Add_Lokasi })(SettingLokasiAdd)