import React, { Fragment, useEffect } from "react"

import CoTable from "../../../../Containers/TableNew"

const MPPOutputDetailed = ({ isLoading, Data }) => {

    const [BodyData, setBodyData] = React.useState([])

    useEffect(() => {
        setBodyData(Data)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Data])

    const st_TableBorderHead = { borderTop: '1px solid black', borderRight: '0px solid black', borderBottom: '1px solid black', borderLeft: '1px solid black', fontFamily: 'Montserrat, Segoe UI', }
    const st_BgOrange = { backgroundColor: '#FFC000' }
    const st_BgBlue = { backgroundColor: '#00B0F0', color: 'white' }
    const st_TableBorderHead_WithoutTop = { borderTop: '0px solid black', borderRight: '0px solid black', borderBottom: '1px solid black', borderLeft: '1px solid black', fontFamily: 'Montserrat, Segoe UI', }

    const st_TableBorder = { borderLeft: '1px solid black', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }

    const HeadData = [
        [
            {
                Nama: 'No',
                align: 'center',
                rowSpan: 2,
                KeyName: 'index',
                SortingType: 'Number',
                style: { ...st_TableBorderHead },
                BodyStyle: { ...st_TableBorder }
            },
            {
                Nama: 'PR Number',
                align: 'center',
                rowSpan: 2,
                KeyName: 'PRNumber',
                style: { ...st_TableBorderHead },
                BodyStyle: { ...st_TableBorder }
            },
            {
                Nama: 'Progress',
                align: 'center',
                rowSpan: 2,
                KeyName: 'Progress',
                style: { ...st_TableBorderHead },
                BodyStyle: { ...st_TableBorder }
            },
            {
                Nama: 'Status',
                align: 'center',
                rowSpan: 2,
                SortingType: 'Status',
                style: { ...st_TableBorderHead },
                BodyStyle: { ...st_TableBorder }
            },
            {
                Nama: 'Nama',
                align: 'center',
                rowSpan: 2,
                KeyName: 'Nama',
                style: { ...st_TableBorderHead },
                BodyStyle: { ...st_TableBorder }
            },
            {
                Nama: 'Divisi',
                align: 'center',
                rowSpan: 2,
                KeyName: 'DivisionNama',
                style: { ...st_TableBorderHead },
                BodyStyle: { ...st_TableBorder }
            },
            {
                Nama: 'Department',
                align: 'center',
                rowSpan: 2,
                KeyName: 'DepartemenNama',
                style: { ...st_TableBorderHead },
                BodyStyle: { ...st_TableBorder }
            },
            {
                Nama: 'Posisi',
                align: 'center',
                rowSpan: 2,
                KeyName: 'Sub',
                style: { ...st_TableBorderHead },
                BodyStyle: { ...st_TableBorder }
            },
            {
                Nama: 'Area Kerja',
                align: 'center',
                rowSpan: 2,
                KeyName: 'LokasiLainnya',
                style: { ...st_TableBorderHead },
                BodyStyle: { ...st_TableBorder }
            },
            {
                Nama: 'Employee Type',
                align: 'center',
                rowSpan: 2,
                KeyName: 'StatusKaryawan',
                style: { ...st_TableBorderHead },
                BodyStyle: { ...st_TableBorder }
            },
            {
                Nama: 'MPP',
                align: 'center',
                colSpan: 2,
                style: { ...st_TableBorderHead, ...st_BgOrange },
                BodyStyle: { ...st_TableBorder }
            },
            {
                Nama: 'Actual',
                align: 'center',
                colSpan: 3,
                style: { ...st_TableBorderHead, ...st_BgBlue, borderRight: '1px solid black' },
                BodyStyle: { ...st_TableBorder, borderRight: '1px solid black' }
            },
        ],
        [
            {
                Nama: 'Q',
                // width: '5%',
                KeyName: 'MMPQ',
                style: { ...st_TableBorderHead_WithoutTop, ...st_BgOrange },
                BodyStyle: { ...st_TableBorder }
            },
            {
                Nama: 'M',
                // width: '5%',
                KeyName: 'MMPM',
                style: { ...st_TableBorderHead_WithoutTop, ...st_BgOrange },
                BodyStyle: { ...st_TableBorder }
            },
            {
                Nama: 'Q',
                // width: '5%',
                KeyName: 'ActualQ',
                style: { ...st_TableBorderHead_WithoutTop, ...st_BgBlue },
                BodyStyle: { ...st_TableBorder }
            },
            {
                Nama: 'M',
                // width: '5%',
                KeyName: 'ActualM',
                style: { ...st_TableBorderHead_WithoutTop, ...st_BgBlue },
                BodyStyle: { ...st_TableBorder }
            },
            {
                Nama: 'D',
                // width: '5%',
                KeyName: 'ActualD',
                style: { ...st_TableBorderHead_WithoutTop, ...st_BgBlue, borderRight: '1px solid black' },
                BodyStyle: { ...st_TableBorder }
            },
        ]
    ]

    const UsedBodyData = BodyData ? BodyData.map((item, index) => {
        return {
            index: {
                value: index + 1
            },
            PRNumber: {
                value: `${item?.JobId ? item.JobId : ' '}.${item?.JobListId ? item.JobListId : ' '}`
            },
            Progress: {
                value: item ?
                    item.OBStatus ? 'Hired'
                        : item.KoStatus ? 'On Boarding'
                            : item.PKStatus ? 'Contract'
                                : item.PBStatus ? 'PKWT/PKWTT'
                                    // : item.PBStatus ? 'PKWT/PKWTT' // TODO pre boarding
                                    : item.OfStatus ? 'MCU'
                                        : item.UsIFStatus ? 'Offering'
                                            : item.HrIStatus ? 'Department Interview'
                                                : item.PTId ? 'HR Interview'
                                                    : item.AccDepStatus ? 'Personality Test'
                                                        : item.AccReferralStatus ? 'Sourcing'
                                                            : ' '
                    : ' '
            },
            Status: {
                value: item ?
                    item.OBStatus ? 'Hired'
                        : item.KoStatus ? item.KoStatus === 'Accept' ? 'Waiting' : item.KoStatus
                            : item.PKStatus ? item.PKStatus === 'Accept' ? 'Waiting' : item.PKStatus
                                : item.PBStatus ? item.PBStatus === 'Accept' ? 'Waiting' : item.PBStatus
                                    // : item.PBStatus ? item.PBStatus === 'Accept' ? 'Waiting' : item.PBStatus // TODO pre boarding
                                    : item.OfStatus ? item.OfStatus === 'Accept' ? 'Waiting' : item.OfStatus
                                        : item.UsIFStatus ? item.UsIFStatus === 'Accept' ? 'Waiting' : item.UsIFStatus
                                            : item.HrIStatus ? item.HrIStatus === 'Accept' ? 'Waiting' : item.HrIStatus
                                                : item.PTId ? item.PTId === 'Accept' ? 'Waiting' : 'Waiting'
                                                    : item.AccDepStatus ? item.AccDepStatus === 'Accept' ? 'Waiting' : item.AccDepStatus
                                                        : item.AccReferralStatus ? item.AccReferralStatus === 'Accept' ? 'Waiting' : item.AccReferralStatus
                                                            : ' '
                    : ' '
            },
            Nama: {
                value: item?.BNama ? item.BNama : ' '
            },
            DivisionNama: {
                value: item?.DivisionNama ? item.DivisionNama : ' '
            },
            DepartemenNama: {
                value: item?.DepartemenNama ? item.DepartemenNama : ' '
            },
            Sub: {
                value: item?.Sub ? item.Sub : ' '
            },
            LokasiLainnya: {
                value: item?.LokasiLainnya ? item.LokasiLainnya : ' '
            },
            StatusKaryawan: {
                value: item?.StatusKaryawan ? item.StatusKaryawan : ' '
            },
            MMPQ: {
                value: item ?
                    item.BulanBuka === 0 || item.BulanBuka === 1 || item.BulanBuka === 2 ? "Q1"
                        : item.BulanBuka === 3 || item.BulanBuka === 4 || item.BulanBuka === 5 ? "Q2"
                            : item.BulanBuka === 6 || item.BulanBuka === 7 || item.BulanBuka === 8 ? "Q3"
                                : item.BulanBuka === 9 || item.BulanBuka === 10 || item.BulanBuka === 11 ? "Q4"
                                    : ' '
                    : ' '
            },
            MPPM: {
                value: item ?
                    item.BulanBuka === 0 ? "Jan"
                        : item.BulanBuka === 1 ? "Feb"
                            : item.BulanBuka === 2 ? "Mar"
                                : item.BulanBuka === 3 ? "Apr"
                                    : item.BulanBuka === 4 ? "May"
                                        : item.BulanBuka === 5 ? "Jun"
                                            : item.BulanBuka === 6 ? "Jul"
                                                : item.BulanBuka === 7 ? "Aug"
                                                    : item.BulanBuka === 8 ? "Sep"
                                                        : item.BulanBuka === 9 ? "Oct"
                                                            : item.BulanBuka === 10 ? "Nov"
                                                                : item.BulanBuka === 11 ? "Dec"
                                                                    : item.BulanBuka
                    : ' '
            },
            ActualQ: {
                value: item?.OBStatus ? item.OBStatus ?
                    item?.OBDate ?
                        new Date(item.OBDate).getMonth() === 0 || new Date(item.OBDate).getMonth() === 1 || new Date(item.OBDate).getMonth() === 2 ? "Q1"
                            : new Date(item.OBDate).getMonth() === 3 || new Date(item.OBDate).getMonth() === 4 || new Date(item.OBDate).getMonth() === 5 ? "Q2"
                                : new Date(item.OBDate).getMonth() === 6 || new Date(item.OBDate).getMonth() === 7 || new Date(item.OBDate).getMonth() === 8 ? "Q3"
                                    : new Date(item.OBDate).getMonth() === 9 || new Date(item.OBDate).getMonth() === 10 || new Date(item.OBDate).getMonth() === 11 ? "Q4"
                                        : ' '
                        : ' '
                    : ' ' : ' '
            },
            ActualM: {
                value: item?.OBStatus ? item.OBStatus ?
                    item?.OBDate ?
                        new Date(item.OBDate).getMonth() === 0 ? "Jan"
                            : new Date(item.OBDate).getMonth() === 1 ? "Feb"
                                : new Date(item.OBDate).getMonth() === 2 ? "Mar"
                                    : new Date(item.OBDate).getMonth() === 3 ? "Apr"
                                        : new Date(item.OBDate).getMonth() === 4 ? "May"
                                            : new Date(item.OBDate).getMonth() === 5 ? "Jun"
                                                : new Date(item.OBDate).getMonth() === 6 ? "Jul"
                                                    : new Date(item.OBDate).getMonth() === 7 ? "Aug"
                                                        : new Date(item.OBDate).getMonth() === 8 ? "Sep"
                                                            : new Date(item.OBDate).getMonth() === 9 ? "Oct"
                                                                : new Date(item.OBDate).getMonth() === 10 ? "Nov"
                                                                    : new Date(item.OBDate).getMonth() === 11 ? "Dec"
                                                                        : new Date(item.OBDate).getMonth()
                        : ' '
                    : ' ' : ' '
            },
            ActualD: {
                value: item?.OBStatus ? item.OBStatus ?
                    item?.OBDate ? new Date(item.OBDate).toLocaleDateString(['ban', 'id'])
                        : ' '
                    : ' ' : ' '
            },

        }
    }) : []

    return (
        <Fragment>
            <CoTable // TODO SOME HOW IF TAB VALUE SET INTO 3 ITS WORK
                TableId={'MPPOutputDetailed'}
                HeadData={HeadData}
                Data={UsedBodyData}
                isHeadLoading={isLoading}
                isBodyLoading={isLoading}
                isPagination={UsedBodyData.length > 5 ? true : false}
                isPaginationLoading={isLoading}
                isHeading={true}
                isDownload={true}
                ComplexDataDownload={true}
                isMultipleHeader={true}
            />
        </Fragment>
    )
}

export default MPPOutputDetailed