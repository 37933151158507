import axios from 'axios'

// import { Redirect } from 'react-router-dom'

// import Store from '../Store'
import { LogIn, Load_User } from './Auth.Action'

import {
    AUTH_RELOADE_PAGE,
    ACCOUNT_ACTION_GET_ID,
    //* BIODATA
    ACCOUNT_BIODATA_LOADING,
    ACCOUNT_BIODATA_LOADED,
    ACCOUNT_BIODATA_LOADING_DONE,
    //* ACCOUNT
    ACCOUNT_ACCOUNT_LOADING,
    ACCOUNT_ACCOUNT_LOADED,
    ACCOUNT_ACCOUNT_LOADING_DONE,
    //* PENDIDIKAN
    ACCOUNT_PENDIDIKAN_FORMAL_LOADING,
    ACCOUNT_PENDIDIKAN_FORMAL_LOADED,
    ACCOUNT_PENDIDIKAN_FORMAL_LOADING_DONE,
    ACCOUNT_PENDIDIKAN_UNFORMAL_LOADING,
    ACCOUNT_PENDIDIKAN_UNFORMAL_LOADED,
    ACCOUNT_PENDIDIKAN_UNFORMAL_LOADING_DONE,
    //* KETERAMPILAN
    ACCOUNT_KETERAMPILAN_BAHASA_LOADING,
    ACCOUNT_KETERAMPILAN_BAHASA_LOADED,
    ACCOUNT_KETERAMPILAN_BAHASA_LOADING_DONE,
    ACCOUNT_KETERAMPILAN_KOMPUTER_LOADING,
    ACCOUNT_KETERAMPILAN_KOMPUTER_LOADED,
    ACCOUNT_KETERAMPILAN_KOMPUTER_LOADING_DONE,
    ACCOUNT_KETERAMPILAN_RELEVANT_LOADING,
    ACCOUNT_KETERAMPILAN_RELEVANT_LOADED,
    ACCOUNT_KETERAMPILAN_RELEVANT_LOADING_DONE,
    //* KELUARGA
    ACCOUNT_KELUARGA_LOADING,
    ACCOUNT_KELUARGA_LOADED,
    ACCOUNT_KELUARGA_LOADING_DONE,
    //* KERJA
    ACCOUNT_KERJA_RIWAYAT_LOADING,
    ACCOUNT_KERJA_RIWAYAT_LOADED,
    ACCOUNT_KERJA_RIWAYAT_LOADING_DONE,
    ACCOUNT_KERJA_REFERENSI_LOADING,
    ACCOUNT_KERJA_REFERENSI_LOADED,
    ACCOUNT_KERJA_REFERENSI_LOADING_DONE,
    ACCOUNT_KERJA_KERJA_LOADING,
    ACCOUNT_KERJA_KERJA_LOADED,
    ACCOUNT_KERJA_KERJA_LOADING_DONE,
    //* PERTANYAAN
    ACCOUNT_PERTANYAAN_LOADING,
    ACCOUNT_PERTANYAAN_LOADED,
    ACCOUNT_PERTANYAAN_LOADING_DONE,
    //* RIWAYAT
    ACCOUNT_RIWAYAT_LOADING,
    ACCOUNT_RIWAYAT_LOADED,
    ACCOUNT_RIWAYAT_LOADING_DONE,
    //* CREATEACCOUNT
    ACCOUNT_CREATEACCOUNT_LOADING,
    ACCOUNT_CREATEACCOUNT_LOADED,
    ACCOUNT_CREATEACCOUNT_ERROR_EMAIL_USED,
    ACCOUNT_CREATEACCOUNT_ERROR_USERNAME_USED,
    //* ONESTEP
    ONESTEP_CREATE_ACCOUNT,
    ONESTEP_FILING_BIODATA,
} from './Type.Action'

import {
    Create_Error_Messages,
    Create_Success_Messages
} from './Messages.Actions'

import { Get_IpAddres, defaultHeader, tokenConfig, tokenConfigMultipleForm, ErrorHandler } from './Base.Action'

import { Error_Messages_Standardization } from '../../Global'
// import { Fragment } from 'react'

export const get_Account_Action_Id = (Id) => (dispatch) => {
    dispatch({
        type: ACCOUNT_ACTION_GET_ID,
        payload: Id
    })
}


//* BIODATA

export const get_Biodata_Data = (AccountId) => async (dispatch, getState) => {
    try {
        dispatch({ type: ACCOUNT_BIODATA_LOADING })

        const IpAddres = Get_IpAddres()
        const Data = await axios.get(`${IpAddres}/account/biodata/${AccountId}`, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({
                type: ACCOUNT_BIODATA_LOADED,
                payload: Data.data
            })
            dispatch({ type: ACCOUNT_BIODATA_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: ACCOUNT_BIODATA_LOADING_DONE })
        ErrorHandler(err, 'get_Biodata_Data')
    }
}

export const get_Biodata_Extend_Data = (AccountId) => async (dispatch, getState) => {
    try {
        dispatch({ type: ACCOUNT_BIODATA_LOADING })

        const IpAddres = Get_IpAddres()
        const Data = await axios.get(`${IpAddres}/account/biodata/extend/${AccountId}`, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({
                type: ACCOUNT_BIODATA_LOADED,
                payload: Data.data
            })
            dispatch({ type: ACCOUNT_BIODATA_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: ACCOUNT_BIODATA_LOADING_DONE })
        ErrorHandler(err, 'get_Biodata_Extend_Data')
    }
}

export const Update_Biodata = (
    Id,
    Nama,
    TempatLahir,
    TanggalLahir,
    JenisKelamin,
    Telepon,
    Alamat,
    Tinggi,
    Berat,
    Hobi,
    PendidikanJenjang,
    PendidikanNama,
    PendidikanJurusan,
    Alasan,
    Salary,
    KerjaReferensiNama,
    KerjaReferensiJabatan,
    KerjaReferensiKontak,

    KerjaReferensiNamaKolega,
    KerjaReferensiKontakKolega,
    KerjaReferensiKeteranganKolega,

    KerjaReferensiNamaHr,
    KerjaReferensiKontakHr,
    KerjaReferensiKeteranganHr,

    KerjaReferensiNamaDirect,
    KerjaReferensiKontakDirect,
    KerjaReferensiKeteranganDirect,

    English,
    Bahasa,
    Nikah,
    KelaurgaEtana,
    KelaurgaEtanaNama,
    Relocate,
    ReasonNotRelocate,
    Vaksin,
    ReasonVaksin,
    FindOutEtana,
    PortofolioLink,
    SMFacebook,
    SMIG,
    SMTwitter,
    SMLinkdin,
    Foto,
    CV,
    RiwayatKerja,
    isGoToJobList,
    isOneStep,
    User
) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()

        const bodydata = new FormData()

        bodydata.append('Nama', Nama)
        bodydata.append('TempatLahir', TempatLahir)
        bodydata.append('TanggalLahir', TanggalLahir)
        bodydata.append('JenisKelamin', JenisKelamin)
        bodydata.append('Telepon', Telepon)
        bodydata.append('Alamat', Alamat)
        bodydata.append('Tinggi', Tinggi)
        bodydata.append('Berat', Berat)
        bodydata.append('Hobi', Hobi)
        bodydata.append('PendidikanJenjang', PendidikanJenjang)
        bodydata.append('PendidikanNama', PendidikanNama)
        bodydata.append('PendidikanJurusan', PendidikanJurusan)
        bodydata.append('Alasan', Alasan)
        bodydata.append('Salary', Salary)
        bodydata.append('KerjaReferensiNama', KerjaReferensiNama)
        bodydata.append('KerjaReferensiJabatan', KerjaReferensiJabatan)
        bodydata.append('KerjaReferensiKontak', KerjaReferensiKontak)

        bodydata.append('KerjaReferensiNamaKolega', KerjaReferensiNamaKolega)
        bodydata.append('KerjaReferensiKontakKolega', KerjaReferensiKontakKolega)
        bodydata.append('KerjaReferensiKeteranganKolega', KerjaReferensiKeteranganKolega)

        bodydata.append('KerjaReferensiNamaHr', KerjaReferensiNamaHr)
        bodydata.append('KerjaReferensiKontakHr', KerjaReferensiKontakHr)
        bodydata.append('KerjaReferensiKeteranganHr', KerjaReferensiKeteranganHr)

        bodydata.append('KerjaReferensiNamaDirect', KerjaReferensiNamaDirect)
        bodydata.append('KerjaReferensiKontakDirect', KerjaReferensiKontakDirect)
        bodydata.append('KerjaReferensiKeteranganDirect', KerjaReferensiKeteranganDirect)





        bodydata.append('English', English)
        bodydata.append('Bahasa', Bahasa)
        bodydata.append('Nikah', Nikah)
        bodydata.append('KelaurgaEtana', KelaurgaEtana)
        bodydata.append('KelaurgaEtanaNama', KelaurgaEtanaNama)
        bodydata.append('Relocate', Relocate)
        bodydata.append('ReasonNotRelocate', ReasonNotRelocate)
        bodydata.append('Vaksin', Vaksin)
        bodydata.append('ReasonVaksin', ReasonVaksin)
        bodydata.append('FindOutEtana', FindOutEtana)
        bodydata.append('PortofolioLink', PortofolioLink)
        bodydata.append('SMFacebook', SMFacebook)
        bodydata.append('SMIG', SMIG)
        bodydata.append('SMTwitter', SMTwitter)
        bodydata.append('SMLinkdin', SMLinkdin)

        bodydata.append('RiwayatKerja', JSON.stringify(RiwayatKerja))

        if (CV !== null) {
            bodydata.append("CV", CV);
        }

        if (Foto !== null) {
            bodydata.append("Foto", Foto);
        }

        const Data = await axios.post(`${IpAddres}/account/biodata/${Id}/update`, bodydata, tokenConfigMultipleForm(getState))

        if (Data.status === 200) {
            if (isOneStep) {
                dispatch(Create_Success_Messages(null, `Biodata Updated`))
                dispatch({
                    type: ONESTEP_FILING_BIODATA,
                    payload: User
                })
            } else if (isGoToJobList) {
                dispatch(Create_Success_Messages(200, `Job Berhasil diupdate, silahkan lamar di vacancy yang tersedia`))
                setTimeout(() => window.location.href = '/joblist', 2)
            } else {
                dispatch({ type: AUTH_RELOADE_PAGE })
                dispatch(Create_Success_Messages(200, `Job Berhasil diupdate`))
            }
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'Update_Biodata')
    }
}

export const Update_Biodata_Extend = (
    Id,
    NamaLengkap,
    NamaPanggilan,
    TanggalMulaiBekerja,
    TempatLahir,
    TanggalLahir,
    Divisi,
    Sub,

    AlamatKTP,
    KelurahanKTP,
    KotaKTP,
    KodePosKTP,
    TlpnRumahKTP,

    Alamat,
    Kelurahan,
    Kota,
    KodePos,
    TlpnRumah,
    Hp,
    Kelamin,
    Agama,
    Perkawinan,
    Kewarganegaraan,
    KTP,
    KK,
    NPWP,
    BPJSKesehatan,
    BPJSKetenagakerjaan,
    BCA,

    NamaPasangan,
    TanggalLahirPasangan,
    PendidikanPasangan,
    PekerjaanPasangan,
    PerusahaanPasangan,

    DataAnak,

    NamaAyah,
    TanggalLahirAyah,
    NamaIbu,
    TanggalLahirIbu,
    AlamatOrangTua,
    KelurahanOrangTua,
    KotaOrangTua,
    KodePosOrangTua,
    NamaAyahMertua,
    NamaIbuMertua,
    AlamatMertua,
    KelurahanMertua,
    KotaMertua,
    KodePosMertua,

    NamaWaris,
    HubunganWaris,
    KontakWaris,

    NamaDarurat,
    NoDarurat,
    HubunganDarurat,

    DataPendidikanFormal,

    DataPendidikanInformal,

    DataPengalamanKerja,

    FileFoto,
    FileKTP,
    FileBPJSKesehatan,
    FileBPJSKetenagakerjaan,
    FileRekeningBCA,
    FileNPWP,
    FileKartuKeluarga,
    FileIjazah,
    FileSertifikasi,
) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()

        const bodydata = new FormData()

        bodydata.append("NamaLengkap", NamaLengkap)
        bodydata.append("NamaPanggilan", NamaPanggilan)
        bodydata.append("TanggalMulaiBekerja", TanggalMulaiBekerja)
        bodydata.append("TempatLahir", TempatLahir)
        bodydata.append("TanggalLahir", TanggalLahir)
        bodydata.append("Divisi", Divisi)
        bodydata.append("Sub", Sub)

        bodydata.append("AlamatKTP", AlamatKTP)
        bodydata.append("KelurahanKTP", KelurahanKTP)
        bodydata.append("KotaKTP", KotaKTP)
        bodydata.append("KodePosKTP", KodePosKTP)
        bodydata.append("TlpnRumahKTP", TlpnRumahKTP)

        bodydata.append("Alamat", Alamat)
        bodydata.append("Kelurahan", Kelurahan)
        bodydata.append("Kota", Kota)
        bodydata.append("KodePos", KodePos)
        bodydata.append("TlpnRumah", TlpnRumah)
        bodydata.append("Hp", Hp)
        bodydata.append("Kelamin", Kelamin)
        bodydata.append("Agama", Agama)
        bodydata.append("Perkawinan", Perkawinan)
        bodydata.append("Kewarganegaraan", Kewarganegaraan)
        bodydata.append("KTP", KTP)
        bodydata.append("KK", KK)
        bodydata.append("NPWP", NPWP)
        bodydata.append("BPJSKesehatan", BPJSKesehatan)
        bodydata.append("BPJSKetenagakerjaan", BPJSKetenagakerjaan)
        bodydata.append("BCA", BCA)

        bodydata.append("NamaPasangan", NamaPasangan)
        bodydata.append("TanggalLahirPasangan", TanggalLahirPasangan)
        bodydata.append("PendidikanPasangan", PendidikanPasangan)
        bodydata.append("PekerjaanPasangan", PekerjaanPasangan)
        bodydata.append("PerusahaanPasangan", PerusahaanPasangan)

        bodydata.append("DataAnak", JSON.stringify(DataAnak))

        bodydata.append("NamaAyah", NamaAyah)
        bodydata.append("TanggalLahirAyah", TanggalLahirAyah)
        bodydata.append("NamaIbu", NamaIbu)
        bodydata.append("TanggalLahirIbu", TanggalLahirIbu)
        bodydata.append("AlamatOrangTua", AlamatOrangTua)
        bodydata.append("KelurahanOrangTua", KelurahanOrangTua)
        bodydata.append("KotaOrangTua", KotaOrangTua)
        bodydata.append("KodePosOrangTua", KodePosOrangTua)
        bodydata.append("NamaAyahMertua", NamaAyahMertua)
        bodydata.append("NamaIbuMertua", NamaIbuMertua)
        bodydata.append("AlamatMertua", AlamatMertua)
        bodydata.append("KelurahanMertua", KelurahanMertua)
        bodydata.append("KotaMertua", KotaMertua)
        bodydata.append("KodePosMertua", KodePosMertua)

        bodydata.append("NamaWaris", NamaWaris)
        bodydata.append("HubunganWaris", HubunganWaris)
        bodydata.append("KontakWaris", KontakWaris)

        bodydata.append("NamaDarurat", NamaDarurat)
        bodydata.append("NoDarurat", NoDarurat)
        bodydata.append("HubunganDarurat", HubunganDarurat)

        bodydata.append("DataPendidikanFormal", JSON.stringify(DataPendidikanFormal))

        bodydata.append("DataPendidikanInformal", JSON.stringify(DataPendidikanInformal))

        bodydata.append("DataPengalamanKerja", JSON.stringify(DataPengalamanKerja))

        if (FileFoto !== null) {
            bodydata.append("FileFoto", FileFoto)
        }

        if (FileKTP !== null) {
            bodydata.append("FileKTP", FileKTP)
        }

        if (FileBPJSKesehatan !== null) {
            bodydata.append("FileBPJSKesehatan", FileBPJSKesehatan)
        }

        if (FileBPJSKetenagakerjaan !== null) {
            bodydata.append("FileBPJSKetenagakerjaan", FileBPJSKetenagakerjaan)
        }

        if (FileRekeningBCA !== null) {
            bodydata.append("FileRekeningBCA", FileRekeningBCA)
        }

        if (FileNPWP !== null) {
            bodydata.append("FileNPWP", FileNPWP)
        }

        if (FileKartuKeluarga !== null) {
            bodydata.append("FileKartuKeluarga", FileKartuKeluarga)
        }

        if (FileIjazah !== null) {
            bodydata.append("FileIjazah", FileIjazah)
        }

        if (FileSertifikasi !== null) {
            bodydata.append("FileSertifikasi", FileSertifikasi)
        }

        const Data = await axios.post(`${IpAddres}/account/biodata/extend/${Id}/update`, bodydata, tokenConfigMultipleForm(getState))

        if (Data.status === 200) {
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `Job Berhasil diupdate`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'Update_Biodata_Extend')
    }
}

//* DOWNLOAD


export const download_Foto = (AccountId, Name) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ AccountId })

        const Responses = await axios.post(`${IpAddres}/account/biodata/download/foto`, body, {
            responseType: 'blob',
            ...tokenConfig(getState)
        })

        if (Responses) {
            const url = window.URL.createObjectURL(new Blob([Responses.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${Name}_Photo.jpeg`)
            document.body.appendChild(link)
            link.click()
            dispatch(Create_Success_Messages(null, 'Proses download Berhasil'))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Something Werong')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'download_Foto')
    }
}

export const download_KTP = (AccountId, Name) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ AccountId })

        const Responses = await axios.post(`${IpAddres}/account/biodata/download/ktp`, body, {
            responseType: 'blob',
            ...tokenConfig(getState)
        })

        if (Responses) {
            const url = window.URL.createObjectURL(new Blob([Responses.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${Name}_KTP.pdf`)
            document.body.appendChild(link)
            link.click()
            dispatch(Create_Success_Messages(null, 'Proses download Berhasil'))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Something Werong')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'download_KTP')
    }
}


export const download_KK = (AccountId, Name) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ AccountId })

        const Responses = await axios.post(`${IpAddres}/account/biodata/download/kk`, body, {
            responseType: 'blob',
            ...tokenConfig(getState)
        })

        if (Responses) {
            const url = window.URL.createObjectURL(new Blob([Responses.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${Name}_KK.pdf`)
            document.body.appendChild(link)
            link.click()
            dispatch(Create_Success_Messages(null, 'Proses download Berhasil'))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Something Werong')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'download_KK')
    }
}


export const download_NPWP = (AccountId, Name) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ AccountId })

        const Responses = await axios.post(`${IpAddres}/account/biodata/download/NPWP`, body, {
            responseType: 'blob',
            ...tokenConfig(getState)
        })

        if (Responses) {
            const url = window.URL.createObjectURL(new Blob([Responses.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${Name}_NPWP.pdf`)
            document.body.appendChild(link)
            link.click()
            dispatch(Create_Success_Messages(null, 'Proses download Berhasil'))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Something Werong')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'download_NPWP')
    }
}


export const download_BCA = (AccountId, Name) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ AccountId })

        const Responses = await axios.post(`${IpAddres}/account/biodata/download/bca`, body, {
            responseType: 'blob',
            ...tokenConfig(getState)
        })

        if (Responses) {
            const url = window.URL.createObjectURL(new Blob([Responses.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${Name}_BCA.pdf`)
            document.body.appendChild(link)
            link.click()
            dispatch(Create_Success_Messages(null, 'Proses download Berhasil'))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Something Werong')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'download_BCA')
    }
}


export const download_BPJSKesehatan = (AccountId, Name) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ AccountId })

        const Responses = await axios.post(`${IpAddres}/account/biodata/download/BPJSKesehatan`, body, {
            responseType: 'blob',
            ...tokenConfig(getState)
        })

        if (Responses) {
            const url = window.URL.createObjectURL(new Blob([Responses.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${Name}_BPJS Kes.pdf`)
            document.body.appendChild(link)
            link.click()
            dispatch(Create_Success_Messages(null, 'Proses download Berhasil'))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Something Werong')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'download_BPJSKesehatan')
    }
}


export const download_BPJSKetenagakerjaan = (AccountId, Name) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ AccountId })

        const Responses = await axios.post(`${IpAddres}/account/biodata/download/BPJSKetenagakerjaan`, body, {
            responseType: 'blob',
            ...tokenConfig(getState)
        })

        if (Responses) {
            const url = window.URL.createObjectURL(new Blob([Responses.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${Name}_BPJS Ket.pdf`)
            document.body.appendChild(link)
            link.click()
            dispatch(Create_Success_Messages(null, 'Proses download Berhasil'))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Something Werong')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'download_BPJSKetenagakerjaan')
    }
}


export const download_Ijazah = (AccountId, Name) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ AccountId })

        const Responses = await axios.post(`${IpAddres}/account/biodata/download/Ijazah`, body, {
            responseType: 'blob',
            ...tokenConfig(getState)
        })

        if (Responses) {
            const url = window.URL.createObjectURL(new Blob([Responses.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${Name}_Ijazah.pdf`)
            document.body.appendChild(link)
            link.click()
            dispatch(Create_Success_Messages(null, 'Proses download Berhasil'))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Something Werong')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'download_Ijazah')
    }
}


export const download_Sertifikat = (AccountId, Name) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ AccountId })

        const Responses = await axios.post(`${IpAddres}/account/biodata/download/Sertifikast`, body, {
            responseType: 'blob',
            ...tokenConfig(getState)
        })

        if (Responses) {
            const url = window.URL.createObjectURL(new Blob([Responses.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${Name}_Sertifikat.pdf`)
            document.body.appendChild(link)
            link.click()
            dispatch(Create_Success_Messages(null, 'Proses download Berhasil'))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Something Werong')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'download_Sertifikat')
    }
}

export const download_CV = (AccountId, Name) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ AccountId })

        const Responses = await axios.post(`${IpAddres}/account/biodata/download/cv`, body, {
            responseType: 'blob',
            ...tokenConfig(getState)
        })

        if (Responses) {
            const url = window.URL.createObjectURL(new Blob([Responses.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${Name}_Curriculum vitae.pdf`)
            document.body.appendChild(link)
            link.click()
            dispatch(Create_Success_Messages(null, 'Proses download Berhasil'))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Something Werong')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'download_Sertifikat')
    }
}

//* ACCOUNT

export const get_Account_Data = (AccountId) => async (dispatch, getState) => {
    try {
        dispatch({ type: ACCOUNT_ACCOUNT_LOADING })

        const IpAddres = Get_IpAddres()
        const Data = await axios.get(`${IpAddres}/account/account/${AccountId}`, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({
                type: ACCOUNT_ACCOUNT_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: ACCOUNT_ACCOUNT_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: ACCOUNT_ACCOUNT_LOADING_DONE })
        ErrorHandler(err, 'get_Account_Data')
    }
}

export const Update_Account_UserName = (BioId, UserName) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ UserName })
        const Data = await axios.post(`${IpAddres}/account/account/${BioId}/update/UserName`, body, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `Job Berhasil diupdate`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'Update_Account_UserName')
    }
}

export const Update_Account_Email = (BioId, Email) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ Email })
        const Data = await axios.post(`${IpAddres}/account/account/${BioId}/update/Email`, body, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `Job Berhasil diupdate`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'Update_Account_Email')
    }
}

export const Update_Account_Password = (BioId, oldPassword, Password) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ oldPassword, Password })
        const Data = await axios.post(`${IpAddres}/account/account/${BioId}/update/Password`, body, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `Job Berhasil diupdate`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'Update_Account_Password')
    }
}


//* PENDIDIKAN

export const get_Pendidikan_Formal_Data = (AccountId) => async (dispatch, getState) => {
    try {
        dispatch({ type: ACCOUNT_PENDIDIKAN_FORMAL_LOADING })

        const IpAddres = Get_IpAddres()
        const Data = await axios.get(`${IpAddres}/account/pendidikan/formal/${AccountId}`, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({
                type: ACCOUNT_PENDIDIKAN_FORMAL_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: ACCOUNT_PENDIDIKAN_FORMAL_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: ACCOUNT_PENDIDIKAN_FORMAL_LOADING_DONE })
        ErrorHandler(err, 'get_Pendidikan_Formal_Data')
    }
}

export const Update_Pendidikan_Formal = (BioId, ListData) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ ListData })
        const Data = await axios.post(`${IpAddres}/account/pendidikan/formal/${BioId}/update`, body, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `Job Berhasil diupdate`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'Update_Pendidikan_Formal')
    }
}

export const get_Pendidikan_UnFormal_Data = (AccountId) => async (dispatch, getState) => {
    try {
        dispatch({ type: ACCOUNT_PENDIDIKAN_UNFORMAL_LOADING })

        const IpAddres = Get_IpAddres()
        const Data = await axios.get(`${IpAddres}/account/pendidikan/unformal/${AccountId}`, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({
                type: ACCOUNT_PENDIDIKAN_UNFORMAL_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: ACCOUNT_PENDIDIKAN_UNFORMAL_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: ACCOUNT_PENDIDIKAN_UNFORMAL_LOADING_DONE })
        ErrorHandler(err, 'get_Pendidikan_UnFormal_Data')
    }
}

export const Update_Pendidikan_UnFormal = (BioId, ListData) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ ListData })
        const Data = await axios.post(`${IpAddres}/account/pendidikan/unformal/${BioId}/update`, body, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `Job Berhasil diupdate`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'Update_Pendidikan_UnFormal')
    }
}

//* KETERAMPILAN

export const get_Keterampilan_Bahasa_Data = (AccountId) => async (dispatch, getState) => {
    try {
        dispatch({ type: ACCOUNT_KETERAMPILAN_BAHASA_LOADING })

        const IpAddres = Get_IpAddres()
        const Data = await axios.get(`${IpAddres}/account/keterampilan/Bahasa/${AccountId}`, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({
                type: ACCOUNT_KETERAMPILAN_BAHASA_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: ACCOUNT_KETERAMPILAN_BAHASA_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: ACCOUNT_KETERAMPILAN_BAHASA_LOADING_DONE })
        ErrorHandler(err, 'get_Keterampilan_Bahasa_Data')
    }
}

export const Update_Keterampilan_Bahasa = (BioId, ListData) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ ListData })
        const Data = await axios.post(`${IpAddres}/account/keterampilan/Bahasa/${BioId}/update`, body, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `Job Berhasil diupdate`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'Update_Keterampilan_Bahasa')
    }
}

export const get_Keterampilan_Komputer_Data = (AccountId) => async (dispatch, getState) => {
    try {
        dispatch({ type: ACCOUNT_KETERAMPILAN_KOMPUTER_LOADING })

        const IpAddres = Get_IpAddres()
        const Data = await axios.get(`${IpAddres}/account/keterampilan/komputer/${AccountId}`, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({
                type: ACCOUNT_KETERAMPILAN_KOMPUTER_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: ACCOUNT_KETERAMPILAN_KOMPUTER_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: ACCOUNT_KETERAMPILAN_KOMPUTER_LOADING_DONE })
        ErrorHandler(err, 'get_Keterampilan_Komputer_Data')
    }
}

export const Update_Keterampilan_Komputer = (BioId, ListData) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ ListData })
        const Data = await axios.post(`${IpAddres}/account/keterampilan/komputer/${BioId}/update`, body, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `Job Berhasil diupdate`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'Update_Keterampilan_Komputer')
    }
}

export const get_Keterampilan_Relevant_Data = (AccountId) => async (dispatch, getState) => {
    try {
        dispatch({ type: ACCOUNT_KETERAMPILAN_RELEVANT_LOADING })

        const IpAddres = Get_IpAddres()
        const Data = await axios.get(`${IpAddres}/account/keterampilan/Relevant/${AccountId}`, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({
                type: ACCOUNT_KETERAMPILAN_RELEVANT_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: ACCOUNT_KETERAMPILAN_RELEVANT_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: ACCOUNT_KETERAMPILAN_RELEVANT_LOADING_DONE })
        ErrorHandler(err, 'get_Keterampilan_Relevant_Data')
    }
}

export const Update_Keterampilan_Relevant = (BioId, ListData) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ ListData })
        const Data = await axios.post(`${IpAddres}/account/keterampilan/Relevant/${BioId}/update`, body, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `Job Berhasil diupdate`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'Update_Keterampilan_Relevant')
    }
}

//* KELUARGA

export const get_Keluarga_Data = (AccountId) => async (dispatch, getState) => {
    try {
        dispatch({ type: ACCOUNT_KELUARGA_LOADING })

        const IpAddres = Get_IpAddres()
        const Data = await axios.get(`${IpAddres}/account/keluarga/${AccountId}`, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({
                type: ACCOUNT_KELUARGA_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: ACCOUNT_KELUARGA_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: ACCOUNT_KELUARGA_LOADING_DONE })
        ErrorHandler(err, 'get_Keluarga_Data')
    }
}

export const Update_Keluarga = (BioId, ListData) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ ListData })
        const Data = await axios.post(`${IpAddres}/account/Keluarga/${BioId}/update`, body, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `Job Berhasil diupdate`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'Update_Keluarga')
    }
}

//* KERJA

export const get_Kerja_Riwayat_Data = (AccountId) => async (dispatch, getState) => {
    try {
        dispatch({ type: ACCOUNT_KERJA_RIWAYAT_LOADING })

        const IpAddres = Get_IpAddres()
        const Data = await axios.get(`${IpAddres}/account/Kerja/Riwayat/${AccountId}`, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({
                type: ACCOUNT_KERJA_RIWAYAT_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: ACCOUNT_KERJA_RIWAYAT_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: ACCOUNT_KERJA_RIWAYAT_LOADING_DONE })
        ErrorHandler(err, 'get_Kerja_Riwayat_Data')
    }
}

export const Update_Kerja_Riwayat = (BioId, ListData) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ ListData })
        const Data = await axios.post(`${IpAddres}/account/Kerja/Riwayat/${BioId}/update`, body, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `Job Berhasil diupdate`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'Update_Kerja_Riwayat')
    }
}
export const get_Kerja_Referensi_Data = (AccountId) => async (dispatch, getState) => {
    try {
        dispatch({ type: ACCOUNT_KERJA_REFERENSI_LOADING })

        const IpAddres = Get_IpAddres()
        const Data = await axios.get(`${IpAddres}/account/Kerja/Referensi/${AccountId}`, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({
                type: ACCOUNT_KERJA_REFERENSI_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: ACCOUNT_KERJA_REFERENSI_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: ACCOUNT_KERJA_REFERENSI_LOADING_DONE })
        ErrorHandler(err, 'get_Kerja_Referensi_Data')
    }
}

export const Update_Kerja_Referensi = (BioId, ListData) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ ListData })
        const Data = await axios.post(`${IpAddres}/account/Kerja/Referensi/${BioId}/update`, body, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `Job Berhasil diupdate`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'Update_Kerja_Referensi')
    }
}
export const get_Kerja_Kerja_Data = (AccountId) => async (dispatch, getState) => {
    try {
        dispatch({ type: ACCOUNT_KERJA_KERJA_LOADING })

        const IpAddres = Get_IpAddres()
        const Data = await axios.get(`${IpAddres}/account/Kerja/Kerja/${AccountId}`, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({
                type: ACCOUNT_KERJA_KERJA_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: ACCOUNT_KERJA_KERJA_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: ACCOUNT_KERJA_KERJA_LOADING_DONE })
        ErrorHandler(err, 'get_Kerja_Kerja_Data')
    }
}

export const Update_Kerja_Kerja = (BioId, Uraian, Struktur) => async (dispatch, getState) => {
    try {
        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ Uraian, Struktur })
        const Data = await axios.post(`${IpAddres}/account/Kerja/Kerja/${BioId}/update`, body, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `Job Berhasil diupdate`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'Update_Kerja_Kerja')
    }
}

//* PERTANYAAN

export const get_Pertanyaan_Data = (AccountId) => async (dispatch, getState) => {
    try {
        dispatch({ type: ACCOUNT_PERTANYAAN_LOADING })

        const IpAddres = Get_IpAddres()
        const Data = await axios.get(`${IpAddres}/account/pertanyaan/${AccountId}`, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({
                type: ACCOUNT_PERTANYAAN_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: ACCOUNT_PERTANYAAN_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: ACCOUNT_PERTANYAAN_LOADING_DONE })
        ErrorHandler(err, 'get_Pertanyaan_Data')
    }
}

//* RIWAYAT

export const get_Riwayat_Data = (AccountId) => async (dispatch, getState) => {
    try {
        dispatch({ type: ACCOUNT_RIWAYAT_LOADING })

        const IpAddres = Get_IpAddres()
        const Data = await axios.get(`${IpAddres}/account/riwayat/${AccountId}`, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({
                type: ACCOUNT_RIWAYAT_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: ACCOUNT_RIWAYAT_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: ACCOUNT_RIWAYAT_LOADING_DONE })
        ErrorHandler(err, 'get_Riwayat_Data')
    }
}

//* CREATEACCOUNT

export const CreateAnAccount = (Email, UserName, Password, isOneStep) => async (dispatch) => {
    try {
        dispatch({ type: ACCOUNT_CREATEACCOUNT_LOADING })
        dispatch({
            type: ACCOUNT_CREATEACCOUNT_ERROR_EMAIL_USED,
            payload: false
        })
        dispatch({
            type: ACCOUNT_CREATEACCOUNT_ERROR_USERNAME_USED,
            payload: false
        })

        if (!Email || !UserName || !Password) {
            const ErrorResponse = Error_Messages_Standardization(400, 'Form Tidak Lengkap')
            dispatch(Create_Error_Messages(400, 'Form Tidak Lengkap'))
            throw ErrorResponse
        }

        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ Email, UserName, Password })
        const UserData = await axios.post(`${IpAddres}/auth/create`, body, defaultHeader())
        if (UserData.status === 200) {

            if (isOneStep) {
                dispatch(Create_Success_Messages(200, `Akun ${UserData.data.UserName} Berhasil dibuat`))
                const newToken = await dispatch(LogIn(UserName, Password, true))
                dispatch(Load_User(newToken))
                dispatch({ type: ONESTEP_CREATE_ACCOUNT })
            } else {
                dispatch({ type: ACCOUNT_CREATEACCOUNT_LOADED })
                dispatch(Create_Success_Messages(200, `Akun ${UserData.data.UserName} Berhasil dibuat`))

                window.location.href = '/login'
            }
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }

    } catch (err) {
        dispatch({ type: ACCOUNT_CREATEACCOUNT_LOADED })
        ErrorHandler(err, 'CreateAnAccount')
        try {
            if (err.response.data.msg && err.response.data.msg === 'Email tidak bisa digunakan') {
                dispatch({
                    type: ACCOUNT_CREATEACCOUNT_ERROR_EMAIL_USED,
                    payload: true
                })
            } else if (err.response.data.msg && err.response.data.msg === 'UserName tidak bisa digunakan') {
                dispatch({
                    type: ACCOUNT_CREATEACCOUNT_ERROR_USERNAME_USED,
                    payload: true
                })
            }
        } catch (err) {
        }
    }
}