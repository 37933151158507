import { combineReducers } from 'redux'

import Auth from './Auth.Reducer'
import Account from './Account.Reducer'
import Job from './Job.Reducer'
import Messages from './Messages.Reducer'
import Setting from './Setting.Reducer'
import MPP from './MPP.Reducer'
import PR from './PR.Reducer'
import Jobdesk from './Jobdesk.Reducer'
import Report from './Report.Reducer'

const RootReducer = combineReducers({
    Auth,
    Account,
    Job,
    Messages,
    Setting,
    MPP,
    PR,
    Jobdesk,
    Report,
})

export default RootReducer