import React, { Fragment } from "react"

import { connect } from 'react-redux'

import { Create_Warning_Messages, Create_Info_Messages } from '../../../../../../Store/Actions/Messages.Actions'
import { get_MPP_Data } from '../../../../../../Store/Actions/MPP.Action'
import { get_Jobdesk_Data_From_MPPList, get_Jobdesk_Master_User } from '../../../../../../Store/Actions/Jobdesk.Action'
import { Add_PR } from '../../../../../../Store/Actions/PR.Action'
import { Load_Lokasi_List } from '../../../../../../Store/Actions/Setting.Action'

import { VariableLimit } from "../../../../../../Global"

// import { Skeleton, TextField, FormControl, InputLabel, Select, Switch, FormGroup, FormControlLabel, Checkbox, Button, CircularProgress } from '@mui/material'
import { Skeleton, TextField, FormControl, InputLabel, Select, FormGroup, FormControlLabel, Checkbox, Button, CircularProgress, Autocomplete, MenuItem } from '@mui/material'
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'

// import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
// import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'

import CoTable from "../../../../../Containers/Table"

class PRForm extends React.Component {
    state = {
        // Level: '',
        CostCenter: '',
        Melapor: '',
        ContractDuration: '',
        AtasanLangsung: null,
        ExpectedWeek: '',
        // TipePegawai: '',
        Bawahan: '',
        Dibutuhkan: '',
        Lokasi: '',
        // BaruOrMenggantikan: '',
        ReplacementName: '',
        // PeralatanDetail: [],
        UraianPosisi: '',
        UsiaMin: '',
        UsiaMax: '',
        JenisKelamin: '',
        Pendidikan: '',
        Jurusan: '',
        Bahasa: '',
        Pengalaman: '',
        Catatan: '',
        Reason: '',
        ReasonText: false,
        Reason2: '',
        ExpectedMonth: new Date().getMonth(),
        // Posisi: '',
        // CostCenterPemohon: '',
        // Diiklankan: false,
        isConfirm: false,
        Skill: [
            {
                Name: ''
            },
            {
                Name: ''
            },
            {
                Name: ''
            },
        ]
    }
    componentDidMount() {
        this.props.get_MPP_Data(this.props.MPPSelectId)
        this.props.Load_Lokasi_List()
        this.props.get_Jobdesk_Master_User()

        const MPPListId = this.props.MPPDetail.MPPListId
        if (MPPListId) {
            this.props.get_Jobdesk_Data_From_MPPList(MPPListId)
        }
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onChangeExpectedMonth = (e) => {
        this.setState({ ExpectedMonth: e.target.value });
    };

    onChangeExpectedWeek = (e) => {
        const inputValue = e.target.value;

        if (inputValue === '' || /^[1-4]$/.test(inputValue)) {
            this.setState({ ExpectedWeek: inputValue });
        } else {
            this.props.Create_Warning_Messages(null, `tidak bisa kurang dari 1 dan melebihi 4`);
        }
    };

    setAtasanLangsung = (NewData) => {
        this.setState({ AtasanLangsung: NewData })
    }

    // AddPeralatanDetail = (ItemLen) => {
    //     const PeralatanDetail = this.state.PeralatanDetail
    //     if (PeralatanDetail.length === 0 || (PeralatanDetail[PeralatanDetail.length - 1].Item && PeralatanDetail[PeralatanDetail.length - 1].Jumlah)) {
    //         if (PeralatanDetail.length < ItemLen) {
    //             const newPeralatanDetail = { Item: '', Jumlah: '' }
    //             PeralatanDetail.push(newPeralatanDetail)
    //             this.setState({ PeralatanDetail: PeralatanDetail })
    //         } else {
    //             this.props.Create_Warning_Messages(null, 'data melebihi jumlah Item MPP yang diajukan')
    //         }
    //     } else {
    //         this.props.Create_Warning_Messages(null, 'mohon lengkapi proses pengisian detail terlebih dahulu')
    //     }
    // }

    // OnChangeMapItem = (e, Index, Order) => {
    //     const PeralatanDetail = this.state.PeralatanDetail
    //     if (Order === 1) {
    //         const isInList = PeralatanDetail.find(item => item.Item === e.target.value)
    //         if (isInList) {
    //             this.props.Create_Warning_Messages(null, `item sudah dimasukkan`)
    //         }
    //         PeralatanDetail[Index].Item = e.target.value
    //         PeralatanDetail[Index].Jumlah = ''
    //     } else if (Order === 2) {
    //         PeralatanDetail[Index].Jumlah = e.target.value
    //     } else {

    //     }
    //     this.setState({ PeralatanDetail: PeralatanDetail })
    // }

    onChangeFieldMapMinMax = (e, Index, Min, MaxData) => {
        const Max = MaxData.Jumlah - (MaxData.JumlahSelesai + MaxData.JumlahTertahan)
        if (Number(e.target.value) >= Min && Number(e.target.value) <= Max) {
            this.OnChangeMapItem(e, Index, 2)
        } else {
            this.props.Create_Warning_Messages(null, `tidak bisa kurang dari ${Min} dan melebih ${Max}`)
        }
    }

    onChangeFieldMinMax = (e, Min, Max) => {
        const enteredValue = Number(e.target.value);

        if ((enteredValue >= Min && enteredValue <= Max) || e.target.value === "") {
            this.setState({ [e.target.name]: e.target.value });

            if (enteredValue > Max) {
                this.props.Create_Warning_Messages(null, `tidak bisa melebihi ${Max}`);
            } else {
            }

            if (enteredValue < Max) {
                this.setState({ ReasonText: true });
                this.props.Create_Info_Messages(null, `Mohon masukkan alasan anda tidak memasukkan angka maksimum (${Max}) untuk pembukaan PR ini`);
            } else if (enteredValue === Max) {
                this.setState({ ReasonText: false });
            } else {
                this.setState({ ReasonText: false });
            }
        } else {
            this.props.Create_Warning_Messages(null, `tidak bisa kurang dari ${Min} dan melebihi ${Max}`);
        }
    }

    // DeletePeralatanDetail = (Index) => {
    //     const PeralatanDetail = this.state.PeralatanDetail
    //     PeralatanDetail.splice(Index, 1)
    //     this.setState({ PeralatanDetail: PeralatanDetail })
    // }

    onChangeSwitch = (e) => {
        this.setState({ Diiklankan: !this.state.Diiklankan })
    }

    OnChangeCheckbox = E => {
        this.setState({ [E.target.name]: !this.state[E.target.name] })
    }

    AddDetailList = () => {
        const Skill = this.state.Skill

        if (Skill.length === 0 || Skill[Skill.length - 1].Name) {
            const newSkill = { Name: '' }
            Skill.push(newSkill)
            this.setState({ Skill: Skill })
        } else {
            this.props.Create_Warning_Messages(null, 'mohon lengkapi proses pengisian terlebih dahulu')
        }
    }

    DeleteDetailList = (Index) => {
        const Skill = this.state.Skill
        if (Skill.length > 3) {
            if (Index > 2) {
                Skill.splice(Index, 1)
                this.setState({ Skill: Skill })
            } else {
                this.props.Create_Warning_Messages(null, 'point 1-3 tidak bisa dihapus')
            }
        } else {
            this.props.Create_Warning_Messages(null, 'Minimal 3')
        }
    }

    OnChangeMapItem = (e, Index, Order) => {
        const Skill = this.state.Skill
        if (Order === 1) {
            Skill[Index].Name = e.target.value
        } else {

        }
        this.setState({ Skill: Skill })
    }

    formOnSubmit = (e) => {
        e.preventDefault()
        const MPPData = this.props.MPPData
        const MPPJenis = MPPData?.Jenis ? MPPData.Jenis : ''
        const MPPDetail = this.props.MPPDetail
        const JobdeskData = this.props.JobdeskData
        const User = this.props.User

        const Items = []

        const MPPList = this.props.MPPList
        const LsMPPList = MPPList.filter(M => M.Id === MPPDetail.MPPListId)
        const UsMPPList = LsMPPList[0]
        const OpMPPListItem = UsMPPList ? UsMPPList.Items : []

        for (const i of OpMPPListItem) {
            const ItemId = i.Item
            const newItem = { Item: ItemId, Jumlah: this.state.Dibutuhkan }
            Items.push(newItem)
        }

        this.props.Add_PR(
            MPPData.Id,
            MPPDetail.MPPListId,
            `${MPPData.LevNama} ${MPPData.Sub}`,
            MPPData.LevelId,
            this.state.CostCenter,
            this.state.AtasanLangsung ? typeof this.state.AtasanLangsung === 'object' ? this.state.AtasanLangsung.Value : this.state.AtasanLangsung : '',
            // JobdeskData.AtasanLangsung,
            MPPDetail.StatusKaryawan,
            this.state.ContractDuration,
            JobdeskData.JumlahBawahanLangsung,
            this.state.Dibutuhkan,
            this.state.Lokasi,
            MPPJenis === VariableLimit.MPPJenis.Replacement ? false : true, // this.state.BaruOrMenggantikan,
            this.state.ReplacementName,
            JobdeskData.UraianTugas,
            JobdeskData.UsiaMin,
            JobdeskData.UsiaMax,
            this.state.JenisKelamin,
            JobdeskData.Pendidikan,
            JobdeskData.Jurusan,
            this.state.Bahasa,
            this.state.Pengalaman,
            this.state.Catatan,
            User.Id,
            User.Level,
            User.Department,
            Items,
            this.state.Skill,
            this.state.ReasonText ? this.state.Reason : "",
            this.state.Reason2,
            this.state.ExpectedMonth ? this.state.ExpectedMonth : MPPList[0]?.BulanBuka,
            this.state.ExpectedWeek
        )
    }

    render() {
        // const isJobdeskActionGetDataLoading = this.props.isJobdeskActionGetDataLoading
        const JobdeskData = this.props.JobdeskData

        const isMPPActionGetDataLoading = this.props.isMPPActionGetDataLoading
        const MPPData = this.props.MPPData
        // console.log(MPPData);
        const MPPJenis = MPPData?.Jenis ? MPPData.Jenis : ''
        const MPPList = this.props.MPPList
        // console.log(MPPList);
        // const MPPListItem = this.props.MPPListItem
        const MPPDetail = this.props.MPPDetail
        const isPRAddLoading = this.props.isPRAddLoading
        const isLokasiLoading = this.props.isLokasiLoading

        const LsMPPList = MPPList.filter(M => M.Id === MPPDetail.MPPListId)
        const UsMPPList = LsMPPList[0]
        const OpMPPListItem = UsMPPList ? UsMPPList.Items : []

        const OpLokasiList = this.props.LokasiList
        // console.log(OpLokasiList);
        const isJobdeskGetMasterUserLoading = this.props.isJobdeskGetMasterUserLoading
        const JobdeskGetMasterUser = this.props.JobdeskGetMasterUser

        // const OpBaruOrMenggantikan = [
        //     {
        //         Option: 'Baru',
        //         value: 1
        //     },
        //     {
        //         Option: 'Menggantikan',
        //         value: 0
        //     },
        // ]

        const OpJenisKelamin = ['Male/Female', 'Male', 'Female']

        // const OpPendidikan = [
        //     {
        //         Option: 'SMA',
        //         value: 'SMA',
        //     },
        //     {
        //         Option: 'D3',
        //         value: 'D3',
        //     },
        //     {
        //         Option: 'D4',
        //         value: 'D4',
        //     },
        //     {
        //         Option: 'S1',
        //         value: 'S1'
        //     },
        //     {
        //         Option: 'S2',
        //         value: 'S2'
        //     },
        //     {
        //         Option: 'S3',
        //         value: 'S3'
        //     },
        // ]

        // const OpMPPListItem = MPPListItem ?
        //     MPPListItem.filter((item) =>
        //         item.BulanBuka === MPPDetail.BulanBuka
        //     )
        //     : []

        const {
            // Level,
            // CostCenter,
            // Melapor,
            ContractDuration,
            AtasanLangsung,
            // TipePegawai,
            // Bawahan,
            Dibutuhkan,
            Lokasi,
            // BaruOrMenggantikan,
            ReplacementName,
            // PeralatanDetail,
            // UraianPosisi,
            // UsiaMin,
            // UsiaMax,
            JenisKelamin,
            // Pendidikan,
            // Jurusan,
            Bahasa,
            Pengalaman,
            Catatan,
            // Posisi,
            // CostCenterPemohon,
            // Diiklankan,
            isConfirm,
            Skill,
            ExpectedWeek,
            Reason,
            ReasonText,
            Reason2,
            ExpectedMonth,
        } = this.state
        const st_icon = { color: 'grey', fontSize: '180%', margin: '0 0 0 1%', cursor: 'pointer' }

        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Skill',
                width: '35%',
            },
            {
                Nama: 'Add',
                width: '10%',
            },
            {
                Nama: 'Delete',
                width: '10%',
            },
        ]

        const BodyData = Skill.map((item, index) => {
            return {
                index: index + 1,
                Pertanyaan: (
                    <TextField
                        value={Skill[index].Name}
                        style={{ width: '90%' }}
                        required
                        label={`Skill ${index + 1}`}
                        onChange={(e) => this.OnChangeMapItem(e, index, 1)}
                    />
                ),
                Tambah: (
                    <AddCircleRoundedIcon
                        onClick={this.AddDetailList}
                        style={st_icon}
                    />
                ),
                Hapus: (
                    <DeleteOutlineRoundedIcon
                        onClick={(e) => this.DeleteDetailList(index)}
                        style={st_icon}
                    />
                ),
            }
        })


        const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }
        const st_textfield_disable = { marginTop: '10px', marginBottom: '10px', width: '100%', backgroundColor: '#E0E0E0' }
        const st_Half = { width: '45%' }
        // const st_icon = { color: 'grey', fontSize: '180%', margin: '0 0 0 1%', cursor: 'pointer' }
        const st_divaddtextfield = { display: 'flex', alignItems: 'center', margin: '0 0 2% 2%' }
        // const st_TextSmall = { width: '20%', margin: '0 1% 0 0' }
        const st_TextSmall_disable = { width: '20%', margin: '0 1% 0 0', backgroundColor: '#E0E0E0' }


        return (
            <Fragment>
                {isMPPActionGetDataLoading || isLokasiLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5, 6, 7].map((item) =>
                            <Skeleton
                                height={'8vh'}
                                key={`PRForm_Skeleton_${item}`}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <form
                            onSubmit={this.formOnSubmit}
                        >
                            <h3>
                                Position Information
                            </h3>
                            <TextField
                                style={st_textfield_disable}
                                variant='outlined'
                                type='text'
                                label='Position Title'
                                name='Posisi'
                                // required
                                disabled
                                value={MPPData ? `${MPPData.DepNama} - ${MPPData.LevNama} ${MPPData.Sub}` : ''}
                            // onChange={this.onChangeField}
                            />
                            <TextField
                                style={{ ...st_textfield_disable }}
                                variant='outlined'
                                type='text'
                                label='Level'
                                name='Level'
                                // required
                                disabled
                                value={MPPData ? MPPData.LevNama : ''}
                            // onChange={this.onChangeField}
                            />
                            {/* <TextField
                                style={{ ...st_textfield_disable, ...st_Half, marginLeft: '5%' }}
                                variant='outlined'
                                type='text'
                                label='Cost Center'
                                name='CostCenter'
                                // required
                                disabled
                                value={CostCenter}
                            // onChange={this.onChangeField}
                            /> */}
                            {/* <TextField
                                style={st_textfield_disable}
                                variant='outlined'
                                type='text'
                                label='Reporting To'
                                name='AtasanLangsungNama'
                                disabled
                                value={JobdeskData?.AtasanLangsungNama ? JobdeskData.AtasanLangsungNama : ''}
                            // onChange={this.onChangeField}
                            /> */}
                            <Autocomplete
                                value={AtasanLangsung}
                                onChange={(event, newParticipan) => {
                                    this.setAtasanLangsung(newParticipan ? newParticipan : null)
                                }}

                                options={JobdeskGetMasterUser}
                                // getOptionLabel={option => option.Name}
                                getOptionLabel={option => typeof option === 'string' ? option : option.Name}
                                style={st_textfield}
                                disabled={isJobdeskGetMasterUserLoading ? true : false}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        value={AtasanLangsung ? AtasanLangsung.Value : ''}
                                        name='Participan'
                                        label={isJobdeskGetMasterUserLoading ? <CircularProgress /> : 'Direct Supervisor'}
                                        variant="outlined"
                                        disabled={isJobdeskGetMasterUserLoading ? true : false}
                                        {...params}
                                    />
                                )}
                            />
                            <TextField
                                style={st_textfield_disable}
                                variant='outlined'
                                type='text'
                                label='Employee Type'
                                name='TipePegawai'
                                // required
                                disabled
                                value={MPPDetail ? MPPDetail.StatusKaryawan : ''}
                            // onChange={this.onChangeField}
                            />
                            {MPPDetail?.StatusKaryawan ? MPPDetail?.StatusKaryawan === VariableLimit.MPPType.Contract ?
                                < TextField
                                    style={st_textfield}
                                    variant='outlined'
                                    type='number'
                                    label='Contract Duration in Month'
                                    name='ContractDuration'
                                    required
                                    value={ContractDuration}
                                    onChange={this.onChangeField}
                                /> : <Fragment></Fragment>
                                : <Fragment></Fragment>
                            }
                            <TextField
                                style={{ ...st_textfield_disable, ...st_Half }}
                                variant='outlined'
                                type='number'
                                label='No of Subordinate'
                                name='Bawahan'
                                disabled
                                value={JobdeskData?.JumlahBawahanLangsung ? JobdeskData.JumlahBawahanLangsung : ''}
                            // onChange={this.onChangeField}
                            />
                            <TextField
                                style={{ ...st_textfield, ...st_Half, marginLeft: '5%' }}
                                variant='outlined'
                                type='number'
                                label='No of open position '
                                name='Dibutuhkan'
                                required
                                // disabled
                                value={Dibutuhkan}
                                onChange={(e) => this.onChangeFieldMinMax(e, 1, MPPDetail.Jumlah - (MPPDetail.JumlahSelesai + MPPDetail.JumlahTertahan))}
                            />
                            {ReasonText &&
                                <TextField
                                    style={st_textfield}
                                    variant='outlined'
                                    type='text'
                                    label='Reason (Why open position not max)'
                                    name='Reason'
                                    required
                                    // disabled
                                    value={Reason}
                                    onChange={this.onChangeField}
                                />
                            }

                            {MPPList[0]?.BulanBuka === new Date().getMonth() ?
                                <>
                                    <TextField
                                        style={{ ...st_textfield_disable, ...st_Half }}
                                        variant='outlined'
                                        type='text'
                                        label='Expected Join (Month)'
                                        name='Join Month'
                                        disabled
                                        // value={MPPList[0].BulanBuka}
                                        value={
                                            MPPList[0]?.BulanBuka !== undefined
                                                ? VariableLimit.Month[MPPList[0].BulanBuka]?.display || ''
                                                : ''
                                        }
                                    // onChange={this.onChangeField}
                                    />
                                    <TextField
                                        style={{ ...st_textfield, ...st_Half, marginLeft: '5%' }}
                                        variant='outlined'
                                        type='number'
                                        label='Expected Join (Week)'
                                        name='ExpectedWeek'
                                        required
                                        // disabled
                                        value={ExpectedWeek}
                                        onChange={(e) => this.onChangeExpectedWeek(e)}
                                    />
                                </> :
                                <>
                                    <TextField
                                        style={st_textfield}
                                        variant='outlined'
                                        type='text'
                                        label={`Reason (Why make PR not in Expected Month ${MPPList[0]?.BulanBuka !== undefined
                                            ? VariableLimit.Month[MPPList[0].BulanBuka]?.display || ''
                                            : ''})`}
                                        name='Reason2'
                                        required
                                        // disabled
                                        value={Reason2}
                                        onChange={this.onChangeField}
                                    />
                                    <TextField
                                        style={{ ...st_textfield, ...st_Half }}
                                        variant='outlined'
                                        select
                                        label='Expected Join (Month)'
                                        name='ExpectedMonth'
                                        required
                                        value={ExpectedMonth}
                                        onChange={this.onChangeExpectedMonth}
                                    >
                                        {VariableLimit.Month.map((month) => (
                                            <MenuItem key={month.value} value={month.value}>
                                                {month.display}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        style={{ ...st_textfield, ...st_Half, marginLeft: '5%' }}
                                        variant='outlined'
                                        type='number'
                                        label='Expected Join (Week)'
                                        name='ExpectedWeek'
                                        required
                                        // disabled
                                        value={ExpectedWeek}
                                        onChange={(e) => this.onChangeExpectedWeek(e)}
                                    />
                                </>
                            }
                            <FormControl
                                style={MPPJenis === VariableLimit.MPPJenis.Replacement ? { ...st_textfield, ...st_Half } : { ...st_textfield }}
                                // style={true ? { ...st_textfield, ...st_Half } : { ...st_textfield }}
                                variant="filled"
                                required
                            >
                                <InputLabel
                                    shrink
                                >
                                    Work Location
                                </InputLabel>
                                <Select
                                    native
                                    style={{ backgroundColor: 'white' }}
                                    onChange={(e) => this.onChangeField(e)}
                                    label="Work Location"
                                    name='Lokasi'
                                    value={Lokasi}
                                >
                                    <option value="" disabled> -- select an option -- </option>
                                    {OpLokasiList.map((option, index) =>
                                        <option key={`OpLokasiList${index}`} value={option.Id}>{option.Nama}</option>
                                    )}
                                </Select>
                            </FormControl>
                            {/* <FormControl
                                style={{ ...st_textfield, ...st_Half, marginLeft: '5%' }}
                                variant="filled"
                                required
                            >
                                <InputLabel
                                    shrink
                                >
                                    Is this a new position or replacement?
                                </InputLabel>
                                <Select
                                    native
                                    style={{ backgroundColor: 'white' }}
                                    onChange={(e) => this.onChangeField(e)}
                                    label="Is this a new position or replacement?"
                                    name='BaruOrMenggantikan'
                                    value={BaruOrMenggantikan}
                                >
                                    <option value="" disabled> -- select an option -- </option>
                                    {OpBaruOrMenggantikan.map((option, index) =>
                                        <option key={`BaruOrMenggantikan_${index}`} value={option.value}>{option.Option}</option>
                                    )}
                                </Select>
                            </FormControl> */}

                            {MPPJenis === VariableLimit.MPPJenis.Replacement ?
                                // {true ?
                                < TextField
                                    style={{ ...st_textfield, ...st_Half, marginLeft: '5%' }}
                                    variant='outlined'
                                    type='text'
                                    label='Replacement Name'
                                    name='ReplacementName'
                                    required
                                    // disabled
                                    value={ReplacementName}
                                    onChange={this.onChangeField}
                                />
                                : <Fragment></Fragment>
                            }
                            <h3>
                                Work Equipment
                            </h3>
                            {!OpMPPListItem || OpMPPListItem.length < 1 ?
                                <center>
                                    <h3>No items submitted during MPP</h3>
                                </center> :
                                OpMPPListItem.map((item, index) => (
                                    <Fragment
                                        key={`OpMPPListItem_Item_${index}`}
                                    >
                                        <div
                                            style={st_divaddtextfield}
                                        >
                                            <span
                                                style={{ marginRight: '1%' }}
                                            >
                                                {`Item ${index + 1}`}
                                            </span>
                                            <TextField
                                                style={st_TextSmall_disable}
                                                variant='outlined'
                                                type='text'
                                                label='Opening Month'
                                                name='Waktu Pembukaan'
                                                value={item.BulanBuka || item.BulanBuka === 0 ? new Date(2022, item.BulanBuka, 1).toLocaleString('default', { month: 'long' }) : ''}
                                                disabled
                                            />
                                            <TextField
                                                style={st_TextSmall_disable}
                                                variant='outlined'
                                                type='text'
                                                label='Equipment'
                                                name='Kebutuhan'
                                                value={item.ItemsNama ? item.ItemsNama : ''}
                                                disabled
                                            />
                                            <TextField
                                                style={st_TextSmall_disable}
                                                variant='outlined'
                                                type='number'
                                                label='Quantity'
                                                name='Jumlah'
                                                value={Dibutuhkan}
                                                disabled
                                            />
                                        </div>
                                    </Fragment>
                                ))}
                            {/* {PeralatanDetail.length === 0 ?
                                <AddCircleRoundedIcon
                                    onClick={() => this.AddPeralatanDetail(OpMPPListItem.length)}
                                    style={st_icon}
                                /> :
                                <Fragment>
                                </Fragment>} */}
                            {/* {PeralatanDetail.map((item, index) =>
                                <div
                                    style={st_divaddtextfield}
                                    key={`PeralatanDetail${index}`}
                                >
                                    <FormControl
                                        style={st_TextSmall}
                                        variant="filled"
                                        required
                                    >
                                        <InputLabel
                                            shrink
                                        >
                                            Item List
                                        </InputLabel>
                                        <Select
                                            native
                                            style={{ backgroundColor: 'white' }}
                                            onChange={(e) => this.OnChangeMapItem(e, index, 1)}
                                            label="Item"
                                            name='Item'
                                            value={PeralatanDetail[index].Item}
                                        >
                                            <option value="" disabled> -- select an option -- </option>
                                            {OpMPPListItem.map((option, index) =>
                                                <option key={`OpMPPListItem_${index}`} value={option.Id} >{option.ItemsNama}</option>
                                            )}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        style={st_TextSmall_disable}
                                        variant='outlined'
                                        type='text'
                                        label='Bulan'
                                        name='Bulan'
                                        disabled
                                        value={PeralatanDetail[index].Item ? new Date(2022, MPPDetail.BulanBuka, 1).toLocaleString('default', { month: 'long' }) : 'Bulan'}
                                    />
                                    <TextField
                                        style={PeralatanDetail[index].Item ? st_TextSmall : st_TextSmall_disable}
                                        variant='outlined'
                                        type='number'
                                        label='Jumlah'
                                        name='Jumlah'
                                        required={PeralatanDetail[index].Item ? true : false}
                                        disabled={PeralatanDetail[index].Item ? false : true}
                                        value={PeralatanDetail[index].Jumlah}
                                        // onChange={(e) => this.onChangeFieldMinMax(e, index, 0, OpMPPListItem.find(item => item.Id === PeralatanDetail[index].Item).Jumlah)}
                                        onChange={(e) => this.onChangeFieldMapMinMax(e, index, 0, OpMPPListItem.find(item => item.Id === Number(PeralatanDetail[index].Item)))}
                                    />
                                    <DeleteOutlineRoundedIcon
                                        onClick={(e) => this.DeletePeralatanDetail(index)}
                                        style={st_icon}
                                    />
                                    <AddCircleRoundedIcon
                                        onClick={() => this.AddPeralatanDetail(OpMPPListItem.length)}
                                        style={st_icon}
                                    />
                                </div>
                            )} */}
                            <h3>
                                Skill Requirement (HR benchmark for applicant's recommendation, asked at registration)
                            </h3>
                            <CoTable
                                TableId={'PR_SKill'}
                                HeadData={HeadData}
                                Data={BodyData}
                            // isHeadLoading={isPertanyaanLoading}
                            // isBodyLoading={isPertanyaanLoading}
                            />
                            <h3>
                                Job Description
                            </h3>
                            <TextField
                                style={st_textfield_disable}
                                variant='outlined'
                                type='text'
                                multiline
                                rows={8}
                                label='Job Description'
                                name='UraianPosisi'
                                // required
                                disabled
                                value={JobdeskData?.UraianTugas ? JobdeskData.UraianTugas : ''}
                            // onChange={this.onChangeField}
                            />
                            <h3>
                                Position Requirements
                            </h3>
                            <TextField
                                style={{ ...st_textfield_disable, ...st_Half }}
                                variant='outlined'
                                type='number'
                                label='Age Min'
                                name='UsiaMin'
                                // required
                                disabled
                                value={JobdeskData?.UsiaMin ? JobdeskData.UsiaMin : ''}
                            // onChange={this.onChangeField}
                            />
                            <TextField
                                style={{ ...st_textfield_disable, ...st_Half, marginLeft: '5%' }}
                                variant='outlined'
                                type='number'
                                label='Age Max'
                                name='UsiaMax'
                                // required
                                disabled
                                value={JobdeskData?.UsiaMax ? JobdeskData.UsiaMax : ''}
                            // onChange={this.onChangeField}
                            />
                            <FormControl
                                style={st_textfield}
                                variant="filled"
                                required
                            >
                                <InputLabel
                                    shrink
                                >
                                    Gender
                                </InputLabel>
                                <Select
                                    native
                                    style={{ backgroundColor: 'white' }}
                                    onChange={(e) => this.onChangeField(e)}
                                    label="Gender"
                                    name='JenisKelamin'
                                    value={JenisKelamin}
                                >
                                    <option value="" disabled> -- select an option -- </option>
                                    {OpJenisKelamin.map((option, index) =>
                                        <option key={`JenisKelamin_${index}`} value={option}>{option}</option>
                                    )}
                                </Select>
                            </FormControl>
                            {/* <FormControl
                                style={{ ...st_textfield_disable, ...st_Half }}
                                variant="filled"
                                required
                            >
                                <InputLabel
                                    shrink
                                >
                                    Pendidikan
                                </InputLabel>
                                <Select
                                    native
                                    // style={{ backgroundColor: 'white' }}
                                    onChange={(e) => this.onChangeField(e)}
                                    label="Pendidikan"
                                    name='Pendidikan'
                                    value={Pendidikan}
                                >
                                    <option value="" disabled> -- select an option -- </option>
                                    {OpPendidikan.map((option, index) =>
                                        <option key={`Pendidikan_${index}`} value={option.value}>{option.Option}</option>
                                    )}
                                </Select>
                            </FormControl> */}
                            <TextField
                                style={{ ...st_textfield_disable, ...st_Half, }}
                                variant='outlined'
                                type='text'
                                label='Education Qualification'
                                name='Pendidikan'
                                // required
                                disabled
                                value={JobdeskData?.Pendidikan ? JobdeskData.Pendidikan : ''}
                            // onChange={this.onChangeField}
                            />
                            <TextField
                                style={{ ...st_textfield_disable, ...st_Half, marginLeft: '5%' }}
                                variant='outlined'
                                type='text'
                                label='Major of Study'
                                name='Jurusan'
                                // required
                                disabled
                                value={JobdeskData?.Jurusan ? JobdeskData.Jurusan : ''}
                            // onChange={this.onChangeField}
                            />
                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Language Skills Besides Indonesian '
                                name='Bahasa'
                                // required
                                // disabled
                                value={Bahasa}
                                onChange={this.onChangeField}
                            />
                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='number'
                                label='Years of Experience'
                                name='Pengalaman'
                                required
                                // disabled
                                value={Pengalaman}
                                onChange={this.onChangeField}
                            />
                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                multiline
                                rows={3}
                                label='Notes'
                                name='Catatan'
                                // required
                                // disabled
                                value={Catatan}
                                onChange={this.onChangeField}
                            />
                            {/* <h3>
                                General Setting
                            </h3>
                            <label>
                                <b>
                                    Diiklankan?
                                </b>
                            </label><br /><br />
                            <label>
                                Tidak
                            </label>
                            <Switch
                                name='Diiklankan'
                                checked={Diiklankan}
                                onClick={this.onChangeSwitch}
                            />
                            <label>
                                Ya
                            </label> */}
                            <FormGroup row>
                                <FormControlLabel
                                    control={<Checkbox color='primary' onChange={this.OnChangeCheckbox} name="isConfirm" value={isConfirm} disabled={isPRAddLoading ? true : false} />}
                                    label="The data that I entered is correct"
                                />
                            </FormGroup>
                            <center>
                                <Button
                                    variant='contained'
                                    style={{ width: '90%', margin: '5% 0 5% 0' }}
                                    type='submit'
                                    disabled={isPRAddLoading || !isConfirm ? true : false}
                                >
                                    {isPRAddLoading ? <CircularProgress /> : 'Submit PR'}
                                </Button>
                            </center>
                        </form>
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isLokasiLoading: state.Setting.isLokasiLoading,
    LokasiList: state.Setting.LokasiList,
    User: state.Auth.User,
    isPRAddLoading: state.PR.isPRAddLoading,
    isMPPActionGetDataLoading: state.MPP.isMPPActionGetDataLoading,
    MPPData: state.MPP.MPPData,
    MPPList: state.MPP.MPPList,
    MPPListItem: state.MPP.MPPListItem,
    isJobdeskActionGetDataLoading: state.Jobdesk.isJobdeskActionGetDataLoading,
    JobdeskData: state.Jobdesk.JobdeskData,
    isJobdeskGetMasterUserLoading: state.Jobdesk.isJobdeskGetPertanyaanLoading,
    JobdeskGetMasterUser: state.Jobdesk.JobdeskGetMasterUser,
})

export default connect(mapStateToProps, { Create_Info_Messages, Create_Warning_Messages, get_MPP_Data, Add_PR, Load_Lokasi_List, get_Jobdesk_Data_From_MPPList, get_Jobdesk_Master_User })(PRForm)