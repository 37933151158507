import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Department_Data, Delete_Department } from '../../../../../../Store/Actions/Setting.Action'

import { FormGroup, FormControlLabel, Checkbox, Button, CircularProgress } from '@mui/material'

import Detail from '../../../../../Containers/Dashboard/Setting/Department/Detail'
import Data from '../../../../../Containers/Dashboard/Setting/Department/Data'

class SettingDepartmentDelete extends React.Component {
    state = {
        isConfirm: false,
    }
    componentDidUpdate(prevProps) {
        if (this.props.DepartmentActionId !== prevProps.DepartmentActionId) {
            this.props.get_Department_Data(this.props.DepartmentActionId)
        }
    }
    componentDidMount() {
        const { DepartmentActionId } = this.props
        if (DepartmentActionId !== null) {
            this.props.get_Department_Data(DepartmentActionId)
        }
    }

    OnChangeCheckbox = E => {
        this.setState({ [E.target.name]: !this.state[E.target.name] })
    }

    onClickDelete(Id) {
        this.props.Delete_Department(Id)
    }
    render() {
        const DepartmentActionId = this.props.DepartmentActionId
        const isDepartmentActionGetDetailLoading = this.props.isDepartmentActionGetDetailLoading
        const DepartmentDetail = this.props.DepartmentDetail
        const DepartmentData = this.props.DepartmentData
        const HeadData = [
            {
                Nama: 'Index',
                width: '5%',
            },
            {
                Nama: 'Id',
                width: '10%',
            },
            {
                Nama: 'UserName',
                width: '10%',
            },
            {
                Nama: 'Nama',
                width: '10%',
            },
            {
                Nama: 'Department',
                width: '10%',
            }
        ]
        const BodyData = DepartmentData ? DepartmentData.map((item, index) => {
            return {
                index: index + 1,
                Id: item.Id,
                UserName: item.UserName,
                Nama: item.BiodataNama,
                Department: item.DepartemenNama,
            }
        }) : []
        const {
            isConfirm
        } = this.state
        const isDepartmentDeleteLoading = this.props.isDepartmentDeleteLoading
        return (
            <Fragment>
                <Detail Data={DepartmentDetail} isLoading={isDepartmentActionGetDetailLoading} />
                <Data HeadData={HeadData} BodyData={BodyData} isLoading={isDepartmentActionGetDetailLoading} />
                <div
                    style={{ margin: '1%' }}
                >
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox color='primary' onChange={this.OnChangeCheckbox} name="isConfirm" value={isConfirm} disabled={isDepartmentDeleteLoading ? true : false} />}
                            label="Saya menyadari akan resikonya dan saya tau apa yang saya lakukan"
                        />
                    </FormGroup>
                    <center>
                        <Button
                            variant='contained'
                            size='large'
                            color='error'
                            disabled={!isConfirm || isDepartmentDeleteLoading ? true : false}
                            onClick={() => this.onClickDelete(DepartmentActionId)}
                        >
                            {isDepartmentDeleteLoading ? <CircularProgress /> : 'Delete'}
                        </Button>
                    </center>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isDepartmentActionGetDetailLoading: state.Setting.isDepartmentActionGetDetailLoading,
    isDepartmentDeleteLoading: state.Setting.isDepartmentDeleteLoading,
    DepartmentActionId: state.Setting.DepartmentActionId,
    DepartmentDetail: state.Setting.DepartmentDetail,
    DepartmentData: state.Setting.DepartmentData,
})

export default connect(mapStateToProps, { get_Department_Data, Delete_Department })(SettingDepartmentDelete)
