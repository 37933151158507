import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Jobdesk_Data } from '../../../../../Store/Actions/Jobdesk.Action'

import CoDetailJobdesk from '../../../../Containers/Dashboard/Jobdesk/Detail'

class DetailJobdesk extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.JobdeskActionId !== prevProps.JobdeskActionId) {
            this.props.get_Jobdesk_Data(this.props.JobdeskActionId)
        }
    }
    componentDidMount() {
        const { JobdeskActionId } = this.props
        if (JobdeskActionId !== null) {
            this.props.get_Jobdesk_Data(JobdeskActionId)
        }
    }
    render() {
        const JobdeskData = this.props.JobdeskData
        const JobdeskListPrilaku = this.props.JobdeskListPrilaku
        const JobdeskListTeknik = this.props.JobdeskListTeknik
        const isJobdeskActionGetDataLoading = this.props.isJobdeskActionGetDataLoading
        return (
            <Fragment>
                <CoDetailJobdesk Data={JobdeskData} ListData={JobdeskListPrilaku} ListData2={JobdeskListTeknik} isLoading={isJobdeskActionGetDataLoading} />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    JobdeskActionId: state.Jobdesk.JobdeskActionId,
    isJobdeskActionGetDataLoading: state.Jobdesk.isJobdeskActionGetDataLoading,
    JobdeskData: state.Jobdesk.JobdeskData,
    JobdeskListPrilaku: state.Jobdesk.JobdeskListPrilaku,
    JobdeskListTeknik: state.Jobdesk.JobdeskListTeknik,
})

export default connect(mapStateToProps, { get_Jobdesk_Data })(DetailJobdesk)