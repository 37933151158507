import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_List_OnBoarding, get_Job_Action_Id } from '../../../../Store/Actions/Job.Action'
import { get_Account_Action_Id } from '../../../../Store/Actions/Account.Action'

import { Collapse } from '@mui/material'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import HowToRegIcon from '@mui/icons-material/HowToReg'
// import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded'

import GenericModals from '../../../Containers/GenericModals'
import CoTable from '../../../Containers/Table'
import NotificationWarning from './Modal/OnBoarding/OnBoarding'
import DetailOnBoarding from './Modal/OnBoarding/DetailOnBoarding'

class OnBoarding extends React.Component {
    state = {
        MenungguOpen: true,
        SelesaiOpen: false,
    }

    componentDidMount() {
        this.props.get_List_OnBoarding()
    }

    handleMenungguOpen = () => {
        this.setState({ MenungguOpen: !this.state.MenungguOpen })
    }

    handleSelesaiOpen = () => {
        this.setState({ SelesaiOpen: !this.state.SelesaiOpen })
    }

    onClickActionId = (JobId, AccountId) => {
        this.props.get_Job_Action_Id(JobId)
        this.props.get_Account_Action_Id(AccountId)
    }
    render() {
        const isListOnBoardingLoading = this.props.isListOnBoardingLoading
        const ListOnBoarding = this.props.ListOnBoarding

        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'No Registrasi',
                width: '10%',
            },
            {
                Nama: 'Nama',
                width: '10%',
            },
            {
                Nama: 'Posisi',
                width: '10%',
            },
            {
                Nama: 'Submit',
                width: '10%',
            },
        ]

        const FilteredList = ListOnBoarding ? ListOnBoarding.filter((item, index) => (
            item.OBAccId === null
        )) : []

        const BodyData = FilteredList.map((item, index) => {
            return {
                index: index + 1,
                Noreg: item.Id,
                Nama: item.BiodataNama,
                Posisi: item.JobListNama,
                Detail: (
                    <GenericModals
                        size='s'
                        Buttonvariant='contained'
                        header='On Boarding'
                        body={<NotificationWarning />}
                        Buttoncolor='primary'
                        ButtononClickeven={() => this.onClickActionId(item.JobListId, item.AccountId)}
                        Buttonlabel={<HowToRegIcon />}
                    />
                )
            }
        })

        const HeadData2 = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'No Registrasi',
                width: '10%',
            },
            {
                Nama: 'Nama',
                width: '10%',
            },
            {
                Nama: 'Posisi',
                width: '10%',
            },
            {
                Nama: 'Status',
                width: '10%',
            },
            {
                Nama: 'Submit',
                width: '10%',
            },
        ]

        const FilteredList2 = ListOnBoarding ? ListOnBoarding.filter((item, index) => (
            item.OBStatus !== null
        )) : []

        const BodyData2 = FilteredList2.map((item, index) => {
            return {
                index: index + 1,
                Noreg: item.Id,
                Nama: item.BiodataNama,
                Posisi: item.JobListNama,
                Status: (
                    <span
                        style={{ color: item.OBStatus ? item.OBStatus === 'Selesai' ? 'green' : 'orange' : 'black' }}
                    >
                        {item.OBStatus}
                    </span>
                ),
                Detail: (
                    <GenericModals
                        size='l'
                        header='Detail OnBoarding'
                        body={<DetailOnBoarding />}
                        Buttoncolor='primary'
                        ButtononClickeven={() => this.onClickActionId(item.JobListId, item.AccountId)}
                        Buttonlabel={'Detail'}
                    />
                )
            }
        })

        const {
            MenungguOpen,
            SelesaiOpen,
        } = this.state

        const RowDataInOnePage = 5
        return (
            <Fragment>
                <h2>
                    OnBoarding
                </h2>
                <h3
                    onClick={this.handleMenungguOpen}
                    style={{ display: 'flex', alignContent: 'center', verticalAlign: 'center', cursor: 'pointer' }}
                >
                    Upcoming{MenungguOpen ? <ExpandLess /> : <ExpandMore />}
                </h3>
                <Collapse in={MenungguOpen} timeout="auto" unmountOnExit>
                    <CoTable
                        TableId={'OnBoarding_Upcoming'}
                        HeadData={HeadData}
                        Data={BodyData}
                        isHeadLoading={isListOnBoardingLoading}
                        isBodyLoading={isListOnBoardingLoading}
                        isPagination={BodyData.length > RowDataInOnePage ? true : false}
                        MaxRowOnPage={RowDataInOnePage}
                        isPaginationLoading={isListOnBoardingLoading}
                        PaginationCounter={
                            BodyData.length / RowDataInOnePage > Math.floor(BodyData.length / RowDataInOnePage) ?
                                Math.floor(BodyData.length / RowDataInOnePage) + 1 :
                                BodyData.length / RowDataInOnePage
                        }
                    />
                </Collapse >
                <div
                    style={{ margin: '0 0 5% 0' }}
                >
                    <h3
                        onClick={this.handleSelesaiOpen}
                        style={{ display: 'flex', alignContent: 'center', verticalAlign: 'center', cursor: 'pointer' }}
                    >
                        Join{SelesaiOpen ? <ExpandLess /> : <ExpandMore />}
                    </h3>
                    <Collapse in={SelesaiOpen} timeout="auto" unmountOnExit>
                        <CoTable
                            TableId={'OnBoarding_Selesai'}
                            HeadData={HeadData2}
                            Data={BodyData2}
                            isHeadLoading={isListOnBoardingLoading}
                            isBodyLoading={isListOnBoardingLoading}
                            isPagination={BodyData2.length > RowDataInOnePage ? true : false}
                            MaxRowOnPage={RowDataInOnePage}
                            isPaginationLoading={isListOnBoardingLoading}
                            PaginationCounter={
                                BodyData2.length / RowDataInOnePage > Math.floor(BodyData2.length / RowDataInOnePage) ?
                                    Math.floor(BodyData2.length / RowDataInOnePage) + 1 :
                                    BodyData2.length / RowDataInOnePage
                            }
                        />
                    </Collapse>
                </div>
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    isListOnBoardingLoading: state.Job.isListOnBoardingLoading,
    ListOnBoarding: state.Job.ListOnBoarding,
})

export default connect(mapStateToProps, { get_List_OnBoarding, get_Job_Action_Id, get_Account_Action_Id })(OnBoarding)