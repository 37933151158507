import React, { Fragment } from "react"

import { connect } from 'react-redux'

import { get_List_Pendaftar_Detail } from '../../../../../../../../Store/Actions/Job.Action'

import { Skeleton } from "@mui/material"

import Profile from '../../../../../../Account/Profile'

class DetailRecom extends React.Component {
    componentDidMount() {
        const { JobActionId, AccountActionId } = this.props
        this.props.get_List_Pendaftar_Detail(JobActionId, AccountActionId)
    }
    render() {
        const isListPendaftarDetailLoading = this.props.isListPendaftarDetailLoading
        const ListPendaftarDetail = this.props.ListPendaftarDetail

        return (
            <Fragment>
                {isListPendaftarDetailLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5,].map((item) =>
                            <Skeleton
                                key={`RecomDiajukan_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <div
                            style={{ margin: '0 0 5% 0' }}
                        >
                            <div
                                style={{ margin: '0 0 3% 0', borderBottom: '1px solid black' }}
                            >
                                <h2
                                    style={{ borderBottom: '1px solid black' }}
                                >
                                    Status Pengurusan
                                </h2>
                                <h3>
                                    HR:
                                </h3>
                                <center>
                                    <h2
                                        style={{ margin: '0 0 0 0', padding: '1%', color: 'green', border: '1px solid blue' }}
                                    >
                                        Status:  {ListPendaftarDetail.AccReferralStatus ? ListPendaftarDetail.AccReferralStatus : ''}
                                    </h2>
                                </center>
                                {/* {JSON.stringify(ListPendaftarDetail)} */}
                                <p>Oleh: {ListPendaftarDetail.BiodataNama ? ListPendaftarDetail.BiodataNama : ''}</p>
                                <p>Tanggal: {ListPendaftarDetail.AccReferralTime ? new Date(ListPendaftarDetail.AccReferralTime).toLocaleString(['ban', 'id']) : ''}</p>
                                <p>Keterangan: {ListPendaftarDetail.AccReferralKet ? ListPendaftarDetail.AccReferralKet : ''}</p>
                                <h3>
                                    Departemen:
                                </h3>
                                <center>
                                    <h2
                                        style={{ margin: '0 0 0 0', padding: '1%', color: ListPendaftarDetail.AccDepStatus ? ListPendaftarDetail.AccDepStatus === 'Accept' ? 'green' : 'red' : 'orange', border: '1px solid Black' }}
                                    >
                                        Status: {ListPendaftarDetail.AccDepStatus ? ListPendaftarDetail.AccDepStatus : 'Menunggu'}
                                    </h2>
                                </center>
                                <p>Oleh: {ListPendaftarDetail.BiodataBiodataNama ? ListPendaftarDetail.BiodataBiodataNama : ''}</p>
                                <p>Tanggal: {ListPendaftarDetail.AccDepTime ? new Date(ListPendaftarDetail.AccDepTime).toLocaleString(['ban', 'id']) : ''}</p>
                                <p>Keterangan: {ListPendaftarDetail.AccDepKet ? ListPendaftarDetail.AccDepKet : ''}</p>
                            </div>
                            <Profile DisableEdit={true} isView={true} />
                        </div>
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
    isListPendaftarDetailLoading: state.Job.isListPendaftarDetailLoading,
    ListPendaftarDetail: state.Job.ListPendaftarDetail,
})

export default connect(mapStateToProps, { get_List_Pendaftar_Detail })(DetailRecom)