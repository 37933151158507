import React, { Fragment, useEffect } from "react"

import CoTable from "../../../../Containers/Table"

import { convertToMonthName } from "../../../../../Global"

const Department = ({ isLoading, Data, UserData }) => {

    const [OriginalData, setOriginalData] = React.useState([])

    useEffect(() => {
        setOriginalData(Data)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Data])

    const UserDepartment = UserData?.Department ? UserData?.Department : [{}]

    // const st_Data = { color: 'white', backgroundColor: '#C55A11' }
    const st_Data = {}
    const st_TableBorderHead = { borderTop: '1px solid black', borderRight: '0px solid black', borderBottom: '1px solid black', borderLeft: '1px solid black', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }
    const st_TableBorder = { borderLeft: '1px solid black', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }

    const HeadData = [
        {
            Nama: 'No',
            width: '5%',
            KeyName: 'index',
            SortingType: 'Number',
            style: { ...st_Data, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Department',
            width: '10%',
            KeyName: 'DepartemenNama',
            style: { ...st_Data, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Sub',
            width: '10%',
            KeyName: 'Sub',
            style: { ...st_Data, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Level',
            width: '10%',
            KeyName: 'LevelNama',
            style: { ...st_Data, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Type',
            width: '10%',
            KeyName: 'MPPListStatusKaryawan',
            style: { ...st_Data, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Year',
            width: '5%',
            KeyName: 'MPPTahun',
            SortingType: 'Number',
            style: { ...st_Data, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Month',
            width: '5%',
            KeyName: 'MPPListBulanBuka',
            SortingType: 'Number',
            style: { ...st_Data, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Quantity',
            width: '5%',
            KeyName: 'MPPListJumlah',
            SortingType: 'Number',
            style: { ...st_Data, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'JobDescription',
            width: '5%',
            KeyName: 'JobDescription',
            SortingType: 'Number',
            style: { ...st_Data, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'My PR',
            width: '5%',
            KeyName: 'MyPR',
            SortingType: 'Number',
            style: { ...st_Data, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Pending PR',
            width: '5%',
            KeyName: 'PendingPR',
            SortingType: 'Number',
            style: { ...st_Data, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Used PR',
            width: '5%',
            KeyName: 'UsedPR',
            SortingType: 'Number',
            style: { ...st_Data, ...st_TableBorderHead, ...{ borderRight: '1px solid black' } },
            BodyStyle: { ...st_TableBorder, ...{ borderRight: '1px solid black' } }
        },
    ]

    const FilteredList = OriginalData ? OriginalData.filter((item, index) => (
        UserDepartment.some((r) => r.DepartemenId === item.DepartemenId) ? true : false
    )) : []

    const BodyData = FilteredList.map((item, index) => {
        return {
            index: {
                value: index + 1
            },
            DepartemenNama: {
                value: item?.DepartemenNama ? item.DepartemenNama : ' '
            },
            Sub: {
                value: item?.Sub ? item.Sub : ' '
            },
            LevelNama: {
                value: item?.LevelNama ? item.LevelNama : ' '
            },
            MPPListStatusKaryawan: {
                value: item?.MPPListStatusKaryawan ? item.MPPListStatusKaryawan : ' '
            },
            MPPTahun: {
                value: item?.MPPTahun ? item.MPPTahun : ' '
            },
            MPPListBulanBuka: {
                value: item?.MPPListBulanBuka ? item.MPPListBulanBuka : ' ',
                display: convertToMonthName(item.MPPListBulanBuka) 
            },
            MPPListJumlah: {
                value: item?.MPPListJumlah ? item.MPPListJumlah : ' '
            },
            JobDescription: {
                value: item?.JobdeskId ? 3 : item?.JobdeskStatus ? 2 : 1,
                display: item?.JobdeskId ? '✔' : item?.JobdeskStatus ? 'Draft' : '✖',
            },
            MyPR: {
                value: (Number(item?.PRAvailable ? item.PRAvailable : 0) - Number(item?.PRPending ? item.PRPending : 0)) - Number(item?.PRUsed ? item.PRUsed : 0) ? (Number(item?.PRAvailable ? item.PRAvailable : 0) - Number(item?.PRPending ? item.PRPending : 0)) - Number(item?.PRUsed ? item.PRUsed : 0) : ' '
            },
            PendingPR: {
                value: Number(item?.PRPending ? item.PRPending : 0) ? Number(item?.PRPending ? item.PRPending : 0) : ' '
            },
            UsedPR: {
                value: Number(item?.PRUsed ? item.PRUsed : 0) ? Number(item?.PRUsed ? item.PRUsed : 0) : ' '
            },
        }
    })

    return (
        <Fragment>
            <CoTable
                TableId={'ReportDepartment'}
                HeadData={HeadData}
                Data={BodyData}
                isHeadLoading={isLoading}
                isBodyLoading={isLoading}
                isPagination={BodyData.length > 5 ? true : false}
                isPaginationLoading={isLoading}
                isHeading={true}
                // isDownload={true}
            />
        </Fragment>
    )
}

export default Department