import React from "react"

import { connect } from 'react-redux'

import { Konfirm_Pendaftar } from '../../../../../../../../Store/Actions/Job.Action'

import { TextField, FormControl, InputLabel, Select, Button } from '@mui/material'

import Profile from '../../../../../../../Layouts/Account/Profile'

class PendaftarKonfirmasi extends React.Component {
    state = {
        Jenis: '',
        Keterangan: '',
        Status: false
    }
    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    formOnSubmit = (e) => {
        e.preventDefault()

        const JobActionId = this.props.JobActionId
        const AccountActionId = this.props.AccountActionId
        this.props.Konfirm_Pendaftar(JobActionId, AccountActionId, this.state.Jenis, this.state.Keterangan, this.props.ListPendaftar)
        this.setState({ Status: true })
    }
    render() {

        const User = this.props.User
        const UserRole = User.Role
        const JobRefereeIntern = '10008'
        const isJobRefereeIntern = UserRole.includes(JobRefereeIntern)

        const st_textfield_non = { marginTop: '10px', marginBottom: '10px', width: '100%' }

        const {
            Jenis,
            Keterangan,
            Status
        } = this.state

        return (
            <div>
                <Profile DisableEdit={true} isView={true} />
                <h2>
                    Action:
                </h2>
                <form
                    onSubmit={this.formOnSubmit}
                >

                    <FormControl style={st_textfield_non} variant="filled" required >
                        <InputLabel shrink >Jenis</InputLabel>
                        <Select style={{ backgroundColor: 'white' }} native onChange={(e) => this.onChangeField(e)} label="Jenis" name='Jenis' value={Jenis}  >
                            <option value="" disabled> -- select an option -- </option>
                            {['Accept', 'Shorted', 'Reject'].map((option, index) =>
                                <option key={index} value={option}>{option}</option>
                            )}
                        </Select>
                    </FormControl>
                    <TextField
                        style={st_textfield_non}
                        variant='outlined'
                        type='text'
                        label='Keterangan'
                        name='Keterangan'
                        value={Keterangan}
                        onChange={this.onChangeField}
                    />
                    <center>
                        <Button
                            variant={'contained'}
                            type="submit"
                            color={Status ? 'success' : 'primary'}
                            style={{ width: '80%', margin: '1% 0 4% 0' }}
                            disabled={isJobRefereeIntern || Status ? true : false}
                        >
                            {Status ? '✔ Submitted' : 'Submit'}
                        </Button>
                    </center>
                </form>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
    User: state.Auth.User,
    ListPendaftar: state.Job.ListPendaftar,
})

export default connect(mapStateToProps, { Konfirm_Pendaftar })(PendaftarKonfirmasi)