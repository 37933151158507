import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Load_Items_List } from '../../../../../../Store/Actions/Setting.Action'
import { get_MPP_Data, Konfirm_MPP, Konfirm_Update_MPP } from '../../../../../../Store/Actions/MPP.Action'
import { Create_Warning_Messages } from '../../../../../../Store/Actions/Messages.Actions'

import { Skeleton, Button, TextField, FormControl, InputLabel, Select, CircularProgress, FormGroup, FormControlLabel, Checkbox } from '@mui/material'

import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import LaptopIcon from '@mui/icons-material/Laptop'

import CoDetailMPP from '../../../../../Containers/Dashboard/MPP/DetailMPP'

class KonfirmasiPengajuanMPP extends React.Component {
    state = {
        Action: '',
        isUpdate: false,
        isConfirm: false,
        Keterangan: '',
        MPPList: [],
    }

    componentDidUpdate(prevProps) {
        if (this.props.MPPActionId !== prevProps.MPPActionId) {
            this.props.get_MPP_Data(this.props.MPPActionId)
        }

        if (this.props.MPPList !== prevProps.MPPList) {
            const { MPPList } = this.props

            const newMPPList = []

            MPPList.forEach((item) => {
                const Bulan = item.BulanBuka || item.BulanBuka === 0 ? item.BulanBuka : ''
                const StatusKaryawan = item.StatusKaryawan ? item.StatusKaryawan : ''
                const Jumlah = item.Jumlah ? item.Jumlah : ''
                const MPPListItems = item.Items ? item.Items : []

                const Items = []

                for (const x of MPPListItems) {
                    const BulanItems = x.BulanBuka || x.BulanBuka === 0 ? x.BulanBuka : ''
                    const KebutuhanItems = x.Item ? x.Item : ''
                    const JumlahItems = x.Jumlah ? x.Jumlah : ''
                    const newItems = { Bulan: BulanItems, Kebutuhan: KebutuhanItems, Jumlah: JumlahItems }
                    Items.push(newItems)
                }

                const MPPListComp = { Bulan: Bulan, StatusKaryawan: StatusKaryawan, Jumlah: Jumlah, Items: Items }
                newMPPList.push(MPPListComp)
            })

            this.setState({ MPPList: newMPPList })

        }

    }

    componentDidMount() {
        const { MPPActionId } = this.props
        if (MPPActionId !== null) {
            this.props.get_MPP_Data(MPPActionId)
            this.props.Load_Items_List()
        }
        console.log(this.props.get_MPP_Data(MPPActionId));
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    SelectFormOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    OnChangeCheckbox = E => {
        this.setState({ [E.target.name]: !this.state[E.target.name] })
    }

    OnChangeMap = (e, Index, Order) => {
        const MPPList = this.state.MPPList
        if (Order === 1) {
            MPPList[Index].Bulan = e.target.value
        } else if (Order === 2) {
            MPPList[Index].StatusKaryawan = e.target.value
        } else if (Order === 3) {
            MPPList[Index].Jumlah = e.target.value
        } else {

        }
        this.setState({ MPPList: MPPList })
    }

    AddMPP = () => {
        const MPPList = this.state.MPPList
        if ((MPPList[MPPList.length - 1].Bulan || MPPList[MPPList.length - 1].Bulan === 0) && MPPList[MPPList.length - 1].StatusKaryawan && MPPList[MPPList.length - 1].Jumlah > 0) {
            const newMPPList = { Bulan: '', StatusKaryawan: '', Jumlah: '', Items: [] }
            MPPList.push(newMPPList)
            this.setState({ MPPList: MPPList })
        } else {
            this.props.Create_Warning_Messages(null, 'Please complete the MPP List detail filling process first')
        }
    }

    DeleteMPP = (Index) => {
        if (Index > 0) {
            const MPPList = this.state.MPPList
            MPPList.splice(Index, 1)
            this.setState({ MPPList: MPPList })
        } else {
            this.props.Create_Warning_Messages(null, 'MPP List Cannot Be Empty')
        }
    }

    OnChangeMapItem = (e, Index, Index_Index, Order) => {
        const MPPList = this.state.MPPList
        const MPPListItem = MPPList[Index].Items

        if (Order === 1) {
            MPPListItem[Index_Index].Bulan = e.target.value
        } else if (Order === 2) {
            MPPListItem[Index_Index].Kebutuhan = e.target.value
        } else if (Order === 3) {
            MPPListItem[Index_Index].Jumlah = e.target.value
        } else {

        }
        this.setState({ MPPList: MPPList })
    }

    AddMPPListItem = (Index) => {
        const MPPList = this.state.MPPList
        const MPPListItem = MPPList[Index].Items

        if (MPPListItem.length === 0 || ((MPPListItem[MPPListItem.length - 1].Bulan || MPPListItem[MPPListItem.length - 1].Bulan === 0) && MPPListItem[MPPListItem.length - 1].Kebutuhan && MPPListItem[MPPListItem.length - 1].Jumlah > 0)) {
            const newMPPListItem = { Bulan: '', Kebutuhan: '', Jumlah: '' }
            MPPListItem.push(newMPPListItem)
            this.setState({ MPPList: MPPList })
        } else {
            this.props.Create_Warning_Messages(null, 'mohon lengkapi proses pengisian detail MPP Item terlebih dahulu')
        }
    }

    DeleteMPPListItem = (Index, Index_Index) => {
        const MPPList = this.state.MPPList
        const MPPListItem = MPPList[Index].Items

        MPPListItem.splice(Index_Index, 1)
        this.setState({ MPPList: MPPList })
    }

    formOnSubmit = (e) => {
        e.preventDefault()
        const { isUpdate } = this.state
        if (isUpdate) {
            this.props.Konfirm_Update_MPP(this.props.MPPActionId, this.state.MPPList, this.state.Action, this.state.Keterangan)
        } else {
            this.props.Konfirm_MPP(this.props.MPPActionId, this.state.Action, this.state.Keterangan)
        }
    }

    render() {

        const OpBulan = [
            {
                Value: 0,
                Item: 'Januari'
            },
            {
                Value: 1,
                Item: 'Februari'
            },
            {
                Value: 2,
                Item: 'Maret'
            },
            {
                Value: 3,
                Item: 'April'
            },
            {
                Value: 4,
                Item: 'Mei'
            },
            {
                Value: 5,
                Item: 'Juni'
            },
            {
                Value: 6,
                Item: 'Juli'
            }, {
                Value: 7,
                Item: 'Agustus'
            },
            {
                Value: 8,
                Item: 'September'
            },
            {
                Value: 9,
                Item: 'Oktober'
            },
            {
                Value: 10,
                Item: 'November'
            },
            {
                Value: 11,
                Item: 'Desember'
            }
        ]

        const OpJenisKaryawan = [
            'Permanent',
            'Contract',
            // 'Intern',
            // 'Outsource'
        ]

        const OpStatsu = [
            {
                Value: "Accept",
                Item: "Accept"
            },
            {
                Value: "Reject",
                Item: "Reject"
            },
        ]

        const {
            Action,
            isUpdate,
            isConfirm,
            Keterangan,
            MPPList,
            // MPPListItem,
        } = this.state

        const MPPData = this.props.MPPData
        const MPPListData = this.props.MPPList
        // const MPPListItemData = this.props.MPPListItem
        const isMPPActionGetDataLoading = this.props.isMPPActionGetDataLoading
        const ItemsList = this.props.ItemsList
        const isItemsLoading = this.props.isItemsLoading
        const isMPPKonfirmasiLoading = this.props.isMPPKonfirmasiLoading
        const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }
        const st_divaddtextfield = { display: 'flex', alignItems: 'center', margin: '0 0 2% 2%' }
        const st_TextSmall = { width: '20%', margin: '0 1% 0 0' }
        const st_icon = { color: 'grey', fontSize: '180%', margin: '0 0 0 1%', cursor: 'pointer' }

        return (
            <Fragment>
                <CoDetailMPP Data={MPPData} MPPList={MPPListData} isLoading={isMPPActionGetDataLoading || isItemsLoading} />

                <form
                    onSubmit={this.formOnSubmit}
                >
                    <h3>
                        Action
                    </h3>
                    {isMPPActionGetDataLoading || isItemsLoading ?
                        <Fragment>
                            {[1, 2, 3, 4, 5].map((item) =>
                                <Skeleton
                                    key={`KonfirmasiPengajuanMPP_Skeleton_${item}`}
                                    height={'5vh'}
                                />
                            )}
                        </Fragment> :
                        <Fragment>
                            <FormGroup row>
                                <FormControlLabel
                                    control={<Checkbox color='primary' onChange={this.OnChangeCheckbox} name="isUpdate" value={isUpdate} disabled={isMPPKonfirmasiLoading ? true : false} />}
                                    label="Edit MPP List"
                                />
                            </FormGroup>
                            {isUpdate ?
                                <Fragment>
                                    <h3>
                                        MPP List
                                    </h3>
                                    {MPPList.map((item, Index) => (
                                        <Fragment
                                            key={`MPPList_${Index}`}
                                        >
                                            <div
                                                style={st_divaddtextfield}
                                            >
                                                <span
                                                    style={{ marginRight: '1%' }}
                                                >
                                                    {`MPP ${Index + 1}`}
                                                </span>
                                                <FormControl
                                                    style={st_TextSmall}
                                                    variant="filled"
                                                    required
                                                >
                                                    <InputLabel
                                                        shrink
                                                    >
                                                        Waktu Pembukaan
                                                    </InputLabel>
                                                    <Select
                                                        native
                                                        style={{ backgroundColor: 'white' }}
                                                        onChange={(e) => this.OnChangeMap(e, Index, 1)}
                                                        label="Waktu Pembukaan"
                                                        name='WaktuPembukaan'
                                                        value={MPPList[Index].Bulan}
                                                    >
                                                        <option value="" disabled> -- select an option -- </option>
                                                        {OpBulan.map((option, index) =>
                                                            <option key={`OpBulan_${Index}_${index}`} value={option.Value}>{option.Item}</option>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                                <FormControl
                                                    style={st_TextSmall}
                                                    variant="filled"
                                                    required
                                                >
                                                    <InputLabel
                                                        shrink
                                                    >
                                                        Status Karyawan
                                                    </InputLabel>
                                                    <Select
                                                        native
                                                        style={{ backgroundColor: 'white' }}
                                                        onChange={(e) => this.OnChangeMap(e, Index, 2)}
                                                        label="Status Karyawan"
                                                        name='StatusKaryawan'
                                                        value={MPPList[Index].StatusKaryawan}
                                                    >
                                                        <option value="" disabled> -- select an option -- </option>
                                                        {OpJenisKaryawan.map((option, index) =>
                                                            <option key={`OpJenisKaryawan_${Index}_${index}`} value={option}>{option}</option>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                                <TextField
                                                    style={st_TextSmall}
                                                    variant='outlined'
                                                    type='number'
                                                    label='Jumlah'
                                                    name='Jumlah'
                                                    required
                                                    value={MPPList[Index].Jumlah}
                                                    onChange={(e) => this.OnChangeMap(e, Index, 3)}
                                                />
                                                {Index === 0 ?
                                                    <Fragment>
                                                    </Fragment> :
                                                    <Fragment>
                                                        <DeleteOutlineRoundedIcon
                                                            onClick={(e) => this.DeleteMPP(Index)}
                                                            style={st_icon}
                                                        />
                                                    </Fragment>}
                                                <LaptopIcon
                                                    onClick={(e) => this.AddMPPListItem(Index)}
                                                    style={st_icon}
                                                />
                                                <AddCircleRoundedIcon
                                                    onClick={this.AddMPP}
                                                    style={st_icon}
                                                />
                                            </div>

                                            {MPPList[Index].Items.map((item_item, Index_Index) => (
                                                <Fragment
                                                    key={`MPPList_Items_${Index}_${Index_Index}`}>
                                                    <div
                                                        style={{ ...st_divaddtextfield, marginLeft: '10%' }}
                                                    >
                                                        <span
                                                            style={{ marginRight: '1%' }}
                                                        >
                                                            {`MPP ${Index + 1} Item ${Index_Index + 1}`}
                                                        </span>
                                                        <FormControl
                                                            style={st_TextSmall}
                                                            variant="filled"
                                                            required
                                                        >
                                                            <InputLabel
                                                                shrink
                                                            >
                                                                Waktu Pembukaan
                                                            </InputLabel>
                                                            <Select
                                                                native
                                                                style={{ backgroundColor: 'white' }}
                                                                onChange={(e) => this.OnChangeMapItem(e, Index, Index_Index, 1)}
                                                                label="Waktu Pembukaan"
                                                                name='WaktuPembukaan'
                                                                value={MPPList[Index].Items[Index_Index].Bulan}
                                                            >
                                                                <option value="" disabled> -- select an option -- </option>
                                                                {OpBulan.map((option, index) =>
                                                                    <option key={`OpBulan_${Index}_${index}`} value={option.Value}>{option.Item}</option>
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                        <FormControl
                                                            style={st_TextSmall}
                                                            variant="filled"
                                                            required
                                                        >
                                                            <InputLabel
                                                                shrink
                                                            >
                                                                Kebutuhan
                                                            </InputLabel>
                                                            <Select
                                                                native
                                                                style={{ backgroundColor: 'white' }}
                                                                onChange={(e) => this.OnChangeMapItem(e, Index, Index_Index, 2)}
                                                                label="Kebutuhan"
                                                                name='Kebutuhan'
                                                                value={MPPList[Index].Items[Index_Index].Kebutuhan}
                                                            >
                                                                <option value="" disabled> -- select an option -- </option>
                                                                {ItemsList.map((option, index) =>
                                                                    <option key={`ItemsList_${Index}_${index}`} value={option.Id}>{option.Nama}</option>
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                        <TextField
                                                            style={st_TextSmall}
                                                            variant='outlined'
                                                            type='number'
                                                            label='Jumlah'
                                                            name='Jumlah'
                                                            required
                                                            value={MPPList[Index].Items[Index_Index].Jumlah}
                                                            onChange={(e) => this.OnChangeMapItem(e, Index, Index_Index, 3)}
                                                        />

                                                        <Fragment>
                                                            <DeleteOutlineRoundedIcon
                                                                onClick={(e) => this.DeleteMPPListItem(Index, Index_Index)}
                                                                style={st_icon}
                                                            />
                                                        </Fragment>
                                                        <LaptopIcon
                                                            onClick={(e) => this.AddMPPListItem(Index, Index_Index)}
                                                            style={st_icon}
                                                        />
                                                    </div>
                                                </Fragment>
                                            ))}
                                        </Fragment>
                                    ))}
                                </Fragment> :
                                <Fragment></Fragment>}
                            <FormControl
                                style={st_textfield}
                                variant="filled"
                                required
                            >
                                <InputLabel
                                    shrink
                                >
                                    Action
                                </InputLabel>
                                <Select
                                    native
                                    style={{ backgroundColor: 'white' }}
                                    onChange={(e) => this.SelectFormOnChange(e)}
                                    label="Action"
                                    name='Action'
                                    value={Action}
                                >
                                    <option value="" disabled> -- select an option -- </option>
                                    {OpStatsu.map((option, index) =>
                                        <option key={`Action_${index}`} value={option.Value}>{option.Item}</option>
                                    )}
                                </Select>
                            </FormControl>
                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Note'
                                name='Keterangan'
                                required={this.state.Action === 'Reject' || isUpdate ? true : false}
                                value={Keterangan}
                                onChange={this.onChangeField}
                            />

                            <FormGroup row>
                                <FormControlLabel
                                    control={<Checkbox color='primary' onChange={this.OnChangeCheckbox} name="isConfirm" value={isConfirm} disabled={isMPPKonfirmasiLoading ? true : false} />}
                                    label="I have confirmed"
                                />
                            </FormGroup>

                            <center>
                                <Button
                                    variant='contained'
                                    style={{ width: '90%', margin: '5% 0 5% 0' }}
                                    type='submit'
                                    disabled={isMPPKonfirmasiLoading || !isConfirm ? true : false}
                                >
                                    {isMPPKonfirmasiLoading ? <CircularProgress /> : 'Konfirmasi MPP'}
                                </Button>
                            </center>
                        </Fragment>}
                </form>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    MPPActionId: state.MPP.MPPActionId,
    isMPPActionGetDataLoading: state.MPP.isMPPActionGetDataLoading,
    MPPData: state.MPP.MPPData,
    MPPList: state.MPP.MPPList,
    MPPListItem: state.MPP.MPPListItem,
    ItemsList: state.Setting.ItemsList,
    isItemsLoading: state.Setting.isItemsLoading,
    isMPPKonfirmasiLoading: state.MPP.isMPPKonfirmasiLoading,
})

export default connect(mapStateToProps, { get_MPP_Data, Load_Items_List, Konfirm_MPP, Konfirm_Update_MPP, Create_Warning_Messages })(KonfirmasiPengajuanMPP)