import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_PR_User_Pengajuan, get_PR_Action_Id } from '../../../../Store/Actions/PR.Action'

import GenericModals from '../../../Containers/GenericModals'
import CoTable from '../../../Containers/Table'

import CoPengajuanPR from './Modal/Pengajuan/PengajuanPR'
import DetailPR from './Modal/DetailPR'
import MoUpdate from './Modal/Update'

class PengajuanPR extends React.Component {
    componentDidMount() {
        this.props.get_PR_User_Pengajuan()
    }

    onClickActionId(Id) {
        this.props.get_PR_Action_Id(Id)
    }

    render() {
        const User = this.props.User
        const Department = User?.Department ? User.Department : []
        const isAdmin = User?.isAdmin ? User.isAdmin : false

        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
                KeyName: 'index',
                SortingType: 'Number',
            },
            {
                Nama: 'Submission Number',
                width: '10%',
                KeyName: 'Id',
                SortingType: 'Number',
            },
            {
                Nama: 'Date',
                width: '10%',
                KeyName: 'TanggalPengajuan',
                SortingType: 'Date',
            },
            {
                Nama: 'Job Title',
                width: '20%',
                KeyName: 'Posisi',
            },
            {
                Nama: 'Status',
                width: '10%',
                KeyName: 'Status',
            },
            {
                Nama: 'Detail',
                width: '10%',
            },
            {
                Nama: 'Edit',
                width: '10%',
            },
        ]

        const PRUserMPAsli = this.props.PRUserMP ? this.props.PRUserMP : []
        const PRUserMP = PRUserMPAsli.filter((item) => Department.find((newItem) => newItem.DepartemenId === item.DepartmenIdPemohon) ? true : false)
        const BodyData = PRUserMP ? PRUserMP.map((item, index) => {
            const TanggalPengajuan = new Date(item.TanggalPengajuan).toLocaleString(['ban', 'id'])
            return {
                index: {
                    value: index + 1
                },
                Id: {
                    value: item.Id
                },
                TanggalPengajuan: {
                    value: item.TanggalPengajuan,
                    display: TanggalPengajuan
                },
                Posisi: {
                    value: item.NamaPosisi
                },
                Status: {
                    value:
                        item.Status,
                    display: (
                        <span
                            style={{
                                color: `${item?.Status ? item.Status === 'Submitted' ? 'Orange' :
                                    item.Status === 'Accept' ? 'Green' : 'Red' : 'black'}`
                            }}
                        >
                            {/* {item?.Status ? item.Status : 'Status'} */}
                            {item?.Status === 'Submitted' ? 'Pending Approval' : item?.Status === 'Accept' ? 'Approved' : item?.Status === 'Reject' ? 'Reject' : ''}
                        </span>)
                },
                Detail: {
                    value: item.Id,
                    display: (
                        <GenericModals
                            size='xl'
                            header='PR Submission Detail'
                            body={<DetailPR />}
                            Buttoncolor='primary'
                            ButtononClickeven={() => this.onClickActionId(item.Id)}
                            Buttonlabel={'Detail'}
                        />
                    )
                },
                Edit: {
                    value: item.Id,
                    display: (
                        <GenericModals
                            size='xl'
                            header='Edit PR Submission'
                            body={<MoUpdate />}
                            Buttoncolor='secondary'
                            // Buttondisabled={item?.Status ? item.Status === 'Submitted' || item.Status === 'Submitted' ? false : true : true}
                            Buttondisabled={isAdmin ? false : true}
                            ButtononClickeven={() => this.onClickActionId(item.Id)}
                            Buttonlabel={'Edit'}
                        />
                    )
                }
            }
        }) : []
console.log(PRUserMP);
        const RowDataInOnePage = 10

        const isPRUserPRLoading = this.props.isPRUserPRLoading
        return (
            <Fragment>
                <h2>
                    PR Submission
                </h2>
                <center>
                    <GenericModals
                        size='xl'
                        header='Create PR Submission'
                        body={<CoPengajuanPR />}
                        Buttoncolor='primary'
                        Buttonstyle={{ width: '90%', margin: '1%' }}
                        ButtononClickeven={() => null}
                        Buttonlabel={'+ Create Submission'}
                    />
                </center>
                <CoTable
                    TableId={'PRPengajuan'}
                    HeadData={HeadData}
                    Data={BodyData}
                    isHeadLoading={isPRUserPRLoading}
                    isBodyLoading={isPRUserPRLoading}
                    isPagination={BodyData.length > RowDataInOnePage ? true : false}
                    isPaginationLoading={isPRUserPRLoading}
                    PaginationCounter={
                        BodyData.length / RowDataInOnePage > Math.floor(BodyData.length / RowDataInOnePage) ?
                            Math.floor(BodyData.length / RowDataInOnePage) + 1 :
                            BodyData.length / RowDataInOnePage
                    }
                    isHeading={true}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    // AccountId: state.Auth.Id,
    User: state.Auth.User,
    isPRUserPRLoading: state.PR.isPRUserPRLoading,
    PRUserMP: state.PR.PRUserMP,
})

export default connect(mapStateToProps, { get_PR_User_Pengajuan, get_PR_Action_Id })(PengajuanPR)
