import React, { Fragment, useEffect } from "react"

import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'

import { VariableLimit } from "../../../../../../Global"

const SelectionProcessComp = ({ isLoading, Data }) => {

    const [SourcingContract, setSourcingContract] = React.useState(0)
    const [SourcingPermanent, setSourcingPermanent] = React.useState(0)
    const [InterviewContract, setInterviewContract] = React.useState(0)
    const [InterviewPermanent, setInterviewPermanent] = React.useState(0)
    const [OfferingContract, setOfferingContract] = React.useState(0)
    const [OfferingPermanent, setOfferingPermanent] = React.useState(0)
    const [MCUContract, setMCUContract] = React.useState(0)
    const [MCUPermanent, setMCUPermanent] = React.useState(0)
    const [PreBoardingContract, setPreBoardingContract] = React.useState(0)
    const [PreBoardingPermanent, setPreBoardingPermanent] = React.useState(0)
    const [HiredContract, setHiredContract] = React.useState(0)
    const [HiredPermanent, setHiredPermanent] = React.useState(0)

    const createBestCandidate = (ArrayValue, PRIdValue) => {
        for (const x in ArrayValue) {
            const Data = ArrayValue[x]
            const PRId = Data?.PRId ? Data.PRId : ''
            // if (Data.OBStatus === VariableLimit.JobProcess.OnBoarding) {
            if (PRIdValue === PRId && Data.OBStatus === 'Finished') {
                return { Data: Data, Posisi: 'Hired' }
                // } else if (PRIdValue === PRId && Data.KoStatus === VariableLimit.JobProcess.Contract) {
            } else if (PRIdValue === PRId && Data.KoStatus === 'Accept') {
                return { Data: Data, Posisi: 'Contract' }
                // } else if (PRIdValue === PRId && Data.BPStatus === VariableLimit.JobProcess.PreBoarding) {
            } else if (PRIdValue === PRId && Data.BPStatus === 'Done') {
                return { Data: Data, Posisi: 'PreBoarding' }
                // } else if (PRIdValue === PRId && Data.AccStatus === VariableLimit.JobProcess.MCUApproval) {
            } else if (PRIdValue === PRId && Data.AccStatus === 'Accept') {
                return { Data: Data, Posisi: 'MCUApproval' }
                // } else if (PRIdValue === PRId && Data.AppDate) {
            } else if (PRIdValue === PRId && Data.AppDate) {
                return { Data: Data, Posisi: 'MCU' }
                // } else if (PRIdValue === PRId && Data.OfStatus === VariableLimit.JobProcess.Offering) {
            } else if (PRIdValue === PRId && Data.OfStatus === 'Accept') {
                return { Data: Data, Posisi: 'Offering' }
                // } else if (PRIdValue === PRId && Data.UsIFStatus === VariableLimit.JobProcess.UserFinalInterview) {
            } else if (PRIdValue === PRId && Data.UsIFStatus === 'Accept') {
                return { Data: Data, Posisi: 'UserInterview' }
                // } else if (PRIdValue === PRId && Data.HrIStatus === VariableLimit.JobProcess.HRInterview) {
            } else if (PRIdValue === PRId && Data.HrIStatus === 'Accept') {
                return { Data: Data, Posisi: 'HRInterview' }
                // } else if (PRIdValue === PRId && Data.PTId) {
            } else if (PRIdValue === PRId && Data.PTId) {
                return { Data: Data, Posisi: 'PersonaltyTest' }
                // } else if (PRIdValue === PRId && Data.AccReferralStatus === VariableLimit.JobProcess.Referral) {
            } else if (PRIdValue === PRId && Data.AccReferralStatus === 'Dilanjutkan') {
                return { Data: Data, Posisi: 'Sourcing' }
            }

        }
        // return { Data: Data, Posisi: 'Hired' }
    }

    useEffect(() => {
        if (Data.length > 0) {
            const UniquePRNUmber = []

            // eslint-disable-next-line
            const newData = Data.filter((item, index) => {
                const PRId = item?.PRId ? item.PRId : ''
                const DefaultReturn = {
                    PRId: item?.PRId ? item.PRId : '',
                }
                if (!(UniquePRNUmber.includes(PRId))) {
                    UniquePRNUmber.push(PRId)
                    return DefaultReturn
                }
            })

            let letSourcingContract = 0
            let letSourcingPermanent = 0
            let letInterviewContract = 0
            let letInterviewPermanent = 0
            let letOfferingContract = 0
            let letOfferingPermanent = 0
            let letMCUContract = 0
            let letMCUPermanent = 0
            let letPreBoardingContract = 0
            let letPreBoardingPermanent = 0
            let letHiredContract = 0
            let letHiredPermanent = 0

            for (const x in newData) {
                const Item = newData[x]
                const PRId = Item?.PRId ? Item.PRId : ''
                const StatusKaryawan = Item?.StatusKaryawan ? Item.StatusKaryawan : ''
                let PosisiDibutuhkan = Item?.PosisiDibutuhkan ? Item.PosisiDibutuhkan : 0

                const BestCandidate = []
                let PosisiDibutuhkanLimit = 0
                while (PosisiDibutuhkanLimit < PosisiDibutuhkan) {
                    // eslint-disable-next-line
                    const UsedData = PosisiDibutuhkanLimit === 0 ? Data : Data.filter((item, index) => {
                        if (!(BestCandidate.includes(item.JobApplyId))) {
                            return { ...item }
                        }
                    })
                    const newBestCandidate = createBestCandidate(UsedData, PRId)
                    const BestCandidateData = newBestCandidate?.Data ? newBestCandidate.Data : ''
                    const BestCandidatePosisi = newBestCandidate?.Posisi ? newBestCandidate.Posisi : ''

                    if (BestCandidatePosisi === 'Hired') {
                        if (StatusKaryawan === VariableLimit.MPPType.Permanent) {
                            letHiredPermanent = letHiredPermanent + 1
                        } else if (StatusKaryawan === VariableLimit.MPPType.Contract) {
                            letHiredContract = letHiredContract + 1
                        }
                    } else if (BestCandidatePosisi === 'Contract' || BestCandidatePosisi === 'PreBoarding' || BestCandidatePosisi === 'MCUApproval') {
                        if (StatusKaryawan === VariableLimit.MPPType.Permanent) {
                            letPreBoardingPermanent = letPreBoardingPermanent + 1
                        } else if (StatusKaryawan === VariableLimit.MPPType.Contract) {
                            letPreBoardingContract = letPreBoardingContract + 1
                        }
                    } else if (BestCandidatePosisi === 'MCU' || BestCandidatePosisi === 'Offering') {
                        if (StatusKaryawan === VariableLimit.MPPType.Permanent) {
                            letMCUPermanent = letMCUPermanent + 1
                        } else if (StatusKaryawan === VariableLimit.MPPType.Contract) {
                            letMCUContract = letMCUContract + 1
                        }
                    } else if (BestCandidatePosisi === 'UserInterview') {
                        if (StatusKaryawan === VariableLimit.MPPType.Permanent) {
                            letOfferingPermanent = letOfferingPermanent + 1
                        } else if (StatusKaryawan === VariableLimit.MPPType.Contract) {
                            letOfferingContract = letOfferingContract + 1
                        }
                    } else if (BestCandidatePosisi === 'HRInterview' || BestCandidatePosisi === 'PersonaltyTest') {
                        if (StatusKaryawan === VariableLimit.MPPType.Permanent) {
                            letInterviewPermanent = letInterviewPermanent + 1
                        } else if (StatusKaryawan === VariableLimit.MPPType.Contract) {
                            letInterviewContract = InterviewContract + 1
                        }
                    } else {
                        if (StatusKaryawan === VariableLimit.MPPType.Permanent) {
                            letSourcingPermanent = letSourcingPermanent + 1
                        } else if (StatusKaryawan === VariableLimit.MPPType.Contract) {
                            letSourcingContract = letSourcingContract + 1
                        }
                    }

                    BestCandidate.push(BestCandidateData?.JobApplyId ? BestCandidateData.JobApplyId : '')
                    PosisiDibutuhkanLimit = PosisiDibutuhkanLimit + 1
                }
            }

            setSourcingContract(letSourcingContract)
            setSourcingPermanent(letSourcingPermanent)
            setInterviewContract(letInterviewContract)
            setInterviewPermanent(letInterviewPermanent)
            setOfferingContract(letOfferingContract)
            setOfferingPermanent(letOfferingPermanent)
            setMCUContract(letMCUContract)
            setMCUPermanent(letMCUPermanent)
            setPreBoardingContract(letPreBoardingContract)
            setPreBoardingPermanent(letPreBoardingPermanent)
            setHiredContract(letHiredContract)
            setHiredPermanent(letHiredPermanent)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Data])

    const st_Orange = { backgroundColor: '#FFC000' }
    const st_Yellow = { backgroundColor: '#FFFF00' }
    const st_Green = { backgroundColor: '#92D050' }
    const st_Pink = { backgroundColor: '#F8CBAD' }
    const st_Blue = { backgroundColor: '#00B0F0' }
    const st_Grey = { backgroundColor: '#DBDBDB' }
    // const st_TableHead = { color: 'white', backgroundColor: '#00a3df' }
    const st_TableBorderHead = { borderTop: '1px solid black', borderRight: '0px solid black', borderBottom: '1px solid black', borderLeft: '1px solid black', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }
    // const st_TableBorder = { borderLeft: '1px solid black', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }
    const st_TableBorderHead_WithoutTop = { borderTop: '0px solid black', borderRight: '0px solid black', borderBottom: '1px solid black', borderLeft: '1px solid black', fontFamily: 'Montserrat, Segoe UI', }

    return (
        <Fragment>
            <center>
            </center>

            <Table
                id={'MPPExistingOutputRealization'}
                stickyHeader
            >
                <TableHead>
                    <TableRow>
                        <TableCell align="center" colSpan={2} style={{ ...st_TableBorderHead, ...st_Orange }} >
                            Sourcing
                        </TableCell>
                        <TableCell align="center" colSpan={2} style={{ ...st_TableBorderHead, ...st_Yellow }} >
                            Interview
                        </TableCell>
                        <TableCell align="center" colSpan={2} style={{ ...st_TableBorderHead, ...st_Green }} >
                            Offering
                        </TableCell>
                        <TableCell align="center" colSpan={2} style={{ ...st_TableBorderHead, ...st_Pink }} >
                            MCU
                        </TableCell>
                        <TableCell align="center" colSpan={2} style={{ ...st_TableBorderHead, ...st_Blue }} >
                            PreBoarding
                        </TableCell>
                        <TableCell align="center" colSpan={2} style={{ ...st_TableBorderHead, ...st_Grey, borderRight: '1px solid black' }} >
                            Hired
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop }} >
                            C
                        </TableCell>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop }} >
                            P
                        </TableCell>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop }} >
                            C
                        </TableCell>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop }} >
                            P
                        </TableCell>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop }} >
                            C
                        </TableCell>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop }} >
                            P
                        </TableCell>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop }} >
                            C
                        </TableCell>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop }} >
                            P
                        </TableCell>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop }} >
                            C
                        </TableCell>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop }} >
                            P
                        </TableCell>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop }} >
                            C
                        </TableCell>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop, borderRight: '1px solid black' }} >
                            P
                        </TableCell>
                    </TableRow>


                </TableHead>

                <TableBody>
                    <TableRow>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop, }}>
                            {SourcingContract}
                        </TableCell>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop, }}>
                            {SourcingPermanent}
                        </TableCell>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop, }}>
                            {InterviewContract}
                        </TableCell>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop, }}>
                            {InterviewPermanent}
                        </TableCell>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop, }}>
                            {OfferingContract}
                        </TableCell>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop, }}>
                            {OfferingPermanent}
                        </TableCell>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop, }}>
                            {MCUContract}
                        </TableCell>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop, }}>
                            {MCUPermanent}
                        </TableCell>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop, }}>
                            {PreBoardingContract}
                        </TableCell>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop, }}>
                            {PreBoardingPermanent}
                        </TableCell>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop, }}>
                            {HiredContract}
                        </TableCell>
                        <TableCell align="center" style={{ ...st_TableBorderHead_WithoutTop, borderRight: '1px solid black' }}>
                            {HiredPermanent}
                        </TableCell>
                    </TableRow>
                </TableBody>

            </Table>
        </Fragment>
    )
}

export default SelectionProcessComp