import React, { Fragment } from "react";

import { connect } from "react-redux";

import {
  get_List_Equipment,
  get_Job_Action_Id,
} from "../../../../Store/Actions/Job.Action";
import { get_Account_Action_Id } from "../../../../Store/Actions/Account.Action";

import { Collapse } from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import GenericModals from "../../../Containers/GenericModals";
import CoTable from "../../../Containers/Table";
import Submit from "./Modal/Equipment/Submit";
import Detail from "./Modal/Equipment/Detail";

class Equipment extends React.Component {
  state = {
    MenungguOpen: true,
    SelesaiOpen: false,
  };

  componentDidMount() {
    this.props.get_List_Equipment(this.props.User.Email);
  }

  handleMenungguOpen = () => {
    this.setState({ MenungguOpen: !this.state.MenungguOpen });
  };

  handleSelesaiOpen = () => {
    this.setState({ SelesaiOpen: !this.state.SelesaiOpen });
  };

  onClickActionId = (JobId, AccountId) => {
    this.props.get_Job_Action_Id(JobId);
    this.props.get_Account_Action_Id(AccountId);
  };

  render() {
    const isListEquipmentLoading = this.props.isListEquipmentLoading;
    const ListEquipment = this.props.ListEquipment;
    const User = this.props.User;
    const isAdmin = User?.isAdmin ? User.isAdmin : false;
    const Department = User?.Department ? User.Department : [];
    const ITDep = "64018";
    const isIT = Department.find((Item) => Item.DepartemenId === ITDep);

    const HeadData = [
      {
        Nama: "No",
        width: "5%",
      },
      {
        Nama: "No Registrasi",
        width: "10%",
      },
      {
        Nama: "Nama",
        width: "10%",
      },
      {
        Nama: "Posisi",
        width: "10%",
      },
      {
        Nama: "Join Date",
        width: "10%",
      },
      {
        Nama: "Submit",
        width: "10%",
      },
    ];

    const FilteredList = ListEquipment
      ? ListEquipment.filter((item, index) => item.EqAccId === null)
      : [];

    const BodyData = FilteredList.map((item, index) => {
      return {
        index: index + 1,
        Noreg: item.Id,
        Nama: item.BiodataNama,
        Posisi: item.JobListNama,
        JoinDate: new Date(item.JoinDate).toLocaleDateString(),
        Detail: (
          <GenericModals
            size="m"
            Buttonvariant="contained"
            header="Submit Data Equipment"
            Buttondisabled={isAdmin || isIT ? false : true}
            body={<Submit />}
            Buttoncolor="primary"
            ButtononClickeven={() =>
              this.onClickActionId(item.JobListId, item.AccountId)
            }
            Buttonlabel={"Submit"}
          />
        ),
      };
    });

    const HeadData2 = [
      {
        Nama: "No",
        width: "5%",
      },
      {
        Nama: "No Registrasi",
        width: "10%",
      },
      {
        Nama: "Nama",
        width: "10%",
      },
      {
        Nama: "Posisi",
        width: "10%",
      },
      {
        Nama: "Status",
        width: "10%",
      },
      {
        Nama: "Keterangan",
        width: "30%",
      },
      {
        Nama: "Join Date",
        width: "10%",
      },
      {
        Nama: "Submit",
        width: "10%",
      },
    ];

    const FilteredList2 = ListEquipment
      ? ListEquipment.filter((item, index) => item.EqStatus === "Pending")
      : [];

    const BodyData2 = FilteredList2.map((item, index) => {
      return {
        index: index + 1,
        Noreg: item.Id,
        Nama: item.BiodataNama,
        Posisi: item.JobListNama,
        JoinDate: new Date(item.JoinDate).toLocaleDateString(),
        Status: (
          <span
            style={{
              color: item.EqStatus
                ? item.EqStatus === "Selesai"
                  ? "green"
                  : item.EqStatus === "Reject"
                  ? "Red"
                  : "orange"
                : "black",
            }}
          >
            {item.EqStatus}
          </span>
        ),
        Ket: item.EqKet,
        Detail: (
          <GenericModals
            size="l"
            header="Detail Data Equipment"
            body={<Submit />}
            Buttondisabled={isAdmin || Department === 64018 ? false : true}
            Buttoncolor="primary"
            ButtononClickeven={() =>
              this.onClickActionId(item.JobListId, item.AccountId)
            }
            Buttonlabel={"Detail"}
          />
        ),
      };
    });

    const HeadData3 = [
      {
        Nama: "No",
        width: "5%",
      },
      {
        Nama: "No Registrasi",
        width: "10%",
      },
      {
        Nama: "Nama",
        width: "10%",
      },
      {
        Nama: "Posisi",
        width: "10%",
      },
      {
        Nama: "Status",
        width: "10%",
      },
      {
        Nama: "Join Date",
        width: "10%",
      },
      {
        Nama: "Submit",
        width: "10%",
      },
    ];

    const FilteredList3 = ListEquipment
      ? ListEquipment.filter(
          (item, index) => item.EqStatus !== null && item.EqStatus !== "Pending"
        )
      : [];

    const BodyData3 = FilteredList3.map((item, index) => {
      return {
        index: index + 1,
        Noreg: item.Id,
        Nama: item.BiodataNama,
        Posisi: item.JobListNama,
        Status: (
          <span
            style={{
              color: item.EqStatus
                ? item.EqStatus === "Selesai"
                  ? "green"
                  : item.EqStatus === "Reject"
                  ? "Red"
                  : "orange"
                : "black",
            }}
          >
            {item.EqStatus}
          </span>
        ),
        JoinDate: new Date(item.JoinDate).toLocaleDateString(),
        Detail: (
          <GenericModals
            size="l"
            header="Detail Data Equipment"
            body={<Detail />}
            Buttoncolor="primary"
            ButtononClickeven={() =>
              this.onClickActionId(item.JobListId, item.AccountId)
            }
            Buttonlabel={"Detail"}
          />
        ),
      };
    });

    const { MenungguOpen, SelesaiOpen } = this.state;

    const RowDataInOnePage = 5;
    return (
      <Fragment>
        <h2>Equipment</h2>
        <h3
          onClick={this.handleMenungguOpen}
          style={{
            display: "flex",
            alignContent: "center",
            verticalAlign: "center",
            cursor: "pointer",
          }}
        >
          Menunggu {MenungguOpen ? <ExpandLess /> : <ExpandMore />}
        </h3>
        <Collapse in={MenungguOpen} timeout="auto" unmountOnExit>
          <CoTable
            TableId={"GreetDay_Menunggu"}
            HeadData={HeadData}
            Data={BodyData}
            isHeadLoading={isListEquipmentLoading}
            isBodyLoading={isListEquipmentLoading}
            isPagination={BodyData.length > RowDataInOnePage ? true : false}
            MaxRowOnPage={RowDataInOnePage}
            isPaginationLoading={isListEquipmentLoading}
            PaginationCounter={
              BodyData.length / RowDataInOnePage >
              Math.floor(BodyData.length / RowDataInOnePage)
                ? Math.floor(BodyData.length / RowDataInOnePage) + 1
                : BodyData.length / RowDataInOnePage
            }
          />
        </Collapse>
        <div style={{ margin: "0 0 5% 0" }}>
          <h3
            onClick={this.handleSelesaiOpen}
            style={{
              display: "flex",
              alignContent: "center",
              verticalAlign: "center",
              cursor: "pointer",
            }}
          >
            Pending{SelesaiOpen ? <ExpandLess /> : <ExpandMore />}
          </h3>
          <Collapse in={SelesaiOpen} timeout="auto" unmountOnExit>
            <CoTable
              TableId={"Panding"}
              HeadData={HeadData2}
              Data={BodyData2}
              isHeadLoading={isListEquipmentLoading}
              isBodyLoading={isListEquipmentLoading}
              isPagination={BodyData2.length > RowDataInOnePage ? true : false}
              MaxRowOnPage={RowDataInOnePage}
              isPaginationLoading={isListEquipmentLoading}
              PaginationCounter={
                BodyData2.length / RowDataInOnePage >
                Math.floor(BodyData2.length / RowDataInOnePage)
                  ? Math.floor(BodyData2.length / RowDataInOnePage) + 1
                  : BodyData2.length / RowDataInOnePage
              }
            />
          </Collapse>
        </div>
        <div style={{ margin: "0 0 5% 0" }}>
          <h3
            onClick={this.handleSelesaiOpen}
            style={{
              display: "flex",
              alignContent: "center",
              verticalAlign: "center",
              cursor: "pointer",
            }}
          >
            Selesai{SelesaiOpen ? <ExpandLess /> : <ExpandMore />}
          </h3>
          <Collapse in={SelesaiOpen} timeout="auto" unmountOnExit>
            <CoTable
              TableId={"GreetDay_Selesai"}
              HeadData={HeadData3}
              Data={BodyData3}
              isHeadLoading={isListEquipmentLoading}
              isBodyLoading={isListEquipmentLoading}
              isPagination={BodyData3.length > RowDataInOnePage ? true : false}
              MaxRowOnPage={RowDataInOnePage}
              isPaginationLoading={isListEquipmentLoading}
              PaginationCounter={
                BodyData3.length / RowDataInOnePage >
                Math.floor(BodyData3.length / RowDataInOnePage)
                  ? Math.floor(BodyData3.length / RowDataInOnePage) + 1
                  : BodyData3.length / RowDataInOnePage
              }
            />
          </Collapse>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  User: state.Auth.User,
  isListEquipmentLoading: state.Job.isListEquipmentLoading,
  ListEquipment: state.Job.ListEquipment,
});

export default connect(mapStateToProps, {
  get_List_Equipment,
  get_Job_Action_Id,
  get_Account_Action_Id,
})(Equipment);
