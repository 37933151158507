import {
    SUCCESS_MESSAGES,
    INFO_MESSAGES,
    WARNING_MESSAGES,
    ERROR_MESSAGES,
    CLEAN_MESSAGES,
    ONESTEP_CREATE_ACCOUNT,
    ONESTEP_CONFIRMATION_ACCOUNT,
    ONESTEP_FILING_BIODATA,
} from '../Actions/Type.Action'

const initialState = {
    isMessages: false,
    MessagesType: null,
    MessagesCode: null,
    Messages: null,
    HideDuration: null,
    CreateAccount: false,
    ConfirmationAccount: false,
    FilingBiodata: false,
}

// eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        case SUCCESS_MESSAGES:
            return {
                ...state,
                isMessages: true,
                MessagesType: "success",
                MessagesCode: action.payload.Status,
                Messages: action.payload.msg,
                HideDuration: action.payload.HideDuration,
            }
        case INFO_MESSAGES:
            return {
                ...state,
                isMessages: true,
                MessagesType: "info",
                MessagesCode: action.payload.Status,
                Messages: action.payload.msg,
                HideDuration: action.payload.HideDuration,
            }
        case WARNING_MESSAGES:
            return {
                ...state,
                isMessages: true,
                MessagesType: "warning",
                MessagesCode: action.payload.Status,
                Messages: action.payload.msg,
                HideDuration: action.payload.HideDuration,
            }
        case ERROR_MESSAGES:
            return {
                ...state,
                isMessages: true,
                MessagesType: "error",
                MessagesCode: action.payload.Status,
                Messages: action.payload.msg,
                HideDuration: action.payload.HideDuration,
            }
        case CLEAN_MESSAGES:
            state.isMessages = false
            state.MessagesType = null
            state.MessagesCode = null
            state.Messages = null
            state.HideDuration = null
            return {
                ...state,
            }
        case ONESTEP_CREATE_ACCOUNT:
            return {
                ...state,
                CreateAccount: true
            }
        case ONESTEP_CONFIRMATION_ACCOUNT:
            return {
                ...state,
                ConfirmationAccount: true
            }
        case ONESTEP_FILING_BIODATA:
            return {
                ...state,
                FilingBiodata: true
            }
        default:
            return state
    }
}