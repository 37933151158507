import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Load_Role_List, get_Role_Action_Id } from '../../../../Store/Actions/Setting.Action'

import CoTable from '../../../Containers/Table'
import GenericModals from '../../../Containers/GenericModals'

import Add from './Modal/Role/Add'
import View from './Modal/Role/View'
import Delete from './Modal/Role/Delete'

class SettingRole extends React.Component {
    componentDidMount() {
        this.props.Load_Role_List()
    }
    onClickActionId(Id) {
        this.props.get_Role_Action_Id(Id)
    }
    render() {
        const RoleList = this.props.RoleList
        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Id',
                width: '15%',
            },
            {
                Nama: 'Nama',
                width: '30%',
            },
            {
                Nama: 'Deskripsi',
                width: '30%',
            },
            {
                Nama: 'View',
                width: '10%',
            },
            {
                Nama: 'Delete',
                width: '10%',
            },
        ]
        const BodyData = RoleList ? RoleList.map((item, index) => {
            return {
                index: index + 1,
                Id: item.Id,
                Name: item.Nama,
                Deskripsi: item.Deskripsi,
                View: (
                    <GenericModals
                        size='xl'
                        header='View Role'
                        body={<View />}
                        Buttoncolor='primary'
                        ButtononClickeven={() => this.onClickActionId(item.Id)}
                        Buttonlabel={'view'}
                    />
                ),
                Delete: (
                    <GenericModals
                        size='m'
                        header='Delete Form'
                        body={<Delete />}
                        Buttoncolor='error'
                        ButtononClickeven={() => this.onClickActionId(item.Id)}
                        Buttonlabel={'Delete'}
                    />
                )
            }
        }) : []
        const isRoleLoading = this.props.isRoleLoading
        return (
            <Fragment>
                <h2>
                    Role Setting
                </h2>

                <center>
                    <GenericModals
                        size='m'
                        header='Tambah Role'
                        body={<Add />}
                        Buttoncolor='primary'
                        Buttonstyle={{ width: '90%' }}
                        ButtononClickeven={() => null}
                        Buttonlabel={'+ Role'}
                    />
                </center>

                <CoTable
                    TableId='RoleSetting'
                    HeadData={HeadData}
                    isHeadLoading={HeadData ? false : true}
                    Data={BodyData}
                    isBodyLoading={isRoleLoading}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isRoleLoading: state.Setting.isRoleLoading,
    RoleList: state.Setting.RoleList,
})

export default connect(mapStateToProps, { Load_Role_List, get_Role_Action_Id })(SettingRole)
