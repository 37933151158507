import {
  //* ROLE
  SETTING_ROLE_LOADING,
  SETTING_ROLE_LOADED,
  SETTING_ROLE_LOADING_DONE,
  SETTING_ROLE_ACTION_ADD_LOADING,
  SETTING_ROLE_ACTION_ADD_LOADING_DONE,
  SETTING_ROLE_ACTION_ADD_ERROR,
  SETTING_ROLE_ACTION_GET_ID,
  SETTING_ROLE_ACTION_GET_ROLELIST_PARTICIPANT_LOADING,
  SETTING_ROLE_ACTION_GET_ROLELIST_PARTICIPANT_LOADED,
  SETTING_ROLE_ACTION_GET_ROLELIST_PARTICIPANT_LOADING_DONE,
  SETTING_ROLE_ACTION_GET_DATA_LOADING,
  SETTING_ROLE_ACTION_GET_DATA_LOADED,
  SETTING_ROLE_ACTION_GET_DATA_LOADING_DONE,
  SETTING_ROLE_ACTION_DELETE_LOADING,
  SETTING_ROLE_ACTION_DELETE_LOADING_DONE,
  SETTING_ROLE_ACTION_ADD_LIST_LOADING,
  SETTING_ROLE_ACTION_ADD_LIST_LOADING_DONE,
  //* DIVISION
  SETTING_DIVISION_LOADING,
  SETTING_DIVISION_LOADED,
  SETTING_DIVISION_LOADING_DONE,
  SETTING_DIVISION_ACTION_ADD_LOADING,
  SETTING_DIVISION_ACTION_ADD_LOADING_DONE,
  SETTING_DIVISION_ACTION_ADD_ERROR,
  SETTING_DIVISION_ACTION_GET_ID,
  SETTING_DIVISION_ACTION_GET_DIVISIONLIST_PARTICIPANT_LOADING,
  SETTING_DIVISION_ACTION_GET_DIVISIONLIST_PARTICIPANT_LOADED,
  SETTING_DIVISION_ACTION_GET_DIVISIONLIST_PARTICIPANT_LOADING_DONE,
  SETTING_DIVISION_ACTION_GET_DATA_LOADING,
  SETTING_DIVISION_ACTION_GET_DATA_LOADED,
  SETTING_DIVISION_ACTION_GET_DATA_LOADING_DONE,
  SETTING_DIVISION_ACTION_DELETE_LOADING,
  SETTING_DIVISION_ACTION_DELETE_LOADING_DONE,
  SETTING_DIVISION_ACTION_ADD_LIST_LOADING,
  SETTING_DIVISION_ACTION_ADD_LIST_LOADING_DONE,
  //* DEPARTMENT
  SETTING_DEPARTMENT_LOADING,
  SETTING_DEPARTMENT_LOADED,
  SETTING_DEPARTMENT_LOADING_DONE,
  SETTING_DEPARTMENT_ACTION_ADD_LOADING,
  SETTING_DEPARTMENT_ACTION_ADD_LOADING_DONE,
  SETTING_DEPARTMENT_ACTION_ADD_ERROR,
  SETTING_DEPARTMENT_ACTION_GET_ID,
  SETTING_DEPARTMENT_ACTION_GET_DEPARTMENTLIST_PARTICIPANT_LOADING,
  SETTING_DEPARTMENT_ACTION_GET_DEPARTMENTLIST_PARTICIPANT_LOADED,
  SETTING_DEPARTMENT_ACTION_GET_DEPARTMENTLIST_PARTICIPANT_LOADING_DONE,
  SETTING_DEPARTMENT_ACTION_GET_DATA_LOADING,
  SETTING_DEPARTMENT_ACTION_GET_DATA_LOADED,
  SETTING_DEPARTMENT_ACTION_GET_DATA_LOADING_DONE,
  SETTING_DEPARTMENT_ACTION_DELETE_LOADING,
  SETTING_DEPARTMENT_ACTION_DELETE_LOADING_DONE,
  SETTING_DEPARTMENT_ACTION_ADD_LIST_LOADING,
  SETTING_DEPARTMENT_ACTION_ADD_LIST_LOADING_DONE,
  //* LEVEL
  SETTING_LEVEL_LOADING,
  SETTING_LEVEL_LOADED,
  SETTING_LEVEL_LOADING_DONE,
  SETTING_LEVEL_ACTION_ADD_LOADING,
  SETTING_LEVEL_ACTION_ADD_LOADING_DONE,
  SETTING_LEVEL_ACTION_ADD_ERROR,
  SETTING_LEVEL_ACTION_GET_ID,
  SETTING_LEVEL_ACTION_GET_LEVELLIST_PARTICIPANT_LOADING,
  SETTING_LEVEL_ACTION_GET_LEVELLIST_PARTICIPANT_LOADED,
  SETTING_LEVEL_ACTION_GET_LEVELLIST_PARTICIPANT_LOADING_DONE,
  SETTING_LEVEL_ACTION_GET_DATA_LOADING,
  SETTING_LEVEL_ACTION_GET_DATA_LOADED,
  SETTING_LEVEL_ACTION_GET_DATA_LOADING_DONE,
  SETTING_LEVEL_ACTION_DELETE_LOADING,
  SETTING_LEVEL_ACTION_DELETE_LOADING_DONE,
  SETTING_LEVEL_ACTION_ADD_LIST_LOADING,
  SETTING_LEVEL_ACTION_ADD_LIST_LOADING_DONE,
  SETTING_GOLONGAN_LOADING,
  SETTING_GOLONGAN_LOADED,
  SETTING_GOLONGAN_LOADING_DONE,
  //* MPP
  SETTING_MPP_LOADING,
  SETTING_MPP_LOADED,
  SETTING_MPP_LOADING_DONE,
  SETTING_MPP_ACTION_ADD_LOADING,
  SETTING_MPP_ACTION_ADD_LOADING_DONE,
  SETTING_MPP_ACTION_ADD_ERROR,
  SETTING_MPP_ACTION_GET_ID,
  SETTING_MPP_ACTION_GET_DATA_LOADING,
  SETTING_MPP_ACTION_GET_DATA_LOADED,
  SETTING_MPP_ACTION_GET_DATA_LOADING_DONE,
  SETTING_MPP_ACTION_DELETE_LOADING,
  SETTING_MPP_ACTION_DELETE_LOADING_DONE,
  //* ITEMS
  SETTING_ITEMS_LOADING,
  SETTING_ITEMS_LOADED,
  SETTING_ITEMS_LOADING_DONE,
  SETTING_ITEMS_ACTION_ADD_LOADING,
  SETTING_ITEMS_ACTION_ADD_LOADING_DONE,
  SETTING_ITEMS_ACTION_ADD_ERROR,
  SETTING_ITEMS_ACTION_GET_ID,
  SETTING_ITEMS_ACTION_GET_DATA_LOADING,
  SETTING_ITEMS_ACTION_GET_DATA_LOADED,
  SETTING_ITEMS_ACTION_GET_DATA_LOADING_DONE,
  SETTING_ITEMS_ACTION_DELETE_LOADING,
  SETTING_ITEMS_ACTION_DELETE_LOADING_DONE,
  //* LOKASI
  SETTING_LOKASI_LOADING,
  SETTING_LOKASI_LOADED,
  SETTING_LOKASI_LOADING_DONE,
  SETTING_LOKASI_ACTION_ADD_LOADING,
  SETTING_LOKASI_ACTION_ADD_LOADING_DONE,
  SETTING_LOKASI_ACTION_ADD_ERROR,
  SETTING_LOKASI_ACTION_GET_ID,
  SETTING_LOKASI_ACTION_GET_DATA_LOADING,
  SETTING_LOKASI_ACTION_GET_DATA_LOADED,
  SETTING_LOKASI_ACTION_GET_DATA_LOADING_DONE,
  SETTING_LOKASI_ACTION_DELETE_LOADING,
  SETTING_LOKASI_ACTION_DELETE_LOADING_DONE,
  //* PERTANYAAN INTERVIEW
  SETTING_PERTANYAAN_INTERVIEW_LOADING,
  SETTING_PERTANYAAN_INTERVIEW_LOADED,
  SETTING_PERTANYAAN_INTERVIEW_LOADING_DONE,
  SETTING_PERTANYAAN_INTERVIEW_ACTION_ADD_LOADING,
  SETTING_PERTANYAAN_INTERVIEW_ACTION_ADD_LOADING_DONE,
  SETTING_PERTANYAAN_INTERVIEW_ACTION_ADD_ERROR,
  SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_ID,
  SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_DATA_LOADING,
  SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_DATA_LOADED,
  SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_DATA_LOADING_DONE,
  SETTING_PERTANYAAN_INTERVIEW_ACTION_DELETE_LOADING,
  SETTING_PERTANYAAN_INTERVIEW_ACTION_DELETE_LOADING_DONE,
  //* PERTANYAAN BIODATA JAWABAN
  SETTING_PERTANYAAN_BIODATA_JAWABAN_LOADING,
  SETTING_PERTANYAAN_BIODATA_JAWABAN_LOADED,
  SETTING_PERTANYAAN_BIODATA_JAWABAN_LOADING_DONE,
  SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_ADD_LOADING,
  SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_ADD_LOADING_DONE,
  SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_ADD_ERROR,
  SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_ID,
  SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_DATA_LOADING,
  SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_DATA_LOADED,
  SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_DATA_LOADING_DONE,
  SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_DELETE_LOADING,
  SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_DELETE_LOADING_DONE,
  //* PERTANYAAN BIODATA_DESKRIPSI
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_LOADING,
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_LOADED,
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_LOADING_DONE,
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_ADD_LOADING,
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_ADD_LOADING_DONE,
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_ADD_ERROR,
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_ID,
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_DATA_LOADING,
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_DATA_LOADED,
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_DATA_LOADING_DONE,
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_DELETE_LOADING,
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_DELETE_LOADING_DONE,
  //* ACCOUNT
  SETTING_ACCOUNT_LOADING,
  SETTING_ACCOUNT_LOADED,
  SETTING_ACCOUNT_LOADING_DONE,
  SETTING_ACCOUNT_DETAIL_LOADING,
  SETTING_ACCOUNT_DETAIL_LOADED,
  SETTING_ACCOUNT_DETAIL_LOADING_DONE,
} from "../Actions/Type.Action";

const initialState = {
  //* ROLE
  isRoleLoading: false,
  RoleList: [],
  isRoleActionAddLoading: false,
  isRoleExist: false,
  RoleActionId: null,
  Participant: [],
  isParticipantLoading: false,
  isRoleActionGetDetailLoading: false,
  RoleDetail: null,
  RoleData: [],
  isRoleDeleteLoading: false,
  isRoleActionAddListLoading: false,
  //* DIVISION
  isDivisionLoading: false,
  DivisionList: [],
  isDivisionActionAddLoading: false,
  isDivisionExist: false,
  DivisionActionId: null,
  DivisionParticipant: [],
  isDivisionParticipantLoading: false,
  isDivisionActionGetDetailLoading: false,
  DivisionDetail: null,
  DivisionData: [],
  isDivisionDeleteLoading: false,
  isDivisionActionAddListLoading: false,
  //* DEPARTMENT
  isDepartmentLoading: false,
  DepartmentList: [],
  isDepartmentActionAddLoading: false,
  isDepartmentExist: false,
  DepartmentActionId: null,
  DepartmentParticipant: [],
  isDepartmentParticipantLoading: false,
  isDepartmentActionGetDetailLoading: false,
  DepartmentDetail: null,
  DepartmentData: [],
  isDepartmentDeleteLoading: false,
  isDepartmentActionAddListLoading: false,
  //* Level
  isLevelLoading: false,
  LevelList: [],
  isLevelActionAddLoading: false,
  isLevelExist: false,
  LevelActionId: null,
  LevelParticipant: [],
  isLevelParticipantLoading: false,
  isLevelActionGetDetailLoading: false,
  LevelDetail: null,
  LevelData: [],
  isLevelDeleteLoading: false,
  isLevelActionAddListLoading: false,
  isGolonganLoading: false,
  GolonganList: [],
  //* MPP
  isMPPLoading: false,
  MPPList: [],
  isMPPActionAddLoading: false,
  isMPPExist: false,
  MPPActionId: null,
  isMPPActionGetDetailLoading: false,
  MPPDetail: null,
  isMPPDeleteLoading: false,
  //* Items
  isItemsLoading: false,
  ItemsList: [],
  isItemsActionAddLoading: false,
  isItemsExist: false,
  ItemsActionId: null,
  isItemsActionGetDetailLoading: false,
  ItemsDetail: null,
  isItemsDeleteLoading: false,
  //* Lokasi
  isLokasiLoading: false,
  LokasiList: [],
  isLokasiActionAddLoading: false,
  isLokasiExist: false,
  LokasiActionId: null,
  isLokasiActionGetDetailLoading: false,
  LokasiDetail: null,
  isLokasiDeleteLoading: false,
  // Pertanyaan Interview
  isPertanyaanLoading: false,
  PertanyaanList: [],
  isPertanyaanActionAddLoading: false,
  isPertanyaanExist: false,
  PertanyaanActionId: null,
  isPertanyaanActionGetDetailLoading: false,
  PertanyaanDetail: null,
  isPertanyaanDeleteLoading: false,
  // Pertanyaan BiodataJawaban
  isPertanyaanBiodataJawabanLoading: false,
  PertanyaanBiodataJawabanList: [],
  isPertanyaanBiodataJawabanActionAddLoading: false,
  isPertanyaanBiodataJawabanExist: false,
  PertanyaanBiodataJawabanActionId: null,
  isPertanyaanBiodataJawabanActionGetDetailLoading: false,
  PertanyaanBiodataJawabanDetail: null,
  isPertanyaanBiodataJawabanDeleteLoading: false,
  // Pertanyaan BiodataDeskripsi
  isPertanyaanBiodataDeskripsiLoading: false,
  PertanyaanBiodataDeskripsiList: [],
  isPertanyaanBiodataDeskripsiActionAddLoading: false,
  isPertanyaanBiodataDeskripsiExist: false,
  PertanyaanBiodataDeskripsiActionId: null,
  isPertanyaanBiodataDeskripsiActionGetDetailLoading: false,
  PertanyaanBiodataDeskripsiDetail: null,
  isPertanyaanBiodataDeskripsiDeleteLoading: false,
  //* ACCOUNT
  isAccountLoading: false,
  AccountList: [],
  isAccountDetailLoading: false,
  AccountDetail: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    //* ROLE
    case SETTING_ROLE_LOADING:
      return {
        ...state,
        isRoleLoading: true,
      };
    case SETTING_ROLE_LOADED:
      return {
        ...state,
        RoleList: action.payload,
      };
    case SETTING_ROLE_LOADING_DONE:
      return {
        ...state,
        isRoleLoading: false,
      };
    case SETTING_ROLE_ACTION_ADD_LOADING:
      return {
        ...state,
        isRoleActionAddLoading: true,
      };
    case SETTING_ROLE_ACTION_ADD_LOADING_DONE:
      return {
        ...state,
        isRoleActionAddLoading: false,
      };
    case SETTING_ROLE_ACTION_ADD_ERROR:
      return {
        ...state,
        isRoleExist: action.payload,
      };
    case SETTING_ROLE_ACTION_GET_ID:
      return {
        ...state,
        RoleActionId: action.payload,
      };
    case SETTING_ROLE_ACTION_GET_ROLELIST_PARTICIPANT_LOADING:
      return {
        ...state,
        isParticipantLoading: true,
      };
    case SETTING_ROLE_ACTION_GET_ROLELIST_PARTICIPANT_LOADED:
      return {
        ...state,
        Participant: action.payload,
      };
    case SETTING_ROLE_ACTION_GET_ROLELIST_PARTICIPANT_LOADING_DONE:
      return {
        ...state,
        isParticipantLoading: false,
      };
    case SETTING_ROLE_ACTION_GET_DATA_LOADING:
      return {
        ...state,
        isRoleActionGetDetailLoading: true,
      };
    case SETTING_ROLE_ACTION_GET_DATA_LOADED:
      return {
        ...state,
        RoleDetail: action.payload.Detail,
        RoleData: action.payload.Data,
      };
    case SETTING_ROLE_ACTION_GET_DATA_LOADING_DONE:
      return {
        ...state,
        isRoleActionGetDetailLoading: false,
      };
    case SETTING_ROLE_ACTION_DELETE_LOADING:
      return {
        ...state,
        isRoleDeleteLoading: true,
      };
    case SETTING_ROLE_ACTION_DELETE_LOADING_DONE:
      return {
        ...state,
        isRoleDeleteLoading: false,
      };
    case SETTING_ROLE_ACTION_ADD_LIST_LOADING:
      return {
        ...state,
        isRoleActionAddListLoading: true,
      };
    case SETTING_ROLE_ACTION_ADD_LIST_LOADING_DONE:
      return {
        ...state,
        isRoleActionAddListLoading: false,
      };
    //* DIVISION
    case SETTING_DIVISION_LOADING:
      return {
        ...state,
        isDivisionLoading: true,
      };
    case SETTING_DIVISION_LOADED:
      return {
        ...state,
        DivisionList: action.payload,
      };
    case SETTING_DIVISION_LOADING_DONE:
      return {
        ...state,
        isDivisionLoading: false,
      };
    case SETTING_DIVISION_ACTION_ADD_LOADING:
      return {
        ...state,
        isDivisionActionAddLoading: true,
      };
    case SETTING_DIVISION_ACTION_ADD_LOADING_DONE:
      return {
        ...state,
        isDivisionActionAddLoading: false,
      };
    case SETTING_DIVISION_ACTION_ADD_ERROR:
      return {
        ...state,
        isDivisionExist: action.payload,
      };
    case SETTING_DIVISION_ACTION_GET_ID:
      return {
        ...state,
        DivisionActionId: action.payload,
      };
    case SETTING_DIVISION_ACTION_GET_DIVISIONLIST_PARTICIPANT_LOADING:
      return {
        ...state,
        isDivisionParticipantLoading: true,
      };
    case SETTING_DIVISION_ACTION_GET_DIVISIONLIST_PARTICIPANT_LOADED:
      return {
        ...state,
        DivisionParticipant: action.payload,
      };
    case SETTING_DIVISION_ACTION_GET_DIVISIONLIST_PARTICIPANT_LOADING_DONE:
      return {
        ...state,
        isDivisionParticipantLoading: false,
      };
    case SETTING_DIVISION_ACTION_GET_DATA_LOADING:
      return {
        ...state,
        isDivisionActionGetDetailLoading: true,
      };
    case SETTING_DIVISION_ACTION_GET_DATA_LOADED:
      return {
        ...state,
        DivisionDetail: action.payload.Detail,
        DivisionData: action.payload.Data,
      };
    case SETTING_DIVISION_ACTION_GET_DATA_LOADING_DONE:
      return {
        ...state,
        isDivisionActionGetDetailLoading: false,
      };
    case SETTING_DIVISION_ACTION_DELETE_LOADING:
      return {
        ...state,
        isDivisionDeleteLoading: true,
      };
    case SETTING_DIVISION_ACTION_DELETE_LOADING_DONE:
      return {
        ...state,
        isDivisionDeleteLoading: false,
      };
    case SETTING_DIVISION_ACTION_ADD_LIST_LOADING:
      return {
        ...state,
        isDivisionActionAddListLoading: true,
      };
    case SETTING_DIVISION_ACTION_ADD_LIST_LOADING_DONE:
      return {
        ...state,
        isDivisionActionAddListLoading: false,
      };
    //* DEPARTMENT
    case SETTING_DEPARTMENT_LOADING:
      return {
        ...state,
        isDepartmentLoading: true,
      };
    case SETTING_DEPARTMENT_LOADED:
      return {
        ...state,
        DepartmentList: action.payload,
      };
    case SETTING_DEPARTMENT_LOADING_DONE:
      return {
        ...state,
        isDepartmentLoading: false,
      };
    case SETTING_DEPARTMENT_ACTION_ADD_LOADING:
      return {
        ...state,
        isDepartmentActionAddLoading: true,
      };
    case SETTING_DEPARTMENT_ACTION_ADD_LOADING_DONE:
      return {
        ...state,
        isDepartmentActionAddLoading: false,
      };
    case SETTING_DEPARTMENT_ACTION_ADD_ERROR:
      return {
        ...state,
        isDepartmentExist: action.payload,
      };
    case SETTING_DEPARTMENT_ACTION_GET_ID:
      return {
        ...state,
        DepartmentActionId: action.payload,
      };
    case SETTING_DEPARTMENT_ACTION_GET_DEPARTMENTLIST_PARTICIPANT_LOADING:
      return {
        ...state,
        isDepartmentParticipantLoading: true,
      };
    case SETTING_DEPARTMENT_ACTION_GET_DEPARTMENTLIST_PARTICIPANT_LOADED:
      return {
        ...state,
        DepartmentParticipant: action.payload,
      };
    case SETTING_DEPARTMENT_ACTION_GET_DEPARTMENTLIST_PARTICIPANT_LOADING_DONE:
      return {
        ...state,
        isDepartmentParticipantLoading: false,
      };
    case SETTING_DEPARTMENT_ACTION_GET_DATA_LOADING:
      return {
        ...state,
        isDepartmentActionGetDetailLoading: true,
      };
    case SETTING_DEPARTMENT_ACTION_GET_DATA_LOADED:
      return {
        ...state,
        DepartmentDetail: action.payload.Detail,
        DepartmentData: action.payload.Data,
      };
    case SETTING_DEPARTMENT_ACTION_GET_DATA_LOADING_DONE:
      return {
        ...state,
        isDepartmentActionGetDetailLoading: false,
      };
    case SETTING_DEPARTMENT_ACTION_DELETE_LOADING:
      return {
        ...state,
        isDepartmentDeleteLoading: true,
      };
    case SETTING_DEPARTMENT_ACTION_DELETE_LOADING_DONE:
      return {
        ...state,
        isDepartmentDeleteLoading: false,
      };
    case SETTING_DEPARTMENT_ACTION_ADD_LIST_LOADING:
      return {
        ...state,
        isDepartmentActionAddListLoading: true,
      };
    case SETTING_DEPARTMENT_ACTION_ADD_LIST_LOADING_DONE:
      return {
        ...state,
        isDepartmentActionAddListLoading: false,
      };
    //* LEVEL
    case SETTING_LEVEL_LOADING:
      return {
        ...state,
        isLevelLoading: true,
      };
    case SETTING_LEVEL_LOADED:
      return {
        ...state,
        LevelList: action.payload,
      };
    case SETTING_LEVEL_LOADING_DONE:
      return {
        ...state,
        isLevelLoading: false,
      };

    case SETTING_GOLONGAN_LOADING:
      return {
        ...state,
        isGolonganLoading: true,
      };
    case SETTING_GOLONGAN_LOADED:
      return {
        ...state,
        GolonganList: action.payload,
      };
    case SETTING_GOLONGAN_LOADING_DONE:
      return {
        ...state,
        isGolonganLoading: false,
      };
    case SETTING_LEVEL_ACTION_ADD_LOADING:
      return {
        ...state,
        isLevelActionAddLoading: true,
      };
    case SETTING_LEVEL_ACTION_ADD_LOADING_DONE:
      return {
        ...state,
        isLevelActionAddLoading: false,
      };
    case SETTING_LEVEL_ACTION_ADD_ERROR:
      return {
        ...state,
        isLevelExist: action.payload,
      };
    case SETTING_LEVEL_ACTION_GET_ID:
      return {
        ...state,
        LevelActionId: action.payload,
      };
    case SETTING_LEVEL_ACTION_GET_LEVELLIST_PARTICIPANT_LOADING:
      return {
        ...state,
        isLevelParticipantLoading: true,
      };
    case SETTING_LEVEL_ACTION_GET_LEVELLIST_PARTICIPANT_LOADED:
      return {
        ...state,
        LevelParticipant: action.payload,
      };
    case SETTING_LEVEL_ACTION_GET_LEVELLIST_PARTICIPANT_LOADING_DONE:
      return {
        ...state,
        isLevelParticipantLoading: false,
      };
    case SETTING_LEVEL_ACTION_GET_DATA_LOADING:
      return {
        ...state,
        isLevelActionGetDetailLoading: true,
      };
    case SETTING_LEVEL_ACTION_GET_DATA_LOADED:
      return {
        ...state,
        LevelDetail: action.payload.Detail,
        LevelData: action.payload.Data,
      };
    case SETTING_LEVEL_ACTION_GET_DATA_LOADING_DONE:
      return {
        ...state,
        isLevelActionGetDetailLoading: false,
      };
    case SETTING_LEVEL_ACTION_DELETE_LOADING:
      return {
        ...state,
        isLevelDeleteLoading: true,
      };
    case SETTING_LEVEL_ACTION_DELETE_LOADING_DONE:
      return {
        ...state,
        isLevelDeleteLoading: false,
      };
    case SETTING_LEVEL_ACTION_ADD_LIST_LOADING:
      return {
        ...state,
        isLevelActionAddListLoading: true,
      };
    case SETTING_LEVEL_ACTION_ADD_LIST_LOADING_DONE:
      return {
        ...state,
        isLevelActionAddListLoading: false,
      };
    //* MPP
    case SETTING_MPP_LOADING:
      return {
        ...state,
        isMPPLoading: true,
      };
    case SETTING_MPP_LOADED:
      return {
        ...state,
        MPPList: action.payload,
      };
    case SETTING_MPP_LOADING_DONE:
      return {
        ...state,
        isMPPLoading: false,
      };
    case SETTING_MPP_ACTION_ADD_LOADING:
      return {
        ...state,
        isMPPActionAddLoading: true,
      };
    case SETTING_MPP_ACTION_ADD_LOADING_DONE:
      return {
        ...state,
        isMPPActionAddLoading: false,
      };
    case SETTING_MPP_ACTION_ADD_ERROR:
      return {
        ...state,
        isMPPExist: action.payload,
      };
    case SETTING_MPP_ACTION_GET_ID:
      return {
        ...state,
        MPPActionId: action.payload,
      };
    case SETTING_MPP_ACTION_GET_DATA_LOADING:
      return {
        ...state,
        isMPPActionGetDetailLoading: true,
      };
    case SETTING_MPP_ACTION_GET_DATA_LOADED:
      return {
        ...state,
        MPPDetail: action.payload.Detail,
      };
    case SETTING_MPP_ACTION_GET_DATA_LOADING_DONE:
      return {
        ...state,
        isMPPActionGetDetailLoading: false,
      };
    case SETTING_MPP_ACTION_DELETE_LOADING:
      return {
        ...state,
        isMPPDeleteLoading: true,
      };
    case SETTING_MPP_ACTION_DELETE_LOADING_DONE:
      return {
        ...state,
        isMPPDeleteLoading: false,
      };
    //* Items
    case SETTING_ITEMS_LOADING:
      return {
        ...state,
        isItemsLoading: true,
      };
    case SETTING_ITEMS_LOADED:
      return {
        ...state,
        ItemsList: action.payload,
      };
    case SETTING_ITEMS_LOADING_DONE:
      return {
        ...state,
        isItemsLoading: false,
      };
    case SETTING_ITEMS_ACTION_ADD_LOADING:
      return {
        ...state,
        isItemsActionAddLoading: true,
      };
    case SETTING_ITEMS_ACTION_ADD_LOADING_DONE:
      return {
        ...state,
        isItemsActionAddLoading: false,
      };
    case SETTING_ITEMS_ACTION_ADD_ERROR:
      return {
        ...state,
        isItemsExist: action.payload,
      };
    case SETTING_ITEMS_ACTION_GET_ID:
      return {
        ...state,
        ItemsActionId: action.payload,
      };
    case SETTING_ITEMS_ACTION_GET_DATA_LOADING:
      return {
        ...state,
        isItemsActionGetDetailLoading: true,
      };
    case SETTING_ITEMS_ACTION_GET_DATA_LOADED:
      return {
        ...state,
        ItemsDetail: action.payload.Detail,
      };
    case SETTING_ITEMS_ACTION_GET_DATA_LOADING_DONE:
      return {
        ...state,
        isItemsActionGetDetailLoading: false,
      };
    case SETTING_ITEMS_ACTION_DELETE_LOADING:
      return {
        ...state,
        isItemsDeleteLoading: true,
      };
    case SETTING_ITEMS_ACTION_DELETE_LOADING_DONE:
      return {
        ...state,
        isItemsDeleteLoading: false,
      };
    //* Lokasi
    case SETTING_LOKASI_LOADING:
      return {
        ...state,
        isLokasiLoading: true,
      };
    case SETTING_LOKASI_LOADED:
      return {
        ...state,
        LokasiList: action.payload,
      };
    case SETTING_LOKASI_LOADING_DONE:
      return {
        ...state,
        isLokasiLoading: false,
      };
    case SETTING_LOKASI_ACTION_ADD_LOADING:
      return {
        ...state,
        isLokasiActionAddLoading: true,
      };
    case SETTING_LOKASI_ACTION_ADD_LOADING_DONE:
      return {
        ...state,
        isLokasiActionAddLoading: false,
      };
    case SETTING_LOKASI_ACTION_ADD_ERROR:
      return {
        ...state,
        isLokasiExist: action.payload,
      };
    case SETTING_LOKASI_ACTION_GET_ID:
      return {
        ...state,
        LokasiActionId: action.payload,
      };
    case SETTING_LOKASI_ACTION_GET_DATA_LOADING:
      return {
        ...state,
        isLokasiActionGetDetailLoading: true,
      };
    case SETTING_LOKASI_ACTION_GET_DATA_LOADED:
      return {
        ...state,
        LokasiDetail: action.payload.Detail,
      };
    case SETTING_LOKASI_ACTION_GET_DATA_LOADING_DONE:
      return {
        ...state,
        isLokasiActionGetDetailLoading: false,
      };
    case SETTING_LOKASI_ACTION_DELETE_LOADING:
      return {
        ...state,
        isLokasiDeleteLoading: true,
      };
    case SETTING_LOKASI_ACTION_DELETE_LOADING_DONE:
      return {
        ...state,
        isLokasiDeleteLoading: false,
      };
    //* PERTANYAAN INTERVIEW
    case SETTING_PERTANYAAN_INTERVIEW_LOADING:
      return {
        ...state,
        isPertanyaanLoading: true,
      };
    case SETTING_PERTANYAAN_INTERVIEW_LOADED:
      return {
        ...state,
        PertanyaanList: action.payload,
      };
    case SETTING_PERTANYAAN_INTERVIEW_LOADING_DONE:
      return {
        ...state,
        isPertanyaanLoading: false,
      };
    case SETTING_PERTANYAAN_INTERVIEW_ACTION_ADD_LOADING:
      return {
        ...state,
        isPertanyaanActionAddLoading: true,
      };
    case SETTING_PERTANYAAN_INTERVIEW_ACTION_ADD_LOADING_DONE:
      return {
        ...state,
        isPertanyaanActionAddLoading: false,
      };
    case SETTING_PERTANYAAN_INTERVIEW_ACTION_ADD_ERROR:
      return {
        ...state,
        isPertanyaanExist: action.payload,
      };
    case SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_ID:
      return {
        ...state,
        PertanyaanActionId: action.payload,
      };
    case SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_DATA_LOADING:
      return {
        ...state,
        isPertanyaanActionGetDetailLoading: true,
      };
    case SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_DATA_LOADED:
      return {
        ...state,
        PertanyaanDetail: action.payload.Detail,
      };
    case SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_DATA_LOADING_DONE:
      return {
        ...state,
        isPertanyaanActionGetDetailLoading: false,
      };
    case SETTING_PERTANYAAN_INTERVIEW_ACTION_DELETE_LOADING:
      return {
        ...state,
        isPertanyaanDeleteLoading: true,
      };
    case SETTING_PERTANYAAN_INTERVIEW_ACTION_DELETE_LOADING_DONE:
      return {
        ...state,
        isPertanyaanDeleteLoading: false,
      };
    //* PERTANYAAN BIODATA JAWABAN
    case SETTING_PERTANYAAN_BIODATA_JAWABAN_LOADING:
      return {
        ...state,
        isPertanyaanBiodataJawabanLoading: true,
      };
    case SETTING_PERTANYAAN_BIODATA_JAWABAN_LOADED:
      return {
        ...state,
        PertanyaanBiodataJawabanList: action.payload,
      };
    case SETTING_PERTANYAAN_BIODATA_JAWABAN_LOADING_DONE:
      return {
        ...state,
        isPertanyaanBiodataJawabanLoading: false,
      };
    case SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_ADD_LOADING:
      return {
        ...state,
        isPertanyaanBiodataJawabanActionAddLoading: true,
      };
    case SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_ADD_LOADING_DONE:
      return {
        ...state,
        isPertanyaanBiodataJawabanActionAddLoading: false,
      };
    case SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_ADD_ERROR:
      return {
        ...state,
        isPertanyaanBiodataJawabanExist: action.payload,
      };
    case SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_ID:
      return {
        ...state,
        PertanyaanBiodataJawabanActionId: action.payload,
      };
    case SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_DATA_LOADING:
      return {
        ...state,
        isPertanyaanBiodataJawabanActionGetDetailLoading: true,
      };
    case SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_DATA_LOADED:
      return {
        ...state,
        PertanyaanBiodataJawabanDetail: action.payload.Detail,
      };
    case SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_DATA_LOADING_DONE:
      return {
        ...state,
        isPertanyaanBiodataJawabanActionGetDetailLoading: false,
      };
    case SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_DELETE_LOADING:
      return {
        ...state,
        isPertanyaanBiodataJawabanDeleteLoading: true,
      };
    case SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_DELETE_LOADING_DONE:
      return {
        ...state,
        isPertanyaanBiodataJawabanDeleteLoading: false,
      };
    //* PERTANYAAN BIODATA DESKRIPSI
    case SETTING_PERTANYAAN_BIODATA_DESKRIPSI_LOADING:
      return {
        ...state,
        isPertanyaanBiodataDeskripsiLoading: true,
      };
    case SETTING_PERTANYAAN_BIODATA_DESKRIPSI_LOADED:
      return {
        ...state,
        PertanyaanBiodataDeskripsiList: action.payload,
      };
    case SETTING_PERTANYAAN_BIODATA_DESKRIPSI_LOADING_DONE:
      return {
        ...state,
        isPertanyaanBiodataDeskripsiLoading: false,
      };
    case SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_ADD_LOADING:
      return {
        ...state,
        isPertanyaanBiodataDeskripsiActionAddLoading: true,
      };
    case SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_ADD_LOADING_DONE:
      return {
        ...state,
        isPertanyaanBiodataDeskripsiActionAddLoading: false,
      };
    case SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_ADD_ERROR:
      return {
        ...state,
        isPertanyaanBiodataDeskripsiExist: action.payload,
      };
    case SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_ID:
      return {
        ...state,
        PertanyaanBiodataDeskripsiActionId: action.payload,
      };
    case SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_DATA_LOADING:
      return {
        ...state,
        isPertanyaanBiodataDeskripsiActionGetDetailLoading: true,
      };
    case SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_DATA_LOADED:
      return {
        ...state,
        PertanyaanBiodataDeskripsiDetail: action.payload.Detail,
      };
    case SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_DATA_LOADING_DONE:
      return {
        ...state,
        isPertanyaanBiodataDeskripsiActionGetDetailLoading: false,
      };
    case SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_DELETE_LOADING:
      return {
        ...state,
        isPertanyaanBiodataDeskripsiDeleteLoading: true,
      };
    case SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_DELETE_LOADING_DONE:
      return {
        ...state,
        isPertanyaanBiodataDeskripsiDeleteLoading: false,
      };
    //* ACCOUNT
    case SETTING_ACCOUNT_LOADING:
      return {
        ...state,
        isAccountLoading: true,
      };
    case SETTING_ACCOUNT_LOADED:
      return {
        ...state,
        AccountList: action.payload,
      };
    case SETTING_ACCOUNT_LOADING_DONE:
      return {
        ...state,
        isAccountLoading: false,
      };
    case SETTING_ACCOUNT_DETAIL_LOADING:
      return {
        ...state,
        isAccountDetailLoading: true,
      };
    case SETTING_ACCOUNT_DETAIL_LOADED:
      return {
        ...state,
        AccountDetail: action.payload,
      };
    case SETTING_ACCOUNT_DETAIL_LOADING_DONE:
      return {
        ...state,
        isAccountDetailLoading: false,
      };
    default:
      return state;
  }
}
