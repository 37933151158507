import axios from 'axios'

import {
    AUTH_RELOADE_PAGE,
    ////// Jobdesk
    JOBDESK_USER_JOBDESK_LOADING,
    JOBDESK_USER_JOBDESK_LOADED,
    JOBDESK_USER_JOBDESK_LOADING_DONE,
    JOBDESK_ADD_LOADING,
    JOBDESK_ADD_LOADING_DONE,
    JOBDESK_ACTION_GET_ID,
    JOBDESK_ACTION_GET_DATA_LOADING,
    JOBDESK_ACTION_GET_DATA_LOADED,
    JOBDESK_ACTION_GET_DATA_LOADING_DONE,
    JOBDESK_GET_PERTANYAAN_LOADING,
    JOBDESK_GET_PERTANYAAN_LOADED,
    JOBDESK_GET_PERTANYAAN_LOADING_DONE,
    JOBDESK_GET_MASTER_USER_LOADING,
    JOBDESK_GET_MASTER_USER_LOADED,
    JOBDESK_GET_MASTER_USER_LOADING_DONE,
} from './Type.Action'

import {
    // Create_Error_Messages,
    Create_Success_Messages
} from './Messages.Actions'

import { Get_IpAddres, tokenConfig, ErrorHandler } from './Base.Action'

import { Error_Messages_Standardization } from '../../Global'

export const get_Jobdesk_All_Pengajuan = () => async (dispatch, getState) => {
    try {
        dispatch({ type: JOBDESK_USER_JOBDESK_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/jobdesk/all`, tokenConfig(getState))
        if (Data) {
            dispatch({
                type: JOBDESK_USER_JOBDESK_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: JOBDESK_USER_JOBDESK_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'get_Jobdesk_All_Pengajuan')
    }
}

export const get_Jobdesk_User_Pengajuan = () => async (dispatch, getState) => {
    try {
        dispatch({ type: JOBDESK_USER_JOBDESK_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/jobdesk/user`, tokenConfig(getState))
        if (Data) {
            dispatch({
                type: JOBDESK_USER_JOBDESK_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: JOBDESK_USER_JOBDESK_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'get_Jobdesk_User_Pengajuan')
    }
}

export const Add_Jobdesk = (
    MPPId,
    NamaJabatan,
    MPPListId,
    Level,
    Departemen,
    StatusKaryawan,
    BulanBuka,
    AtasanLangsung,
    AtasanTakLangsung,
    Tujuan,
    Wewenang,
    TanggungJawab,
    UraianTugas,
    JumlahBawahanLangsung,
    PosisiBawahanLangsung,
    JumlahBawahanTakLangsung,
    PosisiBawahanTakLangsung,
    Internal,
    Eksternal,
    Pendidikan,
    Jurusan,
    UsiaMax,
    UsiaMin,
    KeterampilanUmum,
    PengetahuanKhusus,
    KeterampilanKhusus,
    Sertifikasi,
    PengalamanKerja,
    DalamKantor,
    DalamLapangan,
    LokasiLainnya,
    Status,
    KompetensiPrilaku,
    KompetensiTeknik
) => async (dispatch, getState) => {
    try {
        dispatch({ type: JOBDESK_ADD_LOADING })

        const IpAddres = Get_IpAddres()

        const body = JSON.stringify({
            MPPId,
            NamaJabatan,
            MPPListId,
            Level,
            Departemen,
            StatusKaryawan,
            BulanBuka,
            AtasanLangsung,
            AtasanTakLangsung,
            Tujuan,
            Wewenang,
            TanggungJawab,
            UraianTugas,
            JumlahBawahanLangsung,
            PosisiBawahanLangsung,
            JumlahBawahanTakLangsung,
            PosisiBawahanTakLangsung,
            Internal,
            Eksternal,
            Pendidikan,
            Jurusan,
            UsiaMax,
            UsiaMin,
            KeterampilanUmum,
            PengetahuanKhusus,
            KeterampilanKhusus,
            Sertifikasi,
            PengalamanKerja,
            DalamKantor,
            DalamLapangan,
            LokasiLainnya,
            Status,
            KompetensiPrilaku,
            KompetensiTeknik
        })
        const Data = await axios.post(`${IpAddres}/jobdesk/add`, body, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `Jobdesk Berhasil dibuat`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: JOBDESK_ADD_LOADING_DONE })
        ErrorHandler(err, 'Add_Jobdesk')
    }
}

export const get_Jobdesk_Action_Id = (Id) => (dispatch) => {
    dispatch({
        type: JOBDESK_ACTION_GET_ID,
        payload: Id
    })
}

export const Update_Jobdesk = (
    Id,
    NamaJabatan,
    LevelId,
    DepartemenId,
    StatusKaryawan,
    BulanBuka,
    AtasanLangsung,
    AtasanTakLangsung,
    Tujuan,
    Wewenang,
    TanggungJawab,
    UraianTugas,
    JumlahBawahanLangsung,
    PosisiBawahanLangsung,
    JumlahBawahanTakLangsung,
    PosisiBawahanTakLangsung,
    Internal,
    Eksternal,
    Pendidikan,
    Jurusan,
    UsiaMax,
    UsiaMin,
    KeterampilanUmum,
    PengetahuanKhusus,
    KeterampilanKhusus,
    Sertifikasi,
    PengalamanKerja,
    DalamKantor,
    DalamLapangan,
    LokasiLainnya,
    Status,
    KompetensiPrilaku,
    KompetensiTeknik,
) => async (dispatch, getState) => {
    try {

        const IpAddres = Get_IpAddres()

        const body = JSON.stringify({
            Id,
            NamaJabatan,
            LevelId,
            DepartemenId,
            StatusKaryawan,
            BulanBuka,
            AtasanLangsung,
            AtasanTakLangsung,
            Tujuan,
            Wewenang,
            TanggungJawab,
            UraianTugas,
            JumlahBawahanLangsung,
            PosisiBawahanLangsung,
            JumlahBawahanTakLangsung,
            PosisiBawahanTakLangsung,
            Internal,
            Eksternal,
            Pendidikan,
            Jurusan,
            UsiaMax,
            UsiaMin,
            KeterampilanUmum,
            PengetahuanKhusus,
            KeterampilanKhusus,
            Sertifikasi,
            PengalamanKerja,
            DalamKantor,
            DalamLapangan,
            LokasiLainnya,
            Status,
            KompetensiPrilaku,
            KompetensiTeknik,
        })
        const Data = await axios.post(`${IpAddres}/jobdesk/update`, body, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `Jobdesk Berhasil dibuat`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'Add_Jobdesk')
    }
}


export const get_Jobdesk_Data = (Id) => async (dispatch, getState) => {
    try {
        dispatch({ type: JOBDESK_ACTION_GET_DATA_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/jobdesk/detail/${Id}`, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({
                type: JOBDESK_ACTION_GET_DATA_LOADED,
                payload: Data.data
            })
            dispatch({ type: JOBDESK_ACTION_GET_DATA_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: JOBDESK_ACTION_GET_DATA_LOADING_DONE })
        ErrorHandler(err, 'get_Jobdesk_Data')
    }
}

export const get_Jobdesk_Data_From_MPPList = (Id) => async (dispatch, getState) => {
    try {
        dispatch({ type: JOBDESK_ACTION_GET_DATA_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/jobdesk/detail/mpplist/${Id}`, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({
                type: JOBDESK_ACTION_GET_DATA_LOADED,
                payload: Data.data
            })
            dispatch({ type: JOBDESK_ACTION_GET_DATA_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: JOBDESK_ACTION_GET_DATA_LOADING_DONE })
        ErrorHandler(err, 'get_Jobdesk_Data_From_MPPList')
    }
}

export const get_Jobdesk_Pertanyaan = (JobListId, AccountId) => async (dispatch, getState) => {
    try {
        dispatch({ type: JOBDESK_GET_PERTANYAAN_LOADING })
        const IpAddres = Get_IpAddres()

        const body = JSON.stringify({ JobListId, AccountId })
        const Data = await axios.post(`${IpAddres}/jobdesk/pertanyaan`, body, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({
                type: JOBDESK_GET_PERTANYAAN_LOADED,
                payload: Data.data
            })
            dispatch({ type: JOBDESK_GET_PERTANYAAN_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: JOBDESK_GET_PERTANYAAN_LOADING_DONE })
        ErrorHandler(err, 'get_Jobdesk_Pertanyaan')
    }
}

export const get_Jobdesk_Master_User = () => async (dispatch, getState) => {
    try {
        dispatch({ type: JOBDESK_GET_MASTER_USER_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/account/Account/master/user`, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({
                type: JOBDESK_GET_MASTER_USER_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: JOBDESK_GET_MASTER_USER_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: JOBDESK_GET_MASTER_USER_LOADING_DONE })
        ErrorHandler(err, 'get_Jobdesk_Master_User')
    }
}

