import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Jobdesk_User_Pengajuan, get_Jobdesk_All_Pengajuan, get_Jobdesk_Action_Id } from '../../../../Store/Actions/Jobdesk.Action'

import { Collapse } from '@mui/material'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import GenericModals from '../../../Containers/GenericModals'
import CoTable from '../../../Containers/Table'

import CoPengajuanJobdesk from './Modal/Pengajuan/Pengajuan'
import CoUpdate from './Modal/Pengajuan/Update'
import DetailJobdesk from './Modal/Detail'

class PengajuanJobdesk extends React.Component {
    state = {
        isDraftOpen: false,
        isSubmittedOpen: true,
    }
    componentDidMount() {
        // const User = this.props.User
        this.props.get_Jobdesk_All_Pengajuan()
        // const isAdmin = User?.isAdmin ? User.isAdmin : false
        // if (isAdmin) {
        //     this.props.get_Jobdesk_All_Pengajuan()
        // } else {
        //     this.props.get_Jobdesk_User_Pengajuan()
        // }
    }

    onClickActionId(Id) {
        this.props.get_Jobdesk_Action_Id(Id)
    }

    render() {
        const {
            isDraftOpen,
            isSubmittedOpen,
        } = this.state

        const User = this.props.User
        const Department = User?.Department ? User.Department : []
        const isAdmin = User?.isAdmin ? User.isAdmin : false

        const HeadDataDraft = [
            {
                Nama: 'No',
                width: '5%',
                KeyName: 'index',
                SortingType: 'Number',
            },
            {
                Nama: 'Submission Number',
                width: '10%',
                KeyName: 'Id',
                SortingType: 'Number',
            },
            {
                Nama: 'Date',
                width: '10%',
                KeyName: 'TanggalPengajuan',
                SortingType: 'Date',
            },
            {
                Nama: 'Position Name',
                width: '25%',
                KeyName: 'Posisi',
            },
            {
                Nama: 'Submission',
                width: '10%',
            },
        ]

        const JobdeskUserMPAsli = this.props.JobdeskUserMP ? this.props.JobdeskUserMP : []
        const JobdeskUserMP = JobdeskUserMPAsli.filter((item) => isAdmin ? true : Department.find((newItem) => newItem.DepartemenId === item.DepartemenId) ? true : false)

        const JobdeskUserDraft = JobdeskUserMP.filter((item) => item?.Status ? false : true)
        const BodyDataDraft = JobdeskUserDraft ? JobdeskUserDraft.map((item, index) => {
            const TanggalPengajuan = new Date(item.TanggalPengajuan).toLocaleString(['ban', 'id'])
            return {
                index: {
                    value: index + 1
                },
                Id: {
                    value: item.Id
                },
                TanggalPengajuan: {
                    value: item.TanggalPengajuan,
                    display: TanggalPengajuan
                },
                Posisi: {
                    value: item.NamaJabatan
                },
                Edit: {
                    value: item.Id,
                    display: (
                        <GenericModals
                            size='xl'
                            header='Job Description Submission'
                            body={<CoUpdate />}
                            Buttoncolor='secondary'
                            // Buttondisabled={isAdmin ? false : item.PRId ? true : false}
                            Buttondisabled={isAdmin ? false : false}
                            ButtononClickeven={() => this.onClickActionId(item.Id)}
                            Buttonlabel={'Submission'}
                        />
                    )
                }
            }
        }) : []

        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
                KeyName: 'index',
                SortingType: 'Number',
            },
            {
                Nama: 'Submission Number',
                width: '10%',
                KeyName: 'Id',
                SortingType: 'Number',
            },
            {
                Nama: 'Date',
                width: '10%',
                KeyName: 'TanggalPengajuan',
                SortingType: 'Date',
            },
            {
                Nama: 'Department',
                width: '25%',
                KeyName: 'DepartemenNama',
            },
            {
                Nama: 'Position Name',
                width: '25%',
                KeyName: 'Posisi',
            },
            {
                Nama: 'Details',
                width: '10%',
            },
            isAdmin ? {
                Nama: 'Edit',
                width: '10%',
            } : {
                Nama: '',
                width: '10%',
            },
        ]

        const JobdeskUserSubmissions = JobdeskUserMP.filter((item) => item?.Status ? true : false)
        const BodyData = JobdeskUserSubmissions ? JobdeskUserSubmissions.map((item, index) => {
            const TanggalPengajuan = new Date(item.TanggalPengajuan).toLocaleString(['ban', 'id'])
            return {
                index: {
                    value: index + 1
                },
                Id: {
                    value: item.Id
                },
                TanggalPengajuan: {
                    value: item.TanggalPengajuan,
                    display: TanggalPengajuan
                },
                DepartemenNama: {
                    value: item?.DepartemenNama ? item.DepartemenNama : ' '
                },
                Posisi: {
                    value: item.NamaJabatan
                },
                Detail: {
                    value: item.Id,
                    display: (
                        <GenericModals
                            size='xl'
                            header='Job Description Detail'
                            body={<DetailJobdesk />}
                            Buttoncolor='primary'
                            ButtononClickeven={() => this.onClickActionId(item.Id)}
                            Buttonlabel={'Detail'}
                        />
                    )
                },
                Edit: {
                    value: item.Id,
                    display: isAdmin ? (
                        <GenericModals
                            size='xl'
                            header='Job Description Update'
                            body={<CoUpdate />}
                            Buttoncolor='secondary'
                            // Buttondisabled={isAdmin ? false : item.PRId ? true : false}
                            Buttondisabled={isAdmin ? false : false}
                            ButtononClickeven={() => this.onClickActionId(item.Id)}
                            Buttonlabel={'Edit'}
                        />
                    ) : (<Fragment></Fragment>)
                }
            }
        }) : []

        const MaxRowOnPage = 10

        const isPRUserPRLoading = this.props.isPRUserPRLoading
        return (
            <Fragment>
                <h2>
                    Job Description Submissions
                </h2>
                <center>
                    <GenericModals
                        size='xl'
                        header='Job Description'
                        body={<CoPengajuanJobdesk />}
                        Buttoncolor='primary'
                        Buttonstyle={{ width: '90%', margin: '1%' }}
                        ButtononClickeven={() => null}
                        Buttonlabel={'+ Create Job Description'}
                    />
                </center>

                <h3
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => this.setState({ isDraftOpen: !this.state.isDraftOpen })}
                >
                    Draft {isDraftOpen ? <ExpandLess /> : <ExpandMore />}
                </h3>
                <Collapse in={isDraftOpen} timeout="auto" unmountOnExit>
                    <CoTable
                        TableId={'Job'}
                        HeadData={HeadDataDraft}
                        Data={BodyDataDraft}
                        isHeadLoading={isPRUserPRLoading}
                        isBodyLoading={isPRUserPRLoading}
                        isPagination={BodyDataDraft.length > MaxRowOnPage ? true : false}
                        isPaginationLoading={isPRUserPRLoading}
                        PaginationCounter={
                            BodyDataDraft.length / MaxRowOnPage > Math.floor(BodyDataDraft.length / MaxRowOnPage) ?
                                Math.floor(BodyDataDraft.length / MaxRowOnPage) + 1 :
                                BodyDataDraft.length / MaxRowOnPage
                        }
                    />
                </Collapse>

                <h3
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => this.setState({ isSubmittedOpen: !this.state.isSubmittedOpen })}
                >
                    Submitted {isSubmittedOpen ? <ExpandLess /> : <ExpandMore />}
                </h3>
                <Collapse in={isSubmittedOpen} timeout="auto" unmountOnExit>
                    <CoTable
                        TableId={'PRPengajuan'}
                        HeadData={HeadData}
                        Data={BodyData}
                        isHeadLoading={isPRUserPRLoading}
                        isBodyLoading={isPRUserPRLoading}
                        isPagination={BodyData.length > MaxRowOnPage ? true : false}
                        isPaginationLoading={isPRUserPRLoading}
                        PaginationCounter={
                            BodyData.length / MaxRowOnPage > Math.floor(BodyData.length / MaxRowOnPage) ?
                                Math.floor(BodyData.length / MaxRowOnPage) + 1 :
                                BodyData.length / MaxRowOnPage
                        }
                        isHeading={true}
                    />
                </Collapse>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    // AccountId: state.Auth.Id,
    User: state.Auth.User,
    isJobdeskUserJobdeskLoading: state.Jobdesk.isJobdeskUserJobdeskLoading,
    JobdeskUserMP: state.Jobdesk.JobdeskUserMP,
})

export default connect(mapStateToProps, { get_Jobdesk_User_Pengajuan, get_Jobdesk_All_Pengajuan, get_Jobdesk_Action_Id })(PengajuanJobdesk)
