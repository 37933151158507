import React, { Fragment } from 'react'

import { Skeleton, TextField, Switch } from '@mui/material'

const SettingRoleDetail = ({ Data, isLoading = false, ...props }) => {

    const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%', backgroundColor: '#E0E0E0' }

    return (
        <Fragment>
            {isLoading ?
                <Fragment>
                    <Skeleton
                        height={'10vh'}
                    />
                    <Skeleton
                        height={'10vh'}
                    />
                    <Skeleton
                        height={'10vh'}
                    />
                </Fragment> :
                <Fragment>
                    <h3>
                        Account Detail
                    </h3>
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        label='Id'
                        name='Id'
                        value={Data?.Id ? Data.Id : ''}
                        disabled
                    />
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='email'
                        label='Email'
                        name='Email'
                        value={Data?.Email ? Data.Email : ''}
                        disabled
                    />
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        label='User Name'
                        name='UserName'
                        value={Data?.UserName ? Data.UserName : ''}
                        disabled
                    />
                    <label>
                        <b>
                            isActive?
                        </b>
                    </label><br />
                    <label>
                        Tidak
                    </label>
                    <Switch
                        style={{ color: Data ? Data.isActive ? 'green' : 'red' : 'red' }}
                        name='isActive'
                        disabled
                        checked={Data ? Data.isActive : false}
                    />
                    <label>
                        Ya
                    </label><br /><br />
                    <label>
                        <b>
                            isActiveted?
                        </b>
                    </label><br />
                    <label>
                        Tidak
                    </label>
                    <Switch
                        style={{ color: Data ? Data.isActiveted ? 'green' : 'red' : 'red' }}
                        name='isActiveted'
                        disabled
                        checked={Data ? Data.isActiveted : false}
                    />
                    <label>
                        Ya
                    </label><br /><br />
                    <label>
                        <b>
                            Akun Pelamar?
                        </b>
                    </label><br />
                    <label>
                        Tidak
                    </label>
                    <Switch
                        style={{ color: Data ? Data.isPendaftar ? 'green' : 'red' : 'red' }}
                        name='isPendaftar'
                        disabled
                        checked={Data ? Data.isPendaftar : false}
                    />
                    <label>
                        Ya
                    </label><br /><br />
                    <label>
                        <b>
                            Akun Karyawan?
                        </b>
                    </label><br />
                    <label>
                        Tidak
                    </label>
                    <Switch
                        style={{ color: Data ? Data.isKaryawan ? 'green' : 'red' : 'red' }}
                        name='isKaryawan'
                        disabled
                        checked={Data ? Data.isKaryawan : false}
                    />
                    <label>
                        Ya
                    </label><br /><br />
                    <label>
                        <b>
                            Akun Admin?
                        </b>
                    </label><br />
                    <label>
                        Tidak
                    </label>
                    <Switch
                        style={{ color: Data ? Data.isAdmin ? 'green' : 'red' : 'red' }}
                        name='isAdmin'
                        disabled
                        checked={Data ? Data.isAdmin : false}
                    />
                    <label>
                        Ya
                    </label><br /><br />
                    <label>
                        <b>
                            isBiodataComplete?
                        </b>
                    </label><br />
                    <label>
                        Tidak
                    </label>
                    <Switch
                        style={{ color: Data ? Data.isBiodataComplete ? 'green' : 'red' : 'red' }}
                        name='isBiodataComplete'
                        disabled
                        checked={Data ? Data.isBiodataComplete : false}
                    />
                    <label>
                        Ya
                    </label><br /><br />
                    <label>
                        <b>
                            isBiodataExtend?
                        </b>
                    </label><br />
                    <label>
                        Tidak
                    </label>
                    <Switch
                        style={{ color: Data ? Data.isBiodataExtend ? 'green' : 'red' : 'red' }}
                        name='isBiodataExtend'
                        disabled
                        checked={Data ? Data.isBiodataExtend : false}
                    />
                    <label>
                        Ya
                    </label><br /><br />
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        label='Biodata Id'
                        name='BiodataId'
                        value={Data?.BiodataId ? Data.BiodataId : ''}
                        disabled
                    />
                </Fragment>}
        </Fragment>
    )
}

export default SettingRoleDetail