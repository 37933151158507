import React from 'react'

import { connect } from 'react-redux'
import { Add_Items } from '../../../../../../Store/Actions/Setting.Action'

import { TextField, Button, CircularProgress } from '@mui/material'


class SettingItemsAdd extends React.Component {
    state = {
        Nama: '',
        isNamaError: false,
        Deskripsi: ''
    }
    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    changeToError = (e, value) => {
        this.setState({ isNamaError: value })
    }
    formOnSubmit = (e) => {
        e.preventDefault()
        this.props.Add_Items(this.state.Nama, this.state.Deskripsi)
    }
    render() {
        const {
            Nama,
            isNamaError,
            Deskripsi,
        } = this.state

        const isItemsActionAddLoading = this.props.isItemsActionAddLoading
        const isItemsExist = this.props.isItemsExist

        return (

            <form
                onSubmit={this.formOnSubmit}
            >
                <div
                    style={{ margin: '0 5% 0 5%' }}
                >
                    <TextField
                        label='Nama'
                        type='text'
                        name='Nama'
                        value={Nama}
                        required
                        onInvalid={(e) => this.changeToError(e, true)}
                        onChange={this.onChangeField}
                        style={{ width: '100%', margin: '1% 0 1% 0' }}
                        error={isItemsExist || isNamaError ? true : false}
                        helperText={isItemsExist ? 'Items Sudah Ada' : isNamaError ? 'Nama Harus diisi' : ''}
                    />
                    <TextField
                        label='Deskripsi'
                        type='text'
                        name='Deskripsi'
                        value={Deskripsi}
                        onChange={this.onChangeField}
                        style={{ width: '100%', margin: '1% 0 1% 0' }}
                    />
                </div>
                <center>
                    <Button
                        variant='contained'
                        size='large'
                        style={{ border: '0px solid red', width: "85%", margin: '3% 0 2% 0' }}
                        type='submit'
                        disabled={isItemsActionAddLoading ? true : false}
                    >
                        {isItemsActionAddLoading ? <CircularProgress /> : 'Add Items'}
                    </Button>
                </center>
            </form>
        )
    }
}

const mapStateToProps = state => ({
    isItemsActionAddLoading: state.Setting.isItemsActionAddLoading,
    isItemsExist: state.Setting.isItemsExist,
})

export default connect(mapStateToProps, { Add_Items })(SettingItemsAdd)