import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_List_Rekomendasi } from '../../../../../../../Store/Actions/Job.Action'
import { get_Account_Action_Id } from '../../../../../../../Store/Actions/Account.Action'

import { Collapse, TextField, InputAdornment, Button, Paper } from '@mui/material'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import SearchIcon from '@mui/icons-material/Search'

import GenericModals from '../../../../../../Containers/GenericModals'
import CoTable from '../../../../../../Containers/Table'
import RecomDiajukan from './Modal/RecomDiajukan'
import Detail from './Modal/Detail'

class ListRecom extends React.Component {
    state = {
        isDiajukanOpen: true,
        isSearchOpen: false,
        Search: '',
        Nama: '',
        Registrasi: '',
        UmurMin: '',
        UmurMax: '',
        Pendidikan: '',
        Lokasi: '',
        Instansi: '',
        Jurusan: '',
        MinPengalaman: 0,
    }

    componentDidUpdate(prevProps) {
        if (this.props.JobActionId !== prevProps.JobActionId) {
            this.props.get_List_Rekomendasi(this.props.JobActionId)
        }
    }
    componentDidMount() {
        const { JobActionId } = this.props
        if (JobActionId !== null) {
            this.props.get_List_Rekomendasi(this.props.JobActionId)
        }
    }

    onClickActionId = (Id) => {
        this.props.get_Account_Action_Id(Id)
    }

    handleDiajukanOpen = () => {
        this.setState({ isDiajukanOpen: !this.state.isDiajukanOpen })
    }
    handleSearchOpen = () => {
        this.setState({ isSearchOpen: !this.state.isSearchOpen })
    }
    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    render() {
        // const JobActionId = this.props.JobActionId
        const isListRekomendasiLoading = this.props.isListRekomendasiLoading
        const ListRekomendasi = this.props.ListRekomendasi

        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'No Registrasi',
                width: '10%',
            },
            {
                Nama: 'Nama Kandidat',
                width: '10%',
            },
            {
                Nama: 'Posisi Diajukan',
                width: '10%',
            },
            {
                Nama: 'Status',
                width: '10%',
            },
            {
                Nama: 'Detail Pengajuan',
                width: '10%',
            },
        ]

        const BodyData = ListRekomendasi ? ListRekomendasi.map((item, index) => {
            return {
                index: index + 1,
                Nama: item.Id,
                BiodataNama: item.BiodataNama,
                JobListNama: item.JobListNama,
                AccDepStatus:
                    <span
                        style={{ color: item.AccDepStatus ? item.AccDepStatus === 'Accept' ? 'green' : 'red' : 'orange' }}
                    >
                        {item.AccDepStatus ? item.AccDepStatus : 'Menunggu'}
                    </span>,
                Detail: item.AccDepStatus === null ? (
                    <GenericModals
                        size='xl'
                        header='Detail Rekomendasi'
                        body={<RecomDiajukan />}
                        Buttonvariant='contained'
                        Buttoncolor='primary'
                        ButtononClickeven={() => this.onClickActionId(item.AccountId)}
                        Buttonlabel={'Detail'}
                    />
                ) : <GenericModals
                    size='xl'
                    header='Detail Rekomendasi'
                    body={<Detail />}
                    Buttonvariant='outlined'
                    Buttoncolor='primary'
                    ButtononClickeven={() => this.onClickActionId(item.AccountId)}
                    Buttonlabel={'Detail'}
                />
            }
        }) : []

        const {
            isDiajukanOpen,
            isSearchOpen,
            Search,
            Nama,
            Registrasi,
            UmurMin,
            UmurMax,
            Pendidikan,
            Lokasi,
            Instansi,
            Jurusan,
            MinPengalaman,
        } = this.state

        const RowDataInOnePage = 5

        const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }

        const QueryList = () => {
            return (
                <Fragment>
                    <div
                        style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <TextField
                            variant='outlined'
                            type='text'
                            label='Cari Berdasarkan Nama atau Nomer Registrasi'
                            name='Search'
                            value={Search}
                            style={{ ...st_textfield, ...{ width: '80%' } }}
                            onChange={this.onChangeField}
                            InputProps={{
                                startAdornment:
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>,
                            }}
                        />
                        <Button
                            variant='contained'
                            onClick={this.handleSearchOpen}
                            style={{ width: '10%', margin: '0 0 0 5%', padding: '1%' }}
                        >
                            {isSearchOpen ? <ExpandLess /> : <ExpandMore />}
                        </Button>
                    </div>
                    <Collapse in={isSearchOpen} timeout="auto" unmountOnExit>
                        <Paper
                            style={{ padding: '1%', borderTop: '0px solid black' }}
                        >
                            <TextField
                                variant='outlined'
                                type='text'
                                label='Nama'
                                name='Nama'
                                value={Nama}
                                style={{ ...st_textfield }}
                                onChange={this.onChangeField}
                            />
                            <TextField
                                variant='outlined'
                                type='text'
                                label='Nomer Registrasi'
                                name='Registrasi'
                                value={Registrasi}
                                style={{ ...st_textfield }}
                                onChange={this.onChangeField}
                            />
                            <TextField
                                variant='outlined'
                                type='text'
                                label='Umur Minimal'
                                name='UmurMin'
                                value={UmurMin}
                                style={{ ...st_textfield, ...{ width: '40%' } }}
                                onChange={this.onChangeField}
                            />
                            <TextField
                                variant='outlined'
                                type='text'
                                label='Umur Maksimal'
                                name='UmurMax'
                                value={UmurMax}
                                style={{ ...st_textfield, ...{ width: '40%', marginLeft: '5%' } }}
                                onChange={this.onChangeField}
                            />
                            <TextField
                                variant='outlined'
                                type='text'
                                label='Pendidikan Minimal'
                                name='Pendidikan'
                                value={Pendidikan}
                                style={{ ...st_textfield }}
                                onChange={this.onChangeField}
                            />
                            <TextField
                                variant='outlined'
                                type='text'
                                label='Lokasi'
                                name='Lokasi'
                                value={Lokasi}
                                style={{ ...st_textfield }}
                                onChange={this.onChangeField}
                            />
                            <TextField
                                variant='outlined'
                                type='text'
                                label='Instansi'
                                name='Instansi'
                                value={Instansi}
                                style={{ ...st_textfield }}
                                onChange={this.onChangeField}
                            />
                            <TextField
                                variant='outlined'
                                type='text'
                                label='Jurusan'
                                name='Jurusan'
                                value={Jurusan}
                                style={{ ...st_textfield }}
                                onChange={this.onChangeField}
                            />
                            <TextField
                                variant='outlined'
                                type='text'
                                label='Minimal Pengalaman (Tahun)'
                                name='MinPengalaman'
                                value={MinPengalaman}
                                style={{ ...st_textfield }}
                                onChange={this.onChangeField}
                            />
                            <center>
                                <Button
                                    variant='contained'
                                    style={{ width: '60%', margin: '1% 0 3% 0' }}
                                >
                                    <SearchIcon /> Search
                                </Button>
                            </center>
                        </Paper>
                    </Collapse>
                </Fragment>
            )
        }

        return (
            <Fragment>
                <h2>
                    Rekomendasi
                </h2>
                {false ?
                    <QueryList /> :
                    <Fragment></Fragment>}
                <div
                    style={{ margin: '0 0 5%0' }}
                >
                    <h3
                        onClick={this.handleDiajukanOpen}
                        style={{ display: 'flex', alignContent: 'center', verticalAlign: 'center', cursor: 'pointer' }}
                    >
                        Diajukan{isDiajukanOpen ? <ExpandLess /> : <ExpandMore />}
                    </h3>
                    <Collapse in={isDiajukanOpen} timeout="auto" unmountOnExit>
                        <CoTable
                            TableId={'ACCMPP'}
                            HeadData={HeadData}
                            Data={BodyData}
                            isHeadLoading={isListRekomendasiLoading}
                            isBodyLoading={isListRekomendasiLoading}
                            isPagination={BodyData.length > RowDataInOnePage ? true : false}
                            MaxRowOnPage={RowDataInOnePage}
                            isPaginationLoading={isListRekomendasiLoading}
                            PaginationCounter={
                                BodyData.length / RowDataInOnePage > Math.floor(BodyData.length / RowDataInOnePage) ?
                                    Math.floor(BodyData.length / RowDataInOnePage) + 1 :
                                    BodyData.length / RowDataInOnePage
                            }
                        />
                    </Collapse>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    JobActionId: state.Job.JobActionId,
    isListRekomendasiLoading: state.Job.isListRekomendasiLoading,
    ListRekomendasi: state.Job.ListRekomendasi,
})

export default connect(mapStateToProps, { get_List_Rekomendasi, get_Account_Action_Id })(ListRecom)