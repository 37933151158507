import React, { useEffect } from "react";
import { useParams } from "react-router-dom"; // Impor useParams dari react-router-dom

export default function Workspace() {
  // Gunakan useParams untuk mengambil nilai token dari path URL
  const { token } = useParams();

  useEffect(() => {
    localStorage.setItem("ERecEtana_token", token);
    localStorage.setItem("ERecEtana_isAuth", "true");

    window.location.href = "https://career.id.etanabiotech.com/dashboard";
  }, [token]);

  return <div>Tunggu Sebentar</div>;
}
