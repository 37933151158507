import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Report_MPP, get_Report_Done, get_Report_MPP_Item, get_Report_MPP_Detailed } from '../../../../Store/Actions/Report.Action'
import { Load_Department_List, Load_Level_List, Load_Division_List } from '../../../../Store/Actions/Setting.Action'

import { Tabs, Tab } from '@mui/material'

import MPPApprovalTracker from './MPP/MPPApprovalTracker'
// import MPPExistingOutputRealization from './MPP/MPPExistingOutputRealization'
import MPPOutputRealization from './MPP/MPPOutputRealization'
import MPPOutputDetailed from './MPP/MPPOutputDetailed'
import MPPRecapLevel from './MPP/MPPRecapLevel'
import MPPRecapDevice from './MPP/MPPRecapDevice'

class ReportMPP extends React.Component {
    state = {
        TabValue: 0 // TODO SOME HOW IF TAB VALUE SET INTO 3 ITS WORK
    }

    componentDidMount() {
        this.props.get_Report_MPP()
        this.props.get_Report_Done()
        this.props.get_Report_MPP_Item()
        this.props.Load_Department_List()
        this.props.Load_Level_List()
        this.props.get_Report_MPP_Detailed()
        this.props.Load_Division_List()
    }

    handleTabChange = (e, newTabValue) => {
        this.setState({ TabValue: newTabValue })
    }

    render() {
        const isReportMPPLoading = this.props.isReportMPPLoading
        const ReportMPP = this.props.ReportMPP
        const isReportMPPDoneLoading = this.props.isReportMPPDoneLoading
        const ReportMPPDone = this.props.ReportMPPDone
        const isReportMPPLoadingItem = this.props.isReportMPPLoadingItem
        const ReportMPPItem = this.props.ReportMPPItem
        const DepartmentList = this.props.DepartmentList
        const isDepartmentLoading = this.props.isDepartmentLoading
        const LevelList = this.props.LevelList
        const isLevelLoading = this.props.isLevelLoading
        const isReportMPPDetailedLoading = this.props.isReportMPPDetailedLoading
        const ReportMPPDetailed = this.props.ReportMPPDetailed
        const DivisionList = this.props.DivisionList

        const {
            TabValue
        } = this.state

        const TabData = [
            {
                TabLabel: 'MPP Approval Tracker',
                TabPanel: <MPPApprovalTracker
                    isLoading={isReportMPPLoading}
                    Data={ReportMPP}
                    DepartmentData={DepartmentList}
                    LevelData={LevelList}
                    DivisionData={DivisionList}
                />
            },
            {
                TabLabel: 'MPP Output Realization',
                TabPanel: <MPPOutputRealization
                    isLoading={isReportMPPLoading || isReportMPPDoneLoading || isDepartmentLoading}
                    Data={ReportMPP}
                    DoneData={ReportMPPDone}
                    DepartmentData={DepartmentList}
                    LevelData={LevelList}
                    DivisionData={DivisionList}
                />
            },
            {
                TabLabel: 'MPP Recap Device',
                TabPanel: <MPPRecapDevice
                    isLoading={isReportMPPLoading}
                    Data={ReportMPP}
                    DepartmentData={DepartmentList}
                    LevelData={LevelList}
                    DivisionData={DivisionList}
                />
            },
            // {
            //     TabLabel: 'MPP Recap Base on Level',
            //     TabPanel: <MPPRecapLevel
            //         isLoading={isReportMPPLoading || isDepartmentLoading || isLevelLoading || isReportMPPLoadingItem}
            //         Data={ReportMPP}
            //         Equipment={ReportMPPItem}
            //         DepartmentData={DepartmentList}
            //         LevelData={LevelList}
            //     />
            // }, 
            {
                TabLabel: 'Candidate Journey',
                TabPanel: <MPPOutputDetailed
                    isLoading={isReportMPPDetailedLoading}
                    Data={ReportMPPDetailed}
                />
            },
        ]

        const Data = TabData

        return (
            <Fragment>
                <Tabs
                    value={TabValue}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={TabData.length > 3 ? "scrollable" : null}
                    scrollButtons="auto"
                    style={{ margin: '0 10% 0 10%' }}
                // centered={TabData.length > 6 ? false : true}
                >
                    {Data.map((item, index) => (
                        <Tab
                            style={{ fontSize: '1vw', textTransform: 'none', fontFamily: 'Montserrat, Segoe UI', }}
                            key={`Tabs${index}`}
                            label={item.TabLabel}
                        />
                    ))}
                </Tabs>
                <div
                    style={{ border: '0px solid red', minWidth: '75%', margin: '1% 10% 5% 0' }}
                >
                    {TabValue === 0 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 1 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 2 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 3 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 4 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 5 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 6 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 7 ? TabData[TabValue].TabPanel : null}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isReportMPPLoading: state.Report.isReportMPPLoading,
    ReportMPP: state.Report.ReportMPP,
    isReportMPPDoneLoading: state.Report.isReportMPPDoneLoading,
    ReportMPPDone: state.Report.ReportMPPDone,
    isReportMPPLoadingItem: state.Report.isReportMPPLoadingItem,
    ReportMPPItem: state.Report.ReportMPPItem,
    DepartmentList: state.Setting.DepartmentList,
    DivisionList: state.Setting.DivisionList,
    isDepartmentLoading: state.Setting.isDepartmentLoading,
    LevelList: state.Setting.LevelList,
    isLevelLoading: state.Setting.isLevelLoading,
    isReportMPPDetailedLoading: state.Report.isReportMPPDetailedLoading,
    ReportMPPDetailed: state.Report.ReportMPPDetailed,
})

export default connect(mapStateToProps, { get_Report_MPP, get_Report_Done, get_Report_MPP_Item, Load_Department_List, Load_Level_List, get_Report_MPP_Detailed, Load_Division_List })(ReportMPP)