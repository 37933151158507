import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Job_Data, update_Job_Data } from '../../../../../../../../Store/Actions/Job.Action'
import { Load_Department_List, Load_Level_List, Load_Lokasi_List } from '../../../../../../../../Store/Actions/Setting.Action'
import { Create_Warning_Messages } from '../../../../../../../../Store/Actions/Messages.Actions'


import { Skeleton, TextField, FormControl, InputLabel, Select, Button } from '@mui/material'

import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'

class Edit extends React.Component {
    state = {
        Nama: '',
        Departemen: '',
        Level: '',
        Lokasi: '',
        About: '',
        Status: '',
        Deskripsi: [],
        Persyaratan: [],
    }

    componentDidUpdate(prevProps) {
        if (this.props.JobActionId !== prevProps.JobActionId) {
            this.props.get_Job_Data(this.props.JobActionId)
        }
        if (this.props.JobActionData !== prevProps.JobActionData) {
            const { JobActionData } = this.props
            if (JobActionData) {
                this.setState({
                    Nama: JobActionData.Nama,
                    Departemen: JobActionData.DepartmenId,
                    Level: JobActionData.LevelId,
                    Lokasi: JobActionData.LokasiId,
                    About: JobActionData.About ? JobActionData.About : '',
                    Status: JobActionData.Status,
                })
            }
        }
        if (this.props.JobActionDeskripsi !== prevProps.JobActionDeskripsi) {
            const { JobActionDeskripsi } = this.props
            if (JobActionDeskripsi) {

                const newJobActionDeskripsi = []
                JobActionDeskripsi.forEach((item, index) => {
                    const Deskripsi = item.Deskripsi
                    newJobActionDeskripsi.push(Deskripsi)
                })
                this.setState({
                    Deskripsi: newJobActionDeskripsi
                })
            }
        }
        if (this.props.JobActionPersyaratan !== prevProps.JobActionPersyaratan) {
            const { JobActionPersyaratan } = this.props
            if (JobActionPersyaratan) {
                const newJobActionPersyaratan = []
                JobActionPersyaratan.forEach((item, index) => {
                    const Persyaratan = item.Persyaratan
                    newJobActionPersyaratan.push(Persyaratan)
                })
                this.setState({
                    Persyaratan: newJobActionPersyaratan,
                })
            }
        }
    }
    componentDidMount() {
        const { JobActionId } = this.props
        if (JobActionId !== null) {
            this.props.get_Job_Data(JobActionId)
        }
        this.props.Load_Department_List()
        this.props.Load_Level_List()
        this.props.Load_Lokasi_List()
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    SelectFormOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    AddDeskripsi = () => {
        const Deskripsi = this.state.Deskripsi
        if (Deskripsi.length === 0 || Deskripsi[Deskripsi.length - 1] !== '') {
            const newDeskripsi = ''
            Deskripsi.push(newDeskripsi)
            this.setState({ Deskripsi: Deskripsi })
        } else {
            this.props.Create_Warning_Messages(null, 'mohon lengkapi proses pengisian detail terlebih dahulu')
        }
    }

    onChangeFieldMapDeskripsi = (e, index) => {
        const Deskripsi = this.state.Deskripsi
        Deskripsi[index] = e.target.value

        this.setState({ Deskripsi: Deskripsi })
    }

    DeleteDeskripsi = (Index) => {
        const Deskripsi = this.state.Deskripsi
        Deskripsi.splice(Index, 1)
        this.setState({ Deskripsi: Deskripsi })
    }

    AddPersyaratan = () => {
        const Persyaratan = this.state.Persyaratan
        if (Persyaratan.length === 0 || Persyaratan[Persyaratan.length - 1] !== '') {
            const newPersyaratan = ''
            Persyaratan.push(newPersyaratan)
            this.setState({ Persyaratan: Persyaratan })
        } else {
            this.props.Create_Warning_Messages(null, 'mohon lengkapi proses pengisian detail terlebih dahulu')
        }
    }

    onChangeFieldMapPersyaratan = (e, index) => {
        const Persyaratan = this.state.Persyaratan
        Persyaratan[index] = e.target.value

        this.setState({ Persyaratan: Persyaratan })
    }

    DeletePersyaratan = (Index) => {
        const Persyaratan = this.state.Persyaratan
        Persyaratan.splice(Index, 1)
        this.setState({ Persyaratan: Persyaratan })
    }

    formOnSubmit = (e) => {
        e.preventDefault()
        const { JobActionData } = this.props
        this.props.update_Job_Data(
            this.props.JobActionId,
            JobActionData.JobId,
            this.state.Nama,
            this.state.Departemen,
            this.state.Level,
            this.state.Lokasi,
            this.state.About,
            this.state.Status,
            JobActionData.OpenDate,
            this.state.Deskripsi,
            this.state.Persyaratan,
        )
    }
    render() {
        const OpDepartmentList = this.props.DepartmentList
        const isDepartmentLoading = this.props.isDepartmentLoading
        const OpLevelList = this.props.LevelList
        const isLevelLoading = this.props.isLevelLoading
        const OpLokasiList = this.props.LokasiList
        const isLokasiLoading = this.props.isLokasiLoading

        // const JobActionId = this.props.JobActionId
        const isJobActionDataLoading = this.props.isJobActionDataLoading
        // const JobActionData = this.props.JobActionData
        // const JobActionDeskripsi = this.props.JobActionDeskripsi
        // const JobActionPersyaratan = this.props.JobActionPersyaratan

        const OpStatus = [
            'On Hold',
            'Active',
            'Finished'
        ]

        const {
            Nama,
            Departemen,
            Level,
            Lokasi,
            About,
            Status,
            Deskripsi,
            Persyaratan,
        } = this.state

        const st_textfield = { width: '100%', margin: '1% 0 1% 0' }
        const st_divaddtextfield = { display: 'flex', alignItems: 'center' }
        const st_addtextfield = { width: '95%', margin: '1% 0 1% 0' }
        const st_icon = { color: 'grey', fontSize: '180%', margin: '0 0 0 1%', cursor: 'pointer' }

        return (
            <Fragment>
                {isDepartmentLoading || isLevelLoading || isLokasiLoading || isJobActionDataLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5, 6].map((item) =>
                            <Skeleton
                                key={`Edit_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment>
                    : <Fragment>
                        <form
                            onSubmit={this.formOnSubmit}
                        >
                            <h3>
                                Detail
                            </h3>
                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Nama'
                                name='Nama'
                                required
                                value={Nama}
                                onChange={this.onChangeField}
                            />
                            <FormControl
                                style={st_textfield}
                                variant="filled"
                                required
                            >
                                <InputLabel
                                    shrink
                                >
                                    Departemen
                                </InputLabel>
                                <Select
                                    native
                                    style={{ backgroundColor: 'white' }}
                                    onChange={(e) => this.SelectFormOnChange(e)}
                                    label="Departemen"
                                    name='Departemen'
                                    value={Departemen}
                                >
                                    <option value="" disabled> -- select an option -- </option>
                                    {OpDepartmentList.map((option, index) =>
                                        <option key={`OpDepartmentList${index}`} value={option.Id}>{option.Nama}</option>
                                    )}
                                </Select>
                            </FormControl>
                            <FormControl
                                style={st_textfield}
                                variant="filled"
                                required
                            >
                                <InputLabel
                                    shrink
                                >
                                    Tingkat
                                </InputLabel>
                                <Select
                                    native
                                    style={{ backgroundColor: 'white' }}
                                    onChange={(e) => this.SelectFormOnChange(e)}
                                    label="Tingkat"
                                    name='Level'
                                    value={Level}
                                >
                                    <option value="" disabled> -- select an option -- </option>
                                    {OpLevelList.map((option, index) =>
                                        <option key={`LevelList_${index}`} value={option.Id}>{option.Nama}</option>
                                    )}
                                </Select>
                            </FormControl>
                            <FormControl
                                style={st_textfield}
                                variant="filled"
                                required
                            >
                                <InputLabel
                                    shrink
                                >
                                    Lokasi Kerja
                                </InputLabel>
                                <Select
                                    native
                                    style={{ backgroundColor: 'white' }}
                                    onChange={(e) => this.SelectFormOnChange(e)}
                                    label="Lokasi Kerja"
                                    name='Lokasi'
                                    value={Lokasi}
                                >
                                    <option value="" disabled> -- select an option -- </option>
                                    {OpLokasiList.map((option, index) =>
                                        <option key={`OpLokasiList${index}`} value={option.Id}>{option.Nama}</option>
                                    )}
                                </Select>
                            </FormControl>
                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Job Summary'
                                name='About'
                                value={About}
                                onChange={this.onChangeField}
                            />
                            <h2>
                                Deskripsi Pekerjaan:
                            </h2>
                            {Deskripsi.length === 0 ?
                                <AddCircleRoundedIcon
                                    onClick={this.AddDeskripsi}
                                    style={st_icon}
                                /> :
                                <Fragment></Fragment>}
                            {Deskripsi.map((item, index) =>
                                <div
                                    style={st_divaddtextfield}
                                    key={`Deskripsi_${index}`}
                                >
                                    <TextField
                                        type='text'
                                        label={`Deskripsi ${index + 1}`}
                                        name='Deskripsi'
                                        required
                                        value={Deskripsi[index]}
                                        onChange={(e) => this.onChangeFieldMapDeskripsi(e, index)}
                                        style={st_addtextfield}
                                    />
                                    <DeleteOutlineRoundedIcon
                                        style={st_icon}
                                        onClick={(e) => this.DeleteDeskripsi(index)}
                                    />
                                    <AddCircleRoundedIcon
                                        onClick={this.AddDeskripsi}
                                        style={st_icon}
                                    />
                                    <br />
                                </div>
                            )}

                            <h2>
                                Persyaratan:
                            </h2>
                            {Persyaratan.length === 0 ?
                                <AddCircleRoundedIcon
                                    style={st_icon}
                                    onClick={(e) => this.AddPersyaratan()}
                                /> :
                                <Fragment></Fragment>}
                            {Persyaratan.map((item, index) =>
                                <div
                                    style={st_divaddtextfield}
                                    key={`Persyaratan${index}`}
                                >
                                    <TextField
                                        type='text'
                                        label={`Persyaratan ${index + 1}`}
                                        name='Persyaratan'
                                        required
                                        value={Persyaratan[index]}
                                        onChange={(e) => this.onChangeFieldMapPersyaratan(e, index)}
                                        style={st_addtextfield}
                                    />
                                    <DeleteOutlineRoundedIcon
                                        style={st_icon}
                                        onClick={(e) => this.DeletePersyaratan(index)}
                                    />
                                    <AddCircleRoundedIcon
                                        style={st_icon}
                                        onClick={(e) => this.AddPersyaratan()}
                                    />
                                    <br />
                                </div>
                            )}
                            <h3>
                                Setting
                            </h3>
                            <FormControl
                                style={st_textfield}
                                variant="filled"
                                required
                            >
                                <InputLabel
                                    shrink
                                >
                                    Status
                                </InputLabel>
                                <Select
                                    native
                                    style={{ backgroundColor: 'white' }}
                                    onChange={(e) => this.SelectFormOnChange(e)}
                                    label="Status"
                                    name='Status'
                                    value={Status}
                                >
                                    <option value="" disabled> -- select an option -- </option>
                                    {OpStatus.map((option, index) =>
                                        <option key={`OpStatus${index}`} value={option}>{option}</option>
                                    )}
                                </Select>
                            </FormControl>
                            <center>
                                <Button
                                    variant='contained'
                                    style={{ width: '60%', margin: '2% 0 5% 0' }}
                                    type='submit'
                                >
                                    Save
                                </Button>
                            </center>
                        </form>
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    DepartmentList: state.Setting.DepartmentList,
    isDepartmentLoading: state.Setting.isDepartmentLoading,
    LevelList: state.Setting.LevelList,
    isLevelLoading: state.Setting.isLevelLoading,
    LokasiList: state.Setting.LokasiList,
    isLokasiLoading: state.Setting.isLokasiLoading,
    JobActionId: state.Job.JobActionId,
    isJobActionDataLoading: state.Job.isJobActionDataLoading,
    JobActionData: state.Job.JobActionData,
    JobActionDeskripsi: state.Job.JobActionDeskripsi,
    JobActionPersyaratan: state.Job.JobActionPersyaratan,
})

export default connect(mapStateToProps, { get_Job_Data, Load_Department_List, Load_Level_List, Load_Lokasi_List, update_Job_Data, Create_Warning_Messages })(Edit)