import React, { Fragment } from 'react'

import { connect } from 'react-redux'
import { Update_Account_Password } from '../../../../../Store/Actions/Account.Action'

import { TextField, Button, InputAdornment } from '@mui/material'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

class Account extends React.Component {
    state = {
        Password: '',
        newPassword: '',
        newnewPassword: '',
        isShowPassword: false,
        isShowNewPassword: false,
        isShowNewNewPassword: false,
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onShowPassword = () => {
        this.setState({ isShowPassword: !this.state.isShowPassword })
    }
    onShowNewPassword = () => {
        this.setState({ isShowNewPassword: !this.state.isShowNewPassword })
    }

    onShowNewNewPassword = () => {
        this.setState({ isShowNewNewPassword: !this.state.isShowNewNewPassword })
    }

    formOnSubmit = (e) => {
        e.preventDefault()

        const BiodataData = this.props.Biodata.Data

        const isPasswordOk = this.state.newPassword.length >= 6 && /[a-z]/.test(this.state.newPassword) && /[A-Z]/.test(this.state.newPassword) ? true : false
        if (this.state.newPassword === this.state.newnewPassword && isPasswordOk) {
            this.props.Update_Account_Password(
                BiodataData.BiodataId ? BiodataData.BiodataId : BiodataData.Id ? BiodataData.Id : '',
                this.state.Password,
                this.state.newPassword,
                this.state.newnewPassword,
            )
        }

    }
    render() {
        // const isAccountLoading = this.props.isAccountLoading

        const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }
        // const st_divaddtextfield = { display: 'flex', alignItems: 'center', margin: '0 0 2% 2%' }
        // const st_TextSmall = { width: '20%', margin: '0 1% 0 0' }
        // const st_icon = { color: 'grey', fontSize: '180%', margin: '0 0 0 1%', cursor: 'pointer' }

        const {
            Password,
            newPassword,
            newnewPassword,
            isShowPassword,
            isShowNewPassword,
            isShowNewNewPassword,
        } = this.state

        return (
            <Fragment>
                <form
                    onSubmit={this.formOnSubmit}
                >
                    <div
                        style={{ margin: '5% 0 5% 0' }}
                    >

                        <TextField
                            style={st_textfield}
                            variant='outlined'
                            type={isShowPassword ? 'text' : 'password'}
                            label='Old Password'
                            name='Password'
                            required
                            value={Password}
                            onChange={this.onChangeField}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment
                                        position="end"
                                        style={{ color: '#00a3df', cursor: 'pointer', fontSize: '2vw' }}
                                        onClick={() => this.onShowPassword()}
                                    >
                                        {isShowPassword ?
                                            <VisibilityIcon />
                                            : <VisibilityOffIcon />
                                        }
                                    </InputAdornment>,
                                style: { fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }
                            }}
                        />

                        <TextField
                            style={st_textfield}
                            variant='outlined'
                            type={isShowNewPassword ? 'text' : 'password'}
                            label='New Password'
                            name='newPassword'
                            required
                            value={newPassword}
                            onChange={this.onChangeField}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment
                                        position="end"
                                        style={{ color: '#00a3df', cursor: 'pointer', fontSize: '2vw' }}
                                        onClick={() => this.onShowNewPassword()}
                                    >
                                        {isShowNewPassword ?
                                            <VisibilityIcon />
                                            : <VisibilityOffIcon />
                                        }
                                    </InputAdornment>,
                                style: { fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }
                            }}
                        />
                        <div
                            style={{ margin: '0 0 0 1%', display: newPassword.length > 0 ? 'block' : 'none', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                        >
                            <span style={{ color: newPassword.length >= 6 ? 'green' : 'red' }}>
                                {newPassword.length >= 6 ? '✔ Password More Then 6 Character' : '✖ Password More Then 6 Character'}
                            </span> <br />
                            <span style={{ color: /[a-z]/.test(newPassword) ? 'green' : 'red' }}>
                                {/[a-z]/.test(newPassword) ? '✔ Password Has 1 or More Lowercase Character' : '✖ Password Has 1 or More Lowercase Character'}
                            </span> <br />
                            <span style={{ color: /[A-Z]/.test(newPassword) ? 'green' : 'red' }}>
                                {/[A-Z]/.test(newPassword) ? '✔ Password Has 1 or More Uppercase Character' : '✖ Password Has 1 or More Uppercase Character'}
                            </span> <br />
                        </div>

                        <TextField
                            style={st_textfield}
                            variant='outlined'
                            type={isShowNewNewPassword ? 'text' : 'password'}
                            label='Confirm a New Password'
                            name='newnewPassword'
                            required
                            value={newnewPassword}
                            onChange={this.onChangeField}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment
                                        position="end"
                                        style={{ color: '#00a3df', cursor: 'pointer', fontSize: '2vw' }}
                                        onClick={() => this.onShowNewNewPassword()}
                                    >
                                        {isShowNewNewPassword ?
                                            <VisibilityIcon />
                                            : <VisibilityOffIcon />
                                        }
                                    </InputAdornment>,
                                style: { fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }
                            }}
                        />
                        <div
                            style={{ margin: '0 0 0 1%', display: newnewPassword.length > 0 ? 'block' : 'none' }}
                        >
                            <span style={{ color: newnewPassword === newPassword ? 'green' : 'red', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}>
                                {newnewPassword === newPassword ? '✔ Password and Confirm Password must be the same' : '✖ Password and Confirm Password must be the same'}
                            </span> <br />
                        </div>


                    </div >
                    <center>
                        <Button
                            variant='contained'
                            style={{ border: '0px solid red', width: "60%", margin: '3vh 0 2vh 0', backgroundColor: '#00a3df', textTransform: 'none', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                            // disabled={(Password && (newPassword === newnewPassword)) ? false : true}
                            type='submit'
                        >
                            Save
                        </Button>
                    </center>
                </form>
            </Fragment >
        )
    }

}

const mapStateToProps = state => ({
    Biodata: state.Account.Biodata,
    // isAccountLoading: state.Account.isAccountLoading,
    // Account: state.Account.Account,
})

export default connect(mapStateToProps, { Update_Account_Password })(Account)