import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Report_HR } from '../../../../Store/Actions/Report.Action'
import { Load_Department_List } from '../../../../Store/Actions/Setting.Action'

import { Tabs, Tab } from '@mui/material'

import LeadTime from './HR/LeadTime'
import CandidateJourney from './HR/CandidateJourney'
import SelectionProcess from './HR/SelectionProcess'

class HR extends React.Component {
    state = {
        TabValue: 0
    }

    componentDidMount() {
        this.props.get_Report_HR()
        this.props.Load_Department_List() 
    }

    handleTabChange = (e, newTabValue) => {
        this.setState({ TabValue: newTabValue })
    }

    render() {
        const isReportHRLoading = this.props.isReportHRLoading
        const ReportHR = this.props.ReportHR
        const isDepartmentLoading = this.props.isDepartmentLoading
        const DepartmentList = this.props.DepartmentList

        const {
            TabValue
        } = this.state

        const TabData = [
            {
                TabLabel: 'Lead Time',
                TabPanel: <LeadTime
                    isLoading={isReportHRLoading}
                    Data={ReportHR}
                />
            },
            {
                TabLabel: 'Candidate Journey',
                TabPanel: <CandidateJourney
                    isLoading={isReportHRLoading}
                    Data={ReportHR}
                />
            },
            {
                TabLabel: 'Selection Process',
                TabPanel: <SelectionProcess
                    isLoading={isReportHRLoading || isDepartmentLoading}
                    Data={ReportHR}
                    DepartmentData={DepartmentList}
                />
            },
        ]

        const Data = TabData

        return (
            <Fragment>
                <Tabs
                    value={TabValue}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={TabData.length > 3 ? "scrollable" : null}
                    scrollButtons="auto"
                    style={{ margin: '0 10% 0 10%' }}
                // centered={TabData.length > 6 ? false : true}
                >
                    {Data.map((item, index) => (
                        <Tab
                            style={{ fontSize: '1vw', textTransform: 'none', fontFamily: 'Montserrat, Segoe UI', }}
                            key={`Tabs${index}`}
                            label={item.TabLabel}
                        />
                    ))}
                </Tabs>
                <div
                    style={{ border: '0px solid red', minWidth: '75%', margin: '1% 10% 5% 0' }}
                >
                    {TabValue === 0 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 1 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 2 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 3 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 4 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 5 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 6 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 7 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 8 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 9 ? TabData[TabValue].TabPanel : null}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isReportHRLoading: state.Report.isReportHRLoading,
    ReportHR: state.Report.ReportHR,
    DepartmentList: state.Setting.DepartmentList,
    isDepartmentLoading: state.Setting.isDepartmentLoading,
})

export default connect(mapStateToProps, { get_Report_HR, Load_Department_List })(HR)