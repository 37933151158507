// import React from "react"

// import { List, Divider, Container, Grid } from '@mui/material'

// import Logo from '../../../Img/Etana_logo.png'
// import InstagramLogo from '../../../Img/Instagram-Logo.wine.svg'
// import LinkedInLogo from '../../../Img/LinkedIn-Icon-Logo.wine.svg'

// // import FacebookIcon from '@mui/icons-material/Facebook'
// // import TwitterIcon from '@mui/icons-material/Twitter'
// import LinkedInIcon from '@mui/icons-material/LinkedIn'
// import InstagramIcon from '@mui/icons-material/Instagram'
// import { height } from "@mui/system"

// const Footer = () => {
//     // const FacebookLink = 'https://facebook.com/'
//     // const TwitterLink = 'https://twitter.com/login'
//     const LinkedInLink = 'https://id.linkedin.com/company/pt-etana-biotechnologies-indonesia'
//     const InstagramLink = 'https://www.instagram.com/etanabiotech.id/'
//     const goToLink = (e, LinkLocation) => {
//         window.location.href = LinkLocation
//     }
//     return (
//         <footer
//             style={{ border: '0px solid blue', bottom: '0px', left: '0px', position: 'relative', marginTop: 'auto' }}
//         >
//             <List
//                 style={{ border: '0px solid red', bottom: '0px', position: '' }}
//             >
//                 <Divider />
//                 <Container>
//                     <div
//                         style={{ padding: '8vh 0 8vh 0' }}
//                     >
//                         <Grid
//                             container
//                             direction="row"
//                             justifyContent="center"
//                             alignItems="flex-start"
//                         >
//                             <div
//                                 style={{ height: '20vh', width: '20vw', border: '0px solid red', padding: '1%', margin: '0 10% 0 0' }}
//                             >
//                                 <img
//                                     src={Logo}
//                                     style={{ height: '100%', width: '100%', padding: '1% 1% 1% 2%', border: '0px solid red', cursor: 'pointer' }}
//                                     alt="Logo"
//                                 />
//                             </div>
//                             <div
//                                 style={{ width: '20vw', border: '0px solid red', padding: '1%' }}
//                             >
//                                 <h2>
//                                     Contact Us
//                                 </h2>
//                                 <p>
//                                     Jl. Rw. Gelam V Blok L Kav. 11-13, RW.9, Rw. Terate, Kec. Jatinegara, RW.9, Kel, Cakung, East Jakarta City, Jakarta 13930
//                                 </p>
//                                 <p>
//                                     (021) 4608808
//                                 </p>
//                             </div>
//                             <div
//                                 style={{ width: '20vw', border: '0px solid red', padding: '1%', margin: '0 0 0 10%' }}
//                             >
//                                 <h2>
//                                     Follow Us
//                                 </h2>
//                                 <center>
//                                     {/* <FacebookIcon
//                                         onClick={(e) => goToLink(e, FacebookLink)}
//                                         style={{ fontSize: '52px', color: 'black', padding: '0 2% 0 2%', cursor: 'pointer' }}
//                                     /> */}
//                                     {/* <TwitterIcon
//                                         onClick={(e) => goToLink(e, TwitterLink)}
//                                         style={{ fontSize: '52px', color: 'black', padding: '0 2% 0 2%', cursor: 'pointer' }}
//                                     /> */}
//                                     <LinkedInIcon
//                                         onClick={(e) => goToLink(e, LinkedInLink)}
//                                         style={{ fontSize: '52px', color: 'black', padding: '0 2% 0 2%', cursor: 'pointer' }}
//                                     />
//                                     <InstagramIcon
//                                         onClick={(e) => goToLink(e, InstagramLink)}
//                                         style={{ fontSize: '52px', color: 'black', padding: '0 2% 0 2%', cursor: 'pointer' }}
//                                     />
//                                 </center>
//                             </div>
//                         </Grid>
//                     </div>
//                     <Divider />
//                     <div
//                         style={{ bottom: '0px', position: '', border: '0px solid red' }}
//                     >
//                         <p
//                             style={{ textAlign: 'center', fontWeight: 'lighter', }}
//                         >
//                             © 2021-2022 Etana Biotechnologies.
//                         </p>
//                     </div>
//                 </Container>
//             </List>
//         </footer >
//     )
// }

import React from "react"

import InstagramLogo from '../../../Img/InstagramIcon.svg'
import LinkedInLogo from '../../../Img/LinkedInIcon.svg'

const Footer = () => {
    const EtanaLink = 'https://www.id.etanabiotech.com'
    const LinkedInLink = 'https://id.linkedin.com/company/pt-etana-biotechnologies-indonesia'
    const InstagramLink = 'https://www.instagram.com/etanabiotech.id'
    const goToLink = (e, LinkLocation) => {
        // window.location.href = LinkLocation
        window.open(
            `${LinkLocation}`,
            '_blank'
        )
    }
    return (
        <footer
            style={{ backgroundImage: 'linear-gradient(to Left, #90e0ef, #00a3df', border: '0px solid red', bottom: '0px', left: '0px', height: '8vh', margin: ' 5vh 0 0 0', padding: '1vh 1vw 1vh 1vw' }}
        >
            <div
                style={{ display: 'flex', alignContent: 'center', justifyContent: 'start', border: '0px solid red', height: '6vh', marginTop: '2vh' }}
            >
                <div
                    style={{ color: 'white', height: '6vh', margin:'0 25vw 0 2vw', paddingTop: '1vh', fontFamily: 'Montserrat, Segoe UI', cursor: 'pointer', fontSize: '1.2vw' }}
                    onClick={(e) => goToLink(e, EtanaLink)}
                >
                    © 2022, <b>PT. Etana Biotechnologies Indonesia</b>
                </div>
                <div
                    style={{ border: '0px solid red', width: '3vw', height: '6vh' }}
                >
                    <img
                        style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                        alt='LinkedInLogo'
                        src={LinkedInLogo}
                        onClick={(e) => goToLink(e, LinkedInLink)}
                    />
                </div>
                <div
                    style={{ border: '0px solid red', width: '3vw', height: '6vh' }}
                >
                    <img
                        style={{ width: '100%', height: '100%', cursor: 'pointer', border: '0px solid red' }}
                        alt='InstagramLogo'
                        src={InstagramLogo}
                        onClick={(e) => goToLink(e, InstagramLink)}
                    />
                </div>
            </div>
        </footer >
    )
}

export default Footer