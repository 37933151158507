import React, { Fragment } from 'react'

import { Paper, Typography, Modal, Button, Alert, AlertTitle } from '@mui/material'

const GenericModals = (props) => {
    const modalsize = (props.size === 'xl') ? 90 :
        (props.size === 'l') ? 80 :
            (props.size === 'm') ? 60 : 40

    const [isModalOpen, setOpen] = React.useState(false)

    const handleOpen = () => {
        if (props.ButtononClickevent !== null) {
            props.ButtononClickeven()
        }
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleEsc = (event) => {
        if (event.keyCode === 27) {
            handleClose()
        }
    }

    const header = (
        <div
            style={{ border: '0px solid red', width: props.uncontrollsize === true ? '140vw' : '100%' }}
        >
            <Typography variant='h6' align='right' onClick={handleClose} style={{ cursor: 'pointer', fontSize: '2vw' }} >
                X
            </Typography>
            {props.header ?
                <Typography variant='h4' align='left' style={{ fontSize: '2vw', fontFamily: 'Montserrat, Segoe UI' }}>
                    {props.header}
                </Typography> :
                <h1>HalamanTidakDitemukan</h1>
            }
            <hr />
        </div>
    )

    const massages = (
        <Fragment>
            {props.massages ? (
                <Alert
                    variant="filled"
                    severity={props.massagestype ?
                        props.massagestype
                        : 'warning'
                    }
                >
                    <AlertTitle>
                        {props.massagestype ?
                            props.massagestype
                            : 'Warning'
                        }
                    </AlertTitle>
                    {props.massages}
                </Alert>
            ) : null
            }
        </Fragment>
    )

    const body = (
        <Fragment>
            {props.body ?
                props.body :
                <h1>HalamanTidakDitemukan</h1>
            }
        </Fragment>
    )

    const footer = (
        <Fragment>
            {props.footer ?
                <Fragment>
                    <hr />
                    {props.footer}
                </Fragment> :
                null
            }
        </Fragment>
    )

    return (
        <Fragment>
            <Button
                type={props.Buttontype ? props.Buttontype : null}
                disabled={props.Buttondisabled ? props.Buttondisabled : false}
                variant={props.Buttonvariant ? props.Buttonvariant : "outlined"}
                style={props.Buttonstyle ? props.Buttonstyle : null}
                color={props.Buttoncolor ? props.Buttoncolor : ''}
                size={props.Buttonsize ? props.Buttonsize : 'medium'}
                onClick={handleOpen}
            >
                {props.Buttonlabel ? props.Buttonlabel : 'Open Modal'}
            </Button>
            <Modal
                open={isModalOpen}
                // onClose={handleClose} // TODO SOME USER DIDN'T USE AND HAVE PROBLEM
                onKeyDown={(e) => handleEsc(e)}
                style={{
                    overflow: 'auto',
                    display: 'block',
                }}
            >
                <Paper
                    style={{
                        position: 'absolute',
                        // width: `${modalsize}%`,
                        width: props.uncontrollsize === 'calc(80% - 1px)' ? null : `${modalsize}%`,
                        minWidth: props.uncontrollsize === true ? null : `${modalsize}%`,
                        height: 'calc(90% - 1px)',
                        // backgroundColor: theme.palette.background.paper,
                        border: '2px solid #000',
                        // boxShadow: theme.shadows[5],
                        // padding: theme.spacing(2, 4, 3),
                        padding: '1% 2% 1% 2%',
                        marginLeft: `${(100 - modalsize) / 2}%`,
                        marginRight: `${(100 - modalsize) / 2}%`,
                        marginTop: 30,
                        marginBottom: 30,
                        display: 'block',
                        overflow: 'auto',
                    }}
                >
                    {header}
                    {massages}
                    {body}
                    {footer}
                </Paper>
            </Modal>
        </Fragment >
    )
}

export default GenericModals