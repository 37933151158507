import React from 'react'

import { connect } from 'react-redux'
import { CreateAnAccount } from '../../../Store/Actions/Account.Action'

import { Redirect } from 'react-router-dom'

import { Grid, Paper, TextField, Button, CircularProgress, InputAdornment } from '@mui/material'

import Logo from '../../../Img/Etana_logo.png'

import { CharLimit } from '../../../Global'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

class CreateAccount extends React.Component {
    state = {
        Email: '',
        isNotEmail: false,
        UserName: '',
        Password: '',
        Password1: '',
        isShowPassword: false,
        isShowPassword1: false,
        isGoToLogin: false,
    }
    changeEmailError = (e, value) => {
        this.setState({ isNotEmail: value })
    }
    onShowPassword = () => {
        this.setState({ isShowPassword: !this.state.isShowPassword })
    }
    onShowPassword1 = () => {
        this.setState({ isShowPassword1: !this.state.isShowPassword1 })
    }
    goToLogin = () => {
        this.setState({ isGoToLogin: !this.state.isGoToLogin })
    }
    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    formOnSubmit = (e) => {
        e.preventDefault()
        const isPasswordOk = this.state.Password.length >= 6 && /[a-z]/.test(this.state.Password) && /[A-Z]/.test(this.state.Password) ? true : false
        if (this.state.Password === this.state.Password1 && isPasswordOk) {
            this.props.CreateAnAccount(this.state.Email, this.state.UserName, this.state.Password, this.props.isOneStep)
        } else {

        }
    }
    render() {
        const isOneStep = this.props.isOneStep

        if (this.props.isAuth && this.props.Token) {
            if (!isOneStep) {
                return <Redirect to="/" />
            }
        }

        const isMobileView = this.props.isMobileView

        const {
            Email,
            isNotEmail,
            UserName,
            Password,
            Password1,
            isShowPassword,
            isShowPassword1,
            isGoToLogin,
        } = this.state

        const isCreateAccountLoading = this.props.isCreateAccountLoading
        const isCreateAccountEmailError = this.props.isCreateAccountEmailError
        const isCreateAccountUsernameError = this.props.isCreateAccountUsernameError

        return (
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                {isGoToLogin ? <Redirect to="/login" /> : <div></div>}
                <Paper
                    style={{ width: isMobileView ? isOneStep ? '93%' : '70%' : isOneStep ? '50%' : '30%', margin: '2% 0 2% 0', border: '0px solid red', padding: '2vh 3vw 2vh 3vw', fontFamily: 'Montserrat, Segoe UI', fontSize: isMobileView ? '16px' : '1.2vw' }}
                >
                    <h1>
                        Create Account
                    </h1>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >

                        <img
                            src={Logo}
                            style={{ height: '30%', width: '30%', padding: '1% 1% 1% 1%', margin: '2% 0 5% 0', border: '0px solid red' }}
                            alt="Logo"
                        />
                    </Grid>
                    <form
                        onSubmit={this.formOnSubmit}
                    >
                        <div
                            style={{ margin: '0 5% 0 5%' }}
                        >
                            <label>
                                <b>
                                    Email
                                </b>
                            </label><br />
                            <TextField
                                type='email'
                                name='Email'
                                size='small'
                                placeholder='example@mail.com'
                                value={Email}
                                onInvalid={(e) => this.changeEmailError(e, true)}
                                onChange={this.onChangeField}
                                style={{ width: '100%', margin: '1% 0 1% 0' }}
                                InputProps={{
                                    style: { fontFamily: 'Montserrat, Segoe UI', fontSize: isMobileView ? '16px' : '1.2vw' }
                                }}
                                error={isCreateAccountEmailError || isNotEmail ? true : false || Email.length > CharLimit.Account.Email}
                                helperText={isCreateAccountEmailError ? 'Email Sudah Digunakan, silahkan gunakan email lain' : isNotEmail ? 'Format Email Salah' : Email.length > CharLimit.Account.Email ? `Char Limit ${CharLimit.Account.Email}` : ''}
                            /><br />
                            <label>
                                <b>
                                    User Name
                                </b>
                            </label><br />
                            <TextField
                                type='text'
                                name='UserName'
                                size='small'
                                value={UserName}
                                placeholder='username'
                                onChange={this.onChangeField}
                                style={{ width: '100%', margin: '1% 0 1% 0' }}
                                InputProps={{
                                    style: { fontFamily: 'Montserrat, Segoe UI', fontSize: isMobileView ? '16px' : '1.2vw' }
                                }}
                                error={isCreateAccountUsernameError || UserName.length > CharLimit.Account.UserName ? true : false}
                                helperText={isCreateAccountUsernameError ? 'User Name Sudah Digunakan, silahkan gunakan user name lain' : UserName.length > CharLimit.Account.UserName ? `Char Limit ${CharLimit.Account.UserName}` : ''}
                            /><br />
                            <label>
                                <b>
                                    Password
                                </b>
                            </label><br />
                            <TextField
                                type={isShowPassword ? 'text' : 'password'}
                                name='Password'
                                size='small'
                                value={Password}
                                placeholder='********'
                                onChange={this.onChangeField}
                                style={{ width: '100%', margin: '1% 0 1% 0' }}
                                // error={((Password.length <= 6 || !/[a-z]/.test(Password) || !/[A-Z]/.test(Password) ) && Password.length < 1 ? true : false)}
                                // error={(Password !== Password1 ? true : Password.length < 5 && Password.length !== 0 ? true : false)}
                                // helperText={Password !== Password1 ? 'Password dan Confirm Password harus sama' : Password.length < 5 && Password.length !== 0 ? 'Minimal 5 char' : ''}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment
                                            position="end"
                                            style={{ color: '#00a3df', cursor: 'pointer', fontSize: isMobileView ? '16px' : '2vw' }}
                                            onClick={() => this.onShowPassword()}
                                        >
                                            {isShowPassword ?
                                                <VisibilityIcon />
                                                : <VisibilityOffIcon />
                                            }
                                        </InputAdornment>,
                                    style: { fontFamily: 'Montserrat, Segoe UI', fontSize: isMobileView ? '16px' : '1.2vw' }
                                }}
                            /><br />
                            <div
                                style={{ margin: '0 0 0 5%', display: Password.length > 0 ? 'block' : 'none' }}
                            >
                                <span style={{ color: Password.length >= 6 ? 'green' : 'red' }}>
                                    {Password.length >= 6 ? '✔ Password More Then 6 Character' : '✖ Password More Then 6 Character'}
                                </span> <br />
                                <span style={{ color: /[a-z]/.test(Password) ? 'green' : 'red' }}>
                                    {/[a-z]/.test(Password) ? '✔ Password Has 1 or More Lowercase Character' : '✖ Password Has 1 or More Lowercase Character'}
                                </span> <br />
                                <span style={{ color: /[A-Z]/.test(Password) ? 'green' : 'red' }}>
                                    {/[A-Z]/.test(Password) ? '✔ Password Has 1 or More Uppercase Character' : '✖ Password Has 1 or More Uppercase Character'}
                                </span> <br />
                                {/* <span style={{ color: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(Password) ? 'green' : 'red' }}>
                                    {/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(Password) ? '✔ Password Has 1 or More Special  Character' : '✖ Password Has 1 or More Special  Character'}
                                </span> <br /> */}
                                {/* <span style={{ color: /[0-9]/.test(Password) ? 'green' : 'red' }}>
                                    {/[0-9]/.test(Password) ? '✔ Password Has 1 or More Number Character' : '✖ Password Has 1 or More Number Character'}
                                </span> <br /> */}
                            </div>
                            <label>
                                <b>
                                    Confirm a Password
                                </b>
                            </label><br />
                            <TextField
                                type={isShowPassword1 ? 'text' : 'password'}
                                name='Password1'
                                size='small'
                                value={Password1}
                                placeholder='********'
                                onChange={this.onChangeField}
                                style={{ width: '100%', margin: '1% 0 1% 0' }}
                                error={Password1 !== Password && Password1.length !== 0 ? true : false}
                                // error={Password !== Password1 ? true : false}
                                // helperText={Password !== Password1 ? 'Password dan Confirm Password harus sama' : ''}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment
                                            position="end"
                                            style={{ color: '#00a3df', cursor: 'pointer', fontSize: isMobileView ? '16px' : '2vw' }}
                                            onClick={() => this.onShowPassword1()}
                                        >
                                            {isShowPassword1 ?
                                                <VisibilityIcon />
                                                : <VisibilityOffIcon />
                                            }
                                        </InputAdornment>,
                                    style: { fontFamily: 'Montserrat, Segoe UI', fontSize: isMobileView ? '16px' : '1.2vw' }
                                }}
                            /><br />
                            <div
                                style={{ margin: '0 0 0 5%', display: Password1.length > 0 ? 'block' : 'none' }}
                            >
                                <span style={{ color: Password1 === Password ? 'green' : 'red' }}>
                                    {Password1 === Password ? '✔ Password and Confirm Password must be the same' : '✖ Password and Confirm Password must be the same'}
                                </span> <br />
                            </div>
                        </div>
                        <center>
                            <Button
                                variant='contained'
                                size='large'
                                style={{ border: '0px solid red', width: "85%", margin: '3vh 0 2vh 0', backgroundColor: '#00a3df', textTransform: 'none', fontFamily: 'Montserrat, Segoe UI', fontSize: isMobileView ? '16px' : '1.2vw' }}
                                type='submit'
                                disabled={isCreateAccountLoading ? true : false}
                            >
                                {isCreateAccountLoading ? <CircularProgress /> : 'Register'}
                            </Button>
                        </center>
                    </form>
                    <div
                        style={{ margin: '0 5% 0 5%', alignItems: 'center', textAlign: 'center', color: '#7e7e7e', }}
                    >
                        <p>
                            Already have an account? <span
                                style={{ color: '#00a3df', cursor: 'pointer' }}
                                onClick={this.goToLogin}
                            >
                                Sign in
                            </span>
                        </p>
                    </div>
                </Paper>
            </Grid >
        )
    }
}

const mapStateToProps = state => ({
    isAuth: state.Auth.isAuth,
    Token: state.Auth.Token,
    isCreateAccountLoading: state.Account.isCreateAccountLoading,
    isCreateAccountEmailError: state.Account.isCreateAccountEmailError,
    isCreateAccountUsernameError: state.Account.isCreateAccountUsernameError,
})

export default connect(mapStateToProps, { CreateAnAccount })(CreateAccount)