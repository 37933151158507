import axios from "axios";
import * as XLSX from "xlsx";
import React, { Fragment, useEffect } from "react";
import { Get_IpAddres } from "../../../../../Store/Actions/Base.Action";
import { Create_Warning_Messages } from "../../../../../Store/Actions/Messages.Actions";
import { useDispatch } from "react-redux";
import {
    Button,
    FormControl,
    InputLabel,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import { VariableLimit } from "../../../../../Global";
const MPPRecapDevice = ({
    isLoading,
    Data,
    DepartmentData,
    LevelData,
    DivisionData,
}) => {
    const dispatch = useDispatch();
    const [ActiveYear, setActiveYear] = React.useState(
        Number(new Date().getFullYear())
    );
    const [StartMonth, setStartMonth] = React.useState(0);
    const [EndMonth, setEndMonth] = React.useState(11);
    const [MPPDevice, setMPPDevice] = React.useState("");

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("ERecEtana_token");
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": token,
                },
            };
            try {
                const data = {
                    start: StartMonth,
                    end: EndMonth,
                    year: ActiveYear,
                };
                const response = await axios.post(
                    Get_IpAddres() + "/report/mpp/newdevice",
                    data,
                    config
                );
                const DataAPI = response.data.Data;
                const combinedData = DivisionData.map((division) => {
                    const { Nama, IdDepartemen } = division;
                    const departmentIds = IdDepartemen.split(",").map((id) => id.trim());

                    const divisionObj = {
                        NamaDivisi: Nama,
                        Departemen: [],
                    };
                    DepartmentData.forEach((department) => {
                        if (departmentIds.includes(department.Id)) {
                            divisionObj.Departemen.push({
                                NamaDepartemen: department.Nama,
                                IdDepartemen: department.Id,
                                Level: LevelData.map((level) => {
                                    return {
                                        NamaLevel: level.Nama,
                                        Email: 0,
                                        Laptop: 0,
                                        Tablet: 0,
                                        PC: 0,
                                    };
                                }),
                            });
                        }
                    });
                    return divisionObj;
                });

                const combinedDataWithAPI = combinedData.map((division) => {
                    const updatedDepartments = division.Departemen.map((dept) => {
                        const updatedLevels = dept.Level.map((level) => {
                            const matchingAPI = DataAPI.find(
                                (apiItem) =>
                                    apiItem.divisi === division.NamaDivisi &&
                                    apiItem.departemen === dept.NamaDepartemen &&
                                    apiItem.level === level.NamaLevel
                            );

                            if (matchingAPI) {
                                // Update nilai Email, Laptop, Tablet, dan PC
                                level.Email = matchingAPI.email;
                                level.Laptop = matchingAPI.laptop;
                                level.Tablet = matchingAPI.tablet;
                                level.PC = matchingAPI.pc;
                            }

                            return level;
                        });

                        return { ...dept, Level: updatedLevels };
                    });

                    return { ...division, Departemen: updatedDepartments };
                });
                setMPPDevice(combinedDataWithAPI);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        DivisionData,
        DepartmentData,
        LevelData,
        ActiveYear,
        StartMonth,
        EndMonth,
    ]);

    const changeYear = (newValue) => {
        setActiveYear(newValue);
    };

    const changeStartMonth = (newValue) => {
        if (Number(newValue) > Number(EndMonth)) {
            dispatch(Create_Warning_Messages(null, "Not Higher Then End Month"));
        } else {
            setStartMonth(newValue);
        }
    };

    const changeEndMonth = (newValue) => {
        if (Number(newValue) < Number(StartMonth)) {
            dispatch(Create_Warning_Messages(null, "Not Lower Then Start Month"));
        } else {
            setEndMonth(newValue);
        }
    };
    const st_textfield = { margin: "10px 10px 10px 10px", width: "20%" };
    const exportToExcel = () => {
        const table = document.getElementById("table-device");

        const ws = XLSX.utils.table_to_sheet(table);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, "table.xlsx");
    };
    return (
        <Fragment>
            <center>
                <TextField
                    style={st_textfield}
                    variant="outlined"
                    type="number"
                    label="Active Year"
                    name="ActiveYear"
                    value={ActiveYear}
                    onChange={(e) => changeYear(e.target.value)}
                />
                <FormControl style={{ ...st_textfield }} variant="filled" required>
                    <InputLabel shrink>Start Month</InputLabel>
                    <Select
                        native
                        style={{
                            backgroundColor: "white",
                            fontFamily: "Montserrat, Segoe UI",
                        }}
                        onChange={(e) => changeStartMonth(e.target.value)}
                        label="StartMonth"
                        name="StartMonth"
                        value={StartMonth}
                    >
                        <option value="" disabled>
                            {" "}
                            -- select an option --{" "}
                        </option>
                        {VariableLimit.Month.map((option, index) => (
                            <option key={`Op_StartMonth_${index}`} value={option.value}>
                                {option.display}
                            </option>
                        ))}
                    </Select>
                </FormControl>
                <FormControl style={{ ...st_textfield }} variant="filled" required>
                    <InputLabel shrink>End Month</InputLabel>
                    <Select
                        native
                        style={{
                            backgroundColor: "white",
                            fontFamily: "Montserrat, Segoe UI",
                        }}
                        onChange={(e) => changeEndMonth(e.target.value)}
                        label="End Month"
                        name="EndMonth"
                        value={EndMonth}
                    >
                        <option value="" disabled>
                            {" "}
                            -- select an option --{" "}
                        </option>
                        {VariableLimit.Month.map((option, index) => (
                            <option key={`Op_EndMonth_${index}`} value={option.value}>
                                {option.display}
                            </option>
                        ))}
                    </Select>
                </FormControl>
            </center>
            <h3 style={{ fontSize: "2vw", fontFamily: "Montserrat, Segoe UI" }}>
                MPP Device
            </h3>

            {MPPDevice && (
                <Button
                    variant="contained"
                    onClick={exportToExcel}
                    style={{ marginBottom: "16px", marginLeft: "16px" }}
                >
                    Export to Excel
                </Button>
            )}

            <div className="tableMPP">
                <Table id="table-device">
                    <TableHead>
                        <TableRow>
                            <TableCell>Divisi</TableCell>
                            <TableCell>Department</TableCell>
                            <TableCell>Level</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Laptop</TableCell>
                            <TableCell>Tablet</TableCell>
                            <TableCell>PC</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {MPPDevice &&
                            MPPDevice.map((divisionData, divisionIndex) => (
                                <React.Fragment key={divisionIndex}>
                                    {divisionData.Departemen.map((dept, deptIndex) => (
                                        <React.Fragment key={deptIndex}>
                                            {dept.Level.map((level, levelIndex) => (
                                                <TableRow
                                                    key={`${divisionIndex}-${deptIndex}-${levelIndex}`}
                                                >
                                                    {deptIndex === 0 && levelIndex === 0 && (
                                                        <TableCell
                                                            rowSpan={
                                                                divisionData.Departemen.length *
                                                                dept.Level.length
                                                            }
                                                        >
                                                            {divisionData.NamaDivisi}
                                                        </TableCell>
                                                    )}
                                                    {levelIndex === 0 && (
                                                        <TableCell rowSpan={dept.Level.length}>
                                                            {dept.NamaDepartemen}
                                                        </TableCell>
                                                    )}
                                                    <TableCell>{level.NamaLevel}</TableCell>
                                                    <TableCell>{level.Email}</TableCell>
                                                    <TableCell>{level.Laptop}</TableCell>
                                                    <TableCell>{level.Tablet}</TableCell>
                                                    <TableCell>{level.PC}</TableCell>
                                                </TableRow>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            ))}
                    </TableBody>
                </Table>
            </div>
        </Fragment>
    );
};

export default MPPRecapDevice;
