import React, { Fragment } from "react";

import { Skeleton, Checkbox, TextField } from "@mui/material";

import CoTable from "../../Table";

const Detail = ({ Data, ListData, ListData2, isLoading }) => {
  const st_textfield_disable = {
    marginTop: "10px",
    marginBottom: "10px",
    width: "100%",
    backgroundColor: "#E0E0E0",
  };
  const st_TextSmall_disable = {
    width: "40%",
    margin: "0 1% 0 0",
    marginTop: "10px",
    marginBottom: "10px",
    backgroundColor: "#E0E0E0",
  };

  const HeadData1 = [
    {
      Nama: "No",
      width: "5%",
    },
    {
      Nama: "Description",
      width: "35%",
    },
    {
      Nama: "1",
      width: "5%",
    },
    {
      Nama: "2",
      width: "5%",
    },
    {
      Nama: "3",
      width: "5%",
    },
  ];

  const BodyData1 = ListData2
    ? ListData2.map((item, index) => {
        return {
          index: index + 1,
          Pertanyaan: item.Uraian,
          Point1: (
            <Checkbox disabled checked={item.Point === 1 ? true : false} />
          ),
          Point2: (
            <Checkbox disabled checked={item.Point === 2 ? true : false} />
          ),
          Point3: (
            <Checkbox disabled checked={item.Point === 3 ? true : false} />
          ),
        };
      })
    : [];

  const HeadData = [
    {
      Nama: "No",
      width: "5%",
    },
    {
      Nama: "Description",
      width: "35%",
    },
    {
      Nama: "1",
      width: "5%",
    },
    {
      Nama: "2",
      width: "5%",
    },
    {
      Nama: "3",
      width: "5%",
    },
  ];

  const BodyData = ListData
    ? ListData.map((item, index) => {
        return {
          index: index + 1,
          Pertanyaan: item.Uraian,
          Point1: (
            <Checkbox disabled checked={item.Point === 1 ? true : false} />
          ),
          Point2: (
            <Checkbox disabled checked={item.Point === 2 ? true : false} />
          ),
          Point3: (
            <Checkbox disabled checked={item.Point === 3 ? true : false} />
          ),
        };
      })
    : [];

  return (
    <Fragment>
      {isLoading ? (
        <Fragment>
          {[1, 2, 3, 4, 5, 6, 7].map((item) => (
            <Skeleton height={"8vh"} key={`PRForm_Skeleton_${item}`} />
          ))}
        </Fragment>
      ) : (
        <Fragment>
          <h3>Job Description Detail</h3>
          <div
            style={{
              margin: "1% 0 1% 0",
              padding: "1%",
              border: `1px solid ${
                Data?.Status
                  ? Data.Status === "Submitted"
                    ? "Orange"
                    : Data.Status === "Accept"
                    ? "Green"
                    : "Red"
                  : "black"
              }`,
            }}
          >
            <h4>
              Created by:
              <br />
              {Data?.BiodataNama ? Data.BiodataNama : ""}
              <br />
              <br />
              {/* {Data?.AccBioNama ? Data.AccBioNama : ''}<br /><br /> */}
              On:
              <br />
              {Data?.TanggalPengajuan
                ? new Date(Data.TanggalPengajuan).toLocaleString(["ban", "id"])
                : ""}
              <br />
            </h4>
          </div>
          <h2>Job Description</h2>

          <h3>Position Identity</h3>
          <TextField
            style={st_textfield_disable}
            variant="outlined"
            type="text"
            label="Name of Position"
            name="NamaJabatan"
            disabled
            value={Data?.NamaJabatan ? Data.NamaJabatan : ""}
          />
          <TextField
            style={st_textfield_disable}
            variant="outlined"
            type="text"
            label="Level"
            name="Level"
            disabled
            value={Data?.LevelNama ? Data.LevelNama : ""}
          />
          <TextField
            style={st_textfield_disable}
            variant="outlined"
            type="text"
            label="Department"
            name="Departemen"
            disabled
            value={Data?.DepartemenNama ? Data.DepartemenNama : ""}
          />
          <TextField
            style={st_textfield_disable}
            variant="outlined"
            type="text"
            label="Direct Supervisor"
            name="AtasanLangsungNama"
            disabled
            value={Data?.AtasanLangsungNama ? Data.AtasanLangsungNama : ""}
          />
          <TextField
            style={st_textfield_disable}
            variant="outlined"
            type="text"
            label="Indirect Supervisor"
            name="AtasanTakLangsungNama"
            disabled
            value={
              Data?.AtasanTakLangsungNama ? Data.AtasanTakLangsungNama : ""
            }
          />
          <h3>Job Purpose</h3>
          <TextField
            style={st_textfield_disable}
            variant="outlined"
            multiline
            rows={8}
            type="text"
            label="Job Purpose"
            name="Tujuan"
            disabled
            value={Data?.Tujuan ? Data.Tujuan : ""}
          />
          <h3>Authority & Main Responsibility</h3>
          <TextField
            style={st_textfield_disable}
            variant="outlined"
            multiline
            rows={8}
            type="text"
            label="Authority"
            name="Wewenang"
            disabled
            value={Data?.Wewenang ? Data.Wewenang : ""}
          />
          <TextField
            style={st_textfield_disable}
            variant="outlined"
            multiline
            rows={8}
            type="text"
            label="Main Responsibility"
            name="TanggungJawab"
            disabled
            value={Data?.TanggungJawab ? Data.TanggungJawab : ""}
          />
          <h3>Task Description</h3>
          <TextField
            style={st_textfield_disable}
            variant="outlined"
            multiline
            rows={12}
            type="text"
            label="Task Description"
            name="UraianTugas"
            disabled
            value={Data?.UraianTugas ? Data.UraianTugas : ""}
          />
          <h3>Subordinate</h3>
          <TextField
            style={st_TextSmall_disable}
            variant="outlined"
            type="number"
            label="Number of Direct Subordinate"
            name="JumlahBawahanLangsung"
            disabled
            value={Data?.JumlahBawahanLangsung ? Data.JumlahBawahanLangsung : 0}
          />
          <TextField
            style={st_TextSmall_disable}
            variant="outlined"
            type="text"
            label="Direct Subordinate Position"
            name="PosisiBawahanLangsung"
            disabled
            value={
              Data?.PosisiBawahanLangsung ? Data.PosisiBawahanLangsung : ""
            }
          />
          <TextField
            style={st_TextSmall_disable}
            variant="outlined"
            type="number"
            label="Number of Indirect Subordinate"
            name="JumlahBawahanTakLangsung"
            disabled
            value={
              Data?.JumlahBawahanTakLangsung ? Data.JumlahBawahanTakLangsung : 0
            }
          />
          <TextField
            style={st_TextSmall_disable}
            variant="outlined"
            type="text"
            label="Indirect Subordinate Position"
            name="PosisiBawahanTakLangsung"
            disabled
            value={
              Data?.PosisiBawahanTakLangsung
                ? Data.PosisiBawahanTakLangsung
                : ""
            }
          />
          <h2>Working Relationship</h2>
          <TextField
            style={st_textfield_disable}
            variant="outlined"
            type="text"
            label="Internal Party"
            name="Internal"
            disabled
            value={Data?.Internal ? Data.Internal : ""}
          />
          <TextField
            style={st_textfield_disable}
            variant="outlined"
            type="text"
            label="External Party"
            name="Eksternal"
            disabled
            value={Data?.Eksternal ? Data.Eksternal : ""}
          />
          <h3>Minimum Of Job Qualification</h3>
          <TextField
            style={st_textfield_disable}
            variant="outlined"
            type="text"
            label="Educational"
            name="Pendidikan"
            disabled
            value={Data?.Pendidikan ? Data.Pendidikan : ""}
          />
          <TextField
            style={st_textfield_disable}
            variant="outlined"
            type="text"
            label="Major/ Faculty"
            name="Jurusan"
            disabled
            value={Data?.Jurusan ? Data.Jurusan : ""}
          />
          <TextField
            style={st_TextSmall_disable}
            variant="outlined"
            type="number"
            label="Minimum Age"
            name="UsiaMin"
            disabled
            value={Data?.UsiaMin ? Data.UsiaMin : ""}
          />
          <TextField
            style={st_TextSmall_disable}
            variant="outlined"
            type="number"
            label="Maximum Age"
            name="UsiaMax"
            disabled
            value={Data?.UsiaMax ? Data.UsiaMax : ""}
          />
          <TextField
            style={st_textfield_disable}
            variant="outlined"
            type="text"
            label="General Skill"
            name="KeterampilanUmum"
            disabled
            value={Data?.KeterampilanUmum ? Data.KeterampilanUmum : ""}
          />
          <TextField
            style={st_textfield_disable}
            variant="outlined"
            type="text"
            label="Special Knowledge"
            name="PengetahuanKhusus"
            disabled
            value={Data?.PengetahuanKhusus ? Data.PengetahuanKhusus : ""}
          />
          <TextField
            style={st_textfield_disable}
            variant="outlined"
            multiline
            rows={8}
            type="text"
            label="Special Skill"
            name="KeterampilanKhusus"
            disabled
            value={Data?.KeterampilanKhusus ? Data.KeterampilanKhusus : ""}
          />
          <TextField
            style={st_textfield_disable}
            variant="outlined"
            type="text"
            label="Certification"
            name="Sertifikasi"
            disabled
            value={Data?.Sertifikasi ? Data.Sertifikasi : ""}
          />
          <TextField
            style={st_textfield_disable}
            variant="outlined"
            type="text"
            label="Working Experience"
            name="PengalamanKerja"
            disabled
            value={Data?.PengalamanKerja ? Data.PengalamanKerja : ""}
          />
          <h3>Work Location</h3>
          <TextField
            style={st_textfield_disable}
            variant="outlined"
            type="text"
            label="Inside Office"
            name="DalamKantor"
            disabled
            value={Data?.DalamKantor ? Data.DalamKantor : ""}
          />
          <TextField
            style={st_textfield_disable}
            variant="outlined"
            type="text"
            label="In the Field"
            name="DalamLapangan"
            disabled
            value={Data?.DalamLapangan ? Data.DalamLapangan : ""}
          />
          <TextField
            style={st_textfield_disable}
            variant="outlined"
            type="text"
            label="Other Locations"
            name="LokasiLainnya"
            disabled
            value={Data?.LokasiLainnya ? Data.LokasiLainnya : ""}
          />
          <h3>Behaviour Competency</h3>
          <CoTable
            TableId={"Jobdesk_KompetensiPrilaku"}
            HeadData={HeadData}
            Data={BodyData}
          />
          <h3>Technical Competency</h3>
          <CoTable
            TableId={"Jobdesk_KompetensiTeknik"}
            HeadData={HeadData1}
            Data={BodyData1}
          />
        </Fragment>
      )}
    </Fragment>
  );
};
export default Detail;
