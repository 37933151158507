import React, { Fragment } from "react"

import useMediaQuery from '@mui/material/useMediaQuery'
import { InitState } from '../../../Style'

import FullJobList from './JobList/FullJobList'
import HalfJobList from './JobList/HalfJobList'

const JobList = () => { // FIXME
    const MinWindowFirst = useMediaQuery(`(min-width:${InitState.Units.minWidth_first}px)`)
    return (
        <Fragment>
            {MinWindowFirst ?
                <Fragment>
                    <FullJobList />
                </Fragment>
                : <Fragment>
                    <HalfJobList />
                </Fragment>}
        </Fragment>
    )
}

export default JobList