import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Konfirm_Interview_User, get_List_Interview_User_Detail } from '../../../../../../../Store/Actions/Job.Action'
import { get_Jobdesk_Pertanyaan } from '../../../../../../../Store/Actions/Jobdesk.Action'
import { Load_Pertanyaan_Interview_List } from '../../../../../../../Store/Actions/Setting.Action'
import { Create_Warning_Messages } from '../../../../../../../Store/Actions/Messages.Actions'

import { Skeleton, Checkbox, TextField, FormControl, InputLabel, Select, Button, FormGroup, FormControlLabel } from '@mui/material'

import CoTable from '../../../../../../Containers/Table'
import ReviewInterviewUser from '../../../../../../Containers/Job/InterviewUser/Review/ReviewInterviewUser'

class PenilaianInterviewUser extends React.Component {
    state = {
        InterviewDate: '',
        KeputusanHasil: '',
        Keterangan: '',
        PertanyaanList: [],
        PertanyaanPerilaku: [],
        PertanyaanTeknik: [],
        isShowReview: false,
        IsAddAnother: false,
    }

    componentDidMount() {
        this.props.Load_Pertanyaan_Interview_List('Department')
        const JobActionId = this.props.JobActionId
        const AccountActionId = this.props.AccountActionId
        if (JobActionId && AccountActionId) {
            this.props.get_Jobdesk_Pertanyaan(JobActionId, AccountActionId)
            this.props.get_List_Interview_User_Detail(JobActionId, AccountActionId)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.PertanyaanList !== prevProps.PertanyaanList) {
            const { PertanyaanList } = this.props
            const newPertanyaanList = []
            PertanyaanList.map((item, index) => newPertanyaanList.push({
                Id: item.Id,
                Nama: item.Nama,
                Point: 0,
                Keterangan: ''
            }))
            this.setState({ PertanyaanList: newPertanyaanList })
        }

        if (this.props.JobdeskGetPertanyaanPerilaku !== prevProps.JobdeskGetPertanyaanPerilaku) {
            const { JobdeskGetPertanyaanPerilaku } = this.props
            const newPertanyaanPerilaku = []
            JobdeskGetPertanyaanPerilaku.map((item, index) => newPertanyaanPerilaku.push({
                Id: item.Id,
                Nama: item.Uraian,
                PatokanPoint: item.Point,
                Point: 0,
                Keterangan: ''
            }))
            this.setState({ PertanyaanPerilaku: newPertanyaanPerilaku })
        }

        if (this.props.JobdeskGetPertanyaanTeknik !== prevProps.JobdeskGetPertanyaanTeknik) {
            const { JobdeskGetPertanyaanTeknik } = this.props
            const newPertanyaanTeknik = []
            JobdeskGetPertanyaanTeknik.map((item, index) => newPertanyaanTeknik.push({
                Id: item.Id,
                Nama: item.Uraian,
                PatokanPoint: item.Point,
                Point: 0,
                Keterangan: ''
            }))
            this.setState({ PertanyaanTeknik: newPertanyaanTeknik })
        }

        if (this.props.ListInterviewUserDetail !== prevProps.ListInterviewUserDetail) {
            const { ListInterviewUserDetail } = this.props
            const Data = ListInterviewUserDetail.Data
            if (Data.length === 0) {
                this.setState({ IsAddAnother: true })
            } else if (Data.length > 0) {
                this.setState({ isShowReview: true })
            }
        }
    }

    OnChangeCheckbox = E => {
        this.setState({ [E.target.name]: !this.state[E.target.name] })
    }

    onChangeCheckboxMap = (e, index, value) => {
        const PertanyaanList = this.state.PertanyaanList
        PertanyaanList[index].Point = value
        this.setState({ PertanyaanList: PertanyaanList })
    }

    onChangeFieldMap = (e, index) => {
        const PertanyaanList = this.state.PertanyaanList
        PertanyaanList[index].Keterangan = e.target.value
        this.setState({ PertanyaanList: PertanyaanList })
    }

    onChangeCheckboxMap2 = (e, index, value) => {
        const PertanyaanPerilaku = this.state.PertanyaanPerilaku
        PertanyaanPerilaku[index].Point = value
        this.setState({ PertanyaanPerilaku: PertanyaanPerilaku })
    }

    onChangeFieldMap2 = (e, index) => {
        const PertanyaanPerilaku = this.state.PertanyaanPerilaku
        PertanyaanPerilaku[index].Keterangan = e.target.value
        this.setState({ PertanyaanPerilaku: PertanyaanPerilaku })
    }

    onChangeCheckboxMap3 = (e, index, value) => {
        const PertanyaanTeknik = this.state.PertanyaanTeknik
        PertanyaanTeknik[index].Point = value
        this.setState({ PertanyaanTeknik: PertanyaanTeknik })
    }

    onChangeFieldMap3 = (e, index) => {
        const PertanyaanTeknik = this.state.PertanyaanTeknik
        PertanyaanTeknik[index].Keterangan = e.target.value
        this.setState({ PertanyaanTeknik: PertanyaanTeknik })
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    SelectFormOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    formOnSubmit = (e) => {
        e.preventDefault()

        const JobActionId = this.props.JobActionId
        const AccountActionId = this.props.AccountActionId
        const PertanyaanList = this.state.PertanyaanList
        const PertanyaanPerilaku = this.state.PertanyaanPerilaku
        const PertanyaanTeknik = this.state.PertanyaanTeknik
        const isThereZero = PertanyaanList.find(item => item.Point === 0)
        const isThereZero2 = PertanyaanPerilaku.find(item => item.Point === 0)
        const isThereZero3 = PertanyaanTeknik.find(item => item.Point === 0)
        if (isThereZero || isThereZero2 || isThereZero3) {
            this.props.Create_Warning_Messages(null, 'tidak boleh ada yang kosong')
        } else {
            this.props.Konfirm_Interview_User(
                JobActionId,
                AccountActionId,
                this.state.InterviewDate,
                this.state.KeputusanHasil,
                this.state.Keterangan,
                PertanyaanList,
                PertanyaanPerilaku,
                PertanyaanTeknik,
            )
        }
    }

    render() {
        // const isListInterviewUserDetailLoading = this.props.isListInterviewUserDetailLoading
        const ListInterviewUserDetail = this.props.ListInterviewUserDetail
        const Data = ListInterviewUserDetail?.Data ? ListInterviewUserDetail.Data : []
        const AssessorData = ListInterviewUserDetail?.AssessorData ? ListInterviewUserDetail.AssessorData : {}
        const ListAdditional = ListInterviewUserDetail?.ListAdditional ? ListInterviewUserDetail.ListAdditional : []
        const ListAlternative = ListInterviewUserDetail?.ListAlternative ? ListInterviewUserDetail.ListAlternative : []

        const isPertanyaanLoading = this.props.isPertanyaanLoading
        // const PertanyaanList = this.props.PertanyaanList
        const isJobdeskGetPertanyaanLoading = this.props.isJobdeskGetPertanyaanLoading
        // const JobdeskGetPertanyaanPerilaku = this.props.JobdeskGetPertanyaanPerilaku
        // const JobdeskGetPertanyaanTeknik = this.props.JobdeskGetPertanyaanTeknik

        const {
            InterviewDate,
            KeputusanHasil,
            Keterangan,
            PertanyaanList,
            PertanyaanPerilaku,
            PertanyaanTeknik,
            isShowReview,
            IsAddAnother
        } = this.state

        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Question',
                width: '35%',
            },
            {
                Nama: '1',
                width: '5%',
            },
            {
                Nama: '2',
                width: '5%',
            },
            {
                Nama: '3',
                width: '5%',
            },
            {
                Nama: '4',
                width: '5%',
            },
            {
                Nama: '5',
                width: '5%',
            },
            {
                Nama: 'Note',
                width: '20%',
            },
        ]

        const BodyData = PertanyaanList.map((item, index) => {
            return {
                index: index + 1,
                Pertanyaan: item.Nama,
                Point1: (
                    <Checkbox
                        checked={PertanyaanList[index].Point === 1 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap(e, index, 1)}
                    />
                ),
                Point2: (
                    <Checkbox
                        checked={PertanyaanList[index].Point === 2 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap(e, index, 2)}
                    />
                ),
                Point3: (
                    <Checkbox
                        checked={PertanyaanList[index].Point === 3 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap(e, index, 3)}
                    />
                ),
                Point4: (
                    <Checkbox
                        checked={PertanyaanList[index].Point === 4 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap(e, index, 4)}
                    />
                ),
                Point5: (
                    <Checkbox
                        checked={PertanyaanList[index].Point === 5 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap(e, index, 5)}
                    />
                ),
                Keterangan: (
                    <TextField
                        // style={st_halftextfield}
                        variant='outlined'
                        onChange={(e) => this.onChangeFieldMap(e, index)}
                        type='text'
                        label={`Note ${index + 1}`}
                        name='InterviewDate'
                    // value={InterviewDate}
                    />
                ),
            }
        })

        const HeadData2 = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Question',
                width: '35%',
            },
            {
                Nama: 'Point Benchmark 1',
                width: '5%',
            },
            {
                Nama: 'Point Benchmark 2',
                width: '5%',
            },
            {
                Nama: 'Point Benchmark 3',
                width: '5%',
            },
            {
                Nama: ' ',
                width: '10%',
            },
            {
                Nama: '1',
                width: '5%',
            },
            {
                Nama: '2',
                width: '5%',
            },
            {
                Nama: '3',
                width: '5%',
            },
            {
                Nama: 'Note',
                width: '20%',
            },
        ]

        const BodyData2 = PertanyaanPerilaku.map((item, index) => {
            return {
                index: index + 1,
                Pertanyaan: item.Nama,
                PatokanPoint1: (
                    <Checkbox
                        checked={PertanyaanPerilaku[index].PatokanPoint === 1 ? true : false}
                        disabled
                    />
                ),
                PatokanPoint2: (
                    <Checkbox
                        checked={PertanyaanPerilaku[index].PatokanPoint === 2 ? true : false}
                        disabled
                    />
                ),
                PatokanPoint3: (
                    <Checkbox
                        checked={PertanyaanPerilaku[index].PatokanPoint === 3 ? true : false}
                        disabled
                    />
                ),
                Space: ' ',
                Point1: (
                    <Checkbox
                        checked={PertanyaanPerilaku[index].Point === 1 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap2(e, index, 1)}
                    />
                ),
                Point2: (
                    <Checkbox
                        checked={PertanyaanPerilaku[index].Point === 2 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap2(e, index, 2)}
                    />
                ),
                Point3: (
                    <Checkbox
                        checked={PertanyaanPerilaku[index].Point === 3 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap2(e, index, 3)}
                    />
                ),
                Keterangan: (
                    <TextField
                        // style={st_halftextfield}
                        variant='outlined'
                        onChange={(e) => this.onChangeFieldMap2(e, index)}
                        type='text'
                        label={`Note ${index + 1}`}
                        name='InterviewDate'
                        value={PertanyaanPerilaku[index].Keterangan}
                    />
                ),
            }
        })

        const HeadData3 = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Question',
                width: '35%',
            },
            {
                Nama: 'Point Benchmark 1',
                width: '5%',
            },
            {
                Nama: 'Point Benchmark 2',
                width: '5%',
            },
            {
                Nama: 'Point Benchmark 3',
                width: '5%',
            },
            {
                Nama: ' ',
                width: '10%',
            },
            {
                Nama: '1',
                width: '5%',
            },
            {
                Nama: '2',
                width: '5%',
            },
            {
                Nama: '3',
                width: '5%',
            },
            {
                Nama: 'Note',
                width: '20%',
            },
        ]

        const BodyData3 = PertanyaanTeknik.map((item, index) => {
            return {
                index: index + 1,
                Pertanyaan: item.Nama,
                PatokanPoint1: (
                    <Checkbox
                        checked={PertanyaanTeknik[index].PatokanPoint === 1 ? true : false}
                        disabled
                    />
                ),
                PatokanPoint2: (
                    <Checkbox
                        checked={PertanyaanTeknik[index].PatokanPoint === 2 ? true : false}
                        disabled
                    />
                ),
                PatokanPoint3: (
                    <Checkbox
                        checked={PertanyaanTeknik[index].PatokanPoint === 3 ? true : false}
                        disabled
                    />
                ),
                Space: ' ',
                Point1: (
                    <Checkbox
                        checked={PertanyaanTeknik[index].Point === 1 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap3(e, index, 1)}
                    />
                ),
                Point2: (
                    <Checkbox
                        checked={PertanyaanTeknik[index].Point === 2 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap3(e, index, 2)}
                    />
                ),
                Point3: (
                    <Checkbox
                        checked={PertanyaanTeknik[index].Point === 3 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap3(e, index, 3)}
                    />
                ),
                Keterangan: (
                    <TextField
                        // style={st_halftextfield}
                        variant='outlined'
                        onChange={(e) => this.onChangeFieldMap3(e, index)}
                        type='text'
                        label={`Note ${index + 1}`}
                        name='InterviewDate'
                        value={PertanyaanTeknik[index].Keterangan}
                    />
                ),
            }
        })

        const Score = PertanyaanList.reduce((accumulator, object) => {
            return accumulator + object.Point
        }, 0)

        const isRecommended = (Score / PertanyaanList.length) > ((5 * PertanyaanList.length) / PertanyaanList.length) / 2 ? true : false

        const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }
        const st_halftextfield = { ...st_textfield, width: '50%' }

        return (
            <Fragment>
                {false ?
                    <Fragment>
                        {[1, 2, 3, 4, 5,].map((item) =>
                            <Skeleton
                                key={`PenilaianInterviewUser_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        {isShowReview ?
                            <div
                                style={{ margin: '0 0 10vh 0' }}
                            >
                                <ReviewInterviewUser
                                    Data={Data}
                                    AssessorData={AssessorData}
                                    ListAdditional={ListAdditional}
                                    ListAlternative={ListAlternative}
                                />
                                <br />
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox color='primary' onChange={this.OnChangeCheckbox} name="IsAddAnother" value={IsAddAnother} />}
                                        label="Add Another Interview"
                                    />
                                </FormGroup>
                            </div> :
                            <Fragment>
                            </Fragment>}
                        {IsAddAnother ?
                            <Fragment>
                                <form
                                    onSubmit={this.formOnSubmit}
                                >
                                    <h2>
                                        Applicant Data
                                    </h2>
                                    <TextField
                                        style={st_halftextfield}
                                        variant='outlined'
                                        onChange={this.onChangeField}
                                        type='datetime-local'
                                        label='Interview Date'
                                        name='InterviewDate'
                                        required
                                        value={InterviewDate}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <br />
                                    <h2>
                                        Assessment Form
                                    </h2>
                                    <CoTable
                                        TableId={'PenilaianInterviewUser_Penilaian'}
                                        HeadData={HeadData}
                                        Data={BodyData}
                                        isHeadLoading={isPertanyaanLoading}
                                        isBodyLoading={isPertanyaanLoading}
                                    />
                                    <h2>
                                        Job Profile Assessment
                                    </h2>
                                    <h3>
                                        Behavior
                                    </h3>
                                    <CoTable
                                        TableId={'PenilaianInterviewUser_Penilaian_Perilaku'}
                                        HeadData={HeadData2}
                                        Data={BodyData2}
                                        isHeadLoading={isJobdeskGetPertanyaanLoading}
                                        isBodyLoading={isJobdeskGetPertanyaanLoading}
                                    />
                                    <h3>
                                        Technical
                                    </h3>
                                    <CoTable
                                        TableId={'PenilaianInterviewUser_Penilaian_Teknik'}
                                        HeadData={HeadData3}
                                        Data={BodyData3}
                                        isHeadLoading={isJobdeskGetPertanyaanLoading}
                                        isBodyLoading={isJobdeskGetPertanyaanLoading}
                                    />
                                    <h2>
                                        Interview Result Decision
                                    </h2>
                                    <h3>
                                        Interview Score is:
                                        <span
                                            style={{ color: isRecommended ? 'green' : 'red' }}
                                        >
                                            {` ${(Score / (PertanyaanList.length * 5)) * 100}%`}
                                        </span>
                                    </h3>
                                    <h3>
                                        Suggested Action
                                        <span
                                            style={{ color: isRecommended ? 'green' : 'red' }}
                                        >
                                            {isRecommended ? ` Accept` : ` Reject`}
                                        </span>
                                    </h3>
                                    <FormControl style={st_textfield} variant="filled" required >
                                        <InputLabel shrink >Result Decision</InputLabel>
                                        <Select native style={{ backgroundColor: 'white' }} onChange={(e) => this.SelectFormOnChange(e)} label="Result Decision" name='KeputusanHasil' value={KeputusanHasil} >
                                            <option value="" disabled> -- select an option -- </option>
                                            {['Accept', 'Reject'].map((option, index) =>
                                                <option key={index} value={option}>{option}</option>
                                            )}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        style={st_textfield}
                                        variant='outlined'
                                        onChange={this.onChangeField}
                                        type='texts'
                                        label='Note'
                                        name='Keterangan'
                                        value={Keterangan}
                                    />
                                    <center>
                                        <Button
                                            variant='contained'
                                            style={{ width: '60%', margin: '1% 0 3% 0' }}
                                            type='submit'
                                        >
                                            Submit
                                        </Button>
                                    </center>
                                </form>
                            </Fragment>
                            : <Fragment>

                            </Fragment>}
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({

    isListInterviewUserDetailLoading: state.Job.isListInterviewUserDetailLoading,
    ListInterviewUserDetail: state.Job.ListInterviewUserDetail,


    isPertanyaanLoading: state.Setting.isPertanyaanLoading,
    PertanyaanList: state.Setting.PertanyaanList,
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
    isJobdeskGetPertanyaanLoading: state.Jobdesk.isJobdeskGetPertanyaanLoading,
    JobdeskGetPertanyaanPerilaku: state.Jobdesk.JobdeskGetPertanyaanPerilaku,
    JobdeskGetPertanyaanTeknik: state.Jobdesk.JobdeskGetPertanyaanTeknik,
})

export default connect(mapStateToProps, { Load_Pertanyaan_Interview_List, get_Jobdesk_Pertanyaan, get_List_Interview_User_Detail, Konfirm_Interview_User, Create_Warning_Messages })(PenilaianInterviewUser)