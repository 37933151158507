import {
    REPORT_MPP_LOADING,
    REPORT_MPP_LOADED,
    REPORT_MPP_LOADING_DONE,
    REPORT_MPP_ITEM_LOADING,
    REPORT_MPP_ITEM_LOADED,
    REPORT_MPP_ITEM_LOADING_DONE,
    REPORT_JOBDESC_LOADING,
    REPORT_JOBDESC_LOADED,
    REPORT_JOBDESC_LOADING_DONE,
    REPORT_PR_LOADING,
    REPORT_PR_LOADED,
    REPORT_PR_LOADING_DONE,
    REPORT_PR_REALIZATION_LOADING,
    REPORT_PR_REALIZATION_LOADED,
    REPORT_PR_REALIZATION_LOADING_DONE,
    REPORT_MPP_DONE_LOADING,
    REPORT_MPP_DONE_LOADED,
    REPORT_MPP_DONE_LOADING_DONE,
    REPORT_MPP_DETAILED_LOADING,
    REPORT_MPP_DETAILED_LOADED,
    REPORT_MPP_DETAILED_LOADING_DONE,
    REPORT_HR_LOADING,
    REPORT_HR_LOADED,
    REPORT_HR_LOADING_DONE,
    REPORT_DEPARTMENT_LOADING,
    REPORT_DEPARTMENT_LOADED,
    REPORT_DEPARTMENT_LOADING_DONE,
} from '../Actions/Type.Action'

const initialState = {
    isReportMPPLoading: false,
    ReportMPP: [],
    isReportMPPItemLoading: false,
    ReportMPPItem: [],
    isReportJobDescLoading: false,
    ReportJobDesc: [],
    isReportPRLoading: false,
    ReportPR: [],
    isReportPRRealizationLoading: false,
    ReportPRRealization: [],
    isReportMPPDoneLoading: false,
    ReportMPPDone: [],
    isReportMPPDetailedLoading: false,
    ReportMPPDetailed: [],
    isReportHRLoading: false,
    ReportHR: [],
    isReportDepartmentLoading: false,
    ReportDepartment: [],
}

// eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        case REPORT_MPP_LOADING:
            return {
                ...state,
                isReportMPPLoading: true
            }
        case REPORT_MPP_LOADED:
            return {
                ...state,
                ReportMPP: action.payload
            }
        case REPORT_MPP_LOADING_DONE:
            return {
                ...state,
                isReportMPPLoading: false
            }
        case REPORT_MPP_ITEM_LOADING:
            return {
                ...state,
                isReportMPPItemLoading: true
            }
        case REPORT_MPP_ITEM_LOADED:
            return {
                ...state,
                ReportMPPItem: action.payload
            }
        case REPORT_MPP_ITEM_LOADING_DONE:
            return {
                ...state,
                isReportMPPItemLoading: false
            }
        case REPORT_JOBDESC_LOADING:
            return {
                ...state,
                isReportJobDescLoading: true
            }
        case REPORT_JOBDESC_LOADED:
            return {
                ...state,
                ReportJobDesc: action.payload
            }
        case REPORT_JOBDESC_LOADING_DONE:
            return {
                ...state,
                isReportJobDescLoading: false
            }
        case REPORT_PR_LOADING:
            return {
                ...state,
                isReportPRLoading: true
            }
        case REPORT_PR_LOADED:
            return {
                ...state,
                ReportPR: action.payload
            }
        case REPORT_PR_LOADING_DONE:
            return {
                ...state,
                isReportPRLoading: false
            }
        case REPORT_PR_REALIZATION_LOADING:
            return {
                ...state,
                isReportPRRealizationLoading: true
            }
        case REPORT_PR_REALIZATION_LOADED:
            return {
                ...state,
                ReportPRRealization: action.payload
            }
        case REPORT_PR_REALIZATION_LOADING_DONE:
            return {
                ...state,
                isReportPRRealizationLoading: false
            }
        case REPORT_MPP_DONE_LOADING:
            return {
                ...state,
                isReportMPPDoneLoading: true
            }
        case REPORT_MPP_DONE_LOADED:
            return {
                ...state,
                ReportMPPDone: action.payload
            }
        case REPORT_MPP_DONE_LOADING_DONE:
            return {
                ...state,
                isReportMPPDoneLoading: false
            }
        case REPORT_MPP_DETAILED_LOADING:
            return {
                ...state,
                isReportMPPDetailedLoading: true
            }
        case REPORT_MPP_DETAILED_LOADED:
            return {
                ...state,
                ReportMPPDetailed: action.payload
            }
        case REPORT_MPP_DETAILED_LOADING_DONE:
            return {
                ...state,
                isReportMPPDetailedLoading: false
            }
        case REPORT_HR_LOADING:
            return {
                ...state,
                isReportHRLoading: true
            }
        case REPORT_HR_LOADED:
            return {
                ...state,
                ReportHR: action.payload
            }
        case REPORT_HR_LOADING_DONE:
            return {
                ...state,
                isReportHRLoading: false
            }
        case REPORT_DEPARTMENT_LOADING:
            return {
                ...state,
                isReportDepartmentLoading: true
            }
        case REPORT_DEPARTMENT_LOADED:
            return {
                ...state,
                ReportDepartment: action.payload
            }
        case REPORT_DEPARTMENT_LOADING_DONE:
            return {
                ...state,
                isReportDepartmentLoading: false
            }
        default:
            return state
    }
}