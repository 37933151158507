import React from 'react'

import JobView from '../../../Modal/Job/Job/JobView'

const DetailPosisi = () => {
    return (
        <div>
            <JobView />
        </div>
    )
}

export default DetailPosisi