import React, { Fragment } from 'react'

// import { jsPDF } from 'jspdf'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import { Skeleton, Avatar, Grid, useMediaQuery, Button } from '@mui/material'
import { InitState } from '../../../Style'
import { ImageURL } from '../../../Global'

import CoTable from '../../Containers/Table'

const BiodataExtend = ({ isLoading, AllData, DisableEdit, Edit, isDownload }) => {

    const MinWindowFirst = useMediaQuery(`(min-width:${InitState.Units.minWidth_first}px)`)

    const Data = AllData ? AllData.Data : {}
    const Anak = AllData ? AllData.Anak : [{}]
    const PendidikanFormal = AllData ? AllData.PendidikanFormal : [{}]
    const PendidikanInformal = AllData ? AllData.PendidikanInformal : [{}]
    const PengalamanKerja = AllData ? AllData.PengalamanKerja : [{}]
    const PhotoProfile = Data?.FileFoto ? `${ImageURL.Base}/${Data.FileFoto}` : null

    const st_Row = { display: 'flex', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }
    const st_HalfLeft = { borderTop: '1px solid black', borderRight: '0px solid black', borderBottom: '0px solid black', borderLeft: '1px solid black', width: '35%', padding: '10px' }
    const st_HalfRight = { borderTop: '1px solid black', borderRight: '1px solid black', borderBottom: '0px solid black', borderLeft: '1px solid black', width: '65%', padding: '10px' }
    const st_BottomBorder = { borderBottom: '1px solid black' }
    const st_Space = { margin: '5vh 0 0 0' }
    const st_Full = { borderTop: '1px solid black', borderRight: '1px solid black', borderBottom: '0px solid black', borderLeft: '1px solid black', width: '100%', padding: '10px' }
    const st_Space_Table = { margin: '5vh 0 5vh 0' }
    const st_TableBorderHead = { borderTop: '1px solid black', borderRight: '0px solid black', borderBottom: '1px solid black', borderLeft: '1px solid black', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }
    const st_TableBorder = { borderLeft: '1px solid black', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }

    const HeadData = [
        {
            Nama: 'No',
            width: '5%',
            style: { ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Jenis Pendidikan',
            width: '20%',
            style: { ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Nama Instansi',
            width: '20%',
            style: { ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Tahun Mulai',
            width: '10%',
            style: { ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Tahun Selesai',
            width: '10%',
            style: { ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Jurusan',
            width: '10%',
            style: { ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Kota',
            width: '10%',
            style: { ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Sertifikat',
            width: '10%',
            style: { ...st_TableBorderHead, borderRight: '1px solid black' },
            BodyStyle: { ...st_TableBorder, borderRight: '1px solid black' }
        },
    ]

    const BodyData = PendidikanFormal ? PendidikanFormal.map((item, index) => {
        return {
            index: index + 1,
            Jenis: item.Jenis ? item.Jenis : '',
            InstansiNama: item.InstansiNama ? item.InstansiNama : '',
            TahunMulai: item.TahunMulai ? item.TahunMulai : '',
            TahunSelesai: item.TahunSelesai ? item.TahunSelesai : '',
            Jurusan: item.Jurusan ? item.Jurusan : '',
            Kota: item.Kota ? item.Kota : '',
            Sertifikat: item ? item.Sertifikat === true ? "Ya" : item.Sertifikat === false ? "Tidak" : '' : '',
        }
    }) : [{}]

    const HeadData2 = [
        {
            Nama: 'No',
            width: '5%',
            style: { ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Jenis Pendidikan',
            width: '10%',
            style: { ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Tahun Selesai',
            width: '10%',
            style: { ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Durasi',
            width: '15%',
            style: { ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Nama Program',
            width: '20%',
            style: { ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Bidang',
            width: '10%',
            style: { ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Kota',
            width: '10%',
            style: { ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Sertifikat',
            width: '10%',
            style: { ...st_TableBorderHead, borderRight: '1px solid black' },
            BodyStyle: { ...st_TableBorder, borderRight: '1px solid black' }
        },
    ]

    const BodyData2 = PendidikanInformal ? PendidikanInformal.map((item, index) => {
        return {
            index: index + 1,
            Jenis: item.Jenis ? item.Jenis : '',
            TahunSelesai: item.TahunSelesai ? item.TahunSelesai : '',
            Durasi: item.Durasi ? item.Durasi : '',
            Nama: item.Nama ? item.Nama : '',
            Bidang: item.Bidang ? item.Bidang : '',
            Kota: item.Kota ? item.Kota : '',
            Sertifikat: item ? item.Sertifikat === true ? "Ya" : item.Sertifikat === false ? "Tidak" : '' : '',
        }
    }) : [{}]

    const HeadData3 = [
        {
            Nama: 'No',
            width: '5%',
            style: { ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Nama Perusaaahn',
            width: '30%',
            style: { ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Tahun Mulai',
            width: '10%',
            style: { ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Tahun Selesai',
            width: '10%',
            style: { ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Posisi',
            width: '10%',
            style: { ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Bidang Pekerjaan',
            width: '10%',
            style: { ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Bidang Usaha',
            width: '10%',
            style: { ...st_TableBorderHead, borderRight: '1px solid black' },
            BodyStyle: { ...st_TableBorder, borderRight: '1px solid black' }
        },
    ]

    const BodyData3 = PengalamanKerja ? PengalamanKerja.map((item, index) => {
        return {
            index: index + 1,
            NamaPerusaaahn: item.NamaPerusaaahn ? item.NamaPerusaaahn : '',
            TahunMulai: item.TahunMulai ? item.TahunMulai : '',
            TahunSelesai: item.TahunSelesai ? item.TahunSelesai : '',
            Posisi: item.Posisi ? item.Posisi : '',
            BidangPekerjaan: item.BidangPekerjaan ? item.BidangPekerjaan : '',
            BidangUsaha: item.BidangUsaha ? item.BidangUsaha : '',
        }
    }) : [{}]

    const createPDF = async () => {
        try {

            const PdfDataAnakHead = [{ text: 'Data Anak', colSpan: 2, style: { bold: true }, alignment: 'left' }, {}]
            const PdfDataAnak = Anak && Anak.length > 0 ? [] : [
                PdfDataAnakHead,
                [`Nama Anak Ke-1`, ''],
                [`Jenis Kelamin Anak Ke-1`, ''],
                [`Tanggal Lahir Ke-1`, ''],
            ]

            for (const x in Anak) {
                const item = Anak[x]
                if (x === 0) {
                    PdfDataAnak.push(PdfDataAnakHead)
                }
                PdfDataAnak.push([`Nama Anak Ke-${x + 1}`, item?.Nama ? item.Nama : ''])
                PdfDataAnak.push([`Jenis Kelamin Anak Ke-${x + 1}`, item?.Kelamin ? item.Kelamin === true ? "Male" : item.Kelamin === false ? 'Female' : '' : ''])
                PdfDataAnak.push([`Tanggal Lahir Ke-${x + 1}`, item?.TanggalLahir ? new Date(item.TanggalLahir).toLocaleDateString(['ban', 'id']) : ''])
            }

            const PdfPendidikanFormal = PendidikanFormal && PendidikanFormal.length > 0 ? PendidikanFormal.map((item, index) => {
                return [
                    index + 1,
                    item?.Jenis ? item.Jenis : '',
                    item?.InstansiNama ? item.InstansiNama : '',
                    item?.TahunMulai ? item.TahunMulai : '',
                    item?.TahunSelesai ? item.TahunSelesai : '',
                    item?.Jurusan ? item.Jurusan : '',
                    item?.Kota ? item.Kota : '',
                    item?.Sertifikat ? item.Sertifikat === true ? "Ya" : item.Sertifikat === false ? "Tidak" : '' : '',
                ]
            }) : [{}].map((item, index) => {
                return [
                    index + 1,
                    item?.Jenis ? item.Jenis : '',
                    item?.InstansiNama ? item.InstansiNama : '',
                    item?.TahunMulai ? item.TahunMulai : '',
                    item?.TahunSelesai ? item.TahunSelesai : '',
                    item?.Jurusan ? item.Jurusan : '',
                    item?.Kota ? item.Kota : '',
                    item?.Sertifikat ? item.Sertifikat === true ? "Ya" : item.Sertifikat === false ? "Tidak" : '' : '',
                ]
            })

            const PdfPendidikanInformal = PendidikanInformal && PendidikanInformal.length > 0 ? PendidikanInformal.map((item, index) => {
                return [
                    index + 1,
                    item?.Jenis ? item.Jenis : '',
                    item?.TahunSelesai ? item.TahunSelesai : '',
                    item?.Durasi ? item.Durasi : '',
                    item?.Nama ? item.Nama : '',
                    item?.Bidang ? item.Bidang : '',
                    item?.Kota ? item.Kota : '',
                    item ? item.Sertifikat === true ? "Ya" : item.Sertifikat === false ? "Tidak" : '' : '',
                ]
            }) : [{}].map((item, index) => {
                return [
                    index + 1,
                    item?.Jenis ? item.Jenis : '',
                    item?.TahunSelesai ? item.TahunSelesai : '',
                    item?.Durasi ? item.Durasi : '',
                    item?.Nama ? item.Nama : '',
                    item?.Bidang ? item.Bidang : '',
                    item?.Kota ? item.Kota : '',
                    item ? item.Sertifikat === true ? "Ya" : item.Sertifikat === false ? "Tidak" : '' : '',
                ]
            })

            const PdfPengalamanKerja = PengalamanKerja && PengalamanKerja.length > 0 ? PengalamanKerja.map((item, index) => {
                return [
                    index + 1,
                    item.NamaPerusaaahn ? item.NamaPerusaaahn : '',
                    item.TahunMulai ? item.TahunMulai : '',
                    item.TahunSelesai ? item.TahunSelesai : '',
                    item.Posisi ? item.Posisi : '',
                    item.BidangPekerjaan ? item.BidangPekerjaan : '',
                    item.BidangUsaha ? item.BidangUsaha : '',
                ]
            }) : [{}].map((item, index) => {
                return [
                    index + 1,
                    item.NamaPerusaaahn ? item.NamaPerusaaahn : '',
                    item.TahunMulai ? item.TahunMulai : '',
                    item.TahunSelesai ? item.TahunSelesai : '',
                    item.Posisi ? item.Posisi : '',
                    item.BidangPekerjaan ? item.BidangPekerjaan : '',
                    item.BidangUsaha ? item.BidangUsaha : '',
                ]
            })

            const docDefinition = {
                content: [
                    Data?.FotoBase64 ? {
                        image: Data.FotoBase64,
                        width: 90,
                        height: 90,
                        alignment: 'center',
                        margin: [0, 0, 0, 25],
                    } : {
                        margin: [200, 0, 0, 25],
                        table: {
                            widths: [90],
                            heights: [90],
                            alignment: 'center',
                            body: [
                                [{ text: ' ', style: { bold: true }, alignment: 'center' }],
                            ]
                        }
                    },
                    {
                        margin: [0, 25, 0, 0],
                        heights: 20,
                        table: {
                            widths: [200, 300],
                            body: [
                                ['Nama Lengkap', Data?.NamaLengkap ? Data.NamaLengkap : ''],
                                ['Nama Panggilan', Data?.NamaPanggilan ? Data.NamaPanggilan : ''],
                                ['Tanggal Mulai Bekerja', Data?.TanggalMulaiBekerja ? new Date(Data.TanggalMulaiBekerja).toLocaleDateString(['ban', 'id']) : ''],
                                ['Tempat & Tanggal Lahir', `${Data?.TempatLahir ? Data.TempatLahir : ''}, ${Data?.TanggalLahir ? new Date(Data.TanggalLahir).toLocaleDateString(['ban', 'id']) : ''}`],
                                ['Divisi / Departemen', Data?.Divisi ? Data.Divisi : ''],
                                ['Sub Departemen', Data?.Sub ? Data.Sub : ''],
                            ]
                        }
                    },
                    {
                        margin: [0, 25, 0, 0],
                        heights: 20,
                        table: {
                            widths: [200, 300],
                            body: [
                                [{ text: 'Data Diri', colSpan: 2, style: { bold: true }, alignment: 'left' }, {}],
                                [{ text: 'Alamat Sesuai KTP/Passport', colSpan: 2, style: { bold: true }, alignment: 'left' }, {}],
                                ['Nama Jalan, RT/RW, No. Rumah', Data?.AlamatKTP ? Data.AlamatKTP : ''],
                                ['Kelurahan/Kecamatan', Data?.KelurahanKTP ? Data.KelurahanKTP : ''],
                                ['Kota', Data?.KotaKTP ? Data.KotaKTP : ''],
                                ['Kode Pos', Data?.KodePosKTP ? Data.KodePosKTP : ''],
                                ['No. Telpon Rumah', Data?.TlpnRumahKTP ? Data.TlpnRumahKTP : ''],
                                [{ text: 'Alamat Tempat Tinggal', colSpan: 2, style: { bold: true }, alignment: 'left' }, {}],
                                ['Nama Jalan, RT/RW, No. Rumah', Data?.Alamat ? Data.Alamat : ''],
                                ['Kelurahan/Kecamatan', Data?.Kelurahan ? Data.Kelurahan : ''],
                                ['Kota', Data?.Kota ? Data.Kota : ''],
                                ['Kode Pos', Data?.KodePos ? Data.KodePos : ''],
                                ['No. Telpon Rumah', Data?.TlpnRumah ? Data.TlpnRumah : ''],
                                ['No. Handphone', Data?.Hp ? Data.Hp : ''],
                                ['Jenis Kelamin', Data?.Kelamin ? Data.Kelamin === true ? 'Male' : Data.Kelamin === false ? 'Female' : '' : ''],
                                ['Agama', Data?.Agama ? Data.Agama : ''],
                                ['Status Perkawinan', Data?.Perkawinan ? Data.Perkawinan : ''],
                                ['Kewarganegaraan', Data?.Kewarganegaraan ? Data.Kewarganegaraan : ''],
                                ['No. KTP', Data?.KTP ? Data.KTP : ''],
                                ['No. KK', Data?.KK ? Data.KK : ''],
                                ['No. NPWP', Data?.NPWP ? Data.NPWP : ''],
                                ['No. BPJS Kesehatan', Data?.BPJSKesehatan ? Data.BPJSKesehatan : ''],
                                ['No. BPJS Ketenagakerjaan', Data?.BPJSKetenagakerjaan ? Data.BPJSKetenagakerjaan : ''],
                                ['No. Rekening BCA ', Data?.BCA ? Data.BCA : ''],
                            ]
                        }
                    },
                    {
                        margin: [0, 25, 0, 0],
                        heights: 20,
                        pageBreak: 'before',
                        table: {
                            widths: [200, 300],
                            body: [
                                [{ text: 'Data Suami/Istri', colSpan: 2, style: { bold: true }, alignment: 'left' }, {}],
                                ['Nama Suami/Istri', Data?.NamaPasangan ? Data.NamaPasangan : ''],
                                ['Tanggal Lahir Suami/Istri', Data?.TanggalLahirPasangan ? new Date(Data.TanggalLahirPasangan).toLocaleDateString(['ban', 'id']) : ''],
                                ['Pendidikan Terakhir Suami/Istri', Data?.PendidikanPasangan ? Data.PendidikanPasangan : ''],
                                ['Pekerjaan Suami/Istri', Data?.PekerjaanPasangan ? Data.PekerjaanPasangan : ''],
                                ['Nama Perusahaan Suami/Istri', Data?.PerusahaanPasangan ? Data.PerusahaanPasangan : ''],
                            ]
                        }
                    },
                    {
                        margin: [0, 25, 0, 0],
                        heights: 20,
                        table: {
                            widths: [200, 300],
                            body: PdfDataAnak
                        }
                    },
                    {
                        margin: [0, 25, 0, 0],
                        heights: 20,
                        pageBreak: 'before',
                        table: {
                            widths: [200, 300],
                            body: [
                                [{ text: 'Data Keluarga', colSpan: 2, style: { bold: true }, alignment: 'left' }, {}],
                                ['Nama Ayah Kandung', Data?.NamaAyah ? Data.NamaAyah : ''],
                                ['Tanggal Lahir Ayah Kandung', Data?.TanggalLahirAyah ? new Date(Data.TanggalLahirAyah).toLocaleDateString(['ban', 'id']) : ''],
                                ['Nama Ibu Kandung', Data?.NamaIbu ? Data.NamaIbu : ''],
                                ['Tanggal Lahir Ibu Kandung', Data?.TanggalLahirIbu ? new Date(Data.TanggalLahirIbu).toLocaleDateString(['ban', 'id']) : ''],
                                [{ text: 'Alamat Orang Tua Kandung', colSpan: 2, style: { bold: true }, alignment: 'left' }, {}],
                                ['Nama Jalan, RT/RW, No. Rumah', Data?.AlamatOrangTua ? Data.AlamatOrangTua : ''],
                                ['Kelurahan/Kecamatan', Data?.KelurahanOrangTua ? Data.KelurahanOrangTua : ''],
                                ['Kota', Data?.KotaOrangTua ? Data.KotaOrangTua : ''],
                                ['Kode Pos', Data?.KodePosOrangTua ? Data.KodePosOrangTua : ''],
                                ['Nama Ayah Mertua', Data?.NamaAyahMertua ? Data.NamaAyahMertua : ''],
                                ['Nama Ibu Mertua', Data?.NamaIbuMertua ? Data.NamaIbuMertua : ''],
                                [{ text: 'Alamat Mertua', colSpan: 2, style: { bold: true }, alignment: 'left' }, {}],
                                ['Nama Jalan, RT/RW, No. Rumah', Data?.AlamatMertua ? Data.AlamatMertua : ''],
                                ['Kelurahan/Kecamatan', Data?.KelurahanMertua ? Data.KelurahanMertua : ''],
                                ['Kota', Data?.KotaMertua ? Data.KotaMertua : ''],
                                ['Kode Pos', Data?.KodePosMertua ? Data.KodePosMertua : ''],
                            ]
                        },
                    },
                    {
                        margin: [0, 25, 0, 0],
                        heights: 20,
                        table: {
                            widths: [200, 300],
                            body: [
                                [{ text: 'Kontak Keadaan Darurat', colSpan: 2, style: { bold: true }, alignment: 'left' }, {}],
                                ['Nama ', Data?.NamaDarurat ? Data.NamaDarurat : ''],
                                ['No. Telpon', Data?.NoDarurat ? Data.NoDarurat : ''],
                                ['Hubungan dengan Karyawan', Data?.HubunganDarurat ? Data.HubunganDarurat : ''],
                            ]
                        }
                    },
                    {
                        margin: [0, 25, 0, 0],
                        heights: 20,
                        table: {
                            widths: [200, 300],
                            body: [
                                [{ text: 'Data Ahli Waris', colSpan: 2, style: { bold: true }, alignment: 'left' }, {}],
                                ['Nama Ahli Waris', Data?.NamaWaris ? Data.NamaWaris : ''],
                                ['Hubungan Dengan Karyawan', Data?.HubunganWaris ? Data.HubunganWaris : ''],
                                ['Kontak Ahli Waris', Data?.KontakWaris ? Data.KontakWaris : ''],
                            ]
                        }
                    },
                    {
                        text: 'Riwayat Pendidikan Formal (dimulai dari yang paling akhir)',
                        pageBreak: 'before',
                        style: { bold: true, fontSize: 18 },
                    },
                    {
                        margin: [0, 10, 0, 25],
                        heights: 20,
                        table: {
                            widths: [10, 30, 30, 50, 50, 150, 70, 50],
                            body: [
                                [
                                    { text: 'No', style: { bold: true }, alignment: 'left' },
                                    { text: 'Jenis Pendidikan', style: { bold: true }, alignment: 'left' },
                                    { text: 'Nama Instanasi', style: { bold: true }, alignment: 'left' },
                                    { text: 'Tahun Mulai', style: { bold: true }, alignment: 'left' },
                                    { text: 'Tahun Selesai', style: { bold: true }, alignment: 'left' },
                                    { text: 'Jurusan', style: { bold: true }, alignment: 'left' },
                                    { text: 'Kota', style: { bold: true }, alignment: 'left' },
                                    { text: 'Sertifikat', style: { bold: true }, alignment: 'left' },
                                ],
                                ...PdfPendidikanFormal
                            ]
                        }
                    },
                    {
                        text: 'Riwayat Pendidikan Informal (dimulai dari yang paling akhir)',
                        style: { bold: true, fontSize: 18 },
                    },
                    {
                        margin: [0, 10, 0, 25],
                        heights: 20,
                        table: {
                            widths: [30, 100, 50, 50, 70, 50, 50, 50],
                            body: [
                                [
                                    { text: 'No', style: { bold: true }, alignment: 'left' },
                                    { text: 'Jenis Pendidikan', style: { bold: true }, alignment: 'left' },
                                    { text: 'Tahun Selesai', style: { bold: true }, alignment: 'left' },
                                    { text: 'Durasi', style: { bold: true }, alignment: 'left' },
                                    { text: 'Nama Program', style: { bold: true }, alignment: 'left' },
                                    { text: 'Bidang', style: { bold: true }, alignment: 'left' },
                                    { text: 'Kota', style: { bold: true }, alignment: 'left' },
                                    { text: 'Sertifikat', style: { bold: true }, alignment: 'left' },
                                ],
                                ...PdfPendidikanInformal
                            ]
                        }
                    },
                    {
                        text: 'Pengalaman Kerja (dimulai dari yang paling akhir, Sebelum bergabung dengan perusahaan ini)',
                        style: { bold: true, fontSize: 18 },
                    },
                    {
                        margin: [0, 10, 0, 25],
                        heights: 20,
                        table: {
                            widths: [30, 100, 50, 50, 100, 70, 50],
                            body: [
                                [
                                    { text: 'No', style: { bold: true }, alignment: 'left' },
                                    { text: 'Nama Perusaaahn', style: { bold: true }, alignment: 'left' },
                                    { text: 'Tahun Mulai', style: { bold: true }, alignment: 'left' },
                                    { text: 'Tahun Selesai', style: { bold: true }, alignment: 'left' },
                                    { text: 'Posisi', style: { bold: true }, alignment: 'left' },
                                    { text: 'Bidang Pekerjaan', style: { bold: true }, alignment: 'left' },
                                    { text: 'Bidang Usaha', style: { bold: true }, alignment: 'left' },
                                ],
                                ...PdfPengalamanKerja
                            ]
                        }
                    },
                ]
            }

            pdfMake.vfs = pdfFonts.pdfMake.vfs
            pdfMake.createPdf(docDefinition).download(Data?.NamaLengkap ? `${Data.NamaLengkap}_Form Data Karyawan.pdf` : 'Form Data Karyawan.pdf')
            return true
        } catch (err) {
            console.log(`🚀 ~file: BiodataExtend.js ~line 220 ~createPDF ~err`, err)
            return false
        }
    }

    return (
        <Fragment>
            {isLoading ?
                <Fragment>
                    {[1, 2, 3, 4, 5,].map((item) =>
                        <Skeleton
                            key={`Biodata_Skeleton_${item}`}
                            height={'5vh'}
                        />
                    )}
                </Fragment> :
                <Fragment>
                    {!DisableEdit || isDownload ?
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="baseline"
                        >
                            {!DisableEdit ?
                                <div
                                    style={{ margin: '0 1vw 0 1vw' }}
                                >
                                    {Edit}
                                </div>
                                : < Fragment ></Fragment>}
                            {isDownload ?
                                <div
                                    style={{ margin: '0 1vw 0 1vw' }}
                                >
                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        style={{ border: '0px solid white', textTransform: 'none', color: 'white', boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                                        onClick={() => createPDF()}
                                    >
                                        Download
                                    </Button>
                                </div>
                                : < Fragment ></Fragment>}
                        </Grid>
                        : <Fragment></Fragment>
                    }

                    <center>
                        <Avatar
                            // id='AvatarImage'
                            alt="Avatar"
                            variant='rounded'
                            src={PhotoProfile}
                            style={{ width: MinWindowFirst ? '10vw' : '60vw', height: MinWindowFirst ? '10vw' : '30vh', margin: '5vh 0 5vh 0', display: 'block', position: 'relative' }}
                        />
                    </center>



                    <div
                        style={st_Row}
                    >
                        <div
                            style={st_HalfLeft}
                        >
                            Full Name
                        </div>
                        <div
                            style={st_HalfRight}
                        >
                            {Data?.NamaLengkap ? Data.NamaLengkap : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={st_HalfLeft}
                        >
                            Nickname
                        </div>
                        <div
                            style={st_HalfRight}
                        >
                            {Data?.NamaPanggilan ? Data.NamaPanggilan : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={st_HalfLeft}
                        >
                            Join Date
                        </div>
                        <div
                            style={st_HalfRight}
                        >
                            {Data?.TanggalMulaiBekerja ? new Date(Data.TanggalMulaiBekerja).toLocaleDateString(['ban', 'id']) : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={st_HalfLeft}
                        >
                            Place, Date of Birth
                        </div>
                        <div
                            style={st_HalfRight}
                        >
                            {Data?.TempatLahir ? `${Data.TempatLahir}, ` : ''}{Data?.TanggalLahir ? new Date(Data.TanggalLahir).toLocaleDateString(['ban', 'id']) : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={st_HalfLeft}
                        >
                            Department
                        </div>
                        <div
                            style={st_HalfRight}
                        >
                            {Data?.Divisi ? Data.Divisi : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft, ...st_BottomBorder }}
                        >
                            Sub
                        </div>
                        <div
                            style={{ ...st_HalfRight, ...st_BottomBorder }}
                        >
                            {Data?.Sub ? Data.Sub : ''}
                        </div>
                    </div>



                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_Full, ...st_Space }}
                        >
                            <b>
                                Personal Data
                            </b>
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_Full }}
                        >
                            <b>
                                Address According to KTP/Passport
                            </b>
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Street Name, RT/RW, No. House
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.AlamatKTP ? Data.AlamatKTP : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Kelurahan/Kecamatan
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.KelurahanKTP ? Data.KelurahanKTP : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            City
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.KotaKTP ? Data.KotaKTP : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Post code
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.KodePosKTP ? Data.KodePosKTP : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Home Phone Number
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.TlpnRumahKTP ? Data.TlpnRumahKTP : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_Full }}
                        >
                            <b>
                                Address
                            </b>
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Street Name, RT/RW, No. House
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.Alamat ? Data.Alamat : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Kelurahan/Kecamatan
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.Kelurahan ? Data.Kelurahan : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            City
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.Kota ? Data.Kota : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Post Code
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.KodePos ? Data.KodePos : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Home Phone Number
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.TlpnRumah ? Data.TlpnRumah : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Phone Number
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.Hp ? Data.Hp : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Gender
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data ? Data.Kelamin === true ? 'Male' : Data.Kelamin === false ? 'Female' : '' : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Religion
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.Agama ? Data.Agama : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Marriage Status
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.Perkawinan ? Data.Perkawinan : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Nationality
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.Kewarganegaraan ? Data.Kewarganegaraan : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            KTP Number
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.KTP ? Data.KTP : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Kartu Keluarga Number
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.KK ? Data.KK : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            NPWP Number
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.NPWP ? Data.NPWP : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            BPJS Kesehatan Number
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.BPJSKesehatan ? Data.BPJSKesehatan : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            BPJS Ketenagakerjaan Number
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.BPJSKetenagakerjaan ? Data.BPJSKetenagakerjaan : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft, ...st_BottomBorder }}
                        >
                            BCA Account Number
                        </div>
                        <div
                            style={{ ...st_HalfRight, ...st_BottomBorder }}
                        >
                            {Data?.BCA ? Data.BCA : ''}
                        </div>
                    </div>



                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_Full, ...st_Space }}
                        >
                            <b>
                                Husband/Wife Data
                            </b>
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Name
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.NamaPasangan ? Data.NamaPasangan : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Birthdate
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.TanggalLahirPasangan ? new Date(Data.TanggalLahirPasangan).toLocaleDateString(['ban', 'id']) : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Recent Education
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.PendidikanPasangan ? Data.PendidikanPasangan : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Job
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.PekerjaanPasangan ? Data.PekerjaanPasangan : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft, ...st_BottomBorder }}
                        >
                            Work Company Name
                        </div>
                        <div
                            style={{ ...st_HalfRight, ...st_BottomBorder }}
                        >
                            {Data?.PerusahaanPasangan ? Data.PerusahaanPasangan : ''}
                        </div>
                    </div>

                    {Anak && Anak.length > 0 ?
                        <Fragment>
                            <div
                                style={st_Row}
                            >
                                <div
                                    style={{ ...st_Full, ...st_Space }}
                                >
                                    <b>
                                        Child Data
                                    </b>
                                </div>
                            </div>
                            {Anak.map((item, index) =>
                                <Fragment
                                    key={`Anak_${index}`}>
                                    <div
                                        style={st_Row}
                                    >
                                        <div
                                            style={{ ...st_HalfLeft }}
                                        >
                                            {index + 1}- Chiled Name
                                        </div>
                                        <div
                                            style={{ ...st_HalfRight }}
                                        >
                                            {item?.Nama ? item.Nama : ''}
                                        </div>
                                    </div>

                                    <div
                                        style={st_Row}
                                    >
                                        <div
                                            style={{ ...st_HalfLeft }}
                                        >
                                            {index + 1}- Gender
                                        </div>
                                        <div
                                            style={{ ...st_HalfRight }}
                                        >
                                            {item ? item.Kelamin === true ? "Male" : item.Kelamin === false ? 'Female' : '' : ''}
                                        </div>
                                    </div>

                                    <div
                                        style={st_Row}
                                    >
                                        <div
                                            style={{ ...st_HalfLeft, ...st_BottomBorder }}
                                        >
                                            {index + 1}- Birthdate
                                        </div>
                                        <div
                                            style={{ ...st_HalfRight, ...st_BottomBorder }}
                                        >
                                            {item?.TanggalLahir ? new Date(item.TanggalLahir).toLocaleDateString(['ban', 'id']) : ''}
                                        </div>
                                    </div>

                                </Fragment>
                            )}
                        </Fragment>
                        : <Fragment></Fragment>
                    }



                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_Full, ...st_Space }}
                        >
                            <b>
                                Family Data
                            </b>
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Father Name
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.NamaAyah ? Data.NamaAyah : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Father Birthdate
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.TanggalLahirAyah ? new Date(Data.TanggalLahirAyah).toLocaleDateString(['ban', 'id']) : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Mother Name
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.NamaIbu ? Data.NamaIbu : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Mother Birthdate
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.TanggalLahirIbu ? new Date(Data.TanggalLahirIbu).toLocaleDateString(['ban', 'id']) : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_Full }}
                        >
                            <b>
                                Parents Address
                            </b>
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Nama Jalan, RT/RW, No.Rumah
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.AlamatOrangTua ? Data.AlamatOrangTua : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Kelurahan/Kecamatan
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.KelurahanOrangTua ? Data.KelurahanOrangTua : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Kota
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.KotaOrangTua ? Data.KotaOrangTua : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Kode Pos
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.KodePosOrangTua ? Data.KodePosOrangTua : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_Full }}
                        >
                            <b>
                                Data Mertua
                            </b>
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >

                            Nama Ayah Mertua
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >

                            {Data?.NamaAyahMertua ? Data.NamaAyahMertua : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >

                            Nama Ibu Mertua
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >

                            {Data?.NamaIbuMertua ? Data.NamaIbuMertua : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >

                            Nama Jalan, RT/RW, No.Rumah
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >

                            {Data?.AlamatMertua ? Data.AlamatMertua : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >

                            Kelurahan/Kecamatan
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >

                            {Data?.KelurahanMertua ? Data.KelurahanMertua : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >

                            Kota
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >

                            {Data?.KotaMertua ? Data.KotaMertua : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft, ...st_BottomBorder }}
                        >

                            Kode Pos
                        </div>
                        <div
                            style={{ ...st_HalfRight, ...st_BottomBorder }}
                        >

                            {Data?.KodePosMertua ? Data.KodePosMertua : ''}
                        </div>
                    </div>



                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_Full, ...st_Space }}
                        >

                            <b>
                                Data Ahli Waris
                            </b>
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >

                            Nama Ahli Waris
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >

                            {Data?.NamaWaris ? Data.NamaWaris : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >

                            Hubungan Dengan Karyawan
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >

                            {Data?.HubunganWaris ? Data.HubunganWaris : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft, ...st_BottomBorder }}
                        >

                            Kontak Ahli Waris
                        </div>
                        <div
                            style={{ ...st_HalfRight, ...st_BottomBorder }}
                        >

                            {Data?.KontakWaris ? Data.KontakWaris : ''}
                        </div>
                    </div>



                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_Full, ...st_Space }}
                        >

                            <b>
                                Kontak Keadaan Darurat
                            </b>
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >

                            Nama
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >

                            {Data?.NamaDarurat ? Data.NamaDarurat : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >

                            No. Telpon
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >

                            {Data?.NoDarurat ? Data.NoDarurat : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft, ...st_BottomBorder }}
                        >

                            Hubungan Dengan Karyawan
                        </div>
                        <div
                            style={{ ...st_HalfRight, ...st_BottomBorder }}
                        >

                            {Data?.HubunganDarurat ? Data.HubunganDarurat : ''}
                        </div>
                    </div>



                    <div
                        style={{ ...st_Space_Table }}
                    >
                        <h2>Riwayat Pendidikan Formal (dimulai dari yang paling akhir)</h2>

                        <CoTable
                            TableId={'Riwayat_Pendidikan_Formal'}
                            HeadData={HeadData}
                            Data={BodyData.length > 0 ? BodyData : [{}]}
                            isHeadLoading={isLoading}
                            isBodyLoading={isLoading}
                            isHorizontalScroll={MinWindowFirst ? false : true}
                        />
                    </div>



                    <div
                        style={{ ...st_Space_Table }}
                    >
                        <h2>Riwayat Pendidikan Informal (dimulai dari yang paling akhir)</h2>

                        <CoTable
                            TableId={'Riwayat_Pendidikan_Informal'}
                            HeadData={HeadData2}
                            Data={BodyData2.length > 0 ? BodyData2 : [{}]}
                            isHeadLoading={isLoading}
                            isBodyLoading={isLoading}
                            isHorizontalScroll={MinWindowFirst ? false : true}
                        />
                    </div>



                    <div
                        style={{ ...st_Space_Table }}
                    >
                        <h2>Pengalaman Kerja (dimulai dari yang paling akhir, Sebelum bergabung dengan perusahaan ini)</h2>

                        <CoTable
                            TableId={'Pengalaman_Kerja'}
                            HeadData={HeadData3}
                            Data={BodyData3.length > 0 ? BodyData3 : [{}]}
                            isHeadLoading={isLoading}
                            isBodyLoading={isLoading}
                            isHorizontalScroll={MinWindowFirst ? false : true}
                        />
                    </div>

                </Fragment>}
        </Fragment >
    )
}

export default BiodataExtend