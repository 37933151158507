import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Load_Pertanyaan_Interview_List, get_Pertanyaan_Interview_Action_Id } from '../../../../Store/Actions/Setting.Action'

import CoTable from '../../../Containers/Table'
import GenericModals from '../../../Containers/GenericModals'

import Add from './Modal/PertanyaanInterview/Add'
import View from './Modal/PertanyaanInterview/View'
import Delete from './Modal/PertanyaanInterview/Delete'

class SettingPertanyaan extends React.Component {
    componentDidMount() {
        this.props.Load_Pertanyaan_Interview_List()
    }
    onClickActionId(Id) {
        this.props.get_Pertanyaan_Interview_Action_Id(Id)
    }
    render() {
        const PertanyaanList = this.props.PertanyaanList
        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Id',
                width: '15%',
            },
            {
                Nama: 'Pertanyaan',
                width: '30%',
            },
            {
                Nama: 'Jenis',
                width: '10%',
            },
            {
                Nama: 'Deskripsi',
                width: '10%',
            },
            {
                Nama: 'View',
                width: '10%',
            },
            {
                Nama: 'Delete',
                width: '10%',
            },
        ]
        const BodyData = PertanyaanList ? PertanyaanList.map((item, index) => {
            return {
                index: index + 1,
                Id: item.Id,
                Name: item.Nama,
                Jenis: item.Jenis,
                Deskripsi: item.Deskripsi,
                View: (
                    <GenericModals
                        size='xl'
                        header='View Pertanyaan'
                        body={<View />}
                        Buttoncolor='primary'
                        ButtononClickeven={() => this.onClickActionId(item.Id)}
                        Buttonlabel={'view'}
                    />
                ),
                Delete: (
                    <GenericModals
                        size='m'
                        header='Delete Form'
                        body={<Delete />}
                        Buttoncolor='error'
                        ButtononClickeven={() => this.onClickActionId(item.Id)}
                        Buttonlabel={'Delete'}
                    />
                )
            }
        }) : []
        const isPertanyaanLoading = this.props.isPertanyaanLoading
        return (
            <Fragment>
                <h2>
                    Setting Pertanyaan Interview
                </h2>

                <center>
                    <GenericModals
                        size='m'
                        header='Tambah Pertanyaan'
                        body={<Add />}
                        Buttoncolor='primary'
                        Buttonstyle={{ width: '90%' }}
                        ButtononClickeven={() => null}
                        Buttonlabel={'+ Pertanyaan'}
                    />
                </center>

                <CoTable
                    TableId='PertanyaanSetting'
                    HeadData={HeadData}
                    isHeadLoading={HeadData ? false : true}
                    Data={BodyData}
                    isBodyLoading={isPertanyaanLoading}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isPertanyaanLoading: state.Setting.isPertanyaanLoading,
    PertanyaanList: state.Setting.PertanyaanList,
})

export default connect(mapStateToProps, { Load_Pertanyaan_Interview_List, get_Pertanyaan_Interview_Action_Id })(SettingPertanyaan)
