import {
    ////// PR
    PR_USER_PR_LOADING,
    PR_USER_PR_LOADED,
    PR_USER_PR_LOADING_DONE,
    PR_ADD_LOADING,
    PR_ADD_LOADING_DONE,
    PR_ACTION_GET_ID,
    PR_ACTION_GET_DATA_LOADING,
    PR_ACTION_GET_DATA_LOADED,
    PR_ACTION_GET_DATA_LOADING_DONE,
    PR_GET_UNCONFIRM_LIST_LOADING,
    PR_GET_UNCONFIRM_LIST_LOADED,
    PR_GET_UNCONFIRM_LIST_LOADING_DONE,
    PR_GET_CONFIRM_LIST_LOADING,
    PR_GET_CONFIRM_LIST_LOADED,
    PR_GET_CONFIRM_LIST_LOADING_DONE,
    PR_KONFIRM_LOADING,
    PR_KONFIRM_LOADING_DONE,
} from '../Actions/Type.Action'

const initialState = {
    ////// PR
    isPRUserPRLoading: false,
    PRUserMP: [],
    isPRAddLoading: false,
    PRActionId: null,
    isPRActionGetDataLoading: false,
    PRData: null,
    PRListItem: [],
    PRSkill:[],
    isPRGetUnConfirmLoading: false,
    PRUnConfirm: [],
    isPRGetConfirmLoading: false,
    PRConfirm: [],
    isPRKonfirmasiLoading: false,
}

// eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        ////// PR
        case PR_USER_PR_LOADING:
            return {
                ...state,
                isPRUserPRLoading: true,
            }
        case PR_USER_PR_LOADED:
            return {
                ...state,
                PRUserMP: action.payload
            }
        case PR_USER_PR_LOADING_DONE:
            return {
                ...state,
                isPRUserPRLoading: false,
            }
        case PR_ADD_LOADING:
            return {
                ...state,
                isPRAddLoading: true
            }
        case PR_ADD_LOADING_DONE:
            return {
                ...state,
                isPRAddLoading: false
            }
        case PR_ACTION_GET_ID:
            return {
                ...state,
                PRActionId: action.payload
            }
        case PR_ACTION_GET_DATA_LOADING:
            return {
                ...state,
                isPRActionGetDataLoading: true
            }
        case PR_ACTION_GET_DATA_LOADED:
            return {
                ...state,
                PRData: action.payload.Detail,
                PRListItem: action.payload.PRListItem,
                PRSkill: action.payload.Skill,
            }
        case PR_ACTION_GET_DATA_LOADING_DONE:
            return {
                ...state,
                isPRActionGetDataLoading: false
            }
            case PR_GET_UNCONFIRM_LIST_LOADING:
                return {
                    ...state,
                    isPRGetUnConfirmLoading: true
                }
            case PR_GET_UNCONFIRM_LIST_LOADED:
                return {
                    ...state,
                    PRUnConfirm: action.payload
                }
            case PR_GET_UNCONFIRM_LIST_LOADING_DONE:
                return {
                    ...state,
                    isPRGetUnConfirmLoading: false
                }
            case PR_GET_CONFIRM_LIST_LOADING:
                return {
                    ...state,
                    isPRGetConfirmLoading: true
                }
            case PR_GET_CONFIRM_LIST_LOADED:
                return {
                    ...state,
                    PRConfirm: action.payload
                }
            case PR_GET_CONFIRM_LIST_LOADING_DONE:
                return {
                    ...state,
                    isPRGetConfirmLoading: false
                }
            case PR_KONFIRM_LOADING:
                return {
                    ...state,
                    isPRKonfirmasiLoading: true,
                }
            case PR_KONFIRM_LOADING_DONE:
                return {
                    ...state,
                    isPRKonfirmasiLoading: false,
                }
        default:
            return state
    }
}