import React, { Fragment } from "react";

import { connect } from "react-redux";
import {
  Update_Biodata,
  get_Biodata_Data,
} from "../../../../../../Store/Actions/Account.Action";
import { Create_Warning_Messages } from "../../../../../../Store/Actions/Messages.Actions";

import {
  Skeleton,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";

import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

import { CharLimit, ImageURL, VariableLimit } from "../../../../../../Global";

class Biodata extends React.Component {
  state = {
    Nama: "",
    TempatLahir: "",
    TanggalLahir: "",
    JenisKelamin: "",
    Telepon: "",
    Alamat: "",
    Tinggi: "",
    Berat: "",
    Hobi: "",
    PendidikanJenjang: "",
    PendidikanNama: "",
    PendidikanJurusan: "",
    RiwayatKerja: [],
    Alasan: "",
    Salary: "",
    KerjaReferensiNama: "",
    KerjaReferensiJabatan: "",
    KerjaReferensiKontak: "",

    KerjaReferensiNamaKolega: "",
    KerjaReferensiKeteranganKolega: "",
    KerjaReferensiKontakKolega: "",
    KerjaReferensiNamaHr: "",
    KerjaReferensiKeteranganHr: "",
    KerjaReferensiKontakHr: "",
    KerjaReferensiNamaDirect: "",
    KerjaReferensiKeteranganDirect: "",
    KerjaReferensiKontakDirect: "",

    English: "",
    Bahasa: "",
    Nikah: "",
    KelaurgaEtana: "",
    KelaurgaEtanaNama: "",
    Relocate: "",
    ReasonNotRelocate: "",
    Vaksin: "",
    ReasonVaksin: "",
    FindOutEtana: "",
    PortofolioLink: "",
    SMFacebook: "",
    SMIG: "",
    SMTwitter: "",
    SMLinkdin: "",
    OldFoto: "",
    Foto: "",
    OldCV: "",
    CV: "",
    isGoToJobList: false,
  };

  componentDidMount() {
    const isOneStep = this.props.isOneStep;
    if (isOneStep) {
      const User = this.props.User;
      const AccountId = User.Id;
      this.props.get_Biodata_Data(AccountId);
    } else {
      const { Biodata } = this.props;

      this.initialState(Biodata);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.Biodata !== prevProps.Biodata) {
      const { Biodata } = this.props;

      this.initialState(Biodata);
    }
  }

  initialState = (Biodata) => {
    const BiodataData = Biodata ? Biodata.Data : {};
    const BiodataRiwayatKerjaData = Biodata ? Biodata.RiwayatKerja : [];
    this.setState({
      Nama: BiodataData.Nama ? BiodataData.Nama : "",
      TempatLahir: BiodataData.TempatLahir ? BiodataData.TempatLahir : "",
      TanggalLahir: BiodataData.TanggalLahir
        ? new Date(BiodataData.TanggalLahir).toISOString().slice(0, 10)
        : "",
      JenisKelamin: BiodataData.JenisKelamin ? BiodataData.JenisKelamin : "",
      Telepon: BiodataData.Telepon ? BiodataData.Telepon : "",
      Alamat: BiodataData.Alamat ? BiodataData.Alamat : "",
      Tinggi: BiodataData.Tinggi ? BiodataData.Tinggi : "",
      Berat: BiodataData.Berat ? BiodataData.Berat : "",
      Hobi: BiodataData.Hobi ? BiodataData.Hobi : "",
      PendidikanJenjang: BiodataData.PendidikanJenjang
        ? BiodataData.PendidikanJenjang
        : "",
      PendidikanNama: BiodataData.PendidikanNama
        ? BiodataData.PendidikanNama
        : "",
      PendidikanJurusan: BiodataData.PendidikanJurusan
        ? BiodataData.PendidikanJurusan
        : "",
      Alasan: BiodataData.Alasan ? BiodataData.Alasan : "",
      Salary: BiodataData.Salary ? BiodataData.Salary : "",
      KerjaReferensiNama: BiodataData.KerjaReferensiNama
        ? BiodataData.KerjaReferensiNama
        : "",
      KerjaReferensiJabatan: BiodataData.KerjaReferensiJabatan
        ? BiodataData.KerjaReferensiJabatan
        : "",
      KerjaReferensiKontak: BiodataData.KerjaReferensiKontak
        ? BiodataData.KerjaReferensiKontak
        : "",

      KerjaReferensiNamaHr: BiodataData.KerjaReferensiNamaHr
        ? BiodataData.KerjaReferensiNamaHr
        : "",
      KerjaReferensiKontakHr: BiodataData.KerjaReferensiKontakHr
        ? BiodataData.KerjaReferensiKontakHr
        : "",
      KerjaReferensiKeteranganHr: BiodataData.KerjaReferensiKeteranganHr
        ? BiodataData.KerjaReferensiKeteranganHr
        : "",

      KerjaReferensiNamaKolega: BiodataData.KerjaReferensiNamaKolega
        ? BiodataData.KerjaReferensiNamaKolega
        : "",
      KerjaReferensiKontakKolega: BiodataData.KerjaReferensiKontakKolega
        ? BiodataData.KerjaReferensiKontakKolega
        : "",
      KerjaReferensiKeteranganKolega: BiodataData.KerjaReferensiKeteranganKolega
        ? BiodataData.KerjaReferensiKeteranganKolega
        : "",

      KerjaReferensiNamaDirect: BiodataData.KerjaReferensiNamaDirect
        ? BiodataData.KerjaReferensiNamaDirect
        : "",
      KerjaReferensiKontakDirect: BiodataData.KerjaReferensiKontakDirect
        ? BiodataData.KerjaReferensiKontakDirect
        : "",
      KerjaReferensiKeteranganDirect: BiodataData.KerjaReferensiKeteranganDirect
        ? BiodataData.KerjaReferensiKeteranganDirect
        : "",

      English: BiodataData.English ? BiodataData.English : "",
      Bahasa: BiodataData.Bahasa ? BiodataData.Bahasa : "",
      Nikah: BiodataData ? (BiodataData.Nikah === true ? "1" : "0") : "",
      KelaurgaEtana: BiodataData
        ? BiodataData.KelaurgaEtana === true
          ? "1"
          : "0"
        : "",
      KelaurgaEtanaNama: BiodataData.KelaurgaEtanaNama
        ? BiodataData.KelaurgaEtanaNama
        : "",
      Relocate: BiodataData ? (BiodataData.Relocate === true ? "1" : "0") : "",
      ReasonNotRelocate: BiodataData.ReasonNotRelocate
        ? BiodataData.ReasonNotRelocate
        : "",
      Vaksin: BiodataData ? (BiodataData.Vaksin === true ? "1" : "0") : "",
      ReasonVaksin: BiodataData.ReasonVaksin ? BiodataData.ReasonVaksin : "",
      FindOutEtana: BiodataData.FindOutEtana ? BiodataData.FindOutEtana : "",
      PortofolioLink: BiodataData.PortofolioLink
        ? BiodataData.PortofolioLink
        : "",
      SMFacebook: BiodataData.SMFacebook ? BiodataData.SMFacebook : "",
      SMIG: BiodataData.SMIG ? BiodataData.SMIG : "",
      SMTwitter: BiodataData.SMTwitter ? BiodataData.SMTwitter : "",
      SMLinkdin: BiodataData.SMLinkdin ? BiodataData.SMLinkdin : "",
      OldFoto: BiodataData.Foto ? BiodataData.Foto : "",
      OldCV: BiodataData.CV ? BiodataData.CV : "",
      RiwayatKerja:
        BiodataRiwayatKerjaData.length > 0
          ? BiodataRiwayatKerjaData
          : [
              {
                Nama: "",
                Jabatan: "",
                Year: "",
                Deskripsi: "",
              },
            ],
    });
  };

  onChangeField = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  goToJobList = (e) => {
    this.setState({ isGoToJobList: true });
  };

  File_OnChange = (E) => {
    this.setState({ [E.target.name]: E.target.files[0] });
  };

  AddDetailListPengalamanKerja = () => {
    const RiwayatKerja = this.state.RiwayatKerja;
    if (
      RiwayatKerja.length === 0 ||
      RiwayatKerja.length >= 0
      // (RiwayatKerja[RiwayatKerja.length - 1].Nama &&
      //     RiwayatKerja[RiwayatKerja.length - 1].Jabatan &&
      //     RiwayatKerja[RiwayatKerja.length - 1].Year
      // )
    ) {
      const newData = {
        Nama: "",
        Jabatan: "",
        Year: "",
        Deskripsi: "",
      };
      RiwayatKerja.push(newData);
      this.setState({ RiwayatKerja: RiwayatKerja });
    } else {
      this.props.Create_Warning_Messages(
        null,
        "Please fill in the blank first"
      );
    }
  };

  DeleteDetailListPengalamanKerja = (Index) => {
    const RiwayatKerja = this.state.RiwayatKerja;
    RiwayatKerja.splice(Index, 1);
    this.setState({ RiwayatKerja: RiwayatKerja });
  };

  OnChangeMapItemPengalamanKerja = (e, Index, Order) => {
    const RiwayatKerja = this.state.RiwayatKerja;
    if (Order === 1) {
      RiwayatKerja[Index].Nama = e.target.value;
    } else if (Order === 2) {
      RiwayatKerja[Index].Jabatan = e.target.value;
    } else if (Order === 3) {
      if (Number(e.target.value) > 99) {
        this.props.Create_Warning_Messages(
          null,
          "Work experience in related company, not the starting/ending year"
        );
      } else if (Number(e.target.value) < 0)
        this.props.Create_Warning_Messages(
          null,
          "Work experience min 0 or blank"
        );
      else {
        RiwayatKerja[Index].Year = e.target.value;
      }
    } else if (Order === 4) {
      RiwayatKerja[Index].Deskripsi = e.target.value;
    } else {
    }
    this.setState({ RiwayatKerja: RiwayatKerja });
  };

  formOnSubmit = (e) => {
    e.preventDefault();

    const BiodataData = this.props.Biodata.Data;
    this.props.Update_Biodata(
      BiodataData.Id,
      this.state.Nama,
      this.state.TempatLahir,
      this.state.TanggalLahir,
      this.state.JenisKelamin,
      this.state.Telepon,
      this.state.Alamat,
      this.state.Tinggi,
      this.state.Berat,
      this.state.Hobi,
      this.state.PendidikanJenjang,
      this.state.PendidikanNama,
      this.state.PendidikanJurusan,
      this.state.Alasan,
      this.state.Salary,
      this.state.KerjaReferensiNama,
      this.state.KerjaReferensiJabatan,
      this.state.KerjaReferensiKontak,

      this.state.KerjaReferensiNamaKolega,
      this.state.KerjaReferensiKontakKolega,
      this.state.KerjaReferensiKeteranganKolega,

      this.state.KerjaReferensiNamaHr,
      this.state.KerjaReferensiKontakHr,
      this.state.KerjaReferensiKeteranganHr,

      this.state.KerjaReferensiNamaDirect,
      this.state.KerjaReferensiKontakDirect,
      this.state.KerjaReferensiKeteranganDirect,

      this.state.English,
      this.state.Bahasa,
      this.state.Nikah,
      this.state.KelaurgaEtana,
      this.state.KelaurgaEtanaNama,
      this.state.Relocate,
      this.state.ReasonNotRelocate,
      this.state.Vaksin,
      this.state.ReasonVaksin,
      this.state.FindOutEtana,
      this.state.PortofolioLink,
      this.state.SMFacebook,
      this.state.SMIG,
      this.state.SMTwitter,
      this.state.SMLinkdin,
      this.state.Foto,
      this.state.CV,
      this.state.RiwayatKerja,
      this.state.isGoToJobList,

      this.props.isOneStep,
      this.props.User
    );
  };

  render() {
    const isOneStep = this.props.isOneStep;

    // const BiodataData = this.props.Biodata.Data
    const isBiodataLoading = this.props.isBiodataLoading;

    const OpJenisKelamin = [
      {
        Option: "Male",
        value: 1,
      },
      {
        Option: "Female",
        value: 0,
      },
    ];

    const OpPendidikan = [
      {
        Option: "SMA",
        value: "SMA",
      },
      {
        Option: "D3",
        value: "D3",
      },
      {
        Option: "D4",
        value: "D4",
      },
      {
        Option: "S1",
        value: "S1",
      },
      {
        Option: "S2",
        value: "S2",
      },
      {
        Option: "S3",
        value: "S3",
      },
    ];

    const {
      Nama,
      TempatLahir,
      TanggalLahir,
      JenisKelamin,
      Telepon,
      Alamat,
      Tinggi,
      Berat,
      Hobi,
      PendidikanJenjang,
      PendidikanNama,
      PendidikanJurusan,
      RiwayatKerja,
      Alasan,
      Salary,
      KerjaReferensiNama,
      KerjaReferensiJabatan,
      KerjaReferensiKontak,

      KerjaReferensiNamaKolega,
      KerjaReferensiKontakKolega,
      KerjaReferensiKeteranganKolega,

      KerjaReferensiNamaHr,
      KerjaReferensiKontakHr,
      KerjaReferensiKeteranganHr,

      KerjaReferensiNamaDirect,
      KerjaReferensiKontakDirect,
      KerjaReferensiKeteranganDirect,

      English,
      Bahasa,
      Nikah,
      KelaurgaEtana,
      KelaurgaEtanaNama,
      Relocate,
      ReasonNotRelocate,
      Vaksin,
      ReasonVaksin,
      FindOutEtana,
      PortofolioLink,
      SMFacebook,
      SMIG,
      SMTwitter,
      SMLinkdin,
      OldFoto,
      OldCV,
    } = this.state;

    const st_textfield = {
      marginTop: "10px",
      marginBottom: "10px",
      width: "100%",
      fontFamily: "Montserrat, Segoe UI",
      fontSize: "2vw",
    };
    const st_20 = { width: "20%" };
    const st_70 = { width: "70%" };
    const st_Half = { width: "45%" };

    const st_divaddtextfield = {
      display: "flex",
      alignItems: "center",
      margin: "0 0 2% 2%",
    };
    const st_TextSmall = {
      width: "20%",
      margin: "0 1% 0 0",
      fontFamily: "Montserrat, Segoe UI",
      fontSize: "2vw",
    };
    const st_icon = {
      color: "grey",
      fontSize: "180%",
      margin: "0 0 0 1%",
      cursor: "pointer",
    };
    const st_TextHelp = { color: "#00000099" };

    let filledFieldsCount = 0;
    if (SMFacebook && SMFacebook !== "") filledFieldsCount++;
    if (SMIG && SMIG !== "") filledFieldsCount++;
    if (SMTwitter && SMTwitter !== "") filledFieldsCount++;
    if (SMLinkdin && SMLinkdin !== "") filledFieldsCount++;

    const requiredFields = filledFieldsCount < 2 ? "required" : "";

    return (
      <Fragment>
        {isBiodataLoading ? (
          <Fragment>
            {[1, 2, 3, 4, 5].map((item) => (
              <Skeleton key={`Biodata_Skeleton_${item}`} height={"5vh"} />
            ))}
          </Fragment>
        ) : (
          <Fragment>
            <form onSubmit={this.formOnSubmit}>
              <h2>Biodata</h2>
              <TextField
                style={st_textfield}
                variant="outlined"
                type="text"
                label="Full Name"
                name="Nama"
                onChange={this.onChangeField}
                required
                value={Nama ? Nama : ""}
                error={Nama.length > CharLimit.Biodata.Nama ? true : false}
                helperText={
                  Nama.length > CharLimit.Biodata.Nama
                    ? `Char Limit ${CharLimit.Biodata.Nama}`
                    : ""
                }
              />
              <TextField
                style={{ ...st_textfield, ...st_20 }}
                variant="outlined"
                type="text"
                label="Place of Birth"
                name="TempatLahir"
                required
                onChange={this.onChangeField}
                value={TempatLahir ? TempatLahir : ""}
                error={
                  TempatLahir.length > CharLimit.Biodata.TempatLahir
                    ? true
                    : false
                }
                helperText={
                  TempatLahir.length > CharLimit.Biodata.TempatLahir
                    ? `Char Limit ${CharLimit.Biodata.TempatLahir}`
                    : ""
                }
              />
              <TextField
                style={{ ...st_textfield, ...st_70, marginLeft: "5%" }}
                variant="outlined"
                type="date"
                label="Date of Birth"
                name="TanggalLahir"
                InputLabelProps={{ shrink: true }}
                onChange={this.onChangeField}
                required
                value={TanggalLahir ? TanggalLahir : ""}
              />
              <TextField
                style={{ ...st_textfield, ...st_Half }}
                variant="outlined"
                type="number"
                label="Phone Number (With WHATSAPP)"
                required
                name="Telepon"
                onChange={this.onChangeField}
                value={Telepon ? Telepon : ""}
                error={
                  Telepon.length > CharLimit.Biodata.Telepon ? true : false
                }
                helperText={
                  Telepon.length > CharLimit.Biodata.Telepon
                    ? `Char Limit ${CharLimit.Biodata.Telepon}`
                    : ""
                }
              />
              <FormControl
                style={{ ...st_textfield, ...st_Half, marginLeft: "5%" }}
                variant="filled"
                required
              >
                <InputLabel shrink>Gender</InputLabel>
                <Select
                  native
                  style={{ backgroundColor: "white" }}
                  onChange={this.onChangeField}
                  label="Sex"
                  name="JenisKelamin"
                  value={JenisKelamin ? JenisKelamin : ""}
                >
                  <option value="" disabled>
                    {" "}
                    -- select an option --{" "}
                  </option>
                  {OpJenisKelamin.map((option, index) => (
                    <option key={`OpJenisKelamin${index}`} value={option.value}>
                      {option.Option}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <TextField
                style={st_textfield}
                variant="outlined"
                type="text"
                multiline
                required
                rows={3}
                label="Address"
                name="Alamat"
                onChange={this.onChangeField}
                value={Alamat ? Alamat : ""}
                error={Alamat.length > CharLimit.Biodata.Alamat ? true : false}
                helperText={
                  Alamat.length > CharLimit.Biodata.Alamat
                    ? `Char Limit ${CharLimit.Biodata.Alamat}`
                    : ""
                }
              />
              <TextField
                style={{ ...st_textfield, ...st_Half }}
                variant="outlined"
                type="number"
                label="Height (cm)"
                required
                name="Tinggi"
                onChange={this.onChangeField}
                value={Tinggi ? Tinggi : ""}
              />
              <TextField
                style={{ ...st_textfield, ...st_Half, marginLeft: "5%" }}
                variant="outlined"
                type="number"
                label="Weight (kg)"
                required
                name="Berat"
                onChange={this.onChangeField}
                value={Berat ? Berat : ""}
              />
              <TextField
                style={st_textfield}
                variant="outlined"
                type="text"
                label="Hobby"
                name="Hobi"
                onChange={this.onChangeField}
                value={Hobi ? Hobi : ""}
                error={Hobi.length > CharLimit.Biodata.Hobi ? true : false}
                helperText={
                  Hobi.length > CharLimit.Biodata.Hobi
                    ? `Char Limit ${CharLimit.Biodata.Hobi}`
                    : ""
                }
              />

              {/* <label> Photo :</label><br />
                            <input type='file' accept='image/*' onChange={this.File_OnChange} name='Foto' /><br /><br /> */}
              {/* <label>Curriculum Vitae:</label><br />
                            <input type='file' accept="application/pdf" onChange={this.File_OnChange} name='CV' /><br /> */}
              <h2>Education (Latest/Currently)</h2>
              <FormControl
                style={{ ...st_textfield, ...st_20 }}
                variant="filled"
                required
              >
                <InputLabel shrink>
                  Latest/Currently Formal Education
                </InputLabel>
                <Select
                  native
                  style={{ backgroundColor: "white" }}
                  onChange={this.onChangeField}
                  label="Latest/Currently Formal Education"
                  name="PendidikanJenjang"
                  value={PendidikanJenjang ? PendidikanJenjang : ""}
                >
                  <option value="" disabled>
                    {" "}
                    -- select an option --{" "}
                  </option>
                  {OpPendidikan.map((option, index) => (
                    <option key={`OpPendidikan${index}`} value={option.value}>
                      {option.Option}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <TextField
                style={{ ...st_textfield, width: "30%", marginLeft: "5%" }}
                variant="outlined"
                type="text"
                label="University Name"
                name="PendidikanNama"
                onChange={this.onChangeField}
                required
                value={PendidikanNama ? PendidikanNama : ""}
                helperText="Penulisan tidak disingkat dan sesuai ijazah"
                error={
                  PendidikanNama.length > CharLimit.Biodata.PendidikanNama
                    ? true
                    : false
                }
              />
              <TextField
                style={{ ...st_textfield, width: "30%", marginLeft: "5%" }}
                variant="outlined"
                type="text"
                label="Major"
                name="PendidikanJurusan"
                onChange={this.onChangeField}
                required
                value={PendidikanJurusan ? PendidikanJurusan : ""}
                helperText="Penulisan tidak disingkat dan sesuai ijazah"
                error={
                  PendidikanJurusan.length > CharLimit.Biodata.PendidikanJurusan
                    ? true
                    : false
                }
              />
              <h2>Employment History</h2>
              {RiwayatKerja.length === 0 ? (
                <Fragment>
                  <AddCircleRoundedIcon
                    onClick={this.AddDetailListPengalamanKerja}
                    style={st_icon}
                  />
                </Fragment>
              ) : (
                <Fragment></Fragment>
              )}

              {RiwayatKerja.map((item, index) => (
                <div style={st_divaddtextfield} key={`RiwayatKerja_${index}`}>
                  <label style={{ marginRight: "1%" }}>{index + 1}</label>

                  <TextField
                    style={st_TextSmall}
                    variant="outlined"
                    type="text"
                    label="Company Name"
                    name="Nama"
                    value={RiwayatKerja[index].Nama}
                    onChange={(e) =>
                      this.OnChangeMapItemPengalamanKerja(e, index, 1)
                    }
                    error={
                      RiwayatKerja[index].Nama.length >
                      CharLimit.Biodata.PendidikanNama
                        ? true
                        : false
                    }
                    helperText={
                      RiwayatKerja[index].Nama.length >
                      CharLimit.Biodata.PendidikanNama
                        ? `Char Limit ${CharLimit.Biodata.PendidikanNama}`
                        : ""
                    }
                  />

                  <TextField
                    style={st_TextSmall}
                    variant="outlined"
                    type="text"
                    label="Position"
                    name="Jabatan"
                    value={RiwayatKerja[index].Jabatan}
                    onChange={(e) =>
                      this.OnChangeMapItemPengalamanKerja(e, index, 2)
                    }
                    error={
                      RiwayatKerja[index].Jabatan.length >
                      CharLimit.Biodata.PendidikanNama
                        ? true
                        : false
                    }
                    helperText={
                      RiwayatKerja[index].Jabatan.length >
                      CharLimit.Biodata.PendidikanNama
                        ? `Char Limit ${CharLimit.Biodata.PendidikanNama}`
                        : ""
                    }
                  />

                  <TextField
                    style={st_TextSmall}
                    variant="outlined"
                    type="number"
                    label="Years of service (Year)"
                    name="Year"
                    value={RiwayatKerja[index].Year}
                    onChange={(e) =>
                      this.OnChangeMapItemPengalamanKerja(e, index, 3)
                    }
                  />

                  <TextField
                    style={st_TextSmall}
                    variant="outlined"
                    type="text"
                    label="Job Description"
                    name="Deskripsi"
                    value={RiwayatKerja[index].Deskripsi}
                    onChange={(e) =>
                      this.OnChangeMapItemPengalamanKerja(e, index, 4)
                    }
                  />

                  <DeleteOutlineRoundedIcon
                    onClick={(e) => this.DeleteDetailListPengalamanKerja(index)}
                    style={st_icon}
                  />

                  <AddCircleRoundedIcon
                    onClick={this.AddDetailListPengalamanKerja}
                    style={st_icon}
                  />
                </div>
              ))}

              <TextField
                style={st_textfield}
                variant="outlined"
                type="text"
                label="Reason For Leaving Your Last Company"
                name="Alasan"
                onChange={this.onChangeField}
                value={Alasan ? Alasan : ""}
              />
              <TextField
                style={st_textfield}
                variant="outlined"
                type="text"
                label="Expected Salary"
                name="Salary"
                required
                onChange={this.onChangeField}
                value={Salary ? Salary : ""}
                error={Salary.length > CharLimit.Biodata.Salary ? true : false}
                helperText={
                  Salary.length > CharLimit.Biodata.Salary
                    ? `Char Limit ${CharLimit.Biodata.Salary}`
                    : ""
                }
              />
              <h2>Reference</h2>
              {/* <TextField
                                style={{ ...st_textfield, width: '30%' }}
                                variant='outlined'
                                type='text'
                                label='Company Name'
                                name='KerjaReferensiNama'
                                onChange={this.onChangeField}
                                value={KerjaReferensiNama ? KerjaReferensiNama : ''}
                                error={KerjaReferensiNama.length > CharLimit.Biodata.KerjaReferensiNama ? true : false}
                                helperText={KerjaReferensiNama.length > CharLimit.Biodata.KerjaReferensiNama ? `Char Limit ${CharLimit.Biodata.KerjaReferensiNama}` : ''}
                            />
                            <TextField
                                style={{ ...st_textfield, width: '30%', marginLeft: '5%' }}
                                variant='outlined'
                                type='text'
                                label='Position'
                                name='KerjaReferensiJabatan'
                                onChange={this.onChangeField}
                                value={KerjaReferensiJabatan ? KerjaReferensiJabatan : ''}
                                error={KerjaReferensiJabatan.length > CharLimit.Biodata.KerjaReferensiJabatan ? true : false}
                                helperText={KerjaReferensiJabatan.length > CharLimit.Biodata.KerjaReferensiJabatan ? `Char Limit ${CharLimit.Biodata.KerjaReferensiJabatan}` : ''}
                            />
                            <TextField
                                style={{ ...st_textfield, width: '30%', marginLeft: '5%' }}
                                variant='outlined'
                                type='number'
                                label='Contact Person'
                                name='KerjaReferensiKontak'
                                onChange={this.onChangeField}
                                value={KerjaReferensiKontak ? KerjaReferensiKontak : ''}
                                error={KerjaReferensiKontak.length > CharLimit.Biodata.KerjaReferensiKontak ? true : false}
                                helperText={KerjaReferensiKontak.length > CharLimit.Biodata.KerjaReferensiKontak ? `Char Limit ${CharLimit.Biodata.KerjaReferensiKontak}` : ''}
                            /> */}

              <table
                style={{
                  width: "100%",
                  textAlign: "center",
                  borderCollapse: "collapse",
                  border: "2px solid black",
                }}
              >
                <thead style={{ backgroundColor: "lightgray" }}>
                  <tr>
                    <th style={{ border: "2px solid black", width: "30%" }}>
                      Source
                    </th>
                    <th style={{ border: "2px solid black" }}>Name</th>
                    <th style={{ border: "2px solid black" }}>Phone Number</th>
                    <th style={{ border: "2px solid black" }}>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{ border: "2px solid black", fontWeight: "bold" }}
                    >
                      Collegue
                    </td>
                    <td style={{ border: "2px solid black" }}>
                      <TextField
                        style={{ ...st_textfield, width: "80%" }}
                        variant="standard"
                        type="text"
                        name="KerjaReferensiNamaKolega"
                        onChange={this.onChangeField}
                        value={
                          KerjaReferensiNamaKolega
                            ? KerjaReferensiNamaKolega
                            : ""
                        }
                        error={
                          KerjaReferensiNamaKolega.length >
                          CharLimit.Biodata.KerjaReferensiNamaKolega
                            ? true
                            : false
                        }
                        helperText={
                          KerjaReferensiNamaKolega.length >
                          CharLimit.Biodata.KerjaReferensiNamaKolega
                            ? `Char Limit ${CharLimit.Biodata.KerjaReferensiNamaKolega}`
                            : ""
                        }
                      />
                    </td>
                    <td style={{ border: "2px solid black" }}>
                      <TextField
                        style={{ ...st_textfield, width: "80%" }}
                        variant="standard"
                        type="number"
                        name="KerjaReferensiKontakKolega"
                        onChange={this.onChangeField}
                        value={
                          KerjaReferensiKontakKolega
                            ? KerjaReferensiKontakKolega
                            : ""
                        }
                        error={
                          KerjaReferensiKontakKolega.length >
                          CharLimit.Biodata.KerjaReferensiKontakKolega
                            ? true
                            : false
                        }
                        helperText={
                          KerjaReferensiKontakKolega.length >
                          CharLimit.Biodata.KerjaReferensiKontakKolega
                            ? `Char Limit ${CharLimit.Biodata.KerjaReferensiKontakKolega}`
                            : ""
                        }
                      />
                    </td>
                    <td style={{ border: "2px solid black" }}>
                      <TextField
                        style={{ ...st_textfield, width: "80%" }}
                        variant="standard"
                        type="text"
                        name="KerjaReferensiKeteranganKolega"
                        onChange={this.onChangeField}
                        value={
                          KerjaReferensiKeteranganKolega
                            ? KerjaReferensiKeteranganKolega
                            : ""
                        }
                        error={
                          KerjaReferensiKeteranganKolega.length >
                          CharLimit.Biodata.KerjaReferensiKeteranganKolega
                            ? true
                            : false
                        }
                        helperText={
                          KerjaReferensiKeteranganKolega.length >
                          CharLimit.Biodata.KerjaReferensiKeteranganKolega
                            ? `Char Limit ${CharLimit.Biodata.KerjaReferensiKeteranganKolega}`
                            : ""
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ border: "2px solid black", fontWeight: "bold" }}
                    >
                      HR from previous company
                    </td>
                    <td style={{ border: "2px solid black" }}>
                      <TextField
                        style={{ ...st_textfield, width: "80%" }}
                        variant="standard"
                        type="text"
                        name="KerjaReferensiNamaHr"
                        onChange={this.onChangeField}
                        value={KerjaReferensiNamaHr ? KerjaReferensiNamaHr : ""}
                        error={
                          KerjaReferensiNamaHr.length >
                          CharLimit.Biodata.KerjaReferensiNamaHr
                            ? true
                            : false
                        }
                        helperText={
                          KerjaReferensiNamaHr.length >
                          CharLimit.Biodata.KerjaReferensiNamaHr
                            ? `Char Limit ${CharLimit.Biodata.KerjaReferensiNamaHr}`
                            : ""
                        }
                      />
                    </td>
                    <td style={{ border: "2px solid black" }}>
                      <TextField
                        style={{ ...st_textfield, width: "80%" }}
                        variant="standard"
                        type="number"
                        name="KerjaReferensiKontakHr"
                        onChange={this.onChangeField}
                        value={
                          KerjaReferensiKontakHr ? KerjaReferensiKontakHr : ""
                        }
                        error={
                          KerjaReferensiKontakHr.length >
                          CharLimit.Biodata.KerjaReferensiKontakHr
                            ? true
                            : false
                        }
                        helperText={
                          KerjaReferensiKontakHr.length >
                          CharLimit.Biodata.KerjaReferensiKontakHr
                            ? `Char Limit ${CharLimit.Biodata.KerjaReferensiKontakHr}`
                            : ""
                        }
                      />
                    </td>
                    <td style={{ border: "2px solid black" }}>
                      <TextField
                        style={{ ...st_textfield, width: "80%" }}
                        variant="standard"
                        type="text"
                        name="KerjaReferensiKeteranganHr"
                        onChange={this.onChangeField}
                        value={
                          KerjaReferensiKeteranganHr
                            ? KerjaReferensiKeteranganHr
                            : ""
                        }
                        error={
                          KerjaReferensiKeteranganHr.length >
                          CharLimit.Biodata.KerjaReferensiKeteranganHr
                            ? true
                            : false
                        }
                        helperText={
                          KerjaReferensiKeteranganHr.length >
                          CharLimit.Biodata.KerjaReferensiKeteranganHr
                            ? `Char Limit ${CharLimit.Biodata.KerjaReferensiKeteranganHr}`
                            : ""
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ border: "2px solid black", fontWeight: "bold" }}
                    >
                      Direct Supervisor from previous company
                    </td>
                    <td style={{ border: "2px solid black" }}>
                      <TextField
                        style={{ ...st_textfield, width: "80%" }}
                        variant="standard"
                        type="text"
                        name="KerjaReferensiNamaDirect"
                        onChange={this.onChangeField}
                        value={
                          KerjaReferensiNamaDirect
                            ? KerjaReferensiNamaDirect
                            : ""
                        }
                        error={
                          KerjaReferensiNamaDirect.length >
                          CharLimit.Biodata.KerjaReferensiNamaDirect
                            ? true
                            : false
                        }
                        helperText={
                          KerjaReferensiNamaDirect.length >
                          CharLimit.Biodata.KerjaReferensiNamaDirect
                            ? `Char Limit ${CharLimit.Biodata.KerjaReferensiNamaDirect}`
                            : ""
                        }
                      />
                    </td>
                    <td style={{ border: "2px solid black" }}>
                      <TextField
                        style={{ ...st_textfield, width: "80%" }}
                        variant="standard"
                        type="number"
                        name="KerjaReferensiKontakDirect"
                        onChange={this.onChangeField}
                        value={
                          KerjaReferensiKontakDirect
                            ? KerjaReferensiKontakDirect
                            : ""
                        }
                        error={
                          KerjaReferensiKontakDirect.length >
                          CharLimit.Biodata.KerjaReferensiKontakDirect
                            ? true
                            : false
                        }
                        helperText={
                          KerjaReferensiKontakDirect.length >
                          CharLimit.Biodata.KerjaReferensiKontakDirect
                            ? `Char Limit ${CharLimit.Biodata.KerjaReferensiKontakDirect}`
                            : ""
                        }
                      />
                    </td>
                    <td style={{ border: "2px solid black" }}>
                      <TextField
                        style={{ ...st_textfield, width: "80%" }}
                        variant="standard"
                        type="text"
                        name="KerjaReferensiKeteranganDirect"
                        onChange={this.onChangeField}
                        value={
                          KerjaReferensiKeteranganDirect
                            ? KerjaReferensiKeteranganDirect
                            : ""
                        }
                        error={
                          KerjaReferensiKeteranganDirect.length >
                          CharLimit.Biodata.KerjaReferensiKeteranganDirect
                            ? true
                            : false
                        }
                        helperText={
                          KerjaReferensiKeteranganDirect.length >
                          CharLimit.Biodata.KerjaReferensiKeteranganDirect
                            ? `Char Limit ${CharLimit.Biodata.KerjaReferensiKeteranganDirect}`
                            : ""
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>Additional Information</h2>
              <FormControl
                style={{ ...st_textfield }}
                variant="filled"
                required
              >
                <InputLabel shrink>English Skill</InputLabel>
                <Select
                  native
                  style={{ backgroundColor: "white" }}
                  onChange={this.onChangeField}
                  label="English Skill"
                  name="English"
                  value={English ? English : ""}
                >
                  <option value="" disabled>
                    {" "}
                    -- select an option --{" "}
                  </option>
                  {["Basic", "Intermediate", "Proficient"].map(
                    (option, index) => (
                      <option key={`English_${index}`} value={option}>
                        {option}
                      </option>
                    )
                  )}
                </Select>
              </FormControl>
              <TextField
                style={st_textfield}
                variant="outlined"
                type="text"
                label="Other Language Skill"
                name="Bahasa"
                onChange={this.onChangeField}
                value={Bahasa ? Bahasa : ""}
              />
              <FormControl
                style={{ ...st_textfield }}
                variant="filled"
                required
              >
                <InputLabel shrink>Marital Status</InputLabel>
                <Select
                  native
                  style={{ backgroundColor: "white" }}
                  onChange={this.onChangeField}
                  label="Marital Status"
                  name="Nikah"
                  value={Nikah ? Nikah : ""}
                >
                  <option value="" disabled>
                    {" "}
                    -- select an option --{" "}
                  </option>
                  {[
                    { Option: "Married", Value: 1 },
                    { Option: "Single", Value: 0 },
                  ].map((option, index) => (
                    <option key={`English_${index}`} value={option.Value}>
                      {option.Option}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                style={{ ...st_textfield }}
                variant="filled"
                required
              >
                <InputLabel shrink>
                  Do You Have Any Family/Friend as Employee In Etana
                </InputLabel>
                <Select
                  native
                  style={{ backgroundColor: "white" }}
                  onChange={this.onChangeField}
                  label="Do You Have Any Family/Friend as Employee In Etana"
                  name="KelaurgaEtana"
                  value={
                    KelaurgaEtana || KelaurgaEtana === false
                      ? KelaurgaEtana
                      : ""
                  }
                >
                  <option value="" disabled>
                    {" "}
                    -- select an option --{" "}
                  </option>
                  {[
                    { Option: "Yes", Value: 1 },
                    { Option: "No", Value: 0 },
                  ].map((option, index) => (
                    <option key={`English_${index}`} value={option.Value}>
                      {option.Option}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {(KelaurgaEtana &&
                (KelaurgaEtana === 1 || KelaurgaEtana === "1")) ||
              KelaurgaEtana === true ? (
                <TextField
                  style={st_textfield}
                  variant="outlined"
                  type="text"
                  label="What Is His/Her Name? And What Posistion"
                  name="KelaurgaEtanaNama"
                  required={KelaurgaEtana ? true : false}
                  onChange={this.onChangeField}
                  value={KelaurgaEtanaNama ? KelaurgaEtanaNama : ""}
                  error={
                    KelaurgaEtanaNama.length >
                    CharLimit.Biodata.KelaurgaEtanaNama
                      ? true
                      : false
                  }
                  helperText={
                    KelaurgaEtanaNama.length >
                    CharLimit.Biodata.KelaurgaEtanaNama
                      ? `Char Limit ${CharLimit.Biodata.KelaurgaEtanaNama}`
                      : ""
                  }
                />
              ) : (
                <Fragment></Fragment>
              )}
              <FormControl
                style={{ ...st_textfield }}
                variant="filled"
                required
              >
                <InputLabel shrink>Are You Willing To Relocate</InputLabel>
                <Select
                  native
                  style={{ backgroundColor: "white" }}
                  onChange={this.onChangeField}
                  label="Are You Willing To Relocate"
                  name="Relocate"
                  value={Relocate || Relocate === false ? Relocate : ""}
                >
                  <option value="" disabled>
                    {" "}
                    -- select an option --{" "}
                  </option>
                  {[
                    { Option: "Yes", Value: 1 },
                    { Option: "No", Value: 0 },
                  ].map((option, index) => (
                    <option key={`English_${index}`} value={option.Value}>
                      {option.Option}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {(Relocate && (Relocate === 0 || Relocate === "0")) ||
              Relocate === false ? (
                <TextField
                  style={st_textfield}
                  variant="outlined"
                  type="text"
                  label="If You Are Not Willing To Relocate, Please Give Your Reson"
                  name="ReasonNotRelocate"
                  required={Relocate ? true : false}
                  onChange={this.onChangeField}
                  value={ReasonNotRelocate ? ReasonNotRelocate : ""}
                />
              ) : (
                <Fragment></Fragment>
              )}
              <FormControl
                style={{ ...st_textfield }}
                variant="filled"
                required
              >
                <InputLabel shrink>Have You Been Vaccinated</InputLabel>
                <Select
                  native
                  style={{ backgroundColor: "white" }}
                  onChange={this.onChangeField}
                  label="Have You Been Vaccinated"
                  name="Vaksin"
                  value={Vaksin}
                >
                  <option value="" disabled>
                    {" "}
                    -- select an option --{" "}
                  </option>
                  {[
                    { Option: "Yes", Value: 1 },
                    { Option: "No", Value: 0 },
                  ].map((option, index) => (
                    <option key={`English_${index}`} value={option.Value}>
                      {option.Option}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {(Vaksin && (Vaksin === 0 || Vaksin === "0")) ||
              Vaksin === false ? (
                <TextField
                  style={st_textfield}
                  variant="outlined"
                  type="text"
                  label="If You Have Not Been Vaccinated, Expalin Your Reason"
                  name="ReasonVaksin"
                  required
                  onChange={this.onChangeField}
                  value={ReasonVaksin ? ReasonVaksin : ""}
                />
              ) : (
                <Fragment></Fragment>
              )}
              <TextField
                style={st_textfield}
                variant="outlined"
                type="text"
                label="How Did You Find Out About This Ipportunity at Etana"
                name="FindOutEtana"
                required
                onChange={this.onChangeField}
                value={FindOutEtana ? FindOutEtana : ""}
                error={
                  FindOutEtana.length > CharLimit.Biodata.FindOutEtana
                    ? true
                    : false
                }
                helperText={
                  FindOutEtana.length > CharLimit.Biodata.FindOutEtana
                    ? `Char Limit ${CharLimit.Biodata.FindOutEtana}`
                    : ""
                }
              />
              <TextField
                style={st_textfield}
                variant="outlined"
                type="text"
                label="Your Portofolio Link"
                name="PortofolioLink"
                onChange={this.onChangeField}
                value={PortofolioLink ? PortofolioLink : ""}
              />
              <h3>Social Media</h3>
              <TextField
                style={st_textfield}
                variant="outlined"
                type="text"
                label="Facebook"
                name="SMFacebook"
                onChange={this.onChangeField}
                value={SMFacebook ? SMFacebook : ""}
                required={requiredFields}
                helperText={
                  SMFacebook ? (
                    <a
                      href={`${VariableLimit.SocialMediaInitLink.Facebook}${SMFacebook}`}
                      target="_blank"
                      rel="noreferrer"
                      style={st_TextHelp}
                    >
                      {`${VariableLimit.SocialMediaInitLink.Facebook}${SMFacebook}`}
                    </a>
                  ) : (
                    ""
                  )
                }
              />
              <TextField
                style={st_textfield}
                variant="outlined"
                type="text"
                label="Instagram"
                name="SMIG"
                onChange={this.onChangeField}
                value={SMIG ? SMIG : ""}
                required={requiredFields}
                helperText={
                  SMIG ? (
                    <a
                      href={`${VariableLimit.SocialMediaInitLink.Instagram}${SMIG}`}
                      target="_blank"
                      rel="noreferrer"
                      style={st_TextHelp}
                    >
                      {`${VariableLimit.SocialMediaInitLink.Instagram}${SMIG}`}
                    </a>
                  ) : (
                    ""
                  )
                }
              />
              <TextField
                style={st_textfield}
                variant="outlined"
                type="text"
                label="Twitter"
                name="SMTwitter"
                onChange={this.onChangeField}
                value={SMTwitter ? SMTwitter : ""}
                required={requiredFields}
                helperText={
                  SMTwitter ? (
                    <a
                      href={`${VariableLimit.SocialMediaInitLink.Twitter}${SMTwitter}`}
                      target="_blank"
                      rel="noreferrer"
                      style={st_TextHelp}
                    >
                      {`${VariableLimit.SocialMediaInitLink.Twitter}${SMTwitter}`}
                    </a>
                  ) : (
                    ""
                  )
                }
              />
              <TextField
                style={st_textfield}
                variant="outlined"
                type="text"
                label="Linkdin"
                name="SMLinkdin"
                onChange={this.onChangeField}
                value={SMLinkdin ? SMLinkdin : ""}
                required={requiredFields}
                helperText={
                  SMLinkdin ? (
                    <a
                      href={`${VariableLimit.SocialMediaInitLink.Linkedin}${SMLinkdin}`}
                      target="_blank"
                      rel="noreferrer"
                      style={st_TextHelp}
                    >
                      {`${VariableLimit.SocialMediaInitLink.Linkedin}${SMLinkdin}`}
                    </a>
                  ) : (
                    ""
                  )
                }
              />
              <FormControl style={{ ...st_textfield }} variant="filled">
                <label>
                  Foto (Max 500kb, .jpeg){" "}
                  {OldFoto ? (
                    <span
                      style={{
                        color: "blue",
                        cursor: "pointer",
                        verticalAlign: "super",
                      }}
                      onClick={() =>
                        window.open(`${ImageURL.Base}/${OldFoto}`, "_blank")
                      }
                    >
                      preview
                    </span>
                  ) : (
                    <span style={{ color: "red" }}>*</span>
                  )}
                </label>
                {/* <input type='file' accept='image/jpeg,' onChange={this.File_OnChange} name='Foto' required={OldFoto ? false : true} /> */}
                <TextField
                  label="Foto (Max 500kb, .jpeg)"
                  type="file"
                  name="Foto"
                  style={st_textfield}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    accept: "image/jpeg,",
                  }}
                  variant="outlined"
                  onChange={this.File_OnChange}
                  required={OldFoto ? false : true}
                />
              </FormControl>
              <FormControl style={{ ...st_textfield }} variant="filled">
                <label>
                  Curriculum Vitae (Max 500kb, .pdf){" "}
                  {OldCV ? (
                    <span
                      style={{
                        color: "blue",
                        cursor: "pointer",
                        verticalAlign: "super",
                      }}
                      onClick={() =>
                        window.open(`${ImageURL.Base}/${OldCV}`, "_blank")
                      }
                    >
                      preview
                    </span>
                  ) : (
                    <span style={{ color: "red" }}>*</span>
                  )}
                </label>
                {/* <input type='file' accept='application/pdf' onChange={this.File_OnChange} name='CV' required={OldCV ? false : true} /> */}
                <TextField
                  label="Curriculum Vitae (Max 500kb, .pdf)"
                  type="file"
                  name="CV"
                  style={st_textfield}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    accept: "application/pdf",
                  }}
                  variant="outlined"
                  onChange={this.File_OnChange}
                  required={OldCV ? false : true}
                />
              </FormControl>
              <center>
                <Button
                  variant="contained"
                  style={{
                    width: "60%",
                    margin: "2% 0 2% 0",
                    border: "0px solid white",
                    textTransform: "none",
                    color: "white",
                    backgroundColor: "#00a3df",
                    boxShadow:
                      "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                    fontFamily: "Montserrat, Segoe UI",
                    fontSize: "1.2vw",
                  }}
                  type="submit"
                >
                  {isOneStep ? "Save and Continue" : "Save"}
                </Button>

                {isOneStep ? (
                  <Fragment></Fragment>
                ) : (
                  <Button
                    variant="contained"
                    style={{
                      width: "60%",
                      margin: "2% 0 5% 0",
                      border: "0px solid white",
                      textTransform: "none",
                      color: "white",
                      backgroundColor: "#00a3df",
                      boxShadow:
                        "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                      fontFamily: "Montserrat, Segoe UI",
                      fontSize: "1.2vw",
                    }}
                    type="submit"
                    onClick={this.goToJobList}
                  >
                    Save and Apply to Vacancy
                  </Button>
                )}
              </center>
            </form>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  AccountActionId: state.Account.AccountActionId,
  isBiodataLoading: state.Account.isBiodataLoading,
  Biodata: state.Account.Biodata,
  User: state.Auth.User,
});

export default connect(mapStateToProps, {
  Update_Biodata,
  Create_Warning_Messages,
  get_Biodata_Data,
})(Biodata);
