import React from 'react'

import { connect } from 'react-redux'
import { Add_MPP, Load_Department_List } from '../../../../../../Store/Actions/Setting.Action'

import { TextField, FormControl, InputLabel, Select, Button, CircularProgress, OutlinedInput, MenuItem, Checkbox, ListItemText } from '@mui/material'

import { VariableLimit } from '../../../../../../Global'

class SettingMPPAdd extends React.Component {
    state = {
        Tahun: new Date().getFullYear(),
        Jenis: '',
        TanggalBuka: '',
        TanggalTutup: '',
        isNamaError: false,
        Deskripsi: '',
        Departemen:[]
    }
    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    handleChange = (e) => {
        const selectedValues = e.target.value;
        this.setState({ Departemen: selectedValues });
    }
    changeToError = (e, value) => {
        this.setState({ isNamaError: value })
    }
    formOnSubmit = (e) => {
        e.preventDefault()
        this.props.Add_MPP(this.state.Tahun, this.state.Jenis, this.state.TanggalBuka, this.state.TanggalTutup, this.state.Deskripsi, this.state.Departemen)
    }
    componentDidMount() {
        this.props.Load_Department_List()
    }
    render() {
        const DepartementList = this.props.DepartmentList
        const {
            Tahun,
            Jenis,
            TanggalBuka,
            TanggalTutup,
            isNamaError,
            Deskripsi,
            Departemen
        } = this.state

        const isMPPActionAddLoading = this.props.isMPPActionAddLoading
        const isMPPExist = this.props.isMPPExist
  
        return (

            <form
                onSubmit={this.formOnSubmit}
            >
                <div
                    style={{ margin: '0 5% 0 5%' }}
                >
                    <TextField
                        label='Year'
                        type='number'
                        name='Tahun'
                        value={Tahun}
                        required
                        onInvalid={(e) => this.changeToError(e, true)}
                        onChange={this.onChangeField}
                        style={{ width: '100%', margin: '1% 0 1% 0' }}
                        error={isMPPExist || isNamaError ? true : false}
                        helperText={isMPPExist ? 'MPP Sudah Ada' : isNamaError ? 'Tahun Harus diisi' : ''}
                    />
                    <FormControl
                        style={{ width: '100%', margin: '1% 0 1% 0' }}
                        variant="filled"
                        required
                    >
                        <InputLabel
                            shrink
                        >
                            Type
                        </InputLabel>
                        <Select
                            native
                            style={{ backgroundColor: 'white' }}
                            onChange={this.onChangeField}
                            label="Jenis"
                            name='Jenis'
                            value={Jenis}
                        >
                            <option value="" disabled> -- select an option -- </option>
                            {[
                                VariableLimit.MPPJenis.Yearly,
                                VariableLimit.MPPJenis.Additional,
                                VariableLimit.MPPJenis.Replacement,
                            ].map((option, index) =>
                                <option key={`OpGolonganDarah_${index}`} value={option}>{option}</option>
                            )}
                        </Select>
                    </FormControl>
                    {(Jenis === 'Additional' || Jenis === 'Replacement') ? 
                    <div style={{margin: '1% 0 1% 0'}}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-multiple-checkbox-label">Departement</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={Departemen}
                            onChange={this.handleChange}
                            input={<OutlinedInput label="Departement" />}
                            renderValue={(selected) => selected.map((value) => {
                                const departemen = DepartementList.find(item => item.Id === value);
                                return departemen ? departemen.Nama : '';
                            }).join(', ')}
                        >
                            {DepartementList.map((departemen, index) => (
                                <MenuItem key={index} value={departemen.Id}>
                                    <Checkbox checked={Departemen.indexOf(departemen.Id) > -1} />
                                    <ListItemText primary={departemen.Nama} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    </div>
                     : ''}
                    <TextField
                        label='Open Date'
                        type='date'
                        name='TanggalBuka'
                        value={TanggalBuka}
                        required
                        onChange={this.onChangeField}
                        style={{ width: '100%', margin: '1% 0 1% 0' }}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        label='Close Date'
                        type='date'
                        name='TanggalTutup'
                        value={TanggalTutup}
                        required
                        onChange={this.onChangeField}
                        style={{ width: '100%', margin: '1% 0 1% 0' }}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        label='Note'
                        type='text'
                        name='Deskripsi'
                        value={Deskripsi}
                        onChange={this.onChangeField}
                        style={{ width: '100%', margin: '1% 0 1% 0' }}
                    />
                </div>
                <center>
                    <Button
                        variant='contained'
                        size='large'
                        style={{ border: '0px solid red', width: "85%", margin: '3% 0 2% 0' }}
                        type='submit'
                        disabled={isMPPActionAddLoading ? true : false}
                    >
                        {isMPPActionAddLoading ? <CircularProgress /> : 'Add MPP'}
                    </Button>
                </center>
            </form>
        )
    }
}

const mapStateToProps = state => ({
    isMPPActionAddLoading: state.Setting.isMPPActionAddLoading,
    isMPPExist: state.Setting.isMPPExist,
    DepartmentList: state.Setting.DepartmentList,
})

export default connect(mapStateToProps, { Add_MPP, Load_Department_List })(SettingMPPAdd)