import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Paper } from '@mui/material'

import Carousel from 'react-material-ui-carousel'

import Carousel1 from '../../../Img/Home/Carousel/Carousel001.jpg'
import Carousel2 from '../../../Img/Home/Carousel/Carousel002.jpg'
import Carousel3 from '../../../Img/Home/Carousel/Carousel003.jpg'
import About1 from '../../../Img/Home/About/About001e.jpg'
import About2 from '../../../Img/Home/About/About002.jpg'
import About3 from '../../../Img/Home/About/About003.jpg'
import Value1 from '../../../Img/Home/Value/Value001e.jpg'
import Value2 from '../../../Img/Home/Value/Value002e.jpg'
import Value3 from '../../../Img/Home/Value/Value003e.jpg'
import RecruitmentProcess from '../../../Img/Home/Flow/RecruitmentProcessVertikal.png'

class Home extends React.Component {
    componentDidMount() {
        // this.props.get_PR_User_Pengajuan()
    }

    goToVacancay = () => {
        window.location.href = '/joblist'
        // this.setState({ isGoToVacancay: !this.state.isGoToVacancay })
        // History.push('/login')
    }

    render() {

        const ImageArray = [
            Carousel1, Carousel2, Carousel3
        ]

        return (
            <Fragment>
                <center>
                    <div
                        style={{ height: '25vh', border: '0px solid red', margin: '0 0 0 0' }}
                    >
                        <Carousel
                        >
                            {
                                ImageArray.map((item, index) =>
                                    <Paper
                                        key={`ImageArray_${index}`}
                                        style={{ height: '25vh', border: 'none', boxShadow: 'none', overflow: 'none' }}
                                    >
                                        <center>
                                            <img
                                                style={{ height: '25vh', width: '100%', objectFit: 'scale-down' }}
                                                // style={{ height: '70vh' }}
                                                // style={{ transform: 'translate(50%, 0)' }}
                                                alt={`CarouselImage_${index}`}
                                                src={item}
                                            />
                                        </center>
                                    </Paper>
                                )
                            }
                        </Carousel>
                    </div>
                </center>

                <div
                    style={{ border: '0px solid red', margin: ' 5vh 5vw 5vh 5vw' }}
                >
                    <center>
                        <h1
                            style={{ color: '#03045e', fontFamily: 'Montserrat, Segoe UI', cursor: 'pointer' }}
                            onClick={this.goToVacancay}
                        >
                            Find Your Dream Job in Etana!
                        </h1>
                    </center>
                    <span
                        style={{ fontFamily: 'Montserrat, Segoe UI' }}
                    >
                        Think about the basic components of the career experience
                        and decide which are the aspects that pull you
                    </span>
                </div>

                <div>

                </div>

                <div
                    style={{ border: '0px solid red', backgroundColor: '#f0f0f0', padding: '0 5vw 10vh 5vw' }}
                >
                    <center>
                        <h1
                            style={{ border: '0px solid red', margin: '0 15vw 0 15vw', padding: '10vh 0 5vh 0', color: '#03045e', fontFamily: 'Montserrat, Segoe UI' }}
                        >
                            About Etana
                        </h1>
                        <div
                            style={{ border: '0px solid blue', margin: '1vh 0 1vh 0', width: '90%', height: '100%', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                        >
                            <img
                                style={{ border: '0px solid red', width: '100%', height: '100%', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                src={About1}
                                alt='About1'
                            />
                        </div>

                        <div
                            style={{ border: '0px solid blue', margin: '1vh 0 1vh 0', width: '90%', height: '100%', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                        >
                            <img
                                style={{ border: '0px solid red', width: '48%', marginRight: '1vw', height: '100%', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                src={About2}
                                alt='About2'
                            />
                            <img
                                style={{ border: '0px solid red', width: '48%', marginLeft: '1vw', height: '100%', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                src={About3}
                                alt='About3'
                            />
                        </div>

                        <h2
                            style={{ color: '#03045e', fontFamily: 'Montserrat, Segoe UI' }}
                        >
                            Who We Are
                        </h2>
                    </center>
                    <span
                        style={{ fontFamily: 'Montserrat, Segoe UI', }}
                    >
                        Established in 2014, PT Etana Biotechnologies Indonesia (Etana) is an 
                        emerging producer of high-quality, affordable and innovative 
                        biopharmaceuticals to treat a range of metabolic, autoimmune and other 
                        major life-threatening diseases, including cancer. Serve patients by 
                        providing high quality, affordable and innovative biopharmaceutical 
                        products.
                    </span>


                    <div
                        style={{ margin: '0 5vw 0 5vw', border: '0px solid red' }}
                    >
                        <center>
                            <h2
                                style={{ color: '#03045e', margin: '15vh 0 5vh 0', fontFamily: 'Montserrat, Segoe UI' }}
                            >
                                Our Values
                                and Purpose
                            </h2>
                        </center>

                        <div
                            style={{ margin: ' 0 0 5vh 0' }}
                        >
                            <h3
                                style={{ fontFamily: 'Montserrat, Segoe UI', border: '0px solid red', }}
                            >
                                Commitment
                            </h3>
                            <center>
                                <div
                                    style={{ border: '0px solid red', width: '90%', height: '60%', margin: '0 0 5% 0', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                >
                                    <img
                                        style={{ border: '0px solid red', width: '100%', height: '100%', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                        src={Value1}
                                        alt='Value1'
                                    />
                                </div>
                            </center>
                            <span
                                style={{ fontFamily: 'Montserrat, Segoe UI', }}
                            >
                                We are committed to improving <br />
                                the health and well-being of all <br />
                                Indonesians.
                            </span>
                        </div>

                        <div
                            style={{ margin: ' 0 0 5vh 0' }}
                        >
                            <h3
                                style={{ fontFamily: 'Montserrat, Segoe UI', border: '0px solid red', }}
                            >
                                Care
                            </h3>
                            <center>
                                <div
                                    style={{ border: '0px solid red', width: '90%', height: '60%', margin: '0 0 5% 0', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                >
                                    <img
                                        style={{ border: '0px solid red', width: '100%', height: '100%', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                        src={Value2}
                                        alt='Value2'
                                    />
                                </div>
                            </center>
                            <span
                                style={{ fontFamily: 'Montserrat, Segoe UI', }}
                            >
                                We care for the health of the <br />
                                nation. We encourage all members <br />
                                of our staff to share these values by <br />
                                caring for them, by providing them <br />
                                with good remuneration and <br />
                                opportunities for personal <br />
                                professional growth.
                            </span>
                        </div>

                        <div>
                            <h3
                                style={{ fontFamily: 'Montserrat, Segoe UI', border: '0px solid red', }}
                            >
                                Collaboration
                            </h3>
                            <center>
                                <div
                                    style={{ border: '0px solid red', width: '90%', height: '60%', margin: '0 0 5% 0', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                >
                                    <img
                                        style={{ border: '0px solid red', width: '100%', height: '100%', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                        src={Value3}
                                        alt='Value3'
                                    />
                                </div>
                            </center>
                            <span
                                style={{ fontFamily: 'Montserrat, Segoe UI', }}
                            >
                                We work together with <br />
                                stakeholders and strategic partners <br />
                                in Indonesia to ensure that <br />
                                Indonesia have access to the <br />
                                health services they deserve.
                            </span>
                        </div>
                    </div>
                </div>

                <div
                    style={{ border: '0px solid red', margin: '0 5vw 0 5vw' }}
                >
                    <center>
                        <h1
                            style={{ margin: '10vh 0 5vh 0', color: '#03045e', fontFamily: 'Montserrat, Segoe UI' }}
                        >
                            Recruitment
                            Process
                        </h1>
                    </center>
                    <center>
                        <img
                            style={{ border: '0px solid red', width: '80%' }}
                            src={RecruitmentProcess}
                            alt='RecruitmentProcess'
                        />
                    </center>
                </div>
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    // AccountId: state.Auth.Id,
})

export default connect(mapStateToProps, {})(Home)
