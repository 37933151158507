import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Load_MPP_List } from '../../../../Store/Actions/Setting.Action'
import { get_MPP_Action_Id } from '../../../../Store/Actions/MPP.Action'

import { Skeleton } from '@mui/material'

import CoTable from '../../../Containers/Table'
import GenericModals from '../../../Containers/GenericModals'
import DetailListMpp from './Modal/ViewMPPList/DetailListItemMpp'

class ViewMPPList extends React.Component {
    componentDidMount() {
        this.props.Load_MPP_List()
    }

    onClickActionId = (Tahun) => {
        this.props.get_MPP_Action_Id(Tahun)
    }

    render() {
        const isMPPLoading = this.props.isMPPLoading
        const MPPListData = this.props.MPPList
        const MPPListUnsort = []

        for (const x of MPPListData) {
            const Tahun = x.Tahun
            const isInList = MPPListUnsort.find((i) => i.Tahun === Tahun)
            if (!isInList) {
                MPPListUnsort.push(x)
            }
        }

        const MPPList = MPPListUnsort.sort((a, b) => a.Tahun < b.Tahun ? 1 : a.Tahun > b.Tahun ? -1 : 0)

        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
                KeyName: 'index',
                SortingType: 'Number',
            },
            {
                Nama: 'Tahun',
                width: '10%',
                KeyName: 'Tahun',
                SortingType: 'Number',
            },
            {
                Nama: 'Tanggal Buka',
                width: '10%',
                KeyName: 'TanggalBuka',
                SortingType: 'Date',
            },
            {
                Nama: 'Tanggal Tutup',
                width: '10%',
                KeyName: 'TanggalTutup',
                SortingType: 'Date',
            },
            // {
            //     Nama: 'Keterangan',
            //     width: '20%',
            //     KeyName: 'Keterangan',
            // },
            {
                Nama: 'Detail',
                width: '10%',
            },

        ]

        const BodyData = MPPList ? MPPList.map((item, index) => {
            return {
                index: {
                    value: index + 1
                },
                Tahun: {
                    value: item.Tahun
                },
                TanggalBuka: {
                    value: item.TanggalBuka,
                    display: new Date(item.TanggalBuka).toLocaleDateString()
                },
                TanggalTutup: {
                    value: item.TanggalTutup,
                    display: new Date(item.TanggalTutup).toLocaleDateString()
                },
                // Keterangan: item.Keterangan,
                // Keterangan: {
                //     value: ' '
                // },
                Detail: {
                    value: item.Tahun,
                    display: (
                        <GenericModals
                            size='l'
                            // Buttonvariant='outlined'
                            header='Detail'
                            uncontrollsize={true}
                            body={<DetailListMpp />}
                            Buttoncolor='primary'
                            ButtononClickeven={() => this.onClickActionId(item.Tahun)}
                            Buttonlabel={'Detail'}
                        />
                    )
                }
            }
        }) : []

        return (
            <Fragment>
                <h2>
                    List Mpp
                </h2>
                {false ?
                    <Fragment>
                        {[1, 2, 3].map((item) =>
                            <Skeleton
                                key={`ViewMPPList_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <CoTable
                            TableId={'ViewMPPList'}
                            HeadData={HeadData}
                            Data={BodyData}
                            isHeadLoading={isMPPLoading}
                            isBodyLoading={isMPPLoading}
                        />
                    </Fragment>}
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    isMPPLoading: state.Setting.isMPPLoading,
    MPPList: state.Setting.MPPList,
})

export default connect(mapStateToProps, { Load_MPP_List, get_MPP_Action_Id })(ViewMPPList)