import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_MPP_Data } from '../../../../../Store/Actions/MPP.Action'

import CoDetailMPP from '../../../../Containers/Dashboard/MPP/DetailMPP'
import MPPDetailDownload from '../../../../Containers/Dashboard/MPP/MPPDetailDownload'

class DetailMPP extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.MPPActionId !== prevProps.MPPActionId) {
            this.props.get_MPP_Data(this.props.MPPActionId)
        }
    }
    componentDidMount() {
        const { MPPActionId } = this.props
        if (MPPActionId !== null) {
            this.props.get_MPP_Data(MPPActionId)
        }
    }
    render() {
        const MPPData = this.props.MPPData
        const MPPList = this.props.MPPList
        // const MPPListItem = this.props.MPPListItem
        const isMPPActionGetDataLoading = this.props.isMPPActionGetDataLoading

        const isDownload = false

        return (
            <Fragment>
                {/* <CoDetailMPP Data={MPPData} MPPList={MPPList} MPPListItem={MPPListItem} isLoading={isMPPActionGetDataLoading} /> */}
                <CoDetailMPP Data={MPPData} MPPList={MPPList} isLoading={isMPPActionGetDataLoading} />
                {isDownload ?
                    <Fragment>
                        <MPPDetailDownload Data={MPPData} MPPList={MPPList} isLoading={isMPPActionGetDataLoading} />
                    </Fragment>
                    : <Fragment></Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    MPPActionId: state.MPP.MPPActionId,
    isMPPActionGetDataLoading: state.MPP.isMPPActionGetDataLoading,
    MPPData: state.MPP.MPPData,
    MPPList: state.MPP.MPPList,
    MPPListItem: state.MPP.MPPListItem,
})

export default connect(mapStateToProps, { get_MPP_Data })(DetailMPP)