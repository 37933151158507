import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_List_MCU_Detail } from '../../../../../../Store/Actions/Job.Action'

import { Skeleton } from '@mui/material'

class DetailMCU extends React.Component {
    componentDidMount() {
        const { JobActionId, AccountActionId } = this.props
        this.props.get_List_MCU_Detail(JobActionId, AccountActionId)
    }
    render() {
        const isListMCUDetailLoading = this.props.isListMCUDetailLoading
        const ListMCUDetail = this.props.ListMCUDetail
        return (
            <Fragment>
                {isListMCUDetailLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5,].map((item) =>
                            <Skeleton
                                key={`DetailMenunggu_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <h2>MCU</h2>
                        <h4>
                            MCU dilakukan pada:<br />
                            {ListMCUDetail?.AppDate ? new Date(ListMCUDetail.AppDate).toLocaleString(['ban', 'id']) : ''}<br />
                        </h4>
                        <h3>
                            MCU Resault Approval:
                        </h3>
                        <div
                            style={{
                                margin: '1% 0 1% 0', padding: '1%', border: `1px solid ${ListMCUDetail?.ResultStatus ? ListMCUDetail.ResultStatus === 'Accept' ? 'Green' :
                                    ListMCUDetail.ResultStatus === 'Reject' ? 'red' : 'orange' : 'black'}`
                            }}
                        >
                            <center>
                                <h2
                                    style={{
                                        color: ListMCUDetail?.ResultStatus ? ListMCUDetail.ResultStatus === 'Accept' ? 'Green' :
                                            ListMCUDetail.ResultStatus === 'Reject' ? 'red' : 'orange' : 'black'
                                    }}
                                >
                                    Status : {ListMCUDetail?.ResultStatus ? ListMCUDetail.ResultStatus : 'Status'}
                                </h2>
                            </center>
                            <h4>
                                Oleh:<br />
                                {ListMCUDetail?.BiodataNama ? ListMCUDetail.BiodataNama : ''}<br /><br />
                                Hasil di input pada:<br />
                                {ListMCUDetail?.ResultTime ? new Date(ListMCUDetail.ResultTime).toLocaleString(['ban', 'id']) : ''}<br /><br />
                                Keterangan:<br />
                                {ListMCUDetail?.ResultKet ? ListMCUDetail.ResultKet : ''}<br />
                            </h4>
                        </div>
                        <h3>
                            <br /> MCU Final Approval:
                        </h3>
                        <div
                            style={{
                                margin: '1% 0 1% 0', padding: '1%', border: `1px solid ${ListMCUDetail?.AccStatus ? ListMCUDetail.AccStatus === 'Accept' ? 'Green' :
                                    ListMCUDetail.AccStatus === 'Reject' ? 'red' : 'orange' : 'black'}`
                            }}
                        >
                            <center>
                                <h2
                                    style={{
                                        color: ListMCUDetail?.AccStatus ? ListMCUDetail.AccStatus === 'Accept' ? 'Green' :
                                            ListMCUDetail.AccStatus === 'Reject' ? 'red' : 'orange' : 'black'
                                    }}
                                >
                                    Status : {ListMCUDetail?.AccStatus ? ListMCUDetail.AccStatus : ''}
                                </h2>
                            </center>
                            <h4>
                                Oleh:<br />
                                {ListMCUDetail?.BiodataBiodataNama ? ListMCUDetail.BiodataBiodataNama : ''}<br /><br />
                                Hasil di input pada:<br />
                                {ListMCUDetail?.AccTime ? new Date(ListMCUDetail.AccTime).toLocaleString(['ban', 'id']) : ''}<br /><br />
                                Keterangan:<br />
                                {ListMCUDetail?.AccKet ? ListMCUDetail.AccKet : ''}<br />
                            </h4>
                        </div>
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isListMCUDetailLoading: state.Job.isListMCUDetailLoading,
    ListMCUDetail: state.Job.ListMCUDetail,
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
})

export default connect(mapStateToProps, { get_List_MCU_Detail })(DetailMCU)
