import React, { Fragment } from "react"

import { connect } from 'react-redux'

import { Create_Warning_Messages } from '../../../../../Store/Actions/Messages.Actions'
import { get_PR_Data, Update_PR } from '../../../../../Store/Actions/PR.Action'
import { Load_Lokasi_List } from '../../../../../Store/Actions/Setting.Action'

import { VariableLimit } from "../../../../../Global"

import { Skeleton, TextField, FormControl, InputLabel, Select, FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material'
// import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
// import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'

import CoTable from "../../../../Containers/Table"

class MoUpdate extends React.Component {
    state = {
        NamaPosisi: '',
        Level: '',
        AtasanLangsungNama: '',
        TipePegawai: '',
        ContractDuration: '',
        JumlahBawahan: '',
        PosisiDibutuhkan: '',
        LokasiNama: '',
        isBaruOrMenggantikan: '',
        ReplacementName: '',
        PRListItem: [],
        PRSkill: [],
        UraianPekerjaan: '',
        UsiaMin: '',
        UsiaMax: '',
        JenisKelamin: '',
        Pendidikan: '',
        Jurusan: '',
        Bahasa: '',
        PengalamanTahun: '',
        Catatan: '',
        Reason: '',
        isConfirm: false,
    }

    componentDidMount() {
        this.props.Load_Lokasi_List()

        const { PRActionId } = this.props
        if (PRActionId) {
            this.props.get_PR_Data(PRActionId)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.PRActionId !== prevProps.PRActionId) {
            const { PRActionId } = this.props
            this.props.get_PR_Data(PRActionId)
        }
        if (this.props.PRData !== prevProps.PRData) {
            const { PRData, PRListItem, PRSkill } = this.props

            this.setState({
                NamaPosisi: PRData?.NamaPosisi ? PRData.NamaPosisi : '',
                Level: PRData?.LevelNama ? PRData.LevelNama : '',
                AtasanLangsungNama: PRData?.AtasanLangsungNama ? PRData.AtasanLangsungNama : '',
                TipePegawai: PRData?.TipePegawai ? PRData.TipePegawai : '',
                ContractDuration: PRData?.ContractDuration ? PRData.ContractDuration : '',
                JumlahBawahan: PRData?.JumlahBawahan ? PRData.JumlahBawahan : 0,
                PosisiDibutuhkan: PRData?.PosisiDibutuhkan ? PRData.PosisiDibutuhkan : '',
                LokasiNama: PRData?.LokasiKerja ? PRData.LokasiKerja : '',
                isBaruOrMenggantikan: PRData?.isBaruOrMenggantikan ? 1 : 0,
                ReplacementName: PRData?.ReplacementName ? PRData.ReplacementName : '',
                PRListItem: PRListItem ? PRListItem : [],
                PRSkill: PRSkill ? PRSkill : [],
                UraianPekerjaan: PRData?.UraianPekerjaan ? PRData.UraianPekerjaan : '',
                UsiaMin: PRData?.UsiaMin ? PRData.UsiaMin : '',
                UsiaMax: PRData?.UsiaMax ? PRData.UsiaMax : '',
                JenisKelamin: PRData?.JenisKelamin ? PRData.JenisKelamin : '',
                Pendidikan: PRData?.Pendidikan ? PRData.Pendidikan : '',
                Jurusan: PRData?.Jurusan ? PRData.Jurusan : '',
                Bahasa: PRData?.Bahasa ? PRData.Bahasa : '',
                PengalamanTahun: PRData?.PengalamanTahun ? PRData.PengalamanTahun : '',
                Catatan: PRData?.Catatan ? PRData.Catatan : '',
                Reason: PRData?.Reason ? PRData.Reason : '',
            })
        }
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onChangeFieldMinMax = (e, Min, Max) => {
        if (Number(e.target.value) >= Min && Number(e.target.value) <= Max) {
            this.setState({ [e.target.name]: e.target.value })
        } else {
            this.props.Create_Warning_Messages(null, `tidak bisa kurang dari ${Min} dan melebih ${Max}`)
        }
    }

    onChangeFieldMapMinMax = (e, Index, Min, MaxData) => {
        const Max = MaxData.Jumlah - (MaxData.JumlahSelesai + MaxData.JumlahTertahan)
        if (Number(e.target.value) >= Min && Number(e.target.value) <= Max) {
            this.OnChangeMapItem(e, Index, 2)
        } else {
            this.props.Create_Warning_Messages(null, `tidak bisa kurang dari ${Min} dan melebih ${Max}`)
        }
    }

    onChangeSwitch = (e) => {
        this.setState({ Diiklankan: !this.state.Diiklankan })
    }

    OnChangeCheckbox = E => {
        this.setState({ [E.target.name]: !this.state[E.target.name] })
    }

    AddDetailList = () => {
        const Skill = this.state.Skill

        if (Skill.length === 0 || Skill[Skill.length - 1].Name) {
            const newSkill = { Name: '' }
            Skill.push(newSkill)
            this.setState({ Skill: Skill })
        } else {
            this.props.Create_Warning_Messages(null, 'mohon lengkapi proses pengisian terlebih dahulu')
        }
    }

    DeleteDetailList = (Index) => {
        const Skill = this.state.Skill
        if (Skill.length > 3) {
            if (Index > 2) {
                Skill.splice(Index, 1)
                this.setState({ Skill: Skill })
            } else {
                this.props.Create_Warning_Messages(null, 'point 1-3 tidak bisa dihapus')
            }
        } else {
            this.props.Create_Warning_Messages(null, 'Minimal 3')
        }
    }

    OnChangeMapItem = (e, Index, Order) => {
        const Skill = this.state.Skill
        if (Order === 1) {
            Skill[Index].Name = e.target.value
        } else {

        }
        this.setState({ Skill: Skill })
    }

    formOnSubmit = (e) => {
        e.preventDefault()

        const { PRActionId } = this.props

        this.props.Update_PR(
            PRActionId,
            this.state.ContractDuration,
            this.state.LokasiNama,
            this.state.ReplacementName,
            this.state.JenisKelamin,
            this.state.Bahasa,
            this.state.PengalamanTahun,
            this.state.Catatan,
        )
    }

    render() {

        const isLokasiLoading = this.props.isLokasiLoading
        const isPRActionGetDataLoading = this.props.isPRActionGetDataLoading

        const OpLokasiList = this.props.LokasiList
        console.log(OpLokasiList);
        // const OpBaruOrMenggantikan = [
        //     {
        //         Option: 'Baru',
        //         value: 1
        //     },
        //     {
        //         Option: 'Menggantikan',
        //         value: 0
        //     },
        // ]

        const OpJenisKelamin = ['Male/Female', 'Male', 'Female']

        const {
            NamaPosisi,
            Level,
            AtasanLangsungNama,
            TipePegawai,
            ContractDuration,
            JumlahBawahan,
            PosisiDibutuhkan,
            LokasiNama,
            isBaruOrMenggantikan,
            ReplacementName,
            PRListItem,
            PRSkill,
            UraianPekerjaan,
            UsiaMin,
            UsiaMax,
            JenisKelamin,
            Pendidikan,
            Jurusan,
            Bahasa,
            PengalamanTahun,
            Catatan,
            isConfirm,
            Reason,
        } = this.state

        const Skill = PRSkill

        // const st_icon = { color: 'grey', fontSize: '180%', margin: '0 0 0 1%', cursor: 'pointer' }

        const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }
        const st_textfield_disable = { marginTop: '10px', marginBottom: '10px', width: '100%', backgroundColor: '#E0E0E0' }
        const st_Half = { width: '45%' }
        // const st_icon = { color: 'grey', fontSize: '180%', margin: '0 0 0 1%', cursor: 'pointer' }
        const st_divaddtextfield = { display: 'flex', alignItems: 'center', margin: '0 0 2% 2%' }
        // const st_TextSmall = { width: '20%', margin: '0 1% 0 0' }
        const st_TextSmall_disable = { width: '20%', margin: '0 1% 0 0', backgroundColor: '#E0E0E0' }

        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Skill',
                width: '55%',
            }
        ]

        const BodyData = Skill.map((item, index) => {
            return {
                index: index + 1,
                Pertanyaan: (
                    <TextField
                        value={Skill[index].Skill}
                        style={{ ...st_textfield_disable, ...{ width: '90%' } }}
                        required
                        label={`Skill ${index + 1}`}
                        // onChange={(e) => this.OnChangeMapItem(e, index, 1)}
                        disabled
                    />
                ),
            }
        })

        return (
            <Fragment>
                {isPRActionGetDataLoading || isLokasiLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5, 6, 7].map((item) =>
                            <Skeleton
                                height={'8vh'}
                                key={`PRForm_Skeleton_${item}`}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <form
                            onSubmit={this.formOnSubmit}
                        >
                            <h3>
                                Position Information
                            </h3>
                            <TextField
                                style={st_textfield_disable}
                                variant='outlined'
                                type='text'
                                label='Position Title'
                                name='Posisi'
                                // required
                                disabled
                                value={NamaPosisi}
                            // onChange={this.onChangeField}
                            />
                            <TextField
                                style={{ ...st_textfield_disable }}
                                variant='outlined'
                                type='text'
                                label='Level'
                                name='Level'
                                // required
                                disabled
                                value={Level}
                            // onChange={this.onChangeField}
                            />
                            <TextField
                                style={st_textfield_disable}
                                variant='outlined'
                                type='text'
                                label='Reporting To'
                                name='AtasanLangsungNama'
                                disabled
                                value={AtasanLangsungNama}
                            // onChange={this.onChangeField}
                            />
                            <TextField
                                style={st_textfield_disable}
                                variant='outlined'
                                type='text'
                                label='Employee Type'
                                name='TipePegawai'
                                // required
                                disabled
                                value={TipePegawai}
                            // onChange={this.onChangeField}
                            />
                            {TipePegawai ? TipePegawai === VariableLimit.MPPType.Contract ?
                                <TextField
                                    style={st_textfield}
                                    variant='outlined'
                                    type='number'
                                    label='Contract Duration in Month'
                                    name='ContractDuration'
                                    required
                                    value={ContractDuration}
                                    onChange={this.onChangeField}
                                />
                                : <Fragment></Fragment>
                                : <Fragment></Fragment>
                            }
                            <TextField
                                style={{ ...st_textfield_disable, ...st_Half }}
                                variant='outlined'
                                type='number'
                                label='No of Subordinate'
                                name='Bawahan'
                                disabled
                                value={JumlahBawahan}
                            // onChange={this.onChangeField}
                            />
                            <TextField
                                style={{ ...st_textfield_disable, ...st_Half, marginLeft: '5%' }}
                                variant='outlined'
                                type='number'
                                label='No of open position '
                                name='PosisiDibutuhkan'
                                required
                                disabled
                                value={PosisiDibutuhkan}
                            // onChange={(e) => this.onChangeFieldMinMax(e, 0, MPPDetail.Jumlah - (MPPDetail.JumlahSelesai + MPPDetail.JumlahTertahan))}
                            />
                            {Reason &&
                                <TextField
                                    style={st_textfield_disable}
                                    variant='outlined'
                                    type='text'
                                    label='Reason not maximum open position'
                                    name='Reason'
                                    // required
                                    disabled
                                    value={Reason}
                                // onChange={this.onChangeField}
                                />}
                            {/* <FormControl
                                style={{ ...st_textfield_disable, ...st_Half }}
                                variant="filled"
                                required
                            >
                                <InputLabel
                                    shrink
                                >
                                    Is this a new position or replacement?
                                </InputLabel>
                                <Select
                                    native
                                    // style={{ backgroundColor: 'white' }}
                                    // onChange={(e) => this.onChangeField(e)}
                                    label="Is this a new position or replacement?"
                                    name='isBaruOrMenggantikan'
                                    disabled
                                    value={isBaruOrMenggantikan}
                                >
                                    <option value="" disabled> -- select an option -- </option>
                                    {OpBaruOrMenggantikan.map((option, index) =>
                                        <option key={`BaruOrMenggantikan_${index}`} value={option.value}>{option.Option}</option>
                                    )}
                                </Select>
                            </FormControl> */}
                            <TextField
                                style={isBaruOrMenggantikan ? { ...st_textfield_disable, } : { ...st_textfield_disable, ...st_Half, }}
                                variant='outlined'
                                type='text'
                                label='Is this a new position or replacement?'
                                name='isBaruOrMenggantikan'
                                disabled
                                value={isBaruOrMenggantikan ? 'New' : 'Replacement'}
                            // onChange={(e) => this.onChangeFieldMinMax(e, 0, MPPDetail.Jumlah - (MPPDetail.JumlahSelesai + MPPDetail.JumlahTertahan))}
                            />
                            {isBaruOrMenggantikan ?
                                <Fragment>
                                </Fragment> :
                                <TextField
                                    style={{ ...st_textfield, ...st_Half, marginLeft: '5%' }}
                                    variant='outlined'
                                    type='text'
                                    label='Replacement Name'
                                    name='ReplacementName'
                                    value={ReplacementName}
                                    onChange={(e) => this.onChangeField(e)}
                                />}
                            <FormControl
                                style={{ ...st_textfield }}
                                variant="filled"
                                required
                            >
                                <InputLabel
                                    shrink
                                >
                                    Work Location
                                </InputLabel>
                                <Select
                                    native
                                    style={{ backgroundColor: 'white' }}
                                    onChange={(e) => this.onChangeField(e)}
                                    label="Work Location"
                                    name='LokasiNama'
                                    value={LokasiNama}
                                >
                                    <option value="" disabled> -- select an option -- </option>
                                    {OpLokasiList.map((option, index) =>
                                        <option key={`OpLokasiList${index}`} value={option.Id}>{option.Nama}</option>
                                    )}
                                </Select>
                            </FormControl>
                            <h3>
                                Work Equipment
                            </h3>
                            {!PRListItem || PRListItem.length < 1 ?
                                <center>
                                    <h3>No items submitted during MPP</h3>
                                </center> :
                                PRListItem.map((item, index) => (
                                    <Fragment
                                        key={`OpMPPListItem_Item_${index}`}
                                    >
                                        <div
                                            style={st_divaddtextfield}
                                        >
                                            <span
                                                style={{ marginRight: '1%' }}
                                            >
                                                {`Item ${index + 1}`}
                                            </span>
                                            <TextField
                                                style={st_TextSmall_disable}
                                                variant='outlined'
                                                type='text'
                                                label='Opening Month'
                                                name='Waktu Pembukaan'
                                                value={item.BulanBuka || item.BulanBuka === 0 ? new Date(2022, item.BulanBuka, 1).toLocaleString('default', { month: 'long' }) : ''}
                                                disabled
                                            />
                                            <TextField
                                                style={st_TextSmall_disable}
                                                variant='outlined'
                                                type='text'
                                                label='Equipment'
                                                name='Kebutuhan'
                                                value={item.ItemNama ? item.ItemNama : ''}
                                                disabled
                                            />
                                            <TextField
                                                style={st_TextSmall_disable}
                                                variant='outlined'
                                                type='number'
                                                label='Quantity'
                                                name='PosisiDibutuhkan'
                                                value={PosisiDibutuhkan}
                                                disabled
                                            />
                                        </div>
                                    </Fragment>
                                ))}
                            <h3>
                                Skill Requirement (HR benchmark for applicant's recommendation, asked at registration)
                            </h3>
                            <CoTable
                                TableId={'PR_SKill'}
                                HeadData={HeadData}
                                Data={BodyData}
                            // isHeadLoading={isPertanyaanLoading}
                            // isBodyLoading={isPertanyaanLoading}
                            />
                            <h3>
                                Job Description
                            </h3>
                            <TextField
                                style={st_textfield_disable}
                                variant='outlined'
                                type='text'
                                multiline
                                rows={8}
                                label='Job Description'
                                name='UraianPosisi'
                                // required
                                disabled
                                value={UraianPekerjaan}
                            // onChange={this.onChangeField}
                            />
                            <h3>
                                Position Requirements
                            </h3>
                            <TextField
                                style={{ ...st_textfield_disable, ...st_Half }}
                                variant='outlined'
                                type='number'
                                label='Age Min'
                                name='UsiaMin'
                                // required
                                disabled
                                value={UsiaMin}
                            // onChange={this.onChangeField}
                            />
                            <TextField
                                style={{ ...st_textfield_disable, ...st_Half, marginLeft: '5%' }}
                                variant='outlined'
                                type='number'
                                label='Age Max'
                                name='UsiaMax'
                                // required
                                disabled
                                value={UsiaMax}
                            // onChange={this.onChangeField}
                            />
                            <FormControl
                                style={st_textfield}
                                variant="filled"
                                required
                            >
                                <InputLabel
                                    shrink
                                >
                                    Gender
                                </InputLabel>
                                <Select
                                    native
                                    style={{ backgroundColor: 'white' }}
                                    onChange={(e) => this.onChangeField(e)}
                                    label="Gender"
                                    name='JenisKelamin'
                                    value={JenisKelamin}
                                >
                                    <option value="" disabled> -- select an option -- </option>
                                    {OpJenisKelamin.map((option, index) =>
                                        <option key={`JenisKelamin_${index}`} value={option}>{option}</option>
                                    )}
                                </Select>
                            </FormControl>
                            <TextField
                                style={{ ...st_textfield_disable, ...st_Half, }}
                                variant='outlined'
                                type='text'
                                label='Education Qualification'
                                name='Pendidikan'
                                // required
                                disabled
                                value={Pendidikan}
                            // onChange={this.onChangeField}
                            />
                            <TextField
                                style={{ ...st_textfield_disable, ...st_Half, marginLeft: '5%' }}
                                variant='outlined'
                                type='text'
                                label='Major of Study'
                                name='Jurusan'
                                // required
                                disabled
                                value={Jurusan}
                            // onChange={this.onChangeField}
                            />
                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Language Skills Besides Indonesian '
                                name='Bahasa'
                                // required
                                // disabled
                                value={Bahasa}
                                onChange={this.onChangeField}
                            />
                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='number'
                                label='Years of Experience'
                                name='PengalamanTahun'
                                required
                                // disabled
                                value={PengalamanTahun}
                                onChange={this.onChangeField}
                            />
                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                multiline
                                rows={3}
                                label='Notes'
                                name='Catatan'
                                // required
                                // disabled
                                value={Catatan}
                                onChange={this.onChangeField}
                            />
                            <FormGroup row>
                                <FormControlLabel
                                    control={<Checkbox color='primary' onChange={this.OnChangeCheckbox} name="isConfirm" value={isConfirm} />}
                                    label="The data that I entered is correct"
                                />
                            </FormGroup>
                            <center>
                                <Button
                                    variant='contained'
                                    style={{ width: '90%', margin: '5% 0 5% 0' }}
                                    type='submit'
                                    disabled={isConfirm ? false : true}
                                >
                                    Update PR
                                </Button>
                            </center>
                        </form>
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isLokasiLoading: state.Setting.isLokasiLoading,
    LokasiList: state.Setting.LokasiList,
    User: state.Auth.User,
    PRActionId: state.PR.PRActionId,
    isPRActionGetDataLoading: state.PR.isPRActionGetDataLoading,
    PRData: state.PR.PRData,
    PRListItem: state.PR.PRListItem,
    PRSkill: state.PR.PRSkill,
})

export default connect(mapStateToProps, { Create_Warning_Messages, get_PR_Data, Update_PR, Load_Lokasi_List })(MoUpdate)