import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_List_PreBoarding_Detail, Konfirm_PreBoarding } from '../../../../../../Store/Actions/Job.Action'

import { Skeleton, Checkbox, TextField, FormControl, InputLabel, Select, Button } from '@mui/material'

class Submit extends React.Component {
    state = {
        Status: '',
        KTP: false,
        KTPKet: '',
        KK: false,
        KKKet: '',
        NPWP: false,
        NPWPKet: '',
        BCA: false,
        BCAKet: '',
        BPJSKesehatan: false,
        BPJSKesehatanKet: '',
        BPJSKetenagakerjaan: false,
        BPJSKetenagakerjaanKet: '',
    }

    componentDidMount() {
        const { JobActionId, AccountActionId } = this.props
        this.props.get_List_PreBoarding_Detail(JobActionId, AccountActionId)
    }

    onChangeCheckbox = E => {
        this.setState({ [E.target.name]: !this.state[E.target.name] })
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    formOnSubmit = (e) => {
        e.preventDefault()

        const JobActionId = this.props.JobActionId
        const AccountActionId = this.props.AccountActionId

        this.props.Konfirm_PreBoarding(
            JobActionId,
            AccountActionId,
            this.state.Status,
            this.state.KTP,
            this.state.KTPKet,
            this.state.KK,
            this.state.KKKet,
            this.state.NPWP,
            this.state.NPWPKet,
            this.state.BCA,
            this.state.BCAKet,
            this.state.BPJSKesehatan,
            this.state.BPJSKesehatanKet,
            this.state.BPJSKetenagakerjaan,
            this.state.BPJSKetenagakerjaanKet,
        )
    }
    render() {
        const ListPreBoardingDetail = this.props.ListPreBoardingDetail
        // const Data = ListPreBoardingDetail ? ListPreBoardingDetail.Data : {}
        const Biodata = ListPreBoardingDetail ? ListPreBoardingDetail.Biodata : {}
        const isListPreBoardingDetailLoading = this.props.isListPreBoardingDetailLoading

        const st_textfield_non = { marginTop: '10px', marginBottom: '10px', width: '100%' }

        const {
            Status,
            KTP,
            KTPKet,
            KK,
            KKKet,
            NPWP,
            NPWPKet,
            BCA,
            BCAKet,
            BPJSKesehatan,
            BPJSKesehatanKet,
            BPJSKetenagakerjaan,
            BPJSKetenagakerjaanKet,
        } = this.state

        return (
            <Fragment>
                {isListPreBoardingDetailLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5,].map((item) =>
                            <Skeleton
                                key={`isListPreBoardingDetailLoading_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <h2>
                            Data:
                        </h2>
                        <Checkbox
                            checked={Biodata ? Biodata.NamaLengkap ? true : false : false}
                            disabled
                        />
                        Nama <br />
                        <Checkbox
                            checked={Biodata ? Biodata.TempatLahir ? true : false : false}
                            disabled
                        />
                        TempatLahir <br />
                        <Checkbox
                            checked={Biodata ? Biodata.TanggalLahir ? true : false : false}
                            disabled
                        />
                        Tanggal Lahir <br />
                        <Checkbox
                            checked={Biodata ? Biodata.AlamatKTP ? true : false : false}
                            disabled
                        />
                        Alamat <br />
                        <Checkbox
                            checked={Biodata ? Biodata.Hp ? true : false : false}
                            disabled
                        />
                        No Tlp <br />
                        <Checkbox
                            checked={Biodata ? Biodata.KTP ? true : false : false}
                            disabled
                        />
                        KTP <br />
                        <Checkbox
                            checked={Biodata ? Biodata.KK ? true : false : false}
                            disabled
                        />
                        KK <br />
                        <Checkbox
                            checked={Biodata ? Biodata.NPWP ? true : false : false}
                            disabled
                        />
                        NPWP <br />
                        <Checkbox
                            checked={Biodata ? Biodata.BPJSKesehatan ? true : false : false}
                            disabled
                        />
                        BPJS Kesehatan <br />
                        <Checkbox
                            checked={Biodata ? Biodata.BPJSKetenagakerjaan ? true : false : false}
                            disabled
                        />
                        BPJS Ketenagakerjaan <br />
                        <Checkbox
                            checked={Biodata ? Biodata.BCA ? true : false : false}
                            disabled
                        />
                        BCA <br />
                        <Checkbox
                            checked={Biodata ? Biodata.Foto ? true : false : false}
                            disabled
                        />
                        Foto <br />
                        <form
                            onSubmit={this.formOnSubmit}
                        >
                            <h2>
                                Input Data:
                            </h2>

                            <Checkbox
                                name='KTP'
                                checked={KTP}
                                onChange={(e) => this.onChangeCheckbox(e)}
                            />
                            KTP <br />
                            <TextField
                                style={st_textfield_non}
                                variant='outlined'
                                type='text'
                                label='KTP Keterangan'
                                name='KTPKet'
                                value={KTPKet}
                                onChange={this.onChangeField}
                            />

                            <Checkbox
                                name='KK'
                                checked={KK}
                                onChange={(e) => this.onChangeCheckbox(e)}
                            />
                            KK <br />
                            <TextField
                                style={st_textfield_non}
                                variant='outlined'
                                type='text'
                                label='KK Keterangan'
                                name='KKKet'
                                value={KKKet}
                                onChange={this.onChangeField}
                            />

                            <Checkbox
                                name='NPWP'
                                checked={NPWP}
                                onChange={(e) => this.onChangeCheckbox(e)}
                            />
                            NPWP <br />
                            <TextField
                                style={st_textfield_non}
                                variant='outlined'
                                type='text'
                                label='NPWP Keterangan'
                                name='NPWPKet'
                                value={NPWPKet}
                                onChange={this.onChangeField}
                            />

                            <Checkbox
                                name='BCA'
                                checked={BCA}
                                onChange={(e) => this.onChangeCheckbox(e)}
                            />
                            BCA <br />
                            <TextField
                                style={st_textfield_non}
                                variant='outlined'
                                type='text'
                                label='BCA Keterangan'
                                name='BCAKet'
                                value={BCAKet}
                                onChange={this.onChangeField}
                            />

                            <Checkbox
                                name='BPJSKesehatan'
                                checked={BPJSKesehatan}
                                onChange={(e) => this.onChangeCheckbox(e)}
                            />
                            BPJSKesehatan <br />
                            <TextField
                                style={st_textfield_non}
                                variant='outlined'
                                type='text'
                                label='BPJSKesehatan Keterangan'
                                name='BPJSKesehatanKet'
                                value={BPJSKesehatanKet}
                                onChange={this.onChangeField}
                            />

                            <Checkbox
                                name='BPJSKetenagakerjaan'
                                checked={BPJSKetenagakerjaan}
                                onChange={(e) => this.onChangeCheckbox(e)}
                            />
                            BPJSKetenagakerjaan <br />
                            <TextField
                                style={st_textfield_non}
                                variant='outlined'
                                type='text'
                                label='BPJSKetenagakerjaan Keterangan'
                                name='BPJSKetenagakerjaanKet'
                                value={BPJSKetenagakerjaanKet}
                                onChange={this.onChangeField}
                            />

                            <h2>
                                Action:
                            </h2>
                            <FormControl style={st_textfield_non} variant="filled" required >
                                <InputLabel shrink >Selesai?</InputLabel>
                                <Select style={{ backgroundColor: 'white' }} native onChange={(e) => this.onChangeField(e)} label="Status" name='Status' value={Status}  >
                                    <option value="" disabled> -- select an option -- </option>
                                    {['Done'].map((option, index) =>
                                        <option key={index} value={option}>{option}</option>
                                    )}
                                </Select>
                            </FormControl>
                            <center>
                                <Button
                                    variant='contained'
                                    style={{ margin: '10% 0 5% 0', width: '60%' }}
                                    type='submit'
                                >
                                    Submit
                                </Button>
                            </center>
                        </form>
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
    ListPreBoardingDetail: state.Job.ListPreBoardingDetail,
    isListPreBoardingDetailLoading: state.Job.isListPreBoardingDetailLoading,
})

export default connect(mapStateToProps, { get_List_PreBoarding_Detail, Konfirm_PreBoarding })(Submit)