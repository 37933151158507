import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Role_Data, get_Role_Participan_Data, Add_To_RoleList, Delete_From_RoleList } from '../../../../../../Store/Actions/Setting.Action'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

import Detail from '../../../../../Containers/Dashboard/Setting/Role/Detail'
import AddParticipan from '../../../../../Containers/Dashboard/Setting/Role/AddParticipan'
import Data from '../../../../../Containers/Dashboard/Setting/Role/Data'

class SettingRoleView extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.RoleActionId !== prevProps.RoleActionId) {
            this.props.get_Role_Data(this.props.RoleActionId)
        }
        // if (this.props.RoleActionCounter !== prevProps.RoleActionCounter) {
        //     this.props.get_Role_Data(this.props.RoleActionId)
        // }
    }
    componentDidMount() {
        const { RoleActionId } = this.props
        if (RoleActionId !== null) {
            this.props.get_Role_Data(RoleActionId)
        }
        this.props.get_Role_Participan_Data()
    }

    DeleteFromRoleList(RoleListId) {
        this.props.Delete_From_RoleList(RoleListId)
    }
    render() {
        const isRoleActionAddListLoading = this.props.isRoleActionAddListLoading
        const RoleActionId = this.props.RoleActionId
        const Participant = this.props.Participant
        const isParticipantLoading = this.props.isParticipantLoading
        const isRoleActionGetDetailLoading = this.props.isRoleActionGetDetailLoading
        const RoleDetail = this.props.RoleDetail
        const RoleData = this.props.RoleData
        const HeadData = [
            {
                Nama: 'Index',
                width: '5%',
            },
            {
                Nama: 'Id',
                width: '10%',
            },
            {
                Nama: 'UserName',
                width: '10%',
            },
            {
                Nama: 'Nama',
                width: '10%',
            },
            {
                Nama: 'Department',
                width: '10%',
            },
            {
                Nama: 'Remove',
                width: '15%',
            }
        ]
        const BodyData = RoleData ? RoleData.map((item, index) => {
            return {
                index: index + 1,
                Id: item.Id,
                UserName: item.UserName,
                Nama: item.BiodataNama,
                Department: item.DepartemenNama,
                Remove: (
                    <DeleteOutlineIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.DeleteFromRoleList(item.Id)}
                    />
                )
            }
        }) : []
        return (
            <Fragment>
                <Detail Data={RoleDetail} isLoading={isRoleActionGetDetailLoading} />
                <AddParticipan ParticipanData={Participant} ParticipanDataLoading={isParticipantLoading} RoleId={RoleActionId} isLoading={isRoleActionAddListLoading} sendFunction={this.props.Add_To_RoleList} />
                <Data HeadData={HeadData} BodyData={BodyData} isLoading={isRoleActionGetDetailLoading} />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isRoleActionAddListLoading: state.Setting.isRoleActionAddListLoading,
    isRoleActionGetDetailLoading: state.Setting.isRoleActionGetDetailLoading,
    RoleActionId: state.Setting.RoleActionId,
    Participant: state.Setting.Participant,
    isParticipantLoading: state.Setting.isParticipantLoading,
    RoleDetail: state.Setting.RoleDetail,
    RoleData: state.Setting.RoleData,
})

export default connect(mapStateToProps, { get_Role_Data, get_Role_Participan_Data, Add_To_RoleList, Delete_From_RoleList })(SettingRoleView)
