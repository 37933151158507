import React, { Fragment } from "react";

import { connect } from "react-redux";

import { Create_Warning_Messages } from "../../../../../../Store/Actions/Messages.Actions";
import {
  Add_Jobdesk,
  get_Jobdesk_Master_User,
  get_Jobdesk_Data,
} from "../../../../../../Store/Actions/Jobdesk.Action";

import {
  Skeleton,
  Checkbox,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Button,
  Autocomplete,
  CircularProgress,
} from "@mui/material";

import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

import CoTable from "../../../../../Containers/Table";

import { convertToMonthName } from "../../../../../../Global";

class Jobdesk extends React.Component {
  state = {
    NamaJabatan: "",
    Level: "",
    Departemen: "",
    TipePegawai: "",
    Bulan: "",
    AtasanLangsung: null,
    AtasanTakLangsung: null,
    Tujuan: "",
    Wewenang: "",
    TanggungJawab: "",
    UraianTugas: "",
    JumlahBawahanLangsung: 0,
    PosisiBawahanLangsung: "",
    JumlahBawahanTakLangsung: 0,
    PosisiBawahanTakLangsung: "",
    Internal: "",
    Eksternal: "",
    Pendidikan: "",
    Jurusan: "",
    UsiaMax: "",
    UsiaMin: "",
    KeterampilanUmum: "",
    PengetahuanKhusus: "",
    KeterampilanKhusus: "",
    Sertifikasi: "",
    PengalamanKerja: "",
    DalamKantor: "",
    DalamLapangan: "",
    LokasiLainnya: "",
    KompetensiPrilaku: [
      {
        Uraian: "Pengetahuan Bisnis & Fungsional",
        Point: 0,
      },
      {
        Uraian: "Orientasi Pada Hasil",
        Point: 0,
      },
      {
        Uraian: "Kerjasama",
        Point: 0,
      },
      {
        Uraian: "Fokus Pada Customer",
        Point: 0,
      },
      {
        Uraian: "Jiwa Kepemimpinan",
        Point: 0,
      },
    ],
    KompetensiTeknik: [
      {
        Uraian: "",
        Point: 0,
      },
      {
        Uraian: "",
        Point: 0,
      },
      {
        Uraian: "",
        Point: 0,
      },
      {
        Uraian: "",
        Point: 0,
      },
      {
        Uraian: "",
        Point: 0,
      },
    ],
  };

  componentDidMount() {
    const { isUseTemplate, TemplateId, MPPSelectDetail } = this.props;
    if (isUseTemplate) {
      this.props.get_Jobdesk_Data(TemplateId);
    }
    this.props.get_Jobdesk_Master_User();

    const Level = MPPSelectDetail.LevelId;

    const HeadKompetensiPrilaku = [
      {
        Uraian: "Pengetahuan Bisnis & Fungsional",
        Point: 3,
      },
      {
        Uraian: "Orientasi Pada Hasil",
        Point: 3,
      },
      {
        Uraian: "Kerjasama",
        Point: 3,
      },
      {
        Uraian: "Fokus Pada Customer",
        Point: 3,
      },
      {
        Uraian: "Jiwa Kepemimpinan",
        Point: 3,
      },
    ];

    const SPKompetensiPrilaku = [
      {
        Uraian: "Pengetahuan Bisnis & Fungsional",
        Point: 2,
      },
      {
        Uraian: "Orientasi Pada Hasil",
        Point: 3,
      },
      {
        Uraian: "Kerjasama",
        Point: 3,
      },
      {
        Uraian: "Fokus Pada Customer",
        Point: 2,
      },
      {
        Uraian: "Jiwa Kepemimpinan",
        Point: 2,
      },
    ];

    const STKompetensiPrilaku = [
      {
        Uraian: "Pengetahuan Bisnis & Fungsional",
        Point: 1,
      },
      {
        Uraian: "Orientasi Pada Hasil",
        Point: 2,
      },
      {
        Uraian: "Kerjasama",
        Point: 3,
      },
      {
        Uraian: "Fokus Pada Customer",
        Point: 2,
      },
      {
        Uraian: "Jiwa Kepemimpinan",
        Point: 1,
      },
    ];

    const NonKompetensiPrilaku = [
      {
        Uraian: "Pengetahuan Bisnis & Fungsional",
        Point: 0,
      },
      {
        Uraian: "Orientasi Pada Hasil",
        Point: 0,
      },
      {
        Uraian: "Kerjasama",
        Point: 0,
      },
      {
        Uraian: "Fokus Pada Customer",
        Point: 0,
      },
      {
        Uraian: "Jiwa Kepemimpinan",
        Point: 0,
      },
    ];

    this.setState({
      NamaJabatan: `${
        MPPSelectDetail?.DepNama ? MPPSelectDetail.DepNama : ""
      } - ${MPPSelectDetail?.LevNama ? MPPSelectDetail.LevNama : ""} ${
        MPPSelectDetail?.Sub ? MPPSelectDetail.Sub : ""
      }`,
      Level: MPPSelectDetail?.LevNama ? MPPSelectDetail.LevNama : "",
      Departemen: MPPSelectDetail?.DepNama ? MPPSelectDetail.DepNama : "",
      TipePegawai: MPPSelectDetail?.StatusKaryawan
        ? MPPSelectDetail.StatusKaryawan
        : "",
      Bulan: MPPSelectDetail?.BulanBuka ? MPPSelectDetail.BulanBuka : 0,
      KompetensiPrilaku:
        Level === "12898" ||
        Level === "4968" ||
        Level === "4736" ||
        Level === "15894"
          ? HeadKompetensiPrilaku
          : Level === "1224" || Level === "7845" || Level === "83910"
          ? SPKompetensiPrilaku
          : Level === "78955" ||
            Level === "54621" ||
            Level === "64925" ||
            Level === "62659" ||
            Level === "19902" ||
            Level === "83032"
          ? STKompetensiPrilaku
          : NonKompetensiPrilaku,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.JobdeskData !== prevProps.JobdeskData) {
      const { isUseTemplate, JobdeskListTeknik } = this.props;
      const FinalTemplateData = this.props.JobdeskData;
      const newKompetensiTeknik = [];

      for (const x in JobdeskListTeknik) {
        const JobdeskListTeknikDetail = JobdeskListTeknik[x];

        const defaultKompetensiTeknik = {
          Uraian: JobdeskListTeknikDetail?.Uraian
            ? JobdeskListTeknikDetail.Uraian
            : "",
          Point: JobdeskListTeknikDetail?.Point
            ? JobdeskListTeknikDetail.Point
            : 0,
        };

        newKompetensiTeknik.push(defaultKompetensiTeknik);
      }

      this.setState({
        AtasanLangsung: isUseTemplate
          ? FinalTemplateData?.AtasanLangsungNama
            ? FinalTemplateData.AtasanLangsungNama
            : null
          : null,
        AtasanTakLangsung: isUseTemplate
          ? FinalTemplateData?.AtasanTakLangsungNama
            ? FinalTemplateData.AtasanTakLangsungNama
            : null
          : null,
        Tujuan: isUseTemplate
          ? FinalTemplateData?.Tujuan
            ? FinalTemplateData.Tujuan
            : ""
          : "",
        Wewenang: isUseTemplate
          ? FinalTemplateData?.Wewenang
            ? FinalTemplateData.Wewenang
            : ""
          : "",
        TanggungJawab: isUseTemplate
          ? FinalTemplateData?.TanggungJawab
            ? FinalTemplateData.TanggungJawab
            : ""
          : "",
        UraianTugas: isUseTemplate
          ? FinalTemplateData?.UraianTugas
            ? FinalTemplateData.UraianTugas
            : ""
          : "",
        JumlahBawahanLangsung: isUseTemplate
          ? FinalTemplateData?.JumlahBawahanLangsung
            ? FinalTemplateData.JumlahBawahanLangsung
            : ""
          : "",
        PosisiBawahanLangsung: isUseTemplate
          ? FinalTemplateData?.PosisiBawahanLangsung
            ? FinalTemplateData.PosisiBawahanLangsung
            : ""
          : "",
        JumlahBawahanTakLangsung: isUseTemplate
          ? FinalTemplateData?.JumlahBawahanTakLangsung
            ? FinalTemplateData.JumlahBawahanTakLangsung
            : ""
          : "",
        PosisiBawahanTakLangsung: isUseTemplate
          ? FinalTemplateData?.PosisiBawahanTakLangsung
            ? FinalTemplateData.PosisiBawahanTakLangsung
            : ""
          : "",
        Internal: isUseTemplate
          ? FinalTemplateData?.Internal
            ? FinalTemplateData.Internal
            : ""
          : "",
        Eksternal: isUseTemplate
          ? FinalTemplateData?.Eksternal
            ? FinalTemplateData.Eksternal
            : ""
          : "",
        Pendidikan: isUseTemplate
          ? FinalTemplateData?.Pendidikan
            ? FinalTemplateData.Pendidikan
            : ""
          : "",
        Jurusan: isUseTemplate
          ? FinalTemplateData?.Jurusan
            ? FinalTemplateData.Jurusan
            : ""
          : "",
        UsiaMax: isUseTemplate
          ? FinalTemplateData?.UsiaMax
            ? FinalTemplateData.UsiaMax
            : ""
          : "",
        UsiaMin: isUseTemplate
          ? FinalTemplateData?.UsiaMin
            ? FinalTemplateData.UsiaMin
            : ""
          : "",
        KeterampilanUmum: isUseTemplate
          ? FinalTemplateData?.KeterampilanUmum
            ? FinalTemplateData.KeterampilanUmum
            : ""
          : "",
        PengetahuanKhusus: isUseTemplate
          ? FinalTemplateData?.PengetahuanKhusus
            ? FinalTemplateData.PengetahuanKhusus
            : ""
          : "",
        KeterampilanKhusus: isUseTemplate
          ? FinalTemplateData?.KeterampilanKhusus
            ? FinalTemplateData.KeterampilanKhusus
            : ""
          : "",
        Sertifikasi: isUseTemplate
          ? FinalTemplateData?.Sertifikasi
            ? FinalTemplateData.Sertifikasi
            : ""
          : "",
        PengalamanKerja: isUseTemplate
          ? FinalTemplateData?.PengalamanKerja
            ? FinalTemplateData.PengalamanKerja
            : ""
          : "",
        DalamKantor: isUseTemplate
          ? FinalTemplateData?.DalamKantor
            ? FinalTemplateData.DalamKantor
            : ""
          : "",
        DalamLapangan: isUseTemplate
          ? FinalTemplateData?.DalamLapangan
            ? FinalTemplateData.DalamLapangan
            : ""
          : "",
        LokasiLainnya: isUseTemplate
          ? FinalTemplateData?.LokasiLainnya
            ? FinalTemplateData.LokasiLainnya
            : ""
          : "",
        KompetensiTeknik: newKompetensiTeknik,
      });
    }
  }

  onChangeField = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  setAtasanLangsung = (NewData) => {
    this.setState({ AtasanLangsung: NewData });
  };

  setAtasanTakLangsung = (NewData) => {
    this.setState({ AtasanTakLangsung: NewData });
  };

  AddDetailList = () => {
    const KompetensiPrilaku = this.state.KompetensiPrilaku;

    const isThereZero = KompetensiPrilaku.find((item) => item.Uraian === "");
    const isThereZero2 = KompetensiPrilaku.find((item) => item.Point === 0);

    if (
      KompetensiPrilaku.length === 0 ||
      (KompetensiPrilaku[KompetensiPrilaku.length - 1].Uraian &&
        KompetensiPrilaku[KompetensiPrilaku.length - 1].Point !== 0 &&
        !isThereZero &&
        !isThereZero2)
    ) {
      const newData = { Uraian: "", Point: 0 };
      KompetensiPrilaku.push(newData);
      this.setState({ KompetensiPrilaku: KompetensiPrilaku });
    } else {
      this.props.Create_Warning_Messages(
        null,
        "mohon lengkapi proses pengisian terlebih dahulu"
      );
    }
  };

  DeleteDetailList = (Index) => {
    const KompetensiPrilaku = this.state.KompetensiPrilaku;
    if (KompetensiPrilaku.length > 5) {
      if (Index > 4) {
        KompetensiPrilaku.splice(Index, 1);
        this.setState({ KompetensiPrilaku: KompetensiPrilaku });
      } else {
        this.props.Create_Warning_Messages(
          null,
          "point 1-5 tidak bisa dihapus"
        );
      }
    } else {
      this.props.Create_Warning_Messages(null, "Minimal 5");
    }
  };

  OnChangeMapItem = (e, Index, Order) => {
    const KompetensiPrilaku = this.state.KompetensiPrilaku;
    if (Order === 1) {
      KompetensiPrilaku[Index].Uraian = e.target.value;
    } else {
    }
    this.setState({ KompetensiPrilaku: KompetensiPrilaku });
  };

  onChangeCheckboxMap = (e, index, value) => {
    const KompetensiPrilaku = this.state.KompetensiPrilaku;
    KompetensiPrilaku[index].Point = value;
    this.setState({ KompetensiPrilaku: KompetensiPrilaku });
  };

  AddDetailListTeknik = () => {
    const KompetensiTeknik = this.state.KompetensiTeknik;

    const isThereZero = KompetensiTeknik.find((item) => item.Uraian === "");
    const isThereZero2 = KompetensiTeknik.find((item) => item.Point === 0);

    if (
      KompetensiTeknik.length === 0 ||
      (KompetensiTeknik[KompetensiTeknik.length - 1].Uraian &&
        KompetensiTeknik[KompetensiTeknik.length - 1].Point !== 0 &&
        !isThereZero &&
        !isThereZero2)
    ) {
      const newData = { Uraian: "", Point: 0 };
      KompetensiTeknik.push(newData);
      this.setState({ KompetensiTeknik: KompetensiTeknik });
    } else {
      this.props.Create_Warning_Messages(
        null,
        "mohon lengkapi proses pengisian terlebih dahulu"
      );
    }
  };

  DeleteDetailListTeknik = (Index) => {
    const KompetensiTeknik = this.state.KompetensiTeknik;
    if (KompetensiTeknik.length > 5) {
      KompetensiTeknik.splice(Index, 1);
      this.setState({ KompetensiTeknik: KompetensiTeknik });
    } else {
      this.props.Create_Warning_Messages(null, "Minimal 5");
    }
  };

  OnChangeMapItemTeknik = (e, Index, Order) => {
    const KompetensiTeknik = this.state.KompetensiTeknik;
    if (Order === 1) {
      KompetensiTeknik[Index].Uraian = e.target.value;
    } else {
    }
    this.setState({ KompetensiTeknik: KompetensiTeknik });
  };

  onChangeCheckboxMapTeknik = (e, index, value) => {
    const KompetensiTeknik = this.state.KompetensiTeknik;
    KompetensiTeknik[index].Point = value;
    this.setState({ KompetensiTeknik: KompetensiTeknik });
  };

  formOnSubmit = (Status) => {
    // const MPPData = this.props.MPPData
    // const MPPDetail = this.props.MPPDetail
    const { MPPSelectDetail } = this.props;

    const KompetensiPrilaku = this.state.KompetensiPrilaku;
    const KompetensiTeknik = this.state.KompetensiTeknik;
    const isThereZero = KompetensiPrilaku.find((item) => item.Point === 0);
    const isThereZero2 = KompetensiTeknik.find((item) => item.Point === 0);

    if (isThereZero || isThereZero2) {
      this.props.Create_Warning_Messages(null, "tidak boleh ada yang kosong");
    } else {
      const { JobdeskData } = this.props;

      this.props.Add_Jobdesk(
        MPPSelectDetail.Id,
        this.state.NamaJabatan,
        MPPSelectDetail.MPPListId,
        MPPSelectDetail.LevelId,
        MPPSelectDetail.DepartemenId,
        this.state.TipePegawai,
        this.state.Bulan,
        this.state.AtasanLangsung
          ? typeof this.state.AtasanLangsung === "object"
            ? this.state.AtasanLangsung.Value
            : JobdeskData.AtasanLangsung
          : "",
        this.state.AtasanTakLangsung
          ? typeof this.state.AtasanTakLangsung === "object"
            ? this.state.AtasanTakLangsung.Value
            : JobdeskData.AtasanTakLangsung
          : "",
        this.state.Tujuan,
        this.state.Wewenang,
        this.state.TanggungJawab,
        this.state.UraianTugas,
        this.state.JumlahBawahanLangsung,
        this.state.PosisiBawahanLangsung,
        this.state.JumlahBawahanTakLangsung,
        this.state.PosisiBawahanTakLangsung,
        this.state.Internal,
        this.state.Eksternal,
        this.state.Pendidikan,
        this.state.Jurusan,
        this.state.UsiaMax,
        this.state.UsiaMin,
        this.state.KeterampilanUmum,
        this.state.PengetahuanKhusus,
        this.state.KeterampilanKhusus,
        this.state.Sertifikasi,
        this.state.PengalamanKerja,
        this.state.DalamKantor,
        this.state.DalamLapangan,
        this.state.LokasiLainnya,
        Status,
        KompetensiPrilaku,
        KompetensiTeknik
      );
    }
  };

  render() {
    const isJobdeskActionGetDataLoading =
      this.props.isJobdeskActionGetDataLoading;
    // const MPPData = this.props.MPPData
    // const MPPDetail = this.props.MPPDetail
    const isJobdeskGetMasterUserLoading =
      this.props.isJobdeskGetMasterUserLoading;
    const JobdeskGetMasterUser = this.props.JobdeskGetMasterUser;

    // const MPPSelectId = this.props.MPPSelectId
    // const MPPSelectDetail = this.props.MPPSelectDetail
    // const isUseTemplate = this.props.isUseTemplate
    // const TemplateId = this.props.TemplateId
    // const FinalTemplateData = this.props.FinalTemplateData

    // const MPPList = this.props.MPPList
    // const MPPListItem = this.props.MPPListItem
    // const MPPDetail = this.props.MPPDetail
    // const isPRAddLoading = this.props.isPRAddLoading

    const {
      NamaJabatan,
      Level,
      Departemen,
      TipePegawai,
      Bulan,
      AtasanLangsung,
      AtasanTakLangsung,
      Tujuan,
      Wewenang,
      TanggungJawab,
      UraianTugas,
      JumlahBawahanLangsung,
      PosisiBawahanLangsung,
      JumlahBawahanTakLangsung,
      PosisiBawahanTakLangsung,
      Internal,
      Eksternal,
      Pendidikan,
      Jurusan,
      UsiaMax,
      UsiaMin,
      KeterampilanUmum,
      PengetahuanKhusus,
      KeterampilanKhusus,
      Sertifikasi,
      PengalamanKerja,
      // DalamKantor,
      // DalamLapangan,
      LokasiLainnya,
      KompetensiPrilaku,
      KompetensiTeknik,
    } = this.state;

    const st_textfield = {
      marginTop: "10px",
      marginBottom: "10px",
      width: "100%",
    };
    const st_textfield_disable = {
      marginTop: "10px",
      marginBottom: "10px",
      width: "100%",
      backgroundColor: "#E0E0E0",
    };
    const st_TextSmall = {
      width: "40%",
      margin: "0 1% 0 0",
      marginTop: "10px",
      marginBottom: "10px",
    };
    // const st_TextSmall_disable = { width: '20%', margin: '0 1% 0 0', backgroundColor: '#E0E0E0' }
    const st_icon = {
      color: "grey",
      fontSize: "180%",
      margin: "0 0 0 1%",
      cursor: "pointer",
    };

    const HeadData1 = [
      {
        Nama: "No",
        width: "5%",
      },
      {
        Nama: "Description",
        width: "35%",
      },
      {
        Nama: "1",
        width: "5%",
      },
      {
        Nama: "2",
        width: "5%",
      },
      {
        Nama: "3",
        width: "5%",
      },
      {
        Nama: "Add",
        width: "10%",
      },
      {
        Nama: "Delete",
        width: "10%",
      },
    ];

    const BodyData1 = KompetensiTeknik.map((item, index) => {
      return {
        index: index + 1,
        Pertanyaan: (
          <TextField
            value={KompetensiTeknik[index].Uraian}
            style={{ width: "90%" }}
            required
            label={`Description ${index + 1} `}
            onChange={(e) => this.OnChangeMapItemTeknik(e, index, 1)}
          />
        ),
        Point1: (
          <Checkbox
            checked={KompetensiTeknik[index].Point === 1 ? true : false}
            onChange={(e) => this.onChangeCheckboxMapTeknik(e, index, 1)}
          />
        ),
        Point2: (
          <Checkbox
            checked={KompetensiTeknik[index].Point === 2 ? true : false}
            onChange={(e) => this.onChangeCheckboxMapTeknik(e, index, 2)}
          />
        ),
        Point3: (
          <Checkbox
            checked={KompetensiTeknik[index].Point === 3 ? true : false}
            onChange={(e) => this.onChangeCheckboxMapTeknik(e, index, 3)}
          />
        ),
        Tambah: (
          <AddCircleRoundedIcon
            onClick={this.AddDetailListTeknik}
            style={st_icon}
          />
        ),
        Hapus: (
          <DeleteOutlineRoundedIcon
            onClick={(e) => this.DeleteDetailListTeknik(index)}
            style={st_icon}
          />
        ),
      };
    });

    const HeadData = [
      {
        Nama: "No",
        width: "5%",
      },
      {
        Nama: "Description",
        width: "35%",
      },
      {
        Nama: "1",
        width: "5%",
      },
      {
        Nama: "2",
        width: "5%",
      },
      {
        Nama: "3",
        width: "5%",
      },
      {
        Nama: "Add",
        width: "10%",
      },
      {
        Nama: "Delete",
        width: "10%",
      },
    ];

    const BodyData = KompetensiPrilaku.map((item, index) => {
      return {
        index: index + 1,
        Pertanyaan: (
          <TextField
            value={KompetensiPrilaku[index].Uraian}
            style={{
              width: "90%",
              backgroundColor: index < 5 ? "#E0E0E0" : "white",
            }}
            disabled={index < 5 ? true : false}
            required={index < 5 ? false : true}
            label={`Description ${index + 1} `}
            onChange={(e) => this.OnChangeMapItem(e, index, 1)}
          />
        ),
        Point1: (
          <Checkbox
            checked={KompetensiPrilaku[index].Point === 1 ? true : false}
            disabled={index < 5 ? true : false}
            onChange={(e) => this.onChangeCheckboxMap(e, index, 1)}
          />
        ),
        Point2: (
          <Checkbox
            checked={KompetensiPrilaku[index].Point === 2 ? true : false}
            disabled={index < 5 ? true : false}
            onChange={(e) => this.onChangeCheckboxMap(e, index, 2)}
          />
        ),
        Point3: (
          <Checkbox
            checked={KompetensiPrilaku[index].Point === 3 ? true : false}
            disabled={index < 5 ? true : false}
            onChange={(e) => this.onChangeCheckboxMap(e, index, 3)}
          />
        ),
        Tambah: (
          <AddCircleRoundedIcon onClick={this.AddDetailList} style={st_icon} />
        ),
        Hapus: (
          <DeleteOutlineRoundedIcon
            onClick={(e) => this.DeleteDetailList(index)}
            style={st_icon}
          />
        ),
      };
    });

    const OpPendidikan = [
      {
        Option: "SMA",
        value: "SMA",
      },
      {
        Option: "D3",
        value: "D3",
      },
      {
        Option: "D4",
        value: "D4",
      },
      {
        Option: "S1",
        value: "S1",
      },
      {
        Option: "S2",
        value: "S2",
      },
      {
        Option: "S3",
        value: "S3",
      },
    ];

    return (
      <Fragment>
        {isJobdeskActionGetDataLoading || isJobdeskGetMasterUserLoading ? (
          <Fragment>
            {[1, 2, 3, 4, 5, 6, 7].map((item) => (
              <Skeleton height={"8vh"} key={`PRForm_Skeleton_${item} `} />
            ))}
          </Fragment>
        ) : (
          <Fragment>
            <h3>Position Identity</h3>
            <TextField
              style={st_textfield_disable}
              variant="outlined"
              type="text"
              label="Name of Position"
              name="NamaJabatan"
              disabled
              // value={MPPData ? `${ MPPData.DepNama } - ${ MPPData.LevNama } ${ MPPData.Sub } ` : ''}
              value={NamaJabatan}
              // onChange={this.onChangeField}
            />
            <TextField
              style={st_textfield_disable}
              variant="outlined"
              type="text"
              label="Level"
              name="Level"
              disabled
              // value={MPPData ? MPPData.LevNama : ''}
              value={Level}
              // onChange={this.onChangeField}
            />
            <TextField
              style={st_textfield_disable}
              variant="outlined"
              type="text"
              label="Department"
              name="Departemen"
              disabled
              // value={MPPData ? MPPData.DepNama : ''}
              value={Departemen}
              // onChange={this.onChangeField}
            />
            <TextField
              style={st_textfield_disable}
              variant="outlined"
              type="text"
              label="Employee Type"
              name="TipePegawai"
              disabled
              value={TipePegawai}
              // value={Level}
              // onChange={this.onChangeField}
            />
            <TextField
              style={st_textfield_disable}
              variant="outlined"
              type="text"
              label="Month"
              name="Bulan"
              disabled
              value={convertToMonthName(Bulan)}
              // value={Level}
              // onChange={this.onChangeField}
            />
            {/* < TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Direct Supervisor'
                                name='AtasanLangsung'
                                value={AtasanLangsung}
                                onChange={this.onChangeField}
                            /> */}

            <Autocomplete
              value={AtasanLangsung}
              onChange={(event, newParticipan) => {
                this.setAtasanLangsung(newParticipan ? newParticipan : null);
              }}
              options={JobdeskGetMasterUser}
              // getOptionLabel={option => option.Name}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.Name
              }
              style={st_textfield}
              disabled={isJobdeskGetMasterUserLoading ? true : false}
              renderInput={(params) => (
                <TextField
                  value={AtasanLangsung ? AtasanLangsung.Value : ""}
                  name="Participan"
                  label={
                    isJobdeskGetMasterUserLoading ? (
                      <CircularProgress />
                    ) : (
                      "Direct Supervisor"
                    )
                  }
                  variant="outlined"
                  disabled={isJobdeskGetMasterUserLoading ? true : false}
                  {...params}
                />
              )}
            />
            {/* < TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Indirect Supervisor'
                                name='AtasanTakLangsung'
                                value={AtasanTakLangsung}
                                onChange={this.onChangeField}
                            /> */}
            <Autocomplete
              value={AtasanTakLangsung}
              onChange={(event, newParticipan) => {
                this.setAtasanTakLangsung(newParticipan ? newParticipan : null);
              }}
              options={JobdeskGetMasterUser}
              // getOptionLabel={option => option.Name}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.Name
              }
              style={st_textfield}
              disabled={isJobdeskGetMasterUserLoading ? true : false}
              renderInput={(params) => (
                <TextField
                  value={AtasanTakLangsung ? AtasanTakLangsung.Value : ""}
                  name="Participan"
                  label={
                    isJobdeskGetMasterUserLoading ? (
                      <CircularProgress />
                    ) : (
                      "Indirect Supervisor"
                    )
                  }
                  variant="outlined"
                  disabled={isJobdeskGetMasterUserLoading ? true : false}
                  {...params}
                />
              )}
            />
            <h3>Job Purpose</h3>
            <TextField
              style={st_textfield}
              variant="outlined"
              multiline
              rows={8}
              type="text"
              label="Job Purpose"
              name="Tujuan"
              value={Tujuan}
              required
              onChange={this.onChangeField}
            />
            <h3>Authority & Main Responsibility</h3>
            <TextField
              style={st_textfield}
              variant="outlined"
              multiline
              rows={8}
              type="text"
              label="Authority"
              name="Wewenang"
              value={Wewenang}
              onChange={this.onChangeField}
            />
            <TextField
              style={st_textfield}
              variant="outlined"
              multiline
              rows={8}
              type="text"
              label="Main Responsibility"
              name="TanggungJawab"
              value={TanggungJawab}
              required
              onChange={this.onChangeField}
            />
            <h3>Task Description</h3>
            <TextField
              style={st_textfield}
              variant="outlined"
              multiline
              rows={12}
              type="text"
              label="Task Description"
              name="UraianTugas"
              value={UraianTugas}
              required
              onChange={this.onChangeField}
            />
            <h3>Subordinate</h3>
            <TextField
              style={st_TextSmall}
              variant="outlined"
              type="number"
              label="Number of Direct Subordinate"
              name="JumlahBawahanLangsung"
              value={JumlahBawahanLangsung}
              onChange={this.onChangeField}
            />
            <TextField
              style={st_TextSmall}
              variant="outlined"
              type="text"
              label="Direct Subordinate Position"
              name="PosisiBawahanLangsung"
              value={PosisiBawahanLangsung}
              onChange={this.onChangeField}
            />
            <TextField
              style={st_TextSmall}
              variant="outlined"
              type="number"
              label="Number of Indirect Subordinate"
              name="JumlahBawahanTakLangsung"
              value={JumlahBawahanTakLangsung}
              onChange={this.onChangeField}
            />
            <TextField
              style={st_TextSmall}
              variant="outlined"
              type="text"
              label="Indirect Subordinate Position"
              name="PosisiBawahanTakLangsung"
              value={PosisiBawahanTakLangsung}
              onChange={this.onChangeField}
            />
            <h2>Working Relationship</h2>
            <TextField
              style={st_textfield}
              variant="outlined"
              type="text"
              label="Internal Party"
              name="Internal"
              value={Internal}
              onChange={this.onChangeField}
            />
            <TextField
              style={st_textfield}
              variant="outlined"
              type="text"
              label="External Party"
              name="Eksternal"
              value={Eksternal}
              onChange={this.onChangeField}
            />
            <h3>Minimum Of Job Qualification</h3>
            <FormControl style={st_textfield} variant="filled" required>
              <InputLabel shrink>Educational</InputLabel>
              <Select
                native
                style={{ backgroundColor: "white" }}
                onChange={(e) => this.onChangeField(e)}
                label="Educational"
                name="Pendidikan"
                value={Pendidikan}
              >
                <option value="" disabled>
                  {" "}
                  -- select an option --{" "}
                </option>
                {OpPendidikan.map((option, index) => (
                  <option key={`Pendidikan_${index} `} value={option.value}>
                    {option.Option}
                  </option>
                ))}
              </Select>
            </FormControl>
            <TextField
              style={st_textfield}
              variant="outlined"
              type="text"
              label="Major/ Faculty"
              name="Jurusan"
              value={Jurusan}
              onChange={this.onChangeField}
            />
            <TextField
              style={st_TextSmall}
              variant="outlined"
              type="number"
              label="Minimum Age"
              name="UsiaMin"
              value={UsiaMin}
              onChange={this.onChangeField}
            />
            <TextField
              style={st_TextSmall}
              variant="outlined"
              type="number"
              label="Maximum Age"
              name="UsiaMax"
              value={UsiaMax}
              onChange={this.onChangeField}
            />
            <TextField
              style={st_textfield}
              variant="outlined"
              type="text"
              label="General Skill"
              name="KeterampilanUmum"
              value={KeterampilanUmum}
              onChange={this.onChangeField}
            />
            <TextField
              style={st_textfield}
              variant="outlined"
              type="text"
              label="Special Knowledge"
              name="PengetahuanKhusus"
              value={PengetahuanKhusus}
              onChange={this.onChangeField}
            />
            <TextField
              style={st_textfield}
              variant="outlined"
              multiline
              rows={8}
              type="text"
              label="Special Skill"
              name="KeterampilanKhusus"
              value={KeterampilanKhusus}
              onChange={this.onChangeField}
            />
            <TextField
              style={st_textfield}
              variant="outlined"
              type="text"
              label="Certification"
              name="Sertifikasi"
              value={Sertifikasi}
              onChange={this.onChangeField}
            />
            <TextField
              style={st_textfield}
              variant="outlined"
              type="number"
              label="Minimum Working Experience (Year)"
              name="PengalamanKerja"
              value={PengalamanKerja}
              onChange={this.onChangeField}
            />
            <h3>Work Location</h3>
            <FormControl style={st_textfield} variant="filled">
              <InputLabel shrink>Work Location</InputLabel>
              <Select
                native
                style={{ backgroundColor: "white" }}
                onChange={(e) => this.onChangeField(e)}
                label="Work Location"
                name="LokasiLainnya"
                value={LokasiLainnya}
              >
                <option value="" disabled>
                  {" "}
                  -- select an option --{" "}
                </option>
                {["Head Office", "Branch"].map((option, index) => (
                  <option key={`WorkLocation_${index} `} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </FormControl>
            {/* < TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Inside Office'
                                name='DalamKantor'
                                value={DalamKantor}
                                onChange={this.onChangeField}
                            />
                            < TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='In the Field'
                                name='DalamLapangan'
                                value={DalamLapangan}
                                onChange={this.onChangeField}
                            />
                            < TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Other Locations'
                                name='LokasiLainnya'
                                value={LokasiLainnya}
                                onChange={this.onChangeField}
                            /> */}
            <h3>Behaviour Competency</h3>
            {KompetensiPrilaku.length === 0 ? (
              <AddCircleRoundedIcon
                onClick={this.AddDetailList}
                style={st_icon}
              />
            ) : (
              <Fragment></Fragment>
            )}
            <CoTable
              TableId={"Jobdesk_KompetensiPrilaku"}
              HeadData={HeadData}
              Data={BodyData}
              // isHeadLoading={isPertanyaanLoading}
              // isBodyLoading={isPertanyaanLoading}
            />

            <h3>Technical Competency</h3>
            {KompetensiTeknik.length === 0 ? (
              <AddCircleRoundedIcon
                onClick={this.AddDetailListTeknik}
                style={st_icon}
              />
            ) : (
              <Fragment></Fragment>
            )}

            <CoTable
              TableId={"Jobdesk_KompetensiTeknik"}
              HeadData={HeadData1}
              Data={BodyData1}
              // isHeadLoading={isPertanyaanLoading}
              // isBodyLoading={isPertanyaanLoading}
            />
            <center>
              <Button
                variant="contained"
                color="secondary"
                style={{ width: "60%", margin: "2% 0 0 0" }}
                onClick={() => this.formOnSubmit(false)}
              >
                Save as Draft
              </Button>
              <Button
                variant="contained"
                style={{ width: "60%", margin: "2% 0 5% 0" }}
                onClick={() => this.formOnSubmit(true)}
              >
                Save
              </Button>
            </center>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  User: state.Auth.User,
  // isPRAddLoading: state.PR.isPRAddLoading,
  isJobdeskGetMasterUserLoading: state.Jobdesk.isJobdeskGetPertanyaanLoading,
  JobdeskGetMasterUser: state.Jobdesk.JobdeskGetMasterUser,
  isJobdeskActionGetDataLoading: state.Jobdesk.isJobdeskActionGetDataLoading,
  JobdeskData: state.Jobdesk.JobdeskData,
  // JobdeskListPrilaku: state.Jobdesk.JobdeskListPrilaku,
  JobdeskListTeknik: state.Jobdesk.JobdeskListTeknik,
  // MPPDetail: state.MPP.MPPDetail,
  // MPPList: state.MPP.MPPList,
  // MPPListItem: state.MPP.MPPListItem,
});

export default connect(mapStateToProps, {
  Create_Warning_Messages,
  get_Jobdesk_Data,
  Add_Jobdesk,
  get_Jobdesk_Master_User,
})(Jobdesk);
