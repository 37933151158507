import React, { Fragment } from "react"

const ComponentsError = ({ MessageCode, MessageTitle, Messages }) => {
    const initialCode = 500
    const initialMessageTitle = "Components Error"
    // const URLLocation = window.location.href
    // const WrongLocation = None
    const initialMessages = `Components Error Check Log`
    return (
        <Fragment>
            <center>
                <h1>{MessageCode ? MessageCode : initialCode}</h1>
                <h2>{MessageTitle ? MessageTitle : initialMessageTitle}</h2>
                <h3>{Messages ? Messages : initialMessages}</h3>
            </center>
        </Fragment>
    )
}

export default ComponentsError