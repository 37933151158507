import React, { Fragment } from "react"

import CoTable from "../../../../Containers/Table"

import { convertToMonthName } from "../../../../../Global"

const JobDescTracker = ({ isLoading, Data }) => {

    const st_TableHead = { color: 'white', backgroundColor: '#00a3df' }
    const st_TableBorderHead = { borderTop: '1px solid black', borderRight: '0px solid black', borderBottom: '1px solid black', borderLeft: '1px solid black', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }
    const st_TableBorder = { borderLeft: '1px solid black', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }


    const HeadData = [
        {
            Nama: 'No',
            width: '5%',
            KeyName: 'index',
            SortingType: 'Number',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Status',
            width: '5%',
            KeyName: 'Status',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Submission Number',
            width: '5%',
            KeyName: 'SubmissionNumber',
            SortingType: 'Number',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Submission Date',
            width: '5%',
            KeyName: 'TanggalPengajuan',
            SortingType: 'Date',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Division',
            width: '5%',
            KeyName: 'DivisionNama',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Department',
            width: '5%',
            KeyName: 'DepartemenNama',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'MPP Number',
            width: '25%',
            KeyName: 'MPPId',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Job Title',
            width: '25%',
            KeyName: 'Sub',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Level',
            width: '5%',
            KeyName: 'LevelNama',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Tahun',
            width: '5%',
            KeyName: 'Tahun',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Bulan Buka',
            width: '5%',
            KeyName: 'BulanBuka',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Contract Type',
            width: '5%',
            KeyName: 'StatusKaryawan',
            style: { ...st_TableHead, ...st_TableBorderHead, ...{ borderRight: '1px solid black' } },
            BodyStyle: { ...st_TableBorder, ...{ borderRight: '1px solid black' } }
        },
    ]

    const BodyData = Data ? Data.map((item, index) => {
        return {
            index: {
                value: index + 1
            },
            Status: {
                display: (
                    <span
                        style={{
                            color: item?.Status ? item.Id ? 'green'
                                : 'black' : 'red'
                        }}
                    >
                        {item?.Status ? item.Id ? 'Submitted' : 'Draft' : 'Not Submitted'}
                    </span>
                ),
                value: item?.Status ? item.Id ? 'Submitted' : 'Draft' : 'Not Submitted'
            },
            SubmissionNumber: {
                value: item?.Id ? item.Id : ' '
            },
            TanggalPengajuan: {
                display: item?.TanggalPengajuan ? new Date(item.TanggalPengajuan).toLocaleString(['ban', 'id']) : ' ',
                value: item?.TanggalPengajuan ? item.TanggalPengajuan : ' '
            },
            DivisionNama: {
                value: item?.DivisionNama ? item.DivisionNama : ' '
            },
            DepartemenNama: {
                value: item?.DepartemenNama ? item.DepartemenNama : ' '
            },
            MPPId: {
                value: item?.MPPId ? item.MPPId : ' '
            },
            Sub: {
                value: item?.NamaJabatan ? item.NamaJabatan : ' '
            },
            LevelNama: {
                value: item?.LevelNama ? item.LevelNama : ' '
            },
            Tahun: {
                value: item?.Tahun ? item.Tahun : ' '
            },
            BulanBuka: {
                value: convertToMonthName(item.BulanBuka)
            },
            StatusKaryawan: {
                value: item?.StatusKaryawan ? item.StatusKaryawan : ' '
            },
        }
    }) : []

    return (
        <Fragment>
            <CoTable
                TableId={'JobDescTracker'}
                HeadData={HeadData}
                Data={BodyData}
                isHeadLoading={isLoading}
                isBodyLoading={isLoading}
                isPagination={BodyData.length > 5 ? true : false}
                isPaginationLoading={isLoading}
                isHeading={true}
                isDownload={true}
                ComplexDataDownload={true}
            />
        </Fragment>
    )
}

export default JobDescTracker