import React, { Fragment } from "react";

import { connect } from "react-redux";

import {
  get_List_Interview_Jadwal,
  get_Job_Action_Id,
} from "../../../../Store/Actions/Job.Action";
import { get_Account_Action_Id } from "../../../../Store/Actions/Account.Action";

import { Collapse } from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import CoTable from "../../../Containers/Table";

class JadwalInterview extends React.Component {
  state = {
    MenungguOpen: true,
    SelesaiOpen: false,
  };

  componentDidMount() {
    this.props.get_List_Interview_Jadwal(this.props.User.Email);
  }

  onClickActionId(Id, AccountId) {
    this.props.get_Job_Action_Id(Id);
    this.props.get_Account_Action_Id(AccountId);
  }

  handleMenungguOpen = () => {
    this.setState({ MenungguOpen: !this.state.MenungguOpen });
  };
  handleSelesaiOpen = () => {
    this.setState({ SelesaiOpen: !this.state.SelesaiOpen });
  };

  render() {
    const isListInterviewJadwalLoading =
      this.props.isListInterviewJadwalLoading;
    const ListInterviewJadwal = this.props.ListInterviewJadwal;

    const HeadData = [
      {
        Nama: "No",
        width: "5%",
        KeyName: "index",
        SortingType: "Number",
      },
      {
        Nama: "No Registrasi",
        width: "10%",
        KeyName: "Noreg",
        SortingType: "Number",
      },
      {
        Nama: "Nama",
        width: "10%",
        KeyName: "BiodataNama",
      },
      {
        Nama: "Posisi",
        width: "10%",
        KeyName: "index",
      },
      {
        Nama: "Jadwal Id",
        width: "5%",
        KeyName: "Id",
        SortingType: "Number",
      },
      {
        Nama: "User Name",
        width: "5%",
        KeyName: "UserName",
      },
      {
        Nama: "Time Input",
        width: "5%",
        KeyName: "InputTime",
      },
      {
        Nama: "Time Local",
        width: "5%",
        KeyName: "LocalTime",
      },
    ];

    const FilterdListInterviewJadwal = ListInterviewJadwal
      ? ListInterviewJadwal.filter(
          (item, index) =>
            (item.UsICount === 0 || item.UsICount === null) &&
            new Date().getTime() < new Date(item.Waktu).getTime()
        )
      : [];

    const BodyData = FilterdListInterviewJadwal.map((item, index) => {
      return {
        index: {
          value: index + 1,
        },
        Noreg: {
          value: item.Noreg,
        },
        BiodataNama: {
          value: item.BiodataNama,
        },
        Posisi: {
          value: item.Posisi,
        },
        Id: {
          value: item.Id,
        },
        UserName: {
          value: item.UserName,
        },
        InputTime: {
          value: item.Waktu
            ? `${new Date(item.Waktu).getDate()}/${
                new Date(item.Waktu).getMonth() + 1
              }/${new Date(item.Waktu).getFullYear()} ${new Date(
                item.Waktu
              ).getUTCHours()}.${new Date(item.Waktu).getMinutes()}`
            : " ",
        },
        LocalTime: {
          value: new Date(item.Waktu).toLocaleString(["ban", "id"]),
        },
      };
    });

    const HeadData1 = [
      {
        Nama: "No",
        width: "5%",
        KeyName: "index",
        SortingType: "Number",
      },
      {
        Nama: "No Registrasi",
        width: "10%",
        KeyName: "Noreg",
        SortingType: "Number",
      },
      {
        Nama: "Nama",
        width: "10%",
        KeyName: "BiodataNama",
      },
      {
        Nama: "Posisi",
        width: "10%",
        KeyName: "index",
      },
      {
        Nama: "Jadwal Id",
        width: "5%",
        KeyName: "Id",
        SortingType: "Number",
      },
      {
        Nama: "User Name",
        width: "5%",
        KeyName: "UserName",
      },
      {
        Nama: "Time Input",
        width: "5%",
        KeyName: "InputTime",
      },
      {
        Nama: "Time Local",
        width: "5%",
        KeyName: "LocalTime",
      },
    ];

    const FilterdListInterviewJadwal1 = ListInterviewJadwal
      ? ListInterviewJadwal.filter(
          (item, index) =>
            item.UsICount > 0 &&
            item.UsICount !== null &&
            new Date().getTime() > new Date(item.Waktu).getTime()
        )
      : [];

    const BodyData1 = FilterdListInterviewJadwal1.map((item, index) => {
      return {
        index: {
          value: index + 1,
        },
        Noreg: {
          value: item.Noreg,
        },
        BiodataNama: {
          value: item.BiodataNama,
        },
        Posisi: {
          value: item.Posisi,
        },
        Id: {
          value: item.Id,
        },
        UserName: {
          value: item.UserName,
        },
        InputTime: {
          value: item.Waktu
            ? `${new Date(item.Waktu).getDate()}/${
                new Date(item.Waktu).getMonth() + 1
              }/${new Date(item.Waktu).getFullYear()} ${new Date(
                item.Waktu
              ).getUTCHours()}.${new Date(item.Waktu).getMinutes()}`
            : " ",
        },
        LocalTime: {
          value: new Date(item.Waktu).toLocaleString(["ban", "id"]),
        },
      };
    });

    const { MenungguOpen, SelesaiOpen } = this.state;

    const RowDataInOnePage = 5;

    return (
      <Fragment>
        <h2>Jadwal Interview</h2>
        <h3
          onClick={this.handleMenungguOpen}
          style={{
            display: "flex",
            alignContent: "center",
            verticalAlign: "center",
            cursor: "pointer",
          }}
        >
          Menunggu{MenungguOpen ? <ExpandLess /> : <ExpandMore />}
        </h3>
        <Collapse in={MenungguOpen} timeout="auto" unmountOnExit>
          <CoTable
            TableId={"JadwalInterview_Menunggu"}
            HeadData={HeadData}
            Data={BodyData}
            isHeadLoading={isListInterviewJadwalLoading}
            isBodyLoading={isListInterviewJadwalLoading}
            isPagination={BodyData.length > RowDataInOnePage ? true : false}
            MaxRowOnPage={RowDataInOnePage}
            isPaginationLoading={isListInterviewJadwalLoading}
            PaginationCounter={
              BodyData.length / RowDataInOnePage >
              Math.floor(BodyData.length / RowDataInOnePage)
                ? Math.floor(BodyData.length / RowDataInOnePage) + 1
                : BodyData.length / RowDataInOnePage
            }
            isHeading={true}
          />
        </Collapse>
        <div style={{ margin: "0 0 5% 0" }}>
          <h3
            onClick={this.handleSelesaiOpen}
            style={{
              display: "flex",
              alignContent: "center",
              verticalAlign: "center",
              cursor: "pointer",
            }}
          >
            Selesai{SelesaiOpen ? <ExpandLess /> : <ExpandMore />}
          </h3>
          <Collapse in={SelesaiOpen} timeout="auto" unmountOnExit>
            <CoTable
              TableId={"JadwalInterview_Selesai"}
              HeadData={HeadData1}
              Data={BodyData1}
              isHeadLoading={isListInterviewJadwalLoading}
              isBodyLoading={isListInterviewJadwalLoading}
              isPagination={BodyData1.length > RowDataInOnePage ? true : false}
              MaxRowOnPage={RowDataInOnePage}
              isPaginationLoading={isListInterviewJadwalLoading}
              PaginationCounter={
                BodyData1.length / RowDataInOnePage >
                Math.floor(BodyData1.length / RowDataInOnePage)
                  ? Math.floor(BodyData1.length / RowDataInOnePage) + 1
                  : BodyData1.length / RowDataInOnePage
              }
              isHeading={true}
            />
          </Collapse>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isListInterviewJadwalLoading: state.Job.isListInterviewJadwalLoading,
  ListInterviewJadwal: state.Job.ListInterviewJadwal,
  User: state.Auth.User,
});

export default connect(mapStateToProps, {
  get_List_Interview_Jadwal,
  get_Job_Action_Id,
  get_Account_Action_Id,
})(JadwalInterview);
