import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_List_Equipment_Detail } from '../../../../../../Store/Actions/Job.Action'

import { Skeleton } from '@mui/material'

class DetailEquipment extends React.Component {
    componentDidMount() {
        const { JobActionId, AccountActionId } = this.props
        this.props.get_List_Equipment_Detail(JobActionId, AccountActionId)
    }
    render() {
        const isListEquipmentDetailLoading = this.props.isListEquipmentDetailLoading
        const ListEquipmentDetailData = this.props.ListEquipmentDetail
        const ListEquipmentDetail = ListEquipmentDetailData ? ListEquipmentDetailData.Data : {}
        const ListEquipmentDetailList = ListEquipmentDetailData ? ListEquipmentDetailData.List : []

        return (
            <Fragment>
                {isListEquipmentDetailLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5,].map((item) =>
                            <Skeleton
                                key={`DetailMenunggu_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <h2>Equipment</h2>
                        <div
                            style={{
                                margin: '1% 0 1% 0', padding: '1%', border: `1px solid ${ListEquipmentDetail?.EqStatus ? ListEquipmentDetail.EqStatus === 'Selesai' ? 'Green' :
                                    ListEquipmentDetail.EqStatus === 'Reject' ? 'Red' :
                                        'orange' : 'black'}`
                            }}
                        >
                            <h4>
                                JoinDate:<br />
                                {ListEquipmentDetail?.PKDate ? new Date(ListEquipmentDetail.PKDate).toLocaleDateString(['ban', 'id']) : ''}<br /><br />
                            </h4>
                            <h4>
                                List Permintaan Equipment:<br />
                            </h4>
                            {ListEquipmentDetailList ? ListEquipmentDetailList.map((item, index) =>
                                <Fragment
                                    key={`ListEquipmentDetailList_${index}`}>
                                    <h5>
                                        {`${index + 1} - ${item.Nama} - Sebanyak: 1`}
                                    </h5>
                                </Fragment>) :
                                <Fragment></Fragment>}
                        </div>
                        {ListEquipmentDetail?.EqStatus ? ListEquipmentDetail.EqStatus ?
                            <Fragment>
                                <div
                                    style={{
                                        margin: '1% 0 1% 0', padding: '1%', border: `1px solid ${ListEquipmentDetail?.EqStatus ? ListEquipmentDetail.EqStatus === 'Selesai' ? 'Green' :
                                            ListEquipmentDetail.EqStatus === 'Reject' ? 'Red' :
                                                'orange' : 'black'}`
                                    }}
                                >
                                    <center>
                                        <h2
                                            style={{
                                                color: ListEquipmentDetail?.EqStatus ? ListEquipmentDetail.EqStatus === 'Selesai' ? 'Green' :
                                                    ListEquipmentDetail.EqStatus === 'Reject' ? 'Red' :
                                                        'orange' : 'black'
                                            }}
                                        >
                                            Status : {ListEquipmentDetail?.EqStatus ? ListEquipmentDetail.EqStatus : 'Status'}
                                        </h2>
                                    </center>
                                    <h4>
                                        Diupdate Pada:<br />
                                        {ListEquipmentDetail?.EqDate ? new Date(ListEquipmentDetail.EqDate).toLocaleString(['ban', 'id']) : ''}<br /><br />
                                        Oleh:<br />
                                        {ListEquipmentDetail?.BiodataNama ? ListEquipmentDetail.BiodataNama : ''}<br /><br />
                                        Email:<br />
                                        {ListEquipmentDetail?.EqEmail ? ListEquipmentDetail.EqEmail : ''}<br />
                                        Keterangan:<br />
                                        {ListEquipmentDetail?.EqKet ? ListEquipmentDetail.EqKet : ''}<br />
                                    </h4>
                                </div>
                            </Fragment> :
                            <Fragment></Fragment> : <Fragment></Fragment>}
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isListEquipmentDetailLoading: state.Job.isListEquipmentDetailLoading,
    ListEquipmentDetail: state.Job.ListEquipmentDetail,
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
})

export default connect(mapStateToProps, { get_List_Equipment_Detail })(DetailEquipment)