import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Lokasi_Data, Delete_Lokasi } from '../../../../../../Store/Actions/Setting.Action'

import { FormGroup, FormControlLabel, Checkbox, Button, CircularProgress } from '@mui/material'

import Detail from '../../../../../Containers/Dashboard/Setting/Lokasi/Detail'

class SettingLokasiDelete extends React.Component {
    state = {
        isConfirm: false,
    }
    componentDidUpdate(prevProps) {
        if (this.props.LokasiActionId !== prevProps.LokasiActionId) {
            this.props.get_Lokasi_Data(this.props.LokasiActionId)
        }
    }
    componentDidMount() {
        const { LokasiActionId } = this.props
        if (LokasiActionId !== null) {
            this.props.get_Lokasi_Data(LokasiActionId)
        }
    }

    OnChangeCheckbox = E => {
        this.setState({ [E.target.name]: !this.state[E.target.name] })
    }

    onClickDelete(Id) {
        this.props.Delete_Lokasi(Id)
    }
    render() {
        const LokasiActionId = this.props.LokasiActionId
        const isLokasiActionGetDetailLoading = this.props.isLokasiActionGetDetailLoading
        const LokasiDetail = this.props.LokasiDetail
        const {
            isConfirm
        } = this.state
        const isLokasiDeleteLoading = this.props.isLokasiDeleteLoading
        return (
            <Fragment>
                <Detail Data={LokasiDetail} isLoading={isLokasiActionGetDetailLoading} />
                <div
                    style={{ margin: '1%' }}
                >
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox color='primary' onChange={this.OnChangeCheckbox} name="isConfirm" value={isConfirm} disabled={isLokasiDeleteLoading ? true : false} />}
                            label="Saya menyadari akan resikonya dan saya tau apa yang saya lakukan"
                        />
                    </FormGroup>
                    <center>
                        <Button
                            variant='contained'
                            size='large'
                            color='error'
                            disabled={!isConfirm || isLokasiDeleteLoading ? true : false}
                            onClick={() => this.onClickDelete(LokasiActionId)}
                        >
                            {isLokasiDeleteLoading ? <CircularProgress /> : 'Delete'}
                        </Button>
                    </center>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isLokasiActionGetDetailLoading: state.Setting.isLokasiActionGetDetailLoading,
    isLokasiDeleteLoading: state.Setting.isLokasiDeleteLoading,
    LokasiActionId: state.Setting.LokasiActionId,
    LokasiDetail: state.Setting.LokasiDetail,
})

export default connect(mapStateToProps, { get_Lokasi_Data, Delete_Lokasi })(SettingLokasiDelete)
