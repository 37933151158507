import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_List_TimeLine, get_Job_Action_Id } from '../../../../Store/Actions/Job.Action'

import { ListItemButton, Grid, Divider } from '@mui/material'

import WorkIcon from '@mui/icons-material/Work'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt'
import LocationOnIcon from '@mui/icons-material/LocationOn'

import GenericModals from '../../../Containers/GenericModals'
import CoTable from '../../../Containers/Table'
import MoTimeline from './Modal/Timeline'
class TimeLineCom extends React.Component {
  // state = {
  //   MenungguOpen: true,
  //   SelesaiOpen: false,
  // }

  componentDidMount() {
    this.props.get_List_TimeLine()
  }

  // handleMenungguOpen = () => {
  //   this.setState({ MenungguOpen: !this.state.MenungguOpen })
  // }

  // handleSelesaiOpen = () => {
  //   this.setState({ SelesaiOpen: !this.state.SelesaiOpen })
  // }

  onClickActionId = (JobId) => {
    this.props.get_Job_Action_Id(JobId)
  }

  render() {
    const isTimeLineLoading = this.props.isTimeLineLoading
    const TimeLine = this.props.TimeLine

    const User = this.props.User
    const isAdmin = User ? User.isAdmin : false
    const Department = User ? User.Department : []
    const AllowedDepartment = ['53661']
    const isAllowAll = Department.find(r => AllowedDepartment.some((newItem) => r.DepartemenId === newItem))

    const DetailStyle = { border: '0px solid red', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '30%' }
    const DetailLogoStyle = { marginRight: '2%' }

    const HeadData = [
      {
        Nama: '',
        width: '100%',
      },
    ]

    const FilteredList = TimeLine ?
    isAdmin || isAllowAll ? TimeLine : TimeLine.filter((item, index) => Department.find((newItem) => item.DepartemenId === newItem.DepartemenId) ? true : false)
    : []
    
    const BodyData = FilteredList.map((item, index) => {
      return {
        Detail:
        {
          value: item,
          display: (
            <Fragment
              key={index}
            >
              <ListItemButton>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <div
                    style={{ border: '0px solid red', width: '70%', padding: '0 0 0 1%' }}
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      style={{ border: '0px solid blue', padding: '0 0 3% 0' }}
                    >
                      <b>
                        No: {`${item.JobId}.${item.Id}`}
                        <span
                          style={{ margin: '0 0 0 30px', color: item.Status === 'Active' ? 'green' : item.Status === 'On Hold' ? 'orange' : 'black' }}
                        >
                          Status: {item.Status}
                        </span>
                      </b>
                      <h2
                        style={{ border: '0px solid red' }}
                      >
                        {item.JobListNama}
                      </h2>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        style={{ border: '0px solid red' }}
                      >
                        <div
                          style={DetailStyle}
                        >
                          <WorkIcon
                            style={DetailLogoStyle}
                          />
                          {item.DepartemenNama}
                        </div>
                        <div
                          style={DetailStyle}
                        >
                          <SignalCellularAltIcon
                            style={DetailLogoStyle}
                          />
                          {item.LevelNama}
                        </div>
                        <div
                          style={DetailStyle}
                        >
                          <LocationOnIcon
                            style={DetailLogoStyle}
                          />
                          {item.LokasiNama}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    style={{ border: '0px solid red', width: '10%' }}
                  >

                  </div>
                  <div
                    style={{ border: '0px solid red', width: '10%' }}
                  >
                    <center>
                      <GenericModals
                        size='xl'
                        header='Timeline'
                        body={<MoTimeline />}
                        Buttoncolor='primary'
                        ButtononClickeven={() => this.onClickActionId(item.Id)}
                        Buttonlabel={'View Timeline'}
                      />
                    </center>
                  </div>
                </Grid>
              </ListItemButton>
              <Divider />
            </Fragment>
          )
        }
      }
    })

    const RowDataInOnePage = 5
    return (
      <Fragment>
        <h2>
          Timeline
        </h2>
        <div
          style={{ margin: '0 0 5% 0' }}
        >
          <CoTable
            TableId={'Timeline'}
            HeadData={HeadData}
            Data={BodyData}
            isHeadLoading={isTimeLineLoading}
            isBodyLoading={isTimeLineLoading}
            isPagination={BodyData.length > RowDataInOnePage ? true : false}
            MaxRowOnPage={RowDataInOnePage}
            isPaginationLoading={isTimeLineLoading}
            PaginationCounter={
              BodyData.length / RowDataInOnePage > Math.floor(BodyData.length / RowDataInOnePage) ?
                Math.floor(BodyData.length / RowDataInOnePage) + 1 :
                BodyData.length / RowDataInOnePage
            }
            isHeading={true}
            CustomFilter={[
              { Nama: 'Status', Keyword: '' },
              { Nama: 'JobListNama', Keyword: '' },
              { Nama: 'DepartemenNama', Keyword: '' },
              { Nama: 'LevelNama', Keyword: '' },
              { Nama: 'LokasiNama', Keyword: '' },
            ]}
          />
        </div>
      </Fragment >
    )
  }
}

const mapStateToProps = state => ({
  User: state.Auth.User,
  isTimeLineLoading: state.Job.isTimeLineLoading,
  TimeLine: state.Job.TimeLine,
})

export default connect(mapStateToProps, { get_List_TimeLine, get_Job_Action_Id })(TimeLineCom)