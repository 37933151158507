import React, { Fragment } from "react"

import { connect } from 'react-redux'
import { Get_Job_List } from '../../../../Store/Actions/Job.Action'
import { Load_Department_List, Load_Lokasi_List } from '../../../../Store/Actions/Setting.Action'

import { Skeleton, Container, Grid, Paper, Button, FormControl, Select, TextField, InputAdornment, Collapse } from '@mui/material'

import { Redirect } from 'react-router-dom'

// import MyVacancyImage from '../../../../Img/JobList/MyVacancy.png'
import MyVacancyImage from '../../../../Img/JobList/MyVacancy_e.png'

import SearchIcon from '@mui/icons-material/Search'

class JobList extends React.Component {
    state = {
        UrlId: '',
        SearchKeyword: '',
        SearchLocation: '',
        SearchDep: '',
        isGoToJobDetail: false,
        JobListData: [],
        isShowSearch: false,
    }

    componentDidMount() {
        this.props.Get_Job_List()
        this.props.Load_Department_List()
        this.props.Load_Lokasi_List()
        // const JobListData = this.props.JobListData
        // if (JobListData) {
        //     this.setState({ JobListData: JobListData })
        // }
    }

    componentDidUpdate(prevProps) {
        if (this.props.JobListData !== prevProps.JobListData) {
            const { JobListData } = this.props
            this.setState({ JobListData: JobListData })
        }
    }

    ShowSearch = (e) => {
        this.setState({ isShowSearch: !this.state.isShowSearch })
        if (this.state.isShowSearch === true) {
            this.Reset_Filter()
        }
    }

    FilterData = (TextSearch, LocationSearch, DepartmentSearch) => {
        const { JobListData } = this.props

        const TextJobListData = TextSearch ? JobListData.filter((item) => String(item.Nama).toLocaleLowerCase().search(String(TextSearch).toLocaleLowerCase()) >= 0 ? true : false) : JobListData
        const LocationListData = LocationSearch ? LocationSearch === 'All' ? JobListData : JobListData.filter((item) => String(item.LokasiNama).toLocaleLowerCase().search(String(LocationSearch).toLocaleLowerCase()) >= 0 ? true : false) : JobListData
        const DepartmentListData = DepartmentSearch ? DepartmentSearch === 'All' ? JobListData : JobListData.filter((item) => String(item.DepartemenNama).toLocaleLowerCase() === String(DepartmentSearch).toLocaleLowerCase() ? true : false) : JobListData

        const newJobListData = [
            TextJobListData,
            LocationListData,
            DepartmentListData
        ].reduce((p, c) => p.filter(e => c.includes(e)))

        this.setState({ JobListData: newJobListData })
    }

    onChange_Search = (e) => {
        this.setState({ SearchKeyword: e.target.value })
        this.FilterData(e.target.value, this.state.SearchLocation, this.state.SearchDep)
    }
    onChange_Search_Select = (e) => {
        this.setState({ SearchLocation: e.target.value })
        this.FilterData(this.state.SearchKeyword, e.target.value, this.state.SearchDep)
    }
    onChange_Search_Select_Dep = (e) => {
        this.setState({ SearchDep: e.target.value })
        this.FilterData(this.state.SearchKeyword, this.state.SearchLocation, e.target.value)
    }

    // onChange_Search_Department = (DepNama) => {
    //     const { SearchDep } = this.state
    //     const IndexItem = SearchDep.findIndex((v) => v === DepNama)
    //     if (IndexItem >= 0) {
    //         SearchDep.splice(IndexItem, 1)
    //         this.FilterData(this.state.SearchKeyword, this.state.SearchLocation, SearchDep)
    //     } else {
    //         SearchDep.push(DepNama)
    //         this.FilterData(this.state.SearchKeyword, this.state.SearchLocation, SearchDep)
    //     }
    //     // this.setState({ SearchLocation: e.target.value })
    //     // this.FilterData(this.state.SearchKeyword, e.target.value, this.state.SearchDep)
    // }

    Reset_Filter = (e) => {
        this.setState({
            SearchKeyword: '',
            SearchLocation: '',
            SearchDep: ''
        })
        this.FilterData('', '', '')
    }

    goToJobDetail = (Id) => {
        this.setState({ UrlId: Id })
        this.setState({ isGoToJobDetail: true })
    }

    render() {
        // const JobListData = this.props.JobListData
        const isJobListLoading = this.props.isJobListLoading
        const DepartmentList = this.props.DepartmentList
        const isDepartmentLoading = this.props.isDepartmentLoading
        const isLokasiLoading = this.props.isLokasiLoading
        const LokasiList = this.props.LokasiList
        const UsedLokasiList = LokasiList ? LokasiList.filter((item, index) => (
            item.Id !== "7039"
        )) : []

        const {
            UrlId,
            SearchKeyword,
            SearchLocation,
            SearchDep,
            isGoToJobDetail,
            JobListData,
            isShowSearch
        } = this.state

        const BodyData = JobListData ? JobListData : []
        // const BodyData = [{Nama:'Tes 1 2 3 4 5 6 7 8 9 0', DepartemenNama:' Buisness Development', LokasiNama:' Jakarta'}, {}, {}, {}]

        return (
            <Fragment>
                {isGoToJobDetail ? <Redirect to={`/Jobdetail/${UrlId}`} /> : <div></div>}
                {isJobListLoading || isDepartmentLoading || isLokasiLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5, 6].map((item) =>
                            <Skeleton
                                key={`Edit_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <Container
                            style={{ border: '0px solid red', padding: '0 15vw 0 15vw' }}
                        >

                            <div
                                style={{ color: '#03045e', fontFamily: 'Montserrat, Segoe UI' }}
                            >
                                <h1>My Vacancy</h1>
                                <span
                                    style={{ color: 'black', fontFamily: 'Montserrat, Segoe UI' }}
                                >
                                    Start finding your purpose with Etana. <br />
                                    See our latest vacancies below
                                </span>
                            </div>

                            <div
                                style={{ border: '0 solid blue', padding: '1vh 0 0 0', margin: '0 0 0 0' }}
                            >

                                <img
                                    style={{ border: '0px solid red', width: '100%', height: '100%' }}
                                    alt="MyVacancyImage"
                                    src={MyVacancyImage}
                                />
                            </div>

                        </Container>


                        <div
                            style={{ margin: '-1vh 0 0 0', border: '0px solid green', padding: '1vh 1vw 1vh 1vw', backgroundImage: 'linear-gradient(to Left, #90e0ef, #00a3df', }}
                        >
                            {isShowSearch ?
                                <div
                                    style={{ color: 'white', fontFamily: 'Montserrat, Segoe UI', cursor: 'pointer' }}
                                    onClick={this.ShowSearch}
                                >
                                    <center>
                                        <h3>Click Here to Show All Vacancy</h3>
                                    </center>
                                </div> :
                                <div
                                    style={{ color: 'white', fontFamily: 'Montserrat, Segoe UI', cursor: 'pointer' }}
                                    onClick={this.ShowSearch}
                                >
                                    <center>
                                        <h1>Click Here to Filter Vacancy</h1>
                                    </center>
                                </div>
                            }
                            <div
                                style={{ border: '0px solid red', margin: '5vh 5vw 5vh 5vw' }}
                            >
                                <Collapse in={isShowSearch} timeout="auto" unmountOnExit>
                                    <center>

                                        <FormControl
                                            style={{ width: '90%', border: '0px solid white', margin: "1vh 0 1vh 0" }}
                                        >

                                            <Select
                                                variant="standard"
                                                native
                                                disableUnderline
                                                style={{ border: '3px solid white', borderRadius: '10px', height: '8vh', color: 'white', padding: "5%" }}
                                                onChange={(e) => this.onChange_Search_Select(e)}
                                                name='SearchLocation'
                                                value={SearchLocation}
                                            >
                                                <option value="All" style={{ color: 'black' }}> All Location </option>
                                                {UsedLokasiList.map((option, index) =>
                                                    <option key={`LokasiList_${index}`} style={{ color: 'black' }} value={option.Nama}>{option.Nama}</option>
                                                )}
                                            </Select>
                                        </FormControl>

                                        <TextField
                                            placeholder="Type Your Keyword"
                                            // variant="outlined"
                                            variant="standard"
                                            size="small"
                                            style={{ border: '0px solid red', borderRadius: '10px', color: 'white', height: '8vh', width: '90%', margin: '1vh 0 1vh 10', padding: '1vh 0 1vh 0' }}
                                            value={SearchKeyword}
                                            onChange={this.onChange_Search}
                                            InputProps={{
                                                startAdornment:
                                                    <InputAdornment position="start" style={{ color: 'white' }}>
                                                        <SearchIcon />
                                                    </InputAdornment>,
                                                disableUnderline: true,
                                                style: { color: 'white', border: '3px solid White', borderRadius: '10px', height: '100%', padding: '0 0 0 5%' }
                                            }}
                                        />

                                        <FormControl
                                            style={{ width: '90%', border: '0px solid white', margin: "1vh 0 1vh 0" }}
                                        >
                                            <Select
                                                variant="standard"
                                                native
                                                disableUnderline
                                                style={{ border: '3px solid white', borderRadius: '10px', height: '8vh', color: 'white', padding: "5%" }}
                                                onChange={(e) => this.onChange_Search_Select_Dep(e)}
                                                name='SearchDep'
                                                value={SearchDep}
                                            >
                                                <option value="" style={{ color: 'black' }}> All Department </option>
                                                {DepartmentList.map((option, index) =>
                                                    <option key={`DepartmentList_${index}`} style={{ color: 'black' }} value={option.Nama}>{option.Nama}</option>
                                                )}
                                            </Select>
                                        </FormControl>

                                    </center>
                                </Collapse>
                            </div>
                        </div>

                        <div
                            style={{ margin: '8vh 15vw 5vh 15vw', fontFamily: 'Montserrat, Segoe UI' }}
                        >
                            <h1
                                style={{ color: '#03045e' }}
                            >
                                {/* Department Name */}
                                Available Vacancy
                            </h1>
                            {/* Department Description */}
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-start"
                                style={{ border: '0px solid blue', margin: '5vh 0 20vh -7vw', width: '85vw' }}
                            >
                                {BodyData.map((item, index) =>
                                    <Fragment
                                        key={`Paper_Home_BodyData_${index}`}
                                    >
                                        <Paper
                                            style={{ border: '0px solid red', width: '90%', maxWidth: '400px', padding: '1vh 2vw 1vh 2vw', margin: '5vh 2vw 5vh 2vw', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px', borderRadius: '10px' }}

                                        >
                                            {/* <h2>{JSON.stringify(item)}</h2> */}
                                            <div
                                                style={{ display: 'flex', alignContent: 'flex-start', justifyContent: 'flex-start', border: '0px solid red', fontFamily: 'Montserrat, Segoe UI' }}
                                            >
                                                <h3>{item.Nama}</h3>
                                            </div>
                                            <div
                                                style={{ height: '50%', display: 'flex', alignContent: 'flex-start', justifyContent: 'flex-start', border: '0px solid red', fontFamily: 'Montserrat, Segoe UI' }}
                                            >
                                                <div
                                                    style={{ width: '60%', marginRight: '10%', marginLeft: '1vw', border: '0px solid blue', color: '#00b4d9' }}
                                                >
                                                    <h5>{item.DepartemenNama}</h5>
                                                </div>
                                                <div
                                                    style={{ color: '#00b4d9' }}
                                                >
                                                    <h5>{item.LokasiNama}</h5>
                                                </div>
                                            </div>
                                            <center>
                                                <Button
                                                    size="small"
                                                    style={{ border: '0px solid red', width: '90%', borderRadius: '10px', textTransform: 'none', color: 'white', backgroundImage: 'linear-gradient(to Left, #90e0ef, #00a3df', fontFamily: 'Montserrat, Segoe UI' }}
                                                    onClick={() => this.goToJobDetail(item.JobId)}
                                                >
                                                    <b>
                                                        See Details
                                                    </b>
                                                </Button>
                                            </center>
                                        </Paper>
                                    </Fragment>
                                )}
                            </Grid>
                        </div>
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isLokasiLoading: state.Setting.isLokasiLoading,
    LokasiList: state.Setting.LokasiList,
    DepartmentList: state.Setting.DepartmentList,
    isDepartmentLoading: state.Setting.isDepartmentLoading,
    JobListData: state.Job.JobList,
    isJobListLoading: state.Job.isJobListLoading,
})

export default connect(mapStateToProps, { Get_Job_List, Load_Department_List, Load_Lokasi_List })(JobList)