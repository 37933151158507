import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Move_List, get_Job_Action_Id } from '../../../../Store/Actions/Job.Action'
import { get_Account_Action_Id } from '../../../../Store/Actions/Account.Action'

import GenericModals from '../../../Containers/GenericModals'
import CoTable from '../../../Containers/Table'
import MoDetail from './Modal/Move/Detail'

class Move extends React.Component {
    componentDidMount() {
        this.props.get_Move_List()
    }

    onClickActionId = (JobId, AccountId) => {
        this.props.get_Job_Action_Id(JobId)
        this.props.get_Account_Action_Id(AccountId)
    }

    render() {
        const isMoveListLoading = this.props.isMoveListLoading
        const MoveList = this.props.MoveList

        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
                KeyName: 'index',
                SortingType: 'Number',
            },
            {
                Nama: 'No Registrasi',
                width: '10%',
                KeyName: 'Noreg',
                SortingType: 'Number',
            },
            {
                Nama: 'Nama',
                width: '10%',
                KeyName: 'Nama',
            },
            {
                Nama: 'Departemen',
                width: '10%',
                KeyName: 'Departemen',
            },
            {
                Nama: 'Posisi',
                width: '10%',
                KeyName: 'Posisi',
            },
            {
                Nama: 'Detail',
                width: '10%',
            },
        ]

        // const FilteredList = MoveList ? MoveList.filter((item, index) => (
        //     item.PBId === null
        // )) : []
        const FilteredList = MoveList

        const BodyData = FilteredList.map((item, index) => {
            return {
                index: {
                    value: index + 1
                },
                Noreg: {
                    value: item.Id
                },
                Nama: {
                    value: item.BiodataNama
                },
                Departemen: {
                    value: item.DepartemenNama
                },
                Posisi: {
                    value: item.JobListNama
                },
                Detail: (
                    <GenericModals
                        size='m'
                        Buttonvariant='contained'
                        header='Detail'
                        body={<MoDetail />}
                        Buttoncolor='primary'
                        ButtononClickeven={() => this.onClickActionId(item.JobListId, item.AccountId)}
                        Buttonlabel={'Detail'}
                    />
                )
            }
        })

        return (
            <Fragment>
                <h2>
                    Move Applicant
                </h2>
                <CoTable
                    TableId={'MoveApplicant'}
                    HeadData={HeadData}
                    Data={BodyData}
                    isHeadLoading={isMoveListLoading}
                    isBodyLoading={isMoveListLoading}
                    isPagination={BodyData.length > 5 ? true : false}
                    isPaginationLoading={isMoveListLoading}
                    isHeading={true}
                    isDownload={true}
                    ComplexDataDownload={true}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isMoveListLoading: state.Job.isMoveListLoading,
    MoveList: state.Job.MoveList,
})

export default connect(mapStateToProps, { get_Move_List, get_Job_Action_Id, get_Account_Action_Id })(Move)