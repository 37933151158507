import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Division_Data, get_Division_Participan_Data, Add_To_DivisionList, Delete_From_DivisionList } from '../../../../../../Store/Actions/Setting.Action'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

import Detail from '../../../../../Containers/Dashboard/Setting/Division/Detail'
import AddParticipan from '../../../../../Containers/Dashboard/Setting/Division/AddParticipan'
import Data from '../../../../../Containers/Dashboard/Setting/Division/Data'

class SettingDivisionView extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.DivisionActionId !== prevProps.DivisionActionId) {
            this.props.get_Division_Data(this.props.DivisionActionId)
        }
        // if (this.props.DivisionActionCounter !== prevProps.DivisionActionCounter) {
        //     this.props.get_Division_Data(this.props.DivisionActionId)
        // }
    }
    componentDidMount() {
        const { DivisionActionId } = this.props
        if (DivisionActionId !== null) {
            this.props.get_Division_Data(DivisionActionId)
        }
        this.props.get_Division_Participan_Data()
    }

    DeleteFromDivisionList(DivisionListId) {
        this.props.Delete_From_DivisionList(DivisionListId)
    }
    render() {
        const isDivisionActionAddListLoading = this.props.isDivisionActionAddListLoading
        const DivisionActionId = this.props.DivisionActionId
        const DivisionParticipant = this.props.DivisionParticipant
        const isDivisionParticipantLoading = this.props.isDivisionParticipantLoading
        const isDivisionActionGetDetailLoading = this.props.isDivisionActionGetDetailLoading
        const DivisionDetail = this.props.DivisionDetail
        const DivisionData = this.props.DivisionData
        const HeadData = [
            {
                Nama: 'Index',
                width: '5%',
            },
            {
                Nama: 'Id',
                width: '10%',
            },
            {
                Nama: 'Departement',
                width: '10%',
            },
            {
                Nama: 'Remove',
                width: '15%',
            }
        ]
        const BodyData = DivisionData ? DivisionData.map((item, index) => {
            return {
                index: index + 1,
                Id: item.Id,
                Division: item.DepartemenNama,
                Remove: (
                    <DeleteOutlineIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.DeleteFromDivisionList(item.Id)}
                    />
                )
            }
        }) : []
        return (
            <Fragment>
                <Detail Data={DivisionDetail} isLoading={isDivisionActionGetDetailLoading} />
                <AddParticipan ParticipanData={DivisionParticipant} ParticipanDataLoading={isDivisionParticipantLoading} DataId={DivisionActionId} isLoading={isDivisionActionAddListLoading} sendFunction={this.props.Add_To_DivisionList} />
                <Data HeadData={HeadData} BodyData={BodyData} isLoading={isDivisionActionGetDetailLoading} />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isDivisionActionAddListLoading: state.Setting.isDivisionActionAddListLoading,
    isDivisionActionGetDetailLoading: state.Setting.isDivisionActionGetDetailLoading,
    DivisionActionId: state.Setting.DivisionActionId,
    DivisionParticipant: state.Setting.DivisionParticipant,
    isDivisionParticipantLoading: state.Setting.isDivisionParticipantLoading,
    DivisionDetail: state.Setting.DivisionDetail,
    DivisionData: state.Setting.DivisionData,
})

export default connect(mapStateToProps, { get_Division_Data, get_Division_Participan_Data, Add_To_DivisionList, Delete_From_DivisionList })(SettingDivisionView)
