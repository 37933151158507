import React, { Fragment } from "react";

import { connect } from "react-redux";

import { Skeleton, Avatar, Grid, useMediaQuery } from "@mui/material";
import { InitState } from "../../../Style";
import { ImageURL } from "../../../Global";

import CoTable from "../Table";
// import About1 from '../../../Img/Home/About/About001.jpg'

import { isDev, VariableLimit } from "../../../Global";

const Biodata = ({
  isLoading,
  Data,
  RiwayatKerja,
  DisableEdit,
  Edit,
  ...props
}) => {
  const User = props?.User ? props.User : null;
  const isAdmin = User?.isAdmin ? User.isAdmin : false;
  const isPendaftar = User?.isPendaftar ? User.isPendaftar : false;
  const Department = User?.Department ? User.Department : false;
  const AllowedDepartment = ["53661"];
  const isDepartmentAllow =
    AllowedDepartment.length === 0
      ? true
      : Department.some((r) => AllowedDepartment.includes(r.DepartemenId));
  const isAllowToSee = isAdmin || isPendaftar || isDepartmentAllow;

  const isPCView = useMediaQuery(
    `(min-width:${InitState.Units.minWidth_first}px)`
  );
  const DefaultUrl = ImageURL.Base;
  const isInDev = isDev();
  if (isInDev) {
    // console.log(Data ? `${DefaultUrl}/${Data.Foto}` : '')
    console.log(Data);
  }

  const st_Row = {
    display: "flex",
    fontFamily: "Montserrat, Segoe UI",
    fontSize: isPCView ? "1.2vw" : "16px",
  };
  const st_HalfLeft = {
    borderTop: "1px solid black",
    borderRight: "0px solid black",
    borderBottom: "0px solid black",
    borderLeft: "1px solid black",
    width: "35%",
    padding: "10px",
  };
  const st_HalfRight = {
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "0px solid black",
    borderLeft: "1px solid black",
    width: "65%",
    padding: "10px",
  };
  const st_BottomBorder = { borderBottom: "1px solid black" };
  const st_BigBox = { minHeight: "10vh" };
  const st_Space = { margin: "5vh 0 0 0" };
  const st_Full = {
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "0px solid black",
    borderLeft: "1px solid black",
    width: "100%",
    padding: "10px",
  };
  // const st_Space_Table = { margin: '5vh 0 5vh 0' }
  // const st_TableBorderHead = { borderTop: '1px solid black', borderRight: '0px solid black', borderBottom: '1px solid black', borderLeft: '1px solid black', }
  // const st_TableBorder = { borderLeft: '1px solid black', }
  const st_TableBorderHead = {
    borderTop: "1px solid black",
    borderRight: "0px solid black",
    borderBottom: "1px solid black",
    borderLeft: "1px solid black",
    fontFamily: "Montserrat, Segoe UI",
    fontSize: isPCView ? "1.2vw" : "16px",
  };
  const st_TableBorder = {
    borderLeft: "1px solid black",
    fontFamily: "Montserrat, Segoe UI",
    fontSize: isPCView ? "1.2vw" : "16px",
  };
  const st_Link = { textDecoration: "none", color: "#00a3df" };

  const HeadData = [
    {
      Nama: "No",
      width: "5%",
      style: { ...st_TableBorderHead },
      BodyStyle: { ...st_TableBorder },
    },
    {
      Nama: "Company Name",
      width: "15%",
      style: { ...st_TableBorderHead },
      BodyStyle: { ...st_TableBorder },
    },
    {
      Nama: "Position",
      width: "10%",
      style: { ...st_TableBorderHead },
      BodyStyle: { ...st_TableBorder },
    },
    {
      Nama: "Years of service (Year)",
      width: "10%",
      style: { ...st_TableBorderHead },
      BodyStyle: { ...st_TableBorder },
    },
    {
      Nama: "Job Description",
      width: "60%",
      style: { ...st_TableBorderHead, borderRight: "1px solid black" },
      BodyStyle: { ...st_TableBorder, borderRight: "1px solid black" },
    },
  ];

  const BodyData = RiwayatKerja
    ? RiwayatKerja.map((item, index) => {
        return {
          index: index + 1,
          Name: item.Nama ? item.Nama : "",
          Position: item.Jabatan ? item.Jabatan : "",
          Year: item.Year ? item.Year : "",
          Deskripsi: item.Deskripsi ? item.Deskripsi : "",
        };
      })
    : [];
  return (
    <Fragment>
      {isLoading ? (
        <Fragment>
          {[1, 2, 3, 4, 5].map((item) => (
            <Skeleton key={`Biodata_Skeleton_${item}`} height={"5vh"} />
          ))}
        </Fragment>
      ) : (
        <Fragment>
          {!DisableEdit ? (
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="baseline"
            >
              {Edit}
            </Grid>
          ) : (
            <Fragment></Fragment>
          )}

          <center>
            <Avatar
              variant="rounded"
              src={Data.Foto ? `${DefaultUrl}/${Data.Foto}` : null}
              alt="Avatar"
              style={{
                width: isPCView ? "10vw" : "60vw",
                height: isPCView ? "10vw" : "30vh",
                margin: "5vh 0 5vh 0",
                display: "block",
                position: "relative",
              }}
            />
          </center>

          <div style={st_Row}>
            <div style={{ ...st_Full, ...st_Space }}>
              <b>Biodata</b>
            </div>
          </div>
          <div style={st_Row}>
            <div style={st_HalfLeft}>Full Name</div>
            <div style={st_HalfRight}>{Data?.Nama ? Data.Nama : ""}</div>
          </div>

          <div style={st_Row}>
            <div style={st_HalfLeft}>Place, Date of Birth :</div>
            <div style={st_HalfRight}>
              {`${Data?.TempatLahir ? Data.TempatLahir : ""}, ${
                Data?.TanggalLahir
                  ? new Date(Data.TanggalLahir).toLocaleDateString([
                      "ban",
                      "id",
                    ])
                  : ""
              }`}
            </div>
          </div>

          <div style={st_Row}>
            <div style={st_HalfLeft}>Gender :</div>
            <div style={st_HalfRight}>
              {Data
                ? Data.JenisKelamin === true
                  ? "Male"
                  : Data.JenisKelamin === false
                  ? "Female"
                  : ""
                : ""}
            </div>
          </div>

          <div style={st_Row}>
            <div style={st_HalfLeft}>Phone Number (With WHATSAPP):</div>
            <div style={st_HalfRight}>{Data?.Telepon ? Data.Telepon : ""}</div>
          </div>

          <div style={st_Row}>
            <div style={{ ...st_HalfLeft, ...st_BigBox }}>Address :</div>
            <div style={{ ...st_HalfRight, ...st_BigBox }}>
              {Data?.Alamat ? Data.Alamat : ""}
            </div>
          </div>

          <div style={st_Row}>
            <div style={st_HalfLeft}>Height (cm):</div>
            <div style={st_HalfRight}>{Data?.Tinggi ? Data.Tinggi : ""}</div>
          </div>

          <div style={st_Row}>
            <div style={st_HalfLeft}>Weight (kg):</div>
            <div style={st_HalfRight}>{Data?.Berat ? Data.Berat : ""}</div>
          </div>

          <div style={st_Row}>
            <div style={{ ...st_HalfLeft, ...st_BottomBorder }}>Hobby:</div>
            <div style={{ ...st_HalfRight, ...st_BottomBorder }}>
              {Data?.Hobi ? Data.Hobi : ""}
            </div>
          </div>

          <div style={st_Row}>
            <div style={{ ...st_Full, ...st_Space }}>
              <b>Education</b>
            </div>
          </div>

          <div style={st_Row}>
            <div style={{ ...st_HalfLeft }}>Education Degree :</div>
            <div style={{ ...st_HalfRight }}>
              {Data?.PendidikanJenjang ? Data.PendidikanJenjang : ""}
            </div>
          </div>

          <div style={st_Row}>
            <div style={{ ...st_HalfLeft }}>Name :</div>
            <div style={{ ...st_HalfRight }}>
              {Data?.PendidikanNama ? Data.PendidikanNama : ""}
            </div>
          </div>

          <div style={st_Row}>
            <div style={{ ...st_HalfLeft, ...st_BottomBorder }}>Major :</div>
            <div style={{ ...st_HalfRight, ...st_BottomBorder }}>
              {Data?.PendidikanJurusan ? Data.PendidikanJurusan : ""}
            </div>
          </div>

          <div style={st_Row}>
            <div style={{ ...st_Full, ...st_Space }}>
              <b>Employment History</b>
            </div>
          </div>
          <CoTable
            TableId={"Biodata_Employment_History"}
            HeadData={HeadData}
            Data={BodyData.length > 0 ? BodyData : [{}]}
            isHeadLoading={isLoading}
            isBodyLoading={isLoading}
            isHorizontalScroll={isPCView ? false : true}
          />

          <div style={st_Row}>
            <div
              style={{
                ...st_HalfLeft,
                ...st_BigBox,
                borderTop:
                  BodyData.length > 0 ? "1px solid black" : "0px solid black",
              }}
            >
              Reason For Leaving Your Last Company :
            </div>
            <div
              style={{
                ...st_HalfRight,
                ...st_BigBox,
                borderTop:
                  BodyData.length > 0 ? "1px solid black" : "0px solid black",
              }}
            >
              {Data?.Alasan ? Data.Alasan : ""}
            </div>
          </div>

          {isAllowToSee ? (
            <div style={st_Row}>
              <div style={{ ...st_HalfLeft }}>Expected Salary :</div>
              <div style={{ ...st_HalfRight }}>
                {Data?.Salary ? Data.Salary : ""}
              </div>
            </div>
          ) : (
            <Fragment></Fragment>
          )}
          <div style={st_Row}>
            <div style={{ ...st_Full }}>
              <b>Reference</b>
            </div>
          </div>

          {/* <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Company Name :
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.KerjaReferensiNama ? Data.KerjaReferensiNama : ''}
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft }}
                        >
                            Position :
                        </div>
                        <div
                            style={{ ...st_HalfRight }}
                        >
                            {Data?.KerjaReferensiJabatan ? Data.KerjaReferensiJabatan : ''}<br />
                        </div>
                    </div>

                    <div
                        style={st_Row}
                    >
                        <div
                            style={{ ...st_HalfLeft, ...st_BottomBorder }}
                        >
                            Contact Person :
                        </div>
                        <div
                            style={{ ...st_HalfRight, ...st_BottomBorder }}
                        >
                            {Data?.KerjaReferensiKontak ? Data.KerjaReferensiKontak : ''}
                        </div>
                    </div> */}

          <table
            style={{
              width: "100%",
              textAlign: "center",
              borderCollapse: "collapse",
              border: "2px solid black",
            }}
          >
            <thead style={{ backgroundColor: "lightgray" }}>
              <tr>
                <th style={{ border: "2px solid black", width: "30%" }}>
                  Source
                </th>
                <th style={{ border: "2px solid black" }}>Name</th>
                <th style={{ border: "2px solid black" }}>Phone Number</th>
                <th style={{ border: "2px solid black" }}>Remarks</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ border: "2px solid black", fontWeight: "bold" }}>
                  Collegue
                </td>
                <td style={{ border: "2px solid black" }}>
                  {Data?.KerjaReferensiNamaKolega
                    ? Data.KerjaReferensiNamaKolega
                    : ""}
                </td>
                <td style={{ border: "2px solid black" }}>
                  {Data?.KerjaReferensiKontakKolega
                    ? Data.KerjaReferensiKontakKolega
                    : ""}
                </td>
                <td style={{ border: "2px solid black" }}>
                  {Data?.KerjaReferensiKeteranganKolega
                    ? Data.KerjaReferensiKeteranganKolega
                    : ""}
                </td>
              </tr>
              <tr>
                <td style={{ border: "2px solid black", fontWeight: "bold" }}>
                  HR from previous company
                </td>
                <td style={{ border: "2px solid black" }}>
                  {Data?.KerjaReferensiNamaHr ? Data.KerjaReferensiNamaHr : ""}
                </td>
                <td style={{ border: "2px solid black" }}>
                  {Data?.KerjaReferensiKontakHr
                    ? Data.KerjaReferensiKontakHr
                    : ""}
                </td>
                <td style={{ border: "2px solid black" }}>
                  {Data?.KerjaReferensiKeteranganHr
                    ? Data.KerjaReferensiKeteranganHr
                    : ""}
                </td>
              </tr>
              <tr>
                <td style={{ border: "2px solid black", fontWeight: "bold" }}>
                  Direct Supervisor from previous company
                </td>
                <td style={{ border: "2px solid black" }}>
                  {Data?.KerjaReferensiNamaDirect
                    ? Data.KerjaReferensiNamaDirect
                    : ""}
                </td>
                <td style={{ border: "2px solid black" }}>
                  {/* <TextField
                                        style={{ ...st_textfield, width: '80%' }}
                                        variant='standard'
                                        type='number'
                                        name='KerjaReferensiKontakDirect'
                                        onChange={this.onChangeField}
                                        value={KerjaReferensiKontakDirect ? KerjaReferensiKontakDirect : ''}
                                        error={KerjaReferensiKontakDirect.length > CharLimit.Biodata.KerjaReferensiKontakDirect ? true : false}
                                        helperText={KerjaReferensiKontakDirect.length > CharLimit.Biodata.KerjaReferensiKontakDirect ? `Char Limit ${CharLimit.Biodata.KerjaReferensiKontakDirect}` : ''}
                                    /> */}
                  {Data?.KerjaReferensiKontakDirect
                    ? Data.KerjaReferensiKontakDirect
                    : ""}
                </td>
                <td style={{ border: "2px solid black" }}>
                  {/* <TextField
                                        style={{ ...st_textfield, width: '80%' }}
                                        variant='standard'
                                        type='text'
                                        name='KerjaReferensiKeteranganDirect'
                                        onChange={this.onChangeField}
                                        value={KerjaReferensiKeteranganDirect ? KerjaReferensiKeteranganDirect : ''}
                                        error={KerjaReferensiKeteranganDirect.length > CharLimit.Biodata.KerjaReferensiKeteranganDirect ? true : false}
                                        helperText={KerjaReferensiKeteranganDirect.length > CharLimit.Biodata.KerjaReferensiKeteranganDirect ? `Char Limit ${CharLimit.Biodata.KerjaReferensiKeteranganDirect}` : ''}
                                    /> */}
                  {Data?.KerjaReferensiKeteranganDirect
                    ? Data.KerjaReferensiKeteranganDirect
                    : ""}
                </td>
              </tr>
            </tbody>
          </table>

          <div style={st_Row}>
            <div style={{ ...st_Full, ...st_Space }}>
              <b>Additional Information</b>
            </div>
          </div>

          <div style={st_Row}>
            <div style={{ ...st_HalfLeft }}>English Skill :</div>
            <div style={{ ...st_HalfRight }}>
              {Data?.English ? Data.English : ""}
            </div>
          </div>

          <div style={st_Row}>
            <div style={{ ...st_HalfLeft }}>Other Language Skills :</div>
            <div style={{ ...st_HalfRight }}>
              {Data?.Bahasa ? Data.Bahasa : ""}
            </div>
          </div>

          <div style={st_Row}>
            <div style={{ ...st_HalfLeft }}>Marital Status :</div>
            <div style={{ ...st_HalfRight }}>
              {Data ? (Data.Nikah === true ? "Married" : "Single") : ""}
            </div>
          </div>

          {isAllowToSee ? (
            <div style={st_Row}>
              <div style={{ ...st_HalfLeft }}>
                Do You Have Any Family/Friend As Employee In Etana :
              </div>
              <div style={{ ...st_HalfRight }}>
                {Data ? (
                  Data.KelaurgaEtana === true ? (
                    <span>
                      {`Yes, His/Her Is ${
                        Data.KelaurgaEtanaNama ? Data.KelaurgaEtanaNama : ""
                      }`}
                    </span>
                  ) : (
                    <span>No</span>
                  )
                ) : (
                  <span>No</span>
                )}
              </div>
            </div>
          ) : (
            <Fragment></Fragment>
          )}

          <div style={st_Row}>
            <div style={{ ...st_HalfLeft }}>Are You Willing To Relocate :</div>
            <div style={{ ...st_HalfRight }}>
              {Data ? (
                Data.Relocate === false ? (
                  <span variant="subtitle1">
                    {`No, The Reason Is ${
                      Data.ReasonNotRelocate ? Data.ReasonNotRelocate : ""
                    }`}
                  </span>
                ) : (
                  <span variant="subtitle1">Yes</span>
                )
              ) : (
                <div></div>
              )}
            </div>
          </div>

          <div style={st_Row}>
            <div style={{ ...st_HalfLeft }}>Have You Been Vaccinated :</div>
            <div style={{ ...st_HalfRight }}>
              {Data ? (
                Data.Vaksin === false ? (
                  <span variant="subtitle1">
                    {`No, The Reason Is ${
                      Data.ReasonVaksin ? Data.ReasonVaksin : ""
                    }`}
                  </span>
                ) : (
                  <span variant="subtitle1">Yes</span>
                )
              ) : (
                <div></div>
              )}
            </div>
          </div>

          <div style={st_Row}>
            <div style={{ ...st_HalfLeft }}>
              How Did You Find Out About Work at Etana :
            </div>
            <div style={{ ...st_HalfRight }}>
              {Data?.FindOutEtana ? Data.FindOutEtana : ""}
            </div>
          </div>

          <div style={st_Row}>
            <div
              style={
                isAllowToSee
                  ? { ...st_HalfLeft }
                  : { ...st_HalfLeft, st_BottomBorder }
              }
            >
              Portofolio Link :
            </div>
            <div
              style={
                isAllowToSee
                  ? { ...st_HalfRight }
                  : { ...st_HalfRight, st_BottomBorder }
              }
            >
              {Data?.PortofolioLink ? Data.PortofolioLink : ""}
            </div>
          </div>

          {isAllowToSee ? (
            <Fragment>
              <div style={st_Row}>
                <div style={{ ...st_Full }}>
                  <b>Social Media</b>
                </div>
              </div>

              <div style={st_Row}>
                <div style={{ ...st_HalfLeft }}>Facebook :</div>
                <div style={{ ...st_HalfRight }}>
                  {Data?.SMFacebook ? (
                    <a
                      href={`${VariableLimit.SocialMediaInitLink.Facebook}${Data.SMFacebook}`}
                      target="_blank"
                      rel="noreferrer"
                      style={st_Link}
                    >
                      {Data.SMFacebook}
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div style={st_Row}>
                <div style={{ ...st_HalfLeft }}>Instagram :</div>
                <div style={{ ...st_HalfRight }}>
                  {Data?.SMIG ? (
                    <a
                      href={`${VariableLimit.SocialMediaInitLink.Instagram}${Data.SMIG}`}
                      target="_blank"
                      rel="noreferrer"
                      style={st_Link}
                    >
                      {Data.SMIG}
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div style={st_Row}>
                <div style={{ ...st_HalfLeft }}>Twitter :</div>
                <div style={{ ...st_HalfRight }}>
                  {Data?.SMTwitter ? (
                    <a
                      href={`${VariableLimit.SocialMediaInitLink.Twitter}${Data.SMTwitter}`}
                      target="_blank"
                      rel="noreferrer"
                      style={st_Link}
                    >
                      {Data.SMTwitter}
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div style={st_Row}>
                <div style={{ ...st_HalfLeft, ...st_BottomBorder }}>
                  Linkedin :
                </div>
                <div style={{ ...st_HalfRight, ...st_BottomBorder }}>
                  {Data?.SMLinkdin ? (
                    <a
                      href={`${VariableLimit.SocialMediaInitLink.Linkedin}${Data.SMLinkdin}`}
                      target="_blank"
                      rel="noreferrer"
                      style={st_Link}
                    >
                      {Data.SMLinkdin}
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Fragment>
          ) : (
            <Fragment></Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  User: state.Auth.User,
});

export default connect(mapStateToProps, {})(Biodata);
