import React, { Fragment } from "react"

import useMediaQuery from '@mui/material/useMediaQuery'
import { InitState } from '../../Style'

import FullFooter from './Footer/FullFooter'
import HalfFooter from './Footer/HalfFooter'

const Header = () => { // FIXME
    const MinWindowFirst = useMediaQuery(`(min-width:${InitState.Units.minWidth_first}px)`)
    return (
        <Fragment>
            {MinWindowFirst ?
                <Fragment>
                    <FullFooter />
                </Fragment>
                : <Fragment>
                    <HalfFooter />
                </Fragment>}
        </Fragment>
    )
}

export default Header