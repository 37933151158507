import React from "react";

import { Route, Switch } from "react-router-dom";

import PrivateRoute from "./Security/PrivateRoute";

//* Auth
import Login from "./Components/Layouts/Auth/Login";
import CreateAccount from "./Components/Layouts/Auth/CreateAccount";
import ForgetPassword from "./Components/Layouts/Auth/ForgetPassword";
import Logout from "./Components/Layouts/Auth/Logout";
import Workspace from "./Components/Layouts/Auth/Workspace";

//* Global
import Home from "./Components/Layouts/Home";
import JobList from "./Components/Layouts/Job/JobList";
import JobDetail from "./Components/Layouts/Job/JobDetail";

//* UnCategorized
// import ApplyJob from './Components/Layouts/Job/ApplyJob'
// import SaveJob from './Components/Layouts/Job/SaveJob'
import DaftarKerja from "./Components/Layouts/Job/DaftarKerja";
// import Daftar from './Components/Layouts/Daftar'

//* Profile
// import Profile from './Components/Layouts/Account/Profile1'
import Profile from "./Components/Layouts/Account/Profile";
import ProfileExtend from "./Components/Layouts/Account/ProfileExtend";

//* Applications
import Applications from "./Components/Layouts/Applications/Applications";

//* Dashboard
import Dashboard from "./Components/Layouts/Dashboard/Dashboard";
//* Dashboard-Notification
import Notification from "./Components/Layouts/Dashboard/Notification/Notification";
//* Dashboard-TimeLine
import TimeLine from "./Components/Layouts/Dashboard/TimeLine/TimeLine";
//* Dashboard-MPP
import MPPPengajuan from "./Components/Layouts/Dashboard/MPP/PengajuanMPP";
import MPPPACC from "./Components/Layouts/Dashboard/MPP/ACCMPP";
import MPPMPPList from "./Components/Layouts/Dashboard/MPP/ViewMPPList";
// import MPPMPPListItem from './Components/Layouts/Dashboard/MPP/ViewMPPItemList'
//* Dashboard-Jobdesk
import JobdeskPengajuan from "./Components/Layouts/Dashboard/Jobdesk/Pengajuan";
//* Dashboard-PR
import PRPengajuan from "./Components/Layouts/Dashboard/PR/Pengajuan";
import PRPenerimaan from "./Components/Layouts/Dashboard/PR/Penerimaan";
//* Dashboard-Job
import JOBJob from "./Components/Layouts/Dashboard/Job/Job";
import JOBPersonaltyTest from "./Components/Layouts/Dashboard/Job/PersonaltyTest";
import JOBOffering from "./Components/Layouts/Dashboard/Job/Offering";
import JOBMCU from "./Components/Layouts/Dashboard/Job/MCU";
import JOBMCUApproval from "./Components/Layouts/Dashboard/Job/MCUApproval";
import JOBPreBoarding from "./Components/Layouts/Dashboard/Job/PReboarding";
import JOBPKWT from "./Components/Layouts/Dashboard/Job/PKWT";
import JOBBPJS from "./Components/Layouts/Dashboard/Job/BPJS";
import JOBIndustry from "./Components/Layouts/Dashboard/Job/Industry";
import JOBEquipment from "./Components/Layouts/Dashboard/Job/Equipment";
import JOBGreetDay from "./Components/Layouts/Dashboard/Job/GreetDay";
import JOBKontrak from "./Components/Layouts/Dashboard/Job/Kontrak";
import JOBOnBoarding from "./Components/Layouts/Dashboard/Job/OnBoarding";
import JOBJadwalInterview from "./Components/Layouts/Dashboard/Job/JadwalInterview";
import JOBIntUser from "./Components/Layouts/Dashboard/Job/InterviewUser";
import JOBIntHRGA from "./Components/Layouts/Dashboard/Job/InterviewHRGA";
import JOBMove from "./Components/Layouts/Dashboard/Job/Move";
//* Dashboard-Report
import ReportMPP from "./Components/Layouts/Dashboard/Report/MPP";
import ReportJobDesc from "./Components/Layouts/Dashboard/Report/JobDesc";
import ReportPR from "./Components/Layouts/Dashboard/Report/PR";
import ReportHR from "./Components/Layouts/Dashboard/Report/HR";
import ReportDepartment from "./Components/Layouts/Dashboard/Report/Department";
//* Dashboard-Setting
import SettingUser from "./Components/Layouts/Dashboard/Setting/User";
import SettingRole from "./Components/Layouts/Dashboard/Setting/Role";
import SettingDivision from "./Components/Layouts/Dashboard/Setting/Division";
import SettingDepartment from "./Components/Layouts/Dashboard/Setting/Department";
import SettingLevel from "./Components/Layouts/Dashboard/Setting/Level";
import SettingMPP from "./Components/Layouts/Dashboard/Setting/MPP";
import SettingItems from "./Components/Layouts/Dashboard/Setting/Items";
import SettingLokasi from "./Components/Layouts/Dashboard/Setting/Lokasi";
import SettingPertanyaanInterview from "./Components/Layouts/Dashboard/Setting/PertanyaanInterview";
// import PertanyaanBiodataJawaban from './Components/Layouts/Dashboard/Setting/PertanyaanBiodataJawaban'
// import PertanyaanBiodataPenjelasan from './Components/Layouts/Dashboard/Setting/PertanyaanBiodataPenjelasan'

//* Error
import Error404 from "./Components/Layouts/Error/Error404";

const BaseRouter = ({ isMobileView }) => {
  return (
    <Switch>
      {/* Auth */}
      {/* <Route exact path="/login" component={Login} /> */}
      <Route
        exact
        path="/login"
        render={(props) => <Login isMobileView={isMobileView} {...props} />}
      />
      <Route
        exact
        path="/createaccount"
        render={(props) => (
          <CreateAccount isMobileView={isMobileView} {...props} />
        )}
      />
      <Route
        exact
        path="/forgetpassword"
        render={(props) => (
          <ForgetPassword isMobileView={isMobileView} {...props} />
        )}
      />
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/hrworkspace/:token" component={Workspace} />

      {/* Global */}
      <Route exact path="/" component={Home} />
      <Route exact path="/Joblist" component={JobList} />
      {/* <Route exact path="/JobDetail" component={JobDetail} /> */}
      <Route exact path="/JobDetail/:Id" component={JobDetail} />
      {/* <Route exact path="/JobDetail/:Id/daftar" component={DaftarKerja} /> */}

      {/* Uncatagorize */}
      {/* <PrivateRoute exact path="/applyjob" component={ApplyJob} /> */}
      {/* <PrivateRoute exact path="/savejob" component={SaveJob} /> */}
      <PrivateRoute exact path="/daftarkerja" component={DaftarKerja} />
      {/* <PrivateRoute exact path="/Daftar" component={Daftar} /> */}

      {/* Profile */}
      <PrivateRoute exact path="/profile" component={Profile} />
      {/* <Route exact path="/profile" render={(props) => <Profile isMobileView={isMobileView} {...props} />} /> */}
      <PrivateRoute exact path="/profileextend" component={ProfileExtend} />

      {/* Applications */}
      <PrivateRoute exact path="/applications" component={Applications} />

      {/* Dashboard */}
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      {/* Dashboard-Notification */}
      <PrivateRoute
        exact
        path="/dashboard/notification"
        component={Dashboard}
        Child={Notification}
      />
      {/* Dashboard-TimeLine */}
      <PrivateRoute
        exact
        path="/dashboard/timeline"
        component={Dashboard}
        Child={TimeLine}
      />
      {/* Dashboard-MPP */}
      {/* <PrivateRoute exact path="/dashboard/mpp/pengajuan" component={Dashboard} Child={MPPPengajuan} SecurityType={'Privacry'} RoleList={['77741']} /> */}
      <PrivateRoute
        exact
        path="/dashboard/mpp/pengajuan"
        component={Dashboard}
        Child={MPPPengajuan}
      />
      <PrivateRoute
        exact
        path="/dashboard/mpp/penerimaan"
        component={Dashboard}
        Child={MPPPACC}
      />
      <PrivateRoute
        exact
        path="/dashboard/mpp"
        component={Dashboard}
        Child={MPPMPPList}
      />
      {/* <PrivateRoute exact path="/dashboard/mpp/item" component={Dashboard} Child={MPPMPPListItem} /> */}
      {/* Dashboard-Jobdesk */}
      <PrivateRoute
        exact
        path="/dashboard/jobdesk/pengajuan"
        component={Dashboard}
        Child={JobdeskPengajuan}
      />
      {/* Dashboard-PR */}
      <PrivateRoute
        exact
        path="/dashboard/pr/pengajuan"
        component={Dashboard}
        Child={PRPengajuan}
      />
      <PrivateRoute
        exact
        path="/dashboard/pr/penerimaan"
        component={Dashboard}
        Child={PRPenerimaan}
      />
      {/* Dashboard-Job */}
      <PrivateRoute
        exact
        path="/dashboard/job/job"
        component={Dashboard}
        Child={JOBJob}
      />
      <PrivateRoute
        exact
        path="/dashboard/job/personaltytest"
        component={Dashboard}
        Child={JOBPersonaltyTest}
      />
      <PrivateRoute
        exact
        path="/dashboard/job/offering"
        component={Dashboard}
        Child={JOBOffering}
      />
      <PrivateRoute
        exact
        path="/dashboard/job/mcu"
        component={Dashboard}
        Child={JOBMCU}
      />
      <PrivateRoute
        exact
        path="/dashboard/job/mcu/approval"
        component={Dashboard}
        Child={JOBMCUApproval}
      />
      <PrivateRoute
        exact
        path="/dashboard/job/preboarding"
        component={Dashboard}
        Child={JOBPreBoarding}
      />
      <PrivateRoute
        exact
        path="/dashboard/job/pkwt"
        component={Dashboard}
        Child={JOBPKWT}
      />
      <PrivateRoute
        exact
        path="/dashboard/job/bpjs"
        component={Dashboard}
        Child={JOBBPJS}
      />
      <PrivateRoute
        exact
        path="/dashboard/job/Industry"
        component={Dashboard}
        Child={JOBIndustry}
      />
      <PrivateRoute
        exact
        path="/dashboard/job/equipment"
        component={Dashboard}
        Child={JOBEquipment}
      />
      <PrivateRoute
        exact
        path="/dashboard/job/greetday"
        component={Dashboard}
        Child={JOBGreetDay}
      />
      <PrivateRoute
        exact
        path="/dashboard/job/kontrak"
        component={Dashboard}
        Child={JOBKontrak}
      />
      <PrivateRoute
        exact
        path="/dashboard/job/onboarding"
        component={Dashboard}
        Child={JOBOnBoarding}
      />
      <PrivateRoute
        exact
        path="/dashboard/job/jadwalinterview"
        component={Dashboard}
        Child={JOBJadwalInterview}
      />
      <PrivateRoute
        exact
        path="/dashboard/job/intuser"
        component={Dashboard}
        Child={JOBIntUser}
      />
      <PrivateRoute
        exact
        path="/dashboard/job/inthrga"
        component={Dashboard}
        Child={JOBIntHRGA}
      />
      <PrivateRoute
        exact
        path="/dashboard/job/move"
        component={Dashboard}
        Child={JOBMove}
      />
      {/* Dashboard-Report */}
      <PrivateRoute
        exact
        path="/dashboard/report/mpp"
        component={Dashboard}
        Child={ReportMPP}
      />
      <PrivateRoute
        exact
        path="/dashboard/report/jobdesc"
        component={Dashboard}
        Child={ReportJobDesc}
      />
      <PrivateRoute
        exact
        path="/dashboard/report/pr"
        component={Dashboard}
        Child={ReportPR}
      />
      <PrivateRoute
        exact
        path="/dashboard/report/hr"
        component={Dashboard}
        Child={ReportHR}
      />
      <PrivateRoute
        exact
        path="/dashboard/report/department"
        component={Dashboard}
        Child={ReportDepartment}
      />
      {/* Dashboard-Setting */}
      <PrivateRoute
        exact
        path="/dashboard/setting/user"
        component={Dashboard}
        Child={SettingUser}
      />
      <PrivateRoute
        exact
        path="/dashboard/setting/role"
        component={Dashboard}
        Child={SettingRole}
      />
      <PrivateRoute
        exact
        path="/dashboard/setting/division"
        component={Dashboard}
        Child={SettingDivision}
      />
      <PrivateRoute
        exact
        path="/dashboard/setting/department"
        component={Dashboard}
        Child={SettingDepartment}
      />
      <PrivateRoute
        exact
        path="/dashboard/setting/level"
        component={Dashboard}
        Child={SettingLevel}
      />
      <PrivateRoute
        exact
        path="/dashboard/setting/mpp"
        component={Dashboard}
        Child={SettingMPP}
      />
      <PrivateRoute
        exact
        path="/dashboard/setting/items"
        component={Dashboard}
        Child={SettingItems}
      />
      <PrivateRoute
        exact
        path="/dashboard/setting/lokasi"
        component={Dashboard}
        Child={SettingLokasi}
      />
      <PrivateRoute
        exact
        path="/dashboard/setting/pertanyaan_interview"
        component={Dashboard}
        Child={SettingPertanyaanInterview}
      />
      {/* <PrivateRoute exact path="/dashboard/setting/pertanyaan_biodata_jawaban" component={Dashboard} Child={PertanyaanBiodataJawaban} /> */}
      {/* <PrivateRoute exact path="/dashboard/setting/pertanyaan_biodata_penjelasan" component={Dashboard} Child={PertanyaanBiodataPenjelasan} /> */}

      {/* Error */}
      <Route path="*" component={Error404} />
    </Switch>
  );
};

export default BaseRouter;
