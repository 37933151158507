import React from 'react'

import { connect } from 'react-redux'
import { LogIn } from '../../../Store/Actions/Auth.Action'

import { Redirect } from 'react-router-dom'

import { Grid, Paper, TextField, Button, CircularProgress, InputAdornment } from '@mui/material'

import Logo from '../../../Img/Etana_logo.png'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

class Login extends React.Component {
    state = {
        Email: '',
        Password: '',
        isShowPassword: false,
        isGoToCreateUser: false,
        isGoToForgetPassword: false
    }
    onShowPassword = () => {
        this.setState({ isShowPassword: !this.state.isShowPassword })
    }
    goToCreateUser = () => {
        this.setState({ isGoToCreateUser: !this.state.isGoToCreateUser })
    }
    goToForgetPassword = () => {
        this.setState({ isGoToForgetPassword: !this.state.isGoToForgetPassword })
    }
    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    formOnSubmit = (e) => {
        e.preventDefault()
        this.props.LogIn(
            this.state.Email,
            this.state.Password,
            false,
            this?.props?.location?.state?.PrefLocation ? this.props.location.state.PrefLocation : null
        )
    }
    render() {
        if (this.props.isAuth && this.props.Token) {
            return <Redirect to="/" />
        }

        const isMobileView = this.props.isMobileView

        const {
            Email,
            Password,
            isShowPassword,
            isGoToCreateUser,
            isGoToForgetPassword
        } = this.state

        const isLoginLoading = this.props.isLoginLoading
        // const PrefLocation = this?.props?.location?.state?.PrefLocation ? this.props.location.state.PrefLocation : null

        return (
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                {isGoToCreateUser ? <Redirect to="/createaccount" /> : <div></div>}
                {isGoToForgetPassword ? <Redirect to="/forgetpassword" /> : <div></div>}
                <Paper
                    style={{ width: isMobileView ? '70%' : '30%', margin: '2% 0 2% 0', border: '0px solid red', padding: '2vh 3vw 2vh 3vw', fontFamily: 'Montserrat, Segoe UI', fontSize: isMobileView ? '16px' : '1.2vw' }}
                >
                    <h1>
                        Log In
                    </h1>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <img
                            src={Logo}
                            style={{ height: '30%', width: '30%', padding: '1% 1% 1% 1%', margin: '2% 0 5% 0', border: '0px solid red' }}
                            alt="Logo"
                        />
                    </Grid>
                    <form
                        onSubmit={this.formOnSubmit}
                    >
                        <div
                            style={{ margin: '0 5% 0 5%' }}
                        >
                            <label>
                                <b>Email or Username</b>
                            </label><br />
                            <TextField
                                type='text'
                                name='Email'
                                size='small'
                                value={Email}
                                onChange={this.onChangeField}
                                placeholder='example@mail.com'
                                style={{ width: '100%', margin: '1% 0 1% 0' }}
                                InputProps={{
                                    style: { fontFamily: 'Montserrat, Segoe UI', fontSize: isMobileView ? '16px' : '1.2vw' }
                                }}
                            /><br /><br />
                            <label>
                                <b>Password</b>
                            </label><br />
                            <TextField
                                type={isShowPassword ? 'text' : 'password'}
                                name='Password'
                                size='small'
                                value={Password}
                                placeholder='********'
                                onChange={this.onChangeField}
                                style={{ width: '100%', margin: '1% 0 1% 0' }}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment
                                            position="end"
                                            style={{ color: '#00a3df', cursor: 'pointer', fontSize: isMobileView ? '16px' : '2vw' }}
                                            onClick={() => this.onShowPassword()}
                                        >
                                            {isShowPassword ?
                                                <VisibilityIcon />
                                                : <VisibilityOffIcon />
                                            }
                                        </InputAdornment>,
                                    style: { fontFamily: 'Montserrat, Segoe UI', fontSize: isMobileView ? '16px' : '1.2vw' }
                                }}
                            /><br />
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-start"
                            >
                                <label
                                    style={{ color: '#00a3df', cursor: 'pointer', marginTop: '2vh' }}
                                    onClick={this.goToForgetPassword}
                                >
                                    Forgot password?
                                </label><br />
                            </Grid>
                        </div>
                        <center>

                            <Button
                                variant='contained'
                                size='large'
                                style={{ border: '0px solid red', width: "85%", margin: '3vh 0 2vh 0', backgroundColor: '#00a3df', textTransform: 'none', fontFamily: 'Montserrat, Segoe UI', fontSize: isMobileView ? '16px' : '1.2vw' }}
                                type='submit'
                                disabled={isLoginLoading ? true : false}
                            >
                                {isLoginLoading ? <CircularProgress /> : 'Sign In'}
                            </Button>
                        </center>
                    </form>
                    <div
                        style={{ margin: '0 5% 0 5%', alignItems: 'center', textAlign: 'center', color: '#7e7e7e' }}
                    >
                        <p>
                            Don't have an account? <span
                                style={{ color: '#00a3df', cursor: 'pointer' }}
                                onClick={this.goToCreateUser}
                            >
                                Create Account
                            </span>
                            <br />
                            <span
                                style={{ fontSize: '75%' }}
                            >
                                Version 2.0.8
                            </span>
                        </p>
                    </div>
                </Paper>
                <Paper
                    style={{ width: '40%', margin: '2% 0 2% 0', border: '0px solid red' }}
                >

                </Paper>
            </Grid >
        )
    }
}

const mapStateToProps = state => ({
    isAuth: state.Auth.isAuth,
    isLoginLoading: state.Auth.isLoginLoading,
    Token: state.Auth.Token,
})

export default connect(mapStateToProps, { LogIn })(Login)