import React, { Fragment } from 'react'

import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent, TimelineDot } from '@mui/lab'

import { Typography } from '@mui/material'

import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import GroupRoundedIcon from '@mui/icons-material/GroupRounded'
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded'
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded'
import InterpreterModeRoundedIcon from '@mui/icons-material/InterpreterModeRounded'
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded'
import VaccinesRoundedIcon from '@mui/icons-material/VaccinesRounded'
import AppRegistrationRoundedIcon from '@mui/icons-material/AppRegistrationRounded'
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded'
import AddAlertRoundedIcon from '@mui/icons-material/AddAlertRounded'

const CoTimeline = ({ TimeLineDetail, ...pros }) => {
    return (
        <Timeline position="alternate">


            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                >
                    {TimeLineDetail?.Total ? `${TimeLineDetail.Total} User` : ''}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot
                        // color={isInPendaftar ? "success" : "primary"}
                        // variant={isInPendaftar ? "filled" : "outlined"}
                        color="primary"
                        variant="outlined"
                    >
                        <AccountCircleRoundedIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        Total Pendaftar
                    </Typography>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                >
                    {TimeLineDetail?.ListReferee ? TimeLineDetail.ListReferee.map((item, index) =>
                        <Fragment
                            key={`ListReferee_${index}`}
                        >
                            <Typography>
                                {index + 1} - {item?.BiodataNama ? item.BiodataNama : ''} - {item?.AccReferralTime ? new Date(item.AccReferralTime).toLocaleString(['ban', 'id']) : ''}
                            </Typography>
                        </Fragment>
                    ) : <Fragment></Fragment>}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot
                        // color={LastLocation ? LastLocation === "ListReferee" ? "secondary" : "primary" : "primary"}
                        // variant={LastLocation ? LastLocation === "ListReferee" ? "secondary" : "outlined" : "outlined"}
                        color="primary"
                        variant="outlined"
                    >
                        <GroupRoundedIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        Rekomendasi HR
                    </Typography>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                >

                    {TimeLineDetail?.ListConfirm ? TimeLineDetail.ListConfirm.map((item, index) =>
                        <Fragment
                            key={`ListConfirm_${index}`}
                        >
                            <Typography>
                                {index + 1} - {item?.BiodataNama ? item.BiodataNama : ''} - {item?.AccDepTime ? new Date(item.AccDepTime).toLocaleString(['ban', 'id']) : ''} - <span
                                    style={{
                                        color: item?.AccDepStatus ?
                                            item.AccDepStatus === 'Accept' ? 'green' : 'red'
                                            : 'black'
                                    }}
                                >
                                    {item?.AccDepStatus ? item.AccDepStatus : ''}
                                </span>
                            </Typography>
                        </Fragment>
                    ) : <Fragment></Fragment>}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot
                        // color={LastLocation ? LastLocation === "ListConfirm" ? "secondary" : "primary" : "primary"}
                        // variant={LastLocation ? LastLocation === "ListConfirm" ? "secondary" : "outlined" : "outlined"}
                        color="primary"
                        variant="outlined"
                    >
                        <GroupAddRoundedIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        Rekomendasi Departemen
                    </Typography>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                >
                    {TimeLineDetail?.PersonalityTest ? TimeLineDetail.PersonalityTest.map((item, index) =>
                        <Fragment
                            key={`PersonalityTest_${index}`}
                        >
                            <Typography>
                                {index + 1} - {item?.BiodataNama ? item.BiodataNama : ''} - {item?.PTTime ? new Date(item.PTTime).toLocaleString(['ban', 'id']) : ''}
                            </Typography>
                        </Fragment>
                    ) : <Fragment></Fragment>}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot
                        // color={LastLocation ? LastLocation === "PersonalityTest" ? "secondary" : "primary" : "primary"}
                        // variant={LastLocation ? LastLocation === "PersonalityTest" ? "secondary" : "outlined" : "outlined"}
                        color="primary"
                        variant="outlined"
                    >
                        <PersonSearchRoundedIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        Personality Test
                    </Typography>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                >
                    {TimeLineDetail?.HRInterview ? TimeLineDetail.HRInterview.map((item, index) =>
                        <Fragment
                            key={`HRInterview_${index}`}
                        >
                            <Typography>
                                {index + 1} - {item?.BiodataNama ? item.BiodataNama : ''} - {item?.HrITime ? new Date(item.HrITime).toLocaleString(['ban', 'id']) : ''} - <span
                                    style={{
                                        color: item?.HrIStatus ?
                                            item.HrIStatus === 'Accept' ? 'green' : 'red'
                                            : 'black'
                                    }}
                                >
                                    {item?.HrIStatus ? item.HrIStatus : ''}
                                </span>
                            </Typography>
                        </Fragment>
                    ) : <Fragment></Fragment>}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot
                        // color={LastLocation ? LastLocation === "HRInterview" ? "secondary" : "primary" : "primary"}
                        // variant={LastLocation ? LastLocation === "HRInterview" ? "secondary" : "outlined" : "outlined"}
                        color="primary"
                        variant="outlined"
                    >
                        <InterpreterModeRoundedIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        HR Interview
                    </Typography>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                >
                    {TimeLineDetail?.DepartemenInterview ? TimeLineDetail.DepartemenInterview.map((item, index) =>
                        <Fragment
                            key={`DepartemenInterview_${index}`}
                        >
                            <Typography>
                                {index + 1} - {item?.BiodataNama ? item.BiodataNama : ''} - {item?.UsITime ? new Date(item.UsITime).toLocaleString(['ban', 'id']) : ''} - <span
                                    style={{
                                        color: item?.UsIStatus ?
                                            item.UsIStatus === 'Accept' ? 'green' : 'red'
                                            : 'black'
                                    }}
                                >
                                    {item?.UsIStatus ? item.UsIStatus : ''}
                                </span>
                            </Typography>
                        </Fragment>
                    ) : <Fragment></Fragment>}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot
                        // color={LastLocation ? LastLocation === "DepartemenInterview" ? "secondary" : "primary" : "primary"}
                        // variant={LastLocation ? LastLocation === "DepartemenInterview" ? "secondary" : "outlined" : "outlined"}
                        color="primary"
                        variant="outlined"
                    >
                        <InterpreterModeRoundedIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        Departemen Interview
                    </Typography>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                >
                    {TimeLineDetail?.DepartemenFinal ? TimeLineDetail.DepartemenFinal.map((item, index) =>
                        <Fragment
                            key={`DepartemenFinal_${index}`}
                        >
                            <Typography>
                                {index + 1} - {item?.BiodataNama ? item.BiodataNama : ''} - {item?.UsIFTime ? new Date(item.UsIFTime).toLocaleString(['ban', 'id']) : ''} - <span
                                    style={{
                                        color: item?.UsIFStatus ?
                                            item.UsIFStatus === 'Accept' ? 'green' : 'red'
                                            : 'black'
                                    }}
                                >
                                    {item?.UsIFStatus ? item.UsIFStatus : ''}
                                </span>
                            </Typography>
                        </Fragment>
                    ) : <Fragment></Fragment>}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot
                        // color={LastLocation ? LastLocation === "DepartemenFinal" ? "secondary" : "primary" : "primary"}
                        // variant={LastLocation ? LastLocation === "DepartemenFinal" ? "secondary" : "outlined" : "outlined"}
                        color="primary"
                        variant="outlined"
                    >
                        <PersonAddAltRoundedIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        Departemen Final
                    </Typography>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                >
                    {TimeLineDetail?.Offering ? TimeLineDetail.Offering.map((item, index) =>
                        <Fragment
                            key={`Offering_${index}`}
                        >
                            <Typography>
                                {index + 1} - {item?.BiodataNama ? item.BiodataNama : ''} - {item?.OfTime ? new Date(item.OfTime).toLocaleString(['ban', 'id']) : ''} - <span
                                    style={{
                                        color: item?.OfStatus ?
                                            item.OfStatus === 'Accept' ? 'green' : 'red'
                                            : 'black'
                                    }}
                                >
                                    {item?.OfStatus ? item.OfStatus : ''}
                                </span>
                            </Typography>
                        </Fragment>
                    ) : <Fragment></Fragment>}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot
                        // color={LastLocation ? LastLocation === "Offering" ? "secondary" : "primary" : "primary"}
                        // variant={LastLocation ? LastLocation === "Offering" ? "secondary" : "outlined" : "outlined"}
                        color="primary"
                        variant="outlined"
                    >
                        <AttachMoneyRoundedIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        Offering
                    </Typography>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                >
                    {TimeLineDetail?.MCU ? TimeLineDetail.MCU.map((item, index) =>
                        <Fragment
                            key={`MCU_${index}`}
                        >
                            <Typography>
                                {index + 1} - {item?.BiodataNama ? item.BiodataNama : ''} - {item?.MCUDate ? new Date(item.MCUDate).toLocaleString(['ban', 'id']) : ''}  | {item?.MCUResult ? item.MCUResult : ''} - {item?.MCUTime ? new Date(item.MCUTime).toLocaleString(['ban', 'id']) : ''}-<span
                                    style={{
                                        color: item?.AccDepStatus ?
                                            item.AccDepStatus === 'Accept' ? 'green' : 'red'
                                            : 'black'
                                    }}
                                >
                                    {item?.AccDepStatus ? item.AccDepStatus : ''}
                                </span>
                            </Typography>
                        </Fragment>
                    ) : <Fragment></Fragment>}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot
                        // color={LastLocation ? LastLocation === "MCU" ? "secondary" : "primary" : "primary"}
                        // variant={LastLocation ? LastLocation === "MCU" ? "secondary" : "outlined" : "outlined"}
                        color="primary"
                        variant="outlined"
                    >
                        <VaccinesRoundedIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        MCU
                    </Typography>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                >
                    {TimeLineDetail?.Preboarding ? TimeLineDetail.Preboarding.map((item, index) =>
                        <Fragment
                            key={`Preboarding_${index}`}
                        >
                            <Typography>
                                {index + 1} - {item?.BiodataNama ? item.BiodataNama : ''} - {item?.PBDate ? new Date(item.PBDate).toLocaleString(['ban', 'id']) : ''}
                            </Typography>
                        </Fragment>
                    ) : <Fragment></Fragment>}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot
                        // color={LastLocation ? LastLocation === "Preboarding" ? "secondary" : "primary" : "primary"}
                        // variant={LastLocation ? LastLocation === "Preboarding" ? "secondary" : "outlined" : "outlined"}
                        color="primary"
                        variant="outlined"
                    >
                        <AppRegistrationRoundedIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        Preboarding
                    </Typography>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                >
                    {TimeLineDetail?.PKWT ? TimeLineDetail.PKWT.map((item, index) =>
                        <Fragment
                            key={`PKWT_${index}`}
                        >
                            <Typography>
                                {index + 1} - {item?.BiodataNama ? item.BiodataNama : ''} - {item?.PKDate ? new Date(item.PKDate).toLocaleString(['ban', 'id']) : ''}
                            </Typography>
                        </Fragment>
                    ) : <Fragment></Fragment>}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot
                        // color={LastLocation ? LastLocation === "PKWT" ? "secondary" : "primary" : "primary"}
                        // variant={LastLocation ? LastLocation === "PKWT" ? "secondary" : "outlined" : "outlined"}
                        color="primary"
                        variant="outlined"
                    >
                        <AssignmentRoundedIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        PKWT/PKWTT
                    </Typography>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                >
                    {TimeLineDetail?.BPJS ? TimeLineDetail.BPJS.map((item, index) =>
                        <Fragment
                            key={`BPJS_${index}`}
                        >
                            <Typography>
                                {index + 1} - {item?.BiodataNama ? item.BiodataNama : ''} - {item?.BPDate ? new Date(item.BPDate).toLocaleString(['ban', 'id']) : ''}
                            </Typography>
                        </Fragment>
                    ) : <Fragment></Fragment>}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot
                        // color={LastLocation ? LastLocation === "BPJS" ? "secondary" : "primary" : "primary"}
                        // variant={LastLocation ? LastLocation === "BPJS" ? "secondary" : "outlined" : "outlined"}
                        color="primary"
                        variant="outlined"
                    >
                        <AssignmentRoundedIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        BPJS
                    </Typography>
                </TimelineContent>
            </TimelineItem>

            {/* <TimelineItem>
      <TimelineOppositeContent
        sx={{ m: 'auto 0' }}
        align="right"
        variant="body2"
        color="text.secondary"
      >
        {TimeLineDetail?.Total ? `${TimeLineDetail.Total} User` : ''}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot color="primary" variant="outlined">
          <AssignmentRoundedIcon />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Typography variant="h6" component="span">
          Equipment
        </Typography>
      </TimelineContent>
    </TimelineItem> */}

            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                >
                    {TimeLineDetail?.GreetDay ? TimeLineDetail.GreetDay.map((item, index) =>
                        <Fragment
                            key={`GreetDay_${index}`}
                        >
                            <Typography>
                                {index + 1} - {item?.BiodataNama ? item.BiodataNama : ''} - {item?.GDDate ? new Date(item.GDDate).toLocaleString(['ban', 'id']) : ''}
                            </Typography>
                        </Fragment>
                    ) : <Fragment></Fragment>}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot
                        // color={LastLocation ? LastLocation === "GreetDay" ? "secondary" : "primary" : "primary"}
                        // variant={LastLocation ? LastLocation === "GreetDay" ? "secondary" : "outlined" : "outlined"}
                        color="primary"
                        variant="outlined"
                    >
                        <AssignmentRoundedIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        Great Day
                    </Typography>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                >
                    {TimeLineDetail?.Contrak ? TimeLineDetail.Contrak.map((item, index) =>
                        <Fragment
                            key={`Contrak_${index}`}
                        >
                            <Typography>
                                {index + 1} - {item?.BiodataNama ? item.BiodataNama : ''} - {item?.KoDate ? new Date(item.KoDate).toLocaleString(['ban', 'id']) : ''} - <span
                                    style={{
                                        color: item?.KoStatus ?
                                            item.KoStatus === 'Accept' ? 'green' : 'red'
                                            : 'black'
                                    }}
                                >
                                    {item?.KoStatus ? item.KoStatus : ''}
                                </span>
                            </Typography>
                        </Fragment>
                    ) : <Fragment></Fragment>}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot
                        // color={LastLocation ? LastLocation === "Contrak" ? "secondary" : "primary" : "primary"}
                        // variant={LastLocation ? LastLocation === "Contrak" ? "secondary" : "outlined" : "outlined"}
                        color="primary"
                        variant="outlined"
                    >
                        <AssignmentRoundedIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        Contract
                    </Typography>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                >
                    {TimeLineDetail?.OnBoarding ? TimeLineDetail.OnBoarding.map((item, index) =>
                        <Fragment
                            key={`OnBoarding_${index}`}
                        >
                            <Typography>
                                {index + 1} - {item?.BiodataNama ? item.BiodataNama : ''} - {item?.OBDate ? new Date(item.OBDate).toLocaleString(['ban', 'id']) : ''}
                            </Typography>
                        </Fragment>
                    ) : <Fragment></Fragment>}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot
                        // color={LastLocation ? LastLocation === "OnBoarding" ? "secondary" : "primary" : "primary"}
                        // variant={LastLocation ? LastLocation === "OnBoarding" ? "secondary" : "outlined" : "outlined"}
                        color="primary"
                        variant="outlined"
                    >
                        <AddAlertRoundedIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        OnBoarding
                    </Typography>
                </TimelineContent>
            </TimelineItem>

        </Timeline>
    )
}

export default CoTimeline
