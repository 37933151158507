import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Load_Items_List, get_Items_Action_Id } from '../../../../Store/Actions/Setting.Action'

import CoTable from '../../../Containers/Table'
import GenericModals from '../../../Containers/GenericModals'

import Add from './Modal/Items/Add'
import View from './Modal/Items/View'
import Delete from './Modal/Items/Delete'

class SettingItems extends React.Component {
    componentDidMount() {
        this.props.Load_Items_List()
    }
    onClickActionId(Id) {
        this.props.get_Items_Action_Id(Id)
    }
    render() {
        const ItemsList = this.props.ItemsList
        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Id',
                width: '15%',
            },
            {
                Nama: 'Nama',
                width: '30%',
            },
            {
                Nama: 'Deskripsi',
                width: '30%',
            },
            {
                Nama: 'View',
                width: '10%',
            },
            {
                Nama: 'Delete',
                width: '10%',
            },
        ]
        const BodyData = ItemsList ? ItemsList.map((item, index) => {
            return {
                index: index + 1,
                Id: item.Id,
                Name: item.Nama,
                Deskripsi: item.Deskripsi,
                View: (
                    <GenericModals
                        size='xl'
                        header='View Items'
                        body={<View />}
                        Buttoncolor='primary'
                        ButtononClickeven={() => this.onClickActionId(item.Id)}
                        Buttonlabel={'view'}
                    />
                ),
                Delete: (
                    <GenericModals
                        size='m'
                        header='Delete Form'
                        body={<Delete />}
                        Buttoncolor='error'
                        ButtononClickeven={() => this.onClickActionId(item.Id)}
                        Buttonlabel={'Delete'}
                    />
                )
            }
        }) : []
        const isItemsLoading = this.props.isItemsLoading
        return (
            <Fragment>
                <h2>
                    Items Setting
                </h2>

                <center>
                    <GenericModals
                        size='m'
                        header='Tambah Items'
                        body={<Add />}
                        Buttoncolor='primary'
                        Buttonstyle={{ width: '90%' }}
                        ButtononClickeven={() => null}
                        Buttonlabel={'+ Items'}
                    />
                </center>

                <CoTable
                    TableId='ItemsSetting'
                    HeadData={HeadData}
                    isHeadLoading={HeadData ? false : true}
                    Data={BodyData}
                    isBodyLoading={isItemsLoading}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isItemsLoading: state.Setting.isItemsLoading,
    ItemsList: state.Setting.ItemsList,
})

export default connect(mapStateToProps, { Load_Items_List, get_Items_Action_Id })(SettingItems)
