import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Role_Data, Delete_Role } from '../../../../../../Store/Actions/Setting.Action'

import { FormGroup, FormControlLabel, Checkbox, Button, CircularProgress } from '@mui/material'

import Detail from '../../../../../Containers/Dashboard/Setting/Role/Detail'
import Data from '../../../../../Containers/Dashboard/Setting/Role/Data'

class SettingRoleDelete extends React.Component {
    state = {
        isConfirm: false,
    }
    componentDidUpdate(prevProps) {
        if (this.props.RoleActionId !== prevProps.RoleActionId) {
            this.props.get_Role_Data(this.props.RoleActionId)
        }
    }
    componentDidMount() {
        const { RoleActionId } = this.props
        if (RoleActionId !== null) {
            this.props.get_Role_Data(RoleActionId)
        }
    }

    OnChangeCheckbox = E => {
        this.setState({ [E.target.name]: !this.state[E.target.name] })
    }

    onClickDelete(Id) {
        this.props.Delete_Role(Id)
    }
    render() {
        const RoleActionId = this.props.RoleActionId
        const isRoleActionGetDetailLoading = this.props.isRoleActionGetDetailLoading
        const RoleDetail = this.props.RoleDetail
        const RoleData = this.props.RoleData
        const HeadData = [
            {
                Nama: 'Index',
                width: '5%',
            },
            {
                Nama: 'Id',
                width: '10%',
            },
            {
                Nama: 'UserName',
                width: '10%',
            },
            {
                Nama: 'Nama',
                width: '10%',
            },
            {
                Nama: 'Department',
                width: '10%',
            }
        ]
        const BodyData = RoleData ? RoleData.map((item, index) => {
            return {
                index: index + 1,
                Id: item.Id,
                UserName: item.UserName,
                Nama: item.BiodataNama,
                Department: item.DepartemenNama,
            }
        }) : []
        const {
            isConfirm
        } = this.state
        const isRoleDeleteLoading = this.props.isRoleDeleteLoading
        return (
            <Fragment>
                <Detail Data={RoleDetail} isLoading={isRoleActionGetDetailLoading} />
                <Data HeadData={HeadData} BodyData={BodyData} isLoading={isRoleActionGetDetailLoading} />
                <div
                    style={{ margin: '1%' }}
                >
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox color='primary' onChange={this.OnChangeCheckbox} name="isConfirm" value={isConfirm} disabled={isRoleDeleteLoading ? true : false} />}
                            label="Saya menyadari akan resikonya dan saya tau apa yang saya lakukan"
                        />
                    </FormGroup>
                    <center>
                        <Button
                            variant='contained'
                            size='large'
                            color='error'
                            disabled={!isConfirm || isRoleDeleteLoading ? true : false}
                            onClick={() => this.onClickDelete(RoleActionId)}
                        >
                            {isRoleDeleteLoading ? <CircularProgress /> : 'Delete'}
                        </Button>
                    </center>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isRoleActionGetDetailLoading: state.Setting.isRoleActionGetDetailLoading,
    isRoleDeleteLoading: state.Setting.isRoleDeleteLoading,
    RoleActionId: state.Setting.RoleActionId,
    RoleDetail: state.Setting.RoleDetail,
    RoleData: state.Setting.RoleData,
})

export default connect(mapStateToProps, { get_Role_Data, Delete_Role })(SettingRoleDelete)
