import React, { Fragment } from "react"

import { connect } from 'react-redux'

import { get_List_Applications_Save } from '../../../../../Store/Actions/Job.Action'

import { Redirect } from 'react-router-dom'

import { Skeleton, Container, List, ListItemButton, Divider, Grid, Button } from "@mui/material"

import WorkIcon from '@mui/icons-material/Work'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt'
import LocationOnIcon from '@mui/icons-material/LocationOn'

class JobSave extends React.Component {
    state = {
        UrlId: '',
        isGoToJobDetail: false,
    }

    componentDidMount() {
        this.props.get_List_Applications_Save()
    }

    goToSaveJob = (e, JobId) => {
        this.setState({ UrlId: JobId })
        this.setState({ isGoToJobDetail: true })
    }
    render() {
        const isListApplicationsSaveLoading = this.props.isListApplicationsSaveLoading
        const ListApplicationsSave = this.props.ListApplicationsSave

        const {
            UrlId,
            isGoToJobDetail,
        } = this.state

        const DetailStyle = { border: '0px solid red', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '30%' }
        const DetailLogoStyle = { marginRight: '2%' }

        return (
            <Container>
                {isGoToJobDetail ? <Redirect to={`/Jobdetail/${UrlId}`} /> : <div></div>}
                {isListApplicationsSaveLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5,].map((item) =>
                            <Skeleton
                                key={`isListApplicationsSaveLoading_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <center>
                            <h1>
                                Save Job
                            </h1>
                        </center>
                        <List
                            style={{ margin: '1% 0 5% 0' }}
                        >
                            {ListApplicationsSave && ListApplicationsSave.length > 0 ? ListApplicationsSave.map((item, index) =>
                                <div
                                    key={`JobSave${index}`}
                                >
                                    <ListItemButton
                                    // onClick={goToSaveJob}
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                        >
                                            {/* <h1>{JSON.stringify(item)}</h1> */}
                                            <h2>{item.JobNama}</h2>

                                            <div
                                                style={DetailStyle}
                                            >
                                                <WorkIcon
                                                    style={DetailLogoStyle}
                                                />
                                                {item.DepartemenNama}
                                            </div><br />
                                            <div
                                                style={DetailStyle}
                                            >
                                                <SignalCellularAltIcon
                                                    style={DetailLogoStyle}
                                                />
                                                {item.LevelNama}
                                            </div><br />
                                            <div
                                                style={DetailStyle}
                                            >
                                                <LocationOnIcon
                                                    style={DetailLogoStyle}
                                                />
                                                {item.LokasiNama}
                                            </div><br />
                                        </Grid>
                                        <div
                                            style={{ margin: '2% 0 1% 0', width: '95%' }}
                                        >
                                            <center>
                                                <Button
                                                    // variant="outlined"
                                                    variant="contained"
                                                    style={{ margin: '2% 0 1% 0', width: '80%', borderRadius: '18px' }}
                                                    onClick={(e) => this.goToSaveJob(e, item.JobId)}
                                                >
                                                    See Detail
                                                </Button>
                                            </center>
                                        </div>
                                    </ListItemButton>
                                    <Divider />

                                </div>
                            ) : <Fragment>
                                <center>
                                    <h1
                                        style={{ color: '#03045e', fontFamily: 'Montserrat, Segoe UI' }}
                                    >
                                        No vacancy saved
                                    </h1>
                                </center>
                            </Fragment>}
                        </List>
                    </Fragment>}
            </Container >
        )
    }
}

const mapStateToProps = state => ({
    // AccountId: state.Auth.Id,
    isListApplicationsSaveLoading: state.Job.isListApplicationsSaveLoading,
    ListApplicationsSave: state.Job.ListApplicationsSave,
})

export default connect(mapStateToProps, { get_List_Applications_Save })(JobSave)