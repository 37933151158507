import React, { Fragment, useEffect } from "react";

import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Button,
} from "@mui/material";

import { Create_Warning_Messages } from "../../../../../Store/Actions/Messages.Actions";

import { VariableLimit } from "../../../../../Global";

import { Get_IpAddres } from "../../../../../Store/Actions/Base.Action";
import axios from "axios";

const MPPOutputRealization = ({
    isLoading,
    Data,
    DoneData,
    DepartmentData,
    LevelData,
    DivisionData,
}) => {
    const dispatch = useDispatch();

    const [ActiveYear, setActiveYear] = React.useState(
        Number(new Date().getFullYear())
    );
    const [StartMonth, setStartMonth] = React.useState(0);
    const [EndMonth, setEndMonth] = React.useState(11);

    const [allOutput, setAllOutput] = React.useState("");
    const [specificOutput, setSpecificOutput] = React.useState("");

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("ERecEtana_token");
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": token,
                },
            };
            try {
                const response = await axios.post(
                    Get_IpAddres() + "/report/mpp/newoutput",
                    { year: ActiveYear, start: StartMonth, end: EndMonth },
                    config
                );
                const data = response.data.Data;

                const result = [];

                data.forEach((item) => {
                    const existingItem = result.find(
                        (resultItem) => resultItem.Jenis === item.Jenis
                    ) || {
                        Jenis: item.Jenis,
                        Kontrak: 0,
                        Permanen: 0,
                        JumlahSelesaiKontrak: 0,
                        JumlahSelesaiPermanen: 0,
                        ListNamaKontrak: null,
                        ListNamaPermanen: null,
                    };

                    existingItem.Kontrak +=
                        item.StatusKaryawan === "Contract" ? item.Jumlah : 0;
                    existingItem.Permanen +=
                        item.StatusKaryawan === "Permanent" ? item.Jumlah : 0;
                    existingItem.JumlahSelesaiKontrak +=
                        item.StatusKaryawan === "Contract" ? item.JumlahSelesai : 0;
                    existingItem.JumlahSelesaiPermanen +=
                        item.StatusKaryawan === "Permanent" ? item.JumlahSelesai : 0;

                    if (item.StatusKaryawan === "Contract" && item.ListNama) {
                        existingItem.ListNamaKontrak = existingItem.ListNamaKontrak
                            ? `${existingItem.ListNamaKontrak}, ${item.ListNama}`
                            : item.ListNama;
                    }

                    if (item.StatusKaryawan === "Permanent" && item.ListNama) {
                        existingItem.ListNamaPermanen = existingItem.ListNamaPermanen
                            ? `${existingItem.ListNamaPermanen}, ${item.ListNama}`
                            : item.ListNama;
                    }

                    if (!result.some((resultItem) => resultItem.Jenis === item.Jenis)) {
                        result.push(existingItem);
                    }
                });
                setAllOutput(result);
                const combinedData = DivisionData.map((division) => {
                    const { Nama, IdDepartemen } = division;
                    const departmentIds = IdDepartemen.split(",").map((id) => id.trim());

                    const divisionObj = {
                        NamaDivisi: Nama,
                        Departemen: [],
                    };
                    const jenis = ["Yearly", "Additional", "Replacement"];
                    DepartmentData.forEach((department) => {
                        if (departmentIds.includes(department.Id)) {
                            divisionObj.Departemen.push({
                                NamaDepartemen: department.Nama,
                                IdDepartemen: department.Id,
                                Jenis: jenis.map((jenis) => {
                                    return {
                                        Jenis: jenis,
                                        Level: LevelData.map((level) => {
                                            return {
                                                NamaLevel: level.Nama,
                                                JumlahPermanen: 0,
                                                JumlahPermanenSelesai: 0,
                                                ListNamaPermanen: "",
                                                JumlahKontrak: 0,
                                                JumlahKontrakSelesai: 0,
                                                ListNamaKontrak: "",
                                            };
                                        }),
                                    };
                                }),
                            });
                        }
                    });
                    return divisionObj;
                });
                const combinedDataWithAPI = combinedData.map((divisionData) => {
                    const jenisArray = ["Yearly", "Additional", "Replacement"];
                    const updatedDepartments = divisionData.Departemen.map((dept) => {
                        const apiData = data.filter(
                            (apiItem) =>
                                dept.NamaDepartemen === apiItem.Departemen &&
                                dept.Jenis.some(
                                    (deptJenis) => deptJenis.Jenis === apiItem.Jenis
                                ) &&
                                jenisArray.some((jenis) => jenis === apiItem.Jenis)
                        );

                        if (apiData.length > 0) {
                            apiData.forEach((apiItem) => {
                                const foundLevel = dept.Jenis.find(
                                    (deptJenis) => deptJenis.Jenis === apiItem.Jenis
                                );

                                if (foundLevel) {
                                    const foundLevelData = foundLevel.Level.find(
                                        (lvl) => lvl.NamaLevel === apiItem.Level
                                    );

                                    if (foundLevelData) {
                                        foundLevelData.JumlahKontrak +=
                                            apiItem.StatusKaryawan === "Contract"
                                                ? apiItem.Jumlah
                                                : 0;
                                        foundLevelData.JumlahPermanen +=
                                            apiItem.StatusKaryawan === "Permanent"
                                                ? apiItem.Jumlah
                                                : 0;

                                        foundLevelData.JumlahKontrakSelesai +=
                                            apiItem.StatusKaryawan === "Contract"
                                                ? apiItem.JumlahSelesai
                                                : 0;
                                        foundLevelData.JumlahPermanenSelesai +=
                                            apiItem.StatusKaryawan === "Permanent"
                                                ? apiItem.JumlahSelesai
                                                : 0;

                                        if (
                                            apiItem.StatusKaryawan === "Contract" &&
                                            apiItem.ListNama !== null &&
                                            apiItem.ListNama !== ""
                                        ) {
                                            if (foundLevelData.ListNamaKontrak === "") {
                                                foundLevelData.ListNamaKontrak += apiItem.ListNama;
                                            } else {
                                                foundLevelData.ListNamaKontrak += `${apiItem.ListNama}, `;
                                            }
                                        }

                                        if (
                                            apiItem.StatusKaryawan === "Permanent" &&
                                            apiItem.ListNama !== null &&
                                            apiItem.ListNama !== ""
                                        ) {
                                            if (foundLevelData.ListNamaPermanen === "") {
                                                foundLevelData.ListNamaPermanen += apiItem.ListNama;
                                            } else {
                                                foundLevelData.ListNamaPermanen += `${apiItem.ListNama}, `;
                                            }
                                        }
                                    } else {
                                        foundLevel.Level.push({
                                            NamaLevel: apiItem.Level,
                                            JumlahKontrak:
                                                apiItem.StatusKaryawan === "Contract"
                                                    ? apiItem.Jumlah
                                                    : 0,
                                            JumlahPermanen:
                                                apiItem.StatusKaryawan === "Permanent"
                                                    ? apiItem.Jumlah
                                                    : 0,
                                            JumlahKontrakSelesai:
                                                apiItem.StatusKaryawan === "Contract"
                                                    ? apiItem.JumlahSelesai
                                                    : 0,
                                            JumlahPermanenSelesai:
                                                apiItem.StatusKaryawan === "Permanent"
                                                    ? apiItem.JumlahSelesai
                                                    : 0,
                                            ListNamaKontrak:
                                                apiItem.StatusKaryawan === "Contract"
                                                    ? apiItem.ListNama
                                                    : "",
                                            ListNamaPermanen:
                                                apiItem.StatusKaryawan === "Permanent"
                                                    ? apiItem.ListNama
                                                    : "",
                                        });
                                    }
                                }
                            });
                        }

                        return dept;
                    });

                    return { ...divisionData, Departemen: updatedDepartments };
                });
                setSpecificOutput(combinedDataWithAPI);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [
        DivisionData,
        DepartmentData,
        LevelData,
        ActiveYear,
        StartMonth,
        EndMonth,
    ]);

    useEffect(() => {
        changeYear(ActiveYear);
        changeStartMonth(StartMonth);
        changeEndMonth(EndMonth);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Data]);

    const changeYear = (newValue) => {
        setActiveYear(newValue);
    };

    const changeStartMonth = (newValue) => {
        if (Number(newValue) > Number(EndMonth)) {
            dispatch(Create_Warning_Messages(null, "Not Higher Then End Month"));
        } else {
            setStartMonth(newValue);
        }
    };

    const changeEndMonth = (newValue) => {
        if (Number(newValue) < Number(StartMonth)) {
            dispatch(Create_Warning_Messages(null, "Not Lower Then Start Month"));
        } else {
            setEndMonth(newValue);
        }
    };

    const st_textfield = { margin: "10px 10px 10px 10px", width: "20%" };
    const exportToExcel = () => {
        const table = document.getElementById("table-specific");

        const ws = XLSX.utils.table_to_sheet(table);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, "table.xlsx");
    };
    return (
        <Fragment>
            <center>
                <TextField
                    style={st_textfield}
                    variant="outlined"
                    type="number"
                    label="Active Year"
                    name="ActiveYear"
                    value={ActiveYear}
                    onChange={(e) => changeYear(e.target.value)}
                />
                <FormControl style={{ ...st_textfield }} variant="filled" required>
                    <InputLabel shrink>Start Month</InputLabel>
                    <Select
                        native
                        style={{
                            backgroundColor: "white",
                            fontFamily: "Montserrat, Segoe UI",
                        }}
                        onChange={(e) => changeStartMonth(e.target.value)}
                        label="StartMonth"
                        name="StartMonth"
                        value={StartMonth}
                    >
                        <option value="" disabled>
                            {" "}
                            -- select an option --{" "}
                        </option>
                        {VariableLimit.Month.map((option, index) => (
                            <option key={`Op_StartMonth_${index}`} value={option.value}>
                                {option.display}
                            </option>
                        ))}
                    </Select>
                </FormControl>
                <FormControl style={{ ...st_textfield }} variant="filled" required>
                    <InputLabel shrink>End Month</InputLabel>
                    <Select
                        native
                        style={{
                            backgroundColor: "white",
                            fontFamily: "Montserrat, Segoe UI",
                        }}
                        onChange={(e) => changeEndMonth(e.target.value)}
                        label="End Month"
                        name="EndMonth"
                        value={EndMonth}
                    >
                        <option value="" disabled>
                            {" "}
                            -- select an option --{" "}
                        </option>
                        {VariableLimit.Month.map((option, index) => (
                            <option key={`Op_EndMonth_${index}`} value={option.value}>
                                {option.display}
                            </option>
                        ))}
                    </Select>
                </FormControl>
            </center>
            <h3 style={{ fontSize: "2vw", fontFamily: "Montserrat, Segoe UI" }}>
                All MPP
            </h3>
            <div className="tableMPP">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Jenis</TableCell>
                            <TableCell>Total MPP Permanent</TableCell>
                            <TableCell>Total MPP Contract</TableCell>
                            <TableCell>Total MPP</TableCell>
                            <TableCell>Total MPP Permanent Realization</TableCell>
                            <TableCell>Total MPP Contract Realization</TableCell>
                            <TableCell>Total MPP Realization</TableCell>
                            <TableCell>Total MPP Permanent Not Realization</TableCell>
                            <TableCell>Total MPP Contract Not Realization</TableCell>
                            <TableCell>Total MPP Not Realization</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allOutput &&
                            allOutput.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.Jenis}</TableCell>
                                    <TableCell>{item.Permanen}</TableCell>
                                    <TableCell>{item.Kontrak}</TableCell>
                                    <TableCell>{item.Kontrak + item.Permanen}</TableCell>
                                    <TableCell>
                                        <div class="tooltip">
                                            {item.JumlahSelesaiPermanen}
                                            <span class="tooltiptext">
                                                {item.ListNamaPermanen ? item.ListNamaPermanen : "None"}
                                            </span>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div class="tooltip">
                                            {item.JumlahSelesaiKontrak}
                                            <span class="tooltiptext">
                                                {item.ListNamaKontrak ? item.ListNamaKontrak : "None"}
                                            </span>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        {item.JumlahSelesaiKontrak + item.JumlahSelesaiPermanen}
                                    </TableCell>
                                    <TableCell>
                                        {item.Permanen - item.JumlahSelesaiPermanen}
                                    </TableCell>
                                    <TableCell>
                                        {item.Kontrak - item.JumlahSelesaiKontrak}
                                    </TableCell>
                                    <TableCell>
                                        {item.Permanen +
                                            item.Kontrak -
                                            (item.JumlahSelesaiPermanen + item.JumlahSelesaiKontrak)}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </div>

            <br />
            <h3 style={{ fontSize: "2vw", fontFamily: "Montserrat, Segoe UI" }}>
                Specific Department
            </h3>
            {specificOutput && (
                <Button
                    variant="contained"
                    onClick={exportToExcel}
                    style={{ marginBottom: "16px", marginLeft: "16px" }}
                >
                    Export to Excel
                </Button>
            )}
            <div className="tableMPP">
                <Table id="table-specific">
                    <TableHead>
                        <TableRow>
                            <TableCell>Divisi</TableCell>
                            <TableCell>Department</TableCell>
                            <TableCell>Jenis</TableCell>
                            <TableCell>Level</TableCell>
                            <TableCell>Total MPP Permanent</TableCell>
                            <TableCell>Total MPP Contract</TableCell>
                            <TableCell>Total MPP</TableCell>
                            <TableCell>Total MPP Permanent Realization</TableCell>
                            <TableCell>Total MPP Contract Realization</TableCell>
                            <TableCell>Total MPP Realization</TableCell>
                            <TableCell>Total MPP Permanent Not Realization</TableCell>
                            <TableCell>Total MPP Contract Not Realization</TableCell>
                            <TableCell>Total MPP Not Realization</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {specificOutput &&
                            specificOutput.map((divisionData, divisionIndex) => (
                                <React.Fragment key={divisionIndex}>
                                    {divisionData.Departemen.map((dept, deptIndex) => (
                                        <React.Fragment key={deptIndex}>
                                            {dept.Jenis.map((jenis, jenisIndex) => (
                                                <React.Fragment key={jenisIndex}>
                                                    {jenis.Level.map((level, levelIndex) => (
                                                        <TableRow
                                                            key={`${divisionIndex}-${deptIndex}-${jenisIndex}-${levelIndex}`}
                                                        >
                                                            {deptIndex === 0 &&
                                                                jenisIndex === 0 &&
                                                                levelIndex === 0 && (
                                                                    <TableCell
                                                                        rowSpan={
                                                                            divisionData.Departemen.length *
                                                                            dept.Jenis.length *
                                                                            jenis.Level.length
                                                                        }
                                                                    >
                                                                        {divisionData.NamaDivisi}
                                                                    </TableCell>
                                                                )}
                                                            {jenisIndex === 0 && levelIndex === 0 && (
                                                                <TableCell
                                                                    rowSpan={
                                                                        dept.Jenis.length * jenis.Level.length
                                                                    }
                                                                >
                                                                    {dept.NamaDepartemen}
                                                                </TableCell>
                                                            )}
                                                            {levelIndex === 0 && (
                                                                <TableCell rowSpan={jenis.Level.length}>
                                                                    {jenis.Jenis}
                                                                </TableCell>
                                                            )}
                                                            <TableCell>{level.NamaLevel}</TableCell>
                                                            <TableCell>{level.JumlahPermanen}</TableCell>
                                                            <TableCell>{level.JumlahKontrak}</TableCell>
                                                            <TableCell>
                                                                {level.JumlahKontrak + level.JumlahPermanen}
                                                            </TableCell>
                                                            <TableCell>
                                                                <div class="tooltip">
                                                                    {level.JumlahPermanenSelesai}
                                                                    <span class="tooltiptext">
                                                                        {level.ListNamaPermanen
                                                                            ? level.ListNamaPermanen
                                                                            : "None"}
                                                                    </span>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell>
                                                                <div class="tooltip">
                                                                    {level.JumlahKontrakSelesai}
                                                                    <span class="tooltiptext">
                                                                        {level.ListNamaKontrak
                                                                            ? level.ListNamaKontrak
                                                                            : "None"}
                                                                    </span>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell>
                                                                {level.JumlahKontrakSelesai +
                                                                    level.JumlahPermanenSelesai}
                                                            </TableCell>
                                                            <TableCell>
                                                                {level.JumlahPermanen -
                                                                    level.JumlahPermanenSelesai}
                                                            </TableCell>
                                                            <TableCell>
                                                                {level.JumlahKontrak -
                                                                    level.JumlahKontrakSelesai}
                                                            </TableCell>
                                                            <TableCell>
                                                                {level.JumlahPermanen -
                                                                    level.JumlahPermanenSelesai +
                                                                    (level.JumlahKontrak -
                                                                        level.JumlahKontrakSelesai)}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </React.Fragment>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            ))}
                    </TableBody>
                </Table>
            </div>
        </Fragment>
    );
};

export default MPPOutputRealization;
