import React, { Fragment } from "react"

import { connect } from 'react-redux'

import { Konfirm_Rekomendasi, get_List_Pendaftar_Detail } from '../../../../../../../../Store/Actions/Job.Action'
import { Create_Warning_Messages } from '../../../../../../../../Store/Actions/Messages.Actions'

import { Skeleton, FormControl, InputLabel, Select, TextField, Button } from '@mui/material'

import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'

import Profile from '../../../../../../Account/Profile'

class RecomDiajukan extends React.Component {
    state = {
        Jenis: '',
        Keterangan: '',
        WaktuWawancara: [
            {
                Waktu: ''
            },
            {
                Waktu: ''
            },
            {
                Waktu: ''
            },
        ]
    }

    componentDidMount() {
        const { JobActionId, AccountActionId } = this.props
        this.props.get_List_Pendaftar_Detail(JobActionId, AccountActionId)
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    AddDetailList = () => {
        const WaktuWawancara = this.state.WaktuWawancara
        const newData = { Waktu: '' }
        WaktuWawancara.push(newData)
        this.setState({ WaktuWawancara: WaktuWawancara })
    }

    DeleteDetailList = (Index) => {
        const WaktuWawancara = this.state.WaktuWawancara
        if (WaktuWawancara.length > 3) {

            WaktuWawancara.splice(Index, 1)
            this.setState({ WaktuWawancara: WaktuWawancara })
        } else {
            this.props.Create_Warning_Messages(null, 'Minimal 3')
        }
    }

    OnChangeMapItem = (e, Index, Order) => {
        const WaktuWawancara = this.state.WaktuWawancara

        // let isCross = false
        // WaktuWawancara.forEach((item) => {
        //     const Waktu = new Date(item.Waktu)
        //     const newWaktu = new Date(e.target.value)
        //     const Ingterval = newWaktu - Waktu
        //     // 3600000
        //     // if (Ingterval > 3600000 || Ingterval > -3600000) {
        //     if (Ingterval < 3600000) {
        //         isCross = true
        //     }
        // })

        // if (isCross) {
        //     this.props.Create_Warning_Messages(null, 'Interval minimal 1 jam')
        // } else {
        //     WaktuWawancara[Index].Waktu = e.target.value

        //     this.setState({ WaktuWawancara: WaktuWawancara })
        // }

        WaktuWawancara[Index].Waktu = e.target.value

        this.setState({ WaktuWawancara: WaktuWawancara })
    }


    formOnSubmit = (e) => {
        e.preventDefault()

        const JobActionId = this.props.JobActionId
        const AccountActionId = this.props.AccountActionId
        const isDiterima = this.state.Jenis === 'Accept' ? true : false
        this.props.Konfirm_Rekomendasi(JobActionId, AccountActionId, this.state.Jenis, this.state.Keterangan, isDiterima ? this.state.WaktuWawancara : [])
    }
    render() {
        const isListPendaftarDetailLoading = this.props.isListPendaftarDetailLoading
        const ListPendaftarDetail = this.props.ListPendaftarDetail

        const st_textfield_non = { marginTop: '10px', marginBottom: '10px', width: '100%' }
        const st_divaddtextfield = { display: 'flex', alignItems: 'center', margin: '0 0 2% 2%' }
        const st_textfield_Map = { margin: '10px', width: '80%' }
        const st_icon = { color: 'grey', fontSize: '180%', margin: '0 0 0 1%', cursor: 'pointer' }

        const {
            Jenis,
            Keterangan,
            WaktuWawancara
        } = this.state

        const YYYY = new Date().getFullYear()
        const MM = new Date().getMonth() + 1
        const DD = new Date().getDate() + 3

        const lastDayOfMonth = new Date(YYYY, MM, 0).getDate()
        const RMM = DD > lastDayOfMonth ? MM + 1 : MM
        const RDD = DD > lastDayOfMonth ? DD - lastDayOfMonth : DD

        const MinDate = `${YYYY}-${RMM < 10 ? '0' + RMM : RMM}-${RDD < 10 ? '0' + RDD : RDD}T09:00`

        return (
            <Fragment>
                {isListPendaftarDetailLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5,].map((item) =>
                            <Skeleton
                                key={`RecomDiajukan_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <div
                            style={{ margin: '0 0 5% 0' }}
                        >
                            <div
                                style={{ margin: '0 0 3% 0', borderBottom: '1px solid black' }}
                            >
                                <h2
                                    style={{ borderBottom: '1px solid black' }}
                                >
                                    Status Pengurusan
                                </h2>
                                <h3>
                                    HR:
                                </h3>
                                <center>
                                    <h2
                                        style={{ margin: '0 0 0 0', padding: '1%', color: 'green', border: '1px solid blue' }}
                                    >
                                        Status:  {ListPendaftarDetail.AccReferralStatus ? ListPendaftarDetail.AccReferralStatus : ''}
                                    </h2>
                                </center>
                                <p>Oleh: {ListPendaftarDetail.BiodataNama ? ListPendaftarDetail.BiodataNama : ''}</p>
                                <p>Tanggal: {ListPendaftarDetail.AccReferralTime ? new Date(ListPendaftarDetail.AccReferralTime).toLocaleString(['ban', 'id']) : ''}</p>
                                <p>Keterangan: {ListPendaftarDetail.AccReferralKet ? ListPendaftarDetail.AccReferralKet : ''}</p>
                                <h3>
                                    Departemen:
                                </h3>
                                <center>
                                    <h2
                                        style={{ margin: '0 0 0 0', padding: '1%', color: ListPendaftarDetail.AccDepStatus ? ListPendaftarDetail.AccDepStatus === 'Accept' ? 'green' : 'red' : 'orange', border: '1px solid Black' }}
                                    >
                                        Status: {ListPendaftarDetail.AccDepStatus ? ListPendaftarDetail.AccDepStatus : 'Menunggu'}
                                    </h2>
                                </center>
                                <p>Oleh: {ListPendaftarDetail.BiodataBiodataNama ? ListPendaftarDetail.BiodataBiodataNama : ''}</p>
                                <p>Tanggal: {ListPendaftarDetail.AccDepTime ? new Date(ListPendaftarDetail.AccDepTime).toLocaleString(['ban', 'id']) : ''}</p>
                                <p>Keterangan: {ListPendaftarDetail.AccDepKet ? ListPendaftarDetail.AccDepKet : ''}</p>
                            </div>
                            <Profile DisableEdit={true} isView={true} />
                            <h2>
                                Action:
                            </h2>
                            <form
                                onSubmit={this.formOnSubmit}
                            >
                                <FormControl style={st_textfield_non} variant="filled" required >
                                    <InputLabel shrink >Keputusan</InputLabel>
                                    <Select style={{ backgroundColor: 'white' }} native onChange={(e) => this.onChangeField(e)} label="Jenis" name='Jenis' value={Jenis} >
                                        <option value="" disabled> -- select an option -- </option>
                                        {['Accept', 'Reject'].map((option, index) =>
                                            <option key={index} value={option}>{option}</option>
                                        )}
                                    </Select>
                                </FormControl>
                                <TextField
                                    style={st_textfield_non}
                                    variant='outlined'
                                    type='text'
                                    label='Keterangan'
                                    name='Keterangan'
                                    value={Keterangan}
                                    onChange={this.onChangeField}
                                />
                                {Jenis === 'Accept' ?
                                    <Fragment>
                                        <h3>Silahkan pilih waktu untuk melaksanakan interview dengan kandidat (minimal 3)</h3>
                                        {WaktuWawancara.map((item, index) => (
                                            <div
                                                style={st_divaddtextfield}
                                                key={`WaktuWawancara${index}`}
                                            >
                                                <label
                                                    style={{ marginRight: '1%' }}
                                                >
                                                    {index + 1}
                                                </label>
                                                <TextField
                                                    style={st_textfield_Map}
                                                    variant='outlined'
                                                    type='datetime-local'
                                                    label={`Jadwal Interview ${index + 1}`}
                                                    InputLabelProps={{ shrink: true }}
                                                    inputProps={{ min: MinDate }}
                                                    name='Nama'
                                                    required
                                                    value={WaktuWawancara[index].Waktu}
                                                    onChange={(e) => this.OnChangeMapItem(e, index, 2)}
                                                />
                                                <DeleteOutlineRoundedIcon
                                                    onClick={(e) => this.DeleteDetailList(index)}
                                                    style={st_icon}
                                                />
                                                <AddCircleRoundedIcon
                                                    onClick={this.AddDetailList}
                                                    style={st_icon}
                                                />
                                            </div>
                                        ))}
                                    </Fragment> :
                                    <Fragment></Fragment>}
                                <center>
                                    <Button
                                        variant='contained'
                                        type="submit"
                                        style={{ width: '80%', margin: '1% 0 4% 0' }}
                                    >
                                        Submit
                                    </Button>
                                </center>
                            </form>
                        </div>
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
    isListPendaftarDetailLoading: state.Job.isListPendaftarDetailLoading,
    ListPendaftarDetail: state.Job.ListPendaftarDetail,
})

export default connect(mapStateToProps, { Konfirm_Rekomendasi, get_List_Pendaftar_Detail, Create_Warning_Messages })(RecomDiajukan)