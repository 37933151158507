import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Level_Data, Delete_Level } from '../../../../../../Store/Actions/Setting.Action'

import { FormGroup, FormControlLabel, Checkbox, Button, CircularProgress } from '@mui/material'

import Detail from '../../../../../Containers/Dashboard/Setting/Level/Detail'
import Data from '../../../../../Containers/Dashboard/Setting/Level/Data'

class SettingLevelDelete extends React.Component {
    state = {
        isConfirm: false,
    }
    componentDidUpdate(prevProps) {
        if (this.props.LevelActionId !== prevProps.LevelActionId) {
            this.props.get_Level_Data(this.props.LevelActionId)
        }
    }
    componentDidMount() {
        const { LevelActionId } = this.props
        if (LevelActionId !== null) {
            this.props.get_Level_Data(LevelActionId)
        }
    }

    OnChangeCheckbox = E => {
        this.setState({ [E.target.name]: !this.state[E.target.name] })
    }

    onClickDelete(Id) {
        this.props.Delete_Level(Id)
    }
    render() {
        const LevelActionId = this.props.LevelActionId
        const isLevelActionGetDetailLoading = this.props.isLevelActionGetDetailLoading
        const LevelDetail = this.props.LevelDetail
        const LevelData = this.props.LevelData
        const HeadData = [
            {
                Nama: 'Index',
                width: '5%',
            },
            {
                Nama: 'Id',
                width: '10%',
            },
            {
                Nama: 'UserName',
                width: '10%',
            },
            {
                Nama: 'Nama',
                width: '10%',
            },
            {
                Nama: 'Level',
                width: '10%',
            }
        ]
        const BodyData = LevelData ? LevelData.map((item, index) => {
            return {
                index: index + 1,
                Id: item.Id,
                UserName: item.UserName,
                Nama: item.BiodataNama,
                Level: item.DepartemenNama,
            }
        }) : []
        const {
            isConfirm
        } = this.state
        const isLevelDeleteLoading = this.props.isLevelDeleteLoading
        return (
            <Fragment>
                <Detail Data={LevelDetail} isLoading={isLevelActionGetDetailLoading} />
                <Data HeadData={HeadData} BodyData={BodyData} isLoading={isLevelActionGetDetailLoading} />
                <div
                    style={{ margin: '1%' }}
                >
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox color='primary' onChange={this.OnChangeCheckbox} name="isConfirm" value={isConfirm} disabled={isLevelDeleteLoading ? true : false} />}
                            label="Saya menyadari akan resikonya dan saya tau apa yang saya lakukan"
                        />
                    </FormGroup>
                    <center>
                        <Button
                            variant='contained'
                            size='large'
                            color='error'
                            disabled={!isConfirm || isLevelDeleteLoading ? true : false}
                            onClick={() => this.onClickDelete(LevelActionId)}
                        >
                            {isLevelDeleteLoading ? <CircularProgress /> : 'Delete'}
                        </Button>
                    </center>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isLevelActionGetDetailLoading: state.Setting.isLevelActionGetDetailLoading,
    isLevelDeleteLoading: state.Setting.isLevelDeleteLoading,
    LevelActionId: state.Setting.LevelActionId,
    LevelDetail: state.Setting.LevelDetail,
    LevelData: state.Setting.LevelData,
})

export default connect(mapStateToProps, { get_Level_Data, Delete_Level })(SettingLevelDelete)
