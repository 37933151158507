import React, { Fragment } from 'react'

// import { Skeleton, TextField, Switch } from '@mui/material'
import { Skeleton, TextField } from '@mui/material'

import { VariableLimit } from '../../../../Global'

const DetailPR = ({ Data = {}, PRListItem = [], Skill = [], isLoading }) => {
    const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%', backgroundColor: '#E0E0E0' }
    const st_divaddtextfield = { display: 'flex', alignItems: 'center', margin: '0 0 2% 2%' }
    const st_TextSmall = { width: '20%', margin: '0 1% 0 0', backgroundColor: '#E0E0E0' }
    const st_Half = { width: '45%' }
    const st_textfield_disable = { marginTop: '10px', marginBottom: '10px', width: '100%', backgroundColor: '#E0E0E0' }
    // const st_TextSmall_disable = { width: '20%', margin: '0 1% 0 0', backgroundColor: '#E0E0E0' }
    return (
        <Fragment>
            {isLoading ?
                <Fragment>
                    <Skeleton
                        height={'10vh'}
                        width={'100%'}
                    />
                </Fragment> :
                <Fragment>
                    <h3>
                        Submission Status
                    </h3>
                    <div
                        style={{
                            margin: '1% 0 1% 0', padding: '1%', border: `1px solid ${Data?.Status ? Data.Status === 'Submitted' ? 'Orange' :
                                Data.Status === 'Accept' ? 'Green' : 'Red' : 'black'}`
                        }}
                    >
                        <center>
                            <h2
                                style={{
                                    color: `${Data?.Status ? Data.Status === 'Submitted' ? 'Orange' :
                                        Data.Status === 'Accept' ? 'Green' : 'Red' : 'black'}`
                                }}
                            >
                                Status : {Data?.Status ? Data.Status : 'Status'}
                            </h2>
                        </center>
                        {Data?.Status ? Data.Status !== 'Submitted' ?
                            <h4>
                                By:<br />
                                {Data?.AccBioNama ? Data.AccBioNama : 'AccBioNama'}<br /><br />
                                {/* {Data?.AccBioNama ? Data.AccBioNama : ''}<br /><br /> */}
                                Date:<br />
                                {Data?.TanggalAcc ? new Date(Data.TanggalAcc).toLocaleString(['ban', 'id']) : 'TanggalAcc'}<br /><br />
                                Notes:<br />
                                {Data?.AccKeterangan ? Data.AccKeterangan : 'AccKeterangan'}<br />
                            </h4> :
                            <Fragment>
                            </Fragment> :
                            <Fragment>
                            </Fragment>
                        }
                    </div>
                    <h3>
                        Position Information
                    </h3>
                    <TextField
                        style={st_textfield_disable}
                        variant='outlined'
                        type='text'
                        label='Position Title'
                        name='Posisi'
                        disabled
                        value={Data ? Data.NamaPosisi : ''} />
                    <TextField
                        style={{ ...st_textfield_disable }}
                        variant='outlined'
                        type='text'
                        label='Level'
                        name='Level'
                        disabled
                        value={Data ? Data.LevelNama : ''} />
                    {/* <TextField
                        style={{ ...st_textfield_disable, ...st_Half, marginLeft: '5%' }}
                        variant='outlined'
                        type='text'
                        label='Cost Center'
                        name='CostCenter'
                        disabled
                        value={Data ? Data.CostCenter : 'CostCenter'} /> */}
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        label='Reporting To'
                        name='Melapor'
                        disabled
                        value={Data ? Data.AtasanLangsungNama : ''} />
                    <TextField
                        style={st_textfield_disable}
                        variant='outlined'
                        type='text'
                        label='Employee Type'
                        name='TipePegawai'
                        disabled
                        value={Data ? Data.TipePegawai : ''} />
                    {Data?.TipePegawai ? Data.TipePegawai === VariableLimit.MPPType.Contract ?
                        < TextField
                            style={st_textfield_disable}
                            variant='outlined'
                            type='Number'
                            label='Contract Duration in Month'
                            name='ContractDuration'
                            disabled
                            value={Data ? Data.ContractDuration : ''} />
                        : <Fragment></Fragment>
                        : <Fragment></Fragment>
                    }
                    <TextField
                        style={{ ...st_textfield, ...st_Half }}
                        variant='outlined'
                        type='number'
                        label='No of Subordinate'
                        name='Bawahan'
                        disabled
                        value={Data ? Data.JumlahBawahan : ''} />
                    <TextField
                        style={{ ...st_textfield, ...st_Half, marginLeft: '5%' }}
                        variant='outlined'
                        type='number'
                        label='No of open position'
                        name='Dibutuhkan'
                        disabled
                        value={Data ? Data.PosisiDibutuhkan : ''} />
                    {Data?.Reason &&
                        <TextField
                            style={{ ...st_textfield_disable }}
                            variant='outlined'
                            type='text'
                            label='Reason not maximum open position'
                            name='Reason'
                            disabled
                            value={Data ? Data.Reason : ''} />}

                    <TextField
                        style={{ ...st_textfield, ...st_Half }}
                        variant='outlined'
                        type='text'
                        label='Expected Month'
                        name='ExpectedMonth'
                        disabled
                        value={Data?.ExpectedMonth !== undefined ? VariableLimit.Month[Data.ExpectedMonth]?.display || ''
                            : ''} />

                    <TextField
                        style={{ ...st_textfield, ...st_Half, marginLeft: '5%' }}
                        variant='outlined'
                        type='number'
                        label='Expected Week'
                        name='ExpectedWeek'
                        disabled
                        value={Data ? Data.ExpectedWeek : ''} />

                    {Data?.Reason2 &&
                        <TextField
                            style={{ ...st_textfield_disable }}
                            variant='outlined'
                            type='text'
                            label='Reason Open PR not same as MPP'
                            name='Reason2'
                            disabled
                            value={Data ? Data.Reason2 : ''} />}
                    <TextField
                        style={Data?.isBaruOrMenggantikan ? { ...st_textfield } : { ...st_textfield, ...st_Half }}
                        variant='outlined'
                        type='text'
                        label='Is this a new position or replacement?'
                        name='BaruOrMenggantikan'
                        disabled
                        value={Data ? Data.isBaruOrMenggantikan === true ? 'New' : 'Replacement' : ''} />
                    {Data?.isBaruOrMenggantikan ?
                        <Fragment>
                        </Fragment> : <TextField
                            style={{ ...st_textfield, ...st_Half, marginLeft: '5%' }}
                            variant='outlined'
                            type='text'
                            label='Replacement Name'
                            name='ReplacementName'
                            disabled
                            value={Data ? Data.ReplacementName : ''}
                        />}
                    <TextField
                        style={{ ...st_textfield }}
                        variant='outlined'
                        type='text'
                        label='Work Location'
                        name='Lokasi'
                        disabled
                        value={Data ? Data.LokasiNama : ''} />
                    <h3>
                        Work Equipment
                    </h3>
                    {PRListItem ? PRListItem.map((item, index) => (
                        <div
                            style={st_divaddtextfield}
                            key={`PRListItem_${index}`}
                        >
                            <TextField
                                style={st_TextSmall}
                                variant='outlined'
                                type='text'
                                label='Equipment'
                                name='Item'
                                disabled
                                value={item.ItemNama}
                            />
                            <TextField
                                style={st_TextSmall}
                                variant='outlined'
                                type='text'
                                label='Opening Month'
                                name='WaktuPembukaan'
                                disabled
                                value={new Date(2022, item.BulanBuka, 1).toLocaleString('default', { month: 'long' })}
                            />
                            <TextField
                                style={st_TextSmall}
                                variant='outlined'
                                type='number'
                                label='Quantity'
                                name='Jumlah'
                                disabled
                                value={item.Jumlah}
                            />
                        </div>
                    )) :
                        <Fragment>
                        </Fragment>}
                    <h3>
                        Skill Requirement (HR benchmark for applicant's recommendation, asked at registration)
                    </h3>
                    <div
                        style={{ marginLeft: '2vw' }}
                    >
                        {Skill.map((item, index) =>
                            <h4
                                key={`Skill_${index}`}
                            >
                                {index + 1}. {item.Skill}
                            </h4>
                        )}
                    </div>
                    <h3>
                        Job Description
                    </h3>
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        multiline
                        rows={8}
                        label='Job Description'
                        name='UraianPosisi'
                        disabled
                        value={Data ? Data.UraianPekerjaan : ''} />
                    <h3>
                        Position Requirements
                    </h3>
                    <TextField
                        style={{ ...st_textfield, ...st_Half }}
                        variant='outlined'
                        type='number'
                        label='Age Min'
                        name='UsiaMin'
                        disabled
                        value={Data ? Data.UsiaMin : ''} />
                    <TextField
                        style={{ ...st_textfield, ...st_Half, marginLeft: '5%' }}
                        variant='outlined'
                        type='number'
                        label='Age Max'
                        name='UsiaMax'
                        disabled
                        value={Data ? Data.UsiaMax : ''} />
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        label='Gender'
                        name='JenisKelamin'
                        disabled
                        value={Data ? Data.JenisKelamin : ''} />
                    <TextField
                        style={{ ...st_textfield, ...st_Half }}
                        variant='outlined'
                        type='text'
                        label='Education Qualification'
                        name='Pendidikan'
                        disabled
                        value={Data ? Data.Pendidikan : ''} />
                    <TextField
                        style={{ ...st_textfield, ...st_Half, marginLeft: '5%' }}
                        variant='outlined'
                        type='text'
                        label='Major of Study'
                        name='Jurusan'
                        disabled
                        value={Data ? Data.Jurusan : ''} />
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        label='Language Skills Besides Indonesian'
                        name='Bahasa'
                        disabled
                        value={Data ? Data.Bahasa : ''} />
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='number'
                        label='Years of Experience'
                        name='Pengalaman'
                        disabled
                        value={Data ? Data.PengalamanTahun : ''} />
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        multiline
                        rows={3}
                        label='Notes'
                        name='Catatan'
                        disabled
                        value={Data ? Data.Catatan : ''} />
                    <h3>
                        Additional data
                    </h3>
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        label='Requestor Name'
                        name='Pembuat'
                        disabled
                        value={Data ? Data.BioNama : ''} />
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        label='Requestor Department '
                        name='DepartmenPemohon'
                        disabled
                        value={Data ? Data.DepNama : ''} />
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        label='Requestor Date'
                        name='TanggalPembuatan'
                        disabled
                        value={Data ? new Date(Data.TanggalPengajuan).toLocaleString(['ban', 'id']) : ''} />
                    {/* <h3>
                        General Setting
                    </h3>
                    <label>
                        <b>
                            Diiklankan?
                        </b>
                    </label><br /><br />
                    <label>
                        Tidak
                    </label>
                    <Switch
                        name='Diiklankan'
                        disabled
                        checked={Data ? Data.Diiklankan : 'Diiklankan'}
                    />
                    <label>
                        Ya
                    </label> */}
                </Fragment>}
        </Fragment >

    )
}
export default DetailPR