import React, { Fragment } from 'react'

import { Skeleton, TextField } from '@mui/material'

const SettingMPPDetail = ({ Data, isLoading = false, ...props }) => {
    const TextFieldStyle = { width: '95%', margin: '1%', backgroundColor: '#E0E0E0' }
    return (
        <Fragment>
            {isLoading ?
                <Fragment>
                    <Skeleton
                        height={'10vh'}
                    />
                    <Skeleton
                        height={'10vh'}
                    />
                    <Skeleton
                        height={'10vh'}
                    />
                </Fragment> :
                <Fragment>
                    <h3>
                        MPP Detail
                    </h3>
                    <center>
                        <TextField
                            label='Id'
                            value={Data?.Id ? Data.Id : ''}
                            disabled
                            style={TextFieldStyle}
                        />
                        <TextField
                            label='Year'
                            value={Data?.Tahun ? Data.Tahun : ''}
                            disabled
                            style={TextFieldStyle}
                        />
                        <TextField
                            label='Type'
                            value={Data?.Jenis ? Data.Jenis : ''}
                            disabled
                            style={TextFieldStyle}
                        />
                        <TextField
                            label='Open Date'
                            value={Data?.TanggalBuka ? new Date(Data.TanggalBuka).toLocaleDateString() : ''}
                            disabled
                            style={TextFieldStyle}
                        />
                        <TextField
                            label='Close Date'
                            value={Data?.TanggalTutup ? new Date(Data.TanggalTutup).toLocaleDateString() : ''}
                            disabled
                            style={TextFieldStyle}
                        />
                        <TextField
                            label='Notes'
                            value={Data?.Keterangan ? Data.Keterangan : ''}
                            disabled
                            style={TextFieldStyle}
                        />
                    </center>
                </Fragment>}
        </Fragment>
    )
}

export default SettingMPPDetail