import React, { Fragment, useEffect } from "react"

import CoTable from "../../../../Containers/Table"

import { VariableLimit } from "../../../../../Global"

const CandidateJourneyComp = ({ isLoading, Data }) => {

    const [OriginalData, setOriginalData] = React.useState([])

    useEffect(() => {
        if (Data.length > 0) {
            const UniquePRNUmber = []

            // eslint-disable-next-line
            const newData = Data.filter((item, index) => {
                const PRId = item?.PRId ? item.PRId : ''
                if (!(UniquePRNUmber.includes(PRId))) {
                    UniquePRNUmber.push(PRId)
                    return {
                        PRId: item?.PRId ? item.PRId : '',
                        // DepartemenNama: item?.DepartemenNama ? item.DepartemenNama : '',
                        // Sub: item?.Sub ? item.Sub : '',
                        // DivisionNama: item?.DivisionNama ? item.DivisionNama : '',
                        // LokasiLainnya: item?.LokasiLainnya ? item.LokasiLainnya : '',
                        // LevelNama: item?.LevelNama ? item.LevelNama : '',
                        // StatusKaryawan: item?.StatusKaryawan ? item.StatusKaryawan : '',
                        // PosisiDibutuhkan: item?.PosisiDibutuhkan ? item.PosisiDibutuhkan : '',
                        // TotalPendaftar: Data.reduce((Accumulator, Item) => {
                        //     return Accumulator + (true ? 1 : 0)
                        // }, 0),
                        // TotalDiajukan: 0,
                        // CVUserApproved: 0,
                        // CVUserRejected: 0,
                        // InterviewHRApproved: 0,
                        // InterviewHRRejected: 0,
                        // InterviewUserApproved: 0,
                        // InterviewUserRejected: 0,
                        // Offering: 0,
                        // MCU: 0,
                        // MCUApproved: 0,
                        // PreBoarding: 0,
                        // Contract: 0,
                        // Withdrew: 0,
                        // Cancel: 0,
                        // Hired: 0,
                    }
                }
            })

            setOriginalData(newData)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Data])

    const st_TableHead = { color: 'white', backgroundColor: '#00a3df' }
    const st_Gold = { backgroundColor: '#FFD966' }
    const st_White = { backgroundColor: '#E2F0D9' }
    const st_Brown = { color: 'white', backgroundColor: '#7F6000' }
    const st_Grey = { backgroundColor: '#C9C9C9' }
    const st_Green = { backgroundColor: '#70AD47' }
    const st_Red = { color: 'white', backgroundColor: '#FF0000' }
    const st_Yellow = { backgroundColor: '#FFFF00' }
    const st_Pink = { backgroundColor: '#F8CBAD' }
    const st_Orange = { backgroundColor: '#FFC000' }
    const st_Purple = { color: 'white', backgroundColor: '#7030A0' }
    const st_TableBorderHead = { borderTop: '1px solid black', borderRight: '0px solid black', borderBottom: '1px solid black', borderLeft: '1px solid black', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }
    const st_TableBorder = { borderLeft: '1px solid black', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }

    const HeadData = [
        {
            Nama: 'No',
            width: '5%',
            KeyName: 'index',
            SortingType: 'Number',
            style: { ...st_Gold, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'PR Number',
            width: '5%',
            KeyName: 'PRId',
            SortingType: 'Number',
            style: { ...st_Gold, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Tahun',
            width: '5%',
            KeyName: 'Tahun',
            SortingType: 'Number',
            style: { ...st_Gold, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Bulan Buka',
            width: '5%',
            KeyName: 'BulanBuka',
            style: { ...st_Gold, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Departemen',
            width: '5%',
            KeyName: 'DepartemenNama',
            style: { ...st_Gold, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Posisi',
            width: '5%',
            KeyName: 'Sub',
            style: { ...st_Gold, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Divisi',
            width: '5%',
            KeyName: 'DivisionNama',
            style: { ...st_Gold, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Area Kerja',
            width: '5%',
            KeyName: 'LokasiLainnya',
            style: { ...st_Gold, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Level',
            width: '5%',
            KeyName: 'LevelNama',
            style: { ...st_Gold, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Employee Type',
            width: '5%',
            KeyName: 'StatusKaryawan',
            style: { ...st_Gold, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Quantity',
            width: '5%',
            KeyName: 'PosisiDibutuhkan',
            SortingType: 'Number',
            style: { ...st_Gold, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Total Pendaftar',
            width: '5%',
            KeyName: 'TotalPendaftar',
            SortingType: 'Number',
            style: { ...st_White, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Total Diajukan',
            width: '5%',
            KeyName: 'TotalDiajukan',
            SortingType: 'Number',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'CV Approved by User',
            width: '5%',
            KeyName: 'CVUserApproved',
            SortingType: 'Number',
            style: { ...st_Brown, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'CV Rejected by User',
            width: '5%',
            KeyName: 'CVUserRejected',
            SortingType: 'Number',
            style: { ...st_Brown, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Approved by HR Itv',
            width: '5%',
            KeyName: 'InterviewHRApproved',
            SortingType: 'Number',
            style: { ...st_Grey, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Rejected by HR Itv',
            width: '5%',
            KeyName: 'InterviewHRRejected',
            SortingType: 'Number',
            style: { ...st_Grey, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Approved by User Itv',
            width: '5%',
            KeyName: 'Quarter',
            SortingType: 'InterviewUserApproved',
            style: { ...st_Grey, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Rejected by User Itv',
            width: '5%',
            KeyName: 'InterviewUserRejected',
            SortingType: 'Number',
            style: { ...st_Grey, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Total Offering',
            width: '5%',
            KeyName: 'Offering',
            SortingType: 'Number',
            style: { ...st_Green, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Total MCU',
            width: '5%',
            KeyName: 'MCU',
            SortingType: 'Number',
            style: { ...st_Red, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'MCU Approved',
            width: '5%',
            KeyName: 'MCUApproved',
            SortingType: 'Number',
            style: { ...st_Red, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Total PreBoarding',
            width: '5%',
            KeyName: 'PreBoarding',
            SortingType: 'Number',
            style: { ...st_Yellow, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Total Sign Contract',
            width: '5%',
            KeyName: 'Contract',
            SortingType: 'Number',
            style: { ...st_Pink, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Total Withdrew',
            width: '5%',
            KeyName: 'Withdrew',
            SortingType: 'Number',
            style: { ...st_Orange, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Total Cancel',
            width: '5%',
            KeyName: 'Cancel',
            SortingType: 'Number',
            style: { ...st_White, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Total Hired',
            width: '5%',
            KeyName: 'Hired',
            SortingType: 'Number',
            style: { ...st_Purple, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder, ...{ borderRight: '1px solid black' } }
        },
    ]

    const BodyData = OriginalData ? OriginalData.map((item, index) => {
        const PRId = item?.PRId ? item.PRId : ''

        return {
            index: {
                value: index + 1
            },
            PRId: {
                value: item?.PRId ? item.PRId : ' '
            },
            Tahun: {
                value: item?.Tahun ? item.Tahun : ' '
            },
            BulanBuka: {
                value: item ? item.BulanBuka + 1 : ' '
            },
            DepartemenNama: {
                value: item?.DepartemenNama ? item.DepartemenNama : ' '
            },
            Sub: {
                value: item?.Sub ? item.Sub : ' '
            },
            DivisionNama: {
                value: item?.DivisionNama ? item.DivisionNama : ' '
            },
            LokasiLainnya: {
                value: item?.LokasiLainnya ? item.LokasiLainnya : ' '
            },
            LevelNama: {
                value: item?.LevelNama ? item.LevelNama : ' '
            },
            StatusKaryawan: {
                value: item?.StatusKaryawan ? item.StatusKaryawan : ' '
            },
            PosisiDibutuhkan: {
                value: item?.PosisiDibutuhkan ? item.PosisiDibutuhkan : ' '
            },
            TotalPendaftar: {
                value: Data.reduce((Accumulator, Item) => {
                    return Accumulator + (Item.PRId === PRId ? 1 : 0)
                }, 0) ? Data.reduce((Accumulator, Item) => {
                    return Accumulator + (Item.PRId === PRId ? 1 : 0)
                }, 0) : ' '
            },
            TotalDiajukan: {
                value: Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && (Item.AccReferralStatus === 'Dilanjutkan' || Item.AccReferralStatus === 'Accept')
                            ? 1
                            : 0)
                }, 0) ? Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && (Item.AccReferralStatus === 'Dilanjutkan' || Item.AccReferralStatus === 'Accept')
                            ? 1
                            : 0)
                }, 0) : ' '
            },
            CVUserApproved: {
                value: Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.AccDepStatus === VariableLimit.MPPStatus.Accept
                            ? 1
                            : 0)
                }, 0) ? Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.AccDepStatus === VariableLimit.MPPStatus.Accept
                            ? 1
                            : 0)
                }, 0) : ' '
            },
            CVUserRejected: {
                value: Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.AccDepStatus === VariableLimit.MPPStatus.Reject
                            ? 1
                            : 0)
                }, 0) ? Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.AccDepStatus === VariableLimit.MPPStatus.Reject
                            ? 1
                            : 0)
                }, 0) : ' '
            },
            InterviewHRApproved: {
                value: Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.HrIStatus === VariableLimit.MPPStatus.Accept
                            ? 1
                            : 0)
                }, 0) ? Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.HrIStatus === VariableLimit.MPPStatus.Accept
                            ? 1
                            : 0)
                }, 0) : ' '
            },
            InterviewHRRejected: {
                value: Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.HrIStatus === VariableLimit.MPPStatus.Reject
                            ? 1
                            : 0)
                }, 0) ? Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.HrIStatus === VariableLimit.MPPStatus.Reject
                            ? 1
                            : 0)
                }, 0) : ' '
            },
            InterviewUserApproved: {
                value: Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.UsIFStatus === VariableLimit.MPPStatus.Accept
                            ? 1
                            : 0)
                }, 0) ? Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.UsIFStatus === VariableLimit.MPPStatus.Accept
                            ? 1
                            : 0)
                }, 0) : ' '
            },
            InterviewUserRejected: {
                value: Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.UsIFStatus === VariableLimit.MPPStatus.Reject
                            ? 1
                            : 0)
                }, 0) ? Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.UsIFStatus === VariableLimit.MPPStatus.Reject
                            ? 1
                            : 0)
                }, 0) : ' '
            },
            Offering: {
                value: Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.OfStatus
                            ? 1
                            : 0)
                }, 0) ? Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.OfStatus
                            ? 1
                            : 0)
                }, 0) : ' '
            },
            MCU: {
                value: Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.McId
                            ? 1
                            : 0)
                }, 0) ? Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.McId
                            ? 1
                            : 0)
                }, 0) : ' '
            },
            MCUApproved: {
                value: Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.AccStatus === VariableLimit.MPPStatus.Accept
                            ? 1
                            : 0)
                }, 0) ? Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.AccStatus === VariableLimit.MPPStatus.Accept
                            ? 1
                            : 0)
                }, 0) : ' '
            },
            PreBoarding: {
                value: Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.PBId
                            ? 1
                            : 0)
                }, 0) ? Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.PBId
                            ? 1
                            : 0)
                }, 0) : ' '
            },
            Contract: {
                value: Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.KoStatus
                            ? 1
                            : 0)
                }, 0) ? Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.KoStatus
                            ? 1
                            : 0)
                }, 0) : ' '
            },
            Withdrew: { // TODO
                value: item?.Withdrew ? item.Withdrew : ' '
            },
            Cancel: { // TODO
                value: item?.Cancel ? item.Cancel : ' '
            },
            Hired: {
                value: Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.OBStatus
                            ? 1
                            : 0)
                }, 0) ? Data.reduce((Accumulator, Item) => {
                    return Accumulator + (
                        Item.PRId === PRId
                            && Item.OBStatus
                            ? 1
                            : 0)
                }, 0) : ' '
            },
        }
    }) : []

    return (
        <Fragment>
            <CoTable
                TableId={'CandidateJourneyComp'}
                HeadData={HeadData}
                Data={BodyData}
                isHeadLoading={isLoading}
                isBodyLoading={isLoading}
                isPagination={BodyData.length > 5 ? true : false}
                isPaginationLoading={isLoading}
                isHeading={true}
                isDownload={true}
                ComplexDataDownload={true}
            />
        </Fragment>
    )
}

export default CandidateJourneyComp