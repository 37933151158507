import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_MPP_PR } from '../../../../../../Store/Actions/MPP.Action'

import { Stepper, Step, StepLabel, Button, Paper, FormControl, InputLabel, Select, Skeleton } from '@mui/material'

import PRForm from './PRForm'

import { isLevelQualified } from '../../../../../../Global'

class PengajuanPR extends React.Component {
    state = {
        StepValue: 0,
        MaxStep: 2,
        MPP: '',
        MPPId: '',
        MPPType: '',
        MPPDetail: null
    }

    componentDidMount() {
        this.props.get_MPP_PR()
    }

    handleNext = () => {
        if (this.state === this.state.MaxStep - 1) {
            this.setState({ StepValue: this.state.MaxStep - 1 })
        } else {
            this.setState({ StepValue: this.state.StepValue + 1 })
        }
    }
    handleBack = () => {
        if (this.state === 0) {
            this.setState({ StepValue: 0 })
        } else {
            this.setState({ StepValue: this.state.StepValue - 1 })
        }
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    SelectFormOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    SelectMPPFormOnChange = (e) => {
        const MPPPR = this.props.MPPPR
        const newMPPDetail = MPPPR.find(item => item.MPPListId === Number(e.target.value))
        this.setState({ MPP: newMPPDetail.MPPListId })
        this.setState({ MPPId: newMPPDetail.Id })
        this.setState({ MPPDetail: newMPPDetail })
    }

    SelectMPPTypeOnChange = (e) => {
        this.setState({ MPPType: e.target.value })
    }

    render() {
        const steps = ['MPP Select', 'PR Form']
        const {
            StepValue,
            MPP,
            MPPType
        } = this.state

        const User = this.props.User
        const isAdmin = User?.isAdmin ? User.isAdmin : false
        const Level = User?.Level ? User.Level : ''

        const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }

        const MPPPR = this.props.MPPPR
        const FilteredMPPPR = MPPPR ? MPPPR.filter((item, index) => (
            (isAdmin || isLevelQualified(Level, item?.LevelId ? item.LevelId : ''))
            && (item.Jenis === MPPType))) : []
        const isMPPPRLoading = this.props.isMPPPRLoading
        const isMPPActionGetDataLoading = this.props.isMPPActionGetDataLoading
        // console.log(FilteredMPPPR);
        // console.log(MPPType);
        const FormSelection = () => {
            return (
                <Fragment>
                    {isMPPPRLoading ?
                        <Fragment>
                            <Skeleton
                                height={'10vh'}
                            />
                        </Fragment> :
                        <Fragment>
                            <FormControl
                                style={st_textfield}
                                variant="filled"
                                required
                            >
                                <InputLabel
                                    shrink
                                >
                                    MPP Type
                                </InputLabel>
                                <Select
                                    native
                                    style={{ backgroundColor: 'white' }}
                                    onChange={(e) => this.SelectMPPTypeOnChange(e)}
                                    label="MPP Type"
                                    name='MPP'
                                    value={MPPType}
                                >
                                    <option value="" disabled> -- select an option -- </option>
                                    <option value="Yearly">Yearly</option>
                                    <option value="Additional">Additional</option>
                                    <option value="Replacement">Replacement</option>
                                </Select>
                            </FormControl>
                            <FormControl
                                style={st_textfield}
                                variant="filled"
                                required
                            >
                                <InputLabel
                                    shrink
                                >
                                    MPP
                                </InputLabel>
                                <Select
                                    native
                                    style={{ backgroundColor: 'white' }}
                                    // onChange={(e) => this.SelectFormOnChange(e)}
                                    onChange={(e) => this.SelectMPPFormOnChange(e)}
                                    label="MPP"
                                    name='MPP'
                                    value={MPP}
                                >
                                    <option value="" disabled> -- select an option -- </option>
                                    {FilteredMPPPR.map((option, index) =>
                                        <option key={`MPPPR_${index}`} value={option.MPPListId}>{`(${option.Id}) \t - \t ${option.Tahun} \t - \t ${option.DepNama} \t - \t ${option.LevNama} \t ${option.Sub} \t - \t ${new Date(2022, option.BulanBuka, 1).toLocaleString('default', { month: 'long' })} \t - \t ${option.StatusKaryawan} \t : \t MPP Approved: ${option.Jumlah} | Pending Proposed: ${option.Jumlah - option.JumlahTertahan - option.JumlahSelesai} | Active: ${option.JumlahSelesai}`}</option>
                                        // <option key={`MPPPR_${index}`} value={option.Id}>{`(${option.Id}) \t - \t ${option.Tahun} \t ${option.LevNama} \t ${option.Sub} \t ${option.BulanBuka} \t ${option.StatusKaryawan} \t ${option.Jumlah}`}</option>
                                    )}
                                </Select>
                            </FormControl>
                        </Fragment>}
                </Fragment>
            )
        }

        return (
            <Fragment>
                <Stepper activeStep={StepValue}>
                    {steps.map((label, index) => {
                        const stepProps = {}
                        const labelProps = {}
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        )
                    })}
                </Stepper>
                <Paper
                    style={{ padding: '5%' }}
                >

                    {StepValue === 0 ?
                        <FormSelection /> :
                        <PRForm MPPSelectId={this.state.MPPId} MPPDetail={this.state.MPPDetail} />

                    }
                </Paper>

                <center>
                    <Button
                        variant='contained'
                        size='large'
                        color="error"
                        disabled={StepValue === 0 || isMPPActionGetDataLoading ? true : false}
                        onClick={this.handleBack}
                        style={{ margin: '5% 10% 5% 10%', width: '10vw' }}
                    >
                        Back
                    </Button> <Button
                        variant='contained'
                        size='large'
                        disabled={
                            StepValue === 0 ?
                                isMPPPRLoading || !this.state.MPP ?
                                    true
                                    : false
                                : StepValue === this.state.MaxStep - 1 ?
                                    true
                                    : false
                        }
                        onClick={this.handleNext}
                        style={{ margin: '5% 10% 5% 10%', width: '10vw' }}
                    >
                        Next
                    </Button>
                </center>
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    User: state.Auth.User,
    isMPPActionGetDataLoading: state.MPP.isMPPActionGetDataLoading,
    isMPPPRLoading: state.MPP.isMPPPRLoading,
    MPPPR: state.MPP.MPPPR,
})

export default connect(mapStateToProps, { get_MPP_PR })(PengajuanPR)