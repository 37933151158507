import React, { Fragment } from "react"

import { connect } from 'react-redux'
import { Get_Job_List } from '../../../../Store/Actions/Job.Action'
import { Load_Department_List, Load_Lokasi_List } from '../../../../Store/Actions/Setting.Action'

import { Skeleton, Grid, Paper, Button, FormControl, Select, TextField, InputAdornment, Collapse } from '@mui/material'

import { Redirect } from 'react-router-dom'

// import MyVacancyImage from '../../../../Img/JobList/MyVacancy.png'
import MyVacancyImage from '../../../../Img/JobList/MyVacancy_e.png'

import SearchIcon from '@mui/icons-material/Search'
// import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
// import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'

class JobList extends React.Component {
    state = {
        UrlId: '',
        SearchKeyword: '',
        SearchLocation: '',
        SearchDep: [],
        isGoToJobDetail: false,
        JobListData: [],
        isShowSearch: false,
    }

    componentDidMount() {
        this.props.Get_Job_List()
        this.props.Load_Department_List()
        this.props.Load_Lokasi_List()
        // const JobListData = this.props.JobListData
        // if (JobListData) {
        //     this.setState({ JobListData: JobListData })
        // }
    }

    componentDidUpdate(prevProps) {
        if (this.props.JobListData !== prevProps.JobListData) {
            const { JobListData } = this.props
            this.setState({ JobListData: JobListData })
        }
    }

    ShowSearch = (e) => {
        this.setState({ isShowSearch: !this.state.isShowSearch })
        if (this.state.isShowSearch === true) {
            this.Reset_Filter()
        }
    }

    FilterData = (TextSearch, LocationSearch, DepartmentSearch) => {
        const { JobListData } = this.props

        const TextJobListData = TextSearch ? JobListData.filter((item) => String(item.Nama).toLocaleLowerCase().search(String(TextSearch).toLocaleLowerCase()) >= 0 ? true : false) : JobListData
        const LocationListData = LocationSearch ? LocationSearch === 'All' ? JobListData : JobListData.filter((item) => String(item.LokasiNama).toLocaleLowerCase().search(String(LocationSearch).toLocaleLowerCase()) >= 0 ? true : false) : JobListData
        const DepartmentListData = DepartmentSearch && DepartmentSearch.length > 0 ? JobListData.filter((item) => DepartmentSearch.includes(item.DepartemenNama) ? true : false) : JobListData

        const newJobListData = [
            TextJobListData,
            LocationListData,
            DepartmentListData
        ].reduce((p, c) => p.filter(e => c.includes(e)))

        this.setState({ JobListData: newJobListData })
    }

    onChange_Search = (e) => {
        this.setState({ SearchKeyword: e.target.value })
        this.FilterData(e.target.value, this.state.SearchLocation, this.state.SearchDep)
    }
    onChange_Search_Select = (e) => {
        this.setState({ SearchLocation: e.target.value })
        this.FilterData(this.state.SearchKeyword, e.target.value, this.state.SearchDep)
    }

    Reset_Filter = (e) => {
        this.setState({
            SearchKeyword: '',
            SearchLocation: '',
            SearchDep: []
        })
        this.FilterData('', '', [])
    }


    onChange_Search_Department = (DepNama) => {
        const { SearchDep } = this.state
        const IndexItem = SearchDep.findIndex((v) => v === DepNama)
        if (IndexItem >= 0) {
            SearchDep.splice(IndexItem, 1)
            this.FilterData(this.state.SearchKeyword, this.state.SearchLocation, SearchDep)
        } else {
            SearchDep.push(DepNama)
            this.FilterData(this.state.SearchKeyword, this.state.SearchLocation, SearchDep)
        }
        // this.setState({ SearchLocation: e.target.value })
        // this.FilterData(this.state.SearchKeyword, e.target.value, this.state.SearchDep)
    }

    goToJobDetail = (Id) => {
        this.setState({ UrlId: Id })
        this.setState({ isGoToJobDetail: true })
    }

    render() {
        // const JobListData = this.props.JobListData
        const isJobListLoading = this.props.isJobListLoading
        const DepartmentList = this.props.DepartmentList
        const isDepartmentLoading = this.props.isDepartmentLoading
        const isLokasiLoading = this.props.isLokasiLoading
        const LokasiList = this.props.LokasiList
        const UsedLokasiList = LokasiList ? LokasiList.filter((item, index) => (
            item.Id !== "7039"
        )) : []

        const {
            UrlId,
            SearchKeyword,
            SearchLocation,
            SearchDep,
            isGoToJobDetail,
            JobListData,
            isShowSearch
        } = this.state

        const BodyData = JobListData ? JobListData : []

        return (
            <Fragment>
                {isGoToJobDetail ? <Redirect to={`/Jobdetail/${UrlId}`} /> : <div></div>}
                {isJobListLoading || isDepartmentLoading || isLokasiLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5, 6].map((item) =>
                            <Skeleton
                                key={`Edit_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-end"
                            style={{ border: '0px solid red', height: '85vh' }}
                        >
                            <div
                                style={{ border: '0px solid red', width: '40vw', margin: '0 2vw 0 0', }}
                            >
                                <img
                                    style={{ border: '0px solid red', width: '100%', margin: '0 0 -1vh 0', zIndex: '1' }}
                                    alt="MyVacancyImage"
                                    src={MyVacancyImage}
                                />
                            </div>

                            <div
                                style={{ border: '0px solid red', width: '40vw', margin: '0 0 35vh 0', color: '#03045e', fontFamily: 'Montserrat, Segoe UI', }}
                            >
                                <h1
                                    style={{ fontSize: '2.5vw' }}
                                >
                                    My Vacancy
                                </h1>
                                <span
                                    style={{ color: 'black', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                                >
                                    Start finding your purpose with Etana. <br />
                                    See our latest vacancies below
                                </span>
                                <div
                                    style={{ border: '2px solid #e8e8e8', borderRadius: '10px', padding: '1% 5% 1% 5%', width: '40%', marginTop: '10%', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                                >
                                    <span
                                        style={{ color: 'black' }}
                                    >
                                        Career
                                    </span>
                                    <span
                                        style={{ color: '#5bcae3' }}
                                    >
                                        <b>{`          >  Jobs`}</b>
                                    </span>
                                </div>

                            </div>
                        </Grid>

                        <div
                            style={{ height: isShowSearch ? 'auto' : '15vh', border: '0px solid red', padding: '1vh 1vw 1vh 1vw', backgroundImage: 'linear-gradient(to Left, #90e0ef, #00a3df', }}
                        >
                            {isShowSearch ?
                                <div
                                    style={{ color: 'white', fontFamily: 'Montserrat, Segoe UI', cursor: 'pointer' }}
                                    onClick={this.ShowSearch}
                                >
                                    <center>
                                        <h3
                                            style={{ fontSize: '2vw' }}
                                        >
                                            Click Here to Show All Vacancy
                                        </h3>
                                    </center>
                                </div> :
                                <div
                                    style={{ color: 'white', fontFamily: 'Montserrat, Segoe UI', cursor: 'pointer' }}
                                    onClick={this.ShowSearch}
                                >
                                    <center>
                                        <h1
                                            style={{ fontSize: '2.5vw' }}
                                        >
                                            Click Here to Filter Vacancy
                                        </h1>
                                    </center>
                                </div>
                            }
                            <Collapse in={isShowSearch} timeout="auto" unmountOnExit>
                                <div
                                    style={{ border: '0px solid red', margin: '1vh 15vw 0vh 15vw' }}
                                >

                                    <FormControl
                                        style={{ width: '15vw', border: '0px solid white', }}
                                    >

                                        <Select
                                            variant="standard"
                                            native
                                            disableUnderline
                                            style={{ border: '3px solid white', borderRadius: '10px', height: '8vh', color: 'white', padding: "5%", fontSize: '1vw' }}
                                            onChange={(e) => this.onChange_Search_Select(e)}
                                            name='SearchLocation'
                                            value={SearchLocation}
                                        >
                                            <option value="All" style={{ color: 'black' }}> All Location </option>
                                            {UsedLokasiList.map((option, index) =>
                                                <option key={`UsedLokasiList_${index}`} style={{ color: 'black' }} value={option.Nama}>{option.Nama}</option>
                                            )}
                                        </Select>
                                    </FormControl>

                                    <TextField
                                        placeholder="Type Your Keyword"
                                        // variant="outlined"
                                        variant="standard"
                                        size="small"
                                        style={{ border: '0px solid red', borderRadius: '10px', color: 'white', height: '8vh', width: '36vw', margin: '-1vh 4vw 0 1vw', padding: '1vh 0 1vh 0', }}
                                        value={SearchKeyword}
                                        onChange={this.onChange_Search}
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment position="start" style={{ color: 'white', fontSize: '2vw' }}>
                                                    <SearchIcon />
                                                </InputAdornment>,
                                            disableUnderline: true,
                                            style: { color: 'white', border: '3px solid White', borderRadius: '10px', height: '100%', padding: '0 0 0 5%', fontSize: '1.2vw' }
                                        }}
                                    />
                                    <Button
                                        style={{ border: '0px solid white', borderRadius: '10px', height: '7vh', width: '10vw', textTransform: 'none', color: 'white', backgroundColor: '#00a3df', boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                                    >
                                        <b>
                                            Search
                                        </b>
                                    </Button>
                                </div>
                                <div
                                    style={{ border: '0px solid red', margin: '1vh 10vw 5vh 10vw' }}
                                >
                                    {DepartmentList.map((item, index) =>
                                        <Button
                                            key={`DepartmentList_${index}`}
                                            // style={{ minWidth: '10vw', backgroundColor: 'white', borderRadius: '10px', margin: '1vh 1vw 1vh 1vw', color: 'black', textTransform: 'None', }}
                                            style={{ minWidth: '10vw', maxWidth: '10vw', minHeight: '8vh', maxHeight: '8vh', backgroundColor: SearchDep.includes(item.Nama) ? '#00a3df' : 'white', borderRadius: '10px', margin: '1vh 1vw 1vh 1vw', color: SearchDep.includes(item.Nama) ? 'white' : 'black', textTransform: 'None', fontFamily: 'Montserrat, Segoe UI', fontSize: item.Nama.length > 40 ? '0.7vw' : item.Nama.length > 30 ? '0.8vw' : item.Nama.length > 20 ? '0.8vw' : '1vw', overflow: 'none', boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px' }}
                                            onClick={(e) => this.onChange_Search_Department(item.Nama)}
                                        >
                                            {item.Nama}
                                        </Button>
                                    )}
                                </div>
                            </Collapse>
                        </div>

                        <div
                            style={{ margin: '8vh 15vw 5vh 15vw', fontFamily: 'Montserrat, Segoe UI' }}
                        >
                            <h1
                                style={{ color: '#03045e', fontSize: '2.5vw' }}
                            >
                                {/* Department Name */}
                                Available Vacancy
                            </h1>
                            {/* Department Description */}
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-start"
                                style={{ border: '0px solid blue', margin: '5vh 0 20vh -7vw', width: '85vw' }}
                            >
                                {BodyData.length > 0 ? BodyData.map((item, index) =>
                                    <Fragment
                                        key={`Paper_Home_BodyData_${index}`}
                                    >
                                        <Paper
                                            style={{ border: '0px solid red', width: '20vw', minHeight: '30vh', padding: '1vh 2vw 5vh 2vw', margin: '5vh 2vw 5vh 2vw', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px', borderRadius: '10px' }}
                                        >
                                            {/* <h2>{JSON.stringify(item)}</h2> */}
                                            <div
                                                style={{ display: 'flex', alignContent: 'flex-start', justifyContent: 'flex-start', border: '0px solid red', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                                            >
                                                <h3>{item.Nama}</h3>
                                            </div>
                                            <div
                                                style={{ height: '50%', display: 'flex', alignContent: 'flex-start', justifyContent: 'flex-start', border: '0px solid red', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                                            >
                                                <div
                                                    style={{ width: '60%', marginRight: '10%', marginLeft: '1vw', border: '0px solid blue', color: '#00b4d9' }}
                                                >
                                                    <h5>{item.DepartemenNama}</h5>
                                                </div>
                                                <div
                                                    style={{ color: '#00b4d9' }}
                                                >
                                                    <h5>{item.LokasiNama}</h5>
                                                </div>
                                            </div>
                                            <center>
                                                <Button
                                                    size="small"
                                                    style={{ border: '0px solid red', width: '90%', borderRadius: '10px', textTransform: 'none', color: 'white', backgroundImage: 'linear-gradient(to Left, #90e0ef, #00a3df', fontFamily: 'Montserrat, Segoe UI', fontSize: '1vw' }}
                                                    onClick={() => this.goToJobDetail(item.JobId)}
                                                >
                                                    <b>
                                                        See Details
                                                    </b>
                                                </Button>
                                            </center>
                                        </Paper>
                                    </Fragment>
                                ) :
                                    <center>
                                        <h1
                                            style={{ color: '#03045e', fontSize: '2.5vw' }}
                                        >
                                            No Vacancies Open
                                        </h1>
                                    </center>
                                }
                            </Grid>
                        </div>
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isLokasiLoading: state.Setting.isLokasiLoading,
    LokasiList: state.Setting.LokasiList,
    DepartmentList: state.Setting.DepartmentList,
    isDepartmentLoading: state.Setting.isDepartmentLoading,
    JobListData: state.Job.JobList,
    isJobListLoading: state.Job.isJobListLoading,
})

export default connect(mapStateToProps, { Get_Job_List, Load_Department_List, Load_Lokasi_List })(JobList)