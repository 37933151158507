import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_List_MCU, get_Job_Action_Id } from '../../../../Store/Actions/Job.Action'
import { get_Account_Action_Id } from '../../../../Store/Actions/Account.Action'

import { Collapse } from '@mui/material'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import GenericModals from '../../../Containers/GenericModals'
import CoTable from '../../../Containers/Table'
import Assign from './Modal/MCU/Assign'
import SubmitMCU from './Modal/MCU/SubmitMCU'
import DetailMCU from './Modal/MCU/DetailMCU'

class MCU extends React.Component {
    state = {
        AssignOpen: true,
        MenungguOpen: true,
        SelesaiOpen: false,
    }

    componentDidMount() {
        this.props.get_List_MCU()
    }

    handleAssignOpen = () => {
        this.setState({ AssignOpen: !this.state.AssignOpen })
    }

    handleMenungguOpen = () => {
        this.setState({ MenungguOpen: !this.state.MenungguOpen })
    }

    handleSelesaiOpen = () => {
        this.setState({ SelesaiOpen: !this.state.SelesaiOpen })
    }

    onClickActionId = (JobId, AccountId) => {
        this.props.get_Job_Action_Id(JobId)
        this.props.get_Account_Action_Id(AccountId)
    }

    render() {
        const isListMCULoading = this.props.isListMCULoading
        const ListMCU = this.props.ListMCU

        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'No Registrasi',
                width: '10%',
            },
            {
                Nama: 'Nama',
                width: '10%',
            },
            {
                Nama: 'Posisi',
                width: '10%',
            },
            {
                Nama: 'Submit',
                width: '10%',
            },
        ]

        const FilteredList = ListMCU ? ListMCU.filter((item, index) => (
            item.McId === null
        )) : []

        const BodyData = FilteredList.map((item, index) => {
            return {
                index: index + 1,
                Noreg: item.Id,
                Nama: item.BiodataNama,
                Posisi: item.JobListNama,
                Detail: (
                    <GenericModals
                        size='s'
                        Buttonvariant='contained'
                        header='Submit'
                        body={<Assign />}
                        Buttoncolor='primary'
                        ButtononClickeven={() => this.onClickActionId(item.JobListId, item.AccountId)}
                        Buttonlabel={'Submit'}
                    />
                )
            }
        })

        const HeadData2 = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'No Registrasi',
                width: '10%',
            },
            {
                Nama: 'Nama',
                width: '10%',
            },
            {
                Nama: 'Posisi',
                width: '10%',
            },
            {
                Nama: 'MCU Date',
                width: '10%',
            },
            {
                Nama: 'Submit',
                width: '10%',
            },
        ]

        const FilteredList2 = ListMCU ? ListMCU.filter((item, index) => (
            item.AppDate !== null && item.ResultStatus === null
        )) : []

        const BodyData2 = FilteredList2.map((item, index) => {
            return {
                index: index + 1,
                Noreg: item.Id,
                Nama: item.BiodataNama,
                Posisi: item.JobListNama,
                Date: new Date(item.AppDate).toLocaleDateString(),
                Detail: (
                    <GenericModals
                        size='s'
                        Buttonvariant='contained'
                        header='Submit'
                        body={<SubmitMCU />}
                        Buttoncolor='primary'
                        ButtononClickeven={() => this.onClickActionId(item.JobListId, item.AccountId)}
                        Buttonlabel={'Submit'}
                    />
                )
            }
        })

        const HeadData3 = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'No Registrasi',
                width: '10%',
            },
            {
                Nama: 'Nama',
                width: '10%',
            },
            {
                Nama: 'Posisi',
                width: '10%',
            },
            {
                Nama: 'Status',
                width: '10%',
            },
            {
                Nama: 'Submit',
                width: '10%',
            },
        ]

        const FilteredList3 = ListMCU ? ListMCU.filter((item, index) => (
            item.McId !== null
        )) : []

        const BodyData3 = FilteredList3.map((item, index) => {
            return {
                index: index + 1,
                Noreg: item.Id,
                Nama: item.BiodataNama,
                Posisi: item.JobListNama,
                Status: (
                    <span
                    // style={{ color: item?.McStatus ? item.McStatus === 'Accept' ? 'green' : 'red' : 'black' }}
                    >
                        {item ? item.AppDate !== null && item.ResultStatus === null ? 'Menunggu MCU'
                            : item.AppDate !== null && item.ResultStatus !== null ? item.ResultStatus
                                : '' : ''}
                    </span>
                ),
                Detail: (
                    <GenericModals
                        size='l'
                        header='Detail'
                        body={<DetailMCU />}
                        Buttoncolor='primary'
                        ButtononClickeven={() => this.onClickActionId(item.JobListId, item.AccountId)}
                        Buttonlabel={'Detail'}
                    />
                )
            }
        })

        const {
            AssignOpen,
            MenungguOpen,
            SelesaiOpen,
        } = this.state

        const RowDataInOnePage = 5

        return (
            <Fragment>
                <h2>
                    MCU
                </h2>
                <h3
                    onClick={this.handleAssignOpen}
                    style={{ display: 'flex', alignContent: 'center', verticalAlign: 'center', cursor: 'pointer' }}
                >
                    Menunggu Jadwal{AssignOpen ? <ExpandLess /> : <ExpandMore />}
                </h3>
                <Collapse in={AssignOpen} timeout="auto" unmountOnExit>
                    <CoTable
                        TableId={'MCU_Jadwal'}
                        HeadData={HeadData}
                        Data={BodyData}
                        isHeadLoading={isListMCULoading}
                        isBodyLoading={isListMCULoading}
                        isPagination={BodyData.length > RowDataInOnePage ? true : false}
                        MaxRowOnPage={RowDataInOnePage}
                        isPaginationLoading={isListMCULoading}
                        PaginationCounter={
                            BodyData.length / RowDataInOnePage > Math.floor(BodyData.length / RowDataInOnePage) ?
                                Math.floor(BodyData.length / RowDataInOnePage) + 1 :
                                BodyData.length / RowDataInOnePage
                        }
                    />
                </Collapse>
                <h3
                    onClick={this.handleMenungguOpen}
                    style={{ display: 'flex', alignContent: 'center', verticalAlign: 'center', cursor: 'pointer' }}
                >
                    Menunggu Upload{MenungguOpen ? <ExpandLess /> : <ExpandMore />}
                </h3>
                <Collapse in={MenungguOpen} timeout="auto" unmountOnExit>
                    <CoTable
                        TableId={'MCU_Menunggu'}
                        HeadData={HeadData2}
                        Data={BodyData2}
                        isHeadLoading={isListMCULoading}
                        isBodyLoading={isListMCULoading}
                        isPagination={BodyData2.length > RowDataInOnePage ? true : false}
                        MaxRowOnPage={RowDataInOnePage}
                        isPaginationLoading={isListMCULoading}
                        PaginationCounter={
                            BodyData2.length / RowDataInOnePage > Math.floor(BodyData2.length / RowDataInOnePage) ?
                                Math.floor(BodyData2.length / RowDataInOnePage) + 1 :
                                BodyData2.length / RowDataInOnePage
                        }
                    />
                </Collapse>
                <div
                    style={{ margin: '0 0 5% 0' }}
                >
                    <h3
                        onClick={this.handleSelesaiOpen}
                        style={{ display: 'flex', alignContent: 'center', verticalAlign: 'center', cursor: 'pointer' }}
                    >
                        Selesai{SelesaiOpen ? <ExpandLess /> : <ExpandMore />}
                    </h3>
                    <Collapse in={SelesaiOpen} timeout="auto" unmountOnExit>
                        <CoTable
                            TableId={'MCU_Selesai'}
                            HeadData={HeadData3}
                            Data={BodyData3}
                            isHeadLoading={isListMCULoading}
                            isBodyLoading={isListMCULoading}
                            isPagination={BodyData3.length > RowDataInOnePage ? true : false}
                            MaxRowOnPage={RowDataInOnePage}
                            isPaginationLoading={isListMCULoading}
                            PaginationCounter={
                                BodyData3.length / RowDataInOnePage > Math.floor(BodyData3.length / RowDataInOnePage) ?
                                    Math.floor(BodyData3.length / RowDataInOnePage) + 1 :
                                    BodyData3.length / RowDataInOnePage
                            }
                        />
                    </Collapse>
                </div>
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    isListMCULoading: state.Job.isListMCULoading,
    ListMCU: state.Job.ListMCU,
})

export default connect(mapStateToProps, { get_List_MCU, get_Job_Action_Id, get_Account_Action_Id })(MCU)