import React, { Fragment, useEffect, useState } from 'react'
import { Button, Skeleton, TextField } from '@mui/material'
import axios from 'axios'
import { Get_IpAddres } from '../../../../../Store/Actions/Base.Action';

const SettingEditDetail = ({ Data, isLoading = false, ...props }) => {
    const [data, setData] = useState(Data);
    const [isEditing, setEditing] = useState(false);

    useEffect(() => {
        setData(Data)
    }, [Data])

    const handleEdit = () => {
        setEditing(true);
    };

    const handleSave = () => {
        setEditing(false);
        const updateDivision = async () => {
            const token = localStorage.getItem('ERecEtana_token');
            if (token) {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "x-auth-token": token
                    }
                };
                try {
                    const response = await axios.post(
                        Get_IpAddres() + '/setting/division/edit/' + data.Id,
                        {
                            NamaDivision: data.Nama,
                            Deskripsi: data.Deskripsi,
                        },
                        config
                    );
                    if (response.status === 200) {
                        window.location.reload()
                    }
                } catch (error) {
                    console.error(error);
                }
            } else {
            }
        }
        updateDivision()
    };

    const handleInputChange = (e, field) => {
        const newData = { ...data, [field]: e.target.value };
        setData(newData);
    };

    const TextFieldStyle = { width: '95%', margin: '1%', backgroundColor: '#E0E0E0' };
    const TextFieldStyleChange = { width: '95%', margin: '1%' };

    return (
        <Fragment>
            {isLoading ?
                <Fragment>
                    <Skeleton height={'10vh'} />
                    <Skeleton height={'10vh'} />
                    <Skeleton height={'10vh'} />
                </Fragment> :
                <Fragment>
                    <h3>
                        Role Detail Edit
                    </h3>
                    <center>
                        <TextField
                            label='Id'
                            value={data?.Id ? data.Id : ''}
                            disabled
                            style={TextFieldStyle}
                        />
                        <TextField
                            label='Nama'
                            value={data?.Nama ? data.Nama : ''}
                            onChange={(e) => handleInputChange(e, 'Nama')}
                            style={isEditing ? TextFieldStyleChange : TextFieldStyle}
                            disabled={!isEditing}
                        />
                        <TextField
                            label='Deskripsi'
                            value={data?.Deskripsi ? data.Deskripsi : ''}
                            onChange={(e) => handleInputChange(e, 'Deskripsi')}
                            style={isEditing ? TextFieldStyleChange : TextFieldStyle}
                            disabled={!isEditing}
                        />
                        {isEditing ? (
                            <Button onClick={handleSave}>Save</Button>
                        ) : (
                            <Button onClick={handleEdit}>Edit</Button>
                        )}
                    </center>
                </Fragment>
            }
        </Fragment>
    )
}

export default SettingEditDetail
