import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Load_Account_List_Setting } from '../../../../Store/Actions/Setting.Action'
import { get_Account_Action_Id } from '../../../../Store/Actions/Account.Action'

// import { Button, TextField, InputAdornment, Collapse, Paper } from '@mui/material'

// import ExpandLess from '@mui/icons-material/ExpandLess'
// import ExpandMore from '@mui/icons-material/ExpandMore'
// import SearchIcon from '@mui/icons-material/Search'

import GenericModals from '../../../Containers/GenericModals'
import CoTable from '../../../Containers/Table'

import CreateUser from './Modal/User/Add'
import ViewUser from './Modal/User/View'
import EditUser from './Modal/User/Edit'
import DeleteUser from './Modal/User/Delete'

class SettingUser extends React.Component {
    state = {
        isSearchOpen: false,
        Search: '',
        Nama: '',
        Registrasi: '',
        UmurMin: '',
        UmurMax: '',
        Pendidikan: '',
        Lokasi: '',
        Instansi: '',
        Jurusan: '',
        MinPengalaman: 0,
    }

    componentDidMount() {
        this.props.Load_Account_List_Setting()
    }

    onClickActionId = (AccountId) => {
        this.props.get_Account_Action_Id(AccountId)
    }

    handleDiajukanOpen = () => {
        this.setState({ isDiajukanOpen: !this.state.isDiajukanOpen })
    }

    handleSearchOpen = () => {
        this.setState({ isSearchOpen: !this.state.isSearchOpen })
    }

    handlePendaftarOpen = () => {
        this.setState({ isPendaftarOpen: !this.state.isPendaftarOpen })
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    render() {
        const isAccountLoading = this.props.isAccountLoading
        const AccountList = this.props.AccountList

        const HeadData = [
            {
                Nama: 'Index',
                width: '5%',
            },
            {
                Nama: 'Id',
                width: '15%',
            },
            {
                Nama: 'Email',
                width: '10%',
            },
            {
                Nama: 'UserName',
                width: '10%',
            },
            {
                Nama: 'View',
                width: '10%',
            },
            {
                Nama: 'Edit',
                width: '10%',
            },
            {
                Nama: 'Delete',
                width: '10%',
            },
        ]

        const BodyData = AccountList ? AccountList.map((item, index) => {
            return {
                index: index + 1,
                Id: item.Id,
                Email: item.Email,
                UserName: item.UserName,
                View: (
                    <GenericModals
                        size='l'
                        Buttonvariant='outlined'
                        header='View User'
                        body={<ViewUser />}
                        Buttoncolor='primary'
                        ButtononClickeven={() => this.onClickActionId(item.Id)}
                        Buttonlabel={'View'}
                    />
                ),
                Edit: (
                    <GenericModals
                        size='l'
                        Buttonvariant='outlined'
                        header='Edit Biodata'
                        body={<EditUser />}
                        Buttoncolor='secondary'
                        ButtononClickeven={() => this.onClickActionId(item.Id)}
                        Buttonlabel={'Edit'}
                    />
                ),
                Delete: (
                    <GenericModals
                        size='l'
                        Buttonvariant='outlined'
                        header='Edit Biodata'
                        body={<DeleteUser />}
                        Buttoncolor='error'
                        ButtononClickeven={() => this.onClickActionId(item.Id)}
                        Buttonlabel={'Delete'}
                    />
                ),
            }
        }) : []

        // const {
        //     isSearchOpen,
        //     Search,
        //     Nama,
        //     Registrasi,
        //     UmurMin,
        //     UmurMax,
        //     Pendidikan,
        //     Lokasi,
        //     Instansi,
        //     Jurusan,
        //     MinPengalaman,
        // } = this.state

        const RowDataInOnePage = 10

        // const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }

        return (
            <Fragment>
                <h2>
                    User Setting
                </h2>

                <GenericModals
                    size='xl'
                    header='Tambah User'
                    body={<CreateUser />}
                    Buttoncolor='primary'
                    Buttonstyle={{ width: '90%', margin: '1%' }}
                    ButtononClickeven={() => null}
                    Buttonlabel={'+ Tambah User'}
                />

                {/* <div
                    style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}
                >
                    <TextField
                        variant='outlined'
                        type='text'
                        label='Cari Berdasarkan Nama atau Nomer Registrasi'
                        name='Search'
                        value={Search}
                        style={{ ...st_textfield, ...{ width: '80%' } }}
                        onChange={this.onChangeField}
                        InputProps={{
                            startAdornment:
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>,
                        }}
                    />

                    <Button
                        variant='contained'
                        onClick={this.handleSearchOpen}
                        style={{ width: '10%', margin: '0 0 0 5%', padding: '1%' }}
                    >
                        {isSearchOpen ? <ExpandLess /> : <ExpandMore />}
                    </Button>
                </div>

                <Collapse in={isSearchOpen} timeout="auto" unmountOnExit>
                    <Paper
                        style={{ padding: '1%', borderTop: '0px solid black' }}
                    >
                        <TextField
                            variant='outlined'
                            type='text'
                            label='Nama'
                            name='Nama'
                            value={Nama}
                            style={{ ...st_textfield }}
                            onChange={this.onChangeField}
                        />
                        <TextField
                            variant='outlined'
                            type='text'
                            label='Nomer Registrasi'
                            name='Registrasi'
                            value={Registrasi}
                            style={{ ...st_textfield }}
                            onChange={this.onChangeField}
                        />
                        <TextField
                            variant='outlined'
                            type='text'
                            label='Umur Minimal'
                            name='UmurMin'
                            value={UmurMin}
                            style={{ ...st_textfield, ...{ width: '40%' } }}
                            onChange={this.onChangeField}
                        />
                        <TextField
                            variant='outlined'
                            type='text'
                            label='Umur Maksimal'
                            name='UmurMax'
                            value={UmurMax}
                            style={{ ...st_textfield, ...{ width: '40%', marginLeft: '5%' } }}
                            onChange={this.onChangeField}
                        />
                        <TextField
                            variant='outlined'
                            type='text'
                            label='Pendidikan Minimal'
                            name='Pendidikan'
                            value={Pendidikan}
                            style={{ ...st_textfield }}
                            onChange={this.onChangeField}
                        />
                        <TextField
                            variant='outlined'
                            type='text'
                            label='Lokasi'
                            name='Lokasi'
                            value={Lokasi}
                            style={{ ...st_textfield }}
                            onChange={this.onChangeField}
                        />
                        <TextField
                            variant='outlined'
                            type='text'
                            label='Instansi'
                            name='Instansi'
                            value={Instansi}
                            style={{ ...st_textfield }}
                            onChange={this.onChangeField}
                        />
                        <TextField
                            variant='outlined'
                            type='text'
                            label='Jurusan'
                            name='Jurusan'
                            value={Jurusan}
                            style={{ ...st_textfield }}
                            onChange={this.onChangeField}
                        />
                        <TextField
                            variant='outlined'
                            type='text'
                            label='Minimal Pengalaman (Tahun)'
                            name='MinPengalaman'
                            value={MinPengalaman}
                            style={{ ...st_textfield }}
                            onChange={this.onChangeField}
                        />
                        <center>
                            <Button
                                variant='contained'
                                style={{ width: '60%', margin: '1% 0 3% 0' }}
                            >
                                <SearchIcon /> Search
                            </Button>
                        </center>
                    </Paper>
                </Collapse>

                {!isSearchOpen ?
                    <center>
                        <Button
                            variant='contained'
                            style={{ width: '60%', margin: '1% 0 3% 0' }}
                        >
                            <SearchIcon /> Search
                        </Button>
                    </center>
                    : null} */}

                <CoTable
                    TableId={'RoleSetting_Account'}
                    HeadData={HeadData}
                    Data={BodyData}
                    isHeadLoading={isAccountLoading}
                    isBodyLoading={isAccountLoading}
                    isPagination={BodyData.length > RowDataInOnePage ? true : false}
                    MaxRowOnPage={RowDataInOnePage}
                    isPaginationLoading={isAccountLoading}
                    PaginationCounter={
                        BodyData.length / RowDataInOnePage > Math.floor(BodyData.length / RowDataInOnePage) ?
                            Math.floor(BodyData.length / RowDataInOnePage) + 1 :
                            BodyData.length / RowDataInOnePage
                    }
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isAccountLoading: state.Setting.isAccountLoading,
    AccountList: state.Setting.AccountList,
})

export default connect(mapStateToProps, { Load_Account_List_Setting, get_Account_Action_Id })(SettingUser)