import axios from 'axios'

import {
    AUTH_RELOADE_PAGE,
    //* MPP
    MPP_USER_MPP_LOADING,
    MPP_USER_MPP_LOADED,
    MPP_USER_MPP_LOADING_DONE,
    MPP_ADD_LOADING,
    MPP_ADD_LOADING_DONE,
    MPP_ACTION_GET_ID,
    MPP_ACTION_GET_DATA_LOADING,
    MPP_ACTION_GET_DATA_LOADED,
    MPP_ACTION_GET_DATA_LOADING_DONE,
    MPP_GET_UNCONFIRM_LIST_LOADING,
    MPP_GET_UNCONFIRM_LIST_LOADED,
    MPP_GET_UNCONFIRM_LIST_LOADING_DONE,
    MPP_GET_CONFIRM_LIST_LOADING,
    MPP_GET_CONFIRM_LIST_LOADED,
    MPP_GET_CONFIRM_LIST_LOADING_DONE,
    MPP_KONFIRM_LOADING,
    MPP_KONFIRM_LOADING_DONE,
    //* MPPPR
    MPP_GET_PR_LOADING,
    MPP_GET_PR_LOADED,
    MPP_GET_PR_LOADING_DONE,
    //* MPPJOBDESK
    MPP_GET_JOBDESK_LOADING,
    MPP_GET_JOBDESK_LOADED,
    MPP_GET_JOBDESK_LOADING_DONE,
    //* MPPVIEW
    MPP_GET_VIEW_ALL_LOADING,
    MPP_GET_VIEW_ALL_LOADED,
    MPP_GET_VIEW_ALL_LOADING_DONE,
    MPP_GET_VIEW_IMPLEMENTASI_LOADING,
    MPP_GET_VIEW_IMPLEMENTASI_LOADED,
    MPP_GET_VIEW_IMPLEMENTASI_LOADING_DONE,
    MPP_GET_VIEW_ASLI_LOADING,
    MPP_GET_VIEW_ASLI_LOADED,
    MPP_GET_VIEW_ASLI_LOADING_DONE,
    MPP_GET_VIEW_LAINNYA_LOADING,
    MPP_GET_VIEW_LAINNYA_LOADED,
    MPP_GET_VIEW_LAINNYA_LOADING_DONE,
    MPP_ADD_ADDITIONAL_LOADING,
    MPP_ADD_ADDITIONAL_LOADING_DONE,
} from './Type.Action'

import {
    Create_Error_Messages,
    Create_Success_Messages
} from './Messages.Actions'

import { Get_IpAddres, tokenConfig, ErrorHandler } from './Base.Action'

import { Error_Messages_Standardization } from '../../Global'

export const get_MPP_User_Pengajuan = () => async (dispatch, getState) => {
    try {
        dispatch({ type: MPP_USER_MPP_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/mpp/user`, tokenConfig(getState))
        if (Data) {
            dispatch({
                type: MPP_USER_MPP_LOADED,
                payload: Data.data
            })
            dispatch({ type: MPP_USER_MPP_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: MPP_USER_MPP_LOADING_DONE })
        ErrorHandler(err, 'get_MPP_User_Pengajuan')
    }
}

export const Add_MPP = (DepartmentId, LevelId, Sub, Tahun, DetailJumlahMpp, Keterangan, Jenis, Reason) => async (dispatch, getState) => {
    try {
        dispatch({ type: MPP_ADD_LOADING })
        if (!LevelId || !Sub || !Tahun) {
            const ErrorResponse = Error_Messages_Standardization(400, 'Form Tidak Lengkap')
            dispatch(Create_Error_Messages(400, 'Form Tidak Lengkap'))
            throw ErrorResponse
        }
        const IpAddres = Get_IpAddres()

        const body = JSON.stringify({ DepartmentId, LevelId, Sub, Tahun, DetailJumlahMpp, Keterangan, Jenis, Reason })
        console.log(body);
        const Data = await axios.post(`${IpAddres}/mpp/add`, body, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({ type: MPP_ADD_LOADING_DONE })
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `MPP Berhasil dibuat`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: MPP_ADD_LOADING_DONE })
        ErrorHandler(err, 'Add_MPP')
    }
}

export const Add_Additional_MPP = (DepartmentId, LevelId, Sub, Tahun, DetailJumlahMpp, Keterangan, Jenis, Reason) => async (dispatch, getState) => {
    try {
        dispatch({ type: MPP_ADD_ADDITIONAL_LOADING })
        if (!LevelId || !Sub || !Tahun) {
            const ErrorResponse = Error_Messages_Standardization(400, 'Form Tidak Lengkap')
            dispatch(Create_Error_Messages(400, 'Form Tidak Lengkap'))
            throw ErrorResponse
        }
        const IpAddres = Get_IpAddres()

        const body = JSON.stringify({ DepartmentId, LevelId, Sub, Tahun, DetailJumlahMpp, Keterangan, Jenis, Reason })
        console.log(body);
        const Data = await axios.post(`${IpAddres}/mpp/addadditional`, body, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({ type: MPP_ADD_ADDITIONAL_LOADING_DONE })
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `MPP Berhasil dibuat`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: MPP_ADD_ADDITIONAL_LOADING_DONE })
        ErrorHandler(err, 'Add_MPP_Additional')
    }
}

// export const Add_MPP = (DepartmentId, LevelId, Sub, Tahun, DetailJumlahMpp, Keterangan, Jenis, Reason, Photo) => async (dispatch, getState) => {
//     try {
//         dispatch({ type: MPP_ADD_LOADING });
//         if (!LevelId || !Sub || !Tahun) {
//             const ErrorResponse = Error_Messages_Standardization(400, 'Form Tidak Lengkap');
//             dispatch(Create_Error_Messages(400, 'Form Tidak Lengkap'));
//             throw ErrorResponse;
//         }
//         const IpAddres = Get_IpAddres();

//         const formData = new FormData();
//         formData.append('DepartmentId', DepartmentId);
//         formData.append('LevelId', LevelId);
//         formData.append('Sub', Sub);
//         formData.append('Tahun', Tahun);
//         formData.append('DetailJumlahMpp', DetailJumlahMpp);
//         formData.append('Keterangan', Keterangan);
//         formData.append('Jenis', Jenis);
//         formData.append('Reason', Reason);
//         formData.append('Photo', Photo);

//         const Data = await axios.post(`${IpAddres}/mpp/add`, formData, tokenConfig(getState));

//         if (Data.status === 200) {
//             dispatch({ type: MPP_ADD_LOADING_DONE });
//             dispatch({ type: AUTH_RELOADE_PAGE });
//             dispatch(Create_Success_Messages(200, `MPP Berhasil dibuat`));
//         } else {
//             const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap');
//             throw ErrorResponse;
//         }
//     } catch (err) {
//         dispatch({ type: MPP_ADD_LOADING_DONE });
//         ErrorHandler(err, 'Add_MPP');
//     }
// }

export const Update_MPP = (Id, DepartmentId, LevelId, Sub, Tahun, DetailJumlahMpp, Keterangan) => async (dispatch, getState) => {
    try {
        dispatch({ type: MPP_ADD_LOADING })
        if (!LevelId || !Sub || !Tahun) {
            const ErrorResponse = Error_Messages_Standardization(400, 'Form Tidak Lengkap')
            dispatch(Create_Error_Messages(400, 'Form Tidak Lengkap'))
            throw ErrorResponse
        }
        const IpAddres = Get_IpAddres()

        const body = JSON.stringify({ Id, DepartmentId, LevelId, Sub, Tahun, DetailJumlahMpp, Keterangan })
        const Data = await axios.post(`${IpAddres}/mpp/update`, body, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({ type: MPP_ADD_LOADING_DONE })
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `MPP Berhasil dibuat`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: MPP_ADD_LOADING_DONE })
        ErrorHandler(err, 'Update_MPP')
    }
}

export const get_MPP_Action_Id = (Id) => (dispatch) => {
    dispatch({
        type: MPP_ACTION_GET_ID,
        payload: Id
    })
}

export const get_MPP_Data = (Id) => async (dispatch, getState) => {
    try {
        dispatch({ type: MPP_ACTION_GET_DATA_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/mpp/detail/${Id}`, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({
                type: MPP_ACTION_GET_DATA_LOADED,
                payload: Data.data
            })
            dispatch({ type: MPP_ACTION_GET_DATA_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: MPP_ACTION_GET_DATA_LOADING_DONE })
        ErrorHandler(err, 'get_MPP_Data')
    }
}

export const get_MPP_UnConfirm = () => async (dispatch, getState) => {
    try {
        dispatch({ type: MPP_GET_UNCONFIRM_LIST_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/mpp/unconfirm`, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({
                type: MPP_GET_UNCONFIRM_LIST_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: MPP_GET_UNCONFIRM_LIST_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: MPP_GET_UNCONFIRM_LIST_LOADING_DONE })
        ErrorHandler(err, 'get_MPP_UnConfirm')
    }
}

export const get_MPP_Confirm = () => async (dispatch, getState) => {
    try {
        dispatch({ type: MPP_GET_CONFIRM_LIST_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/mpp/confirm`, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({
                type: MPP_GET_CONFIRM_LIST_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: MPP_GET_CONFIRM_LIST_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: MPP_GET_CONFIRM_LIST_LOADING_DONE })
        ErrorHandler(err, 'get_MPP_Confirm')
    }
}

export const Konfirm_MPP = (MPPId, Action, Keterangan) => async (dispatch, getState) => {
    try {
        dispatch({ type: MPP_KONFIRM_LOADING })
        if (!MPPId || !Action) {
            const ErrorResponse = Error_Messages_Standardization(400, 'Form Tidak Lengkap')
            dispatch(Create_Error_Messages(400, 'Form Tidak Lengkap'))
            throw ErrorResponse
        }
        const isMenunggu = Action === 'Submitted' ? true : false

        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ MPPId, Action, Keterangan })
        const Data = isMenunggu ? { status: 200 } : await axios.post(`${IpAddres}/mpp/konfirm`, body, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({ type: MPP_KONFIRM_LOADING_DONE })
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `MPP Berhasil dikonfirm`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: MPP_KONFIRM_LOADING_DONE })
        ErrorHandler(err, 'Konfirm_MPP')
    }
}

export const Konfirm_Update_MPP = (MPPId, MPPListData, Action, Keterangan) => async (dispatch, getState) => {
    try {
        dispatch({ type: MPP_KONFIRM_LOADING })
        if (!MPPId || !Action) {
            const ErrorResponse = Error_Messages_Standardization(400, 'Form Tidak Lengkap')
            dispatch(Create_Error_Messages(400, 'Form Tidak Lengkap'))
            throw ErrorResponse
        }
        const isMenunggu = Action === 'Submitted' ? true : false

        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ MPPId, MPPListData, Action, Keterangan })
        const Data = isMenunggu ? { status: 200 } : await axios.post(`${IpAddres}/mpp/konfirmupdate`, body, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({ type: MPP_KONFIRM_LOADING_DONE })
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `MPP Berhasil dikonfirm`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: MPP_KONFIRM_LOADING_DONE })
        ErrorHandler(err, 'Konfirm_Update_MPP')
    }
}

//* MPPPR
export const get_MPP_PR = () => async (dispatch, getState) => {
    try {
        dispatch({ type: MPP_GET_PR_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/mpp/pr`, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({
                type: MPP_GET_PR_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: MPP_GET_PR_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: MPP_GET_PR_LOADING_DONE })
        ErrorHandler(err, 'get_MPP_PR')
    }
}

//* MPPJOBDESK
export const get_MPP_Jobdesk = () => async (dispatch, getState) => {
    try {
        dispatch({ type: MPP_GET_JOBDESK_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/mpp/jobdesk`, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({
                type: MPP_GET_JOBDESK_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: MPP_GET_JOBDESK_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: MPP_GET_JOBDESK_LOADING_DONE })
        ErrorHandler(err, 'get_MPP_Jobdesk')
    }
}

//* MPPVIEW

export const get_MPP_View_All = (Tahun) => async (dispatch, getState) => {
    try {
        dispatch({ type: MPP_GET_VIEW_ALL_LOADING })
        const IpAddres = Get_IpAddres()

        const body = JSON.stringify({ Tahun })
        const Data = await axios.post(`${IpAddres}/report/mpp/view/all`, body, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({
                type: MPP_GET_VIEW_ALL_LOADED,
                payload: Data.data.ProcessedData
            })
            dispatch({ type: MPP_GET_VIEW_ALL_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: MPP_GET_VIEW_ALL_LOADING_DONE })
        ErrorHandler(err, 'get_MPP_View_All')
    }
}

export const get_MPP_View_Implementasi = (Tahun) => async (dispatch, getState) => {
    try {
        dispatch({ type: MPP_GET_VIEW_IMPLEMENTASI_LOADING })
        const IpAddres = Get_IpAddres()

        const body = JSON.stringify({ Tahun })
        const Data = await axios.post(`${IpAddres}/report/mpp/view/implementasi`, body, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({
                type: MPP_GET_VIEW_IMPLEMENTASI_LOADED,
                payload: Data.data.ProcessedData
            })
            dispatch({ type: MPP_GET_VIEW_IMPLEMENTASI_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: MPP_GET_VIEW_IMPLEMENTASI_LOADING_DONE })
        ErrorHandler(err, 'get_MPP_View_Implementasi')
    }
}

export const get_MPP_View_Asli = () => async (dispatch, getState) => {
    try {
        dispatch({ type: MPP_GET_VIEW_ASLI_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/mpp/view/asli`, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({
                type: MPP_GET_VIEW_ASLI_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: MPP_GET_VIEW_ASLI_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: MPP_GET_VIEW_ASLI_LOADING_DONE })
        ErrorHandler(err, 'get_MPP_View_Asli')
    }
}

export const get_MPP_View_Lainnya = () => async (dispatch, getState) => {
    try {
        dispatch({ type: MPP_GET_VIEW_LAINNYA_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/mpp/view/lainnya`, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({
                type: MPP_GET_VIEW_LAINNYA_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: MPP_GET_VIEW_LAINNYA_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: MPP_GET_VIEW_LAINNYA_LOADING_DONE })
        ErrorHandler(err, 'get_MPP_View_Lainnya')
    }
}