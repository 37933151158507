import React, { Fragment } from "react"

import { connect } from 'react-redux'

import { get_List_Applications_Apply } from '../../../../Store/Actions/Job.Action'

import { Redirect } from 'react-router-dom'

import { Skeleton, Container, Select, MenuItem, List, ListItemButton, Divider, Grid, Button } from "@mui/material"

class JobApplications extends React.Component {
    state = {
        selected: 'Active',
        UrlId: '',
        isGoToJobDetail: false,
    }
    componentDidMount() {
        this.props.get_List_Applications_Apply()
    }

    // onClickActionId(Id) {
    //     this.props.get_Jobdesk_Action_Id(Id)
    // }

    handleChange = (e) => {
        this.setState({ selected: e.target.value })
    }

    goToApplyJob = (e, JobId) => {
        this.setState({ UrlId: JobId })
        this.setState({ isGoToJobDetail: true })
    }

    render() {
        const isListApplicationsApplyLoading = this.props.isListApplicationsApplyLoading
        const ListApplicationsApply = this.props.ListApplicationsApply

        const {
            selected,
            UrlId,
            isGoToJobDetail,
        } = this.state

        return (
            <Container>
                {isGoToJobDetail ? <Redirect to={`/Jobdetail/${UrlId}`} /> : <div></div>}
                {isListApplicationsApplyLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5,].map((item) =>
                            <Skeleton
                                key={`isListApplicationsApplyLoading_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <center>
                            <h1
                                style={{ fontFamily: 'Montserrat, Segoe UI', fontSize: '2vw' }}
                            >
                                Job Applications
                            </h1>
                        </center>
                        {false ?
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selected}
                                // label="selected"
                                // InputLabelProps={{ shrink: true }}
                                onChange={this.handleChange}
                                style={{ marginLeft: '90%' }}
                            >
                                <MenuItem value={'Active'}>Active</MenuItem>
                                <MenuItem value={'Inactive'}>Inactive</MenuItem>
                            </Select> :
                            <Fragment></Fragment>}
                        <List
                            style={{ margin: '1% 0 5% 0' }}
                        >
                            {ListApplicationsApply && ListApplicationsApply.length > 0 ? ListApplicationsApply.map((item, index) =>
                                <div
                                    key={`JobApplications${index}`}
                                >
                                    {/* {JSON.stringify(item)} */}
                                    <ListItemButton
                                        style={{ cursor: 'default' }}
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                        >
                                            <h2
                                                style={{ fontFamily: 'Montserrat, Segoe UI', fontSize: '1.5vw' }}
                                            >
                                                {item.JobListNama}
                                            </h2>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                style={{ fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                                            >
                                                <p>Registration Number : {item.Id} </p>
                                                <p
                                                    style={{ marginLeft: '5%' }}
                                                >
                                                    Date : {new Date(item.ApplyTime).toLocaleString(['ban', 'id'])}</p>
                                                <p
                                                    style={{ marginLeft: '5%' }}
                                                >
                                                    Status :
                                                    <span
                                                        style={{ color: item.StatusLamaran === 'Register' ? 'orange' : item.StatusLamaran === 'Diproses' ? 'green' : 'black' }}
                                                    >
                                                        {item.StatusLamaran}
                                                    </span>
                                                </p>
                                            </Grid>
                                        </Grid>
                                        {false ?
                                            <Button
                                                variant="contained"
                                            >
                                                Submit
                                            </Button> :
                                            <Fragment></Fragment>}
                                        <Button
                                            variant="outlined"
                                            onClick={(e) => this.goToApplyJob(e, item.JobId)}
                                            style={{ margin: '2%', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                                        >
                                            Detail
                                        </Button>
                                    </ListItemButton>
                                    <Divider />
                                </div>
                            ) : <Fragment>
                                <center>
                                    <h1
                                        style={{ color: '#03045e', fontFamily: 'Montserrat, Segoe UI' }}
                                    >
                                        No vacancy applied
                                    </h1>
                                </center>
                            </Fragment>}
                        </List>
                    </Fragment>}
            </Container >
        )
    }
}

const mapStateToProps = state => ({
    // AccountId: state.Auth.Id,
    isListApplicationsApplyLoading: state.Job.isListApplicationsApplyLoading,
    ListApplicationsApply: state.Job.ListApplicationsApply,
})

export default connect(mapStateToProps, { get_List_Applications_Apply })(JobApplications)
