import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Pertanyaan_Interview_Data } from '../../../../../../Store/Actions/Setting.Action'

import Detail from '../../../../../Containers/Dashboard/Setting/PertanyaanInterview/Detail'

class SettingPertanyaanView extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.PertanyaanActionId !== prevProps.PertanyaanActionId) {
            this.props.get_Pertanyaan_Interview_Data(this.props.PertanyaanActionId)
        }
        // if (this.props.PertanyaanActionCounter !== prevProps.PertanyaanActionCounter) {
        //     this.props.get_Pertanyaan_Interview_Data(this.props.PertanyaanActionId)
        // }
    }
    componentDidMount() {
        const { PertanyaanActionId } = this.props
        if (PertanyaanActionId !== null) {
            this.props.get_Pertanyaan_Interview_Data(PertanyaanActionId)
        }
    }

    render() {
        const isPertanyaanActionGetDetailLoading = this.props.isPertanyaanActionGetDetailLoading
        const PertanyaanDetail = this.props.PertanyaanDetail

        return (
            <Fragment>
                <Detail Data={PertanyaanDetail} isLoading={isPertanyaanActionGetDetailLoading} />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isPertanyaanActionGetDetailLoading: state.Setting.isPertanyaanActionGetDetailLoading,
    PertanyaanActionId: state.Setting.PertanyaanActionId,
    PertanyaanDetail: state.Setting.PertanyaanDetail,
})

export default connect(mapStateToProps, { get_Pertanyaan_Interview_Data })(SettingPertanyaanView)
