import React, { Fragment } from 'react'

import CoTable from '../../../Table'

const SettingRoleData = ({ HeadData, BodyData, isLoading = false, ...props }) => {
    return (
        <Fragment>
            <Fragment>
                <h3>
                    Role Data
                </h3>
                <CoTable
                    TableId=''
                    HeadData={HeadData}
                    isHeadLoading={isLoading}
                    Data={BodyData}
                    isBodyLoadin={isLoading}
                />
            </Fragment>
        </Fragment>
    )
}

export default SettingRoleData