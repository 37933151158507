import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Report_PR } from '../../../../Store/Actions/Report.Action'

import { Tabs, Tab } from '@mui/material'

import PRTracker from './PR/PRTracker'
import PRRealization from './PR/PRRealization'

class PR extends React.Component {
    state = {
        TabValue: 0
    }

    componentDidMount() {
        this.props.get_Report_PR()
    }

    handleTabChange = (e, newTabValue) => {
        this.setState({ TabValue: newTabValue })
    }

    render() {
        const isReportPRLoading = this.props.isReportPRLoading
        const ReportPR = this.props.ReportPR

        const {
            TabValue
        } = this.state

        const TabData = [
            {
                TabLabel: 'PR Output Tracker',
                TabPanel: <PRTracker
                    isLoading={isReportPRLoading}
                    Data={ReportPR}
                />
            },
            {
                TabLabel: 'PR Realization',
                TabPanel: <PRRealization />
            },
        ]

        const Data = TabData

        return (
            <Fragment>
                <Tabs
                    value={TabValue}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={TabData.length > 3 ? "scrollable" : null}
                    scrollButtons="auto"
                    style={{ margin: '0 10% 0 10%' }}
                // centered={TabData.length > 6 ? false : true}
                >
                    {Data.map((item, index) => (
                        <Tab
                            style={{ fontSize: '1vw', textTransform: 'none', fontFamily: 'Montserrat, Segoe UI', }}
                            key={`Tabs${index}`}
                            label={item.TabLabel}
                        />
                    ))}
                </Tabs>
                <div
                    style={{ border: '0px solid red', minWidth: '75%', margin: '1% 10% 5% 0' }}
                >
                    {TabValue === 0 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 1 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 2 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 3 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 4 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 5 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 6 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 7 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 8 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 9 ? TabData[TabValue].TabPanel : null}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isReportPRLoading: state.Report.isReportPRLoading,
    ReportPR: state.Report.ReportPR,
})

export default connect(mapStateToProps, { get_Report_PR })(PR)