import axios from "axios";

import {
  AUTH_RELOADE_PAGE,
  //* ROLE
  SETTING_ROLE_LOADING,
  SETTING_ROLE_LOADED,
  SETTING_ROLE_LOADING_DONE,
  SETTING_ROLE_ACTION_ADD_LOADING,
  SETTING_ROLE_ACTION_ADD_LOADING_DONE,
  SETTING_ROLE_ACTION_ADD_ERROR,
  SETTING_ROLE_ACTION_GET_ID,
  SETTING_ROLE_ACTION_GET_ROLELIST_PARTICIPANT_LOADING,
  SETTING_ROLE_ACTION_GET_ROLELIST_PARTICIPANT_LOADED,
  SETTING_ROLE_ACTION_GET_ROLELIST_PARTICIPANT_LOADING_DONE,
  SETTING_ROLE_ACTION_GET_DATA_LOADING,
  SETTING_ROLE_ACTION_GET_DATA_LOADED,
  SETTING_ROLE_ACTION_GET_DATA_LOADING_DONE,
  SETTING_ROLE_ACTION_DELETE_LOADING,
  SETTING_ROLE_ACTION_DELETE_LOADING_DONE,
  SETTING_ROLE_ACTION_ADD_LIST_LOADING,
  SETTING_ROLE_ACTION_ADD_LIST_LOADING_DONE,
  //* DIVISION
  SETTING_DIVISION_LOADING,
  SETTING_DIVISION_LOADED,
  SETTING_DIVISION_LOADING_DONE,
  SETTING_DIVISION_ACTION_ADD_LOADING,
  SETTING_DIVISION_ACTION_ADD_LOADING_DONE,
  SETTING_DIVISION_ACTION_ADD_ERROR,
  SETTING_DIVISION_ACTION_GET_ID,
  SETTING_DIVISION_ACTION_GET_DIVISIONLIST_PARTICIPANT_LOADING,
  SETTING_DIVISION_ACTION_GET_DIVISIONLIST_PARTICIPANT_LOADED,
  SETTING_DIVISION_ACTION_GET_DIVISIONLIST_PARTICIPANT_LOADING_DONE,
  SETTING_DIVISION_ACTION_GET_DATA_LOADING,
  SETTING_DIVISION_ACTION_GET_DATA_LOADED,
  SETTING_DIVISION_ACTION_GET_DATA_LOADING_DONE,
  SETTING_DIVISION_ACTION_DELETE_LOADING,
  SETTING_DIVISION_ACTION_DELETE_LOADING_DONE,
  SETTING_DIVISION_ACTION_ADD_LIST_LOADING,
  SETTING_DIVISION_ACTION_ADD_LIST_LOADING_DONE,
  //* DEPARTMENT
  SETTING_DEPARTMENT_LOADING,
  SETTING_DEPARTMENT_LOADED,
  SETTING_DEPARTMENT_LOADING_DONE,
  SETTING_DEPARTMENT_ACTION_ADD_LOADING,
  SETTING_DEPARTMENT_ACTION_ADD_LOADING_DONE,
  SETTING_DEPARTMENT_ACTION_ADD_ERROR,
  SETTING_DEPARTMENT_ACTION_GET_ID,
  SETTING_DEPARTMENT_ACTION_GET_DEPARTMENTLIST_PARTICIPANT_LOADING,
  SETTING_DEPARTMENT_ACTION_GET_DEPARTMENTLIST_PARTICIPANT_LOADED,
  SETTING_DEPARTMENT_ACTION_GET_DEPARTMENTLIST_PARTICIPANT_LOADING_DONE,
  SETTING_DEPARTMENT_ACTION_GET_DATA_LOADING,
  SETTING_DEPARTMENT_ACTION_GET_DATA_LOADED,
  SETTING_DEPARTMENT_ACTION_GET_DATA_LOADING_DONE,
  SETTING_DEPARTMENT_ACTION_DELETE_LOADING,
  SETTING_DEPARTMENT_ACTION_DELETE_LOADING_DONE,
  SETTING_DEPARTMENT_ACTION_ADD_LIST_LOADING,
  SETTING_DEPARTMENT_ACTION_ADD_LIST_LOADING_DONE,
  //* LEVEL
  SETTING_LEVEL_LOADING,
  SETTING_LEVEL_LOADED,
  SETTING_LEVEL_LOADING_DONE,
  SETTING_LEVEL_ACTION_ADD_LOADING,
  SETTING_LEVEL_ACTION_ADD_LOADING_DONE,
  SETTING_LEVEL_ACTION_ADD_ERROR,
  SETTING_LEVEL_ACTION_GET_ID,
  SETTING_LEVEL_ACTION_GET_LEVELLIST_PARTICIPANT_LOADING,
  SETTING_LEVEL_ACTION_GET_LEVELLIST_PARTICIPANT_LOADED,
  SETTING_LEVEL_ACTION_GET_LEVELLIST_PARTICIPANT_LOADING_DONE,
  SETTING_LEVEL_ACTION_GET_DATA_LOADING,
  SETTING_LEVEL_ACTION_GET_DATA_LOADED,
  SETTING_LEVEL_ACTION_GET_DATA_LOADING_DONE,
  SETTING_LEVEL_ACTION_DELETE_LOADING,
  SETTING_LEVEL_ACTION_DELETE_LOADING_DONE,
  SETTING_LEVEL_ACTION_ADD_LIST_LOADING,
  SETTING_LEVEL_ACTION_ADD_LIST_LOADING_DONE,
  //* MPP
  SETTING_MPP_LOADING,
  SETTING_MPP_LOADED,
  SETTING_MPP_LOADING_DONE,
  SETTING_MPP_ACTION_ADD_LOADING,
  SETTING_MPP_ACTION_ADD_LOADING_DONE,
  SETTING_MPP_ACTION_ADD_ERROR,
  SETTING_MPP_ACTION_GET_ID,
  SETTING_MPP_ACTION_GET_DATA_LOADING,
  SETTING_MPP_ACTION_GET_DATA_LOADED,
  SETTING_MPP_ACTION_GET_DATA_LOADING_DONE,
  SETTING_MPP_ACTION_DELETE_LOADING,
  SETTING_MPP_ACTION_DELETE_LOADING_DONE,
  //* ITEMS
  SETTING_ITEMS_LOADING,
  SETTING_ITEMS_LOADED,
  SETTING_ITEMS_LOADING_DONE,
  SETTING_ITEMS_ACTION_ADD_LOADING,
  SETTING_ITEMS_ACTION_ADD_LOADING_DONE,
  SETTING_ITEMS_ACTION_ADD_ERROR,
  SETTING_ITEMS_ACTION_GET_ID,
  SETTING_ITEMS_ACTION_GET_DATA_LOADING,
  SETTING_ITEMS_ACTION_GET_DATA_LOADED,
  SETTING_ITEMS_ACTION_GET_DATA_LOADING_DONE,
  SETTING_ITEMS_ACTION_DELETE_LOADING,
  SETTING_ITEMS_ACTION_DELETE_LOADING_DONE,
  SETTING_GOLONGAN_LOADING,
  SETTING_GOLONGAN_LOADED,
  SETTING_GOLONGAN_LOADING_DONE,
  //* LOKASI
  SETTING_LOKASI_LOADING,
  SETTING_LOKASI_LOADED,
  SETTING_LOKASI_LOADING_DONE,
  SETTING_LOKASI_ACTION_ADD_LOADING,
  SETTING_LOKASI_ACTION_ADD_LOADING_DONE,
  SETTING_LOKASI_ACTION_ADD_ERROR,
  SETTING_LOKASI_ACTION_GET_ID,
  SETTING_LOKASI_ACTION_GET_DATA_LOADING,
  SETTING_LOKASI_ACTION_GET_DATA_LOADED,
  SETTING_LOKASI_ACTION_GET_DATA_LOADING_DONE,
  SETTING_LOKASI_ACTION_DELETE_LOADING,
  SETTING_LOKASI_ACTION_DELETE_LOADING_DONE,
  //* PERTANYAAN INTERVIEW
  SETTING_PERTANYAAN_INTERVIEW_LOADING,
  SETTING_PERTANYAAN_INTERVIEW_LOADED,
  SETTING_PERTANYAAN_INTERVIEW_LOADING_DONE,
  SETTING_PERTANYAAN_INTERVIEW_ACTION_ADD_LOADING,
  SETTING_PERTANYAAN_INTERVIEW_ACTION_ADD_LOADING_DONE,
  SETTING_PERTANYAAN_INTERVIEW_ACTION_ADD_ERROR,
  SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_ID,
  SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_DATA_LOADING,
  SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_DATA_LOADED,
  SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_DATA_LOADING_DONE,
  SETTING_PERTANYAAN_INTERVIEW_ACTION_DELETE_LOADING,
  SETTING_PERTANYAAN_INTERVIEW_ACTION_DELETE_LOADING_DONE,
  //* PERTANYAAN BIODATA JAWABAN
  SETTING_PERTANYAAN_BIODATA_JAWABAN_LOADING,
  SETTING_PERTANYAAN_BIODATA_JAWABAN_LOADED,
  SETTING_PERTANYAAN_BIODATA_JAWABAN_LOADING_DONE,
  SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_ADD_LOADING,
  SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_ADD_LOADING_DONE,
  SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_ADD_ERROR,
  SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_ID,
  SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_DATA_LOADING,
  SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_DATA_LOADED,
  SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_DATA_LOADING_DONE,
  SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_DELETE_LOADING,
  SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_DELETE_LOADING_DONE,
  //* PERTANYAAN BIODATA_DESKRIPSI
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_LOADING,
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_LOADED,
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_LOADING_DONE,
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_ADD_LOADING,
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_ADD_LOADING_DONE,
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_ADD_ERROR,
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_ID,
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_DATA_LOADING,
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_DATA_LOADED,
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_DATA_LOADING_DONE,
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_DELETE_LOADING,
  SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_DELETE_LOADING_DONE,
  //* ACCOUNT
  SETTING_ACCOUNT_LOADING,
  SETTING_ACCOUNT_LOADED,
  SETTING_ACCOUNT_LOADING_DONE,
  SETTING_ACCOUNT_DETAIL_LOADING,
  SETTING_ACCOUNT_DETAIL_LOADED,
  SETTING_ACCOUNT_DETAIL_LOADING_DONE,
} from "./Type.Action";

import {
  Create_Error_Messages,
  Create_Success_Messages,
} from "./Messages.Actions";

import { Get_IpAddres, tokenConfig, ErrorHandler } from "./Base.Action";

import { Error_Messages_Standardization } from "../../Global";

//* ROLE
export const Load_Role_List = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_ROLE_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.get(
      `${IpAddres}/setting/role`,
      tokenConfig(getState)
    );
    if (Data) {
      dispatch({
        type: SETTING_ROLE_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: SETTING_ROLE_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_ROLE_LOADING_DONE });
    ErrorHandler(err, "Load_Role_List");
  }
};

export const Add_Role = (Nama, Deskripsi) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_ROLE_ACTION_ADD_LOADING });
    if (!Nama) {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Form Tidak Lengkap"
      );
      dispatch(Create_Error_Messages(400, "Form Tidak Lengkap"));
      throw ErrorResponse;
    }
    const IpAddres = Get_IpAddres();

    const body = JSON.stringify({ Nama, Deskripsi });
    const Data = await axios.post(
      `${IpAddres}/setting/role`,
      body,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch({ type: AUTH_RELOADE_PAGE });
      dispatch(Create_Success_Messages(200, `Role ${Nama} Berhasil dibuat`));
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_ROLE_ACTION_ADD_LOADING_DONE });
    ErrorHandler(err, "Add_Role");
    try {
      if (err.response.data.msg && err.response.data.msg === "Data Sudah Ada") {
        dispatch({
          type: SETTING_ROLE_ACTION_ADD_ERROR,
          payload: true,
        });
      }
    } catch (err) {}
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else if (err.response.data.msg) {
    //         dispatch(Create_Error_Messages(400, err.response.data.msg))
    //         if (err.response.data.msg === 'Data Sudah Ada') {
    //             dispatch({
    //                 type: SETTING_ROLE_ACTION_ADD_ERROR,
    //                 payload: true
    //             })
    //         }
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Role Tida Bisa ditambah'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const get_Role_Action_Id = (Id) => (dispatch) => {
  dispatch({
    type: SETTING_ROLE_ACTION_GET_ID,
    payload: Id,
  });
};

export const get_Role_Data = (Id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_ROLE_ACTION_GET_DATA_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.get(
      `${IpAddres}/setting/role/detail/${Id}`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch({
        type: SETTING_ROLE_ACTION_GET_DATA_LOADED,
        payload: Data.data,
      });
      dispatch({ type: SETTING_ROLE_ACTION_GET_DATA_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_ROLE_ACTION_GET_DATA_LOADING_DONE });
    ErrorHandler(err, "get_Role_Data");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const Add_To_RoleList =
  (RoleId, AccountData) => async (dispatch, getState) => {
    try {
      dispatch({ type: SETTING_ROLE_ACTION_ADD_LIST_LOADING });
      const IpAddres = Get_IpAddres();

      const AccountID = AccountData?.AccountId ? AccountData.AccountId : null;

      const body = JSON.stringify({ RoleId, AccountID });
      const Data = await axios.post(
        `${IpAddres}/setting/role/list/add`,
        body,
        tokenConfig(getState)
      );
      if (Data.status === 200) {
        dispatch(Create_Success_Messages(200, "Berhasil ditambah"));
        dispatch({ type: SETTING_ROLE_ACTION_ADD_LIST_LOADING_DONE });
        dispatch({ type: AUTH_RELOADE_PAGE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak ditemukan"
        );
        throw ErrorResponse;
      }

      // const RoleId = 36617
      // const body = JSON.stringify({ RoleId, AccountID })
      // const Data = await axios.post(`${IpAddres}/setting/role/list/add`, body, tokenConfig(getState))
      // if (Data.status === 200) {
      //     dispatch(Create_Success_Messages(200, 'Berhasil ditambah'))
      //     // dispatch({ type: AUTH_RELOADE_PAGE })
      // } else {
      //     const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
      //     throw ErrorResponse
      // }

      // const RoleId1 = 25354
      // const body1 = JSON.stringify({ RoleId: RoleId1, AccountID })
      // const Data1 = await axios.post(`${IpAddres}/setting/role/list/add`, body1, tokenConfig(getState))
      // if (Data1.status === 200) {
      //     dispatch(Create_Success_Messages(200, 'Berhasil ditambah'))
      //     // dispatch({ type: AUTH_RELOADE_PAGE })
      // } else {
      //     const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
      //     throw ErrorResponse
      // }

      // const RoleId2 = 77741
      // const body2 = JSON.stringify({ RoleId: RoleId2, AccountID })
      // const Data2 = await axios.post(`${IpAddres}/setting/role/list/add`, body2, tokenConfig(getState))
      // if (Data2.status === 200) {
      //     dispatch(Create_Success_Messages(200, 'Berhasil ditambah'))
      //     // dispatch({ type: AUTH_RELOADE_PAGE })
      // } else {
      //     const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
      //     throw ErrorResponse
      // }
      // dispatch({ type: SETTING_ROLE_ACTION_ADD_LIST_LOADING_DONE })
    } catch (err) {
      dispatch({ type: SETTING_ROLE_ACTION_ADD_LIST_LOADING_DONE });
      ErrorHandler(err, "Add_To_RoleList");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg === 'Form Tidak Lengkap') {
      //         dispatch(Create_Error_Messages(400, 'Form Tidak Lengkap'))
      //     } else if (err.response.data.msg === 'Role List Tidak ditemukan') {
      //         dispatch(Create_Error_Messages(400, 'Role List Tidak ditemukan'))
      //     } else if (err.response.data.msg === 'Account Tidak ditemukan') {
      //         dispatch(Create_Error_Messages(400, 'Account Tidak ditemukan'))
      //     } else if (err.response.data.msg === 'Account Already There') {
      //         dispatch(Create_Error_Messages(400, 'Account Already There'))
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const Delete_From_RoleList = (Id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_ROLE_ACTION_DELETE_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.delete(
      `${IpAddres}/setting/role/list/${Id}/delete`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch(Create_Success_Messages(200, "Berhasil dihapus"));
      dispatch({ type: SETTING_ROLE_ACTION_DELETE_LOADING_DONE });
      dispatch({ type: AUTH_RELOADE_PAGE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_ROLE_ACTION_DELETE_LOADING_DONE });
    ErrorHandler(err, "Delete_From_RoleList");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const Delete_Role = (Id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_ROLE_ACTION_DELETE_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.delete(
      `${IpAddres}/setting/role/${Id}/delete`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch(Create_Success_Messages(200, "Berhasil dihapus"));
      dispatch({ type: SETTING_ROLE_ACTION_DELETE_LOADING_DONE });
      dispatch({ type: AUTH_RELOADE_PAGE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_ROLE_ACTION_DELETE_LOADING_DONE });
    ErrorHandler(err, "Delete_Role");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const get_Role_Participan_Data = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_ROLE_ACTION_GET_ROLELIST_PARTICIPANT_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.get(
      `${IpAddres}/setting/role/list/participant`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch({
        type: SETTING_ROLE_ACTION_GET_ROLELIST_PARTICIPANT_LOADED,
        payload: Data.data.Data,
      });
      dispatch({
        type: SETTING_ROLE_ACTION_GET_ROLELIST_PARTICIPANT_LOADING_DONE,
      });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({
      type: SETTING_ROLE_ACTION_GET_ROLELIST_PARTICIPANT_LOADING_DONE,
    });
    ErrorHandler(err, "get_Role_Participan_Data");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
    //     }
    // } catch (err2) {
    // }
  }
};

//* DIVISION
export const Load_Division_List = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_DIVISION_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.get(
      `${IpAddres}/setting/division`,
      tokenConfig(getState)
    );
    if (Data) {
      dispatch({
        type: SETTING_DIVISION_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: SETTING_DIVISION_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_DIVISION_LOADING_DONE });
    ErrorHandler(err, "Load_DIVISION_List");
  }
};

export const Add_Division = (Nama, Deskripsi) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_DIVISION_ACTION_ADD_LOADING });
    if (!Nama) {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Form Tidak Lengkap"
      );
      dispatch(Create_Error_Messages(400, "Form Tidak Lengkap"));
      throw ErrorResponse;
    }
    const IpAddres = Get_IpAddres();

    const body = JSON.stringify({ Nama, Deskripsi });
    const Data = await axios.post(
      `${IpAddres}/setting/division`,
      body,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch({ type: AUTH_RELOADE_PAGE });
      dispatch(
        Create_Success_Messages(200, `DIVISION ${Nama} Berhasil dibuat`)
      );
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_DIVISION_ACTION_ADD_LOADING_DONE });
    ErrorHandler(err, "Add_DIVISION");
    try {
      if (err.response.data.msg && err.response.data.msg === "Data Sudah Ada") {
        dispatch({
          type: SETTING_DIVISION_ACTION_ADD_ERROR,
          payload: true,
        });
      }
    } catch (err) {}
  }
};

export const get_Division_Action_Id = (Id) => (dispatch) => {
  dispatch({
    type: SETTING_DIVISION_ACTION_GET_ID,
    payload: Id,
  });
};

export const get_Division_Data = (Id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_DIVISION_ACTION_GET_DATA_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.get(
      `${IpAddres}/setting/division/${Id}`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch({
        type: SETTING_DIVISION_ACTION_GET_DATA_LOADED,
        payload: Data.data,
      });
      dispatch({ type: SETTING_DIVISION_ACTION_GET_DATA_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_DIVISION_ACTION_GET_DATA_LOADING_DONE });
    ErrorHandler(err, "get_DIVISION_Data");
  }
};

export const Add_To_DivisionList =
  (DivisionId, ParticipantData) => async (dispatch, getState) => {
    try {
      dispatch({ type: SETTING_DIVISION_ACTION_ADD_LIST_LOADING });
      const IpAddres = Get_IpAddres();

      const ID = ParticipantData?.Id ? ParticipantData.Id : null;

      const body = JSON.stringify({ DivisionId, ID });
      const Data = await axios.post(
        `${IpAddres}/setting/division/list/add`,
        body,
        tokenConfig(getState)
      );
      if (Data.status === 200) {
        dispatch(Create_Success_Messages(200, "Berhasil ditambah"));
        dispatch({ type: SETTING_DIVISION_ACTION_ADD_LIST_LOADING_DONE });
        dispatch({ type: AUTH_RELOADE_PAGE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak ditemukan"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: SETTING_DIVISION_ACTION_ADD_LIST_LOADING_DONE });
      ErrorHandler(err, "Add_To_DIVISIONList");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg === 'Form Tidak Lengkap') {
      //         dispatch(Create_Error_Messages(400, 'Form Tidak Lengkap'))
      //     } else if (err.response.data.msg === 'Role List Tidak ditemukan') {
      //         dispatch(Create_Error_Messages(400, 'Role List Tidak ditemukan'))
      //     } else if (err.response.data.msg === 'Account Tidak ditemukan') {
      //         dispatch(Create_Error_Messages(400, 'Account Tidak ditemukan'))
      //     } else if (err.response.data.msg === 'Account Already There') {
      //         dispatch(Create_Error_Messages(400, 'Account Already There'))
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const Delete_From_DivisionList = (Id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_DIVISION_ACTION_DELETE_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.delete(
      `${IpAddres}/setting/division/list/${Id}/delete`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch(Create_Success_Messages(200, "Berhasil dihapus"));
      dispatch({ type: SETTING_DIVISION_ACTION_DELETE_LOADING_DONE });
      dispatch({ type: AUTH_RELOADE_PAGE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_DIVISION_ACTION_DELETE_LOADING_DONE });
    ErrorHandler(err, "Delete_From_DIVISIONList");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const Delete_Division = (Id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_DIVISION_ACTION_DELETE_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.delete(
      `${IpAddres}/setting/division/${Id}/delete`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch(Create_Success_Messages(200, "Berhasil dihapus"));
      dispatch({ type: SETTING_DIVISION_ACTION_DELETE_LOADING_DONE });
      dispatch({ type: AUTH_RELOADE_PAGE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_DIVISION_ACTION_DELETE_LOADING_DONE });
    ErrorHandler(err, "Delete_Division");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const get_Division_Participan_Data =
  () => async (dispatch, getState) => {
    try {
      dispatch({
        type: SETTING_DIVISION_ACTION_GET_DIVISIONLIST_PARTICIPANT_LOADING,
      });
      const IpAddres = Get_IpAddres();

      const Data = await axios.get(
        `${IpAddres}/setting/division/list/participant`,
        tokenConfig(getState)
      );
      if (Data.status === 200) {
        dispatch({
          type: SETTING_DIVISION_ACTION_GET_DIVISIONLIST_PARTICIPANT_LOADED,
          payload: Data.data.Data,
        });
        dispatch({
          type: SETTING_DIVISION_ACTION_GET_DIVISIONLIST_PARTICIPANT_LOADING_DONE,
        });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak ditemukan"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({
        type: SETTING_DIVISION_ACTION_GET_DIVISIONLIST_PARTICIPANT_LOADING_DONE,
      });
      ErrorHandler(err, "get_DIVISION_Participan_Data");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
      //     }
      // } catch (err2) {
      // }
    }
  };

//* DEPARTMENT
export const Load_Department_List = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_DEPARTMENT_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.get(
      `${IpAddres}/setting/department`,
      tokenConfig(getState)
    );
    if (Data) {
      dispatch({
        type: SETTING_DEPARTMENT_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: SETTING_DEPARTMENT_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_DEPARTMENT_LOADING_DONE });
    ErrorHandler(err, "Load_Department_List");
  }
};

export const Add_Department =
  (Nama, Deskripsi) => async (dispatch, getState) => {
    try {
      dispatch({ type: SETTING_DEPARTMENT_ACTION_ADD_LOADING });
      if (!Nama) {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Form Tidak Lengkap"
        );
        dispatch(Create_Error_Messages(400, "Form Tidak Lengkap"));
        throw ErrorResponse;
      }
      const IpAddres = Get_IpAddres();

      const body = JSON.stringify({ Nama, Deskripsi });
      const Data = await axios.post(
        `${IpAddres}/setting/department`,
        body,
        tokenConfig(getState)
      );
      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(
          Create_Success_Messages(200, `Department ${Nama} Berhasil dibuat`)
        );
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: SETTING_DEPARTMENT_ACTION_ADD_LOADING_DONE });
      ErrorHandler(err, "Add_Department");
      try {
        if (
          err.response.data.msg &&
          err.response.data.msg === "Data Sudah Ada"
        ) {
          dispatch({
            type: SETTING_DEPARTMENT_ACTION_ADD_ERROR,
            payload: true,
          });
        }
      } catch (err) {}
    }
  };

export const get_Department_Action_Id = (Id) => (dispatch) => {
  dispatch({
    type: SETTING_DEPARTMENT_ACTION_GET_ID,
    payload: Id,
  });
};

export const get_Department_Data = (Id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_DEPARTMENT_ACTION_GET_DATA_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.get(
      `${IpAddres}/setting/department/${Id}`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch({
        type: SETTING_DEPARTMENT_ACTION_GET_DATA_LOADED,
        payload: Data.data,
      });
      dispatch({ type: SETTING_DEPARTMENT_ACTION_GET_DATA_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_DEPARTMENT_ACTION_GET_DATA_LOADING_DONE });
    ErrorHandler(err, "get_Department_Data");
  }
};

export const Add_To_DepartmentList =
  (DepartmentId, AccountData) => async (dispatch, getState) => {
    try {
      dispatch({ type: SETTING_DEPARTMENT_ACTION_ADD_LIST_LOADING });
      const IpAddres = Get_IpAddres();

      const AccountID = AccountData?.AccountId ? AccountData.AccountId : null;

      const body = JSON.stringify({ DepartmentId, AccountID });
      const Data = await axios.post(
        `${IpAddres}/setting/department/list/add`,
        body,
        tokenConfig(getState)
      );
      if (Data.status === 200) {
        dispatch(Create_Success_Messages(200, "Berhasil ditambah"));
        dispatch({ type: SETTING_DEPARTMENT_ACTION_ADD_LIST_LOADING_DONE });
        dispatch({ type: AUTH_RELOADE_PAGE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak ditemukan"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: SETTING_DEPARTMENT_ACTION_ADD_LIST_LOADING_DONE });
      ErrorHandler(err, "Add_To_DepartmentList");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg === 'Form Tidak Lengkap') {
      //         dispatch(Create_Error_Messages(400, 'Form Tidak Lengkap'))
      //     } else if (err.response.data.msg === 'Role List Tidak ditemukan') {
      //         dispatch(Create_Error_Messages(400, 'Role List Tidak ditemukan'))
      //     } else if (err.response.data.msg === 'Account Tidak ditemukan') {
      //         dispatch(Create_Error_Messages(400, 'Account Tidak ditemukan'))
      //     } else if (err.response.data.msg === 'Account Already There') {
      //         dispatch(Create_Error_Messages(400, 'Account Already There'))
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const Delete_From_DepartmentList =
  (Id) => async (dispatch, getState) => {
    try {
      dispatch({ type: SETTING_DEPARTMENT_ACTION_DELETE_LOADING });
      const IpAddres = Get_IpAddres();

      const Data = await axios.delete(
        `${IpAddres}/setting/department/list/${Id}/delete`,
        tokenConfig(getState)
      );
      if (Data.status === 200) {
        dispatch(Create_Success_Messages(200, "Berhasil dihapus"));
        dispatch({ type: SETTING_DEPARTMENT_ACTION_DELETE_LOADING_DONE });
        dispatch({ type: AUTH_RELOADE_PAGE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak ditemukan"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: SETTING_DEPARTMENT_ACTION_DELETE_LOADING_DONE });
      ErrorHandler(err, "Delete_From_DepartmentList");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const Delete_Department = (Id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_DEPARTMENT_ACTION_DELETE_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.delete(
      `${IpAddres}/setting/department/${Id}/delete`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch(Create_Success_Messages(200, "Berhasil dihapus"));
      dispatch({ type: SETTING_DEPARTMENT_ACTION_DELETE_LOADING_DONE });
      dispatch({ type: AUTH_RELOADE_PAGE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_DEPARTMENT_ACTION_DELETE_LOADING_DONE });
    ErrorHandler(err, "Delete_Department");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const get_Department_Participan_Data =
  () => async (dispatch, getState) => {
    try {
      dispatch({
        type: SETTING_DEPARTMENT_ACTION_GET_DEPARTMENTLIST_PARTICIPANT_LOADING,
      });
      const IpAddres = Get_IpAddres();

      const Data = await axios.get(
        `${IpAddres}/setting/department/list/participant`,
        tokenConfig(getState)
      );
      if (Data.status === 200) {
        dispatch({
          type: SETTING_DEPARTMENT_ACTION_GET_DEPARTMENTLIST_PARTICIPANT_LOADED,
          payload: Data.data.Data,
        });
        dispatch({
          type: SETTING_DEPARTMENT_ACTION_GET_DEPARTMENTLIST_PARTICIPANT_LOADING_DONE,
        });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak ditemukan"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({
        type: SETTING_DEPARTMENT_ACTION_GET_DEPARTMENTLIST_PARTICIPANT_LOADING_DONE,
      });
      ErrorHandler(err, "get_Department_Participan_Data");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
      //     }
      // } catch (err2) {
      // }
    }
  };

//* GOLONGAN
export const Load_Golongan_List = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_GOLONGAN_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.get(
      `${IpAddres}/setting/level/golongan`,
      tokenConfig(getState)
    );
    if (Data) {
      dispatch({
        type: SETTING_GOLONGAN_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: SETTING_GOLONGAN_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_GOLONGAN_LOADING_DONE });
    ErrorHandler(err, "Load_GOLONGAN_List");
  }
};

//* LEVEL
export const Load_Level_List = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_LEVEL_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.get(
      `${IpAddres}/setting/level`,
      tokenConfig(getState)
    );
    if (Data) {
      dispatch({
        type: SETTING_LEVEL_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: SETTING_LEVEL_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_LEVEL_LOADING_DONE });
    ErrorHandler(err, "Load_Level_List");
  }
};

export const Add_Level = (Nama, Deskripsi) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_LEVEL_ACTION_ADD_LOADING });
    if (!Nama) {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Form Tidak Lengkap"
      );
      dispatch(Create_Error_Messages(400, "Form Tidak Lengkap"));
      throw ErrorResponse;
    }
    const IpAddres = Get_IpAddres();

    const body = JSON.stringify({ Nama, Deskripsi });
    const Data = await axios.post(
      `${IpAddres}/setting/level`,
      body,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch({ type: AUTH_RELOADE_PAGE });
      dispatch(Create_Success_Messages(200, `Level ${Nama} Berhasil dibuat`));
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_LEVEL_ACTION_ADD_LOADING_DONE });
    ErrorHandler(err, "Add_Level");
    try {
      if (err.response.data.msg && err.response.data.msg === "Data Sudah Ada") {
        dispatch({
          type: SETTING_LEVEL_ACTION_ADD_ERROR,
          payload: true,
        });
      }
    } catch (err) {}
  }
};

export const get_Level_Action_Id = (Id) => (dispatch) => {
  dispatch({
    type: SETTING_LEVEL_ACTION_GET_ID,
    payload: Id,
  });
};

export const get_Level_Data = (Id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_LEVEL_ACTION_GET_DATA_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.get(
      `${IpAddres}/setting/level/${Id}`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch({
        type: SETTING_LEVEL_ACTION_GET_DATA_LOADED,
        payload: Data.data,
      });
      dispatch({ type: SETTING_LEVEL_ACTION_GET_DATA_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_LEVEL_ACTION_GET_DATA_LOADING_DONE });
    ErrorHandler(err, "get_Level_Data");
  }
};

export const Add_To_LevelList =
  (LevelId, AccountData) => async (dispatch, getState) => {
    try {
      dispatch({ type: SETTING_LEVEL_ACTION_ADD_LIST_LOADING });
      const IpAddres = Get_IpAddres();

      const AccountID = AccountData?.AccountId ? AccountData.AccountId : null;

      const body = JSON.stringify({ LevelId, AccountID });
      const Data = await axios.post(
        `${IpAddres}/setting/level/list/add`,
        body,
        tokenConfig(getState)
      );
      if (Data.status === 200) {
        dispatch(Create_Success_Messages(200, "Berhasil ditambah"));
        dispatch({ type: SETTING_LEVEL_ACTION_ADD_LIST_LOADING_DONE });
        dispatch({ type: AUTH_RELOADE_PAGE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak ditemukan"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: SETTING_LEVEL_ACTION_ADD_LIST_LOADING_DONE });
      ErrorHandler(err, "Add_To_LevelList");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg === 'Form Tidak Lengkap') {
      //         dispatch(Create_Error_Messages(400, 'Form Tidak Lengkap'))
      //     } else if (err.response.data.msg === 'Role List Tidak ditemukan') {
      //         dispatch(Create_Error_Messages(400, 'Role List Tidak ditemukan'))
      //     } else if (err.response.data.msg === 'Account Tidak ditemukan') {
      //         dispatch(Create_Error_Messages(400, 'Account Tidak ditemukan'))
      //     } else if (err.response.data.msg === 'Account Already There') {
      //         dispatch(Create_Error_Messages(400, 'Account Already There'))
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const Delete_From_LevelList = (Id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_LEVEL_ACTION_DELETE_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.delete(
      `${IpAddres}/setting/level/list/${Id}/delete`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch(Create_Success_Messages(200, "Berhasil dihapus"));
      dispatch({ type: SETTING_LEVEL_ACTION_DELETE_LOADING_DONE });
      dispatch({ type: AUTH_RELOADE_PAGE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_LEVEL_ACTION_DELETE_LOADING_DONE });
    ErrorHandler(err, "Delete_From_LevelList");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const Delete_Level = (Id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_LEVEL_ACTION_DELETE_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.delete(
      `${IpAddres}/setting/level/${Id}/delete`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch(Create_Success_Messages(200, "Berhasil dihapus"));
      dispatch({ type: SETTING_LEVEL_ACTION_DELETE_LOADING_DONE });
      dispatch({ type: AUTH_RELOADE_PAGE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_LEVEL_ACTION_DELETE_LOADING_DONE });
    ErrorHandler(err, "Delete_Level");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const get_Level_Participan_Data = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_LEVEL_ACTION_GET_LEVELLIST_PARTICIPANT_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.get(
      `${IpAddres}/setting/level/list/participant`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch({
        type: SETTING_LEVEL_ACTION_GET_LEVELLIST_PARTICIPANT_LOADED,
        payload: Data.data.Data,
      });
      dispatch({
        type: SETTING_LEVEL_ACTION_GET_LEVELLIST_PARTICIPANT_LOADING_DONE,
      });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({
      type: SETTING_LEVEL_ACTION_GET_LEVELLIST_PARTICIPANT_LOADING_DONE,
    });
    ErrorHandler(err, "get_Level_Participan_Data");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
    //     }
    // } catch (err2) {
    // }
  }
};

//* MPP
export const Load_MPP_List = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_MPP_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.get(
      `${IpAddres}/setting/mpp`,
      tokenConfig(getState)
    );
    if (Data) {
      dispatch({
        type: SETTING_MPP_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: SETTING_MPP_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_MPP_LOADING_DONE });
    ErrorHandler(err, "Load_MPP_List");
  }
};

export const Add_MPP =
  (Nama, Jenis, TanggalBuka, TanggalTutup, Deskripsi, Departemen) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: SETTING_MPP_ACTION_ADD_LOADING });
      if (!Nama) {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Form Tidak Lengkap"
        );
        dispatch(Create_Error_Messages(400, "Form Tidak Lengkap"));
        throw ErrorResponse;
      }
      const IpAddres = Get_IpAddres();

      const body = JSON.stringify({
        Nama,
        Jenis,
        TanggalBuka,
        TanggalTutup,
        Deskripsi,
        Departemen
      });
      const Data = await axios.post(
        `${IpAddres}/setting/mpp`,
        body,
        tokenConfig(getState)
      );
      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `MPP ${Nama} Berhasil dibuat`));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: SETTING_MPP_ACTION_ADD_LOADING_DONE });
      ErrorHandler(err, "Add_MPP");
      try {
        if (
          err.response.data.msg &&
          err.response.data.msg === "Data Sudah Ada"
        ) {
          dispatch({
            type: SETTING_MPP_ACTION_ADD_ERROR,
            payload: true,
          });
        }
      } catch (err) {}
    }
  };

export const get_MPP_Action_Id = (Id) => (dispatch) => {
  dispatch({
    type: SETTING_MPP_ACTION_GET_ID,
    payload: Id,
  });
};

export const get_MPP_Data = (Id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_MPP_ACTION_GET_DATA_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.get(
      `${IpAddres}/setting/mpp/${Id}`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch({
        type: SETTING_MPP_ACTION_GET_DATA_LOADED,
        payload: Data.data,
      });
      dispatch({ type: SETTING_MPP_ACTION_GET_DATA_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_MPP_ACTION_GET_DATA_LOADING_DONE });
    ErrorHandler(err, "get_MPP_Data");
  }
};

export const Delete_MPP = (Id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_MPP_ACTION_DELETE_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.delete(
      `${IpAddres}/setting/mpp/${Id}/delete`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch(Create_Success_Messages(200, "Berhasil dihapus"));
      dispatch({ type: SETTING_MPP_ACTION_DELETE_LOADING_DONE });
      dispatch({ type: AUTH_RELOADE_PAGE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_MPP_ACTION_DELETE_LOADING_DONE });
    ErrorHandler(err, "Delete_MPP");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
    //     }
    // } catch (err2) {
    // }
  }
};

//* ITEMS
export const Load_Items_List = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_ITEMS_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.get(
      `${IpAddres}/setting/items`,
      tokenConfig(getState)
    );
    if (Data) {
      dispatch({
        type: SETTING_ITEMS_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: SETTING_ITEMS_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_ITEMS_LOADING_DONE });
    ErrorHandler(err, "Load_Items_List");
  }
};

export const Add_Items = (Nama, Deskripsi) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_ITEMS_ACTION_ADD_LOADING });
    if (!Nama) {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Form Tidak Lengkap"
      );
      dispatch(Create_Error_Messages(400, "Form Tidak Lengkap"));
      throw ErrorResponse;
    }
    const IpAddres = Get_IpAddres();

    const body = JSON.stringify({ Nama, Deskripsi });
    const Data = await axios.post(
      `${IpAddres}/setting/items`,
      body,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch({ type: AUTH_RELOADE_PAGE });
      dispatch(Create_Success_Messages(200, `Items ${Nama} Berhasil dibuat`));
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_ITEMS_ACTION_ADD_LOADING_DONE });
    ErrorHandler(err, "Add_Items");
    try {
      if (err.response.data.msg && err.response.data.msg === "Data Sudah Ada") {
        dispatch({
          type: SETTING_ITEMS_ACTION_ADD_ERROR,
          payload: true,
        });
      }
    } catch (err) {}
  }
};

export const get_Items_Action_Id = (Id) => (dispatch) => {
  dispatch({
    type: SETTING_ITEMS_ACTION_GET_ID,
    payload: Id,
  });
};

export const get_Items_Data = (Id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_ITEMS_ACTION_GET_DATA_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.get(
      `${IpAddres}/setting/items/${Id}`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch({
        type: SETTING_ITEMS_ACTION_GET_DATA_LOADED,
        payload: Data.data,
      });
      dispatch({ type: SETTING_ITEMS_ACTION_GET_DATA_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_ITEMS_ACTION_GET_DATA_LOADING_DONE });
    ErrorHandler(err, "get_Items_Data");
  }
};

export const Delete_Items = (Id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_ITEMS_ACTION_DELETE_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.delete(
      `${IpAddres}/setting/items/${Id}/delete`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch(Create_Success_Messages(200, "Berhasil dihapus"));
      dispatch({ type: SETTING_ITEMS_ACTION_DELETE_LOADING_DONE });
      dispatch({ type: AUTH_RELOADE_PAGE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_ITEMS_ACTION_DELETE_LOADING_DONE });
    ErrorHandler(err, "Delete_Items");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
    //     }
    // } catch (err2) {
    // }
  }
};

//* LOKASI
export const Load_Lokasi_List = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_LOKASI_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.get(
      `${IpAddres}/setting/lokasi`,
      tokenConfig(getState)
    );
    if (Data) {
      dispatch({
        type: SETTING_LOKASI_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: SETTING_LOKASI_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_LOKASI_LOADING_DONE });
    ErrorHandler(err, "Load_Lokasi_List");
  }
};

export const Add_Lokasi = (Nama, Deskripsi) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_LOKASI_ACTION_ADD_LOADING });
    if (!Nama) {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Form Tidak Lengkap"
      );
      dispatch(Create_Error_Messages(400, "Form Tidak Lengkap"));
      throw ErrorResponse;
    }
    const IpAddres = Get_IpAddres();

    const body = JSON.stringify({ Nama, Deskripsi });
    const Data = await axios.post(
      `${IpAddres}/setting/lokasi`,
      body,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch({ type: AUTH_RELOADE_PAGE });
      dispatch(Create_Success_Messages(200, `Lokasi ${Nama} Berhasil dibuat`));
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak lengkap"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_LOKASI_ACTION_ADD_LOADING_DONE });
    ErrorHandler(err, "Add_Lokasi");
    try {
      if (err.response.data.msg && err.response.data.msg === "Data Sudah Ada") {
        dispatch({
          type: SETTING_LOKASI_ACTION_ADD_ERROR,
          payload: true,
        });
      }
    } catch (err) {}
  }
};

export const get_Lokasi_Action_Id = (Id) => (dispatch) => {
  dispatch({
    type: SETTING_LOKASI_ACTION_GET_ID,
    payload: Id,
  });
};

export const get_Lokasi_Data = (Id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_LOKASI_ACTION_GET_DATA_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.get(
      `${IpAddres}/setting/lokasi/${Id}`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch({
        type: SETTING_LOKASI_ACTION_GET_DATA_LOADED,
        payload: Data.data,
      });
      dispatch({ type: SETTING_LOKASI_ACTION_GET_DATA_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_LOKASI_ACTION_GET_DATA_LOADING_DONE });
    ErrorHandler(err, "get_Lokasi_Data");
  }
};

export const Delete_Lokasi = (Id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_LOKASI_ACTION_DELETE_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.delete(
      `${IpAddres}/setting/lokasi/${Id}/delete`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch(Create_Success_Messages(200, "Berhasil dihapus"));
      dispatch({ type: SETTING_LOKASI_ACTION_DELETE_LOADING_DONE });
      dispatch({ type: AUTH_RELOADE_PAGE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_LOKASI_ACTION_DELETE_LOADING_DONE });
    ErrorHandler(err, "Delete_Lokasi");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
    //     }
    // } catch (err2) {
    // }
  }
};

//* PERTANYAAN INTERVIEW
export const Load_Pertanyaan_Interview_List =
  (User) => async (dispatch, getState) => {
    try {
      dispatch({ type: SETTING_PERTANYAAN_INTERVIEW_LOADING });
      const IpAddres = Get_IpAddres();

      const Url = User
        ? User === "Department"
          ? `${IpAddres}/setting/interview/pertanyaan/department`
          : `${IpAddres}/setting/interview/pertanyaan/hr`
        : `${IpAddres}/setting/interview/pertanyaan`;

      const Data = await axios.get(Url, tokenConfig(getState));
      if (Data) {
        dispatch({
          type: SETTING_PERTANYAAN_INTERVIEW_LOADED,
          payload: Data.data.Data,
        });
        dispatch({ type: SETTING_PERTANYAAN_INTERVIEW_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak ditemukan"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: SETTING_PERTANYAAN_INTERVIEW_LOADING_DONE });
      ErrorHandler(err, "Load_Pertanyaan_Interview_List");
    }
  };

export const Add_Pertanyaan_Interview =
  (Nama, Jenis, Deskripsi) => async (dispatch, getState) => {
    try {
      dispatch({ type: SETTING_PERTANYAAN_INTERVIEW_ACTION_ADD_LOADING });
      if (!Nama) {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Form Tidak Lengkap"
        );
        dispatch(Create_Error_Messages(400, "Form Tidak Lengkap"));
        throw ErrorResponse;
      }
      const IpAddres = Get_IpAddres();

      const body = JSON.stringify({ Nama, Jenis, Deskripsi });
      const Data = await axios.post(
        `${IpAddres}/setting/interview/pertanyaan`,
        body,
        tokenConfig(getState)
      );
      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(
          Create_Success_Messages(200, `Pertanyaan ${Nama} Berhasil dibuat`)
        );
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: SETTING_PERTANYAAN_INTERVIEW_ACTION_ADD_LOADING_DONE });
      ErrorHandler(err, "Add_Pertanyaan_Interview");
      try {
        if (
          err.response.data.msg &&
          err.response.data.msg === "Data Sudah Ada"
        ) {
          dispatch({
            type: SETTING_PERTANYAAN_INTERVIEW_ACTION_ADD_ERROR,
            payload: true,
          });
        }
      } catch (err) {}
    }
  };

export const get_Pertanyaan_Interview_Action_Id = (Id) => (dispatch) => {
  dispatch({
    type: SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_ID,
    payload: Id,
  });
};

export const get_Pertanyaan_Interview_Data =
  (Id) => async (dispatch, getState) => {
    try {
      dispatch({ type: SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_DATA_LOADING });
      const IpAddres = Get_IpAddres();

      const Data = await axios.get(
        `${IpAddres}/setting/interview/pertanyaan/${Id}`,
        tokenConfig(getState)
      );
      if (Data.status === 200) {
        dispatch({
          type: SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_DATA_LOADED,
          payload: Data.data,
        });
        dispatch({
          type: SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_DATA_LOADING_DONE,
        });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak ditemukan"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({
        type: SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_DATA_LOADING_DONE,
      });
      ErrorHandler(err, "get_Pertanyaan_Interview_Data");
    }
  };

export const Delete_Pertanyaan_Interview =
  (Id) => async (dispatch, getState) => {
    try {
      dispatch({ type: SETTING_PERTANYAAN_INTERVIEW_ACTION_DELETE_LOADING });
      const IpAddres = Get_IpAddres();

      const Data = await axios.delete(
        `${IpAddres}/setting/interview/pertanyaan/${Id}/delete`,
        tokenConfig(getState)
      );
      if (Data.status === 200) {
        dispatch(Create_Success_Messages(200, "Berhasil dihapus"));
        dispatch({
          type: SETTING_PERTANYAAN_INTERVIEW_ACTION_DELETE_LOADING_DONE,
        });
        dispatch({ type: AUTH_RELOADE_PAGE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak ditemukan"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({
        type: SETTING_PERTANYAAN_INTERVIEW_ACTION_DELETE_LOADING_DONE,
      });
      ErrorHandler(err, "Delete_Pertanyaan_Interview");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
      //     }
      // } catch (err2) {
      // }
    }
  };

//* PERTANYAAN_BIODATA_JAWABAN
export const Load_Pertanyaan_BIODATA_JAWABAN_List =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: SETTING_PERTANYAAN_BIODATA_JAWABAN_LOADING });
      const IpAddres = Get_IpAddres();

      const Data = await axios.get(
        `${IpAddres}/setting/pertanyaan/jawaban`,
        tokenConfig(getState)
      );
      if (Data) {
        dispatch({
          type: SETTING_PERTANYAAN_BIODATA_JAWABAN_LOADED,
          payload: Data.data.Data,
        });
        dispatch({ type: SETTING_PERTANYAAN_BIODATA_JAWABAN_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak ditemukan"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: SETTING_PERTANYAAN_BIODATA_JAWABAN_LOADING_DONE });
      ErrorHandler(err, "Load_Pertanyaan_BIODATA_JAWABAN_List");
    }
  };

export const Add_Pertanyaan_BIODATA_JAWABAN =
  (Nama, Deskripsi) => async (dispatch, getState) => {
    try {
      dispatch({ type: SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_ADD_LOADING });
      if (!Nama) {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Form Tidak Lengkap"
        );
        dispatch(Create_Error_Messages(400, "Form Tidak Lengkap"));
        throw ErrorResponse;
      }
      const IpAddres = Get_IpAddres();

      const body = JSON.stringify({ Nama, Deskripsi });
      const Data = await axios.post(
        `${IpAddres}/setting/pertanyaan/jawaban`,
        body,
        tokenConfig(getState)
      );
      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(
          Create_Success_Messages(200, `Pertanyaan ${Nama} Berhasil dibuat`)
        );
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({
        type: SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_ADD_LOADING_DONE,
      });
      ErrorHandler(err, "Add_Pertanyaan_BIODATA_JAWABAN");
      try {
        if (
          err.response.data.msg &&
          err.response.data.msg === "Data Sudah Ada"
        ) {
          dispatch({
            type: SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_ADD_ERROR,
            payload: true,
          });
        }
      } catch (err) {}
    }
  };

export const get_Pertanyaan_BIODATA_JAWABAN_Action_Id = (Id) => (dispatch) => {
  dispatch({
    type: SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_ID,
    payload: Id,
  });
};

export const get_Pertanyaan_BIODATA_JAWABAN_Data =
  (Id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_DATA_LOADING,
      });
      const IpAddres = Get_IpAddres();

      const Data = await axios.get(
        `${IpAddres}/setting/pertanyaan/jawaban/${Id}`,
        tokenConfig(getState)
      );
      if (Data.status === 200) {
        dispatch({
          type: SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_DATA_LOADED,
          payload: Data.data,
        });
        dispatch({
          type: SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_DATA_LOADING_DONE,
        });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak ditemukan"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({
        type: SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_DATA_LOADING_DONE,
      });
      ErrorHandler(err, "get_Pertanyaan_BIODATA_JAWABAN_Data");
    }
  };

export const Delete_Pertanyaan_BIODATA_JAWABAN =
  (Id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_DELETE_LOADING,
      });
      const IpAddres = Get_IpAddres();

      const Data = await axios.delete(
        `${IpAddres}/setting/pertanyaan/jawaban/${Id}/delete`,
        tokenConfig(getState)
      );
      if (Data.status === 200) {
        dispatch(Create_Success_Messages(200, "Berhasil dihapus"));
        dispatch({
          type: SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_DELETE_LOADING_DONE,
        });
        dispatch({ type: AUTH_RELOADE_PAGE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak ditemukan"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({
        type: SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_DELETE_LOADING_DONE,
      });
      ErrorHandler(err, "Delete_Pertanyaan_BIODATA_JAWABAN");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
      //     }
      // } catch (err2) {
      // }
    }
  };

//* PERTANYAAN_BIODATA_DESKRIPSI
export const Load_Pertanyaan_BIODATA_DESKRIPSI_List =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: SETTING_PERTANYAAN_BIODATA_DESKRIPSI_LOADING });
      const IpAddres = Get_IpAddres();

      const Data = await axios.get(
        `${IpAddres}/setting/pertanyaan/jawaban`,
        tokenConfig(getState)
      );
      if (Data) {
        dispatch({
          type: SETTING_PERTANYAAN_BIODATA_DESKRIPSI_LOADED,
          payload: Data.data.Data,
        });
        dispatch({ type: SETTING_PERTANYAAN_BIODATA_DESKRIPSI_LOADING_DONE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak ditemukan"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({ type: SETTING_PERTANYAAN_BIODATA_DESKRIPSI_LOADING_DONE });
      ErrorHandler(err, "Load_Pertanyaan_BIODATA_DESKRIPSI_List");
    }
  };

export const Add_Pertanyaan_BIODATA_DESKRIPSI =
  (Nama, Deskripsi) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_ADD_LOADING,
      });
      if (!Nama) {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Form Tidak Lengkap"
        );
        dispatch(Create_Error_Messages(400, "Form Tidak Lengkap"));
        throw ErrorResponse;
      }
      const IpAddres = Get_IpAddres();

      const body = JSON.stringify({ Nama, Deskripsi });
      const Data = await axios.post(
        `${IpAddres}/setting/pertanyaan/jawaban`,
        body,
        tokenConfig(getState)
      );
      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(
          Create_Success_Messages(200, `Pertanyaan ${Nama} Berhasil dibuat`)
        );
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({
        type: SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_ADD_LOADING_DONE,
      });
      ErrorHandler(err, "Add_Pertanyaan_BIODATA_DESKRIPSI");
      try {
        if (
          err.response.data.msg &&
          err.response.data.msg === "Data Sudah Ada"
        ) {
          dispatch({
            type: SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_ADD_ERROR,
            payload: true,
          });
        }
      } catch (err) {}
    }
  };

export const get_Pertanyaan_BIODATA_DESKRIPSI_Action_Id =
  (Id) => (dispatch) => {
    dispatch({
      type: SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_ID,
      payload: Id,
    });
  };

export const get_Pertanyaan_BIODATA_DESKRIPSI_Data =
  (Id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_DATA_LOADING,
      });
      const IpAddres = Get_IpAddres();

      const Data = await axios.get(
        `${IpAddres}/setting/pertanyaan/jawaban/${Id}`,
        tokenConfig(getState)
      );
      if (Data.status === 200) {
        dispatch({
          type: SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_DATA_LOADED,
          payload: Data.data,
        });
        dispatch({
          type: SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_DATA_LOADING_DONE,
        });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak ditemukan"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({
        type: SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_DATA_LOADING_DONE,
      });
      ErrorHandler(err, "get_Pertanyaan_BIODATA_DESKRIPSI_Data");
    }
  };

export const Delete_Pertanyaan_BIODATA_DESKRIPSI =
  (Id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_DELETE_LOADING,
      });
      const IpAddres = Get_IpAddres();

      const Data = await axios.delete(
        `${IpAddres}/setting/pertanyaan/jawaban/${Id}/delete`,
        tokenConfig(getState)
      );
      if (Data.status === 200) {
        dispatch(Create_Success_Messages(200, "Berhasil dihapus"));
        dispatch({
          type: SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_DELETE_LOADING_DONE,
        });
        dispatch({ type: AUTH_RELOADE_PAGE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak ditemukan"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      dispatch({
        type: SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_DELETE_LOADING_DONE,
      });
      ErrorHandler(err, "Delete_Pertanyaan_BIODATA_DESKRIPSI");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
      //     }
      // } catch (err2) {
      // }
    }
  };

//* ACCOUNT
export const Load_Account_List_Setting = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_ACCOUNT_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.get(
      `${IpAddres}/setting/account/`,
      tokenConfig(getState)
    );
    if (Data) {
      dispatch({
        type: SETTING_ACCOUNT_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: SETTING_ACCOUNT_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_ACCOUNT_LOADING_DONE });
    ErrorHandler(err, "Load_Account_List_Setting");
    // try {
    //     dispatch({ type: SETTING_ACCOUNT_LOADING_DONE })
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else {
    //     }
    // } catch (err2) {
    // }
  }
};

export const get_Account_Data = (Id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTING_ACCOUNT_DETAIL_LOADING });
    const IpAddres = Get_IpAddres();

    const Data = await axios.get(
      `${IpAddres}/setting/account/detail/${Id}`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch({
        type: SETTING_ACCOUNT_DETAIL_LOADED,
        payload: Data.data.Data,
      });
      dispatch({ type: SETTING_ACCOUNT_DETAIL_LOADING_DONE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    dispatch({ type: SETTING_ACCOUNT_DETAIL_LOADING_DONE });
    ErrorHandler(err, "get_Account_Data");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
    //     }
    // } catch (err2) {
    // }
  }
};

export const Add_Account =
  (Email, UserName, Password, isPelamar, isKaryawan, isAdmin) =>
  async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();

      const body = JSON.stringify({
        Email,
        UserName,
        Password,
        isPelamar,
        isKaryawan,
        isAdmin,
      });
      const Data = await axios.post(
        `${IpAddres}/setting/account/`,
        body,
        tokenConfig(getState)
      );
      if (Data.status === 200) {
        dispatch({ type: AUTH_RELOADE_PAGE });
        dispatch(Create_Success_Messages(200, `Account Berhasil dibuat`));
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "Add_Account");

      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         //     dispatch({
      //         //         type: SETTING_ROLE_ACTION_ADD_ERROR,
      //         //         payload: true
      //         //     })
      //         // }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Role Tida Bisa ditambah'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const Update_Account =
  (
    Id,
    isActive,
    isPendaftar,
    isAdmin,
    isKaryawan,
    isActiveted,
    isBiodataComplete,
    isBiodataExtend
  ) =>
  async (dispatch, getState) => {
    try {
      const IpAddres = Get_IpAddres();

      const body = JSON.stringify({
        isActive,
        isPendaftar,
        isAdmin,
        isKaryawan,
        isActiveted,
        isBiodataComplete,
        isBiodataExtend,
      });
      const Data = await axios.post(
        `${IpAddres}/setting/account/${Id}/update`,
        body,
        tokenConfig(getState)
      );
      if (Data.status === 200) {
        dispatch(Create_Success_Messages(200, `Account Berhasil diupdate`));
        dispatch({ type: AUTH_RELOADE_PAGE });
      } else {
        const ErrorResponse = Error_Messages_Standardization(
          400,
          "Data tidak lengkap"
        );
        throw ErrorResponse;
      }
    } catch (err) {
      ErrorHandler(err, "Update_Account");
      // try {
      //     if (err.message === 'Network Error') {
      //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
      //     } else if (err.response.data.msg) {
      //         dispatch(Create_Error_Messages(400, err.response.data.msg))
      //         // if (err.response.data.msg === 'Data Sudah Ada') {
      //         //     dispatch({
      //         //         type: SETTING_ROLE_ACTION_ADD_ERROR,
      //         //         payload: true
      //         //     })
      //         // }
      //     } else {
      //         dispatch(Create_Error_Messages(400, 'Role Tida Bisa ditambah'))
      //     }
      // } catch (err2) {
      // }
    }
  };

export const Delete_Account = (Id) => async (dispatch, getState) => {
  try {
    const IpAddres = Get_IpAddres();

    const Data = await axios.delete(
      `${IpAddres}/setting/account/${Id}/delete`,
      tokenConfig(getState)
    );
    if (Data.status === 200) {
      dispatch(Create_Success_Messages(200, "Berhasil dihapus"));
      dispatch({ type: AUTH_RELOADE_PAGE });
    } else {
      const ErrorResponse = Error_Messages_Standardization(
        400,
        "Data tidak ditemukan"
      );
      throw ErrorResponse;
    }
  } catch (err) {
    ErrorHandler(err, "Delete_Account");
    // try {
    //     if (err.message === 'Network Error') {
    //         dispatch(Create_Error_Messages(500, 'Tidak Terhubung'))
    //     } else {
    //         dispatch(Create_Error_Messages(400, 'Data tidak ditemukan'))
    //     }
    // } catch (err2) {
    // }
  }
};
