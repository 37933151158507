import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Pertanyaan_Interview_Data, Delete_Pertanyaan_Interview } from '../../../../../../Store/Actions/Setting.Action'

import { FormGroup, FormControlLabel, Checkbox, Button, CircularProgress } from '@mui/material'

import Detail from '../../../../../Containers/Dashboard/Setting/PertanyaanInterview/Detail'

class SettingPertanyaanDelete extends React.Component {
    state = {
        isConfirm: false,
    }
    componentDidUpdate(prevProps) {
        if (this.props.PertanyaanActionId !== prevProps.PertanyaanActionId) {
            this.props.get_Pertanyaan_Interview_Data(this.props.PertanyaanActionId)
        }
    }
    componentDidMount() {
        const { PertanyaanActionId } = this.props
        if (PertanyaanActionId !== null) {
            this.props.get_Pertanyaan_Interview_Data(PertanyaanActionId)
        }
    }

    OnChangeCheckbox = E => {
        this.setState({ [E.target.name]: !this.state[E.target.name] })
    }

    onClickDelete(Id) {
        this.props.Delete_Pertanyaan_Interview(Id)
    }
    render() {
        const PertanyaanActionId = this.props.PertanyaanActionId
        const isPertanyaanActionGetDetailLoading = this.props.isPertanyaanActionGetDetailLoading
        const PertanyaanDetail = this.props.PertanyaanDetail
        const {
            isConfirm
        } = this.state
        const isPertanyaanDeleteLoading = this.props.isPertanyaanDeleteLoading
        return (
            <Fragment>
                <Detail Data={PertanyaanDetail} isLoading={isPertanyaanActionGetDetailLoading} />
                <div
                    style={{ margin: '1%' }}
                >
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox color='primary' onChange={this.OnChangeCheckbox} name="isConfirm" value={isConfirm} disabled={isPertanyaanDeleteLoading ? true : false} />}
                            label="Saya menyadari akan resikonya dan saya tau apa yang saya lakukan"
                        />
                    </FormGroup>
                    <center>
                        <Button
                            variant='contained'
                            size='large'
                            color='error'
                            disabled={!isConfirm || isPertanyaanDeleteLoading ? true : false}
                            onClick={() => this.onClickDelete(PertanyaanActionId)}
                        >
                            {isPertanyaanDeleteLoading ? <CircularProgress /> : 'Delete'}
                        </Button>
                    </center>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isPertanyaanActionGetDetailLoading: state.Setting.isPertanyaanActionGetDetailLoading,
    isPertanyaanDeleteLoading: state.Setting.isPertanyaanDeleteLoading,
    PertanyaanActionId: state.Setting.PertanyaanActionId,
    PertanyaanDetail: state.Setting.PertanyaanDetail,
})

export default connect(mapStateToProps, { get_Pertanyaan_Interview_Data, Delete_Pertanyaan_Interview })(SettingPertanyaanDelete)
