import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Move_Detail, Get_Job_List, Move_Application, } from '../../../../../../Store/Actions/Job.Action'

import { Skeleton, FormControl, InputLabel, Select, TextField, Button } from '@mui/material'

// import CoTimeline from '../../../../../Containers/Dashboard/Timeline/Timeline'

class Detail extends React.Component {
    state = {
        MoveJob: '',
        Keterangan: ''
    }

    componentDidMount() {
        const { JobActionId, AccountActionId } = this.props
        this.props.get_Move_Detail(JobActionId, AccountActionId)
        this.props.Get_Job_List()
    }

    SelectFormOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    formOnSubmit = (e) => {
        e.preventDefault()

        this.props.Move_Application(
            this.props.JobActionId,
            this.props.AccountActionId,
            this.state.MoveJob,
            this.state.Keterangan,
        )
    }
    render() {
        const isMoveDetailLoading = this.props.isMoveDetailLoading
        const MoveDetail = this.props.MoveDetail
        const isJobListLoading = this.props.isJobListLoading
        const JobListData = this.props.JobListData

        const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }

        const {
            MoveJob,
            Keterangan,
        } = this.state

        return (
            <Fragment>
                {isMoveDetailLoading || isJobListLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5,].map((item) =>
                            <Skeleton
                                key={`DetailMenunggu_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <h2>{MoveDetail?.BiodataNama ? MoveDetail.BiodataNama : ''}</h2>

                        <div
                            style={{ margin: '5% 0 5% 0' }}
                        >
                            {/* <CoTimeline // TODO
                                TimeLineDetail={MoveDetail}
                            /> */}
                        </div>

                        <form
                            onSubmit={this.formOnSubmit}
                        >
                            <FormControl style={st_textfield} variant="filled" required >
                                <InputLabel shrink >Move to</InputLabel>
                                <Select native style={{ backgroundColor: 'white' }} onChange={(e) => this.SelectFormOnChange(e)} label="Move to" name='MoveJob' value={MoveJob} >
                                    <option value="" disabled> -- select an option -- </option>
                                    {JobListData.map((option, index) =>
                                        <option key={index} value={option.Id}>{`${option.Id}.${option.JobId} - ${option.DepartemenNama} - ${option.Nama}`}</option>
                                    )}
                                </Select>
                            </FormControl>
                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                required
                                onChange={this.onChangeField}
                                type='texts'
                                label='Keterangan'
                                name='Keterangan'
                                value={Keterangan}
                            />
                            <center>
                                <Button
                                    variant='contained'
                                    style={{ width: '80%', margin: '1% 0 3% 0' }}
                                    type='submit'
                                >
                                    Submit
                                </Button>
                            </center>
                        </form>
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isMoveDetailLoading: state.Job.isMoveDetailLoading,
    MoveDetail: state.Job.MoveDetail,
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
    JobListData: state.Job.JobList,
    isJobListLoading: state.Job.isJobListLoading,
})

export default connect(mapStateToProps, { get_Move_Detail, Get_Job_List, Move_Application })(Detail)
