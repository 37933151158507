import React, { Fragment, useEffect } from "react"

import CoTable from "../../../../Containers/Table"

import { VariableLimit, convertToDay } from "../../../../../Global"

const LeadTime = ({ isLoading, Data }) => {

    const [OriginalData, setOriginalData] = React.useState([])

    useEffect(() => {
        if (Data.length > 0) {
            const UniquePRNUmber = []

            // eslint-disable-next-line
            const newData = Data.filter((item, index) => {
                const PRId = item?.PRId ? item.PRId : ''
                const PosisiDibutuhkan = item?.PosisiDibutuhkan ? item.PosisiDibutuhkan : 0
                const DefaultReturn = {
                    PRId: item?.PRId ? item.PRId : '',
                    // DepartemenNama: item?.DepartemenNama ? item.DepartemenNama : '',
                    // Sub: item?.Sub ? item.Sub : '',
                    // DivisionNama: item?.DivisionNama ? item.DivisionNama : '',
                    // LokasiLainnya: item?.LokasiLainnya ? item.LokasiLainnya : '',
                    // LevelNama: item?.LevelNama ? item.LevelNama : '',
                    // StatusKaryawan: item?.StatusKaryawan ? item.StatusKaryawan : '',
                    // PosisiDibutuhkan: item?.PosisiDibutuhkan ? item.PosisiDibutuhkan : '',
                    // TotalPendaftar: Data.reduce((Accumulator, Item) => {
                    //     return Accumulator + (true ? 1 : 0)
                    // }, 0),
                    // TotalDiajukan: 0,
                    // CVUserApproved: 0,
                    // CVUserRejected: 0,
                    // InterviewHRApproved: 0,
                    // InterviewHRRejected: 0,
                    // InterviewUserApproved: 0,
                    // InterviewUserRejected: 0,
                    // Offering: 0,
                    // MCU: 0,
                    // MCUApproved: 0,
                    // PreBoarding: 0,
                    // Contract: 0,
                    // Withdrew: 0,
                    // Cancel: 0,
                    // Hired: 0,
                }
                if (!(UniquePRNUmber.includes(PRId))) {
                    UniquePRNUmber.push(PRId)
                    return DefaultReturn
                    // eslint-disable-next-line
                } else if ((UniquePRNUmber.filter((newItem) => newItem === PRId).length) < Number(PosisiDibutuhkan)) {
                    UniquePRNUmber.push(PRId)
                    return DefaultReturn
                }
            })

            setOriginalData(newData)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Data])

    const st_TableHead = { color: 'white', backgroundColor: '#00a3df' }
    const st_Data = { color: 'white', backgroundColor: '#C55A11' }
    const st_White = { backgroundColor: '#DEEBF7' }
    const st_Blue = { color: 'white', backgroundColor: '#0070C0' }
    const st_Grey = { backgroundColor: '#C9C9C9' }
    const st_TableBorderHead = { borderTop: '1px solid black', borderRight: '0px solid black', borderBottom: '1px solid black', borderLeft: '1px solid black', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }
    const st_TableBorder = { borderLeft: '1px solid black', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }

    const HeadData = [
        {
            Nama: 'No',
            width: '5%',
            KeyName: 'index',
            SortingType: 'Number',
            style: { ...st_Data, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'PR Number',
            width: '5%',
            KeyName: 'PRId',
            SortingType: 'Number',
            style: { ...st_Data, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Tahun',
            width: '5%',
            KeyName: 'Tahun',
            SortingType: 'Number',
            style: { ...st_Data, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Bulan Buka',
            width: '5%',
            KeyName: 'BulanBuka',
            style: { ...st_Data, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Departemen',
            width: '5%',
            KeyName: 'DepartemenNama',
            style: { ...st_Data, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Posisi',
            width: '5%',
            KeyName: 'Sub',
            style: { ...st_Data, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Divisi',
            width: '5%',
            KeyName: 'DivisionNama',
            style: { ...st_Data, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Level',
            width: '5%',
            KeyName: 'LevelNama',
            style: { ...st_Data, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Employee Type',
            width: '5%',
            KeyName: 'StatusKaryawan',
            style: { ...st_Data, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Quantity',
            width: '5%',
            KeyName: 'PosisiDibutuhkan',
            SortingType: 'Number',
            style: { ...st_Data, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Total Pendaftar',
            width: '5%',
            KeyName: 'TotalPendaftar',
            SortingType: 'Number',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Sourcing',
            width: '5%',
            KeyName: 'Sourcing',
            SortingType: 'Number',
            style: { ...st_White, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Personality Test',
            width: '5%',
            KeyName: 'PersonaltyTest',
            SortingType: 'Number',
            style: { ...st_White, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'HR Interview',
            width: '5%',
            KeyName: 'HRInterview',
            SortingType: 'Number',
            style: { ...st_White, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        // {
        //     Nama: 'Departemen Interview',
        //     width: '5%',
        //     KeyName: 'DepartemenInterview',
        //     SortingType: 'Number',
        //     style: { ...st_Grey, ...st_TableBorderHead },
        //     BodyStyle: { ...st_TableBorder }
        // },
        {
            Nama: 'Departemen Final Interview',
            width: '5%',
            KeyName: 'DepartemenFinalInterview',
            SortingType: 'Number',
            style: { ...st_Grey, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Offering',
            width: '5%',
            KeyName: 'Offering',
            SortingType: 'Number',
            style: { ...st_White, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'MCU',
            width: '5%',
            KeyName: 'MCU',
            SortingType: 'Number',
            style: { ...st_White, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'MCU Approval',
            width: '5%',
            KeyName: 'MCUApproval',
            SortingType: 'Number',
            style: { ...st_White, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'PreBoarding',
            width: '5%',
            KeyName: 'PreBoarding',
            SortingType: 'Number',
            style: { ...st_White, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Contract',
            width: '5%',
            KeyName: 'Contract',
            SortingType: 'Number',
            style: { ...st_White, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Hired',
            width: '5%',
            KeyName: 'Hired',
            SortingType: 'Number',
            style: { ...st_White, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Total Lead Time',
            width: '5%',
            KeyName: 'TotalLeadTime',
            SortingType: 'Number',
            style: { ...st_Blue, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder, ...{ borderRight: '1px solid black' } }
        },
    ]

    const UsedPR = []

    const BodyData = OriginalData ? OriginalData.map((item, index) => {
        const PRId = item?.PRId ? item.PRId : ''

        // const PosisiDibutuhkan = item?.PosisiDibutuhkan ? item.PosisiDibutuhkan : ''
        const OpenDate = item?.OpenDate ? new Date(item.OpenDate).getTime() : ''
        const OpenEditDate = item?.OpenEditDate ? new Date(item.OpenEditDate) : ''

        // eslint-disable-next-line
        const newData = Data.filter((item, index) => {
            if (item.PRId === PRId) {
                return { ...item }
            }
        })

        const createBestCandidate = (ArrayValue, PRIdValue) => {
            for (const x in ArrayValue) {
                const Data = ArrayValue[x]
                const PRId = Data?.PRId ? Data.PRId : ''

                if (PRIdValue === PRId && Data.OBStatus === VariableLimit.JobProcess.OnBoarding) {
                    UsedPR.push(Data)
                    return Data
                } else if (PRIdValue === PRId && Data.KoStatus === VariableLimit.JobProcess.Contract) {
                    UsedPR.push(Data)
                    return Data
                } else if (PRIdValue === PRId && Data.BPStatus === VariableLimit.JobProcess.PreBoarding) {
                    UsedPR.push(Data)
                    return Data
                } else if (PRIdValue === PRId && Data.AccStatus === VariableLimit.JobProcess.MCUApproval) {
                    UsedPR.push(Data)
                    return Data
                } else if (PRIdValue === PRId && Data.AppDate) {
                    UsedPR.push(Data)
                    return Data
                } else if (PRIdValue === PRId && Data.OfStatus === VariableLimit.JobProcess.Offering) {
                    UsedPR.push(Data)
                    return Data
                } else if (PRIdValue === PRId && Data.UsIFStatus === VariableLimit.JobProcess.UserFinalInterview) {
                    UsedPR.push(Data)
                    return Data
                } else if (PRIdValue === PRId && Data.HrIStatus === VariableLimit.JobProcess.HRInterview) {
                    UsedPR.push(Data)
                    return Data
                } else if (PRIdValue === PRId && Data.PTId) {
                    UsedPR.push(Data)
                    return Data
                } else if (PRIdValue === PRId && Data.AccReferralStatus === VariableLimit.JobProcess.Referral) {
                    UsedPR.push(Data)
                    return Data
                }

            }
        }

        const BestCandidate = createBestCandidate(newData.length > 0 ?
            // eslint-disable-next-line
            newData.filter((item, index) => {
                // JobApplyId
                const isInUsedPR = UsedPR.find((i) => i.JobApplyId === item.JobApplyId)
                if (!isInUsedPR) {
                    return { ...item }
                }
            })
            : newData, PRId)

        const SourcingLead = convertToDay(OpenEditDate ? OpenEditDate : OpenDate ? OpenDate : '', BestCandidate?.AccReferralTime ? BestCandidate.AccReferralTime : '')

        const PersonaltyTestLead = convertToDay(BestCandidate?.AccReferralTime ? BestCandidate.AccReferralTime : '', BestCandidate?.PTTime ? BestCandidate.PTTime : '')

        const HRInterviewLead = convertToDay(BestCandidate?.PTTime ? BestCandidate.PTTime : '', BestCandidate?.HrITime ? BestCandidate.HrITime : '')

        // const DepartemenInterviewLead = 

        const DepartemenFinalInterviewLead = convertToDay(BestCandidate?.HrITime ? BestCandidate.HrITime : '', BestCandidate?.UsIFTime ? BestCandidate.UsIFTime : '')

        const OfferingLead = convertToDay(BestCandidate?.UsIFTime ? BestCandidate.UsIFTime : '', BestCandidate?.OfTime ? BestCandidate.OfTime : '')

        const MCULead = convertToDay(BestCandidate?.OfTime ? BestCandidate.OfTime : '', BestCandidate?.AppTime ? BestCandidate.AppTime : '')

        const MCUApprovalLead = convertToDay(BestCandidate?.AppTime ? BestCandidate.AppTime : '', BestCandidate?.AccTime ? BestCandidate.AccTime : '')

        const PreBoardingLead = convertToDay(BestCandidate?.AccTime ? BestCandidate.AccTime : '', BestCandidate?.BPDate ? BestCandidate.BPDate : '')

        const ContractLead = convertToDay(BestCandidate?.BPDate ? BestCandidate.BPDate : '', BestCandidate?.KoDate ? BestCandidate.KoDate : '')

        const HiredLead = convertToDay(BestCandidate?.KoDate ? BestCandidate.KoDate : '', BestCandidate?.OBDate ? BestCandidate.OBDate : '')

        // const TotalLeadTimeLead = (
        //     Number(SourcingLead ? SourcingLead : 0)
        //     + Number(PersonaltyTestLead ? PersonaltyTestLead : 0)
        //     + Number(HRInterviewLead ? HRInterviewLead : 0)
        //     + Number(DepartemenFinalInterviewLead ? DepartemenFinalInterviewLead : 0)
        //     + Number(OfferingLead ? OfferingLead : 0)
        //     + Number(MCULead ? MCULead : 0)
        //     + Number(MCUApprovalLead ? MCUApprovalLead : 0)
        //     + Number(PreBoardingLead ? PreBoardingLead : 0)
        //     + Number(ContractLead ? ContractLead : 0)
        //     + Number(HiredLead ? HiredLead : 0)
        // )

        const TotalLeadTimeLead = convertToDay(OpenEditDate ? OpenEditDate : OpenDate ? OpenDate : '', BestCandidate?.OBDate ? BestCandidate.OBDate : '')

        const DefaultReturn = {
            index: {
                value: index + 1
            },
            PRId: {
                value: item?.PRId ? item.PRId : ' '
            },
            Tahun: {
                value: item?.Tahun ? item.Tahun : ' '
            },
            BulanBuka: {
                value: item ? item.BulanBuka + 1 : ' '
            },
            DepartemenNama: {
                value: item?.DepartemenNama ? item.DepartemenNama : ' '
            },
            Sub: {
                value: item?.Sub ? item.Sub : ' '
            },
            DivisionNama: {
                value: item?.DivisionNama ? item.DivisionNama : ' '
            },
            LevelNama: {
                value: item?.LevelNama ? item.LevelNama : ' '
            },
            StatusKaryawan: {
                value: item?.StatusKaryawan ? item.StatusKaryawan : ' '
            },
            PosisiDibutuhkan: {
                value: item?.PosisiDibutuhkan ? 1 : ' '
            },
            TotalPendaftar: {
                value: Data.reduce((Accumulator, Item) => {
                    return Accumulator + (Item.PRId === PRId ? 1 : 0)
                }, 0) ? Data.reduce((Accumulator, Item) => {
                    return Accumulator + (Item.PRId === PRId ? 1 : 0)
                }, 0) : ' '
            },
            Sourcing: {
                value: SourcingLead ? SourcingLead : ' ',
                display: SourcingLead ? (
                    <Fragment>
                        <b>{SourcingLead}</b> days from <b>{OpenEditDate ? 'Job Open' : 'Job Posted'}</b>
                    </Fragment>) : ' '
            },
            PersonaltyTest: {
                value: PersonaltyTestLead ? PersonaltyTestLead : ' ',
                display: PersonaltyTestLead ? (
                    <Fragment>
                        <b>{PersonaltyTestLead}</b> days from <b> Departemen CV Approval</b>
                    </Fragment>) : ' '
            },
            HRInterview: {
                value: HRInterviewLead ? HRInterviewLead : ' ',
                display: HRInterviewLead ? (
                    <Fragment>
                        <b>{HRInterviewLead}</b> days from <b> Personality Test</b>
                    </Fragment>) : ' '
            },
            // DepartemenInterview: {
            //     value: DepartemenInterviewLead ? DepartemenInterviewLead : ' '
            // },
            DepartemenFinalInterview: {
                value: DepartemenFinalInterviewLead ? DepartemenFinalInterviewLead : ' ',
                display: DepartemenFinalInterviewLead ? (
                    <Fragment>
                        <b>{DepartemenFinalInterviewLead}</b> days from <b> HR Interview</b>
                    </Fragment>) : ' '
            },
            Offering: {
                value: OfferingLead ? OfferingLead : ' ',
                display: OfferingLead ? (
                    <Fragment>
                        <b>{OfferingLead}</b> days from <b> Departemen Interview</b>
                    </Fragment>) : ' '
            },
            MCU: {
                value: MCULead ? MCULead : ' ',
                display: MCULead ? (
                    <Fragment>
                        <b>{MCULead}</b> days from <b> Offering</b>
                    </Fragment>) : ' '
            },
            MCUApproval: {
                value: MCUApprovalLead ? MCUApprovalLead : ' ',
                display: MCUApprovalLead ? (
                    <Fragment>
                        <b>{MCUApprovalLead}</b> days from <b> MCU</b>
                    </Fragment>) : ' '
            },
            PreBoarding: {
                value: PreBoardingLead ? PreBoardingLead : ' ',
                display: PreBoardingLead ? (
                    <Fragment>
                        <b>{PreBoardingLead}</b> days from <b> MCU Approval</b>
                    </Fragment>) : ' '
            },
            Contract: {
                value: ContractLead ? ContractLead : ' ',
                display: ContractLead ? (
                    <Fragment>
                        <b>{ContractLead}</b> days from <b> Pre Boarding</b>
                    </Fragment>) : ' '
            },
            Hired: {
                value: HiredLead ? HiredLead : ' ',
                display: HiredLead ? (
                    <Fragment>
                        <b>{HiredLead}</b> days from <b> Contract</b>
                    </Fragment>) : ' '
            },
            TotalLeadTime: {
                value: BestCandidate?.OBDate ? TotalLeadTimeLead ? TotalLeadTimeLead : ' ' : ' ',
                display: BestCandidate?.OBDate ? TotalLeadTimeLead ? (
                    <Fragment>
                        <b>{TotalLeadTimeLead}</b> days from <b> {OpenEditDate ? 'Job Open' : 'Job Posted'}</b> to <b> Hired</b>
                    </Fragment>) : ' ' : ' '
            },
        }

        return DefaultReturn
    }) : []

    return (
        <Fragment>
            <CoTable
                TableId={'CandidateJourneyComp'}
                HeadData={HeadData}
                Data={BodyData}
                isHeadLoading={isLoading}
                isBodyLoading={isLoading}
                isPagination={BodyData.length > 5 ? true : false}
                isPaginationLoading={isLoading}
                isHeading={true}
                isDownload={true}
                ComplexDataDownload={true}
            />
        </Fragment>
    )
}

export default LeadTime