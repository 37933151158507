import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Konfirm_PKWT } from '../../../../../../Store/Actions/Job.Action'

import { FormControl, InputLabel, Select, TextField, Button } from '@mui/material'

class SubmitPKWT extends React.Component {
    state = {
        Status: '',
        JoinDate: '',
        EndDate: '',
        Keterangan: '',
        AdjGol: '',
        AdjLevelNama: '',
        AdjSub: '',
        AdjCoDur: '',
    }

    componentDidMount() {
        const { AdjGol, AdjLevelNama, AdjSub, AdjCoDur } = this.props
        if (AdjGol) {
            this.setState({ AdjGol: AdjGol })
        }
        if (AdjLevelNama) {
            this.setState({ AdjLevelNama: AdjLevelNama })
        }
        if (AdjSub) {
            this.setState({ AdjSub: AdjSub })
        }
        if (AdjCoDur) {
            this.setState({ AdjCoDur: AdjCoDur })
        }
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    formOnSubmit = (e) => {
        e.preventDefault()

        const JobActionId = this.props.JobActionId
        const AccountActionId = this.props.AccountActionId

        this.props.Konfirm_PKWT(
            JobActionId,
            AccountActionId,
            this.state.Status,
            this.state.JoinDate,
            this.state.EndDate,
            this.state.Keterangan,
            this.state.AdjCoDur,
        )
    }
    render() {
        const st_textfield_non = { marginTop: '10px', marginBottom: '10px', width: '100%' }
        const st_textfield_disable = { marginTop: '10px', marginBottom: '10px', width: '100%', backgroundColor: '#E0E0E0' }

        const {
            Status,
            JoinDate,
            EndDate,
            Keterangan,
            AdjGol,
            AdjLevelNama,
            AdjSub,
            AdjCoDur
        } = this.state

        return (
            <Fragment>
                <form
                    onSubmit={this.formOnSubmit}
                >
                    <TextField
                        style={st_textfield_disable}
                        variant='outlined'
                        type='text'
                        label='Golongan'
                        name='AdjGol'
                        value={AdjGol}
                        // onChange={this.onChangeField}
                        disabled
                    />
                    <TextField
                        style={st_textfield_disable}
                        variant='outlined'
                        type='text'
                        label='Level'
                        name='AdjLevelNama'
                        value={AdjLevelNama}
                        // onChange={this.onChangeField}
                        disabled
                    />
                    <TextField
                        style={st_textfield_disable}
                        variant='outlined'
                        type='text'
                        label='Sub'
                        name='AdjSub'
                        value={AdjSub}
                        // onChange={this.onChangeField}
                        disabled
                    />
                    <TextField
                        style={st_textfield_non}
                        variant='outlined'
                        type='number'
                        label='Contract Duration (In Month)'
                        name='AdjCoDur'
                        value={AdjCoDur}
                        onChange={this.onChangeField}
                    // disabled
                    />
                    <FormControl style={st_textfield_non} variant="filled" required >
                        <InputLabel shrink >Status PKWT/PKWTT</InputLabel>
                        <Select style={{ backgroundColor: 'white' }} native onChange={(e) => this.onChangeField(e)} label="Status" name='Status' value={Status}  >
                            <option value="" disabled> -- select an option -- </option>
                            {['PKWT', 'PKWTT'].map((option, index) =>
                                <option key={index} value={option}>{option}</option>
                            )}
                        </Select>
                    </FormControl>

                    {Status ?
                        <TextField
                            style={st_textfield_non}
                            variant='outlined'
                            type='date'
                            label='Join Date'
                            name='JoinDate'
                            InputLabelProps={{ shrink: true }}
                            onChange={this.onChangeField}
                            required
                            value={JoinDate}
                        /> :
                        <Fragment></Fragment>}

                    {Status === 'PKWT' ?
                        <TextField
                            style={st_textfield_non}
                            variant='outlined'
                            type='date'
                            label='End Date'
                            name='EndDate'
                            InputLabelProps={{ shrink: true }}
                            onChange={this.onChangeField}
                            required
                            value={EndDate}
                        /> :
                        <Fragment></Fragment>}
                    <TextField
                        style={st_textfield_non}
                        variant='outlined'
                        type='text'
                        label='Keterangan'
                        name='Keterangan'
                        value={Keterangan}
                        onChange={this.onChangeField}
                    />

                    <center>
                        <Button
                            variant='contained'
                            style={{ margin: '10% 0 5% 0', width: '60%' }}
                            type='submit'
                        >
                            Submit
                        </Button>
                    </center>
                </form>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
})

export default connect(mapStateToProps, { Konfirm_PKWT })(SubmitPKWT)
