import React, { Fragment } from 'react'

import { useHistory } from 'react-router-dom'

import { ListItemButton, Badge, ListItemText, ListItemIcon, Collapse } from '@mui/material'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

const Menu = ({ User, MenuTitle, Privacry, AllowedRole = [], AllowedLevel = [], AllowedDepartment = [], Link, MenuTitlebadgeContent, MenuTitleColor, MenuTitleVariant, MenuTitleVertical, MenuTitleHorizontal, MenuCollapse, ...props }) => {
    const [isSubMenuOpen, setSubMenuOpen] = React.useState(false)

    const History = useHistory()

    const isAdmin = User ? User.isAdmin : false
    const isKaryawan = User ? User.isKaryawan : false
    const Role = User ? User.Role : []
    const Department = User ? User.Department : []
    const Level = User ? User.Level : ''

    const goToLink = (GoToLink) => {
        if (GoToLink) {
            History.push(GoToLink)
            window.scrollTo({ top: 0, behavior: 'smooth' })
        } else {
        }
    }

    const ItemCompNotif = ({ MenuTitlebadgeContent, MenuTitleColor, MenuTitleVariant, MenuTitleVertical, MenuTitleHorizontal, MenuTitle }) => (
        <Fragment>
            <Badge
                badgeContent={MenuTitlebadgeContent ? MenuTitlebadgeContent : 0}
                color={MenuTitleColor ? MenuTitleColor : "primary"}
                variant={MenuTitleVariant ? MenuTitleVariant : "dot"}
                anchorOrigin={{
                    vertical: MenuTitleVertical ? MenuTitleVertical : 'bottom',
                    horizontal: MenuTitleHorizontal ? MenuTitleHorizontal : 'right',
                }}
            >
                {MenuTitle ? MenuTitle : 'MenuTitle'}
            </Badge>
        </Fragment >
    )

    const HeadComp = () => (
        <Fragment>
            <ListItemButton
                sx={{ pl: 1 }}
                onClick={() => { Link ? goToLink(Link) : setSubMenuOpen(!isSubMenuOpen) }}
            >
                {MenuTitlebadgeContent ?
                    <ListItemText>
                        <ItemCompNotif
                            MenuTitlebadgeContent={MenuTitlebadgeContent}
                            MenuTitleColor={MenuTitleColor}
                            MenuTitleVariant={MenuTitleVariant}
                            MenuTitleVertical={MenuTitleVertical}
                            MenuTitleHorizontal={MenuTitleHorizontal}
                            MenuTitle={MenuTitle}
                        />
                    </ListItemText>
                    : <ListItemText>
                        {MenuTitle ? MenuTitle : 'MenuTitle'}
                    </ListItemText>
                }
                {MenuCollapse ?
                    <ListItemIcon>
                        {isSubMenuOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemIcon>
                    : null
                }
            </ListItemButton >
        </Fragment >
    )

    const CollapseComp = ({ Link, MenuTitlebadgeContent, MenuTitleColor, MenuTitleVariant, MenuTitleVertical, MenuTitleHorizontal, MenuTitle }) => (
        <Fragment>
            <ListItemButton
                sx={{ pl: 3 }}
                onClick={() => goToLink(Link)}
            >
                {MenuTitlebadgeContent ?
                    <ListItemText>
                        <ItemCompNotif
                            MenuTitlebadgeContent={MenuTitlebadgeContent}
                            MenuTitleColor={MenuTitleColor}
                            MenuTitleVariant={MenuTitleVariant}
                            MenuTitleVertical={MenuTitleVertical}
                            MenuTitleHorizontal={MenuTitleHorizontal}
                            MenuTitle={MenuTitle}
                        />
                    </ListItemText>
                    : <ListItemText>
                        {MenuTitle ? MenuTitle : 'MenuTitle'}
                    </ListItemText>
                }
            </ListItemButton>
        </Fragment>
    )

    const isThereAreForbid = (MenuCollapseData, MenuTitle) => {
        try {
            const newAllowedRole = []

            for (const x in MenuCollapseData) {
                const Data = MenuCollapseData[x]
                const AllowedRole = Data?.AllowedRole ? Data.AllowedRole : []
                for (const y in AllowedRole) {
                    const isInArray = newAllowedRole.includes(AllowedRole[y])
                    if (!isInArray) {
                        newAllowedRole.push(AllowedRole[y])
                    }
                }
            }

            const isAllowedRole = AllowedRole.length === 0 ? true : AllowedRole.some(r => Role.includes(r))
            const isRoleShowedMenu = newAllowedRole.length === 0 ? true : newAllowedRole.some(r => Role.includes(r))
            const isLevelShowedMenu = AllowedLevel.length === 0 ? true : AllowedLevel.includes(Level)
            const isDepartmentShowedMenu = AllowedDepartment.length === 0 ? true : Department.some(r => AllowedDepartment.includes(r.DepartemenId))

            // console.log(MenuTitle, newAllowedRole)

            const isShow = isAllowedRole && isRoleShowedMenu && isLevelShowedMenu && isDepartmentShowedMenu

            return isShow

        } catch (err) {
            console.log(`🚀 ~ file: Menu.js ~ line 102 ~ isThereAreForbid ~ err`, err)
            return false
        }
    }

    const isShowMenu = isAdmin ? true
        : !MenuCollapse ? true
            : isThereAreForbid(MenuCollapse, MenuTitle ? MenuTitle : '')

    return (
        <div>
            {Privacry === 'SuperPrivacry' && isAdmin ?
                <HeadComp />
                : Privacry === 'Privacry' && (isAdmin || isKaryawan) && isShowMenu ?
                    <HeadComp />
                    : null}

            {MenuCollapse ?
                <Fragment>
                    <Collapse in={isSubMenuOpen} timeout="auto" unmountOnExit>
                        {MenuCollapse.map((item, index) => {
                            const CollapseAlloweRole = item.AllowedRole ? item.AllowedRole : []
                            const CollapseAlloweLevel = item.AllowedLevel ? item.AllowedLevel : []
                            const CollapseAllowedDepartment = item.AllowedDepartment ? item.AllowedDepartment : []
                            const isCollapseRoleAllow = CollapseAlloweRole.length === 0 ? true : CollapseAlloweRole.some(r => Role.includes(r))
                            const isCollapseLevelAllow = CollapseAlloweLevel.length === 0 ? true : CollapseAlloweLevel.includes(Level)
                            const isCollapseDepartmentAllow = CollapseAllowedDepartment.length === 0 ? true : Department.some(r => CollapseAllowedDepartment.includes(r.DepartemenId))

                            return (
                                <Fragment
                                    key={index}
                                >
                                    {Privacry === 'SuperPrivacry' && isAdmin ?
                                        <CollapseComp
                                            Link={item.Link}
                                            MenuTitlebadgeContent={item.MenuTitlebadgeContent}
                                            MenuTitleColor={item.MenuTitleColor}
                                            MenuTitleVariant={item.MenuTitleVariant}
                                            MenuTitleVertical={item.MenuTitleVertical}
                                            MenuTitleHorizontal={item.MenuTitleHorizontal}
                                            MenuTitle={item.MenuTitle}
                                        />
                                        : Privacry === 'Privacry' && isAdmin ?
                                            <CollapseComp
                                                Link={item.Link}
                                                MenuTitlebadgeContent={item.MenuTitlebadgeContent}
                                                MenuTitleColor={item.MenuTitleColor}
                                                MenuTitleVariant={item.MenuTitleVariant}
                                                MenuTitleVertical={item.MenuTitleVertical}
                                                MenuTitleHorizontal={item.MenuTitleHorizontal}
                                                MenuTitle={item.MenuTitle}
                                            />
                                            : Privacry === 'Privacry' && isKaryawan && isCollapseRoleAllow && isCollapseLevelAllow && isCollapseDepartmentAllow ?
                                                <CollapseComp
                                                    Link={item.Link}
                                                    MenuTitlebadgeContent={item.MenuTitlebadgeContent}
                                                    MenuTitleColor={item.MenuTitleColor}
                                                    MenuTitleVariant={item.MenuTitleVariant}
                                                    MenuTitleVertical={item.MenuTitleVertical}
                                                    MenuTitleHorizontal={item.MenuTitleHorizontal}
                                                    MenuTitle={item.MenuTitle}
                                                />
                                                : null}
                                </Fragment>
                            )

                        })}
                    </Collapse>
                </Fragment>
                : null}
        </div >
    )
}

export default Menu