export const Color = {
    Text: {
        primary: 'black',
        secondary: "#797b7c"
    }
}

export const InitState = {
    Units: {
        minWidth_first: 1000,
    }
}