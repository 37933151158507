import React, { Fragment } from 'react'

import { Skeleton, useMediaQuery } from '@mui/material'

import { InitState } from '../../../Style'
import { ImageURL } from '../../../Global'

// import SomePDF from '../../../Img/Test.pdf'

import { isDev } from '../../../Global'

const CurriculumVitae = ({ isLoading, Data }) => {
    const CV = Data.CV ? Data.CV : null

    const MinWindowFirst = useMediaQuery(`(min-width:${InitState.Units.minWidth_first}px)`)

    const DefaultUrl = ImageURL.Base

    const isInDev = isDev()
    if (isInDev) {
        console.log(CV ? `CV: ${DefaultUrl}/${CV}` : '')
    }

    return (
        <Fragment>
            {isLoading ?
                <Fragment>
                    {[1, 2, 3, 4, 5,].map((item) =>
                        <Skeleton
                            key={`Biodata_Skeleton_${item}`}
                            height={'5vh'}
                        />
                    )}
                </Fragment> :
                <Fragment>
                    <center>
                        <div>
                            {CV ?
                                <center>
                                    <iframe
                                        src={`${DefaultUrl}/${CV}`}
                                        // height="1000"
                                        // width="1000"
                                        title='Curriculum Vitae'
                                        style={{ width: MinWindowFirst ? '60vw' : '60vw', minHeight: '100vh' }}
                                    />
                                </center>
                                : <Fragment>
                                    <center>
                                        <h1>
                                            No Curriculum Vitae
                                        </h1>
                                    </center>
                                </Fragment>
                            }
                        </div>
                    </center>
                </Fragment>}
        </Fragment>
    )
}

export default CurriculumVitae