import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_PR_Data, Konfirm_PR } from '../../../../../../Store/Actions/PR.Action'

import { Button, TextField, FormControl, InputLabel, Select, CircularProgress, FormGroup, FormControlLabel, Checkbox } from '@mui/material'

import CoDetailPR from '../../../../../Containers/Dashboard/PR/DetailPR'

class KonfirmasiPengajuanPR extends React.Component {
    state = {
        Action: '',
        isConfirm: false,
        Keterangan: '',
    }

    componentDidUpdate(prevProps) {
        if (this.props.PRActionId !== prevProps.PRActionId) {
            this.props.get_PR_Data(this.props.PRActionId)
        }
    }

    componentDidMount() {
        const { PRActionId } = this.props
        if (PRActionId !== null) {
            this.props.get_PR_Data(PRActionId)
        }
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    SelectFormOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    OnChangeCheckbox = E => {
        this.setState({ [E.target.name]: !this.state[E.target.name] })
    }

    formOnSubmit = (e) => {
        e.preventDefault()
        this.props.Konfirm_PR(this.props.PRActionId, this.state.Action, this.state.Keterangan, this.props.PRData, this.props.PRListItem)
    }
    render() {
        const OpStatsu = [
            {
                Value: "Accept",
                Item: "Accept"
            },
            {
                Value: "Reject",
                Item: "Reject"
            },
        ]

        const {
            Action,
            isConfirm,
            Keterangan,
        } = this.state

        const PRData = this.props.PRData
        const PRListItem = this.props.PRListItem
        const Skill = this.props.PRSkill
        const isPRActionGetDataLoading = this.props.isPRActionGetDataLoading
        const isPRKonfirmasiLoading = this.props.isPRKonfirmasiLoading

        const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }

        return (
            <Fragment>
                <CoDetailPR Data={PRData} PRListItem={PRListItem} Skill={Skill} isLoading={isPRActionGetDataLoading} />

                <form
                    onSubmit={this.formOnSubmit}
                >
                    <h3>
                        Action
                    </h3>
                    <FormControl
                        style={st_textfield}
                        variant="filled"
                        required
                    >
                        <InputLabel
                            shrink
                        >
                            Action
                        </InputLabel>
                        <Select
                            native
                            style={{ backgroundColor: 'white' }}
                            onChange={(e) => this.SelectFormOnChange(e)}
                            label="Action"
                            name='Action'
                            value={Action}
                        >
                            <option value="" disabled> -- select an option -- </option>
                            {OpStatsu.map((option, index) =>
                                <option key={`Action_${index}`} value={option.Value}>{option.Item}</option>
                            )}
                        </Select>
                    </FormControl>
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        label='Keterangan'
                        name='Keterangan'
                        required={this.state.Action === 'Reject' ? true : false}
                        value={Keterangan}
                        onChange={this.onChangeField}
                    />

                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox color='primary' onChange={this.OnChangeCheckbox} name="isConfirm" value={isConfirm} disabled={isPRKonfirmasiLoading ? true : false} />}
                            label="Saya sudah mengkonfirmasi"
                        />
                    </FormGroup>

                    <center>
                        <Button
                            variant='contained'
                            style={{ width: '90%', margin: '5% 0 5% 0' }}
                            type='submit'
                            disabled={isPRKonfirmasiLoading || !isConfirm ? true : false}
                        >
                            {isPRKonfirmasiLoading ? <CircularProgress /> : 'Konfirmasi PR'}
                        </Button>
                    </center>
                </form>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    PRActionId: state.PR.PRActionId,
    isPRActionGetDataLoading: state.PR.isPRActionGetDataLoading,
    PRData: state.PR.PRData,
    PRSkill: state.PR.PRSkill,
    PRListItem: state.PR.PRListItem,
    isPRKonfirmasiLoading: state.PR.isPRKonfirmasiLoading,
})

export default connect(mapStateToProps, { get_PR_Data, Konfirm_PR })(KonfirmasiPengajuanPR)