import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Edit_Interview_HR } from '../../../../../../Store/Actions/Job.Action'

import { Tabs, Tab, FormControl, Select, InputLabel, TextField, Button } from '@mui/material'

import DetailPosisi from './Sub/DetailPosisi'
import DetailKaryawan from './Sub/DetailKaryawan'
import JobdeskDetail from '../../../Jobdesk/Modal/Detail'
import ReviewPenilaianInterviewHR from './Sub/ReviewPenilaianInterviewHR'

class DetailPenilianKandidat extends React.Component {
    state = {
        TabValue: 0,
        KeputusanHasil: '',
        Keterangan: ''
    }

    handleTabChange = (e, newTabValue) => {
        this.setState({ TabValue: newTabValue })
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    SelectFormOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    formOnSubmit = (e) => {
        e.preventDefault()

        const JobActionId = this.props.JobActionId
        const AccountActionId = this.props.AccountActionId

        this.props.Edit_Interview_HR(
            JobActionId,
            AccountActionId,
            this.state.KeputusanHasil,
            this.state.Keterangan,
        )
    }


    render() {

        const {
            TabValue,
            KeputusanHasil,
            Keterangan,
        } = this.state

        const TabData = [
            { TabLabel: 'Detail Posisi', TabPanel: <DetailPosisi /> },
            { TabLabel: 'Jobdesk Detail', TabPanel: <JobdeskDetail /> },
            { TabLabel: 'Detail Karyawan', TabPanel: <DetailKaryawan /> },
            { TabLabel: 'Penilaian Interview', TabPanel: <ReviewPenilaianInterviewHR /> },
        ]

        const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }

        return (
            <Fragment>

                <Tabs
                    value={TabValue}
                    onChange={(e, newValue) => this.handleTabChange(e, newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={TabData.length > 6 ? "scrollable" : null}
                    scrollButtons="auto"
                // centered={TabData.length > 6 ? false : true}
                >

                    {TabData.map((item, index) => (
                        <Tab key={`Tabs${index}`} label={item.TabLabel} />
                    ))}

                </Tabs>

                <div
                    style={{ margin: '5vh 0 2vh 0' }}
                >
                    {TabValue === 0 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 1 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 2 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 3 ? TabData[TabValue].TabPanel : null}
                </div>
                <div
                    style={{ margin: '0 0 5vh 0' }}
                >
                    <form
                        onSubmit={this.formOnSubmit}
                    >
                        <FormControl style={st_textfield} variant="filled" required >
                            <InputLabel shrink >Keputusan Hasil Interview</InputLabel>
                            <Select native style={{ backgroundColor: 'white' }} onChange={(e) => this.SelectFormOnChange(e)} label="Keputusan Hasil Interview" name='KeputusanHasil' value={KeputusanHasil} >
                                <option value="" disabled> -- select an option -- </option>
                                {['Accept', 'Reject'].map((option, index) =>
                                    <option key={index} value={option}>{option}</option>
                                )}
                            </Select>
                        </FormControl>
                        <TextField
                            style={st_textfield}
                            variant='outlined'
                            required
                            onChange={this.onChangeField}
                            type='texts'
                            label='Keterangan'
                            name='Keterangan'
                            value={Keterangan}
                        />
                        <center>
                            <Button
                                variant='contained'
                                style={{ width: '80%', margin: '1% 0 3% 0' }}
                                type='submit'
                            >
                                Submit
                            </Button>
                        </center>
                    </form>
                </div>

            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isPertanyaanLoading: state.Setting.isPertanyaanLoading,
    PertanyaanList: state.Setting.PertanyaanList,
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
    isJobdeskGetPertanyaanLoading: state.Jobdesk.isJobdeskGetPertanyaanLoading,
    JobdeskGetPertanyaanPerilaku: state.Jobdesk.JobdeskGetPertanyaanPerilaku,
    JobdeskGetPertanyaanTeknik: state.Jobdesk.JobdeskGetPertanyaanTeknik,
})


export default connect(mapStateToProps, { Edit_Interview_HR })(DetailPenilianKandidat)
