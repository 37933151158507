import React, { Fragment } from 'react'

import { Tabs, Tab } from '@mui/material'

import DetailPosisi from './Sub/DetailPosisi'
import DetailKaryawan from './Sub/DetailKaryawan'
import JobdeskDetail from '../../../Jobdesk/Modal/Detail'
import PersonaltyTestDetail from '../PersonaltyTest/Detail'
import PenilaianInterviewHR from './Sub/PenilaianInterviewHR'
import ReviewPenilaianInterviewHR from './Sub/ReviewPenilaianInterviewHR'
import PenilaianInterviewUser from './Sub/PenilaianInterviewUser'

const DetailPenilianKandidat = ({ isHR }) => {
    const [TabValue, setTabValue] = React.useState(0)

    const handleTabChange = (e, newTabValue) => {
        setTabValue(newTabValue)
    }

    const TabData = [
        { TabLabel: `Position Detail's`, TabPanel: <DetailPosisi /> },
        { TabLabel: `Job Descriptions Detail's`, TabPanel: <JobdeskDetail /> },
        { TabLabel: `Applicant Detail's`, TabPanel: <DetailKaryawan /> },
        { TabLabel: `Personality Test Detail's`, TabPanel: <PersonaltyTestDetail isJobListId={true} /> },
        { TabLabel: `HR Interview Assessment Detail's`, TabPanel: <ReviewPenilaianInterviewHR /> },
        { TabLabel: `Interview Assessment`, TabPanel: isHR ? <PenilaianInterviewHR /> : <PenilaianInterviewUser /> },
    ]

    return (
        <Fragment>

            <Tabs
                value={TabValue}
                onChange={(e, newValue) => handleTabChange(e, newValue)}
                indicatorColor="primary"
                textColor="primary"
                variant={TabData.length > 5 ? "scrollable" : null}
                scrollButtons="auto"
                centered={TabData.length > 5 ? false : true}
            >

                {TabData.map((item, index) => (
                    <Tab key={`Tabs${index}`} label={item.TabLabel} />
                ))}

            </Tabs>

            <div
                style={{ margin: '5% 0 5% 0' }}
            >
                {TabValue === 0 ? TabData[TabValue].TabPanel : null}
                {TabValue === 1 ? TabData[TabValue].TabPanel : null}
                {TabValue === 2 ? TabData[TabValue].TabPanel : null}
                {TabValue === 3 ? TabData[TabValue].TabPanel : null}
                {TabValue === 4 ? TabData[TabValue].TabPanel : null}
                {TabValue === 5 ? TabData[TabValue].TabPanel : null}
            </div>

        </Fragment >
    )
}

export default DetailPenilianKandidat