import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Level_Data, get_Level_Participan_Data, Add_To_LevelList, Delete_From_LevelList } from '../../../../../../Store/Actions/Setting.Action'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

import Detail from '../../../../../Containers/Dashboard/Setting/Level/Detail'
import AddParticipan from '../../../../../Containers/Dashboard/Setting/Level/AddParticipan'
import Data from '../../../../../Containers/Dashboard/Setting/Level/Data'

class SettingLevelView extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.LevelActionId !== prevProps.LevelActionId) {
            this.props.get_Level_Data(this.props.LevelActionId)
        }
        // if (this.props.LevelActionCounter !== prevProps.LevelActionCounter) {
        //     this.props.get_Level_Data(this.props.LevelActionId)
        // }
    }
    componentDidMount() {
        const { LevelActionId } = this.props
        if (LevelActionId !== null) {
            this.props.get_Level_Data(LevelActionId)
        }
        this.props.get_Level_Participan_Data()
    }

    DeleteFromLevelList(LevelListId) {
        this.props.Delete_From_LevelList(LevelListId)
    }
    render() {
        const isLevelActionAddListLoading = this.props.isLevelActionAddListLoading
        const LevelActionId = this.props.LevelActionId
        const LevelParticipant = this.props.LevelParticipant
        const isLevelParticipantLoading = this.props.isLevelParticipantLoading
        const isLevelActionGetDetailLoading = this.props.isLevelActionGetDetailLoading
        const LevelDetail = this.props.LevelDetail
        const LevelData = this.props.LevelData
        const HeadData = [
            {
                Nama: 'Index',
                width: '5%',
            },
            {
                Nama: 'Id',
                width: '10%',
            },
            {
                Nama: 'UserName',
                width: '10%',
            },
            {
                Nama: 'Nama',
                width: '10%',
            },
            {
                Nama: 'Department',
                width: '10%',
            },
            {
                Nama: 'Remove',
                width: '15%',
            }
        ]
        const BodyData = LevelData ? LevelData.map((item, index) => {
            return {
                index: index + 1,
                Id: item.Id,
                UserName: item.UserName,
                Nama: item.BiodataNama,
                Level: item.DepartemenNama,
                Remove: (
                    <DeleteOutlineIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.DeleteFromLevelList(item.Id)}
                    />
                )
            }
        }) : []
        return (
            <Fragment>
                <Detail Data={LevelDetail} isLoading={isLevelActionGetDetailLoading} />
                <AddParticipan ParticipanData={LevelParticipant} ParticipanDataLoading={isLevelParticipantLoading} DataId={LevelActionId} isLoading={isLevelActionAddListLoading} sendFunction={this.props.Add_To_LevelList} />
                <Data HeadData={HeadData} BodyData={BodyData} isLoading={isLevelActionGetDetailLoading} />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isLevelActionAddListLoading: state.Setting.isLevelActionAddListLoading,
    isLevelActionGetDetailLoading: state.Setting.isLevelActionGetDetailLoading,
    LevelActionId: state.Setting.LevelActionId,
    LevelParticipant: state.Setting.LevelParticipant,
    isLevelParticipantLoading: state.Setting.isLevelParticipantLoading,
    LevelDetail: state.Setting.LevelDetail,
    LevelData: state.Setting.LevelData,
})

export default connect(mapStateToProps, { get_Level_Data, get_Level_Participan_Data, Add_To_LevelList, Delete_From_LevelList })(SettingLevelView)
