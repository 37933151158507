import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Paper, Grid } from '@mui/material'

import Carousel from 'react-material-ui-carousel'

import Carousel1 from '../../../Img/Home/Carousel/Carousel001.jpg'
import Carousel2 from '../../../Img/Home/Carousel/Carousel002.jpg'
import Carousel3 from '../../../Img/Home/Carousel/Carousel003.jpg'
import About1 from '../../../Img/Home/About/About001e.jpg'
import About2 from '../../../Img/Home/About/About002.jpg'
import About3 from '../../../Img/Home/About/About003.jpg'
import Value1 from '../../../Img/Home/Value/Value001e.jpg'
import Value2 from '../../../Img/Home/Value/Value002e.jpg'
import Value3 from '../../../Img/Home/Value/Value003e.jpg'
import RecruitmentProcessHorizontal from '../../../Img/Home/Flow/RecruitmentProcessHorizontal.png'

class Home extends React.Component {
    componentDidMount() {
        // this.props.get_PR_User_Pengajuan()
    }

    goToVacancay = () => {
        window.location.href = '/joblist'
        // this.setState({ isGoToVacancay: !this.state.isGoToVacancay })
        // History.push('/login')
    }

    render() {

        const ImageArray = [
            Carousel1, Carousel2, Carousel3
        ]

        return (
            <Fragment>
                <center>
                    <div
                        style={{ height: '70vh', border: '0px solid red', margin: '-5vh 0 0 0' }}
                    >
                        <Carousel
                        >
                            {
                                ImageArray.map((item, index) =>
                                    <Paper
                                        key={`ImageArray_${index}`}
                                        style={{ height: '69vh', border: 'none', boxShadow: 'none', overflow: 'none' }}
                                    >
                                        <center>
                                            <img
                                                style={{ height: '70vh', width: '100%', objectFit: 'cover' }}
                                                // style={{ height: '70vh' }}
                                                // style={{ transform: 'translate(50%, 0)' }}
                                                alt={`CarouselImage_${index}`}
                                                src={item}
                                            />
                                        </center>
                                    </Paper>
                                )
                            }
                        </Carousel>
                    </div>
                </center>

                <div
                    style={{ border: '0px solid red', height: '20vh' }}
                >
                    <center>
                        <h1
                            style={{ color: '#03045e', fontFamily: 'Montserrat, Segoe UI', cursor: 'pointer', fontSize: '2.5vw' }}
                            onClick={this.goToVacancay}
                        >
                            Find Your Dream Job in Etana!
                        </h1>
                        <span
                            style={{ fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                        >
                            Think about the basic components of the career experience <br />
                            and decide which are the aspects that pull you
                        </span>
                    </center>
                </div>

                <div>

                </div>

                <div
                    style={{ border: '0px solid red', backgroundColor: '#f0f0f0', padding: '0 0 15vh 0' }}
                >
                    <div>
                        <h1
                            style={{ border: '0px solid red', margin: '0 15vw 0 15vw', padding: '10vh 0 5vh 0', color: '#03045e', fontFamily: 'Montserrat, Segoe UI', fontSize: '2.5vw' }}
                        >
                            About Etana
                        </h1>
                        <div
                            style={{ margin: '0 15vw 0 15vw', border: '0px solid red', height: '55vh', display: 'flex' }}
                        >
                            <div
                                style={{ border: '0px solid blue', width: '36%', margin: '0 5% 0 15% ', height: '99%', backgroundColor: 'transparent', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                            >
                                <img
                                    // style={{ border: '0px solid red', width: '100%', height: '100%',  boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                    style={{ border: '0px solid red', width: '100%', height: '100%', objectFit: 'contain', backgroundColor: 'transparent', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                    src={About1}
                                    alt='About1'
                                />
                            </div>

                            <div
                                style={{ border: '0px solid blue', width: '25%', height: '100%', padding: '1% 0 0 0' }}
                            >
                                <div
                                    style={{ border: '0px solid green', width: '100%', height: '46%', margin: '0 0 5% 0', backgroundColor: 'transparent', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                >
                                    <img
                                        style={{ border: '0px solid red', width: '100%', height: '100%', objectFit: 'contain', backgroundColor: 'transparent', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                        src={About2}
                                        alt='About2'
                                    />
                                </div>
                                <div
                                    style={{ border: '0px solid green', width: '100%', height: '46%', backgroundColor: 'transparent', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                >
                                    <img
                                        style={{ border: '0px solid red', width: '100%', height: '100%', objectFit: 'contain', backgroundColor: 'transparent', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                        src={About3}
                                        alt='About3'
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <center>
                                <h2
                                    style={{ color: '#03045e', fontFamily: 'Montserrat, Segoe UI', fontSize: '2vw' }}
                                >
                                    Who We Are
                                </h2>
                                <span
                                    style={{ fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                                >
                                    Established in 2014, PT Etana Biotechnologies Indonesia (Etana) is an <br />
                                    emerging producer of high-quality, affordable and innovative <br />
                                    biopharmaceuticals to treat a range of metabolic, autoimmune and other <br />
                                    major life-threatening diseases, including cancer. Serve patients by <br />
                                    providing high quality, affordable and innovative biopharmaceutical <br />
                                    products.
                                </span>
                            </center>
                        </div>
                    </div>

                    <div>
                        <center>
                            <h2
                                style={{ color: '#03045e', margin: '15vh 0 0 0', fontFamily: 'Montserrat, Segoe UI', fontSize: '2vw' }}
                            >
                                Our Values and Purpose
                            </h2>
                        </center>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            style={{ border: '0px solid blue', margin: '5vh 15vw 0 15vw', width: '70%' }}
                        >
                            <div
                                style={{ border: '0px solid red', width: '30%', height: '71vh', margin: '1vh 1vw 1vh 1vw' }}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    style={{ border: '0px solid green', width: '100%', height: '100%' }}
                                >
                                    <div
                                        style={{ border: '0px solid red', width: '94%', height: '60%', margin: '0 0 5% 0', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                    >
                                        <img
                                            style={{ border: '0px solid red', width: '100%', height: '100%', objectFit: 'contain', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                            src={Value1}
                                            alt='Value1'
                                        />
                                    </div>
                                    <div
                                        style={{ border: '0px solid red', width: '100%', height: '30%' }}
                                    >
                                        <h3
                                            style={{ fontFamily: 'Montserrat, Segoe UI', border: '0px solid red', fontSize: '1.5vw' }}
                                        >
                                            Commitment
                                        </h3>
                                        <span
                                            style={{ fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                                        >
                                            We are committed to improving
                                            the health and well-being of all
                                            Indonesians.
                                        </span>
                                    </div>
                                </Grid>
                            </div>
                            <div
                                style={{ border: '0px solid red', width: '30%', height: '71vh', margin: '1vh 1vw 1vh 1vw' }}
                            >

                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    style={{ border: '0px solid green', width: '100%', height: '100%' }}
                                >
                                    <div
                                        style={{ border: '0px solid red', width: '94%', height: '60%', margin: '0 0 5% 0', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                    >
                                        <img
                                            style={{ border: '0px solid red', width: '100%', height: '100%', objectFit: 'contain', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                            src={Value2}
                                            alt='Value2'
                                        />
                                    </div>
                                    <div
                                        style={{ border: '0px solid red', width: '100%', height: '30%' }}
                                    >
                                        <h3
                                            style={{ fontFamily: 'Montserrat, Segoe UI', border: '0px solid red', fontSize: '1.5vw' }}
                                        >
                                            Care
                                        </h3>
                                        <span
                                            style={{ fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                                        >
                                            We care for the health of the
                                            nation. We encourage all members
                                            of our staff to share these values by
                                            caring for them, by providing them
                                            with good remuneration and
                                            opportunities for personal
                                            professional growth.
                                        </span>
                                    </div>
                                </Grid>

                            </div>
                            <div
                                style={{ border: '0px solid red', width: '30%', height: '71vh', margin: '1vh 1vw 1vh 1vw' }}
                            >

                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    style={{ border: '0px solid green', width: '100%', height: '100%' }}
                                >
                                    <div
                                        style={{ border: '0px solid red', width: '94%', height: '60%', margin: '0 0 5% 0', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                    >
                                        <img
                                            style={{ border: '0px solid red', width: '100%', height: '100%', objectFit: 'contain', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                                            src={Value3}
                                            alt='Value3'
                                        />
                                    </div>
                                    <div
                                        style={{ border: '0px solid red', width: '100%', height: '30%' }}
                                    >
                                        <h3
                                            style={{ fontFamily: 'Montserrat, Segoe UI', border: '0px solid red', fontSize: '1.5vw' }}
                                        >
                                            Collaboration
                                        </h3>
                                        <span
                                            style={{ fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                                        >
                                            We work together with
                                            stakeholders and strategic partners
                                            in Indonesia to ensure that
                                            Indonesia have access to the
                                            health services they deserve.
                                        </span>
                                    </div>
                                </Grid>

                            </div>
                        </Grid>
                    </div>
                </div>

                <div
                    style={{ border: '0px solid red', height: '80vh' }}
                >
                    <h1
                        style={{ margin: '10vh 0 15vh 15vw', color: '#03045e', fontFamily: 'Montserrat, Segoe UI', fontSize: '2.5vw' }}
                    >
                        Recruitment Process
                    </h1>
                    <center>
                        <img
                            style={{ border: '0px solid red', width: '80%', height: '50%', objectFit:'cover' }}
                            src={RecruitmentProcessHorizontal}
                            alt='RecruitmentProcessHorizontal'
                        />
                    </center>
                </div>
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    // AccountId: state.Auth.Id,
})

export default connect(mapStateToProps, {})(Home)
