import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Konfirm_OnBoarding } from '../../../../../../Store/Actions/Job.Action'

import { Button } from '@mui/material'

class NotificationWarning extends React.Component {

    formOnSubmit = (e) => {
        e.preventDefault()

        const JobActionId = this.props.JobActionId
        const AccountActionId = this.props.AccountActionId

        this.props.Konfirm_OnBoarding(JobActionId, AccountActionId)
    }
    render() {
        return (
            <Fragment>
                <form
                    onSubmit={this.formOnSubmit}
                >
                    <center>
                        <b>
                            Pastikan Semua Rangkaian Wajib terselesaikan, akan menyudahi proses Recruitment.
                        </b>
                        <br />
                        <Button
                            variant='contained'
                            style={{ margin: '5% 0 3% 0', width: '60%', textTransform: 'none' }}
                            type='submit'
                        >
                            Done
                        </Button>
                    </center>
                </form>
            </Fragment>
        )
    }
}
const mapStateToProps = state => ({
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
})

export default connect(mapStateToProps, { Konfirm_OnBoarding })(NotificationWarning)