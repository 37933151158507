import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_MPP_View_All } from '../../../../../../../Store/Actions/MPP.Action'

import { Skeleton, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'

class AllMPP extends React.Component {
    componentDidMount() {
        const { MPPActionId } = this.props
        if (MPPActionId !== null) {
            this.props.get_MPP_View_All(MPPActionId)
        }
    }

    render() {
        const User = this.props.User
        const isAdmin = User ? User.isAdmin : false
        const Role = User ? User.Role : []
        const Department = User ? User.Department : []
        const AccessorMPP = '1105'
        const isAccessorMPP = Role.includes(AccessorMPP)
        const MPPActionId = this.props.MPPActionId
        const isMPPViewAllLoading = this.props.isMPPViewAllLoading
        const MPPViewAllData = this.props.MPPViewAll ? this.props.MPPViewAll : []
        const MPPViewAll = isAdmin || isAccessorMPP ? MPPViewAllData : MPPViewAllData.filter((item) => (
            Department.find((newItem) => item.DepartemenNama === newItem.DepartemenNama)  ? true : false
        ))

        const st_BorderLeft = { borderLeft: '1px solid black' }
        const st_BorderRight = { borderRight: '1px solid black' }

        return (
            <Fragment>
                {/* {JSON.stringify(MPPViewAll)} */}
                {isMPPViewAllLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5].map((item) =>
                            <Skeleton
                                key={`AllMPP_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <center>
                            <h2>
                                Keseluruhan MPP Aktif Tahun {MPPActionId}
                            </h2>
                        </center>
                        <Table
                            stickyHeader
                            style={{ margin: '0 5vw 5vh 0', border: '0px solid red' }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align='center'
                                        colSpan={3}
                                    />
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        Januari
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        Februari
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        Maret
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        April
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        Mei
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        Juni
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        Juli
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        Agustus
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        September
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        Oktober
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        November
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        Desember
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Departemen
                                    </TableCell>
                                    <TableCell>
                                        Level
                                    </TableCell>
                                    <TableCell >
                                        Sub
                                    </TableCell>
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell> */}
                                    {/* <TableCell>
                                        Outsource
                                    </TableCell> */}
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell> */}
                                    {/* <TableCell>
                                        Outsource
                                    </TableCell> */}
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell> */}
                                    {/* <TableCell>
                                        Outsource
                                    </TableCell> */}
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell> */}
                                    {/* <TableCell>
                                        Outsource
                                    </TableCell> */}
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell> */}
                                    {/* <TableCell>
                                        Outsource
                                    </TableCell> */}
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell> */}
                                    {/* <TableCell>
                                        Outsource
                                    </TableCell> */}
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell> */}
                                    {/* <TableCell>
                                        Outsource
                                    </TableCell> */}
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell> */}
                                    {/* <TableCell>
                                        Outsource
                                    </TableCell> */}
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell> */}
                                    {/* <TableCell>
                                        Outsource
                                    </TableCell> */}
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell> */}
                                    {/* <TableCell>
                                        Outsource
                                    </TableCell> */}
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell> */}
                                    {/* <TableCell>
                                        Outsource
                                    </TableCell> */}
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell> */}
                                    {/* <TableCell>
                                        Outsource
                                    </TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {MPPViewAll.map((item, index) =>
                                    <TableRow
                                        hover
                                        key={`MPPViewAll_${index}`}
                                    >
                                        <TableCell>
                                            {item.DepartemenNama}
                                        </TableCell>
                                        <TableCell>
                                            {item.LevelNama}
                                        </TableCell>
                                        <TableCell>
                                            {item.Sub}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.JanuariPermanen}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.JanuariKontrak}
                                        </TableCell>
                                        {/* <TableCell>
                                            {item.JanuariIntern}
                                        </TableCell>
                                        <TableCell>
                                            {item.JanuariOutsource}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.FebruariPermanen}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.FebruariKontrak}
                                        </TableCell>
                                        {/* <TableCell>
                                            {item.FebruariIntern}
                                        </TableCell>
                                        <TableCell>
                                            {item.FebruariOutsource}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.MaretPermanen}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.MaretKontrak}
                                        </TableCell>
                                        {/* <TableCell>
                                            {item.MaretIntern}
                                        </TableCell>
                                        <TableCell>
                                            {item.MaretOutsource}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.AprilPermanen}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.AprilKontrak}
                                        </TableCell>
                                        {/* <TableCell>
                                            {item.AprilIntern}
                                        </TableCell>
                                        <TableCell>
                                            {item.AprilOutsource}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.MeiPermanen}
                                        </TableCell>

                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.MeiKontrak}
                                        </TableCell>
                                        {/* <TableCell>
                                            {item.MeiIntern}
                                        </TableCell>
                                        <TableCell>
                                            {item.MeiOutsource}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.JuniPermanen}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.JuniKontrak}
                                        </TableCell>
                                        {/* <TableCell>
                                            {item.JuniIntern}
                                        </TableCell>
                                        <TableCell>
                                            {item.JuniOutsource}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.JuliPermanen}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.JuliKontrak}
                                        </TableCell>
                                        {/* <TableCell>
                                            {item.JuliIntern}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.JuliOutsource}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.AgustusPermanen}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.AgustusKontrak}
                                        </TableCell>
                                        {/* <TableCell>
                                            {item.AgustusIntern}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.AgustusOutsource}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.SeptemberPermanen}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.SeptemberKontrak}
                                        </TableCell>
                                        {/* <TableCell>
                                            {item.SeptemberIntern}
                                        </TableCell>
                                        <TableCell>
                                            {item.SeptemberOutsource}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.OktoberPermanen}
                                        </TableCell>

                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.OktoberKontrak}
                                        </TableCell>
                                        {/* <TableCell>
                                            {item.OktoberIntern}
                                        </TableCell>
                                        <TableCell>
                                            {item.OktoberOutsource}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.NovemberPermanen}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.NovemberKontrak}
                                        </TableCell>
                                        {/* <TableCell>
                                            {item.NovemberIntern}
                                        </TableCell>
                                        <TableCell>
                                            {item.NovemberOutsource}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {item.DesemberPermanen}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={{ ...st_BorderLeft, ...st_BorderRight }}
                                        >
                                            {item.DesemberKontrak}
                                        </TableCell>
                                        {/* <TableCell>
                                            {item.DesemberIntern}
                                        </TableCell>
                                        <TableCell>
                                            {item.DesemberOutsource}
                                        </TableCell> */}
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    User: state.Auth.User,
    MPPActionId: state.MPP.MPPActionId,
    isMPPViewAllLoading: state.MPP.isMPPViewAllLoading,
    MPPViewAll: state.MPP.MPPViewAll,
})

export default connect(mapStateToProps, { get_MPP_View_All })(AllMPP)
