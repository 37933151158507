import React, { Fragment } from 'react'

import { connect } from 'react-redux'
import { get_Biodata_Data, get_Account_Data, get_Riwayat_Data } from '../../../Store/Actions/Account.Action'
import { Create_Warning_Messages } from '../../../Store/Actions/Messages.Actions'

import { Tabs, Tab } from '@mui/material'

import Biodata from '../../Containers/Account/Biodata1'
import CurriculumVitae from '../../Containers/Account/CurriculumVitae'

import Account from '../../Containers/Account/Account'

// import Riwayat from '../../Containers/Account/Riwayat'

import MoBiodata from './Modal/Biodata/Biodata1'

import MoAccountUserName from './Modal/Account/AccountUserName'
import MoAccountEmail from './Modal/Account/AccountEmail'
import MoAccountPassword from './Modal/Account/AccountPassword'

import GenericModals from '../../Containers/GenericModals'

class Profile extends React.Component {
    state = {
        // TabValue: 6
        TabValue: 0
    }

    componentDidMount() {
        const isView = this.props.isView ? true : false
        const JobActionId = this.props.JobActionId ? this.props.JobActionId : false

        const { AccountActionId, User } = this.props
        const UserId = User.Id

        const AccountId = isView ? AccountActionId : UserId

        this.props.get_Biodata_Data(AccountId)

        this.props.get_Account_Data(AccountId)

        this.props.get_Riwayat_Data(AccountId)

        if (JobActionId) {
            // this.props.get_Apply_Question(AccountId, JobActionId)
        }
    }

    handleTabChange = (e, newTabValue) => {
        this.setState({ TabValue: newTabValue })
    }

    onClickActionId = (Id) => {
    }

    render() {
        const isMobileView = this.props.isMobileView ? this.props.isMobileView : false

        const Auth = this.props.Auth
        const User = Auth?.User ? Auth.User : null
        const isPendaftar = User?.isPendaftar ? User.isPendaftar : false
        const DisableEdit = this.props.DisableEdit ? true : false

        const isView = this.props.isView ? true : false
        const AccountActionId = this.props.AccountActionId
        const UserId = User.Id
        const AccountId = isView ? AccountActionId : UserId

        const isBiodataLoading = this.props.isBiodataLoading
        const BiodataData = this.props.Biodata ? this.props.Biodata.Data : {}
        const BiodataRiwayatKerjaData = this.props.Biodata ? this.props.Biodata.RiwayatKerja : []

        const isAccountLoading = this.props.isAccountLoading
        const AccountData = this.props.Account

        // const isRiwayatLoading = this.props.isRiwayatLoading
        // const RiwayatData = this.props.Riwayat

        const isBiodataComplete = User ? User.isBiodataComplete : null
        const isUnBiodata = isBiodataComplete === false ? true : false

        // const JobActionId = this.props.JobActionId

        const TabData = [
            {
                TabLabel: 'Biodata',
                TabPanel: <Biodata
                    isLoading={isBiodataLoading}
                    Data={BiodataData}
                    RiwayatKerja={BiodataRiwayatKerjaData}
                    DisableEdit={DisableEdit}
                    Edit={
                        <GenericModals
                            size='l'
                            Buttonvariant='contained'
                            header='Edit Biodata'
                            body={<MoBiodata />}
                            Buttoncolor='primary'
                            ButtononClickeven={() => this.onClickActionId(AccountId)}
                            Buttonlabel={'Update The Form'}
                            Buttonstyle={{ border: '0px solid white', textTransform: 'none', color: 'white', backgroundColor: '#00a3df', boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px', fontFamily: 'Montserrat, Segoe UI', fontSize: isMobileView ? '16px' : '1.2vw' }}
                        />
                    }
                />
            },
            {
                TabLabel: 'Account',
                TabPanel: <Account
                    isLoading={isAccountLoading}
                    Data={AccountData}
                    DisableEdit={DisableEdit}
                    Edit={
                        <GenericModals
                            size='l'
                            Buttonvariant='contained'
                            header='Edit Account'
                            body={<MoAccountUserName />}
                            Buttoncolor='primary'
                            ButtononClickeven={() => this.onClickActionId(AccountId)}
                            Buttonlabel={'Update UserName'}
                            Buttonstyle={{ border: '0px solid white', textTransform: 'none', color: 'white', backgroundColor: '#00a3df', boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px', fontFamily: 'Montserrat, Segoe UI', fontSize: isMobileView ? '16px' : '1.2vw' }}
                        />
                    }
                    Edit2={
                        <GenericModals
                            size='l'
                            Buttonvariant='contained'
                            header='Edit Account'
                            body={<MoAccountEmail />}
                            Buttoncolor='primary'
                            ButtononClickeven={() => this.onClickActionId(AccountId)}
                            Buttonlabel={'Update Email'}
                            Buttonstyle={{ border: '0px solid white', textTransform: 'none', color: 'white', backgroundColor: '#00a3df', boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px', fontFamily: 'Montserrat, Segoe UI', fontSize: isMobileView ? '16px' : '1.2vw' }}
                        />
                    }
                    Edit3={
                        <GenericModals
                            size='l'
                            Buttonvariant='contained'
                            header='Edit Account'
                            body={<MoAccountPassword />}
                            Buttoncolor='primary'
                            ButtononClickeven={() => this.onClickActionId(AccountId)}
                            Buttonlabel={'Update Password'}
                            Buttonstyle={{ border: '0px solid white', textTransform: 'none', color: 'white', backgroundColor: '#00a3df', boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px', fontFamily: 'Montserrat, Segoe UI', fontSize: isMobileView ? '16px' : '1.2vw' }}
                        />
                    }
                />
            },
            {
                TabLabel: 'Curriculum Vitae',
                TabPanel: <CurriculumVitae
                    isLoading={isBiodataLoading}
                    Data={BiodataData}
                />
            },
            // {
            //     TabLabel: 'Apply Question',
            //     TabPanel: <ApplyQuestion
            //         isLoading={isBiodataLoading}
            //         Data={BiodataData}
            //     />
            // },
            // {
            //     TabLabel: 'Riwayat',
            //     TabPanel: <Riwayat
            //         isLoading={isRiwayatLoading}
            //         Data={RiwayatData}
            //     />
            // },
        ]

        const Data = isPendaftar ? TabData.slice(0, 3) : TabData

        const {
            TabValue
        } = this.state

        if (isUnBiodata) {
            this.props.Create_Warning_Messages(null, 'Mohon Lengkapi Biodata Terlebih Dahulu')
        }
        return (
            <Fragment>
                <Tabs
                    value={TabValue}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={TabData.length > 6 ? "scrollable" : null}
                    scrollButtons="auto"
                    style={{ margin: '0 10% 0 10%' }}
                // centered={TabData.length > 6 ? false : true}
                >
                    {Data.map((item, index) => (
                        <Tab
                            style={{ fontSize: isMobileView ? '16px' : '1vw', fontFamily: 'Montserrat, Segoe UI', }}
                            key={`Tabs${index}`}
                            label={item.TabLabel}
                        />
                    ))}
                </Tabs>
                <div
                    style={{ border: '0px solid red', width: '75%', margin: '1% 10% 5% 10%' }}
                >
                    {TabValue === 0 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 1 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 2 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 3 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 4 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 5 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 6 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 7 ? TabData[TabValue].TabPanel : null}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    AccountActionId: state.Account.AccountActionId,
    User: state.Auth.User,
    Auth: state.Auth,
    //* Biodata
    isBiodataLoading: state.Account.isBiodataLoading,
    Biodata: state.Account.Biodata,
    //* Accoun
    isAccountLoading: state.Account.isAccountLoading,
    Account: state.Account.Account,
    //* Riwayat
    isRiwayatLoading: state.Account.isRiwayatLoading,
    Riwayat: state.Account.Riwayat,
})

export default connect(mapStateToProps, { get_Biodata_Data, get_Account_Data, get_Riwayat_Data, Create_Warning_Messages })(Profile)