import React, { Fragment } from "react"

import { connect } from 'react-redux'

import { User_Apply_Job, get_PreRegisterData } from '../../Store/Actions/Job.Action'
import { Create_Warning_Messages } from '../../Store/Actions/Messages.Actions'

import { Button, FormControl, InputLabel, Select } from '@mui/material'

class Daftar extends React.Component {
    state = {
        PreRegisterDataSkill: [],
        Noticed: '',
    }

    componentDidMount() {
        const UrlId = this.props.URLId
        this.props.get_PreRegisterData(UrlId)
    }

    componentDidUpdate(prevProps) {
        if (this.props.PreRegisterData !== prevProps.PreRegisterData) {
            const { PreRegisterData } = this.props
            const PreRegisterDataSkill = PreRegisterData?.PRSkill ? PreRegisterData.PRSkill : []

            const newPreRegisterDataSkill = []
            PreRegisterDataSkill.map((item, index) => newPreRegisterDataSkill.push({
                Skill: item.Skill,
                Answer: ''
            }))
            this.setState({ PreRegisterDataSkill: newPreRegisterDataSkill })
        }
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    OnChangeMapItem = (e, Index, Order) => {
        const PreRegisterDataSkill = this.state.PreRegisterDataSkill
        if (Order === 1) {
            PreRegisterDataSkill[Index].Answer = e.target.value
        } else {

        }
        this.setState({ PreRegisterDataSkill: PreRegisterDataSkill })
    }

    DaftarKerja = (e) => {
        e.preventDefault()
        const UrlId = this.props.URLId
        if (UrlId !== null) {
            const PreRegisterDataSkill = this.state.PreRegisterDataSkill
            const newPreRegisterDataSkill = {
                Skill: 'Noticed Period',
                Answer: this.state.Noticed
            }
            PreRegisterDataSkill.push(newPreRegisterDataSkill)
            this.props.User_Apply_Job(UrlId, PreRegisterDataSkill)
        } else {
            this.props.Create_Warning_Messages(null, 'Job tidak ditemukan')
        }
    }

    render() {
        // const UrlId = this.props.URLId
        const isMobileView = this.props.isMobileView ? this.props.isMobileView : false

        const {
            PreRegisterDataSkill,
            Noticed
        } = this.state

        const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }

        return (
            <form
                onSubmit={this.DaftarKerja}
            >
                {PreRegisterDataSkill.map((item, index) =>
                    <Fragment
                        key={`PreRegisterDataSkill_${index}`}
                    >
                        <FormControl
                            style={{ ...st_textfield, }}
                            variant="filled"
                            required
                        >
                            <InputLabel
                                shrink
                            >
                                {index + 1}. How Good Are you In <b>{item.Skill}</b> Skill?
                            </InputLabel>
                            <Select
                                native
                                style={{ backgroundColor: 'white', fontSize: isMobileView ? '16px' : '1.2vw', fontFamily: 'Montserrat, Segoe UI', }}
                                onChange={(e) => this.OnChangeMapItem(e, index, 1)}
                                label="Golongan Darah"
                                name='GolDar'
                                value={PreRegisterDataSkill[index].Answer}
                            >
                                <option value="" disabled> -- select an option -- </option>
                                {['Basic', 'Intermediate', 'Proficient'].map((option, index) =>
                                    <option key={`Op_Jawban_${index}`} value={option}>{option}</option>
                                )}
                            </Select>
                        </FormControl>
                    </Fragment>
                )}
                <FormControl
                    style={{ ...st_textfield, }}
                    variant="filled"
                    required
                >
                    <InputLabel
                        shrink
                    >
                        How Long Is Your Noticed Period?
                    </InputLabel>
                    <Select
                        native
                        style={{ backgroundColor: 'white', fontSize: isMobileView ? '16px' : '1.2vw', fontFamily: 'Montserrat, Segoe UI', }}
                        onChange={this.onChangeField}
                        label="How Long Is Your Noticed Period"
                        name='Noticed'
                        value={Noticed}
                    >
                        <option value="" disabled> -- select an option -- </option>
                        {['No Notice, Able To Join As Soon As Possible', '1 Weeks', '2 Weeks', '3 Weeks', '1 Month', '2 Month', '3 Month', 'More then 3 Month'].map((option, index) =>
                            <option key={`Op_Jawban_${index}`} value={option}>{option}</option>
                        )}
                    </Select>
                </FormControl>
                <center>
                    <Button
                        variant="outlined"
                        color='success'
                        style={{ padding: '1%', margin: '2% 0 5% 0', width: '60%', borderRadius: '10px', textTransform: 'none', fontSize: isMobileView ? '16px' : '1.2vw', fontFamily: 'Montserrat, Segoe UI', }}
                        type='submit'
                    // onClick={this.DaftarKerja}
                    >
                        Apply
                    </Button>

                </center>
            </form>
        )
    }
}

const mapStateToProps = state => ({
    isPreRegisterLoading: state.Job.isPreRegisterLoading,
    PreRegisterData: state.Job.PreRegisterData,
})

export default connect(mapStateToProps, { User_Apply_Job, get_PreRegisterData, Create_Warning_Messages })(Daftar)