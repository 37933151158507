import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_List_BPJS, get_Job_Action_Id } from '../../../../Store/Actions/Job.Action'
import { get_Account_Action_Id } from '../../../../Store/Actions/Account.Action'

import { Collapse } from '@mui/material'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import GenericModals from '../../../Containers/GenericModals'
import CoTable from '../../../Containers/Table'
import Submit from './Modal/BPJS/Submit'
import Detail from './Modal/BPJS/Detail'
import MoDetail from './Modal/PReboarding/MoDetail'

class BPJS extends React.Component {
    state = {
        MenungguOpen: true,
        SelesaiOpen: false,
    }

    componentDidMount() {
        this.props.get_List_BPJS()
    }

    handleMenungguOpen = () => {
        this.setState({ MenungguOpen: !this.state.MenungguOpen })
    }

    handleSelesaiOpen = () => {
        this.setState({ SelesaiOpen: !this.state.SelesaiOpen })
    }

    onClickActionId = (JobId, AccountId) => {
        this.props.get_Job_Action_Id(JobId)
        this.props.get_Account_Action_Id(AccountId)
    }

    render() {
        const isListBPJSLoading = this.props.isListBPJSLoading
        const ListBPJS = this.props.ListBPJS

        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
                KeyName: 'index',
                SortingType: 'Number',
            },
            {
                Nama: 'No Registrasi',
                width: '10%',
                KeyName: 'Noreg',
                SortingType: 'Number',
            },
            {
                Nama: 'Nama',
                width: '10%',
                KeyName: 'Nama',
            },
            {
                Nama: 'Department',
                width: '10%',
                KeyName: 'DepartemenNama',
            },
            {
                Nama: 'Posisi',
                width: '10%',
                KeyName: 'Posisi',
            },
            {
                Nama: 'Detail Applicant',
                width: '10%',
            },
            {
                Nama: 'Submit',
                width: '10%',
            },
        ]

        const FilteredList = ListBPJS ? ListBPJS.filter((item, index) => (
            item.BPStatus === null || item.BPStatus === 'Unfinished'
        )) : []

        const BodyData = FilteredList.map((item, index) => {
            return {
                index: {
                    value: index + 1
                },
                Noreg: {
                    value: item.Id
                },
                Nama: {
                    value: item.BiodataNama
                },
                DepartemenNama: {
                    value: item.DepartemenNama
                },
                Posisi: {
                    value: item.JobListNama
                },
                Detail: {
                    value: (
                        <GenericModals
                            size='l'
                            Buttonvariant='contained'
                            header='Submit'
                            body={<MoDetail />}
                            Buttoncolor='secondary'
                            ButtononClickeven={() => this.onClickActionId(item.JobListId, item.AccountId)
                            }
                            Buttonlabel={'Detail'}
                        />
                    )
                },
                Submit: {
                    value: (
                        <GenericModals
                            size='s'
                            Buttonvariant='contained'
                            header='Submit'
                            body={< Submit />}
                            Buttoncolor='primary'
                            ButtononClickeven={() => this.onClickActionId(item.JobListId, item.AccountId)
                            }
                            Buttonlabel={'Submit'}
                        />
                    )
                }
            }
        })

        const HeadData2 = [
            {
                Nama: 'No',
                width: '5%',
                KeyName: 'index',
                SortingType: 'Number',
            },
            {
                Nama: 'No Registrasi',
                width: '10%',
                KeyName: 'Noreg',
                SortingType: 'Number',
            },
            {
                Nama: 'Nama',
                width: '10%',
                KeyName: 'Nama',
            },
            {
                Nama: 'Departemen',
                width: '10%',
                KeyName: 'DepartemenNama',
            },
            {
                Nama: 'Posisi',
                width: '10%',
                KeyName: 'Posisi',
            },
            {
                Nama: 'Submit',
                width: '10%',
            },
        ]

        const FilteredList2 = ListBPJS ? ListBPJS.filter((item, index) => (
            item.BPStatus !== null
        )) : []

        const BodyData2 = FilteredList2.map((item, index) => {
            return {
                index: {
                    value: index + 1
                },
                Noreg: {
                    value: item.Id
                },
                Nama: {
                    value: item.BiodataNama
                },
                DepartemenNama: {
                    value: item.DepartemenNama
                },
                Posisi: {
                    value: item.JobListNama
                },
                Detail: {
                    value: (
                        <GenericModals
                            size='l'
                            header='Detail'
                            body={< Detail />}
                            Buttoncolor='primary'
                            ButtononClickeven={() => this.onClickActionId(item.JobListId, item.AccountId)
                            }
                            Buttonlabel={'Detail'}
                        />
                    )
                }
            }
        })

        const {
            MenungguOpen,
            SelesaiOpen,
        } = this.state

        const RowDataInOnePage = 5
        return (
            <Fragment>
                <h2>
                    BPJS
                </h2>
                <h3
                    onClick={this.handleMenungguOpen}
                    style={{ display: 'flex', alignContent: 'center', verticalAlign: 'center', cursor: 'pointer' }}
                >
                    Menunggu {MenungguOpen ? <ExpandLess /> : <ExpandMore />}
                </h3>
                <Collapse in={MenungguOpen} timeout="auto" unmountOnExit>
                    <CoTable
                        TableId={'BPJS_Menunggu'}
                        HeadData={HeadData}
                        Data={BodyData}
                        isHeadLoading={isListBPJSLoading}
                        isBodyLoading={isListBPJSLoading}
                        isPagination={BodyData.length > RowDataInOnePage ? true : false}
                        MaxRowOnPage={RowDataInOnePage}
                        isPaginationLoading={isListBPJSLoading}
                        PaginationCounter={
                            BodyData.length / RowDataInOnePage > Math.floor(BodyData.length / RowDataInOnePage) ?
                                Math.floor(BodyData.length / RowDataInOnePage) + 1 :
                                BodyData.length / RowDataInOnePage
                        }
                        isHeading={true}
                    />
                </Collapse>
                <div
                    style={{ margin: '0 0 5% 0' }}
                >
                    <h3
                        onClick={this.handleSelesaiOpen}
                        style={{ display: 'flex', alignContent: 'center', verticalAlign: 'center', cursor: 'pointer' }}
                    >
                        Selesai{SelesaiOpen ? <ExpandLess /> : <ExpandMore />}
                    </h3>
                    <Collapse in={SelesaiOpen} timeout="auto" unmountOnExit>
                        <CoTable
                            TableId={'BPJS_Selesai'}
                            HeadData={HeadData2}
                            Data={BodyData2}
                            isHeadLoading={isListBPJSLoading}
                            isBodyLoading={isListBPJSLoading}
                            isPagination={BodyData2.length > RowDataInOnePage ? true : false}
                            MaxRowOnPage={RowDataInOnePage}
                            isPaginationLoading={isListBPJSLoading}
                            PaginationCounter={
                                BodyData2.length / RowDataInOnePage > Math.floor(BodyData2.length / RowDataInOnePage) ?
                                    Math.floor(BodyData2.length / RowDataInOnePage) + 1 :
                                    BodyData2.length / RowDataInOnePage
                            }
                            isHeading={true}
                        />
                    </Collapse>
                </div>
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    isListBPJSLoading: state.Job.isListBPJSLoading,
    ListBPJS: state.Job.ListBPJS,
})

export default connect(mapStateToProps, { get_List_BPJS, get_Job_Action_Id, get_Account_Action_Id })(BPJS)