import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_List_Offering_Detail } from '../../../../../../Store/Actions/Job.Action'

import { Skeleton } from '@mui/material'

class DetailOffering extends React.Component {
    componentDidMount() {
        const { JobActionId, AccountActionId } = this.props
        this.props.get_List_Offering_Detail(JobActionId, AccountActionId)
    }
    render() {
        const isListOfferingDetailLoading = this.props.isListOfferingDetailLoading
        const ListOfferingDetail = this.props.ListOfferingDetail
        return (
            <Fragment>
                {isListOfferingDetailLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5,].map((item) =>
                            <Skeleton
                                key={`DetailMenunggu_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <h2>Offering</h2>
                        <div
                            style={{
                                margin: '1% 0 1% 0', padding: '1%', border: `1px solid ${ListOfferingDetail?.OfStatus ? ListOfferingDetail.OfStatus === 'Accept' ? 'Green' :
                                    ListOfferingDetail.OfStatus === 'Reject' ? 'red' : 'orange' : 'black'}`
                            }}
                        >
                            <center>
                                <h2
                                    style={{
                                        color: ListOfferingDetail?.OfStatus ? ListOfferingDetail.OfStatus === 'Accept' ? 'Green' :
                                            ListOfferingDetail.OfStatus === 'Reject' ? 'red' : 'orange' : 'black'
                                    }}
                                >
                                    Status : {ListOfferingDetail?.OfStatus ? ListOfferingDetail.OfStatus : 'Status'}
                                </h2>
                            </center>
                            <h4>
                                Oleh:<br />
                                {ListOfferingDetail?.BiodataNama ? ListOfferingDetail.BiodataNama : ''}<br /><br />
                                Pada Tanggal:<br />
                                {ListOfferingDetail?.OfTime ? new Date(ListOfferingDetail.OfTime).toLocaleString(['ban', 'id']) : ''}<br /><br />
                                Keterangan:<br />
                                {ListOfferingDetail?.OfKet ? ListOfferingDetail.OfKet : ''}<br />
                            </h4>
                        </div>
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isListOfferingDetailLoading: state.Job.isListOfferingDetailLoading,
    ListOfferingDetail: state.Job.ListOfferingDetail,
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
})

export default connect(mapStateToProps, { get_List_Offering_Detail })(DetailOffering)
