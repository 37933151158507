import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_MPP_UnConfirm, get_MPP_Confirm, get_MPP_Action_Id } from '../../../../Store/Actions/MPP.Action'

import { Collapse } from '@mui/material'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import GenericModals from '../../../Containers/GenericModals'
import CoTable from '../../../Containers/Table'

import DetailMPP from './Modal/DetailMPP'
import KonfirmasiPengajuanMPP from './Modal/ACCMPP/KonfirmasiPengajuanMPP'
import EditMPP from './Modal/EditMPP/EditMPP'

class ACCMPP extends React.Component {
    state = {
        isUnConfimOpen: true,
        isConfimOpen: false,
    }
    componentDidMount() {
        this.props.get_MPP_UnConfirm()
        this.props.get_MPP_Confirm()
    }

    onClickActionId(Id) {
        this.props.get_MPP_Action_Id(Id)
    }

    render() {
        const {
            isUnConfimOpen,
            isConfimOpen,
        } = this.state

        const RowDataInOnePage = 5

        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
                KeyName: 'index',
                SortingType: 'Number',
            },
            {
                Nama: 'Nomer Pengajuan',
                width: '10%',
                KeyName: 'Id',
                SortingType: 'Number',
            },
            {
                Nama: 'Tahun',
                width: '10%',
                KeyName: 'Tahun',
                SortingType: 'Number',
            },
            {
                Nama: 'Tanggal Pengajuan',
                width: '10%',
                KeyName: 'TanggalPengajuan',
                SortingType: 'Date',
            },
            {
                Nama: 'Department',
                width: '10%',
                KeyName: 'Departemen',
            },
            {
                Nama: 'Level',
                width: '10%',
                KeyName: 'Level',
            },
            {
                Nama: 'Job Title',
                width: '10%',
                KeyName: 'Sub',
            },
            {
                Nama: 'Jumlah',
                width: '10%',
                KeyName: 'Jumlah',
                SortingType: 'Number',
            },
            {
                Nama: 'Jenis Pengajuan',
                width: '10%',
                KeyName: 'Jenis',
            },
            {
                Nama: 'Detail Pengajuan',
                width: '10%',
            },
        ]

        const isMPPGetUnConfirmLoading = this.props.isMPPGetUnConfirmLoading
        const MPPUnConfirm = this.props.MPPUnConfirm
        const BodyData = MPPUnConfirm ? MPPUnConfirm.map((item, index) => {
            const TanggalPengajuan = new Date(item.TanggalPembuatan).toLocaleString(['ban', 'id'])
            return {
                index: {
                    value: index + 1,
                },
                Id: {
                    value: item.Id,
                },
                Tahun: {
                    value: item.Tahun,
                },
                TanggalPengajuan: {
                    value: item.TanggalPembuatan,
                    display: TanggalPengajuan
                },
                Departemen: {
                    value: item.DepNama,
                },
                Level: {
                    value: item.Levnama,
                },
                Sub: {
                    value: item.Sub,
                },
                Jumlah: {
                    value: item.TotalJumlah ? item.TotalJumlah : ' ',
                },
                Jenis: {
                    value: item.Jenis === 'Additional' ?
                        <div class="tooltip">{item.Jenis}
                            <span class="tooltiptext">{item.Reason ? item.Reason : 'None'}</span>
                        </div>
                        : item.Jenis,
                },
                Detail: {
                    value: item.Id,
                    display: <GenericModals
                        size='xl'
                        header='Detail Pengajuan Form MPP'
                        body={<KonfirmasiPengajuanMPP />}
                        Buttonvariant='contained'
                        Buttoncolor='primary'
                        ButtononClickeven={() => this.onClickActionId(item.Id)}
                        Buttonlabel={'Detail'}
                    />
                },
            }
        }) : []

        const HeadData1 = [
            {
                Nama: 'No',
                width: '5%',
                KeyName: 'index',
                SortingType: 'Number',
            },
            {
                Nama: 'Nomer Pengajuan',
                width: '5%',
                KeyName: 'Id',
                SortingType: 'Number',
            },
            {
                Nama: 'Tahun',
                width: '5%',
                KeyName: 'Tahun',
                SortingType: 'Number',
            },
            {
                Nama: 'Tanggal Pengajuan',
                width: '10%',
                KeyName: 'TanggalPengajuan',
                SortingType: 'Date',
            },
            {
                Nama: 'Department',
                width: '10%',
                KeyName: 'Departemen',
            },
            {
                Nama: 'Level',
                width: '10%',
                KeyName: 'Level',
            },
            {
                Nama: 'Job Tite',
                width: '10%',
                KeyName: 'Sub',
            },
            {
                Nama: 'Jumlah',
                width: '10%',
                KeyName: 'Jumlah',
                SortingType: 'Number',
            },
            {
                Nama: 'Jenis Pengajuan',
                width: '10%',
                KeyName: 'Jenis',
            },
            {
                Nama: 'Status',
                width: '5%',
                KeyName: 'Status',
            },
            {
                Nama: 'Detail Pengajuan',
                width: '10%',
            },
            {
                Nama: 'Edit',
                width: '10%',
            },
        ]

        const isMPPGetConfirmLoading = this.props.isMPPGetConfirmLoading
        const MPPConfirm = this.props.MPPConfirm
        const BodyData1 = MPPConfirm ? MPPConfirm.map((item, index) => {
            const TanggalPengajuan = new Date(item.TanggalPembuatan).toLocaleString(['ban', 'id'])
            return {
                index: {
                    value: index + 1
                },
                Id: {
                    value: item.Id
                },
                Tahun: {
                    value: item.Tahun
                },
                TanggalPengajuan: {
                    value: item.TanggalPembuatan,
                    display: TanggalPengajuan
                },
                Departemen: {
                    value: item.DepNama
                },
                Level: {
                    value: item.Levnama
                },
                Sub: {
                    value: item.Sub
                },
                Jumlah: {
                    value: item.TotalJumlah ? item.TotalJumlah : ' ',
                },
                Jenis: {
                    value: item.Jenis === 'Additional' ?
                        <div class="tooltip">{item.Jenis}
                            <span class="tooltiptext">{item.Reason ? item.Reason : 'None'}</span>
                        </div>
                        : item.Jenis,
                },
                Status: {
                    value: item.Status,
                    display: (
                        <span
                            style={{ color: item?.Status ? item.Status === 'Accept' ? 'green' : 'red' : 'black' }}
                        >
                            {/* {item.Status} */}
                            {item?.Status === 'Submitted' ? 'Pending Approval' : item?.Status === 'Accept' ? 'Approved' : ''}
                        </span>
                    )
                },
                Detail: {
                    value: item.Id,
                    display: (
                        <GenericModals
                            size='xl'
                            header='Detail MPP'
                            body={<DetailMPP />}
                            Buttoncolor='primary'
                            ButtononClickeven={() => this.onClickActionId(item.Id)}
                            Buttonlabel={'Detail'}
                        />
                    )
                },
                Edit: {
                    value: item.Id,
                    display: (
                        <GenericModals
                            size='xl'
                            header='Edit MPP Submission'
                            body={<EditMPP />}
                            Buttoncolor='secondary'
                            ButtononClickeven={() => this.onClickActionId(item.Id)}
                            Buttonlabel={'Edit'}
                        />
                    )
                }
            }
        }) : []

        return (
            <Fragment>
                <h2>
                    MPP Approval
                </h2>
                <h3
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => this.setState({ isUnConfimOpen: !this.state.isUnConfimOpen })}
                >
                    Pending Approval {isUnConfimOpen ? <ExpandLess /> : <ExpandMore />}
                </h3>
                <Collapse in={isUnConfimOpen} timeout="auto" unmountOnExit>
                    <CoTable
                        TableId={'ACCMPP'}
                        HeadData={HeadData}
                        Data={BodyData}
                        isHeadLoading={isMPPGetUnConfirmLoading}
                        isBodyLoading={isMPPGetUnConfirmLoading}
                        isPagination={BodyData.length > RowDataInOnePage ? true : false}
                        MaxRowOnPage={RowDataInOnePage}
                        isPaginationLoading={isMPPGetUnConfirmLoading}
                        PaginationCounter={
                            BodyData.length / RowDataInOnePage > Math.floor(BodyData.length / RowDataInOnePage) ?
                                Math.floor(BodyData.length / RowDataInOnePage) + 1 :
                                BodyData.length / RowDataInOnePage
                        }
                        isHeading={true}
                    />
                </Collapse>
                <h3
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => this.setState({ isConfimOpen: !this.state.isConfimOpen })}
                >
                    Approved {isConfimOpen ? <ExpandLess /> : <ExpandMore />}
                </h3>
                <Collapse in={isConfimOpen} timeout="auto" unmountOnExit>
                    <CoTable
                        TableId={'ACCMPPKonfirm'}
                        HeadData={HeadData1}
                        Data={BodyData1}
                        isHeadLoading={isMPPGetConfirmLoading}
                        isBodyLoading={isMPPGetConfirmLoading}
                        isPagination={BodyData1.length > RowDataInOnePage ? true : false}
                        MaxRowOnPage={RowDataInOnePage}
                        isPaginationLoading={isMPPGetConfirmLoading}
                        PaginationCounter={
                            BodyData1.length / RowDataInOnePage > Math.floor(BodyData1.length / RowDataInOnePage) ?
                                Math.floor(BodyData1.length / RowDataInOnePage) + 1 :
                                BodyData1.length / RowDataInOnePage
                        }
                        isHeading={true}
                    />
                </Collapse>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isMPPGetUnConfirmLoading: state.MPP.isMPPGetUnConfirmLoading,
    MPPUnConfirm: state.MPP.MPPUnConfirm,
    isMPPGetConfirmLoading: state.MPP.isMPPGetConfirmLoading,
    MPPConfirm: state.MPP.MPPConfirm,
})

export default connect(mapStateToProps, { get_MPP_UnConfirm, get_MPP_Confirm, get_MPP_Action_Id })(ACCMPP)