import React, { Fragment, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import { Get_IpAddres } from "../../../../../Store/Actions/Base.Action";
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
} from "@mui/material";

const MPPApprovalTracker = ({
    isLoading,
    Data,
    DepartmentData,
    LevelData,
    DivisionData,
}) => {
    const [mppReport, setMppReport] = useState("");
    const [year, setYear] = useState(new Date().getFullYear());
    const [type, setType] = useState("Yearly");
    const [status, setStatus] = useState("Submitted");

    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear - 3; i <= currentYear + 3; i++) {
        years.push(i);
    }

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("ERecEtana_token");
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": token,
                },
            };
            try {
                const data = {
                    status: status,
                    type: type,
                    year: year,
                };
                const response = await axios.post(
                    Get_IpAddres() + "/report/mpp/newyearly",
                    data,
                    config
                );
                const DataAPI = response.data.Data;
                const months = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                ];
                if (LevelData && DepartmentData && DivisionData) {
                    const combinedData = DivisionData.map((division) => {
                        const { Nama, IdDepartemen } = division;
                        const departmentIds = IdDepartemen.split(",").map((id) => id.trim());

                        // Membuat objek divisi dengan nama dan deskripsi
                        const divisionObj = {
                            NamaDivisi: Nama,
                            DeskripsiDivisi: division.Deskripsi,
                            Departemen: [],
                        };

                        // Iterasi melalui data DepartmentData dan menambahkan departemen yang sesuai ke dalam objek divisi
                        DepartmentData.forEach((department) => {
                            if (departmentIds.includes(department.Id)) {
                                divisionObj.Departemen.push({
                                    NamaDepartemen: department.Nama,
                                    IdDepartemen: department.Id,
                                    Levels: LevelData.map((level) => {
                                        return {
                                            NamaLevel: level.Nama,
                                            IDLevel: level.Id,
                                            Months: months.map((month) => {
                                                return {
                                                    Month: month,
                                                    jumlahKontrak: 0,
                                                    jumlahPermanen: 0,
                                                };
                                            }),
                                        };
                                    }),
                                });
                            }
                        });

                        return divisionObj;
                    });
                    const combinedDataWithAPI = combinedData.map((divisionData) => {

                        const apiData = DataAPI.filter((apiItem) =>
                            divisionData.Departemen.some(
                                (dept) => dept.IdDepartemen === apiItem.departemenId
                            )
                        );

                        const updatedDivisionData = { ...divisionData };

                        updatedDivisionData.Departemen = updatedDivisionData.Departemen.map(
                            (department) => {
                                const apiDataForDepartment = apiData.filter(
                                    (apiItem) => apiItem.departemenId === department.IdDepartemen
                                );

                                const updatedDepartment = { ...department };

                                updatedDepartment.Levels = updatedDepartment.Levels.map(
                                    (level) => {
                                        const apiDataForLevel = apiDataForDepartment.filter(
                                            (apiItem) => apiItem.levelId === level.IDLevel
                                        );

                                        const updatedLevel = { ...level };

                                        updatedLevel.Months = updatedLevel.Months.map((monthData) => {
                                            const apiDataForMonth = apiDataForLevel.find(
                                                (apiItem) =>
                                                    apiItem.bulan === months.indexOf(monthData.Month) + 1
                                            );

                                            if (apiDataForMonth) {
                                                monthData.jumlahKontrak = apiDataForMonth.jumlahKontrak;
                                                monthData.jumlahPermanen = apiDataForMonth.jumlahPermanen;
                                            }

                                            return monthData;
                                        });

                                        return updatedLevel;
                                    }
                                );

                                return updatedDepartment;
                            }
                        );

                        return updatedDivisionData;
                    });

                    setMppReport(combinedDataWithAPI);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
        //eslint-disable-next-line
    }, [year, type, status, isLoading]);

    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const exportToExcel = () => {
        const table = document.getElementById("table-to-xls");

        const ws = XLSX.utils.table_to_sheet(table);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, "table.xlsx");
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case "year":
                setYear(value);
                break;
            case "type":
                setType(value);
                break;
            case "status":
                setStatus(value);
                break;
            default:
                break;
        }
    };

    return (
        <Fragment>
            <div className="tableMPP">
                <Stack direction="row" spacing={2} style={{ margin: "16px" }}>
                    <FormControl fullWidth>
                        <InputLabel id="select-label-year">Year</InputLabel>
                        <Select
                            labelId="select-label-year"
                            name="year"
                            value={year}
                            label="Year"
                            onChange={handleChange}
                        >
                            {years.map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="select-label-type">Type</InputLabel>
                        <Select
                            labelId="select-label-type"
                            name="type"
                            value={type}
                            label="Type"
                            onChange={handleChange}
                        >
                            <MenuItem value={false}>Select All</MenuItem>
                            <MenuItem value={"Yearly"}>Yearly</MenuItem>
                            <MenuItem value={"Additional"}>Additional</MenuItem>
                            <MenuItem value={"Replacement"}>Replacement</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="select-label-status">Status</InputLabel>
                        <Select
                            labelId="select-label-status"
                            name="status"
                            value={status}
                            label="Status"
                            onChange={handleChange}
                        >
                            <MenuItem value={"Submitted"}>Submitted</MenuItem>
                            <MenuItem value={"Accept"}>Accept</MenuItem>
                            <MenuItem value={"Reject"}>Reject</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                {mppReport && (
                    <Button
                        variant="contained"
                        onClick={exportToExcel}
                        style={{ marginBottom: "16px", marginLeft: "16px" }}
                    >
                        Export to Excel
                    </Button>
                )}
                <table id="table-to-xls">
                    <thead>
                        <tr>
                            <th rowSpan="2">Division</th>
                            <th rowSpan="2">Departemen</th>
                            <th rowSpan="2">Level</th>
                            {Array.from({ length: 12 }).map((_, monthIndex) => (
                                <React.Fragment key={monthIndex}>
                                    <th colSpan="2">{monthNames[monthIndex]}</th>
                                </React.Fragment>
                            ))}
                        </tr>
                        <tr>
                            {Array.from({ length: 24 }).map((_, index) => (
                                <th key={index}>
                                    {index % 2 === 0 ? "Permanent" : "Contract"}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {mppReport &&
                            mppReport.map((division, divisionIndex) => {
                                return division.Departemen.map(
                                    (department, departmentIndex) => {
                                        let departmentTotalPermanen = new Array(
                                            department.Levels[0].Months.length
                                        ).fill(0);
                                        let departmentTotalKontrak = new Array(
                                            department.Levels[0].Months.length
                                        ).fill(0);

                                        return department.Levels.map((level, levelIndex) => {
                                            let levelTotalPermanen = new Array(
                                                level.Months.length
                                            ).fill(0);
                                            let levelTotalKontrak = new Array(
                                                level.Months.length
                                            ).fill(0);

                                            return (
                                                <>
                                                    <tr
                                                        key={`${divisionIndex}-${departmentIndex}-${levelIndex}`}
                                                    >
                                                        {departmentIndex === 0 && levelIndex === 0 && (
                                                            <td
                                                                rowSpan={
                                                                    division.Departemen.length +
                                                                    division.Departemen.length *
                                                                    department.Levels.length
                                                                }
                                                            >
                                                                {division.NamaDivisi}
                                                            </td>
                                                        )}
                                                        {levelIndex === 0 && (
                                                            <>
                                                                <td rowSpan={department.Levels.length}>
                                                                    {department.NamaDepartemen}
                                                                </td>
                                                            </>
                                                        )}
                                                        <td>{level.NamaLevel}</td>
                                                        {level.Months.map((monthData, monthDataIndex) => {
                                                            levelTotalPermanen[monthDataIndex] +=
                                                                monthData.jumlahPermanen;
                                                            levelTotalKontrak[monthDataIndex] +=
                                                                monthData.jumlahKontrak;
                                                            departmentTotalPermanen[monthDataIndex] +=
                                                                monthData.jumlahPermanen;
                                                            departmentTotalKontrak[monthDataIndex] +=
                                                                monthData.jumlahKontrak;
                                                            return (
                                                                <React.Fragment key={monthDataIndex}>
                                                                    <td>{monthData.jumlahPermanen}</td>
                                                                    <td>{monthData.jumlahKontrak}</td>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </tr>
                                                    {levelIndex === department.Levels.length - 1 && (
                                                        <tr>
                                                            <td
                                                                colSpan={2}
                                                                style={{ backgroundColor: "yellow" }}
                                                            >
                                                                Total
                                                            </td>
                                                            {departmentTotalPermanen.map(
                                                                (total, monthIndex) => (
                                                                    <React.Fragment key={monthIndex + 9999}>
                                                                        <td style={{ backgroundColor: "yellow" }}>
                                                                            {total}
                                                                        </td>
                                                                        <td style={{ backgroundColor: "yellow" }}>
                                                                            {departmentTotalKontrak[monthIndex]}
                                                                        </td>
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                        </tr>
                                                    )}
                                                </>
                                            );
                                        });
                                    }
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </Fragment>
    );
};

export default MPPApprovalTracker;