import React, { Fragment } from "react"

import { connect } from "react-redux"

import { get_Account_Action_Id } from '../../../Store/Actions/Account.Action'

import { Link as Redirect } from 'react-router-dom'

import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Divider, ClickAwayListener } from '@mui/material'

import Logo from '../../../Img/Etana_logo.png'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'

class Header extends React.Component {
    state = {
        userOpen: false,
        isGoToAboutUs: false,
        isGoToVacancay: false,
        isGoToLogIn: false,
        isGoToLogOut: false,
        isGoToLogProfile: false,
        isGoToProfileExtend: false,
    }

    handleUserOpen = () => {
        this.setState({ userOpen: true })
    }

    handleUserClose = (e) => {
        this.setState({ userOpen: false })
    }

    handleImgClick = () => {
        window.location.href = '/'
    }

    goToEtana = () => {
        window.location.href = 'https://www.id.etanabiotech.com/'
    }

    goToAboutUs = () => {
        window.location.href = '/'
        // this.setState({ isGoToAboutUs: !this.state.isGoToAboutUs })
        // History.push('/login')
    }

    goToVacancay = () => {
        window.location.href = '/joblist'
        // this.setState({ isGoToVacancay: !this.state.isGoToVacancay })
        // History.push('/login')
    }

    goToLogin = () => {
        window.location.href = '/login'
        // this.setState({ isGoToLogIn: !this.state.isGoToLogIn })
        // History.push('/login')
    }

    goToDashboard = () => {
        window.location.href = '/dashboard'
        // this.setState({ isGoToLogIn: !this.state.isGoToLogIn })
        // History.push('/dashboard')
    }

    goToApplications = () => {
        window.location.href = '/applications'
        // this.setState({ isGoToLogIn: !this.state.isGoToLogIn })
        // History.push('/applications')
    }

    goToLogout = (e) => {
        window.location.href = '/logout'
        // this.handleUserClose(e)
        // this.setState({ isGoToLogOut: !this.state.isGoToLogOut })

        // History.push('/logout')
    }

    goToProfile = (e) => {
        window.location.href = '/profile'
        // const User = this.props.User
        // const UserId = User.Id
        // this.props.get_Account_Action_Id(UserId)
        // this.handleUserClose(e)
        // this.setState({ isGoToProfile: !this.state.isGoToProfile })

        // this.props.history.push("/profile")
        // History.push('/profile')
    }

    goToProfileExtend = (e) => {
        window.location.href = '/profileextend'
        // const User = this.props.User
        // const UserId = User.Id
        // this.props.get_Account_Action_Id(UserId)
        // this.handleUserClose(e)
        // this.setState({ isGoToProfileExtend: !this.state.isGoToProfileExtend })

        // this.props.history.push("/profile")
        // History.push('/profile')
    }

    render() {
        const isAuth = this.props.isAuth
        const User = this.props.User
        const UserName = User?.UserName ? User.UserName : 'User404'
        const isKaryawan = User?.isKaryawan ? User.isKaryawan : false
        // const isPendaftar = User?.isPendaftar ? User.isPendaftar : false
        const isAdmin = User?.isAdmin ? User.isAdmin : false
        const isBiodataExtend = User?.isBiodataExtend ? User.isBiodataExtend : false

        const URLLocation = window.location.href
        const URLLocationSh = String(URLLocation).toLocaleLowerCase()
        const LoginLocation = '/login'
        const VacancayLocation = '/joblist'
        const DashboardLocation = '/dashboard'
        const ApplicationsLocation = '/applications'
        const ProfileLocation = '/profile'
        const ProfileExtendLocation = '/profileextend'

        const {
            userOpen,
            isGoToAboutUs,
            isGoToVacancay,
            isGoToLogIn,
            isGoToLogOut,
            isGoToProfile,
            isGoToProfileExtend,
        } = this.state

        // const StyleMenu = { margin: '0 1% 0 0%', border: '0px solid red', fontSize: '18px', fontWeight: 'inherit', display: 'flex' }
        // const defaultStyle = { border: '0px solid red', margin: ' 10px', color: 'black', fontSize: '120%', textDecoration: 'none' }

        return (
            <Fragment>
                {isGoToAboutUs ? <Redirect to="/" /> : <Fragment></Fragment>}
                {isGoToVacancay ? <Redirect to="/joblist" /> : <Fragment></Fragment>}
                {isGoToLogIn ? <Redirect to="/login" /> : <Fragment></Fragment>}
                {isGoToLogOut ? <Redirect to="/logout" /> : <Fragment></Fragment>}
                {isGoToProfileExtend ? <Redirect to="/profileextend" /> : <Fragment></Fragment>}
                {isGoToProfile ? <Redirect to="/profile" /> : <Fragment></Fragment>}
                <header
                    style={{ border: '0px solid red', height: '12vh', padding: '1vh 1vw 2vh 1vw' }}
                >
                    <div
                        // style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', border: '0px solid red', height: '8vh', marginTop: '2vh' }}
                        style={{ display: 'flex', alignContent: 'start', margin: '2vh 0 0 5vw', border: '0px solid blue', height: '8vh', }}
                    >
                        <div
                            style={{ border: '0px solid red', marginRight: isAuth ? '50vw' : '60vw' }}
                        >
                            <img
                                style={{ width: '100%', height: '70%', cursor: 'pointer' }}
                                src={Logo}
                                alt="EtanaLogo"
                                onClick={this.handleImgClick}
                            />
                        </div>
                        {isAuth ?
                            <div
                                style={{ margin: '0 1vw 0 1vw', cursor: 'pointer', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                                onClick={this.goToVacancay}
                            >
                                {URLLocationSh.includes(VacancayLocation) ?
                                    <b>Vacancy</b> :
                                    `Vacancy`
                                }
                            </div> :
                            <div
                                style={{ margin: '0 1vw 0 1vw', cursor: 'pointer', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                                onClick={this.goToAboutUs}
                            >
                                {!URLLocationSh.includes(VacancayLocation) && !URLLocationSh.includes(LoginLocation) ?
                                    <b>About Us</b> :
                                    `About Us`
                                }
                            </div>}
                        {isAuth ?
                            <div
                                style={{ margin: '0 1vw 0 1vw', cursor: 'pointer', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                                onClick={isAdmin || isKaryawan ? this.goToDashboard : this.goToApplications}
                            >
                                {isAdmin || isKaryawan ? URLLocationSh.includes(DashboardLocation) ?
                                    <b>Dashboard</b> :
                                    `Dashboard` :
                                    URLLocationSh.includes(ApplicationsLocation) ?
                                        <b>Applications</b> :
                                        `Applications`
                                }
                            </div> :
                            <div
                                style={{ margin: '0 1vw 0 1vw', cursor: 'pointer', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                                onClick={this.goToVacancay}
                            >
                                {URLLocationSh.includes(VacancayLocation) ?
                                    <b>Vacancy</b> :
                                    `Vacancy`
                                }
                            </div>}
                        {isAuth ?
                            <div
                                style={{ margin: '0 1vw 0 1vw', cursor: 'pointer', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                            >

                                <div>
                                    <div
                                        onClick={() => this.handleUserOpen()}
                                        style={{ display: 'flex', alignContent: 'center' }}
                                    >
                                        {URLLocationSh.includes(ProfileLocation) || URLLocationSh.includes(ProfileExtendLocation) ?
                                            <b>Hi, {UserName}</b> :
                                            `Hi, ${UserName}`
                                        }
                                        {userOpen ? <ExpandLess style={{ fontSize: '1.2vw' }} /> : <ExpandMore style={{ fontSize: '1.2vw' }} />}
                                    </div>
                                    <Collapse in={userOpen} timeout="auto" unmountOnExit>
                                        <ClickAwayListener onClickAway={(e) => this.handleUserClose(e)}>
                                            <List component="div" disablePadding style={{ border: '0px solid red', width: '15vw', backgroundColor: 'white', zIndex: '100' }}>
                                                <Divider />
                                                {!isBiodataExtend || isAdmin ?
                                                    <Fragment>
                                                        <ListItemButton
                                                            sx={{ pl: 1 }}
                                                            onClick={this.goToProfile}
                                                        >
                                                            <ListItemIcon>
                                                                <ManageAccountsRoundedIcon style={{ fontSize: '2vw' }} />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Manage Account" style={{ fontSize: '1.2vw' }} />
                                                        </ListItemButton>
                                                        <Divider />
                                                    </Fragment> :
                                                    <Fragment></Fragment>}
                                                {isBiodataExtend || isAdmin ?
                                                    <Fragment>
                                                        <ListItemButton
                                                            sx={{ pl: 1 }}
                                                            onClick={this.goToProfileExtend}
                                                        >
                                                            <ListItemIcon>
                                                                <AssignmentIndIcon style={{ fontSize: '2vw' }} />
                                                            </ListItemIcon>
                                                            <ListItemText primary="Manage Account Extend" style={{ fontSize: '1.2vw' }} />
                                                        </ListItemButton>
                                                        <Divider />
                                                    </Fragment> :
                                                    <Fragment></Fragment>}
                                                <ListItemButton
                                                    sx={{ pl: 1 }}
                                                    onClick={this.goToLogout}
                                                >
                                                    <ListItemIcon>
                                                        <LogoutRoundedIcon style={{ fontSize: '2vw' }} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Log Out" style={{ fontSize: '1.2vw' }} />
                                                </ListItemButton>
                                                <Divider />
                                            </List>
                                        </ClickAwayListener>
                                    </Collapse>
                                </div>
                            </div> :
                            <div
                                style={{ margin: '0 1vw 0 1vw', cursor: 'pointer', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                                onClick={this.goToLogin}
                            >
                                {URLLocationSh.includes(LoginLocation) ?
                                    <b>Login</b> :
                                    `Login`
                                }
                            </div>
                        }
                    </div>

                </header>
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    isAuth: state.Auth.isAuth,
    User: state.Auth.User,
})

export default connect(mapStateToProps, { get_Account_Action_Id })(Header)