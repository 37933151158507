import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Konfirm_Kontrak } from '../../../../../../Store/Actions/Job.Action'

import { FormControl, InputLabel, Select, TextField, Button } from '@mui/material'

class SubmitKontrak extends React.Component {
    state = {
        Status: '',
        Keterangan: ''
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    formOnSubmit = (e) => {
        e.preventDefault()

        const JobActionId = this.props.JobActionId
        const AccountActionId = this.props.AccountActionId

        this.props.Konfirm_Kontrak(JobActionId, AccountActionId, this.state.Status, this.state.Keterangan)
    }
    render() {
        const st_textfield_non = { marginTop: '10px', marginBottom: '10px', width: '100%' }

        const {
            Status,
            Keterangan,
        } = this.state
        return (
            <Fragment>
                <form
                    onSubmit={this.formOnSubmit}
                >
                    <FormControl style={st_textfield_non} variant="filled" required >
                        <InputLabel shrink >Status Kontrak</InputLabel>
                        <Select style={{ backgroundColor: 'white' }} native onChange={(e) => this.onChangeField(e)} label="Status" name='Status' value={Status}  >
                            <option value="" disabled> -- select an option -- </option>
                            {['Accept', 'Reject'].map((option, index) =>
                                <option key={index} value={option}>{option}</option>
                            )}
                        </Select>
                    </FormControl>

                    <TextField
                        style={st_textfield_non}
                        variant='outlined'
                        type='text'
                        label='Keterangan'
                        name='Keterangan'
                        value={Keterangan}
                        onChange={this.onChangeField}
                    />

                    <center>
                        <Button
                            variant='contained'
                            style={{ margin: '10% 0 5% 0', width: '60%' }}
                            type='submit'
                        >
                            Submit
                        </Button>
                    </center>
                </form>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
})

export default connect(mapStateToProps, { Konfirm_Kontrak })(SubmitKontrak)