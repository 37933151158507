import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Items_Data } from '../../../../../../Store/Actions/Setting.Action'

import Detail from '../../../../../Containers/Dashboard/Setting/Items/Detail'

class SettingItemsView extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.ItemsActionId !== prevProps.ItemsActionId) {
            this.props.get_Items_Data(this.props.ItemsActionId)
        }
        // if (this.props.ItemsActionCounter !== prevProps.ItemsActionCounter) {
        //     this.props.get_Items_Data(this.props.ItemsActionId)
        // }
    }
    componentDidMount() {
        const { ItemsActionId } = this.props
        if (ItemsActionId !== null) {
            this.props.get_Items_Data(ItemsActionId)
        }
    }

    render() {
        const isItemsActionGetDetailLoading = this.props.isItemsActionGetDetailLoading
        const ItemsDetail = this.props.ItemsDetail

        return (
            <Fragment>
                <Detail Data={ItemsDetail} isLoading={isItemsActionGetDetailLoading} />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isItemsActionGetDetailLoading: state.Setting.isItemsActionGetDetailLoading,
    ItemsActionId: state.Setting.ItemsActionId,
    ItemsDetail: state.Setting.ItemsDetail,
})

export default connect(mapStateToProps, { get_Items_Data })(SettingItemsView)
