import React, { Fragment, useEffect } from "react"

import { TextField, FormControl, InputLabel, Select } from '@mui/material'

import SelectionProcessComp from './SelectionProcess/SelectionProcessComp'

const SelectionProcess = ({ isLoading, Data, DepartmentData }) => {

    const [BodyData, setBodyData] = React.useState([])

    const [ActiveYear, setActiveYear] = React.useState(Number(new Date().getFullYear()))
    const [ActiveDepartment, setActiveDepartment] = React.useState('')

    useEffect(() => {
        setBodyData(Data)
        changeYear(ActiveYear)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Data])

    const changeYear = (newValue) => {
        setActiveYear(newValue)
        changeBodyData(newValue, ActiveDepartment)
    }

    const changeDepartment = (newValue) => {
        setActiveDepartment(newValue)
        changeBodyData(ActiveYear, newValue)
    }

    const changeBodyData = (YearValue, ActiveDepartmentValue) => {
        const newData = Data.map((item, index) => {
            const Tahun = item.Tahun ? item.Tahun : ''
            const DepartemenId = item.DepartemenId ? item.DepartemenId : ''

            const isYearMatch = Number(YearValue) === Number(Tahun) ? true : false
            const isDepartmentOk = ActiveDepartmentValue ? ActiveDepartmentValue === 'All' ? true : DepartemenId === ActiveDepartmentValue ? true : false : true
            const isMatch = isYearMatch && isDepartmentOk ? true : false
            return isMatch ? item : {}
        })
        setBodyData(newData)
    }

    const st_textfield = { margin: '10px 10px 10px 10px', width: '20%' }

    return (
        <Fragment>
            <center>
                <TextField
                    style={st_textfield}
                    variant='outlined'
                    type='number'
                    label='Active Year'
                    name='ActiveYear'
                    value={ActiveYear}
                    onChange={(e) => changeYear(e.target.value)}
                />
                <FormControl
                    style={{ ...st_textfield, width: '40%' }}
                    variant="filled"
                    required
                >
                    <InputLabel
                        shrink
                    >
                        Active Department
                    </InputLabel>
                    <Select
                        native
                        style={{ backgroundColor: 'white', fontFamily: 'Montserrat, Segoe UI', }}
                        onChange={(e) => changeDepartment(e.target.value)}
                        label="Active Department"
                        name='ActiveDepartment'
                        value={ActiveDepartment}
                    >
                        <option value="All" > -- select an option -- </option>
                        {DepartmentData.map((option, index) =>
                            <option key={`Op_DepartmentData_${index}`} value={option.Id}>{option.Nama}</option>
                        )}
                    </Select>
                </FormControl>
            </center>

            <SelectionProcessComp isLoading={isLoading} Data={BodyData} />
        </Fragment>
    )
}

export default SelectionProcess