import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Division_Data, Delete_Division } from '../../../../../../Store/Actions/Setting.Action'

import { FormGroup, FormControlLabel, Checkbox, Button, CircularProgress } from '@mui/material'

import Detail from '../../../../../Containers/Dashboard/Setting/Division/Detail'
import Data from '../../../../../Containers/Dashboard/Setting/Division/Data'

class SettingDivisionDelete extends React.Component {
    state = {
        isConfirm: false,
    }
    componentDidUpdate(prevProps) {
        if (this.props.DivisionActionId !== prevProps.DivisionActionId) {
            this.props.get_Division_Data(this.props.DivisionActionId)
        }
    }
    componentDidMount() {
        const { DivisionActionId } = this.props
        if (DivisionActionId !== null) {
            this.props.get_Division_Data(DivisionActionId)
        }
    }

    OnChangeCheckbox = E => {
        this.setState({ [E.target.name]: !this.state[E.target.name] })
    }

    onClickDelete(Id) {
        this.props.Delete_Division(Id)
    }
    render() {
        const DivisionActionId = this.props.DivisionActionId
        const isDivisionActionGetDetailLoading = this.props.isDivisionActionGetDetailLoading
        const DivisionDetail = this.props.DivisionDetail
        const DivisionData = this.props.DivisionData
        const HeadData = [
            {
                Nama: 'Index',
                width: '5%',
            },
            {
                Nama: 'Id',
                width: '10%',
            },
            {
                Nama: 'UserName',
                width: '10%',
            },
            {
                Nama: 'Nama',
                width: '10%',
            },
            {
                Nama: 'Division',
                width: '10%',
            }
        ]
        const BodyData = DivisionData ? DivisionData.map((item, index) => {
            return {
                index: index + 1,
                Id: item.Id,
                UserName: item.UserName,
                Nama: item.BiodataNama,
                Division: item.DepartemenNama,
            }
        }) : []
        const {
            isConfirm
        } = this.state
        const isDivisionDeleteLoading = this.props.isDivisionDeleteLoading
        return (
            <Fragment>
                <Detail Data={DivisionDetail} isLoading={isDivisionActionGetDetailLoading} />
                <Data HeadData={HeadData} BodyData={BodyData} isLoading={isDivisionActionGetDetailLoading} />
                <div
                    style={{ margin: '1%' }}
                >
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox color='primary' onChange={this.OnChangeCheckbox} name="isConfirm" value={isConfirm} disabled={isDivisionDeleteLoading ? true : false} />}
                            label="Saya menyadari akan resikonya dan saya tau apa yang saya lakukan"
                        />
                    </FormGroup>
                    <center>
                        <Button
                            variant='contained'
                            size='large'
                            color='error'
                            disabled={!isConfirm || isDivisionDeleteLoading ? true : false}
                            onClick={() => this.onClickDelete(DivisionActionId)}
                        >
                            {isDivisionDeleteLoading ? <CircularProgress /> : 'Delete'}
                        </Button>
                    </center>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isDivisionActionGetDetailLoading: state.Setting.isDivisionActionGetDetailLoading,
    isDivisionDeleteLoading: state.Setting.isDivisionDeleteLoading,
    DivisionActionId: state.Setting.DivisionActionId,
    DivisionDetail: state.Setting.DivisionDetail,
    DivisionData: state.Setting.DivisionData,
})

export default connect(mapStateToProps, { get_Division_Data, Delete_Division })(SettingDivisionDelete)
