import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Account_Data, Delete_Account } from '../../../../../../Store/Actions/Setting.Action'

import { FormGroup, FormControlLabel, Checkbox, Button, CircularProgress } from '@mui/material'

import Detail from '../../../../../Containers/Dashboard/Setting/User/Detail'

class SettingItemsView extends React.Component {
    state = {
        isConfirm: false,
    }
    componentDidMount() {
        const { AccountActionId } = this.props
        if (AccountActionId !== null) {
            this.props.get_Account_Data(AccountActionId)
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.AccountActionId !== prevProps.AccountActionId) {
            this.props.get_Account_Data(this.props.AccountActionId)
        }
    }

    OnChangeCheckbox = E => {
        this.setState({ [E.target.name]: !this.state[E.target.name] })
    }

    onClickDelete(Id) {
        this.props.Delete_Account(Id)
    }

    render() {

        const AccountActionId = this.props.AccountActionId
        const isAccountDetailLoading = this.props.isAccountDetailLoading
        const AccountDetail = this.props.AccountDetail

        const {
            isConfirm
        } = this.state

        return (
            <Fragment>
                <Detail Data={AccountDetail} isLoading={isAccountDetailLoading} />

                <div
                    style={{ margin: '1%' }}
                >
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox color='primary' onChange={this.OnChangeCheckbox} name="isConfirm" value={isConfirm} disabled={isAccountDetailLoading ? true : false} />}
                            label="Saya menyadari akan resikonya dan saya tau apa yang saya lakukan"
                        />
                    </FormGroup>
                    <center>
                        <Button
                            variant='contained'
                            size='large'
                            color='error'
                            disabled={!isConfirm || isAccountDetailLoading ? true : false}
                            onClick={() => this.onClickDelete(AccountActionId)}
                        >
                            {isAccountDetailLoading ? <CircularProgress /> : 'Delete'}
                        </Button>
                    </center>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isAccountDetailLoading: state.Setting.isAccountDetailLoading,
    AccountDetail: state.Setting.AccountDetail,
    AccountActionId: state.Account.AccountActionId,
})

export default connect(mapStateToProps, { get_Account_Data, Delete_Account })(SettingItemsView)