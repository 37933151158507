import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Department_Data, get_Department_Participan_Data, Add_To_DepartmentList, Delete_From_DepartmentList } from '../../../../../../Store/Actions/Setting.Action'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

import Detail from '../../../../../Containers/Dashboard/Setting/Department/Detail'
import AddParticipan from '../../../../../Containers/Dashboard/Setting/Department/AddParticipan'
import Data from '../../../../../Containers/Dashboard/Setting/Department/Data'

class SettingDepartmentView extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.DepartmentActionId !== prevProps.DepartmentActionId) {
            this.props.get_Department_Data(this.props.DepartmentActionId)
        }
        // if (this.props.DepartmentActionCounter !== prevProps.DepartmentActionCounter) {
        //     this.props.get_Department_Data(this.props.DepartmentActionId)
        // }
    }
    componentDidMount() {
        const { DepartmentActionId } = this.props
        if (DepartmentActionId !== null) {
            this.props.get_Department_Data(DepartmentActionId)
        }
        this.props.get_Department_Participan_Data()
    }

    DeleteFromDepartmentList(DepartmentListId) {
        this.props.Delete_From_DepartmentList(DepartmentListId)
    }
    render() {
        const isDepartmentActionAddListLoading = this.props.isDepartmentActionAddListLoading
        const DepartmentActionId = this.props.DepartmentActionId
        const DepartmentParticipant = this.props.DepartmentParticipant
        const isDepartmentParticipantLoading = this.props.isDepartmentParticipantLoading
        const isDepartmentActionGetDetailLoading = this.props.isDepartmentActionGetDetailLoading
        const DepartmentDetail = this.props.DepartmentDetail
        const DepartmentData = this.props.DepartmentData
        const HeadData = [
            {
                Nama: 'Index',
                width: '5%',
            },
            {
                Nama: 'Id',
                width: '10%',
            },
            {
                Nama: 'UserName',
                width: '10%',
            },
            {
                Nama: 'Nama',
                width: '10%',
            },
            {
                Nama: 'Department',
                width: '10%',
            },
            {
                Nama: 'Remove',
                width: '15%',
            }
        ]
        const BodyData = DepartmentData ? DepartmentData.map((item, index) => {
            return {
                index: index + 1,
                Id: item.Id,
                UserName: item.UserName,
                Nama: item.BiodataNama,
                Department: item.DepartemenNama,
                Remove: (
                    <DeleteOutlineIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.DeleteFromDepartmentList(item.Id)}
                    />
                )
            }
        }) : []
        return (
            <Fragment>
                <Detail Data={DepartmentDetail} isLoading={isDepartmentActionGetDetailLoading} />
                <AddParticipan ParticipanData={DepartmentParticipant} ParticipanDataLoading={isDepartmentParticipantLoading} DataId={DepartmentActionId} isLoading={isDepartmentActionAddListLoading} sendFunction={this.props.Add_To_DepartmentList} />
                <Data HeadData={HeadData} BodyData={BodyData} isLoading={isDepartmentActionGetDetailLoading} />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isDepartmentActionAddListLoading: state.Setting.isDepartmentActionAddListLoading,
    isDepartmentActionGetDetailLoading: state.Setting.isDepartmentActionGetDetailLoading,
    DepartmentActionId: state.Setting.DepartmentActionId,
    DepartmentParticipant: state.Setting.DepartmentParticipant,
    isDepartmentParticipantLoading: state.Setting.isDepartmentParticipantLoading,
    DepartmentDetail: state.Setting.DepartmentDetail,
    DepartmentData: state.Setting.DepartmentData,
})

export default connect(mapStateToProps, { get_Department_Data, get_Department_Participan_Data, Add_To_DepartmentList, Delete_From_DepartmentList })(SettingDepartmentView)
