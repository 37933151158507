// import { Create_Error_Messages } from './Store/Actions/Messages.Actions'

export const isDev = () => {
  if (process.env.NODE_ENV !== "production") {
    return true;
  } else {
    return false;
  }
  // return true
};

export const isAuth = () => {
  const Token = localStorage.getItem("ERecEtana_token");
  const isAuth = localStorage.getItem("ERecEtana_isAuth");
  if (Token && isAuth) {
    return true;
  } else {
    return false;
  }
};

export const Error_Messages_Standardization = (ErrorCode, MSG) => {
  const ErrorResponse = {
    response: {
      status: ErrorCode ? ErrorCode : 400,
      data: {
        msg: MSG ? MSG : "",
      },
    },
  };
  return ErrorResponse;
};

export const Handle_Error =
  (Location = "", ErrDetail = "", ErrMessages = "") =>
  (dispatch) => {
    // export const Handle_Error = (Location = '', ErrDetail = '', ErrMessages = '', Massages, MassagesCode = 400) => (dispatch) => {
    try {
      const isThisInDev = isDev();
      if (isThisInDev) {
        console.info(`🚀 ~ file: Global.js ~ line 32 ~ Location`, Location);
        console.log(`🚀 ~ file: Global.js ~ line 32 ~ ErrDetail`, ErrDetail);
        console.log(
          `🚀 ~ file: Global.js ~ line 32 ~ ErrMessages`,
          ErrMessages
        );
      }
    } catch (err) {
      console.log(`🚀 ~ file: Global.js ~ line 43 ~ err`, err);
    }
  };

export const ImageURL = {
  // Base: 'http://localhost:5000'
  Base: "https://erecruitmentapi.azurewebsites.net",
};

export const VariableLimit = {
  MPPStatus: {
    Accept: "Accept",
    Submitted: "Submitted",
    Reject: "Reject",
  },
  MPPJenis: {
    Yearly: "Yearly",
    Additional: "Additional",
    Replacement: "Replacement",
  },
  MPPType: {
    Contract: "Contract",
    Permanent: "Permanent",
  },
  Month: [
    {
      value: 0,
      display: "Jan",
    },
    {
      value: 1,
      display: "Feb",
    },
    {
      value: 2,
      display: "Mar",
    },
    {
      value: 3,
      display: "Apr",
    },
    {
      value: 4,
      display: "May",
    },
    {
      value: 5,
      display: "Jun",
    },
    {
      value: 6,
      display: "Jul",
    },
    {
      value: 7,
      display: "Aug",
    },
    {
      value: 8,
      display: "Sep",
    },
    {
      value: 9,
      display: "Oct",
    },
    {
      value: 10,
      display: "Nov",
    },
    {
      value: 11,
      display: "Dec",
    },
  ],
  JobProcess: {
    OnBoarding: {
      Accept: "Finished",
    },
    Contract: {
      Accept: "Accept",
    },
    PreBoarding: {
      Accept: "Done",
    },
    MCUApproval: {
      Accept: "Accept",
    },
    Offering: {
      Accept: "Accept",
    },
    UserFinalInterview: {
      Accept: "Accept",
    },
    HRInterview: {
      Accept: "Accept",
    },
    Referral: {
      Accept: "Dilanjutkan",
    },
  },
  SocialMediaInitLink: {
    Facebook: "https://www.facebook.com/",
    Instagram: "https://www.instagram.com/",
    Twitter: "https://twitter.com/",
    Linkedin: "https://www.linkedin.com/in/", //nugroho-suryo-buwono-3279b0218/
    // Linkedin: 'https://www.linkedin.com/',
  },
};

export const OptionalChoice = {
  Golongan: [
    {
      Option: "5",
      Value: "5",
    },
    {
      Option: "4",
      Value: "4",
    },
    {
      Option: "3A",
      Value: "3A",
    },
    {
      Option: "3B",
      Value: "3B",
    },
    {
      Option: "2A",
      Value: "2A",
    },
    {
      Option: "2B",
      Value: "2B",
    },
    {
      Option: "1A",
      Value: "1A",
    },
    {
      Option: "1B",
      Value: "1B",
    },
    {
      Option: "1C",
      Value: "1C",
    },
    {
      Option: "1D",
      Value: "1D",
    },
    {
      Option: "1E",
      Value: "1E",
    },
  ],
};

export const CharLimit = {
  Account: {
    Email: 300,
    UserName: 200,
  },
  Biodata: {
    Nama: 300,
    TempatLahir: 200,
    Telepon: 20,
    Alamat: 500,
    Hobi: 200,
    PendidikanNama: 200,
    PendidikanJurusan: 200,
    Salary: 200,
    KerjaReferensiNama: 100,
    KerjaReferensiJabatan: 200,
    KerjaReferensiKontak: 20,

    KerjaReferensiNamaKolega: 100,
    KerjaReferensiKontakKolega: 20,
    KerjaReferensiKeteranganKolega: 200,

    KerjaReferensiNamaHr: 100,
    KerjaReferensiKontakHr: 20,
    KerjaReferensiKeteranganHr: 200,

    KerjaReferensiNamaDirect: 100,
    KerjaReferensiKontakDirect: 20,
    KerjaReferensiKeteranganDirect: 200,

    KelaurgaEtanaNama: 200,
    FindOutEtana: 200,
  },
};

export const convertToDay = (FirstTime, SecondTime) => {
  try {
    const FirstTimeDate = new Date(FirstTime);
    const SecondTimeDate = new Date(SecondTime);
    const diffTime = Math.abs(SecondTimeDate - FirstTimeDate);
    const newDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return newDays;
  } catch (err) {
    console.log(`🚀 ~ file: Global.js ~ line 148 ~ convertToDay ~ err`, err);
    return false;
  }
};

export const convertToMonthName = (MonthValue) => {
  try {
    return MonthValue === 0
      ? "Jan"
      : MonthValue === 1
      ? "Feb"
      : MonthValue === 2
      ? "Mar"
      : MonthValue === 3
      ? "Apr"
      : MonthValue === 4
      ? "May"
      : MonthValue === 5
      ? "Jun"
      : MonthValue === 6
      ? "Jul"
      : MonthValue === 7
      ? "Aug"
      : MonthValue === 8
      ? "Sep"
      : MonthValue === 9
      ? "Oct"
      : MonthValue === 10
      ? "Nov"
      : MonthValue === 11
      ? "Dec"
      : MonthValue;
  } catch (err) {
    console.log(`🚀 ~ file: Global.js:197 ~ convertToMonthName ~ err`, err);
    return false;
  }
};

export const convertMonthToQue = (MonthValue) => {
  try {
    return MonthValue === 0 || MonthValue === 1 || MonthValue === 2
      ? "Q1"
      : MonthValue === 3 || MonthValue === 4 || MonthValue === 5
      ? "Q2"
      : MonthValue === 6 || MonthValue === 7 || MonthValue === 8
      ? "Q3"
      : MonthValue === 9 || MonthValue === 10 || MonthValue === 11
      ? "Q4"
      : MonthValue;
  } catch (err) {
    console.log(`🚀 ~ file: Global.js:210 ~ convertMonthToQue ~ err`, err);
    return false;
  }
};

export const isLevelQualified = (LevelId, LevelCompare) => {
  // const allLevel = [
  //     '12898', // Head
  //     '4968', // Sr. Manager
  //     '4736', // Manager
  //     '15894', // Jr. Manager
  //     '1224', // Sr. Supervisor
  //     '7845', // Supervisor
  //     '83910', // Jr. Supervisor
  //     '78955', // Sr. Staff
  //     '54621', // Staff
  //     '64925', // Sr. Technician
  //     '62659', // Technician
  //     '83032', // Admin
  // ]
  const isSrManager = [
    "4736", // Manager
    "15894", // Jr. Manager
    "1224", // Sr. Supervisor
    "7845", // Supervisor
    "83910", // Jr. Supervisor
    "78955", // Sr. Staff
    "54621", // Staff
    "64925", // Sr. Technician
    "62659", // Technician
    "83032", // Admin
    "19902", //Operator
  ];
  const isManager = [
    "15894", // Jr. Manager
    "1224", // Sr. Supervisor
    "7845", // Supervisor
    "83910", // Jr. Supervisor
    "78955", // Sr. Staff
    "54621", // Staff
    "64925", // Sr. Technician
    "62659", // Technician
    "83032", // Admin
    "19902", //Operator
  ];
  const isJrManager = [
    "1224", // Sr. Supervisor
    "7845", // Supervisor
    "83910", // Jr. Supervisor
    "78955", // Sr. Staff
    "54621", // Staff
    "64925", // Sr. Technician
    "62659", // Technician
    "83032", // Admin
    "19902", //Operator
  ];
  const isSrSupervisor = [
    "7845", // Supervisor
    "83910", // Jr. Supervisor
    "78955", // Sr. Staff
    "54621", // Staff
    "64925", // Sr. Technician
    "62659", // Technician
    "83032", // Admin
    "19902", //Operator
  ];
  const isSupervisor = [
    "83910", // Jr. Supervisor
    "78955", // Sr. Staff
    "54621", // Staff
    "64925", // Sr. Technician
    "62659", // Technician
    "83032", // Admin
    "19902", //Operator
  ];
  const isJrSupervisor = [
    "78955", // Sr. Staff
    "54621", // Staff
    "64925", // Sr. Technician
    "62659", // Technician
    "83032", // Admin
    "19902", //Operator
  ];
  const isSrStaff = [
    "54621", // Staff
    "62659", // Technician
    "83032", // Admin
    "19902", //Operator
  ];
  try {
    return LevelId === "12898"
      ? true
      : LevelId === "4968" && isSrManager.some((c) => c === LevelCompare)
      ? true
      : LevelId === "4736" && isManager.some((c) => c === LevelCompare)
      ? true
      : LevelId === "15894" && isJrManager.some((c) => c === LevelCompare)
      ? true
      : LevelId === "1224" && isSrSupervisor.some((c) => c === LevelCompare)
      ? true
      : LevelId === "7845" && isSupervisor.some((c) => c === LevelCompare)
      ? true
      : LevelId === "83910" && isJrSupervisor.some((c) => c === LevelCompare)
      ? true
      : (LevelId === "78955" || LevelId === "64925") &&
        isSrStaff.some((c) => c === LevelCompare)
      ? true
      : false;
  } catch (err) {
    console.log(`🚀 ~ file: Global.js:359 ~ isLevelQualified ~ err`, err);
    return false;
  }
};
