import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Konfirm_Interview_HR } from '../../../../../../../Store/Actions/Job.Action'
import { get_Jobdesk_Pertanyaan } from '../../../../../../../Store/Actions/Jobdesk.Action'
import { Load_Pertanyaan_Interview_List } from '../../../../../../../Store/Actions/Setting.Action'
import { Create_Warning_Messages } from '../../../../../../../Store/Actions/Messages.Actions'

import { Skeleton, Checkbox, TextField, FormControl, InputLabel, Select, Button } from '@mui/material'

import CoTable from '../../../../../../Containers/Table'

class PenilaianInterviewHR extends React.Component {
    state = {
        InterviewDate: '',
        KeputusanHasil: '',
        Keterangan: '',
        PertanyaanList: [],
        PertanyaanPerilaku: [],
        PertanyaanTeknik: [],
    }

    componentDidMount() {
        this.props.Load_Pertanyaan_Interview_List('HR')
        const JobActionId = this.props.JobActionId
        const AccountActionId = this.props.AccountActionId
        if (JobActionId && AccountActionId) {
            this.props.get_Jobdesk_Pertanyaan(JobActionId, AccountActionId)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.PertanyaanList !== prevProps.PertanyaanList) {
            const { PertanyaanList } = this.props
            const newPertanyaanList = []
            PertanyaanList.map((item, index) => newPertanyaanList.push({
                Id: item.Id,
                Nama: item.Nama,
                Point: 0,
                Keterangan: ''
            }))
            this.setState({ PertanyaanList: newPertanyaanList })
        }

        if (this.props.JobdeskGetPertanyaanPerilaku !== prevProps.JobdeskGetPertanyaanPerilaku) {
            const { JobdeskGetPertanyaanPerilaku } = this.props
            const newPertanyaanPerilaku = []
            JobdeskGetPertanyaanPerilaku.map((item, index) => newPertanyaanPerilaku.push({
                Id: item.Id,
                Nama: item.Uraian,
                PatokanPoint: item.Point,
                Point: 0,
                Keterangan: ''
            }))
            this.setState({ PertanyaanPerilaku: newPertanyaanPerilaku })
        }

        if (this.props.JobdeskGetPertanyaanTeknik !== prevProps.JobdeskGetPertanyaanTeknik) {
            const { JobdeskGetPertanyaanTeknik } = this.props
            const newPertanyaanTeknik = []
            JobdeskGetPertanyaanTeknik.map((item, index) => newPertanyaanTeknik.push({
                Id: item.Id,
                Nama: item.Uraian,
                PatokanPoint: item.Point,
                Point: 0,
                Keterangan: ''
            }))
            this.setState({ PertanyaanTeknik: newPertanyaanTeknik })
        }
    }

    onChangeCheckboxMap = (e, index, value) => {
        const PertanyaanList = this.state.PertanyaanList
        PertanyaanList[index].Point = value
        this.setState({ PertanyaanList: PertanyaanList })
    }

    onChangeFieldMap = (e, index) => {
        const PertanyaanList = this.state.PertanyaanList
        PertanyaanList[index].Keterangan = e.target.value
        this.setState({ PertanyaanList: PertanyaanList })
    }

    onChangeCheckboxMap2 = (e, index, value) => {
        const PertanyaanPerilaku = this.state.PertanyaanPerilaku
        PertanyaanPerilaku[index].Point = value
        this.setState({ PertanyaanPerilaku: PertanyaanPerilaku })
    }

    onChangeFieldMap2 = (e, index) => {
        const PertanyaanPerilaku = this.state.PertanyaanPerilaku
        PertanyaanPerilaku[index].Keterangan = e.target.value
        this.setState({ PertanyaanPerilaku: PertanyaanPerilaku })
    }

    onChangeCheckboxMap3 = (e, index, value) => {
        const PertanyaanTeknik = this.state.PertanyaanTeknik
        PertanyaanTeknik[index].Point = value
        this.setState({ PertanyaanTeknik: PertanyaanTeknik })
    }

    onChangeFieldMap3 = (e, index) => {
        const PertanyaanTeknik = this.state.PertanyaanTeknik
        PertanyaanTeknik[index].Keterangan = e.target.value
        this.setState({ PertanyaanTeknik: PertanyaanTeknik })
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    SelectFormOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    formOnSubmit = (e) => {
        e.preventDefault()

        const JobActionId = this.props.JobActionId
        const AccountActionId = this.props.AccountActionId
        const PertanyaanList = this.state.PertanyaanList
        const PertanyaanPerilaku = this.state.PertanyaanPerilaku
        const PertanyaanTeknik = this.state.PertanyaanTeknik
        const isThereZero = PertanyaanList.find(item => item.Point === 0)
        const isThereZero2 = PertanyaanPerilaku.find(item => item.Point === 0)
        // const isThereZero3 = PertanyaanTeknik.find(item => item.Point === 0)
        const isThereZero3 = false
        if (isThereZero || isThereZero2 || isThereZero3) {
            this.props.Create_Warning_Messages(null, 'tidak boleh ada yang kosong')
        } else {
            this.props.Konfirm_Interview_HR(
                JobActionId,
                AccountActionId,
                this.state.InterviewDate,
                this.state.KeputusanHasil,
                this.state.Keterangan,
                PertanyaanList,
                PertanyaanPerilaku,
                PertanyaanTeknik,
            )
        }
    }

    render() {
        const isPertanyaanLoading = this.props.isPertanyaanLoading
        // const PertanyaanList = this.props.PertanyaanList
        const isJobdeskGetPertanyaanLoading = this.props.isJobdeskGetPertanyaanLoading
        // const JobdeskGetPertanyaanPerilaku = this.props.JobdeskGetPertanyaanPerilaku
        // const JobdeskGetPertanyaanTeknik = this.props.JobdeskGetPertanyaanTeknik
        const {
            InterviewDate,
            KeputusanHasil,
            Keterangan,
            PertanyaanList,
            PertanyaanPerilaku,
            // PertanyaanTeknik,
        } = this.state

        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Pertanyaan',
                width: '35%',
            },
            {
                Nama: '1',
                width: '5%',
            },
            {
                Nama: '2',
                width: '5%',
            },
            {
                Nama: '3',
                width: '5%',
            },
            {
                Nama: '4',
                width: '5%',
            },
            {
                Nama: '5',
                width: '5%',
            },
            {
                Nama: 'Keterangan',
                width: '20%',
            },
        ]

        const BodyData = PertanyaanList.map((item, index) => {
            return {
                index: index + 1,
                Pertanyaan: item.Nama,
                Point1: (
                    <Checkbox
                        checked={PertanyaanList[index].Point === 1 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap(e, index, 1)}
                    />
                ),
                Point2: (
                    <Checkbox
                        checked={PertanyaanList[index].Point === 2 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap(e, index, 2)}
                    />
                ),
                Point3: (
                    <Checkbox
                        checked={PertanyaanList[index].Point === 3 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap(e, index, 3)}
                    />
                ),
                Point4: (
                    <Checkbox
                        checked={PertanyaanList[index].Point === 4 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap(e, index, 4)}
                    />
                ),
                Point5: (
                    <Checkbox
                        checked={PertanyaanList[index].Point === 5 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap(e, index, 5)}
                    />
                ),
                Keterangan: (
                    <TextField
                        // style={st_halftextfield}
                        variant='outlined'
                        onChange={(e) => this.onChangeFieldMap(e, index)}
                        type='text'
                        label={`Keterangan ${index + 1}`}
                        name='InterviewDate'
                    // value={InterviewDate}
                    />
                ),
            }
        })

        const HeadData2 = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Pertanyaan',
                width: '35%',
            },
            {
                Nama: 'Patokan Point 1',
                width: '5%',
            },
            {
                Nama: 'Patokan Point 2',
                width: '5%',
            },
            {
                Nama: 'Patokan Point 3',
                width: '5%',
            },
            {
                Nama: ' ',
                width: '10%',
            },
            {
                Nama: '1',
                width: '5%',
            },
            {
                Nama: '2',
                width: '5%',
            },
            {
                Nama: '3',
                width: '5%',
            },
            {
                Nama: 'Keterangan',
                width: '20%',
            },
        ]

        const BodyData2 = PertanyaanPerilaku.map((item, index) => {
            return {
                index: index + 1,
                Pertanyaan: item.Nama,
                PatokanPoint1: (
                    <Checkbox
                        checked={PertanyaanPerilaku[index].PatokanPoint === 1 ? true : false}
                        disabled
                    />
                ),
                PatokanPoint2: (
                    <Checkbox
                        checked={PertanyaanPerilaku[index].PatokanPoint === 2 ? true : false}
                        disabled
                    />
                ),
                PatokanPoint3: (
                    <Checkbox
                        checked={PertanyaanPerilaku[index].PatokanPoint === 3 ? true : false}
                        disabled
                    />
                ),
                Space: ' ',
                Point1: (
                    <Checkbox
                        checked={PertanyaanPerilaku[index].Point === 1 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap2(e, index, 1)}
                    />
                ),
                Point2: (
                    <Checkbox
                        checked={PertanyaanPerilaku[index].Point === 2 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap2(e, index, 2)}
                    />
                ),
                Point3: (
                    <Checkbox
                        checked={PertanyaanPerilaku[index].Point === 3 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap2(e, index, 3)}
                    />
                ),
                Keterangan: (
                    <TextField
                        // style={st_halftextfield}
                        variant='outlined'
                        onChange={(e) => this.onChangeFieldMap2(e, index)}
                        type='text'
                        label={`Keterangan ${index + 1}`}
                        name='InterviewDate'
                        value={PertanyaanPerilaku[index].Keterangan}
                    />
                ),
            }
        })

        // const HeadData3 = [
        //     {
        //         Nama: 'No',
        //         width: '5%',
        //     },
        //     {
        //         Nama: 'Pertanyaan',
        //         width: '35%',
        //     },
        //     {
        //         Nama: 'Patokan Point 1',
        //         width: '5%',
        //     },
        //     {
        //         Nama: 'Patokan Point 2',
        //         width: '5%',
        //     },
        //     {
        //         Nama: 'Patokan Point 3',
        //         width: '5%',
        //     },
        //     {
        //         Nama: ' ',
        //         width: '10%',
        //     },
        //     {
        //         Nama: '1',
        //         width: '5%',
        //     },
        //     {
        //         Nama: '2',
        //         width: '5%',
        //     },
        //     {
        //         Nama: '3',
        //         width: '5%',
        //     },
        //     {
        //         Nama: 'Keterangan',
        //         width: '20%',
        //     },
        // ]

        // const BodyData3 = PertanyaanTeknik.map((item, index) => {
        //     return {
        //         index: index + 1,
        //         Pertanyaan: item.Nama,
        //         PatokanPoint1: (
        //             <Checkbox
        //                 checked={PertanyaanTeknik[index].PatokanPoint === 1 ? true : false}
        //                 disabled
        //             />
        //         ),
        //         PatokanPoint2: (
        //             <Checkbox
        //                 checked={PertanyaanTeknik[index].PatokanPoint === 2 ? true : false}
        //                 disabled
        //             />
        //         ),
        //         PatokanPoint3: (
        //             <Checkbox
        //                 checked={PertanyaanTeknik[index].PatokanPoint === 3 ? true : false}
        //                 disabled
        //             />
        //         ),
        //         Space: ' ',
        //         Point1: (
        //             <Checkbox
        //                 checked={PertanyaanTeknik[index].Point === 1 ? true : false}
        //                 onChange={(e) => this.onChangeCheckboxMap3(e, index, 1)}
        //             />
        //         ),
        //         Point2: (
        //             <Checkbox
        //                 checked={PertanyaanTeknik[index].Point === 2 ? true : false}
        //                 onChange={(e) => this.onChangeCheckboxMap3(e, index, 2)}
        //             />
        //         ),
        //         Point3: (
        //             <Checkbox
        //                 checked={PertanyaanTeknik[index].Point === 3 ? true : false}
        //                 onChange={(e) => this.onChangeCheckboxMap3(e, index, 3)}
        //             />
        //         ),
        //         Keterangan: (
        //             <TextField
        //                 // style={st_halftextfield}
        //                 variant='outlined'
        //                 onChange={(e) => this.onChangeFieldMap3(e, index)}
        //                 type='text'
        //                 label={`Keterangan ${index + 1}`}
        //                 name='InterviewDate'
        //                 value={PertanyaanTeknik[index].Keterangan}
        //             />
        //         ),
        //     }
        // })

        const Score = PertanyaanList.reduce((accumulator, object) => {
            return accumulator + object.Point
        }, 0)

        const isRecommended = (Score / PertanyaanList.length) > ((5 * PertanyaanList.length) / PertanyaanList.length) / 2 ? true : false

        const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }
        const st_halftextfield = { ...st_textfield, width: '50%' }

        return (
            <Fragment>
                {false ?
                    <Fragment>
                        {[1, 2, 3, 4, 5,].map((item) =>
                            <Skeleton
                                key={`PenilaianInterviewHR_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <form
                            onSubmit={this.formOnSubmit}
                        >
                            <h2>
                                Data Pelamar
                            </h2>
                            <TextField
                                style={st_halftextfield}
                                variant='outlined'
                                onChange={this.onChangeField}
                                type='datetime-local'
                                label='Tanggal Interview'
                                name='InterviewDate'
                                required
                                value={InterviewDate}
                                InputLabelProps={{ shrink: true }}
                            />
                            <br />
                            <h2>
                                Form Penilaian
                            </h2>
                            <CoTable
                                TableId={'PenilaianInterviewHR_Penilaian'}
                                HeadData={HeadData}
                                Data={BodyData}
                                isHeadLoading={isPertanyaanLoading}
                                isBodyLoading={isPertanyaanLoading}
                            />
                            <h2>
                                Job Profile Assessment
                            </h2>
                            <h3>
                                Perilaku
                            </h3>
                            <CoTable
                                TableId={'PenilaianInterviewUser_Penilaian_Perilaku'}
                                HeadData={HeadData2}
                                Data={BodyData2}
                                isHeadLoading={isJobdeskGetPertanyaanLoading}
                                isBodyLoading={isJobdeskGetPertanyaanLoading}
                            />
                            {/* <h3>
                                Teknik
                            </h3>
                            <CoTable
                                TableId={'PenilaianInterviewUser_Penilaian_Teknik'}
                                HeadData={HeadData3}
                                Data={BodyData3}
                                isHeadLoading={isJobdeskGetPertanyaanLoading}
                                isBodyLoading={isJobdeskGetPertanyaanLoading}
                            /> */}
                            <h2>
                                Keputusan Hasil Interview
                            </h2>
                            <h3>
                                Score Interview Adalah:
                                <span
                                    style={{ color: isRecommended ? 'green' : 'red' }}
                                >
                                    {` ${(Score / (PertanyaanList.length * 5)) * 100}%`}
                                </span>
                            </h3>
                            <h3>
                                Disarankan Untuk
                                <span
                                    style={{ color: isRecommended ? 'green' : 'red' }}
                                >
                                    {isRecommended ? ` Accept` : ` Reject`}
                                </span>
                            </h3>
                            <FormControl style={st_textfield} variant="filled" required >
                                <InputLabel shrink >Keputusan Hasil Interview</InputLabel>
                                <Select native style={{ backgroundColor: 'white' }} onChange={(e) => this.SelectFormOnChange(e)} label="Keputusan Hasil Interview" name='KeputusanHasil' value={KeputusanHasil} >
                                    <option value="" disabled> -- select an option -- </option>
                                    {['Accept', 'Reject'].map((option, index) =>
                                        <option key={index} value={option}>{option}</option>
                                    )}
                                </Select>
                            </FormControl>
                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                onChange={this.onChangeField}
                                type='texts'
                                label='Keterangan'
                                name='Keterangan'
                                value={Keterangan}
                            />
                            <center>
                                <Button
                                    variant='contained'
                                    style={{ width: '60%', margin: '1% 0 3% 0' }}
                                    type='submit'
                                >
                                    Submit
                                </Button>
                            </center>
                        </form>
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isPertanyaanLoading: state.Setting.isPertanyaanLoading,
    PertanyaanList: state.Setting.PertanyaanList,
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
    isJobdeskGetPertanyaanLoading: state.Jobdesk.isJobdeskGetPertanyaanLoading,
    JobdeskGetPertanyaanPerilaku: state.Jobdesk.JobdeskGetPertanyaanPerilaku,
    JobdeskGetPertanyaanTeknik: state.Jobdesk.JobdeskGetPertanyaanTeknik,
})

export default connect(mapStateToProps, { Load_Pertanyaan_Interview_List, get_Jobdesk_Pertanyaan, Konfirm_Interview_HR, Create_Warning_Messages })(PenilaianInterviewHR)