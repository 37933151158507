import React, { Fragment } from 'react'

import { connect } from 'react-redux'
import { Update_Biodata_Extend } from '../../../../../../Store/Actions/Account.Action'
import { Create_Warning_Messages } from '../../../../../../Store/Actions/Messages.Actions'

import { Skeleton, Button, TextField, FormControl, InputLabel, Select } from '@mui/material'

import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
// import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'

import { ImageURL } from '../../../../../../Global'

import PhotoGuidance from '../../../../../Containers/Account/PhotoGuidance'

class Biodata extends React.Component {
    state = {
        NamaLengkap: '',
        NamaPanggilan: '',
        TanggalMulaiBekerja: '',
        TempatLahir: '',
        TanggalLahir: '',
        Divisi: '',
        Sub: '',

        AlamatKTP: '',
        KelurahanKTP: '',
        KotaKTP: '',
        KodePosKTP: '',
        TlpnRumahKTP: '',

        Alamat: '',
        Kelurahan: '',
        Kota: '',
        KodePos: '',
        TlpnRumah: '',
        Hp: '',
        Kelamin: '',
        Agama: '',
        Perkawinan: '',
        Kewarganegaraan: '',
        KTP: '',
        KK: '',
        NPWP: '',
        BPJSKesehatan: '',
        BPJSKetenagakerjaan: '',
        BCA: '',

        NamaPasangan: '',
        TanggalLahirPasangan: '',
        PendidikanPasangan: '',
        PekerjaanPasangan: '',
        PerusahaanPasangan: '',

        DataAnak: [],

        NamaAyah: '',
        TanggalLahirAyah: '',
        NamaIbu: '',
        TanggalLahirIbu: '',
        AlamatOrangTua: '',
        KelurahanOrangTua: '',
        KotaOrangTua: '',
        KodePosOrangTua: '',
        NamaAyahMertua: '',
        NamaIbuMertua: '',
        AlamatMertua: '',
        KelurahanMertua: '',
        KotaMertua: '',
        KodePosMertua: '',

        NamaWaris: '',
        HubunganWaris: '',
        KontakWaris: '',

        NamaDarurat: '',
        NoDarurat: '',
        HubunganDarurat: '',

        DataPendidikanFormal: [],

        DataPendidikanInformal: [],

        DataPengalamanKerja: [],

        OldFileFoto: '',
        FileFoto: '',
        OldFileKTP: '',
        FileKTP: '',
        OldFileBPJSKesehatan: '',
        FileBPJSKesehatan: '',
        OldFileBPJSKetenagakerjaan: '',
        FileBPJSKetenagakerjaan: '',
        OldFileRekeningBCA: '',
        FileRekeningBCA: '',
        OldFileNPWP: '',
        FileNPWP: '',
        OldFileKartuKeluarga: '',
        FileKartuKeluarga: '',
        OldFileIjazah: '',
        FileIjazah: '',
        OldFileSertifikasi: '',
        FileSertifikasi: '',
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    componentDidMount() {
        const { Biodata } = this.props
        const Data = Biodata.Data
        const Anak = Biodata.Anak
        const PendidikanFormal = Biodata.PendidikanFormal
        const PendidikanInformal = Biodata.PendidikanInformal
        const PengalamanKerja = Biodata.PengalamanKerja

        this.setState({
            NamaLengkap: Data.NamaLengkap ? Data.NamaLengkap : '',
            NamaPanggilan: Data.NamaPanggilan ? Data.NamaPanggilan : '',
            TanggalMulaiBekerja: Data.TanggalMulaiBekerja ? new Date(Data.TanggalMulaiBekerja).toISOString().slice(0, 10) : '',
            TempatLahir: Data.TempatLahir ? Data.TempatLahir : '',
            TanggalLahir: Data.TanggalLahir ? new Date(Data.TanggalLahir).toISOString().slice(0, 10) : '',
            Divisi: Data.Divisi ? Data.Divisi : '',
            Sub: Data.Sub ? Data.Sub : '',

            AlamatKTP: Data.AlamatKTP ? Data.AlamatKTP : '',
            KelurahanKTP: Data.KelurahanKTP ? Data.KelurahanKTP : '',
            KotaKTP: Data.KotaKTP ? Data.KotaKTP : '',
            KodePosKTP: Data.KodePosKTP ? Data.KodePosKTP : '',
            TlpnRumahKTP: Data.TlpnRumahKTP ? Data.TlpnRumahKTP : '',

            Alamat: Data.Alamat ? Data.Alamat : '',
            Kelurahan: Data.Kelurahan ? Data.Kelurahan : '',
            Kota: Data.Kota ? Data.Kota : '',
            KodePos: Data.KodePos ? Data.KodePos : '',
            TlpnRumah: Data.TlpnRumah ? Data.TlpnRumah : '',
            Hp: Data.Hp ? Data.Hp : '',
            Kelamin: Data ? Data.Kelamin : '',
            Agama: Data.Agama ? Data.Agama : '',
            Perkawinan: Data.Perkawinan ? Data.Perkawinan : '',
            Kewarganegaraan: Data.Kewarganegaraan ? Data.Kewarganegaraan : '',
            KTP: Data.KTP ? Data.KTP : '',
            KK: Data.KK ? Data.KK : '',
            NPWP: Data.NPWP ? Data.NPWP : '',
            BPJSKesehatan: Data.BPJSKesehatan ? Data.BPJSKesehatan : '',
            BPJSKetenagakerjaan: Data.BPJSKetenagakerjaan ? Data.BPJSKetenagakerjaan : '',
            BCA: Data.BCA ? Data.BCA : '',

            NamaPasangan: Data.NamaPasangan ? Data.NamaPasangan : '',
            TanggalLahirPasangan: Data.TanggalLahirPasangan ? new Date(Data.TanggalLahirPasangan).toISOString().slice(0, 10) : '',
            PendidikanPasangan: Data.PendidikanPasangan ? Data.PendidikanPasangan : '',
            PekerjaanPasangan: Data.PekerjaanPasangan ? Data.PekerjaanPasangan : '',
            PerusahaanPasangan: Data.PerusahaanPasangan ? Data.PerusahaanPasangan : '',

            // DataAnak: Anak ? Anak : [],

            NamaAyah: Data.NamaAyah ? Data.NamaAyah : '',
            TanggalLahirAyah: Data.TanggalLahirAyah ? new Date(Data.TanggalLahirAyah).toISOString().slice(0, 10) : '',
            NamaIbu: Data.NamaIbu ? Data.NamaIbu : '',
            TanggalLahirIbu: Data.TanggalLahirIbu ? new Date(Data.TanggalLahirIbu).toISOString().slice(0, 10) : '',
            AlamatOrangTua: Data.AlamatOrangTua ? Data.AlamatOrangTua : '',
            KelurahanOrangTua: Data.KelurahanOrangTua ? Data.KelurahanOrangTua : '',
            KotaOrangTua: Data.KotaOrangTua ? Data.KotaOrangTua : '',
            KodePosOrangTua: Data.KodePosOrangTua ? Data.KodePosOrangTua : '',
            NamaAyahMertua: Data.NamaAyahMertua ? Data.NamaAyahMertua : '',
            NamaIbuMertua: Data.NamaIbuMertua ? Data.NamaIbuMertua : '',
            AlamatMertua: Data.AlamatMertua ? Data.AlamatMertua : '',
            KelurahanMertua: Data.KelurahanMertua ? Data.KelurahanMertua : '',
            KotaMertua: Data.KotaMertua ? Data.KotaMertua : '',
            KodePosMertua: Data.KodePosMertua ? Data.KodePosMertua : '',

            NamaWaris: Data.NamaWaris ? Data.NamaWaris : '',
            HubunganWaris: Data.HubunganWaris ? Data.HubunganWaris : '',
            KontakWaris: Data.KontakWaris ? Data.KontakWaris : '',

            NamaDarurat: Data.NamaDarurat ? Data.NamaDarurat : '',
            NoDarurat: Data.NoDarurat ? Data.NoDarurat : '',
            HubunganDarurat: Data.HubunganDarurat ? Data.HubunganDarurat : '',

            DataPendidikanFormal: PendidikanFormal ? PendidikanFormal : [],

            DataPendidikanInformal: PendidikanInformal ? PendidikanInformal : [],

            DataPengalamanKerja: PengalamanKerja ? PengalamanKerja : [],

            OldFileFoto: Data.FileFoto ? Data.FileFoto : '',
            OldFileKTP: Data.FileKTP ? Data.FileKTP : '',
            OldFileBPJSKesehatan: Data.FileBPJSKesehatan ? Data.FileBPJSKesehatan : '',
            OldFileBPJSKetenagakerjaan: Data.FileBPJSKetenagakerjaan ? Data.FileBPJSKetenagakerjaan : '',
            OldFileRekeningBCA: Data.FileRekeningBCA ? Data.FileRekeningBCA : '',
            OldFileNPWP: Data.FileNPWP ? Data.FileNPWP : '',
            OldFileKartuKeluarga: Data.FileKartuKeluarga ? Data.FileKartuKeluarga : '',
            OldFileIjazah: Data.FileIjazah ? Data.FileIjazah : '',
            OldFileSertifikasi: Data.FileSertifikasi ? Data.FileSertifikasi : '',
        })

        const newAnak = []
        for (const x of Anak) {
            const newNewAnak = {
                Nama: x.Nama,
                Kelamin: x.Kelamin,
                TanggalLahir: new Date(x.TanggalLahir).toISOString().slice(0, 10)
            }
            newAnak.push(newNewAnak)
        }

        this.setState({ DataAnak: newAnak })
    }

    File_OnChange = E => {
        this.setState({ [E.target.name]: E.target.files[0] })
    }

    AddDetailList = () => {
        const DataAnak = this.state.DataAnak
        if (
            DataAnak.length === 0 ||
            (DataAnak[DataAnak.length - 1].Nama &&
                (DataAnak[DataAnak.length - 1].Kelamin === true || DataAnak[DataAnak.length - 1].Kelamin === false) &&
                DataAnak[DataAnak.length - 1].TanggalLahir
            )
        ) {
            const newData = { Nama: '', Kelamin: '', TanggalLahir: '' }
            DataAnak.push(newData)
            this.setState({ DataAnak: DataAnak })
        } else {
            this.props.Create_Warning_Messages(null, 'mohon lengkapi proses pengisian terlebih dahulu')
        }
    }

    DeleteDetailList = (Index) => {
        const DataAnak = this.state.DataAnak
        DataAnak.splice(Index, 1)
        this.setState({ DataAnak: DataAnak })
    }

    OnChangeMapItem = (e, Index, Order) => {
        const DataAnak = this.state.DataAnak
        if (Order === 1) {
            DataAnak[Index].Nama = e.target.value
        } else if (Order === 2) {
            DataAnak[Index].Kelamin = e.target.value
        } else if (Order === 3) {
            DataAnak[Index].TanggalLahir = e.target.value
        } else {

        }
        this.setState({ DataAnak: DataAnak })
    }

    AddDetailListFormal = () => {
        const DataPendidikanFormal = this.state.DataPendidikanFormal
        // if (DataPendidikanFormal.length !== 0) {
        //     console.log(DataPendidikanFormal[DataPendidikanFormal.length - 1].TahunMulai)
        //     console.log(DataPendidikanFormal[DataPendidikanFormal.length - 1].TahunSelesai)
        //     console.log(DataPendidikanFormal[DataPendidikanFormal.length - 1].Jurusan)
        //     console.log(DataPendidikanFormal[DataPendidikanFormal.length - 1].Kota)
        //     console.log((DataPendidikanFormal[DataPendidikanFormal.length - 1].Sertifikat === true || DataPendidikanFormal[DataPendidikanFormal.length - 1].Sertifikat === false))
        // }
        if (
            DataPendidikanFormal.length === 0 ||
            (DataPendidikanFormal[DataPendidikanFormal.length - 1].Jenis &&
                DataPendidikanFormal[DataPendidikanFormal.length - 1].InstansiNama &&
                DataPendidikanFormal[DataPendidikanFormal.length - 1].TahunMulai &&
                DataPendidikanFormal[DataPendidikanFormal.length - 1].TahunSelesai &&
                DataPendidikanFormal[DataPendidikanFormal.length - 1].Jurusan &&
                DataPendidikanFormal[DataPendidikanFormal.length - 1].Kota
            )
        ) {
            const newData = { Jenis: '', InstansiNama: '', TahunMulai: '', TahunSelesai: '', Jurusan: '', Kota: '', Sertifikat: '' }
            DataPendidikanFormal.push(newData)
            this.setState({ DataPendidikanFormal: DataPendidikanFormal })
        } else {
            this.props.Create_Warning_Messages(null, 'mohon lengkapi proses pengisian terlebih dahulu')
        }
    }

    DeleteDetailListFormal = (Index) => {
        const DataPendidikanFormal = this.state.DataPendidikanFormal
        DataPendidikanFormal.splice(Index, 1)
        this.setState({ DataPendidikanFormal: DataPendidikanFormal })
    }

    OnChangeMapItemFormal = (e, Index, Order) => {
        const DataPendidikanFormal = this.state.DataPendidikanFormal
        if (Order === 1) {
            DataPendidikanFormal[Index].Jenis = e.target.value
        } else if (Order === 2) {
            DataPendidikanFormal[Index].InstansiNama = e.target.value
        } else if (Order === 3) {
            DataPendidikanFormal[Index].TahunMulai = e.target.value
        } else if (Order === 4) {
            DataPendidikanFormal[Index].TahunSelesai = e.target.value
        } else if (Order === 5) {
            DataPendidikanFormal[Index].Jurusan = e.target.value
        } else if (Order === 6) {
            DataPendidikanFormal[Index].Kota = e.target.value
        } else if (Order === 7) {
            DataPendidikanFormal[Index].Sertifikat = e.target.value
        } else {

        }
        this.setState({ DataPendidikanFormal: DataPendidikanFormal })
    }

    AddDetailListInformal = () => {
        const DataPendidikanInformal = this.state.DataPendidikanInformal
        if (
            DataPendidikanInformal.length === 0 ||
            (DataPendidikanInformal[DataPendidikanInformal.length - 1].Jenis &&
                DataPendidikanInformal[DataPendidikanInformal.length - 1].TahunSelesai &&
                DataPendidikanInformal[DataPendidikanInformal.length - 1].Durasi &&
                DataPendidikanInformal[DataPendidikanInformal.length - 1].Nama &&
                DataPendidikanInformal[DataPendidikanInformal.length - 1].Bidang &&
                DataPendidikanInformal[DataPendidikanInformal.length - 1].Kota
            )
        ) {
            const newData = { Jenis: '', TahunSelesai: '', Durasi: '', Nama: '', Bidang: '', Kota: '', Sertifikat: '' }
            DataPendidikanInformal.push(newData)
            this.setState({ DataPendidikanInformal: DataPendidikanInformal })
        } else {
            this.props.Create_Warning_Messages(null, 'mohon lengkapi proses pengisian terlebih dahulu')
        }
    }

    DeleteDetailListInformal = (Index) => {
        const DataPendidikanInformal = this.state.DataPendidikanInformal
        DataPendidikanInformal.splice(Index, 1)
        this.setState({ DataPendidikanInformal: DataPendidikanInformal })
    }

    OnChangeMapItemInformal = (e, Index, Order) => {
        const DataPendidikanInformal = this.state.DataPendidikanInformal
        if (Order === 1) {
            DataPendidikanInformal[Index].Jenis = e.target.value
        } else if (Order === 2) {
            DataPendidikanInformal[Index].TahunSelesai = e.target.value
        } else if (Order === 3) {
            DataPendidikanInformal[Index].Durasi = e.target.value
        } else if (Order === 4) {
            DataPendidikanInformal[Index].Nama = e.target.value
        } else if (Order === 5) {
            DataPendidikanInformal[Index].Bidang = e.target.value
        } else if (Order === 6) {
            DataPendidikanInformal[Index].Kota = e.target.value
        } else if (Order === 7) {
            DataPendidikanInformal[Index].Sertifikat = e.target.value
        } else {

        }
        this.setState({ DataPendidikanInformal: DataPendidikanInformal })
    }

    AddDetailListPengalamanKerja = () => {
        const DataPengalamanKerja = this.state.DataPengalamanKerja
        if (
            DataPengalamanKerja.length === 0 ||
            (DataPengalamanKerja[DataPengalamanKerja.length - 1].NamaPerusaaahn &&
                DataPengalamanKerja[DataPengalamanKerja.length - 1].TahunMulai &&
                DataPengalamanKerja[DataPengalamanKerja.length - 1].TahunSelesai &&
                DataPengalamanKerja[DataPengalamanKerja.length - 1].Posisi &&
                DataPengalamanKerja[DataPengalamanKerja.length - 1].BidangPekerjaan &&
                DataPengalamanKerja[DataPengalamanKerja.length - 1].BidangUsaha
            )
        ) {
            const newData = { NamaPerusaaahn: '', TahunMulai: '', TahunSelesai: '', Posisi: '', BidangPekerjaan: '', BidangUsaha: '' }
            DataPengalamanKerja.push(newData)
            this.setState({ DataPengalamanKerja: DataPengalamanKerja })
        } else {
            this.props.Create_Warning_Messages(null, 'mohon lengkapi proses pengisian terlebih dahulu')
        }
    }

    DeleteDetailListPengalamanKerja = (Index) => {
        const DataPengalamanKerja = this.state.DataPengalamanKerja
        DataPengalamanKerja.splice(Index, 1)
        this.setState({ DataPengalamanKerja: DataPengalamanKerja })
    }

    OnChangeMapItemPengalamanKerja = (e, Index, Order) => {
        const DataPengalamanKerja = this.state.DataPengalamanKerja
        if (Order === 1) {
            DataPengalamanKerja[Index].NamaPerusaaahn = e.target.value
        } else if (Order === 2) {
            DataPengalamanKerja[Index].TahunMulai = e.target.value
        } else if (Order === 3) {
            DataPengalamanKerja[Index].TahunSelesai = e.target.value
        } else if (Order === 4) {
            DataPengalamanKerja[Index].Posisi = e.target.value
        } else if (Order === 5) {
            DataPengalamanKerja[Index].BidangPekerjaan = e.target.value
        } else if (Order === 6) {
            DataPengalamanKerja[Index].BidangUsaha = e.target.value
        } else {

        }
        this.setState({ DataPengalamanKerja: DataPengalamanKerja })
    }

    formOnSubmit = (e) => {
        e.preventDefault()

        const BiodataData = this.props.Biodata
        const BiodataId = BiodataData.Data.BiodataId

        this.props.Update_Biodata_Extend(
            BiodataId,
            this.state.NamaLengkap,
            this.state.NamaPanggilan,
            this.state.TanggalMulaiBekerja,
            this.state.TempatLahir,
            this.state.TanggalLahir,
            this.state.Divisi,
            this.state.Sub,

            this.state.AlamatKTP,
            this.state.KelurahanKTP,
            this.state.KotaKTP,
            this.state.KodePosKTP,
            this.state.TlpnRumahKTP,

            this.state.Alamat,
            this.state.Kelurahan,
            this.state.Kota,
            this.state.KodePos,
            this.state.TlpnRumah,
            this.state.Hp,
            this.state.Kelamin,
            this.state.Agama,
            this.state.Perkawinan,
            this.state.Kewarganegaraan,
            this.state.KTP,
            this.state.KK,
            this.state.NPWP,
            this.state.BPJSKesehatan,
            this.state.BPJSKetenagakerjaan,
            this.state.BCA,

            this.state.NamaPasangan,
            this.state.TanggalLahirPasangan,
            this.state.PendidikanPasangan,
            this.state.PekerjaanPasangan,
            this.state.PerusahaanPasangan,

            this.state.DataAnak,

            this.state.NamaAyah,
            this.state.TanggalLahirAyah,
            this.state.NamaIbu,
            this.state.TanggalLahirIbu,
            this.state.AlamatOrangTua,
            this.state.KelurahanOrangTua,
            this.state.KotaOrangTua,
            this.state.KodePosOrangTua,
            this.state.NamaAyahMertua,
            this.state.NamaIbuMertua,
            this.state.AlamatMertua,
            this.state.KelurahanMertua,
            this.state.KotaMertua,
            this.state.KodePosMertua,

            this.state.NamaWaris,
            this.state.HubunganWaris,
            this.state.KontakWaris,

            this.state.NamaDarurat,
            this.state.NoDarurat,
            this.state.HubunganDarurat,

            this.state.DataPendidikanFormal,

            this.state.DataPendidikanInformal,

            this.state.DataPengalamanKerja,

            this.state.FileFoto,
            this.state.FileKTP,
            this.state.FileBPJSKesehatan,
            this.state.FileBPJSKetenagakerjaan,
            this.state.FileRekeningBCA,
            this.state.FileNPWP,
            this.state.FileKartuKeluarga,
            this.state.FileIjazah,
            this.state.FileSertifikasi,
        )
    }

    render() {
        // const BiodataData = this.props.Biodata.Data
        const isBiodataLoading = this.props.isBiodataLoading

        const OpJenisKelamin = [
            {
                Option: 'Male',
                value: true
            },
            {
                Option: 'Female',
                value: false
            },
        ]

        const OpSertifikat = [
            {
                Option: 'Ya',
                value: true
            },
            {
                Option: 'Tidak',
                value: false
            },
        ]

        const OpPendidikan = [
            {
                Option: 'SMA',
                value: 'SMA',
            },
            {
                Option: 'D3',
                value: 'D3',
            },
            {
                Option: 'D4',
                value: 'D4',
            },
            {
                Option: 'S1',
                value: 'S1'
            },
            {
                Option: 'S2',
                value: 'S2'
            },
            {
                Option: 'S3',
                value: 'S3'
            },
        ]

        const OpPendidikanInformal = [
            {
                Option: 'Seminar',
                value: 'Seminar',
            },
            {
                Option: 'Training',
                value: 'Training',
            },
            {
                Option: 'Diklat',
                value: 'Diklat',
            },
            {
                Option: 'Lainnya',
                value: 'Lainnya'
            }
        ]

        const OpAgama = ['Islam', 'Kristen Protestan', 'Kristen Katolik', 'Hindu', 'Buddha', 'Konghucu', 'Agama lainnya']
        const OpKawin = ['Belum Kawin', 'Kawin', 'Cerai Hidup', 'Cerai Mati']

        const {
            NamaLengkap,
            NamaPanggilan,
            TanggalMulaiBekerja,
            TempatLahir,
            TanggalLahir,
            Divisi,
            Sub,

            AlamatKTP,
            KelurahanKTP,
            KotaKTP,
            KodePosKTP,
            TlpnRumahKTP,

            Alamat,
            Kelurahan,
            Kota,
            KodePos,
            TlpnRumah,
            Hp,
            Kelamin,
            Agama,
            Perkawinan,
            Kewarganegaraan,
            KTP,
            KK,
            NPWP,
            BPJSKesehatan,
            BPJSKetenagakerjaan,
            BCA,

            NamaPasangan,
            TanggalLahirPasangan,
            PendidikanPasangan,
            PekerjaanPasangan,
            PerusahaanPasangan,

            DataAnak,

            NamaAyah,
            TanggalLahirAyah,
            NamaIbu,
            TanggalLahirIbu,
            AlamatOrangTua,
            KelurahanOrangTua,
            KotaOrangTua,
            KodePosOrangTua,
            NamaAyahMertua,
            NamaIbuMertua,
            AlamatMertua,
            KelurahanMertua,
            KotaMertua,
            KodePosMertua,

            NamaWaris,
            HubunganWaris,
            KontakWaris,

            NamaDarurat,
            NoDarurat,
            HubunganDarurat,

            DataPendidikanFormal,

            DataPendidikanInformal,

            DataPengalamanKerja,

            OldFileFoto,
            OldFileKTP,
            OldFileBPJSKesehatan,
            OldFileBPJSKetenagakerjaan,
            OldFileRekeningBCA,
            OldFileNPWP,
            OldFileKartuKeluarga,
            OldFileIjazah,
            OldFileSertifikasi,
        } = this.state

        const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }
        // const st_20 = { width: '20%', }
        // const st_70 = { width: '70%', }

        // const st_divaddtextfield = { display: 'flex', alignItems: 'center', margin: '0 0 2% 2%' }
        // const st_TextSmall = { width: '20%', margin: '0 1% 0 0' }
        const st_icon = { color: 'grey', fontSize: '180%', margin: '0 0 0 1%', cursor: 'pointer' }

        // const st_Half = { width: '45%' }

        return (
            <Fragment>
                {isBiodataLoading ?
                    <Fragment>
                        {
                            [1, 2, 3, 4, 5,].map((item) =>
                                <Skeleton
                                    key={`Biodata_Skeleton_${item}`}
                                    height={'5vh'}
                                />
                            )
                        }
                    </Fragment> :
                    <Fragment>
                        <form
                            onSubmit={this.formOnSubmit}
                        >

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Nama Lengkap'
                                name='NamaLengkap'
                                onChange={this.onChangeField}
                                value={NamaLengkap ? NamaLengkap : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Nama Panggilan'
                                name='NamaPanggilan'
                                onChange={this.onChangeField}
                                value={NamaPanggilan ? NamaPanggilan : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='date'
                                label='Tanggal Mulai Bekerja'
                                name='TanggalMulaiBekerja'
                                InputLabelProps={{ shrink: true }}
                                onChange={this.onChangeField}
                                value={TanggalMulaiBekerja ? TanggalMulaiBekerja : ''}
                            />

                            <TextField
                                style={{ ...st_textfield }}
                                variant='outlined'
                                type='text'
                                label='Tempat Lahir'
                                name='TempatLahir'
                                onChange={this.onChangeField}
                                value={TempatLahir ? TempatLahir : ''}
                            />
                            <TextField
                                style={{ ...st_textfield }}
                                variant='outlined'
                                type='date'
                                label='Tanggal Lahir'
                                name='TanggalLahir'
                                InputLabelProps={{ shrink: true }}
                                onChange={this.onChangeField}
                                value={TanggalLahir ? TanggalLahir : ''}
                            />


                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Divisi'
                                name='Divisi'
                                onChange={this.onChangeField}
                                value={Divisi ? Divisi : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Sub'
                                name='Sub'
                                onChange={this.onChangeField}
                                value={Sub ? Sub : ''}
                            />

                            <h2
                                style={{ margin: '5vh 0 0 0' }}
                            >
                                Data Diri
                            </h2>
                            <h3>
                                Alamat Sesuai KTP/Passport
                            </h3>
                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Nama Jalan, RT/RW, No. Rumah'
                                name='AlamatKTP'
                                onChange={this.onChangeField}
                                value={AlamatKTP ? AlamatKTP : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Kelurahan/Kecamatan'
                                name='KelurahanKTP'
                                onChange={this.onChangeField}
                                value={KelurahanKTP ? KelurahanKTP : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Kota'
                                name='KotaKTP'
                                onChange={this.onChangeField}
                                value={KotaKTP ? KotaKTP : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='number'
                                label='Kode Pos'
                                name='KodePosKTP'
                                onChange={this.onChangeField}
                                value={KodePosKTP ? KodePosKTP : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='number'
                                label='No Telpon Rumah'
                                name='TlpnRumahKTP'
                                onChange={this.onChangeField}
                                value={TlpnRumahKTP ? TlpnRumahKTP : ''}
                            />

                            <h3>
                                Alamat Tempat Tinggal
                            </h3>

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Nama Jalan, RT/RW, No. Rumah'
                                name='Alamat'
                                onChange={this.onChangeField}
                                value={Alamat ? Alamat : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Kelurahan/Kecamatan'
                                name='Kelurahan'
                                onChange={this.onChangeField}
                                value={Kelurahan ? Kelurahan : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Kota'
                                name='Kota'
                                onChange={this.onChangeField}
                                value={Kota ? Kota : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='number'
                                label='Kode Pos'
                                name='KodePos'
                                onChange={this.onChangeField}
                                value={KodePos ? KodePos : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='number'
                                label='No Telpon Rumah'
                                name='TlpnRumah'
                                onChange={this.onChangeField}
                                value={TlpnRumah ? TlpnRumah : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='number'
                                label='No Handphone'
                                name='Hp'
                                onChange={this.onChangeField}
                                value={Hp ? Hp : ''}
                            />

                            <FormControl
                                style={st_textfield}
                                variant="filled"
                                required
                            >
                                <InputLabel
                                    shrink
                                >
                                    Jenis Kelamin
                                </InputLabel>
                                <Select
                                    native
                                    style={{ backgroundColor: 'white' }}
                                    onChange={this.onChangeField}
                                    label="Jenis Kelamin"
                                    name='Kelamin'
                                    value={Kelamin}
                                >
                                    <option value="" disabled> -- select an option -- </option>
                                    {OpJenisKelamin.map((option, index) =>
                                        <option key={`OpJenisKelamin${index}`} value={option.value}>{option.Option}</option>
                                    )}
                                </Select>
                            </FormControl>

                            <FormControl
                                style={st_textfield}
                                variant="filled"
                            >
                                <InputLabel
                                    shrink
                                >
                                    Agama
                                </InputLabel>
                                <Select
                                    native
                                    style={{ backgroundColor: 'white' }}
                                    onChange={this.onChangeField}
                                    label="Agama"
                                    name='Agama'
                                    value={Agama ? Agama : ''}
                                >
                                    <option value="" disabled> -- select an option -- </option>
                                    {OpAgama.map((option, index) =>
                                        <option key={`OpAgama${index}`} value={option}>{option}</option>
                                    )}
                                </Select>
                            </FormControl>

                            <FormControl
                                style={st_textfield}
                                variant="filled"
                            >
                                <InputLabel
                                    shrink
                                >
                                    Status Perkawinan
                                </InputLabel>
                                <Select
                                    native
                                    style={{ backgroundColor: 'white' }}
                                    onChange={this.onChangeField}
                                    label="Perkawinan"
                                    name='Perkawinan'
                                    value={Perkawinan ? Perkawinan : ''}
                                >
                                    <option value="" disabled> -- select an option -- </option>
                                    {OpKawin.map((option, index) =>
                                        <option key={`OpKawin${index}`} value={option}>{option}</option>
                                    )}
                                </Select>
                            </FormControl>

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Kewarganegaraan'
                                name='Kewarganegaraan'
                                onChange={this.onChangeField}
                                value={Kewarganegaraan ? Kewarganegaraan : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='number'
                                label='No KTP'
                                name='KTP'
                                onChange={this.onChangeField}
                                value={KTP ? KTP : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='number'
                                label='No Kartu Keluarga'
                                name='KK'
                                onChange={this.onChangeField}
                                value={KK ? KK : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='number'
                                label='No NPWP'
                                name='NPWP'
                                onChange={this.onChangeField}
                                value={NPWP ? NPWP : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='number'
                                label='No BPJS Kesehatan'
                                name='BPJSKesehatan'
                                onChange={this.onChangeField}
                                value={BPJSKesehatan ? BPJSKesehatan : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='number'
                                label='No BPJS Ketenagakerjaan'
                                name='BPJSKetenagakerjaan'
                                onChange={this.onChangeField}
                                value={BPJSKetenagakerjaan ? BPJSKetenagakerjaan : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='number'
                                label='No Rekening BCA'
                                name='BCA'
                                onChange={this.onChangeField}
                                value={BCA ? BCA : ''}
                            />



                            <h3>
                                Data Suami/Istri
                            </h3>

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Nama Suami/Istri'
                                name='NamaPasangan'
                                onChange={this.onChangeField}
                                value={NamaPasangan ? NamaPasangan : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='date'
                                InputLabelProps={{ shrink: true }}
                                label='Tanggal Lahir Suami/Istri'
                                name='TanggalLahirPasangan'
                                onChange={this.onChangeField}
                                value={TanggalLahirPasangan ? TanggalLahirPasangan : ''}
                            />

                            <FormControl
                                style={st_textfield}
                                variant="filled"
                            >
                                <InputLabel
                                    shrink
                                >
                                    Pendidikan Terakhir Suami/Istri
                                </InputLabel>
                                <Select
                                    native
                                    style={{ backgroundColor: 'white' }}
                                    onChange={this.onChangeField}
                                    label="Pendidikan Terakhir Suami/Istri"
                                    name='PendidikanPasangan'
                                    value={PendidikanPasangan ? PendidikanPasangan : ''}
                                >
                                    <option value="" disabled> -- select an option -- </option>
                                    {OpPendidikan.map((option, index) =>
                                        <option key={`OpPendidikanPasangan${index}`} value={option.value}>{option.Option}</option>
                                    )}
                                </Select>
                            </FormControl>

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Pekerjaan Suami/Istri'
                                name='PekerjaanPasangan'
                                onChange={this.onChangeField}
                                value={PekerjaanPasangan ? PekerjaanPasangan : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Nama Perusahaan Suami/Istri'
                                name='PerusahaanPasangan'
                                onChange={this.onChangeField}
                                value={PerusahaanPasangan ? PerusahaanPasangan : ''}
                            />



                            <h3>
                                Data Anak
                            </h3>
                            {DataAnak.length === 0 ?
                                <Fragment>
                                    <AddCircleRoundedIcon
                                        onClick={this.AddDetailList}
                                        style={st_icon}
                                    />
                                </Fragment> :
                                <Fragment></Fragment>}


                            {DataAnak.map((item, index) =>
                                <div
                                    key={`DataAnak_${index}`}
                                >
                                    <label
                                        style={{ marginRight: '1%' }}
                                    >
                                        <b>
                                            {index + 1}
                                        </b>
                                    </label>
                                    <div
                                        style={{ margin: '0 0 0 5%' }}
                                    >
                                        <TextField
                                            style={st_textfield}
                                            variant='outlined'
                                            type='text'
                                            label='Nama'
                                            name='Nama'
                                            required
                                            value={DataAnak[index].Nama}
                                            onChange={(e) => this.OnChangeMapItem(e, index, 1)}
                                        />

                                        <FormControl
                                            style={st_textfield}
                                            variant="filled"
                                            required
                                        >
                                            <InputLabel
                                                shrink
                                            >
                                                Jenis Kelamin
                                            </InputLabel>
                                            <Select
                                                native
                                                style={{ backgroundColor: 'white' }}
                                                onChange={(e) => this.OnChangeMapItem(e, index, 2)}
                                                name='JenisKelamin'
                                                value={DataAnak[index].Kelamin}
                                            >
                                                <option value="" disabled> -- select an option -- </option>
                                                {OpJenisKelamin.map((option, index) =>
                                                    <option key={`OpJenisKelamin__${index}`} value={option.value}>{option.Option}</option>
                                                )}
                                            </Select>
                                        </FormControl>

                                        <TextField
                                            style={st_textfield}
                                            variant='outlined'
                                            type='date'
                                            InputLabelProps={{ shrink: true }}
                                            label='TanggalLahir'
                                            name='TanggalLahir'
                                            required
                                            value={DataAnak[index].TanggalLahir}
                                            onChange={(e) => this.OnChangeMapItem(e, index, 3)}
                                        />
                                    </div>

                                    <DeleteOutlineRoundedIcon
                                        onClick={(e) => this.DeleteDetailList(index)}
                                        style={st_icon}
                                    />

                                    <AddCircleRoundedIcon
                                        onClick={this.AddDetailList}
                                        style={st_icon}
                                    />
                                </div>)}



                            <h3>
                                Data Keluarga
                            </h3>

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Nama Ayah Kandung'
                                name='NamaAyah'
                                onChange={this.onChangeField}
                                value={NamaAyah ? NamaAyah : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='date'
                                InputLabelProps={{ shrink: true }}
                                label='Tanggal Lahir Ayah Kandung'
                                name='TanggalLahirAyah'
                                onChange={this.onChangeField}
                                value={TanggalLahirAyah ? TanggalLahirAyah : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Nama Ibu Kandung'
                                name='NamaIbu'
                                onChange={this.onChangeField}
                                value={NamaIbu ? NamaIbu : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='date'
                                InputLabelProps={{ shrink: true }}
                                label='Tanggal Lahir Ibu Kandung'
                                name='TanggalLahirIbu'
                                onChange={this.onChangeField}
                                value={TanggalLahirIbu ? TanggalLahirIbu : ''}
                            />

                            <h3>
                                Alamat Orang Tua Kandung
                            </h3>

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Nama Jalan, RT/RW, No.Rumah'
                                name='AlamatOrangTua'
                                onChange={this.onChangeField}
                                value={AlamatOrangTua ? AlamatOrangTua : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Kelurahan/Kecamatan'
                                name='KelurahanOrangTua'
                                onChange={this.onChangeField}
                                value={KelurahanOrangTua ? KelurahanOrangTua : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Kota'
                                name='KotaOrangTua'
                                onChange={this.onChangeField}
                                value={KotaOrangTua ? KotaOrangTua : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='number'
                                label='Kode Pos'
                                name='KodePosOrangTua'
                                onChange={this.onChangeField}
                                value={KodePosOrangTua ? KodePosOrangTua : ''}
                            />

                            <h3>
                                Data Mertua
                            </h3>

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Nama Ayah Mertua'
                                name='NamaAyahMertua'
                                onChange={this.onChangeField}
                                value={NamaAyahMertua ? NamaAyahMertua : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Nama Ibu Mertua'
                                name='NamaIbuMertua'
                                onChange={this.onChangeField}
                                value={NamaIbuMertua ? NamaIbuMertua : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Nama Jalan, RT/RW, No.Rumah'
                                name='AlamatMertua'
                                onChange={this.onChangeField}
                                value={AlamatMertua ? AlamatMertua : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Kelurahan/Kecamatan'
                                name='KelurahanMertua'
                                onChange={this.onChangeField}
                                value={KelurahanMertua ? KelurahanMertua : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Kota'
                                name='KotaMertua'
                                onChange={this.onChangeField}
                                value={KotaMertua ? KotaMertua : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='number'
                                label='Kode Pos'
                                name='KodePosMertua'
                                onChange={this.onChangeField}
                                value={KodePosMertua ? KodePosMertua : ''}
                            />

                            <h3>
                                Data Ahli Waris
                            </h3>

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Nama Ahli Waris'
                                name='NamaWaris'
                                onChange={this.onChangeField}
                                value={NamaWaris ? NamaWaris : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Hubungan Dengan Karyawan'
                                name='HubunganWaris'
                                onChange={this.onChangeField}
                                value={HubunganWaris ? HubunganWaris : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Kontak Ahli Waris'
                                name='KontakWaris'
                                onChange={this.onChangeField}
                                value={KontakWaris ? KontakWaris : ''}
                            />



                            <h3>
                                Kontak Keadaan Darurat
                            </h3>

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Nama'
                                name='NamaDarurat'
                                onChange={this.onChangeField}
                                value={NamaDarurat ? NamaDarurat : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='number'
                                label='No. Telpon'
                                name='NoDarurat'
                                onChange={this.onChangeField}
                                value={NoDarurat ? NoDarurat : ''}
                            />

                            <TextField
                                style={st_textfield}
                                variant='outlined'
                                type='text'
                                label='Hubungan Dengan Karyawan'
                                name='HubunganDarurat'
                                onChange={this.onChangeField}
                                value={HubunganDarurat ? HubunganDarurat : ''}
                            />



                            <h3>
                                Riwayat Pendidikan Formal
                            </h3>
                            {DataPendidikanFormal.length === 0 ?
                                <Fragment>
                                    <AddCircleRoundedIcon
                                        onClick={this.AddDetailListFormal}
                                        style={st_icon}
                                    />
                                </Fragment> :
                                <Fragment></Fragment>}


                            {DataPendidikanFormal.map((item, index) =>
                                <div
                                    key={`DataPendidikanFormal_${index}`}
                                >
                                    <label
                                        style={{ marginRight: '1%' }}
                                    >
                                        <b>
                                            {index + 1}
                                        </b>
                                    </label>
                                    <div
                                        style={{ margin: '0 0 0 5%' }}
                                    >
                                        <FormControl
                                            style={st_textfield}
                                            variant="filled"
                                            required
                                        >
                                            <InputLabel
                                                shrink
                                            >
                                                Jenis
                                            </InputLabel>
                                            <Select
                                                native
                                                style={{ backgroundColor: 'white' }}
                                                onChange={(e) => this.OnChangeMapItemFormal(e, index, 1)}
                                                name='Jenis'
                                                value={DataPendidikanFormal[index].Jenis}
                                            >
                                                <option value="" disabled> -- select an option -- </option>
                                                {OpPendidikan.map((option, index) =>
                                                    <option key={`OpPendidikan__${index}`} value={option.value}>{option.Option}</option>
                                                )}
                                            </Select>
                                        </FormControl>

                                        <TextField
                                            style={st_textfield}
                                            variant='outlined'
                                            type='text'
                                            label='Nama Instansi'
                                            name='InstansiNama'
                                            required
                                            value={DataPendidikanFormal[index].InstansiNama}
                                            onChange={(e) => this.OnChangeMapItemFormal(e, index, 2)}
                                        />

                                        <TextField
                                            style={st_textfield}
                                            variant='outlined'
                                            type='number'
                                            label='TahunMulai'
                                            name='TahunMulai'
                                            required
                                            value={DataPendidikanFormal[index].TahunMulai}
                                            onChange={(e) => this.OnChangeMapItemFormal(e, index, 3)}
                                        />

                                        <TextField
                                            style={st_textfield}
                                            variant='outlined'
                                            type='number'
                                            label='TahunSelesai'
                                            name='TahunSelesai'
                                            required
                                            value={DataPendidikanFormal[index].TahunSelesai}
                                            onChange={(e) => this.OnChangeMapItemFormal(e, index, 4)}
                                        />

                                        <TextField
                                            style={st_textfield}
                                            variant='outlined'
                                            type='text'
                                            label='Jurusan'
                                            name='Jurusan'
                                            required
                                            value={DataPendidikanFormal[index].Jurusan}
                                            onChange={(e) => this.OnChangeMapItemFormal(e, index, 5)}
                                        />

                                        <TextField
                                            style={st_textfield}
                                            variant='outlined'
                                            type='text'
                                            label='Kota'
                                            name='Kota'
                                            required
                                            value={DataPendidikanFormal[index].Kota}
                                            onChange={(e) => this.OnChangeMapItemFormal(e, index, 6)}
                                        />

                                        <FormControl
                                            style={st_textfield}
                                            variant="filled"
                                            required
                                        >
                                            <InputLabel
                                                shrink
                                            >
                                                Sertifikat
                                            </InputLabel>
                                            <Select
                                                native
                                                style={{ backgroundColor: 'white' }}
                                                onChange={(e) => this.OnChangeMapItemFormal(e, index, 7)}
                                                name='Sertifikat'
                                                value={DataPendidikanFormal[index].Sertifikat}
                                            >
                                                <option value="" disabled> -- select an option -- </option>
                                                {OpSertifikat.map((option, index) =>
                                                    <option key={`OpSertifikat__${index}`} value={option.value}>{option.Option}</option>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <DeleteOutlineRoundedIcon
                                        onClick={(e) => this.DeleteDetailListFormal(index)}
                                        style={st_icon}
                                    />

                                    <AddCircleRoundedIcon
                                        onClick={this.AddDetailListFormal}
                                        style={st_icon}
                                    />
                                </div>)}


                            <h3>
                                Riwayat Pendidikan Informal
                            </h3>
                            {DataPendidikanInformal.length === 0 ?
                                <Fragment>
                                    <AddCircleRoundedIcon
                                        onClick={this.AddDetailListInformal}
                                        style={st_icon}
                                    />
                                </Fragment> :
                                <Fragment></Fragment>}


                            {DataPendidikanInformal.map((item, index) =>
                                <div
                                    key={`DataPendidikanInformal_${index}`}
                                >
                                    <label
                                        style={{ marginRight: '1%' }}
                                    >
                                        <b>
                                            {index + 1}
                                        </b>
                                    </label>
                                    <div
                                        style={{ margin: '0 0 0 5%' }}
                                    >
                                        <FormControl
                                            style={st_textfield}
                                            variant="filled"
                                            required
                                        >
                                            <InputLabel
                                                shrink
                                            >
                                                Jenis
                                            </InputLabel>
                                            <Select
                                                native
                                                style={{ backgroundColor: 'white' }}
                                                onChange={(e) => this.OnChangeMapItemInformal(e, index, 1)}
                                                name='Jenis'
                                                value={DataPendidikanInformal[index].Jenis}
                                            >
                                                <option value="" disabled> -- select an option -- </option>
                                                {OpPendidikanInformal.map((option, index) =>
                                                    <option key={`OpPendidikanInformal${index}`} value={option.value}>{option.Option}</option>
                                                )}
                                            </Select>
                                        </FormControl>

                                        <TextField
                                            style={st_textfield}
                                            variant='outlined'
                                            type='number'
                                            label='TahunSelesai'
                                            name='TahunSelesai'
                                            required
                                            value={DataPendidikanInformal[index].TahunSelesai}
                                            onChange={(e) => this.OnChangeMapItemInformal(e, index, 2)}
                                        />

                                        <TextField
                                            style={st_textfield}
                                            variant='outlined'
                                            type='text'
                                            label='Durasi'
                                            name='Durasi'
                                            required
                                            value={DataPendidikanInformal[index].Durasi}
                                            onChange={(e) => this.OnChangeMapItemInformal(e, index, 3)}
                                        />

                                        <TextField
                                            style={st_textfield}
                                            variant='outlined'
                                            type='text'
                                            label='Nama'
                                            name='Nama'
                                            required
                                            value={DataPendidikanInformal[index].Nama}
                                            onChange={(e) => this.OnChangeMapItemInformal(e, index, 4)}
                                        />

                                        <TextField
                                            style={st_textfield}
                                            variant='outlined'
                                            type='text'
                                            label='Bidang'
                                            name='Bidang'
                                            required
                                            value={DataPendidikanInformal[index].Bidang}
                                            onChange={(e) => this.OnChangeMapItemInformal(e, index, 5)}
                                        />

                                        <TextField
                                            style={st_textfield}
                                            variant='outlined'
                                            type='text'
                                            label='Kota'
                                            name='Kota'
                                            required
                                            value={DataPendidikanInformal[index].Kota}
                                            onChange={(e) => this.OnChangeMapItemInformal(e, index, 6)}
                                        />

                                        <FormControl
                                            style={st_textfield}
                                            variant="filled"
                                            required
                                        >
                                            <InputLabel
                                                shrink
                                            >
                                                Sertifikat
                                            </InputLabel>
                                            <Select
                                                native
                                                style={{ backgroundColor: 'white' }}
                                                onChange={(e) => this.OnChangeMapItemInformal(e, index, 7)}
                                                name='Sertifikat'
                                                value={DataPendidikanInformal[index].Sertifikat}
                                            >
                                                <option value="" disabled> -- select an option -- </option>
                                                {OpSertifikat.map((option, index) =>
                                                    <option key={`OpSertifikat__${index}`} value={option.value}>{option.Option}</option>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <DeleteOutlineRoundedIcon
                                        onClick={(e) => this.DeleteDetailListInformal(index)}
                                        style={st_icon}
                                    />

                                    <AddCircleRoundedIcon
                                        onClick={this.AddDetailListInformal}
                                        style={st_icon}
                                    />
                                </div>)}


                            <h3>
                                Pengalaman Kerja
                            </h3>
                            {DataPengalamanKerja.length === 0 ?
                                <Fragment>
                                    <AddCircleRoundedIcon
                                        onClick={this.AddDetailListPengalamanKerja}
                                        style={st_icon}
                                    />
                                </Fragment> :
                                <Fragment></Fragment>}


                            {DataPengalamanKerja.map((item, index) =>
                                <div
                                    key={`DataPengalamanKerja_${index}`}
                                >
                                    <label
                                        style={{ marginRight: '1%' }}
                                    >
                                        <b>
                                            {index + 1}
                                        </b>
                                    </label>

                                    <div
                                        style={{ margin: '0 0 0 5%' }}
                                    >
                                        <TextField
                                            style={st_textfield}
                                            variant='outlined'
                                            type='text'
                                            label='Nama Perusaaahn'
                                            name='NamaPerusaaahn'
                                            required
                                            value={DataPengalamanKerja[index].NamaPerusaaahn}
                                            onChange={(e) => this.OnChangeMapItemPengalamanKerja(e, index, 1)}
                                        />

                                        <TextField
                                            style={st_textfield}
                                            variant='outlined'
                                            type='number'
                                            label='Tahun Mulai'
                                            name='TahunMulai'
                                            required
                                            value={DataPengalamanKerja[index].TahunMulai}
                                            onChange={(e) => this.OnChangeMapItemPengalamanKerja(e, index, 2)}
                                        />

                                        <TextField
                                            style={st_textfield}
                                            variant='outlined'
                                            type='text'
                                            label='Tahun Selesai'
                                            name='TahunSelesai'
                                            required
                                            value={DataPengalamanKerja[index].TahunSelesai}
                                            onChange={(e) => this.OnChangeMapItemPengalamanKerja(e, index, 3)}
                                        />

                                        <TextField
                                            style={st_textfield}
                                            variant='outlined'
                                            type='text'
                                            label='Posisi'
                                            name='Posisi'
                                            required
                                            value={DataPengalamanKerja[index].Posisi}
                                            onChange={(e) => this.OnChangeMapItemPengalamanKerja(e, index, 4)}
                                        />

                                        <TextField
                                            style={st_textfield}
                                            variant='outlined'
                                            type='text'
                                            label='BidangPekerjaan'
                                            name='BidangPekerjaan'
                                            required
                                            value={DataPengalamanKerja[index].BidangPekerjaan}
                                            onChange={(e) => this.OnChangeMapItemPengalamanKerja(e, index, 5)}
                                        />

                                        <TextField
                                            style={st_textfield}
                                            variant='outlined'
                                            type='text'
                                            label='BidangUsaha'
                                            name='BidangUsaha'
                                            required
                                            value={DataPengalamanKerja[index].BidangUsaha}
                                            onChange={(e) => this.OnChangeMapItemPengalamanKerja(e, index, 6)}
                                        />
                                    </div>

                                    <DeleteOutlineRoundedIcon
                                        onClick={(e) => this.DeleteDetailListPengalamanKerja(index)}
                                        style={st_icon}
                                    />

                                    <AddCircleRoundedIcon
                                        onClick={this.AddDetailListPengalamanKerja}
                                        style={st_icon}
                                    />
                                </div>)}



                            <PhotoGuidance />



                            <FormControl
                                style={{ ...st_textfield }}
                                variant="filled"
                            >

                                <label>
                                    Foto (Max 500kb, .jpeg) {OldFileFoto ?
                                        <span
                                            style={{ color: 'blue', cursor: 'pointer', verticalAlign: 'super' }}
                                            onClick={() => window.open(
                                                `${ImageURL.Base}/${OldFileFoto}`,
                                                '_blank'
                                            )}
                                        >
                                            preview
                                        </span> : <span style={{ color: 'red' }} >*</span>}
                                </label>
                                {/* <input type='file' accept='image/jpeg,' onChange={this.File_OnChange} name='FileFoto' required={OldFileFoto ? false : true} /> */}
                                <TextField
                                    label="Foto (Max 500kb, .jpeg) "
                                    type="file"
                                    name='FileFoto'
                                    style={st_textfield}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        accept: 'image/jpeg'
                                    }}
                                    variant="outlined"
                                    onChange={this.File_OnChange}
                                    required={OldFileFoto ? false : true}
                                />
                            </FormControl>

                            <FormControl
                                style={{ ...st_textfield }}
                                variant="filled"
                            >
                                <label>
                                    KTP (Max 500kb, .pdf) {OldFileKTP ?
                                        <span
                                            style={{ color: 'blue', cursor: 'pointer', verticalAlign: 'super' }}
                                            onClick={() => window.open(
                                                `${ImageURL.Base}/${OldFileKTP}`,
                                                '_blank'
                                            )}
                                        >
                                            preview
                                        </span> : <span style={{ color: 'red' }} >*</span>}
                                </label>
                                {/* <input type='file' accept='application/pdf,' onChange={this.File_OnChange} name='FileKTP' required={OldFileKTP ? false : true} /> */}
                                <TextField
                                    label="KTP (Max 500kb, .pdf)"
                                    type="file"
                                    name='FileKTP'
                                    style={st_textfield}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        accept: 'application/pdf'
                                    }}
                                    variant="outlined"
                                    onChange={this.File_OnChange}
                                    required={OldFileKTP ? false : true}
                                />
                            </FormControl>

                            <FormControl
                                style={{ ...st_textfield }}
                                variant="filled"
                            >
                                <label>
                                    BPJS Kesehatan (Max 500kb, .pdf) {OldFileBPJSKesehatan ?
                                        <span
                                            style={{ color: 'blue', cursor: 'pointer', verticalAlign: 'super' }}
                                            onClick={() => window.open(
                                                `${ImageURL.Base}/${OldFileBPJSKesehatan}`,
                                                '_blank'
                                            )}
                                        >
                                            preview
                                        </span> : <span style={{ color: 'red' }} ></span>}
                                </label>
                                {/* <input type='file' accept='application/pdf,' onChange={this.File_OnChange} name='FileBPJSKesehatan' /> */}
                                <TextField
                                    label="BPJS Kesehatan (Max 500kb, .pdf)"
                                    type="file"
                                    name='FileBPJSKesehatan'
                                    style={st_textfield}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        accept: 'application/pdf'
                                    }}
                                    variant="outlined"
                                    onChange={this.File_OnChange}
                                />
                            </FormControl>

                            <FormControl
                                style={{ ...st_textfield }}
                                variant="filled"
                            >
                                <label>
                                    BPJS Ketenagakerjaan (Max 500kb, .pdf){OldFileBPJSKetenagakerjaan ?
                                        <span
                                            style={{ color: 'blue', cursor: 'pointer', verticalAlign: 'super' }}
                                            onClick={() => window.open(
                                                `${ImageURL.Base}/${OldFileBPJSKetenagakerjaan}`,
                                                '_blank'
                                            )}
                                        >
                                            preview
                                        </span> : <span style={{ color: 'red' }} ></span>}
                                </label>
                                {/* <input type='file' accept='application/pdf,' onChange={this.File_OnChange} name='FileBPJSKetenagakerjaan' /> */}
                                <TextField
                                    label="BPJS Ketenagakerjaan (Max 500kb, .pdf)"
                                    type="file"
                                    name='FileBPJSKetenagakerjaan'
                                    style={st_textfield}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        accept: 'application/pdf'
                                    }}
                                    variant="outlined"
                                    onChange={this.File_OnChange}
                                />
                            </FormControl>

                            <FormControl
                                style={{ ...st_textfield }}
                                variant="filled"
                            >
                                <label>
                                    Rekening BCA (Max 500kb, .pdf){OldFileRekeningBCA ?
                                        <span
                                            style={{ color: 'blue', cursor: 'pointer', verticalAlign: 'super' }}
                                            onClick={() => window.open(
                                                `${ImageURL.Base}/${OldFileRekeningBCA}`,
                                                '_blank'
                                            )}
                                        >
                                            preview
                                        </span> : <span style={{ color: 'red' }} ></span>}
                                </label>
                                {/* <input type='file' accept='application/pdf,' onChange={this.File_OnChange} name='FileRekeningBCA' /> */}
                                <TextField
                                    label="Rekening BCA (Max 500kb, .pdf)"
                                    type="file"
                                    name='FileRekeningBCA'
                                    style={st_textfield}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        accept: 'application/pdf'
                                    }}
                                    variant="outlined"
                                    onChange={this.File_OnChange}
                                />
                            </FormControl>

                            <FormControl
                                style={{ ...st_textfield }}
                                variant="filled"
                            >
                                <label>
                                    NPWP (Max 500kb, .pdf){OldFileNPWP ?
                                        <span
                                            style={{ color: 'blue', cursor: 'pointer', verticalAlign: 'super' }}
                                            onClick={() => window.open(
                                                `${ImageURL.Base}/${OldFileNPWP}`,
                                                '_blank'
                                            )}
                                        >
                                            preview
                                        </span> : <span style={{ color: 'red' }} ></span>}
                                </label>
                                {/* <input type='file' accept='application/pdf,' onChange={this.File_OnChange} name='FileNPWP' /> */}
                                <TextField
                                    label="NPWP (Max 500kb, .pdf)"
                                    type="file"
                                    name='FileNPWP'
                                    style={st_textfield}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        accept: 'application/pdf'
                                    }}
                                    variant="outlined"
                                    onChange={this.File_OnChange}
                                />
                            </FormControl>

                            <FormControl
                                style={{ ...st_textfield }}
                                variant="filled"
                            >
                                <label>
                                    Kartu Keluarga (Max 500kb, .pdf) {OldFileKartuKeluarga ?
                                        <span
                                            style={{ color: 'blue', cursor: 'pointer', verticalAlign: 'super' }}
                                            onClick={() => window.open(
                                                `${ImageURL.Base}/${OldFileKartuKeluarga}`,
                                                '_blank'
                                            )}
                                        >
                                            preview
                                        </span> : <span style={{ color: 'red' }} >*</span>}
                                </label>
                                {/* <input type='file' accept='application/pdf,' onChange={this.File_OnChange} name='FileKartuKeluarga' required={OldFileKartuKeluarga ? false : true} /> */}
                                <TextField
                                    label="Kartu Keluarga (Max 500kb, .pdf)"
                                    type="file"
                                    name='FileKartuKeluarga'
                                    style={st_textfield}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        accept: 'application/pdf'
                                    }}
                                    variant="outlined"
                                    onChange={this.File_OnChange}
                                    required={OldFileKartuKeluarga ? false : true}
                                />
                            </FormControl>

                            <FormControl
                                style={{ ...st_textfield }}
                                variant="filled"
                            >
                                <label>
                                    Ijazah (Max 500kb, .pdf)  {OldFileIjazah ?
                                        <span
                                            style={{ color: 'blue', cursor: 'pointer', verticalAlign: 'super' }}
                                            onClick={() => window.open(
                                                `${ImageURL.Base}/${OldFileIjazah}`,
                                                '_blank'
                                            )}
                                        >
                                            preview
                                        </span> : <span style={{ color: 'red' }} >*</span>}
                                </label>
                                {/* <input type='file' accept='application/pdf,' onChange={this.File_OnChange} name='FileIjazah' required={OldFileIjazah ? false : true} /> */}
                                <TextField
                                    label="Ijazah (Max 500kb, .pdf)"
                                    type="file"
                                    name='FileIjazah'
                                    style={st_textfield}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        accept: 'application/pdf'
                                    }}
                                    variant="outlined"
                                    onChange={this.File_OnChange}
                                    required={OldFileIjazah ? false : true}
                                />
                            </FormControl>

                            <FormControl
                                style={{ ...st_textfield }}
                                variant="filled"
                            >
                                <label>
                                    Sertifikat (Max 500kb, .pdf){OldFileSertifikasi ?
                                        <span
                                            style={{ color: 'blue', cursor: 'pointer', verticalAlign: 'super' }}
                                            onClick={() => window.open(
                                                `${ImageURL.Base}/${OldFileSertifikasi}`,
                                                '_blank'
                                            )}
                                        >
                                            preview
                                        </span> : <span style={{ color: 'red' }} ></span>}
                                </label>
                                {/* <input type='file' accept='application/pdf,' onChange={this.File_OnChange} name='FileSertifikasi' /> */}
                                <TextField
                                    label="Sertifikat (Max 500kb, .pdf)"
                                    type="file"
                                    name='FileSertifikasi'
                                    style={st_textfield}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        accept: 'application/pdf'
                                    }}
                                    variant="outlined"
                                    onChange={this.File_OnChange}
                                />
                            </FormControl>

                            <center>
                                <Button
                                    variant='contained'
                                    style={{ width: '60%', margin: '2% 0 5% 0', border: '0px solid white', textTransform: 'none', color: 'white', backgroundColor: '#00a3df', boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px', fontFamily: 'Montserrat, Segoe UI' }}
                                    type='submit'
                                >
                                    Save
                                </Button>
                            </center>
                        </form>
                    </Fragment>}
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    AccountActionId: state.Account.AccountActionId,
    isBiodataLoading: state.Account.isBiodataLoading,
    Biodata: state.Account.Biodata,
})

export default connect(mapStateToProps, { Update_Biodata_Extend, Create_Warning_Messages })(Biodata)