//* AUTH
// export const AUTH_AUTH_LOADING = 'AUTH_AUTH_LOADING'
// export const AUTH_AUTH_LOADED = 'AUTH_AUTH_LOADED'
export const AUTH_RELOADE_PAGE = "AUTH_RELOADE_PAGE";
export const AUTH_LOGIN_LOADING = "AUTH_LOGIN_LOADING";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_LOADED = "AUTH_LOGIN_LOADED";
export const AUTH_USER_LOADING = "AUTH_USER_LOADING";
export const AUTH_USER_LOADED = "AUTH_USER_LOADED";
export const AUTH_USER_LOADING_DONE = "AUTH_USER_LOADING_DONE";
export const AUTH_USER_EXPIRED = "AUTH_USER_EXPIRED";
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
//* END-AUTH
//* ACCOUNT
export const ACCOUNT_ACTION_GET_ID = "ACCOUNT_ACTION_GET_ID";
//* ACCOUNT-BIODATA
export const ACCOUNT_BIODATA_LOADING = "ACCOUNT_BIODATA_LOADING";
export const ACCOUNT_BIODATA_LOADED = "ACCOUNT_BIODATA_LOADED";
export const ACCOUNT_BIODATA_LOADING_DONE = "ACCOUNT_BIODATA_LOADING_DONE";
//* ACCOUNT-ACCOUNT
export const ACCOUNT_ACCOUNT_LOADING = "ACCOUNT_ACCOUNT_LOADING";
export const ACCOUNT_ACCOUNT_LOADED = "ACCOUNT_ACCOUNT_LOADED";
export const ACCOUNT_ACCOUNT_LOADING_DONE = "ACCOUNT_ACCOUNT_LOADING_DONE";
//* ACCOUNT-PENDIDIKAN
export const ACCOUNT_PENDIDIKAN_FORMAL_LOADING =
  "ACCOUNT_PENDIDIKAN_FORMAL_LOADING";
export const ACCOUNT_PENDIDIKAN_FORMAL_LOADED =
  "ACCOUNT_PENDIDIKAN_FORMAL_LOADED";
export const ACCOUNT_PENDIDIKAN_FORMAL_LOADING_DONE =
  "ACCOUNT_PENDIDIKAN_FORMAL_LOADING_DONE";
export const ACCOUNT_PENDIDIKAN_UNFORMAL_LOADING =
  "ACCOUNT_PENDIDIKAN_UNFORMAL_LOADING";
export const ACCOUNT_PENDIDIKAN_UNFORMAL_LOADED =
  "ACCOUNT_PENDIDIKAN_UNFORMAL_LOADED";
export const ACCOUNT_PENDIDIKAN_UNFORMAL_LOADING_DONE =
  "ACCOUNT_PENDIDIKAN_UNFORMAL_LOADING_DONE";
//* ACCOUNT-KETERAMPILAN
export const ACCOUNT_KETERAMPILAN_BAHASA_LOADING =
  "ACCOUNT_KETERAMPILAN_BAHASA_LOADING";
export const ACCOUNT_KETERAMPILAN_BAHASA_LOADED =
  "ACCOUNT_KETERAMPILAN_BAHASA_LOADED";
export const ACCOUNT_KETERAMPILAN_BAHASA_LOADING_DONE =
  "ACCOUNT_KETERAMPILAN_BAHASA_LOADING_DONE";
export const ACCOUNT_KETERAMPILAN_KOMPUTER_LOADING =
  "ACCOUNT_KETERAMPILAN_KOMPUTER_LOADING";
export const ACCOUNT_KETERAMPILAN_KOMPUTER_LOADED =
  "ACCOUNT_KETERAMPILAN_KOMPUTER_LOADED";
export const ACCOUNT_KETERAMPILAN_KOMPUTER_LOADING_DONE =
  "ACCOUNT_KETERAMPILAN_KOMPUTER_LOADING_DONE";
export const ACCOUNT_KETERAMPILAN_RELEVANT_LOADING =
  "ACCOUNT_KETERAMPILAN_RELEVANT_LOADING";
export const ACCOUNT_KETERAMPILAN_RELEVANT_LOADED =
  "ACCOUNT_KETERAMPILAN_RELEVANT_LOADED";
export const ACCOUNT_KETERAMPILAN_RELEVANT_LOADING_DONE =
  "ACCOUNT_KETERAMPILAN_RELEVANT_LOADING_DONE";
//* ACCOUNT-KELUARGA
export const ACCOUNT_KELUARGA_LOADING = "ACCOUNT_KELUARGA_LOADING";
export const ACCOUNT_KELUARGA_LOADED = "ACCOUNT_KELUARGA_LOADED";
export const ACCOUNT_KELUARGA_LOADING_DONE = "ACCOUNT_KELUARGA_LOADING_DONE";
//* ACCOUNT-KERJA
export const ACCOUNT_KERJA_RIWAYAT_LOADING = "ACCOUNT_KERJA_RIWAYAT_LOADING";
export const ACCOUNT_KERJA_RIWAYAT_LOADED = "ACCOUNT_KERJA_RIWAYAT_LOADED";
export const ACCOUNT_KERJA_RIWAYAT_LOADING_DONE =
  "ACCOUNT_KERJA_RIWAYAT_LOADING_DONE";
export const ACCOUNT_KERJA_REFERENSI_LOADING =
  "ACCOUNT_KERJA_REFERENSI_LOADING";
export const ACCOUNT_KERJA_REFERENSI_LOADED = "ACCOUNT_KERJA_REFERENSI_LOADED";
export const ACCOUNT_KERJA_REFERENSI_LOADING_DONE =
  "ACCOUNT_KERJA_REFERENSI_LOADING_DONE";
export const ACCOUNT_KERJA_KERJA_LOADING = "ACCOUNT_KERJA_KERJA_LOADING";
export const ACCOUNT_KERJA_KERJA_LOADED = "ACCOUNT_KERJA_KERJA_LOADED";
export const ACCOUNT_KERJA_KERJA_LOADING_DONE =
  "ACCOUNT_KERJA_KERJA_LOADING_DONE";
//* ACCOUNT-PERTANYAAN
export const ACCOUNT_PERTANYAAN_LOADING = "ACCOUNT_PERTANYAAN_LOADING";
export const ACCOUNT_PERTANYAAN_LOADED = "ACCOUNT_PERTANYAAN_LOADED";
export const ACCOUNT_PERTANYAAN_LOADING_DONE =
  "ACCOUNT_PERTANYAAN_LOADING_DONE";
//* ACCOUNT-RIWAYAT
export const ACCOUNT_RIWAYAT_LOADING = "ACCOUNT_RIWAYAT_LOADING";
export const ACCOUNT_RIWAYAT_LOADED = "ACCOUNT_RIWAYAT_LOADED";
export const ACCOUNT_RIWAYAT_LOADING_DONE = "ACCOUNT_RIWAYAT_LOADING_DONE";
//* ACCOUNT-CREATEACCOUNT
export const ACCOUNT_CREATEACCOUNT_LOADING = "ACCOUNT_CREATEACCOUNT_LOADING";
export const ACCOUNT_CREATEACCOUNT_LOADED = "ACCOUNT_CREATEACCOUNT_LOADED";
export const ACCOUNT_CREATEACCOUNT_ERROR_EMAIL_USED =
  "ACCOUNT_CREATEACCOUNT_ERROR_EMAIL_USED";
export const ACCOUNT_CREATEACCOUNT_ERROR_USERNAME_USED =
  "ACCOUNT_CREATEACCOUNT_ERROR_USERNAME_USED";
//* END-ACCOUNT
//* JOB
export const TIMELINE_LOADING = "TIMELINE_LOADING";
export const TIMELINE_LOADED = "TIMELINE_LOADED";
export const TIMELINE_LOADING_DONE = "TIMELINE_LOADING_DONE";
export const TIMELINE_DETAIL_LOADING = "TIMELINE_DETAIL_LOADING";
export const TIMELINE_DETAIL_LOADED = "TIMELINE_DETAIL_LOADED";
export const TIMELINE_DETAIL_LOADING_DONE = "TIMELINE_DETAIL_LOADING_DONE";
//*
export const JOB_LIST_LOADING = "JOB_LIST_LOADING";
export const JOB_LIST_LOADED = "JOB_LIST_LOADED";
export const JOB_LIST_LOADING_DONE = "JOB_LIST_LOADING_DONE";
export const JOB_FULL_LIST_LOADING = "JOB_FULL_LIST_LOADING";
export const JOB_FULL_LIST_LOADED = "JOB_FULL_LIST_LOADED";
export const JOB_FULL_LIST_LOADING_DONE = "JOB_FULL_LIST_LOADING_DONE";
export const JOB_ACTION_GET_ID = "JOB_ACTION_GET_ID";
export const JOB_ACTION_GET_DATA_LOADING = "JOB_ACTION_GET_DATA_LOADING";
export const JOB_ACTION_GET_DATA_LOADED = "JOB_ACTION_GET_DATA_LOADED";
export const JOB_ACTION_GET_DATA_LOADING_DONE =
  "JOB_ACTION_GET_DATA_LOADING_DONE";
export const JOB_IS_JOB_SAVE_LOADING = "JOB_IS_JOB_SAVE_LOADING";
export const JOB_IS_JOB_SAVE_LOADED = "JOB_IS_JOB_SAVE_LOADED";
export const JOB_IS_JOB_SAVE_LOADING_DONE = "JOB_IS_JOB_SAVE_LOADING_DONE";
export const JOB_IS_JOB_APPLY_LOADING = "JOB_IS_JOB_APPLY_LOADING";
export const JOB_IS_JOB_APPLY_LOADED = "JOB_IS_JOB_APPLY_LOADED";
export const JOB_IS_JOB_APPLY_LOADING_DONE = "JOB_IS_JOB_APPLY_LOADING_DONE";
export const JOB_LIST_PENDAFTAR_LOADING = "JOB_LIST_PENDAFTAR_LOADING";
export const JOB_LIST_PENDAFTAR_LOADED = "JOB_LIST_PENDAFTAR_LOADED";
export const JOB_LIST_PENDAFTAR_LOADING_DONE =
  "JOB_LIST_PENDAFTAR_LOADING_DONE";
export const JOB_LIST_PENDAFTAR_DETAIL_LOADING =
  "JOB_LIST_PENDAFTAR_DETAIL_LOADING";
export const JOB_LIST_PENDAFTAR_DETAIL_LOADED =
  "JOB_LIST_PENDAFTAR_DETAIL_LOADED";
export const JOB_LIST_PENDAFTAR_DETAIL_LOADING_DONE =
  "JOB_LIST_PENDAFTAR_DETAIL_LOADING_DONE";
export const JOB_LIST_PENDAFTAR_DIAJUKAN_LOADING =
  "JOB_LIST_PENDAFTAR_DIAJUKAN_LOADING";
export const JOB_LIST_PENDAFTAR_DIAJUKAN_LOADED =
  "JOB_LIST_PENDAFTAR_DIAJUKAN_LOADED";
export const JOB_LIST_PENDAFTAR_DIAJUKAN_LOADING_DONE =
  "JOB_LIST_PENDAFTAR_DIAJUKAN_LOADING_DONE";
export const JOB_LIST_PENDAFTAR_SUCCESS = "JOB_LIST_PENDAFTAR_SUCCESS";
export const JOB_LIST_REKOMENDASI_LOADING = "JOB_LIST_REKOMENDASI_LOADING";
export const JOB_LIST_REKOMENDASI_LOADED = "JOB_LIST_REKOMENDASI_LOADED";
export const JOB_LIST_REKOMENDASI_LOADING_DONE =
  "JOB_LIST_REKOMENDASI_LOADING_DONE";
//* JOB-PERSONALITYTEST
export const JOB_LIST_PERSONALITYTEST_LOADING =
  "JOB_LIST_PERSONALITYTEST_LOADING";
export const JOB_LIST_PERSONALITYTEST_LOADED =
  "JOB_LIST_PERSONALITYTEST_LOADED";
export const JOB_LIST_PERSONALITYTEST_LOADING_DONE =
  "JOB_LIST_PERSONALITYTEST_LOADING_DONE";
export const JOB_LIST_PERSONALITYTEST_DETAIL_LOADING =
  "JOB_LIST_PERSONALITYTEST_DETAIL_LOADING";
export const JOB_LIST_PERSONALITYTEST_DETAIL_LOADED =
  "JOB_LIST_PERSONALITYTEST_DETAIL_LOADED";
export const JOB_LIST_PERSONALITYTEST_DETAIL_LOADING_DONE =
  "JOB_LIST_PERSONALITYTEST_DETAIL_LOADING_DONE";
//* JOB-INTERVIEW
export const JOB_LIST_INTERVIEW_JADWAL_LOADING =
  "JOB_LIST_INTERVIEW_USER_LOADING";
export const JOB_LIST_INTERVIEW_JADWAL_LOADED =
  "JOB_LIST_INTERVIEW_JADWAL_LOADED";
export const JOB_LIST_INTERVIEW_JADWAL_LOADING_DONE =
  "JOB_LIST_INTERVIEW_JADWAL_LOADING_DONE";
export const JOB_LIST_INTERVIEW_JADWAL_DETAIL_LOADING =
  "JOB_LIST_INTERVIEW_JADWAL_DETAIL_LOADING";
export const JOB_LIST_INTERVIEW_JADWAL_DETAIL_LOADED =
  "JOB_LIST_INTERVIEW_JADWAL_DETAIL_LOADED";
export const JOB_LIST_INTERVIEW_JADWAL_DETAIL_LOADING_DONE =
  "JOB_LIST_INTERVIEW_JADWAL_DETAIL_LOADING_DONE";
export const JOB_LIST_INTERVIEW_USER_LOADING =
  "JOB_LIST_INTERVIEW_USER_LOADING";
export const JOB_LIST_INTERVIEW_USER_LOADED = "JOB_LIST_INTERVIEW_USER_LOADED";
export const JOB_LIST_INTERVIEW_USER_LOADING_DONE =
  "JOB_LIST_INTERVIEW_USER_LOADING_DONE";
export const JOB_LIST_INTERVIEW_USER_DETAIL_LOADING =
  "JOB_LIST_INTERVIEW_USER_DETAIL_LOADING";
export const JOB_LIST_INTERVIEW_USER_DETAIL_LOADED =
  "JOB_LIST_INTERVIEW_USER_DETAIL_LOADED";
export const JOB_LIST_INTERVIEW_USER_DETAIL_LOADING_DONE =
  "JOB_LIST_INTERVIEW_USER_DETAIL_LOADING_DONE";
export const JOB_LIST_INTERVIEW_HR_LOADING = "JOB_LIST_INTERVIEW_HR_LOADING";
export const JOB_LIST_INTERVIEW_HR_LOADED = "JOB_LIST_INTERVIEW_HR_LOADED";
export const JOB_LIST_INTERVIEW_HR_LOADING_DONE =
  "JOB_LIST_INTERVIEW_HR_LOADING_DONE";
export const JOB_LIST_INTERVIEW_HR_DETAIL_LOADING =
  "JOB_LIST_INTERVIEW_HR_DETAIL_LOADING";
export const JOB_LIST_INTERVIEW_HR_DETAIL_LOADED =
  "JOB_LIST_INTERVIEW_HR_DETAIL_LOADED";
export const JOB_LIST_INTERVIEW_HR_DETAIL_LOADING_DONE =
  "JOB_LIST_INTERVIEW_HR_DETAIL_LOADING_DONE";
//* JOB-OFFERING
export const JOB_LIST_OFFERING_LOADING = "JOB_LIST_OFFERING_LOADING";
export const JOB_LIST_OFFERING_LOADED = "JOB_LIST_OFFERING_LOADED";
export const JOB_LIST_OFFERING_LOADING_DONE = "JOB_LIST_OFFERING_LOADING_DONE";
export const JOB_LIST_OFFERING_DETAIL_LOADING =
  "JOB_LIST_OFFERING_DETAIL_LOADING";
export const JOB_LIST_OFFERING_DETAIL_LOADED =
  "JOB_LIST_OFFERING_DETAIL_LOADED";
export const JOB_LIST_OFFERING_DETAIL_LOADING_DONE =
  "JOB_LIST_OFFERING_DETAIL_LOADING_DONE";
//* JOB-MCU
export const JOB_LIST_MCU_LOADING = "JOB_LIST_MCU_LOADING";
export const JOB_LIST_MCU_LOADED = "JOB_LIST_MCU_LOADED";
export const JOB_LIST_MCU_LOADING_DONE = "JOB_LIST_MCU_LOADING_DONE";
export const JOB_LIST_MCU_DETAIL_LOADING = "JOB_LIST_MCU_DETAIL_LOADING";
export const JOB_LIST_MCU_DETAIL_LOADED = "JOB_LIST_MCU_DETAIL_LOADED";
export const JOB_LIST_MCU_DETAIL_LOADING_DONE =
  "JOB_LIST_MCU_DETAIL_LOADING_DONE";
//* JOB-EQUIPMENT
export const JOB_LIST_EQUIPMENT_LOADING = "JOB_LIST_EQUIPMENT_LOADING";
export const JOB_LIST_EQUIPMENT_LOADED = "JOB_LIST_EQUIPMENT_LOADED";
export const JOB_LIST_EQUIPMENT_LOADING_DONE =
  "JOB_LIST_EQUIPMENT_LOADING_DONE";
export const JOB_LIST_EQUIPMENT_DETAIL_LOADING =
  "JOB_LIST_EQUIPMENT_DETAIL_LOADING";
export const JOB_LIST_EQUIPMENT_DETAIL_LOADED =
  "JOB_LIST_EQUIPMENT_DETAIL_LOADED";
export const JOB_LIST_EQUIPMENT_DETAIL_LOADING_DONE =
  "JOB_LIST_EQUIPMENT_DETAIL_LOADING_DONE";
//* JOB-PREBOARDING
export const JOB_LIST_PREBOARDING_LOADING = "JOB_LIST_PREBOARDING_LOADING";
export const JOB_LIST_PREBOARDING_LOADED = "JOB_LIST_PREBOARDING_LOADED";
export const JOB_LIST_PREBOARDING_LOADING_DONE =
  "JOB_LIST_PREBOARDING_LOADING_DONE";
export const JOB_LIST_PREBOARDING_DETAIL_LOADING =
  "JOB_LIST_PREBOARDING_DETAIL_LOADING";
export const JOB_LIST_PREBOARDING_DETAIL_LOADED =
  "JOB_LIST_PREBOARDING_DETAIL_LOADED";
export const JOB_LIST_PREBOARDING_DETAIL_LOADING_DONE =
  "JOB_LIST_PREBOARDING_DETAIL_LOADING_DONE";
//* JOB-PKWT
export const JOB_LIST_PKWT_LOADING = "JOB_LIST_PKWT_LOADING";
export const JOB_LIST_PKWT_LOADED = "JOB_LIST_PKWT_LOADED";
export const JOB_LIST_PKWT_LOADING_DONE = "JOB_LIST_PKWT_LOADING_DONE";
export const JOB_LIST_PKWT_DETAIL_LOADING = "JOB_LIST_PKWT_DETAIL_LOADING";
export const JOB_LIST_PKWT_DETAIL_LOADED = "JOB_LIST_PKWT_DETAIL_LOADED";
export const JOB_LIST_PKWT_DETAIL_LOADING_DONE =
  "JOB_LIST_PKWT_DETAIL_LOADING_DONE";
//* JOB-BPJS
export const JOB_LIST_BPJS_LOADING = "JOB_LIST_BPJS_LOADING";
export const JOB_LIST_BPJS_LOADED = "JOB_LIST_BPJS_LOADED";
export const JOB_LIST_BPJS_LOADING_DONE = "JOB_LIST_BPJS_LOADING_DONE";
export const JOB_LIST_BPJS_DETAIL_LOADING = "JOB_LIST_BPJS_DETAIL_LOADING";
export const JOB_LIST_BPJS_DETAIL_LOADED = "JOB_LIST_BPJS_DETAIL_LOADED";
export const JOB_LIST_BPJS_DETAIL_LOADING_DONE =
  "JOB_LIST_BPJS_DETAIL_LOADING_DONE";
//* JOB-INDUSTRY
export const JOB_LIST_INDUSTRY_LOADING = "JOB_LIST_INDUSTRY_LOADING";
export const JOB_LIST_INDUSTRY_LOADED = "JOB_LIST_INDUSTRY_LOADED";
export const JOB_LIST_INDUSTRY_LOADING_DONE = "JOB_LIST_INDUSTRY_LOADING_DONE";
export const JOB_LIST_INDUSTRY_DETAIL_LOADING =
  "JOB_LIST_INDUSTRY_DETAIL_LOADING";
export const JOB_LIST_INDUSTRY_DETAIL_LOADED =
  "JOB_LIST_INDUSTRY_DETAIL_LOADED";
export const JOB_LIST_INDUSTRY_DETAIL_LOADING_DONE =
  "JOB_LIST_INDUSTRY_DETAIL_LOADING_DONE";
//* JOB-GREETDAY
export const JOB_LIST_GREETDAY_LOADING = "JOB_LIST_GREETDAY_LOADING";
export const JOB_LIST_GREETDAY_LOADED = "JOB_LIST_GREETDAY_LOADED";
export const JOB_LIST_GREETDAY_LOADING_DONE = "JOB_LIST_GREETDAY_LOADING_DONE";
export const JOB_LIST_GREETDAY_DETAIL_LOADING =
  "JOB_LIST_GREETDAY_DETAIL_LOADING";
export const JOB_LIST_GREETDAY_DETAIL_LOADED =
  "JOB_LIST_GREETDAY_DETAIL_LOADED";
export const JOB_LIST_GREETDAY_DETAIL_LOADING_DONE =
  "JOB_LIST_GREETDAY_DETAIL_LOADING_DONE";
//* JOB-KONTRAK
export const JOB_LIST_KONTRAK_LOADING = "JOB_LIST_KONTRAK_LOADING";
export const JOB_LIST_KONTRAK_LOADED = "JOB_LIST_KONTRAK_LOADED";
export const JOB_LIST_KONTRAK_LOADING_DONE = "JOB_LIST_KONTRAK_LOADING_DONE";
export const JOB_LIST_KONTRAK_DETAIL_LOADING =
  "JOB_LIST_KONTRAK_DETAIL_LOADING";
export const JOB_LIST_KONTRAK_DETAIL_LOADED = "JOB_LIST_KONTRAK_DETAIL_LOADED";
export const JOB_LIST_KONTRAK_DETAIL_LOADING_DONE =
  "JOB_LIST_KONTRAK_DETAIL_LOADING_DONE";
//* JOB-ONBOARDING
export const JOB_LIST_ONBOARDING_LOADING = "JOB_LIST_ONBOARDING_LOADING";
export const JOB_LIST_ONBOARDING_LOADED = "JOB_LIST_ONBOARDING_LOADED";
export const JOB_LIST_ONBOARDING_LOADING_DONE =
  "JOB_LIST_ONBOARDING_LOADING_DONE";
export const JOB_LIST_ONBOARDING_DETAIL_LOADING =
  "JOB_LIST_ONBOARDING_DETAIL_LOADING";
export const JOB_LIST_ONBOARDING_DETAIL_LOADED =
  "JOB_LIST_ONBOARDING_DETAIL_LOADED";
export const JOB_LIST_ONBOARDING_DETAIL_LOADING_DONE =
  "JOB_LIST_ONBOARDING_DETAIL_LOADING_DONE";
//* APPLICATIONS
export const JOB_LIST_APPLICATIONS_APPLY_LOADING =
  "JOB_LIST_APPLICATIONS_APPLY_LOADING";
export const JOB_LIST_APPLICATIONS_APPLY_LOADED =
  "JOB_LIST_APPLICATIONS_APPLY_LOADED";
export const JOB_LIST_APPLICATIONS_APPLY_LOADING_DONE =
  "JOB_LIST_APPLICATIONS_APPLY_LOADING_DONE";
export const JOB_LIST_APPLICATIONS_SAVE_LOADING =
  "JOB_LIST_APPLICATIONS_SAVE_LOADING";
export const JOB_LIST_APPLICATIONS_SAVE_LOADED =
  "JOB_LIST_APPLICATIONS_SAVE_LOADED";
export const JOB_LIST_APPLICATIONS_SAVE_LOADING_DONE =
  "JOB_LIST_APPLICATIONS_SAVE_LOADING_DONE";
//* PREREGISTER
export const JOB_PREREGISTER_LOADING = "JOB_PREREGISTER_LOADING";
export const JOB_PREREGISTER_LOADED = "JOB_PREREGISTER_LOADED";
export const JOB_PREREGISTER_LOADING_DONE = "JOB_PREREGISTER_LOADING_DONE";
//* MOVE
export const JOB_MOVE_LIST_LOADING = "JOB_MOVE_LIST_LOADING";
export const JOB_MOVE_LIST_LOADED = "JOB_MOVE_LIST_LOADED";
export const JOB_MOVE_LIST_LOADING_DONE = "JOB_MOVE_LIST_LOADING_DONE";
export const JOB_MOVE_DETAIL_LOADING = "JOB_MOVE_DETAIL_LOADING";
export const JOB_MOVE_DETAIL_LOADED = "JOB_MOVE_DETAIL_LOADED";
export const JOB_MOVE_DETAIL_LOADING_DONE = "JOB_MOVE_DETAIL_LOADING_DONE";
//* END-JOB
//* MESSAGES
export const SUCCESS_MESSAGES = "SUCCESS_MESSAGES";
export const INFO_MESSAGES = "INFO_MESSAGES";
export const WARNING_MESSAGES = "WARNING_MESSAGES";
export const ERROR_MESSAGES = "ERROR_MESSAGES";
export const CLEAN_MESSAGES = "CLEAN_MESSAGES";
//* END-MESSAGES
//* ONESTEP
export const ONESTEP_CREATE_ACCOUNT = "ONESTEP_CREATE_ACCOUNT";
export const ONESTEP_CONFIRMATION_ACCOUNT = "ONESTEP_CONFIRMATION_ACCOUNT";
export const ONESTEP_FILING_BIODATA = "ONESTEP_FILING_BIODATA";
//* SETTING
//* SETTING-ROLE
export const SETTING_ROLE_LOADING = "SETTING_ROLE_LOADING";
export const SETTING_ROLE_LOADED = "SETTING_ROLE_LOADED";
export const SETTING_ROLE_LOADING_DONE = "SETTING_ROLE_LOADING_DONE";
export const SETTING_ROLE_ACTION_ADD_LOADING =
  "SETTING_ROLE_ACTION_ADD_LOADING";
export const SETTING_ROLE_ACTION_ADD_LOADING_DONE =
  "SETTING_ROLE_ACTION_ADD_LOADING_DONE";
export const SETTING_ROLE_ACTION_ADD_ERROR = "SETTING_ROLE_ACTION_ADD_ERROR";
export const SETTING_ROLE_ACTION_GET_ID = "SETTING_ROLE_ACTION_GET_ID";
export const SETTING_ROLE_ACTION_GET_ROLELIST_PARTICIPANT_LOADING =
  "SETTING_ROLE_ACTION_GET_ROLELIST_PARTICIPANT_LOADING";
export const SETTING_ROLE_ACTION_GET_ROLELIST_PARTICIPANT_LOADED =
  "SETTING_ROLE_ACTION_GET_ROLELIST_PARTICIPANT_LOADED";
export const SETTING_ROLE_ACTION_GET_ROLELIST_PARTICIPANT_LOADING_DONE =
  "SETTING_ROLE_ACTION_GET_ROLELIST_PARTICIPANT_LOADING_DONE";
export const SETTING_ROLE_ACTION_GET_DATA_LOADING =
  "SETTING_ROLE_ACTION_GET_DATA_LOADING";
export const SETTING_ROLE_ACTION_GET_DATA_LOADED =
  "SETTING_ROLE_ACTION_GET_DATA_LOADED";
export const SETTING_ROLE_ACTION_GET_DATA_LOADING_DONE =
  "SETTING_ROLE_ACTION_GET_DATA_LOADING_DONE";
export const SETTING_ROLE_ACTION_DELETE_LOADING =
  "SETTING_ROLE_ACTION_DELETE_LOADING";
export const SETTING_ROLE_ACTION_DELETE_LOADING_DONE =
  "SETTING_ROLE_ACTION_DELETE_LOADING_DONE";
export const SETTING_ROLE_ACTION_ADD_LIST_LOADING =
  "SETTING_ROLE_ACTION_ADD_LIST_LOADING";
export const SETTING_ROLE_ACTION_ADD_LIST_LOADING_DONE =
  "SETTING_ROLE_ACTION_ADD_LIST_LOADING_DONE";
//* SETTING-DIVISION
export const SETTING_DIVISION_LOADING = "SETTING_DIVISION_LOADING";
export const SETTING_DIVISION_LOADED = "SETTING_DIVISION_LOADED";
export const SETTING_DIVISION_LOADING_DONE = "SETTING_DIVISION_LOADING_DONE";
export const SETTING_DIVISION_ACTION_ADD_LOADING =
  "SETTING_DIVISION_ACTION_ADD_LOADING";
export const SETTING_DIVISION_ACTION_ADD_LOADING_DONE =
  "SETTING_DIVISION_ACTION_ADD_LOADING_DONE";
export const SETTING_DIVISION_ACTION_ADD_ERROR =
  "SETTING_DIVISION_ACTION_ADD_ERROR";
export const SETTING_DIVISION_ACTION_GET_ID = "SETTING_DIVISION_ACTION_GET_ID";
export const SETTING_DIVISION_ACTION_GET_DIVISIONLIST_PARTICIPANT_LOADING =
  "SETTING_DIVISION_ACTION_GET_DIVISIONLIST_PARTICIPANT_LOADING";
export const SETTING_DIVISION_ACTION_GET_DIVISIONLIST_PARTICIPANT_LOADED =
  "SETTING_DIVISION_ACTION_GET_DIVISIONLIST_PARTICIPANT_LOADED";
export const SETTING_DIVISION_ACTION_GET_DIVISIONLIST_PARTICIPANT_LOADING_DONE =
  "SETTING_DIVISION_ACTION_GET_DIVISIONLIST_PARTICIPANT_LOADING_DONE";
export const SETTING_DIVISION_ACTION_GET_DATA_LOADING =
  "SETTING_DIVISION_ACTION_GET_DATA_LOADING";
export const SETTING_DIVISION_ACTION_GET_DATA_LOADED =
  "SETTING_DIVISION_ACTION_GET_DATA_LOADED";
export const SETTING_DIVISION_ACTION_GET_DATA_LOADING_DONE =
  "SETTING_DIVISION_ACTION_GET_DATA_LOADING_DONE";
export const SETTING_DIVISION_ACTION_DELETE_LOADING =
  "SETTING_DIVISION_ACTION_DELETE_LOADING";
export const SETTING_DIVISION_ACTION_DELETE_LOADING_DONE =
  "SETTING_DIVISION_ACTION_DELETE_LOADING_DONE";
export const SETTING_DIVISION_ACTION_ADD_LIST_LOADING =
  "SETTING_DIVISION_ACTION_ADD_LIST_LOADING";
export const SETTING_DIVISION_ACTION_ADD_LIST_LOADING_DONE =
  "SETTING_DIVISION_ACTION_ADD_LIST_LOADING_DONE";
//* SETTING-DEPARTMENT
export const SETTING_DEPARTMENT_LOADING = "SETTING_DEPARTMENT_LOADING";
export const SETTING_DEPARTMENT_LOADED = "SETTING_DEPARTMENT_LOADED";
export const SETTING_DEPARTMENT_LOADING_DONE =
  "SETTING_DEPARTMENT_LOADING_DONE";
export const SETTING_DEPARTMENT_ACTION_ADD_LOADING =
  "SETTING_DEPARTMENT_ACTION_ADD_LOADING";
export const SETTING_DEPARTMENT_ACTION_ADD_LOADING_DONE =
  "SETTING_DEPARTMENT_ACTION_ADD_LOADING_DONE";
export const SETTING_DEPARTMENT_ACTION_ADD_ERROR =
  "SETTING_DEPARTMENT_ACTION_ADD_ERROR";
export const SETTING_DEPARTMENT_ACTION_GET_ID =
  "SETTING_DEPARTMENT_ACTION_GET_ID";
export const SETTING_DEPARTMENT_ACTION_GET_DEPARTMENTLIST_PARTICIPANT_LOADING =
  "SETTING_DEPARTMENT_ACTION_GET_DEPARTMENTLIST_PARTICIPANT_LOADING";
export const SETTING_DEPARTMENT_ACTION_GET_DEPARTMENTLIST_PARTICIPANT_LOADED =
  "SETTING_DEPARTMENT_ACTION_GET_DEPARTMENTLIST_PARTICIPANT_LOADED";
export const SETTING_DEPARTMENT_ACTION_GET_DEPARTMENTLIST_PARTICIPANT_LOADING_DONE =
  "SETTING_DEPARTMENT_ACTION_GET_DEPARTMENTLIST_PARTICIPANT_LOADING_DONE";
export const SETTING_DEPARTMENT_ACTION_GET_DATA_LOADING =
  "SETTING_DEPARTMENT_ACTION_GET_DATA_LOADING";
export const SETTING_DEPARTMENT_ACTION_GET_DATA_LOADED =
  "SETTING_DEPARTMENT_ACTION_GET_DATA_LOADED";
export const SETTING_DEPARTMENT_ACTION_GET_DATA_LOADING_DONE =
  "SETTING_DEPARTMENT_ACTION_GET_DATA_LOADING_DONE";
export const SETTING_DEPARTMENT_ACTION_DELETE_LOADING =
  "SETTING_DEPARTMENT_ACTION_DELETE_LOADING";
export const SETTING_DEPARTMENT_ACTION_DELETE_LOADING_DONE =
  "SETTING_DEPARTMENT_ACTION_DELETE_LOADING_DONE";
export const SETTING_DEPARTMENT_ACTION_ADD_LIST_LOADING =
  "SETTING_DEPARTMENT_ACTION_ADD_LIST_LOADING";
export const SETTING_DEPARTMENT_ACTION_ADD_LIST_LOADING_DONE =
  "SETTING_DEPARTMENT_ACTION_ADD_LIST_LOADING_DONE";
//* SETTING-LEVEL
export const SETTING_LEVEL_LOADING = "SETTING_LEVEL_LOADING";
export const SETTING_LEVEL_LOADED = "SETTING_LEVEL_LOADED";
export const SETTING_LEVEL_LOADING_DONE = "SETTING_LEVEL_LOADING_DONE";
export const SETTING_GOLONGAN_LOADING = "SETTING_GOLONGAN_LOADING";
export const SETTING_GOLONGAN_LOADED = "SETTING_GOLONGAN_LOADED";
export const SETTING_GOLONGAN_LOADING_DONE = "SETTING_GOLONGAN_LOADING_DONE";
export const SETTING_LEVEL_ACTION_ADD_LOADING =
  "SETTING_LEVEL_ACTION_ADD_LOADING";
export const SETTING_LEVEL_ACTION_ADD_LOADING_DONE =
  "SETTING_LEVEL_ACTION_ADD_LOADING_DONE";
export const SETTING_LEVEL_ACTION_ADD_ERROR = "SETTING_LEVEL_ACTION_ADD_ERROR";
export const SETTING_LEVEL_ACTION_GET_ID = "SETTING_LEVEL_ACTION_GET_ID";
export const SETTING_LEVEL_ACTION_GET_LEVELLIST_PARTICIPANT_LOADING =
  "SETTING_LEVEL_ACTION_GET_LEVELLIST_PARTICIPANT_LOADING";
export const SETTING_LEVEL_ACTION_GET_LEVELLIST_PARTICIPANT_LOADED =
  "SETTING_LEVEL_ACTION_GET_LEVELLIST_PARTICIPANT_LOADED";
export const SETTING_LEVEL_ACTION_GET_LEVELLIST_PARTICIPANT_LOADING_DONE =
  "SETTING_LEVEL_ACTION_GET_LEVELLIST_PARTICIPANT_LOADING_DONE";
export const SETTING_LEVEL_ACTION_GET_DATA_LOADING =
  "SETTING_LEVEL_ACTION_GET_DATA_LOADING";
export const SETTING_LEVEL_ACTION_GET_DATA_LOADED =
  "SETTING_LEVEL_ACTION_GET_DATA_LOADED";
export const SETTING_LEVEL_ACTION_GET_DATA_LOADING_DONE =
  "SETTING_LEVEL_ACTION_GET_DATA_LOADING_DONE";
export const SETTING_LEVEL_ACTION_DELETE_LOADING =
  "SETTING_LEVEL_ACTION_DELETE_LOADING";
export const SETTING_LEVEL_ACTION_DELETE_LOADING_DONE =
  "SETTING_LEVEL_ACTION_DELETE_LOADING_DONE";
export const SETTING_LEVEL_ACTION_ADD_LIST_LOADING =
  "SETTING_LEVEL_ACTION_ADD_LIST_LOADING";
export const SETTING_LEVEL_ACTION_ADD_LIST_LOADING_DONE =
  "SETTING_LEVEL_ACTION_ADD_LIST_LOADING_DONE";
//* SETTING-MPP
export const SETTING_MPP_LOADING = "SETTING_MPP_LOADING";
export const SETTING_MPP_LOADED = "SETTING_MPP_LOADED";
export const SETTING_MPP_LOADING_DONE = "SETTING_MPP_LOADING_DONE";
export const SETTING_MPP_ACTION_ADD_LOADING = "SETTING_MPP_ACTION_ADD_LOADING";
export const SETTING_MPP_ACTION_ADD_LOADING_DONE =
  "SETTING_MPP_ACTION_ADD_LOADING_DONE";
export const SETTING_MPP_ACTION_ADD_ERROR = "SETTING_MPP_ACTION_ADD_ERROR";
export const SETTING_MPP_ACTION_GET_ID = "SETTING_MPP_ACTION_GET_ID";
export const SETTING_MPP_ACTION_GET_DATA_LOADING =
  "SETTING_MPP_ACTION_GET_DATA_LOADING";
export const SETTING_MPP_ACTION_GET_DATA_LOADED =
  "SETTING_MPP_ACTION_GET_DATA_LOADED";
export const SETTING_MPP_ACTION_GET_DATA_LOADING_DONE =
  "SETTING_MPP_ACTION_GET_DATA_LOADING_DONE";
export const SETTING_MPP_ACTION_DELETE_LOADING =
  "SETTING_MPP_ACTION_DELETE_LOADING";
export const SETTING_MPP_ACTION_DELETE_LOADING_DONE =
  "SETTING_MPP_ACTION_DELETE_LOADING_DONE";
//* SETTING-MPPVIEW
export const MPP_GET_VIEW_ALL_LOADING = "MPP_GET_VIEW_ALL_LOADING";
export const MPP_GET_VIEW_ALL_LOADED = "MPP_GET_VIEW_ALL_LOADED";
export const MPP_GET_VIEW_ALL_LOADING_DONE = "MPP_GET_VIEW_ALL_LOADING_DONE";
export const MPP_GET_VIEW_IMPLEMENTASI_LOADING =
  "MPP_GET_VIEW_IMPLEMENTASI_LOADING";
export const MPP_GET_VIEW_IMPLEMENTASI_LOADED =
  "MPP_GET_VIEW_IMPLEMENTASI_LOADED";
export const MPP_GET_VIEW_IMPLEMENTASI_LOADING_DONE =
  "MPP_GET_VIEW_IMPLEMENTASI_LOADING_DONE";
export const MPP_GET_VIEW_ASLI_LOADING = "MPP_GET_VIEW_ASLI_LOADING";
export const MPP_GET_VIEW_ASLI_LOADED = "MPP_GET_VIEW_ASLI_LOADED";
export const MPP_GET_VIEW_ASLI_LOADING_DONE = "MPP_GET_VIEW_ASLI_LOADING_DONE";
export const MPP_GET_VIEW_LAINNYA_LOADING = "MPP_GET_VIEW_LAINNYA_LOADING";
export const MPP_GET_VIEW_LAINNYA_LOADED = "MPP_GET_VIEW_LAINNYA_LOADED";
export const MPP_GET_VIEW_LAINNYA_LOADING_DONE =
  "MPP_GET_VIEW_LAINNYA_LOADING_DONE";
//* SETTING-ITEMS
export const SETTING_ITEMS_LOADING = "SETTING_ITEMS_LOADING";
export const SETTING_ITEMS_LOADED = "SETTING_ITEMS_LOADED";
export const SETTING_ITEMS_LOADING_DONE = "SETTING_ITEMS_LOADING_DONE";
export const SETTING_ITEMS_ACTION_ADD_LOADING =
  "SETTING_ITEMS_ACTION_ADD_LOADING";
export const SETTING_ITEMS_ACTION_ADD_LOADING_DONE =
  "SETTING_ITEMS_ACTION_ADD_LOADING_DONE";
export const SETTING_ITEMS_ACTION_ADD_ERROR = "SETTING_ITEMS_ACTION_ADD_ERROR";
export const SETTING_ITEMS_ACTION_GET_ID = "SETTING_ITEMS_ACTION_GET_ID";
export const SETTING_ITEMS_ACTION_GET_DATA_LOADING =
  "SETTING_ITEMS_ACTION_GET_DATA_LOADING";
export const SETTING_ITEMS_ACTION_GET_DATA_LOADED =
  "SETTING_ITEMS_ACTION_GET_DATA_LOADED";
export const SETTING_ITEMS_ACTION_GET_DATA_LOADING_DONE =
  "SETTING_ITEMS_ACTION_GET_DATA_LOADING_DONE";
export const SETTING_ITEMS_ACTION_DELETE_LOADING =
  "SETTING_ITEMS_ACTION_DELETE_LOADING";
export const SETTING_ITEMS_ACTION_DELETE_LOADING_DONE =
  "SETTING_ITEMS_ACTION_DELETE_LOADING_DONE";
//* SETTING-LOKASI
export const SETTING_LOKASI_LOADING = "SETTING_LOKASI_LOADING";
export const SETTING_LOKASI_LOADED = "SETTING_LOKASI_LOADED";
export const SETTING_LOKASI_LOADING_DONE = "SETTING_LOKASI_LOADING_DONE";
export const SETTING_LOKASI_ACTION_ADD_LOADING =
  "SETTING_LOKASI_ACTION_ADD_LOADING";
export const SETTING_LOKASI_ACTION_ADD_LOADING_DONE =
  "SETTING_LOKASI_ACTION_ADD_LOADING_DONE";
export const SETTING_LOKASI_ACTION_ADD_ERROR =
  "SETTING_LOKASI_ACTION_ADD_ERROR";
export const SETTING_LOKASI_ACTION_GET_ID = "SETTING_LOKASI_ACTION_GET_ID";
export const SETTING_LOKASI_ACTION_GET_DATA_LOADING =
  "SETTING_LOKASI_ACTION_GET_DATA_LOADING";
export const SETTING_LOKASI_ACTION_GET_DATA_LOADED =
  "SETTING_LOKASI_ACTION_GET_DATA_LOADED";
export const SETTING_LOKASI_ACTION_GET_DATA_LOADING_DONE =
  "SETTING_LOKASI_ACTION_GET_DATA_LOADING_DONE";
export const SETTING_LOKASI_ACTION_DELETE_LOADING =
  "SETTING_LOKASI_ACTION_DELETE_LOADING";
export const SETTING_LOKASI_ACTION_DELETE_LOADING_DONE =
  "SETTING_LOKASI_ACTION_DELETE_LOADING_DONE";
//* SETTING-PERTANYAAN INTERVIEW
export const SETTING_PERTANYAAN_INTERVIEW_LOADING =
  "SETTING_PERTANYAAN_INTERVIEW_LOADING";
export const SETTING_PERTANYAAN_INTERVIEW_LOADED =
  "SETTING_PERTANYAAN_INTERVIEW_LOADED";
export const SETTING_PERTANYAAN_INTERVIEW_LOADING_DONE =
  "SETTING_PERTANYAAN_INTERVIEW_LOADING_DONE";
export const SETTING_PERTANYAAN_INTERVIEW_ACTION_ADD_LOADING =
  "SETTING_PERTANYAAN_INTERVIEW_ACTION_ADD_LOADING";
export const SETTING_PERTANYAAN_INTERVIEW_ACTION_ADD_LOADING_DONE =
  "SETTING_PERTANYAAN_INTERVIEW_ACTION_ADD_LOADING_DONE";
export const SETTING_PERTANYAAN_INTERVIEW_ACTION_ADD_ERROR =
  "SETTING_PERTANYAAN_INTERVIEW_ACTION_ADD_ERROR";
export const SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_ID =
  "SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_ID";
export const SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_DATA_LOADING =
  "SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_DATA_LOADING";
export const SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_DATA_LOADED =
  "SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_DATA_LOADED";
export const SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_DATA_LOADING_DONE =
  "SETTING_PERTANYAAN_INTERVIEW_ACTION_GET_DATA_LOADING_DONE";
export const SETTING_PERTANYAAN_INTERVIEW_ACTION_DELETE_LOADING =
  "SETTING_PERTANYAAN_INTERVIEW_ACTION_DELETE_LOADING";
export const SETTING_PERTANYAAN_INTERVIEW_ACTION_DELETE_LOADING_DONE =
  "SETTING_PERTANYAAN_INTERVIEW_ACTION_DELETE_LOADING_DONE";
//* SETTING-PERTANYAAN BIODATA JAWABAN
export const SETTING_PERTANYAAN_BIODATA_JAWABAN_LOADING =
  "SETTING_PERTANYAAN_BIODATA_JAWABAN_LOADING";
export const SETTING_PERTANYAAN_BIODATA_JAWABAN_LOADED =
  "SETTING_PERTANYAAN_BIODATA_JAWABAN_LOADED";
export const SETTING_PERTANYAAN_BIODATA_JAWABAN_LOADING_DONE =
  "SETTING_PERTANYAAN_BIODATA_JAWABAN_LOADING_DONE";
export const SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_ADD_LOADING =
  "SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_ADD_LOADING";
export const SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_ADD_LOADING_DONE =
  "SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_ADD_LOADING_DONE";
export const SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_ADD_ERROR =
  "SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_ADD_ERROR";
export const SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_ID =
  "SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_ID";
export const SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_DATA_LOADING =
  "SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_DATA_LOADING";
export const SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_DATA_LOADED =
  "SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_DATA_LOADED";
export const SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_DATA_LOADING_DONE =
  "SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_GET_DATA_LOADING_DONE";
export const SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_DELETE_LOADING =
  "SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_DELETE_LOADING";
export const SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_DELETE_LOADING_DONE =
  "SETTING_PERTANYAAN_BIODATA_JAWABAN_ACTION_DELETE_LOADING_DONE";
//* PERTANYAAN BIODATA_DESKRIPSI
export const SETTING_PERTANYAAN_BIODATA_DESKRIPSI_LOADING =
  "SETTING_PERTANYAAN_BIODATA_DESKRIPSI_LOADING";
export const SETTING_PERTANYAAN_BIODATA_DESKRIPSI_LOADED =
  "SETTING_PERTANYAAN_BIODATA_DESKRIPSI_LOADED";
export const SETTING_PERTANYAAN_BIODATA_DESKRIPSI_LOADING_DONE =
  "SETTING_PERTANYAAN_BIODATA_DESKRIPSI_LOADING_DONE";
export const SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_ADD_LOADING =
  "SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_ADD_LOADING";
export const SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_ADD_LOADING_DONE =
  "SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_ADD_LOADING_DONE";
export const SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_ADD_ERROR =
  "SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_ADD_ERROR";
export const SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_ID =
  "SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_ID";
export const SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_DATA_LOADING =
  "SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_DATA_LOADING";
export const SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_DATA_LOADED =
  "SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_DATA_LOADED";
export const SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_DATA_LOADING_DONE =
  "SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_GET_DATA_LOADING_DONE";
export const SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_DELETE_LOADING =
  "SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_DELETE_LOADING";
export const SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_DELETE_LOADING_DONE =
  "SETTING_PERTANYAAN_BIODATA_DESKRIPSI_ACTION_DELETE_LOADING_DONE";
//* SETTING-ACCOUNT
export const SETTING_ACCOUNT_LOADING = "SETTING_ACCOUNT_LOADING";
export const SETTING_ACCOUNT_LOADED = "SETTING_ACCOUNT_LOADED";
export const SETTING_ACCOUNT_LOADING_DONE = "SETTING_ACCOUNT_LOADING_DONE";
export const SETTING_ACCOUNT_DETAIL_LOADING = "SETTING_ACCOUNT_DETAIL_LOADING";
export const SETTING_ACCOUNT_DETAIL_LOADED = "SETTING_ACCOUNT_DETAIL_LOADED";
export const SETTING_ACCOUNT_DETAIL_LOADING_DONE =
  "SETTING_ACCOUNT_DETAIL_LOADING_DONE";
//* END-SETTING
//* MPP
export const MPP_USER_MPP_LOADING = "MPP_USER_MPP_LOADING";
export const MPP_USER_MPP_LOADED = "MPP_USER_MPP_LOADED";
export const MPP_USER_MPP_LOADING_DONE = "MPP_USER_MPP_LOADING_DONE";
export const MPP_ADD_LOADING = "MPP_ADD_LOADING";
export const MPP_ADD_LOADING_DONE = "MPP_ADD_LOADING_DONE";
export const MPP_ADD_ADDITIONAL_LOADING = "MPP_ADD_ADDITIONAL_LOADING";
export const MPP_ADD_ADDITIONAL_LOADING_DONE = "MPP_ADD_ADDITIONAL_LOADING_DONE";
export const MPP_ACTION_GET_ID = "MPP_ACTION_GET_ID";
export const MPP_ACTION_GET_DATA_LOADING = "MPP_ACTION_GET_DATA_LOADING";
export const MPP_ACTION_GET_DATA_LOADED = "MPP_ACTION_GET_DATA_LOADED";
export const MPP_ACTION_GET_DATA_LOADING_DONE =
  "MPP_ACTION_GET_DATA_LOADING_DONE";
export const MPP_GET_UNCONFIRM_LIST_LOADING = "MPP_GET_UNCONFIRM_LIST_LOADING";
export const MPP_GET_UNCONFIRM_LIST_LOADED = "MPP_GET_UNCONFIRM_LIST_LOADED";
export const MPP_GET_UNCONFIRM_LIST_LOADING_DONE =
  "MPP_GET_UNCONFIRM_LIST_LOADING_DONE";
export const MPP_GET_CONFIRM_LIST_LOADING = "MPP_GET_CONFIRM_LIST_LOADING";
export const MPP_GET_CONFIRM_LIST_LOADED = "MPP_GET_CONFIRM_LIST_LOADED";
export const MPP_GET_CONFIRM_LIST_LOADING_DONE =
  "MPP_GET_CONFIRM_LIST_LOADING_DONE";
export const MPP_KONFIRM_LOADING = "MPP_KONFIRM_LOADING";
export const MPP_KONFIRM_LOADING_DONE = "MPP_KONFIRM_LOADING_DONE";
//* MPPPR
export const MPP_GET_PR_LOADING = "MPP_GET_PR_LOADING";
export const MPP_GET_PR_LOADED = "MPP_GET_PR_LOADED";
export const MPP_GET_PR_LOADING_DONE = "MPP_GET_PR_LOADING_DONE";
//* MPPJOBDESK
export const MPP_GET_JOBDESK_LOADING = "MPP_GET_JOBDESK_LOADING";
export const MPP_GET_JOBDESK_LOADED = "MPP_GET_JOBDESK_LOADED";
export const MPP_GET_JOBDESK_LOADING_DONE = "MPP_GET_JOBDESK_LOADING_DONE";
//* PR
export const PR_USER_PR_LOADING = "PR_USER_PR_LOADING";
export const PR_USER_PR_LOADED = "PR_USER_PR_LOADED";
export const PR_USER_PR_LOADING_DONE = "PR_USER_PR_LOADING_DONE";
export const PR_ADD_LOADING = "PR_ADD_LOADING";
export const PR_ADD_LOADING_DONE = "PR_ADD_LOADING_DONE";
export const PR_ACTION_GET_ID = "PR_ACTION_GET_ID";
export const PR_ACTION_GET_DATA_LOADING = "PR_ACTION_GET_DATA_LOADING";
export const PR_ACTION_GET_DATA_LOADED = "PR_ACTION_GET_DATA_LOADED";
export const PR_ACTION_GET_DATA_LOADING_DONE =
  "PR_ACTION_GET_DATA_LOADING_DONE";
export const PR_GET_UNCONFIRM_LIST_LOADING = "PR_GET_UNCONFIRM_LIST_LOADING";
export const PR_GET_UNCONFIRM_LIST_LOADED = "PR_GET_UNCONFIRM_LIST_LOADED";
export const PR_GET_UNCONFIRM_LIST_LOADING_DONE =
  "PR_GET_UNCONFIRM_LIST_LOADING_DONE";
export const PR_GET_CONFIRM_LIST_LOADING = "PR_GET_CONFIRM_LIST_LOADING";
export const PR_GET_CONFIRM_LIST_LOADED = "PR_GET_CONFIRM_LIST_LOADED";
export const PR_GET_CONFIRM_LIST_LOADING_DONE =
  "PR_GET_CONFIRM_LIST_LOADING_DONE";
export const PR_KONFIRM_LOADING = "PR_KONFIRM_LOADING";
export const PR_KONFIRM_LOADING_DONE = "PR_KONFIRM_LOADING_DONE";
//* JOBDESK
export const JOBDESK_USER_JOBDESK_LOADING = "JOBDESK_USER_JOBDESK_LOADING";
export const JOBDESK_USER_JOBDESK_LOADED = "JOBDESK_USER_JOBDESK_LOADED";
export const JOBDESK_USER_JOBDESK_LOADING_DONE =
  "JOBDESK_USER_JOBDESK_LOADING_DONE";
export const JOBDESK_ADD_LOADING = "JOBDESK_ADD_LOADING";
export const JOBDESK_ADD_LOADING_DONE = "JOBDESK_ADD_LOADING_DONE";
export const JOBDESK_ACTION_GET_ID = "JOBDESK_ACTION_GET_ID";
export const JOBDESK_ACTION_GET_DATA_LOADING =
  "JOBDESK_ACTION_GET_DATA_LOADING";
export const JOBDESK_ACTION_GET_DATA_LOADED = "JOBDESK_ACTION_GET_DATA_LOADED";
export const JOBDESK_ACTION_GET_DATA_LOADING_DONE =
  "JOBDESK_ACTION_GET_DATA_LOADING_DONE";
export const JOBDESK_GET_PERTANYAAN_LOADING = "JOBDESK_GET_PERTANYAAN_LOADING";
export const JOBDESK_GET_PERTANYAAN_LOADED = "JOBDESK_GET_PERTANYAAN_LOADED";
export const JOBDESK_GET_PERTANYAAN_LOADING_DONE =
  "JOBDESK_GET_PERTANYAAN_LOADING_DONE";
export const JOBDESK_GET_MASTER_USER_LOADING =
  "JOBDESK_GET_MASTER_USER_LOADING";
export const JOBDESK_GET_MASTER_USER_LOADED = "JOBDESK_GET_MASTER_USER_LOADED";
export const JOBDESK_GET_MASTER_USER_LOADING_DONE =
  "JOBDESK_GET_MASTER_USER_LOADING_DONE";
//* END-MPP
//* REPORT
export const REPORT_MPP_LOADING = "REPORT_MPP_LOADING";
export const REPORT_MPP_LOADED = "REPORT_MPP_LOADED";
export const REPORT_MPP_LOADING_DONE = "REPORT_MPP_LOADING_DONE";
export const REPORT_MPP_ITEM_LOADING = "REPORT_MPP_ITEM_LOADING";
export const REPORT_MPP_ITEM_LOADED = "REPORT_MPP_ITEM_LOADED";
export const REPORT_MPP_ITEM_LOADING_DONE = "REPORT_MPP_ITEM_LOADING_DONE ";
export const REPORT_MPP_REALIZATION_LOADING = "REPORT_MPP_REALIZATION_LOADING";
export const REPORT_MPP_REALIZATION_LOADED = "REPORT_MPP_REALIZATION_LOADED";
export const REPORT_MPP_REALIZATION_LOADING_DONE =
  "REPORT_MPP_REALIZATION_LOADING_DONE";
export const REPORT_JOBDESC_LOADING = "REPORT_JOBDESC_LOADING";
export const REPORT_JOBDESC_LOADED = "REPORT_JOBDESC_LOADED";
export const REPORT_JOBDESC_LOADING_DONE = "REPORT_JOBDESC_LOADING_DONE";
export const REPORT_PR_LOADING = "REPORT_PR_LOADING";
export const REPORT_PR_LOADED = "REPORT_PR_LOADED";
export const REPORT_PR_LOADING_DONE = "REPORT_PR_LOADING_DONE";
export const REPORT_PR_REALIZATION_LOADING = "REPORT_PR_REALIZATION_LOADING";
export const REPORT_PR_REALIZATION_LOADED = "REPORT_PR_REALIZATION_LOADED";
export const REPORT_PR_REALIZATION_LOADING_DONE =
  "REPORT_PR_REALIZATION_LOADING_DONE";
export const REPORT_MPP_DONE_LOADING = "REPORT_MPP_DONE_LOADING";
export const REPORT_MPP_DONE_LOADED = "REPORT_MPP_DONE_LOADED";
export const REPORT_MPP_DONE_LOADING_DONE = "REPORT_MPP_DONE_LOADING_DONE";
export const REPORT_MPP_DETAILED_LOADING = "REPORT_MPP_DETAILED_LOADING";
export const REPORT_MPP_DETAILED_LOADED = "REPORT_MPP_DETAILED_LOADED";
export const REPORT_MPP_DETAILED_LOADING_DONE =
  "REPORT_MPP_DETAILED_LOADING_DONE";
export const REPORT_HR_LOADING = "REPORT_HR_LOADING";
export const REPORT_HR_LOADED = "REPORT_HR_LOADED";
export const REPORT_HR_LOADING_DONE = "REPORT_HR_LOADING_DONE";
export const REPORT_DEPARTMENT_LOADING = "REPORT_DEPARTMENT_LOADING";
export const REPORT_DEPARTMENT_LOADED = "REPORT_DEPARTMENT_LOADED";
export const REPORT_DEPARTMENT_LOADING_DONE = "REPORT_DEPARTMENT_LOADING_DONE";
//* END-REPORT
