import {
    ////// Jobdesk
    JOBDESK_USER_JOBDESK_LOADING,
    JOBDESK_USER_JOBDESK_LOADED,
    JOBDESK_USER_JOBDESK_LOADING_DONE,
    JOBDESK_ADD_LOADING,
    JOBDESK_ADD_LOADING_DONE,
    JOBDESK_ACTION_GET_ID,
    JOBDESK_ACTION_GET_DATA_LOADING,
    JOBDESK_ACTION_GET_DATA_LOADED,
    JOBDESK_ACTION_GET_DATA_LOADING_DONE,
    JOBDESK_GET_PERTANYAAN_LOADING,
    JOBDESK_GET_PERTANYAAN_LOADED,
    JOBDESK_GET_PERTANYAAN_LOADING_DONE,
    JOBDESK_GET_MASTER_USER_LOADING,
    JOBDESK_GET_MASTER_USER_LOADED,
    JOBDESK_GET_MASTER_USER_LOADING_DONE,
} from '../Actions/Type.Action'

const initialState = {
    ////// Jobdesk
    isJobdeskUserJobdeskLoading: false,
    JobdeskUserMP: [],
    isJobdeskAddLoading: false,
    JobdeskActionId: null,
    isJobdeskActionGetDataLoading: false,
    JobdeskData: null,
    JobdeskListPrilaku: [],
    JobdeskListTeknik: [],
    isJobdeskGetPertanyaanLoading: false,
    JobdeskGetPertanyaanPerilaku: [],
    JobdeskGetPertanyaanTeknik: [],
    isJobdeskGetMasterUserLoading: false,
    JobdeskGetMasterUser: [],
}

// eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        ////// Jobdesk
        case JOBDESK_USER_JOBDESK_LOADING:
            return {
                ...state,
                isJobdeskUserJobdeskLoading: true,
            }
        case JOBDESK_USER_JOBDESK_LOADED:
            return {
                ...state,
                JobdeskUserMP: action.payload
            }
        case JOBDESK_USER_JOBDESK_LOADING_DONE:
            return {
                ...state,
                isJobdeskUserJobdeskLoading: false,
            }
        case JOBDESK_ADD_LOADING:
            return {
                ...state,
                isJobdeskAddLoading: true
            }
        case JOBDESK_ADD_LOADING_DONE:
            return {
                ...state,
                isJobdeskAddLoading: false
            }
        case JOBDESK_ACTION_GET_ID:
            return {
                ...state,
                JobdeskActionId: action.payload
            }
        case JOBDESK_ACTION_GET_DATA_LOADING:
            return {
                ...state,
                isJobdeskActionGetDataLoading: true
            }
        case JOBDESK_ACTION_GET_DATA_LOADED:
            return {
                ...state,
                JobdeskData: action.payload.Detail,
                JobdeskListPrilaku: action.payload.JobdeskListPrilaku,
                JobdeskListTeknik: action.payload.JobdeskListTeknik,
            }
        case JOBDESK_ACTION_GET_DATA_LOADING_DONE:
            return {
                ...state,
                isJobdeskActionGetDataLoading: false
            }
        case JOBDESK_GET_PERTANYAAN_LOADING:
            return {
                ...state,
                isJobdeskGetPertanyaanLoading: true,
            }
        case JOBDESK_GET_PERTANYAAN_LOADED:
            return {
                ...state,
                JobdeskGetPertanyaanPerilaku: action.payload.Perilaku,
                JobdeskGetPertanyaanTeknik: action.payload.Teknik,
            }
        case JOBDESK_GET_PERTANYAAN_LOADING_DONE:
            return {
                ...state,
                isJobdeskGetPertanyaanLoading: false,
            }
        case JOBDESK_GET_MASTER_USER_LOADING:
            return {
                ...state,
                isJobdeskGetMasterUserLoading: true,
            }
        case JOBDESK_GET_MASTER_USER_LOADED:
            return {
                ...state,
                JobdeskGetMasterUser: action.payload,
            }
        case JOBDESK_GET_MASTER_USER_LOADING_DONE:
            return {
                ...state,
                isJobdeskGetMasterUserLoading: false,
            }
        default:
            return state
    }
}