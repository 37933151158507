import React, { Fragment } from "react"

import { connect } from 'react-redux'

import { Stepper, Step, StepLabel, Button, Paper, Skeleton } from '@mui/material'

import CreateAccount from '../Auth/CreateAccount'
// import Login from '../Auth/Login'
import Confirmation from '../Auth/Confirmation'
import FillingBiodata from '../Account/FillingBiodata'
import Profile from '../Account/Profile'
import Daftar from '../Daftar'
// import Other from '../Job/Other'

class DaftarKerja extends React.Component {
    state = {
        StepValue: 0,
        MaxStep: 5
    }
    componentDidMount() {
        const { User } = this.props

        const isActiveted = User?.isActiveted ? true : false
        const isBiodataComplete = User?.isBiodataComplete ? true : false

        this.setState({
            StepValue: User ?
                !isActiveted ? 1
                    : !isBiodataComplete ? 2
                        : 3
                : 0
        })
    }

    componentDidUpdate(prevProps) {
        // if (this.props.User !== prevProps.User) {
        //     const { User } = this.props

        //     const isActiveted = User?.isActiveted ? true : false
        //     const isBiodataComplete = User?.isBiodataComplete ? true : false

        //     this.setState({ StepValue: isActiveted ? isBiodataComplete ? 3 : 2 : 1 })
        // }
        if (this.props.OSCreateAccount !== prevProps.OSCreateAccount) {
            this.setState({ StepValue: 1 })
        }
        if (this.props.OSConfirmationAccount !== prevProps.OSConfirmationAccount) {
            this.setState({ StepValue: 2 })
        }
        if (this.props.OSFilingBiodata !== prevProps.OSFilingBiodata) {
            this.setState({ StepValue: 3 })
        }
    }

    handleNext = () => {
        if (this.state === this.state.MaxStep - 1) {
            this.setState({ StepValue: this.state.MaxStep - 1 })
        } else {
            this.setState({ StepValue: this.state.StepValue + 1 })
        }
    }

    handleBack = () => {
        if (this.state === 0) {
            this.setState({ StepValue: 0 })
        } else {
            this.setState({ StepValue: this.state.StepValue - 1 })
        }
    }

    render() {

        const steps = ['Create Account', 'Account Confirmation', 'Filling Biodata', 'Biodata Confirm', 'Requirement']

        const {
            StepValue
        } = this.state

        const isMobileView = this.props.isMobileView ? this.props.isMobileView : false
        const URLId = this.props.URLId

        const isUserLoading = this.props.isUserLoading
        const User = this.props.User
        const isActiveted = User?.isActiveted ? true : false
        const isBiodataComplete = User?.isBiodataComplete ? true : false
        const UserEmail = User?.Email ? User.Email : ''

        return (isUserLoading) ? (
            <Fragment>
                {
                    [0, 1, 2, 3, 4, 6, 7, 8, 9].map((item) => (
                        <Skeleton
                            variant='string'
                            height={'5vh'}
                            style={{ margin: '1% 0 1% 0' }}
                        />
                    ))
                }
            </Fragment>
        ) : (
            <Fragment>
                <Stepper
                    activeStep={StepValue}
                    orientation={isMobileView ? "vertical" : "horizontal"}
                >
                    {steps.map((label, index) => {
                        const stepProps = {}
                        const labelProps = {}
                        return (
                            <Step
                                key={label}
                                {...stepProps}
                            >
                                <StepLabel
                                    {...labelProps}
                                >
                                    {label}
                                </StepLabel>
                            </Step>
                        )
                    })}
                </Stepper>

                <Paper
                    style={{ padding: '5%' }}
                >

                    {StepValue === 0 ?
                        <CreateAccount isOneStep={true} isMobileView={isMobileView} />
                        : StepValue === 1 ?
                            <Fragment>
                                {User ?
                                    <Confirmation isOneStep={true} Email={UserEmail} />
                                    : <Fragment></Fragment>
                                }
                            </Fragment>
                            : StepValue === 2 ?
                                <FillingBiodata isOneStep={true} />
                                : StepValue === 3 ?
                                    <Profile DisableEdit={true} isMobileView={isMobileView} />
                                    : StepValue === 4 ?
                                        <Daftar URLId={URLId} isMobileView={isMobileView} />
                                        : <Fragment></Fragment>
                    }
                </Paper>
                <center>
                    <Button
                        variant='contained'
                        size='large'
                        color="error"
                        disabled={StepValue === 0 ? true
                            : !isActiveted && StepValue <= 1 ? true
                                : !isBiodataComplete && StepValue <= 3 ? true
                                    : isBiodataComplete && StepValue < 4 ? true
                                        : false}
                        onClick={this.handleBack}
                        style={{ margin: '5% 10% 5% 10%', width: '10vw', fontSize: isMobileView ? '16px' : '1.2vw' }}
                    >
                        Back
                    </Button>
                    <Button
                        variant='contained'
                        size='large'
                        disabled={StepValue === this.state.MaxStep - 1 ? true
                            : !User && StepValue >= 0 ? true
                                : !isActiveted && StepValue >= 1 ? true
                                    : (StepValue === 2) || (!isBiodataComplete && StepValue >= 3) ? true
                                        : false
                        }
                        onClick={this.handleNext}
                        style={{ margin: '5% 10% 5% 10%', width: '10vw', fontSize: isMobileView ? '16px' : '1.2vw' }}
                    >
                        Next
                    </Button>
                </center>

            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isUserLoading: state.Auth.isUserLoading,
    User: state.Auth.User,
    OSCreateAccount: state.Messages.CreateAccount,
    OSConfirmationAccount: state.Messages.ConfirmationAccount,
    OSFilingBiodata: state.Messages.FilingBiodata,
})

export default connect(mapStateToProps, {})(DaftarKerja)

