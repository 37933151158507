import React, { Fragment } from "react"

const NoRecord = ({ MessageCode, MessageTitle, Messages }) => {
    const initialMessageTitle = "There is no data record"
    const initialMessages = `Data not found. Please add data or there is no data that can be displayed yet`
    return (
        <Fragment>
            <center>
                <h2>{MessageTitle ? MessageTitle : initialMessageTitle}</h2>
                <h3>{Messages ? Messages : initialMessages}</h3>
            </center>
        </Fragment>
    )
}

export default NoRecord