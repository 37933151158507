import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Items_Data, Delete_Items } from '../../../../../../Store/Actions/Setting.Action'

import { FormGroup, FormControlLabel, Checkbox, Button, CircularProgress } from '@mui/material'

import Detail from '../../../../../Containers/Dashboard/Setting/Items/Detail'

class SettingItemsDelete extends React.Component {
    state = {
        isConfirm: false,
    }
    componentDidUpdate(prevProps) {
        if (this.props.ItemsActionId !== prevProps.ItemsActionId) {
            this.props.get_Items_Data(this.props.ItemsActionId)
        }
    }
    componentDidMount() {
        const { ItemsActionId } = this.props
        if (ItemsActionId !== null) {
            this.props.get_Items_Data(ItemsActionId)
        }
    }

    OnChangeCheckbox = E => {
        this.setState({ [E.target.name]: !this.state[E.target.name] })
    }

    onClickDelete(Id) {
        this.props.Delete_Items(Id)
    }
    render() {
        const ItemsActionId = this.props.ItemsActionId
        const isItemsActionGetDetailLoading = this.props.isItemsActionGetDetailLoading
        const ItemsDetail = this.props.ItemsDetail
        const {
            isConfirm
        } = this.state
        const isItemsDeleteLoading = this.props.isItemsDeleteLoading
        return (
            <Fragment>
                <Detail Data={ItemsDetail} isLoading={isItemsActionGetDetailLoading} />
                <div
                    style={{ margin: '1%' }}
                >
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox color='primary' onChange={this.OnChangeCheckbox} name="isConfirm" value={isConfirm} disabled={isItemsDeleteLoading ? true : false} />}
                            label="Saya menyadari akan resikonya dan saya tau apa yang saya lakukan"
                        />
                    </FormGroup>
                    <center>
                        <Button
                            variant='contained'
                            size='large'
                            color='error'
                            disabled={!isConfirm || isItemsDeleteLoading ? true : false}
                            onClick={() => this.onClickDelete(ItemsActionId)}
                        >
                            {isItemsDeleteLoading ? <CircularProgress /> : 'Delete'}
                        </Button>
                    </center>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isItemsActionGetDetailLoading: state.Setting.isItemsActionGetDetailLoading,
    isItemsDeleteLoading: state.Setting.isItemsDeleteLoading,
    ItemsActionId: state.Setting.ItemsActionId,
    ItemsDetail: state.Setting.ItemsDetail,
})

export default connect(mapStateToProps, { get_Items_Data, Delete_Items })(SettingItemsDelete)
