import React, { Fragment } from "react";

import { connect } from "react-redux";

import {
  get_List_PreBoarding,
  get_Job_Action_Id,
} from "../../../../Store/Actions/Job.Action";
import { get_Account_Action_Id } from "../../../../Store/Actions/Account.Action";

import { Collapse } from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import GenericModals from "../../../Containers/GenericModals";
import CoTable from "../../../Containers/Table";
import MoPreBoarding from "./Modal/PReboarding/PreBoarding";
import Submit from "./Modal/PReboarding/Submit";
import MoDetail from "./Modal/PReboarding/MoDetail";
// import Detail from './Modal/PreBoarding/Detail'

class PreBoarding extends React.Component {
  state = {
    MenungguOpen: true,
    SelesaiOpen: false,
  };

  componentDidMount() {
    this.props.get_List_PreBoarding(this.props.User.Email);
  }

  handleMenungguOpen = () => {
    this.setState({ MenungguOpen: !this.state.MenungguOpen });
  };

  handleSelesaiOpen = () => {
    this.setState({ SelesaiOpen: !this.state.SelesaiOpen });
  };

  onClickActionId = (JobId, AccountId) => {
    this.props.get_Job_Action_Id(JobId);
    this.props.get_Account_Action_Id(AccountId);
  };

  render() {
    const isListPreBoardingLoading = this.props.isListPreBoardingLoading;
    const ListPreBoarding = this.props.ListPreBoarding;

    const HeadData = [
      {
        Nama: "No",
        width: "5%",
      },
      {
        Nama: "No Registrasi",
        width: "10%",
      },
      {
        Nama: "Nama",
        width: "10%",
      },
      {
        Nama: "Posisi",
        width: "10%",
      },
      {
        Nama: "Submit",
        width: "10%",
      },
      {
        Nama: "Detail",
        width: "10%",
      },
    ];

    const FilteredList = ListPreBoarding
      ? ListPreBoarding.filter((item, index) => item.PBId === null)
      : [];

    const BodyData = FilteredList.map((item, index) => {
      return {
        index: index + 1,
        Noreg: item.Id,
        Nama: item.BiodataNama,
        Posisi: item.JobListNama,
        Submit: (
          <GenericModals
            size="m"
            Buttonvariant="contained"
            header="Submit Data PreBoarding"
            body={<Submit />}
            Buttoncolor="primary"
            ButtononClickeven={() =>
              this.onClickActionId(item.JobListId, item.AccountId)
            }
            Buttonlabel={"Submit"}
          />
        ),
        Detail: (
          <GenericModals
            size="m"
            Buttonvariant="contained"
            header="Detail Preboarding"
            body={<MoDetail />}
            Buttoncolor="primary"
            ButtononClickeven={() =>
              this.onClickActionId(item.JobListId, item.AccountId)
            }
            Buttonlabel={"Detail"}
          />
        ),
      };
    });

    const HeadData2 = [
      {
        Nama: "No",
        width: "5%",
      },
      {
        Nama: "No Registrasi",
        width: "10%",
      },
      {
        Nama: "Nama",
        width: "10%",
      },
      {
        Nama: "Posisi",
        width: "10%",
      },
      {
        Nama: "Status",
        width: "10%",
      },
      {
        Nama: "Submit",
        width: "10%",
      },
    ];

    const FilteredList2 = ListPreBoarding
      ? ListPreBoarding.filter((item, index) => item.PBId !== null)
      : [];

    const BodyData2 = FilteredList2.map((item, index) => {
      return {
        index: index + 1,
        Noreg: item.Id,
        Nama: item.BiodataNama,
        Posisi: item.JobListNama,
        Status: (
          <span
            style={{
              color: item.GDStatus
                ? item.GDStatus === "Selesai"
                  ? "green"
                  : "orange"
                : "black",
            }}
          >
            {item.GDStatus}
          </span>
        ),
        Detail: (
          <GenericModals
            size="l"
            header="Detail Data PreBoarding"
            body={<MoPreBoarding />}
            Buttoncolor="primary"
            ButtononClickeven={() =>
              this.onClickActionId(item.JobListId, item.AccountId)
            }
            Buttonlabel={"Detail"}
          />
        ),
      };
    });

    const { MenungguOpen, SelesaiOpen } = this.state;

    const RowDataInOnePage = 5;
    return (
      <Fragment>
        <h2>PreBoarding</h2>
        <h3
          onClick={this.handleMenungguOpen}
          style={{
            display: "flex",
            alignContent: "center",
            verticalAlign: "center",
            cursor: "pointer",
          }}
        >
          Menunggu {MenungguOpen ? <ExpandLess /> : <ExpandMore />}
        </h3>
        <Collapse in={MenungguOpen} timeout="auto" unmountOnExit>
          <CoTable
            TableId={"PreBoarding_Menunggu"}
            HeadData={HeadData}
            Data={BodyData}
            isHeadLoading={isListPreBoardingLoading}
            isBodyLoading={isListPreBoardingLoading}
            isPagination={BodyData.length > RowDataInOnePage ? true : false}
            MaxRowOnPage={RowDataInOnePage}
            isPaginationLoading={isListPreBoardingLoading}
            PaginationCounter={
              BodyData.length / RowDataInOnePage >
              Math.floor(BodyData.length / RowDataInOnePage)
                ? Math.floor(BodyData.length / RowDataInOnePage) + 1
                : BodyData.length / RowDataInOnePage
            }
          />
        </Collapse>
        <div style={{ margin: "0 0 5% 0" }}>
          <h3
            onClick={this.handleSelesaiOpen}
            style={{
              display: "flex",
              alignContent: "center",
              verticalAlign: "center",
              cursor: "pointer",
            }}
          >
            Selesai{SelesaiOpen ? <ExpandLess /> : <ExpandMore />}
          </h3>
          <Collapse in={SelesaiOpen} timeout="auto" unmountOnExit>
            <CoTable
              TableId={"PreBoarding_Selesai"}
              HeadData={HeadData2}
              Data={BodyData2}
              isHeadLoading={isListPreBoardingLoading}
              isBodyLoading={isListPreBoardingLoading}
              isPagination={BodyData2.length > RowDataInOnePage ? true : false}
              MaxRowOnPage={RowDataInOnePage}
              isPaginationLoading={isListPreBoardingLoading}
              PaginationCounter={
                BodyData2.length / RowDataInOnePage >
                Math.floor(BodyData2.length / RowDataInOnePage)
                  ? Math.floor(BodyData2.length / RowDataInOnePage) + 1
                  : BodyData2.length / RowDataInOnePage
              }
            />
          </Collapse>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isListPreBoardingLoading: state.Job.isListPreBoardingLoading,
  ListPreBoarding: state.Job.ListPreBoarding,
  User: state.Auth.User,
});

export default connect(mapStateToProps, {
  get_List_PreBoarding,
  get_Job_Action_Id,
  get_Account_Action_Id,
})(PreBoarding);
