import {
    ////// MPP
    MPP_USER_MPP_LOADING,
    MPP_USER_MPP_LOADED,
    MPP_USER_MPP_LOADING_DONE,

    MPP_ADD_LOADING,
    MPP_ADD_LOADING_DONE,

    MPP_ADD_ADDITIONAL_LOADING,
    MPP_ADD_ADDITIONAL_LOADING_DONE,

    MPP_ACTION_GET_ID,
    MPP_ACTION_GET_DATA_LOADING,
    MPP_ACTION_GET_DATA_LOADED,
    MPP_ACTION_GET_DATA_LOADING_DONE,
    MPP_GET_UNCONFIRM_LIST_LOADING,
    MPP_GET_UNCONFIRM_LIST_LOADED,
    MPP_GET_UNCONFIRM_LIST_LOADING_DONE,
    MPP_GET_CONFIRM_LIST_LOADING,
    MPP_GET_CONFIRM_LIST_LOADED,
    MPP_GET_CONFIRM_LIST_LOADING_DONE,
    MPP_KONFIRM_LOADING,
    MPP_KONFIRM_LOADING_DONE,
    ////// MPPPR
    MPP_GET_PR_LOADING,
    MPP_GET_PR_LOADED,
    MPP_GET_PR_LOADING_DONE,
    ////// MPPJOBDESK
    MPP_GET_JOBDESK_LOADING,
    MPP_GET_JOBDESK_LOADED,
    MPP_GET_JOBDESK_LOADING_DONE,
    ////// MPPVIEW
    MPP_GET_VIEW_ALL_LOADING,
    MPP_GET_VIEW_ALL_LOADED,
    MPP_GET_VIEW_ALL_LOADING_DONE,
    MPP_GET_VIEW_IMPLEMENTASI_LOADING,
    MPP_GET_VIEW_IMPLEMENTASI_LOADED,
    MPP_GET_VIEW_IMPLEMENTASI_LOADING_DONE,
    MPP_GET_VIEW_ASLI_LOADING,
    MPP_GET_VIEW_ASLI_LOADED,
    MPP_GET_VIEW_ASLI_LOADING_DONE,
    MPP_GET_VIEW_LAINNYA_LOADING,
    MPP_GET_VIEW_LAINNYA_LOADED,
    MPP_GET_VIEW_LAINNYA_LOADING_DONE,
} from '../Actions/Type.Action'

const initialState = {
    ////// MPP
    isMPPUserMPPLoading: false,
    MPPUserMP: [],
    isMppAddLoading: false,
    MPPActionId: null,
    isMPPActionGetDataLoading: false,
    MPPData: null,
    MPPList: [],
    MPPListItem: [],
    isMPPGetUnConfirmLoading: false,
    MPPUnConfirm: [],
    isMPPGetConfirmLoading: false,
    MPPConfirm: [],
    isMPPKonfirmasiLoading: false,
    ////// MPPPR
    isMPPPRLoading: false,
    MPPPR: [],
    ////// MPPJOBDESK
    isMPPJobdeskLoading: false,
    MPPJobdesk: [],
    ////// MPPVIEW
    isMPPViewAllLoading: false,
    MPPViewAll: [],
    isMPPViewImplementasiLoading: false,
    MPPViewImplementasi: [],
    isMPPViewAsliLoading: false,
    MPPViewAsli: [],
    isMPPViewLainnyaLoading: false,
    MPPViewLainnya: [],
}

// eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        ////// MPP
        case MPP_USER_MPP_LOADING:
            return {
                ...state,
                isMPPUserMPPLoading: true,
            }
        case MPP_USER_MPP_LOADED:
            return {
                ...state,
                MPPUserMP: action.payload
            }
        case MPP_USER_MPP_LOADING_DONE:
            return {
                ...state,
                isMPPUserMPPLoading: false,
            }
        case MPP_ADD_LOADING:
            return {
                ...state,
                isMppAddLoading: true
            }
        case MPP_ADD_LOADING_DONE:
            return {
                ...state,
                isMppAddLoading: false
            }
        case MPP_ADD_ADDITIONAL_LOADING:
            return {
                ...state,
                isMppAddLoading: true
            }
        case MPP_ADD_ADDITIONAL_LOADING_DONE:
            return {
                ...state,
                isMppAddLoading: false
            }
        case MPP_ACTION_GET_ID:
            return {
                ...state,
                MPPActionId: action.payload
            }
        case MPP_ACTION_GET_DATA_LOADING:
            return {
                ...state,
                isMPPActionGetDataLoading: true
            }
        case MPP_ACTION_GET_DATA_LOADED:
            return {
                ...state,
                MPPData: action.payload.Detail,
                MPPList: action.payload.MPPList,
                MPPListItem: action.payload.MPPListItem,
            }
        case MPP_ACTION_GET_DATA_LOADING_DONE:
            return {
                ...state,
                isMPPActionGetDataLoading: false
            }
        case MPP_GET_UNCONFIRM_LIST_LOADING:
            return {
                ...state,
                isMPPGetUnConfirmLoading: true
            }
        case MPP_GET_UNCONFIRM_LIST_LOADED:
            return {
                ...state,
                MPPUnConfirm: action.payload
            }
        case MPP_GET_UNCONFIRM_LIST_LOADING_DONE:
            return {
                ...state,
                isMPPGetUnConfirmLoading: false
            }
        case MPP_GET_CONFIRM_LIST_LOADING:
            return {
                ...state,
                isMPPGetConfirmLoading: true
            }
        case MPP_GET_CONFIRM_LIST_LOADED:
            return {
                ...state,
                MPPConfirm: action.payload
            }
        case MPP_GET_CONFIRM_LIST_LOADING_DONE:
            return {
                ...state,
                isMPPGetConfirmLoading: false
            }
        case MPP_KONFIRM_LOADING:
            return {
                ...state,
                isMPPKonfirmasiLoading: true,
            }
        case MPP_KONFIRM_LOADING_DONE:
            return {
                ...state,
                isMPPKonfirmasiLoading: false,
            }
        ////// MPPPR
        case MPP_GET_PR_LOADING:
            return {
                ...state,
                isMPPPRLoading: true,
            }
        case MPP_GET_PR_LOADED:
            return {
                ...state,
                MPPPR: action.payload
            }
        case MPP_GET_PR_LOADING_DONE:
            return {
                ...state,
                isMPPPRLoading: false,
            }
        ////// MPPJOBDESK
        case MPP_GET_JOBDESK_LOADING:
            return {
                ...state,
                isMPPJobdeskLoading: true,
            }
        case MPP_GET_JOBDESK_LOADED:
            return {
                ...state,
                MPPJobdesk: action.payload
            }
        case MPP_GET_JOBDESK_LOADING_DONE:
            return {
                ...state,
                isMPPJobdeskLoading: false,
            }
        ////// MPPVIEW
        case MPP_GET_VIEW_ALL_LOADING:
            return {
                ...state,
                isMPPViewAllLoading: true,
            }
        case MPP_GET_VIEW_ALL_LOADED:
            return {
                ...state,
                MPPViewAll: action.payload
            }
        case MPP_GET_VIEW_ALL_LOADING_DONE:
            return {
                ...state,
                isMPPViewAllLoading: false,
            }
        case MPP_GET_VIEW_IMPLEMENTASI_LOADING:
            return {
                ...state,
                isMPPViewImplementasiLoading: true,
            }
        case MPP_GET_VIEW_IMPLEMENTASI_LOADED:
            return {
                ...state,
                MPPViewImplementasi: action.payload
            }
        case MPP_GET_VIEW_IMPLEMENTASI_LOADING_DONE:
            return {
                ...state,
                isMPPViewImplementasiLoading: false,
            }
        case MPP_GET_VIEW_ASLI_LOADING:
            return {
                ...state,
                isMPPViewAsliLoading: true,
            }
        case MPP_GET_VIEW_ASLI_LOADED:
            return {
                ...state,
                MPPViewAsli: action.payload
            }
        case MPP_GET_VIEW_ASLI_LOADING_DONE:
            return {
                ...state,
                isMPPViewAsliLoading: false,
            }
        case MPP_GET_VIEW_LAINNYA_LOADING:
            return {
                ...state,
                isMPPViewLainnyaLoading: true,
            }
        case MPP_GET_VIEW_LAINNYA_LOADED:
            return {
                ...state,
                MPPViewLainnya: action.payload
            }
        case MPP_GET_VIEW_LAINNYA_LOADING_DONE:
            return {
                ...state,
                isMPPViewLainnyaLoading: false,
            }
        default:
            return state
    }
}