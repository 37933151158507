import React, { Fragment } from "react"

import useMediaQuery from '@mui/material/useMediaQuery'
import { InitState } from '../../../Style'

import FullJobDetail from './JobDetail/FullJobDetail'
import HalfJobDetail from './JobDetail/HalfJobDetail'

const JobList = () => {
    const MinWindowFirst = useMediaQuery(`(min-width:${InitState.Units.minWidth_first}px)`)
    return (
        <Fragment>
            {MinWindowFirst ?
                <Fragment>
                    <FullJobDetail />
                </Fragment>
                : <Fragment>
                    <HalfJobDetail />
                </Fragment>}
        </Fragment>
    )
}

export default JobList