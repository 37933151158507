import React, { Fragment } from 'react'

import { Modal, CircularProgress } from '@mui/material'

const LoadingModal = ({ isModalOpen, ...props }) => {
    return (
        <Fragment>
            <Modal
                open={isModalOpen ? isModalOpen : false}
                style={{
                    border: '0px solid red'
                }}
            >
                <div
                    style={{ border: '0px solid red', width: '100vw', height: '100vh', position: 'fixed', padding: '16% 0 10% 0', }}
                >
                    <center>
                        <CircularProgress
                            size={200}
                        />
                    </center>
                </div>
            </Modal>
        </Fragment >
    )
}

export default LoadingModal