import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_List_Pendaftar, get_PreRegisterData } from '../../../../../../../Store/Actions/Job.Action'
import { get_Account_Action_Id } from '../../../../../../../Store/Actions/Account.Action'

import { Skeleton, Collapse, TextField, FormControl, InputLabel, Select, InputAdornment, Button, Paper } from '@mui/material'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import SearchIcon from '@mui/icons-material/Search'
import RestartAltIcon from '@mui/icons-material/RestartAlt'

import GenericModals from '../../../../../../Containers/GenericModals'
import CoTable from '../../../../../../Containers/Table'
import DetailDiajukan from './Modal/DetailDiajukan'
import DetailPendaftar from './Modal/PendaftarKonfirmasi'
import LoadingModal from '../../../../../../Containers/LoadingModal'

class ListPendaftar extends React.Component {
    state = {
        isDiajukanOpen: false,
        isSearchOpen: false,
        isShortList: false,
        isPendaftarOpen: true,
        Search: '',
        Nama: '',
        Registrasi: '',
        UmurMin: '',
        UmurMax: '',
        Pendidikan: '',
        Instansi: '',
        Jurusan: '',
        MinPengalaman: 0,
        ListPendaftar: [],
        isSearchLoading: false,
        PreRegisterDataSkill: [],
        NoticedPeriod: '',
    }

    componentDidUpdate(prevProps) {
        if (this.props.JobActionId !== prevProps.JobActionId) {
            this.props.get_List_Pendaftar(this.props.JobActionId)
        }
        if (this.props.ListPendaftar !== prevProps.ListPendaftar) {
            const { ListPendaftar } = this.props
            this.setState({ ListPendaftar: ListPendaftar })
        }

        if (this.props.PreRegisterData !== prevProps.PreRegisterData) {
            const { PreRegisterData } = this.props
            const PreRegisterDataSkill = PreRegisterData?.PRSkill ? PreRegisterData.PRSkill : []

            const newPreRegisterDataSkill = []
            PreRegisterDataSkill.map((item, index) => newPreRegisterDataSkill.push({
                Skill: item.Skill,
                Answer: ''
            }))
            this.setState({ PreRegisterDataSkill: newPreRegisterDataSkill })
        }
    }
    componentDidMount() {
        const { JobActionId } = this.props
        if (JobActionId !== null) {
            this.props.get_List_Pendaftar(this.props.JobActionId)
            this.props.get_PreRegisterData(this.props.JobActionId)
        }
    }

    onClickActionId = (Id) => {
        this.props.get_Account_Action_Id(Id)
    }

    handleDiajukanOpen = () => {
        this.setState({ isDiajukanOpen: !this.state.isDiajukanOpen })
    }
    handleSearchOpen = () => {
        this.setState({ isSearchOpen: !this.state.isSearchOpen })
    }
    handleShortList = () => {
        this.setState({ isShortList: !this.state.isShortList })
    }
    handlePendaftarOpen = () => {
        this.setState({ isPendaftarOpen: !this.state.isPendaftarOpen })
    }
    onChange_Search = (e) => {
        this.setState({ Search: e.target.value })
        const { ListPendaftar } = this.props
        const newListPendaftar = ListPendaftar.filter((item) => String(item.Nama).toLocaleLowerCase().search(String(e.target.value).toLocaleLowerCase()) >= 0 ? true : false)
        this.setState({ ListPendaftar: newListPendaftar })
    }
    SearchData = (e) => {
        this.setState({ isSearchLoading: true })
        const { ListPendaftar } = this.props
        const { Nama, Registrasi, UmurMin, UmurMax, Pendidikan, Instansi, Jurusan, MinPengalaman, PreRegisterDataSkill, NoticedPeriod } = this.state

        const ListPendaftarNama = Nama ? ListPendaftar.filter((item) => String(item.Nama).toLocaleLowerCase().search(String(Nama).toLocaleLowerCase()) >= 0 ? true : false) : ListPendaftar
        const ListPendaftarRegistrasi = Registrasi ? ListPendaftar.filter((item) => String(item.NoRegistrasi).toLocaleLowerCase().search(String(Registrasi).toLocaleLowerCase()) >= 0 ? true : false) : ListPendaftar
        const ListPendaftarUmurMin = UmurMin ? ListPendaftar.filter((item) => new Date().getFullYear() - new Date(item.TanggalLahir).getFullYear() >= UmurMin ? true : false) : ListPendaftar
        const ListPendaftarUmurMax = UmurMax ? ListPendaftar.filter((item) => new Date().getFullYear() - new Date(item.TanggalLahir).getFullYear() <= UmurMax ? true : false) : ListPendaftar
        const ListPendaftarPendidikan = Pendidikan ? ListPendaftar.filter((item) =>
            Pendidikan === 'S3' ? item.PendidikanJenjang === 'S3' :
                Pendidikan === 'S2' ? item.PendidikanJenjang === 'S3' || item.PendidikanJenjang === 'S2' :
                    Pendidikan === 'S1' ? item.PendidikanJenjang === 'S3' || item.PendidikanJenjang === 'S2' || item.PendidikanJenjang === 'S1' :
                        Pendidikan === 'D4' ? item.PendidikanJenjang === 'S3' || item.PendidikanJenjang === 'S2' || item.PendidikanJenjang === 'S1' || item.PendidikanJenjang === 'D4' :
                            Pendidikan === 'D3' ? item.PendidikanJenjang === 'S3' || item.PendidikanJenjang === 'S2' || item.PendidikanJenjang === 'S1' || item.PendidikanJenjang === 'D4' || item.PendidikanJenjang === 'D3' :
                                Pendidikan === 'SMA' ? item.PendidikanJenjang === 'S3' || item.PendidikanJenjang === 'S2' || item.PendidikanJenjang === 'S1' || item.PendidikanJenjang === 'D4' || item.PendidikanJenjang === 'D3' || item.PendidikanJenjang === 'SMA' :
                                    false
        ) : ListPendaftar
        const ListPendaftarInstansi = Instansi ? ListPendaftar.filter((item) => String(item.PendidikanNama).toLocaleLowerCase().search(String(Instansi).toLocaleLowerCase()) >= 0 ? true : false) : ListPendaftar
        const ListPendaftarJurusan = Jurusan ? ListPendaftar.filter((item) => String(item.PendidikanJurusan).toLocaleLowerCase().search(String(Jurusan).toLocaleLowerCase()) >= 0 ? true : false) : ListPendaftar
        const ListPendaftarMinPengalaman = MinPengalaman && MinPengalaman > 0 ?
            ListPendaftar.filter((item) => item.RiwayatKerja.length > 0 ?
                item.RiwayatKerja.reduce((prev, cur) => { return prev + Number(cur.BioKerjaRiwayatListYear) }, 0) >= MinPengalaman ?
                    true : false : false) : ListPendaftar

        const ListPendaftarPreRegisterDataSkill = PreRegisterDataSkill.every(v => v.Answer === '') ? ListPendaftar : ListPendaftar.filter((item) => item.ApplySkill.filter((item_item) =>
            PreRegisterDataSkill.filter((newItem) =>
                newItem.Answer === 'Basic' ? newItem.Skill === item_item.JobApplySkillSkill && (item_item.JobApplySkillAnswer === 'Basic' || item_item.JobApplySkillAnswer === 'Intermediate' || item_item.JobApplySkillAnswer === 'Proficient') :
                    newItem.Answer === 'Intermediate' ? newItem.Skill === item_item.JobApplySkillSkill && (item_item.JobApplySkillAnswer === 'Intermediate' || item_item.JobApplySkillAnswer === 'Proficient') :
                        newItem.Answer === 'Proficient' ? newItem.Skill === item_item.JobApplySkillSkill && (item_item.JobApplySkillAnswer === 'Proficient') :
                            newItem.Answer === '' ? newItem.Skill === item_item.JobApplySkillSkill :
                                false).length > 0 ?
                true : false).length >= PreRegisterDataSkill.length ?
            true : false)

        const ListPendaftarNoticedPeriod = NoticedPeriod ? ListPendaftar.filter((item) => item.ApplySkill.length > 0 ?
            item.ApplySkill.filter((item_item) =>
                NoticedPeriod === 'No Notice, Able To Join As Soon As Possible' ? item_item.JobApplySkillSkill === 'Noticed Period' && item_item.JobApplySkillAnswer === 'No Notice, Able To Join As Soon As Possible' :
                    NoticedPeriod === '1 Weeks' ? item_item.JobApplySkillSkill === 'Noticed Period' && (item_item.JobApplySkillAnswer === 'No Notice, Able To Join As Soon As Possible' || item_item.JobApplySkillAnswer === '1 Weeks') :
                        NoticedPeriod === '2 Weeks' ? item_item.JobApplySkillSkill === 'Noticed Period' && (item_item.JobApplySkillAnswer === 'No Notice, Able To Join As Soon As Possible' || item_item.JobApplySkillAnswer === '1 Weeks' || item_item.JobApplySkillAnswer === '2 Weeks') :
                            NoticedPeriod === '3 Weeks' ? item_item.JobApplySkillSkill === 'Noticed Period' && (item_item.JobApplySkillAnswer === 'No Notice, Able To Join As Soon As Possible' || item_item.JobApplySkillAnswer === '1 Weeks' || item_item.JobApplySkillAnswer === '2 Weeks' || item_item.JobApplySkillAnswer === '3 Weeks') :
                                NoticedPeriod === '1 Month' ? item_item.JobApplySkillSkill === 'Noticed Period' && (item_item.JobApplySkillAnswer === 'No Notice, Able To Join As Soon As Possible' || item_item.JobApplySkillAnswer === '1 Weeks' || item_item.JobApplySkillAnswer === '2 Weeks' || item_item.JobApplySkillAnswer === '3 Weeks' || item_item.JobApplySkillAnswer === '1 Month') :
                                    NoticedPeriod === '2 Month' ? item_item.JobApplySkillSkill === 'Noticed Period' && (item_item.JobApplySkillAnswer === 'No Notice, Able To Join As Soon As Possible' || item_item.JobApplySkillAnswer === '1 Weeks' || item_item.JobApplySkillAnswer === '2 Weeks' || item_item.JobApplySkillAnswer === '3 Weeks' || item_item.JobApplySkillAnswer === '1 Month' || item_item.JobApplySkillAnswer === '2 Month') :
                                        NoticedPeriod === 'More then 3 Month' ? item_item.JobApplySkillSkill === 'Noticed Period' && (item_item.JobApplySkillAnswer === 'No Notice, Able To Join As Soon As Possible' || item_item.JobApplySkillAnswer === '1 Weeks' || item_item.JobApplySkillAnswer === '2 Weeks' || item_item.JobApplySkillAnswer === '3 Weeks' || item_item.JobApplySkillAnswer === '1 Month' || item_item.JobApplySkillAnswer === '2 Month' || item_item.JobApplySkillAnswer === 'More then 3 Month') :
                                            false).length > 0 ? true : false : false) : ListPendaftar

        const newListPendaftar = [
            ListPendaftarNama,
            ListPendaftarRegistrasi,
            ListPendaftarUmurMin,
            ListPendaftarUmurMax,
            ListPendaftarPendidikan,
            ListPendaftarInstansi,
            ListPendaftarJurusan,
            ListPendaftarMinPengalaman,
            ListPendaftarPreRegisterDataSkill,
            ListPendaftarNoticedPeriod
        ].reduce((p, c) => p.filter(e => c.includes(e)))

        this.setState({ ListPendaftar: newListPendaftar })
        this.setState({ isSearchLoading: false })
    }
    ResetSearch = (e) => {
        const { ListPendaftar } = this.props
        const { PreRegisterData } = this.props
        const PreRegisterDataSkill = PreRegisterData?.PRSkill ? PreRegisterData.PRSkill : []

        const newPreRegisterDataSkill = []
        PreRegisterDataSkill.map((item, index) => newPreRegisterDataSkill.push({
            Skill: item.Skill,
            Answer: ''
        }))
        this.setState({
            Search: '',
            Nama: '',
            Registrasi: '',
            UmurMin: '',
            UmurMax: '',
            Pendidikan: '',
            Instansi: '',
            Jurusan: '',
            MinPengalaman: 0,
            ListPendaftar: ListPendaftar,
            PreRegisterDataSkill: newPreRegisterDataSkill,
            NoticedPeriod: ''
        })
    }
    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    OnChangeMapItem = (e, Index, Order) => {
        const PreRegisterDataSkill = this.state.PreRegisterDataSkill
        if (Order === 1) {
            PreRegisterDataSkill[Index].Answer = e.target.value
        } else {

        }
        this.setState({ PreRegisterDataSkill: PreRegisterDataSkill })
    }
    render() {
        // const JobActionId = this.props.JobActionId
        const isListPendaftarLoading = this.props.isListPendaftarLoading
        // const OrListPendaftar = this.props.ListPendaftar

        const {
            isDiajukanOpen,
            isSearchOpen,
            isShortList,
            isPendaftarOpen,
            Search,
            Nama,
            Registrasi,
            UmurMin,
            UmurMax,
            Pendidikan,
            Instansi,
            Jurusan,
            MinPengalaman,
            ListPendaftar,
            isSearchLoading,
            PreRegisterDataSkill, //FIXME
            NoticedPeriod,
        } = this.state

        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Nomer Pangajuan',
                width: '10%',
            },
            {
                Nama: 'Nama',
                width: '10%',
            },
            {
                Nama: 'Umur',
                width: '10%',
            },
            {
                Nama: 'Pendidikan Terakhir',
                width: '10%',
            },
            {
                Nama: 'Instansi',
                width: '10%',
            },
            {
                Nama: 'Jurusan',
                width: '10%',
            },
            {
                Nama: 'Status',
                width: '10%',
            },
            {
                Nama: 'Detail Pengajuan',
                width: '10%',
            },
        ]

        const FilteredList = ListPendaftar ? ListPendaftar.filter((item, index) => (
            item.AccReferralStatus !== null && item.AccReferralStatus !== 'Shorted'
        )) : []

        const BodyData = FilteredList.map((item, index) => {
            return {
                index: index + 1,
                NoRegistrasi: item.NoRegistrasi,
                Nama: item.Nama,
                Umur: item.TanggalLahir ? new Date().getFullYear() - new Date(item.TanggalLahir).getFullYear() : '',
                Pendidikan: item.PendidikanJenjang ? item.PendidikanJenjang : '',
                Instansi: item.PendidikanNama ? item.PendidikanNama : '',
                Jurusan: item.PendidikanJurusan ? item.PendidikanJurusan : '',
                Status: (
                    <span
                        style={{ color: item.AccDepStatus ? item.AccDepStatus === 'Accept' ? 'green' : 'red' : 'orange' }}
                    >
                        {item.AccDepStatus ? item.AccDepStatus : item.AccReferralStatus ? item.AccReferralStatus : ''}
                    </span>
                ),
                Detail: (
                    <GenericModals
                        size='xl'
                        header='Detail Diajukan'
                        body={<DetailDiajukan />}
                        Buttonvariant='outlined'
                        Buttoncolor='primary'
                        ButtononClickeven={() => this.onClickActionId(item.AccountId)}
                        Buttonlabel={'Detail'}
                    />
                )
            }
        })

        const HeadData2 = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Nomer Pangajuan',
                width: '10%',
            },
            {
                Nama: 'Nama',
                width: '10%',
            },
            {
                Nama: 'Umur',
                width: '10%',
            },
            {
                Nama: 'Pendidikan Terakhir',
                width: '10%',
            },
            {
                Nama: 'Instansi',
                width: '10%',
            },
            {
                Nama: 'Jurusan',
                width: '10%',
            },
            {
                Nama: 'Status',
                width: '10%',
            },
            {
                Nama: 'Detail Pengajuan',
                width: '10%',
            },
        ]

        const FilteredList2 = ListPendaftar ? ListPendaftar.filter((item, index) => (
            item.AccReferralStatus === null
        )) : []

        const BodyData2 = FilteredList2.map((item, index) => {
            return {
                index: index + 1,
                NoRegistrasi: item.NoRegistrasi,
                Nama: item.Nama,
                Umur: item.TanggalLahir ? new Date().getFullYear() - new Date(item.TanggalLahir).getFullYear() : '',
                Pendidikan: item.PendidikanJenjang ? item.PendidikanJenjang : '',
                Instansi: item.PendidikanNama ? item.PendidikanNama : '',
                Jurusan: item.PendidikanJurusan ? item.PendidikanJurusan : '',
                Status: item.StatusLamaran,
                Detail: item.StatusSubmission ? (
                    <Button
                        variant='outlined'
                        color='success'
                    >
                        ✔ Submitted
                    </Button>
                ) : (
                    <GenericModals
                        size='xl'
                        header='Detail Pendaftar'
                        body={<DetailPendaftar />}
                        Buttonvariant='contained'
                        Buttoncolor={item?.isRejected ? item.isRejected ? 'warning' : 'primary' : 'primary'}
                        ButtononClickeven={() => this.onClickActionId(item.AccountId)
                        }
                        Buttonlabel={'Detail'}
                    />
                )
            }
        })

        const FilteredList3 = ListPendaftar ? ListPendaftar.filter((item, index) => (
            item.AccReferralStatus !== null && (item.AccReferralStatus === 'Shorted')
        )) : []

        const BodyData3 = FilteredList3.map((item, index) => {
            return {
                index: index + 1,
                NoRegistrasi: item.NoRegistrasi,
                Nama: item.Nama,
                Umur: item.TanggalLahir ? new Date().getFullYear() - new Date(item.TanggalLahir).getFullYear() : '',
                Pendidikan: item.PendidikanJenjang ? item.PendidikanJenjang : '',
                Instansi: item.PendidikanNama ? item.PendidikanNama : '',
                Jurusan: item.PendidikanJurusan ? item.PendidikanJurusan : '',
                Status: item.StatusLamaran,
                Detail: item.StatusSubmission ? (
                    <Button
                        variant='outlined'
                        color='success'
                    >
                        ✔ Submitted
                    </Button>
                ) : (
                    <GenericModals
                        size='xl'
                        header='Detail Pendaftar'
                        body={<DetailPendaftar />}
                        Buttonvariant='contained'
                        Buttoncolor={item?.isRejected ? item.isRejected ? 'warning' : 'primary' : 'primary'}
                        ButtononClickeven={() => this.onClickActionId(item.AccountId)
                        }
                        Buttonlabel={'Detail'}
                    />
                )
            }
        })

        const RowDataInOnePage = 5

        const OpPendidikan = [
            {
                Option: 'SMA',
                value: 'SMA',
            },
            {
                Option: 'D3',
                value: 'D3',
            },
            {
                Option: 'D4',
                value: 'D4',
            },
            {
                Option: 'S1',
                value: 'S1'
            },
            {
                Option: 'S2',
                value: 'S2'
            },
            {
                Option: 'S3',
                value: 'S3'
            },
        ]

        const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }

        return (
            <Fragment>
                <LoadingModal isModalOpen={isSearchLoading} />
                {false ?
                    <Fragment>
                        {[1, 2, 3, 4, 5,].map((item) =>
                            <Skeleton
                                key={`ListPendaftar_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <h3
                            onClick={this.handleDiajukanOpen}
                            style={{ display: 'flex', alignContent: 'center', verticalAlign: 'center', cursor: 'pointer' }}
                        >
                            Proposed{isDiajukanOpen ? <ExpandLess /> : <ExpandMore />}
                        </h3>
                        <Collapse in={isDiajukanOpen} timeout="auto" unmountOnExit>
                            <CoTable
                                TableId={'ACCMPP'}
                                HeadData={HeadData}
                                Data={BodyData}
                                isHeadLoading={isListPendaftarLoading}
                                isBodyLoading={isListPendaftarLoading}
                                isPagination={BodyData.length > RowDataInOnePage ? true : false}
                                MaxRowOnPage={RowDataInOnePage}
                                isPaginationLoading={isListPendaftarLoading}
                                PaginationCounter={
                                    BodyData.length / RowDataInOnePage > Math.floor(BodyData.length / RowDataInOnePage) ?
                                        Math.floor(BodyData.length / RowDataInOnePage) + 1 :
                                        BodyData.length / RowDataInOnePage
                                }
                            />
                        </Collapse>

                        <Fragment>
                            <div
                                style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}
                            >
                                <TextField
                                    variant='outlined'
                                    type='text'
                                    label='Cari Berdasarkan Nama'
                                    name='Search'
                                    disabled={isSearchOpen ? true : false}
                                    value={Search}
                                    style={{ ...st_textfield, ...{ width: '80%' } }}
                                    onChange={this.onChange_Search}
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>,
                                    }}
                                />
                                <Button
                                    variant='contained'
                                    onClick={this.handleSearchOpen}
                                    style={{ width: '10%', margin: '0 0 0 5%', padding: '1%' }}
                                >
                                    {isSearchOpen ? <ExpandLess /> : <ExpandMore />}
                                </Button>
                            </div>
                            <Collapse in={isSearchOpen} timeout="auto" unmountOnExit>
                                <Paper
                                    style={{ padding: '1%', borderTop: '0px solid black' }}
                                >
                                    <TextField
                                        variant='outlined'
                                        type='text'
                                        label='Nama'
                                        name='Nama'
                                        value={Nama}
                                        style={{ ...st_textfield }}
                                        onChange={this.onChangeField}
                                    />
                                    <TextField
                                        variant='outlined'
                                        type='text'
                                        label='Nomer Registrasi'
                                        name='Registrasi'
                                        value={Registrasi}
                                        style={{ ...st_textfield }}
                                        onChange={this.onChangeField}
                                    />
                                    <TextField
                                        variant='outlined'
                                        type='number'
                                        label='Umur Minimal'
                                        name='UmurMin'
                                        value={UmurMin}
                                        style={{ ...st_textfield, ...{ width: '40%' } }}
                                        onChange={this.onChangeField}
                                    />
                                    <TextField
                                        variant='outlined'
                                        type='number'
                                        label='Umur Maksimal'
                                        name='UmurMax'
                                        value={UmurMax}
                                        style={{ ...st_textfield, ...{ width: '40%', marginLeft: '5%' } }}
                                        onChange={this.onChangeField}
                                    />
                                    <FormControl
                                        style={{ ...st_textfield }}
                                        variant="filled"
                                    >
                                        <InputLabel
                                            shrink
                                        >
                                            Minimum Jenjang
                                        </InputLabel>
                                        <Select
                                            native
                                            style={{ backgroundColor: 'white' }}
                                            onChange={this.onChangeField}
                                            label="Pendidikan Minimal"
                                            name='Pendidikan'
                                            value={Pendidikan ? Pendidikan : ''}
                                        >
                                            <option value=''> -- select an option -- </option>
                                            {OpPendidikan.map((option, index) =>
                                                <option key={`OpPendidikan${index}`} value={option.value}>{option.Option}</option>
                                            )}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        variant='outlined'
                                        type='text'
                                        label='Instansi'
                                        name='Instansi'
                                        value={Instansi}
                                        style={{ ...st_textfield }}
                                        onChange={this.onChangeField}
                                    />
                                    <TextField
                                        variant='outlined'
                                        type='text'
                                        label='Jurusan'
                                        name='Jurusan'
                                        value={Jurusan}
                                        style={{ ...st_textfield }}
                                        onChange={this.onChangeField}
                                    />
                                    <TextField
                                        variant='outlined'
                                        type='number'
                                        label='Minimal Pengalaman (Tahun)'
                                        name='MinPengalaman'
                                        value={MinPengalaman}
                                        style={{ ...st_textfield }}
                                        onChange={this.onChangeField}
                                    />
                                    <h2>
                                        Minimum Skill
                                    </h2>
                                    {PreRegisterDataSkill.map((item, index) =>
                                        <Fragment
                                            key={`PreRegisterDataSkill_${index}`}
                                        >
                                            <FormControl
                                                style={{ ...st_textfield, }}
                                                variant="filled"
                                            >
                                                <InputLabel
                                                    shrink
                                                >
                                                    {item.Skill}
                                                </InputLabel>
                                                <Select
                                                    native
                                                    style={{ backgroundColor: 'white' }}
                                                    onChange={(e) => this.OnChangeMapItem(e, index, 1)}
                                                    label="Golongan Darah"
                                                    name='GolDar'
                                                    value={PreRegisterDataSkill[index].Answer}
                                                >
                                                    <option value="" > -- select an option -- </option>
                                                    {['Basic', 'Intermediate', 'Proficient'].map((option, index) =>
                                                        <option key={`Op_Jawban_${index}`} value={option}>{option}</option>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Fragment>)}

                                    <FormControl
                                        style={{ ...st_textfield, }}
                                        variant="filled"
                                    >
                                        <InputLabel
                                            shrink
                                        >
                                            Noticed Period
                                        </InputLabel>
                                        <Select
                                            native
                                            style={{ backgroundColor: 'white' }}
                                            // onChange={(e) => this.OnChangeMapItem(e, index, 1)}
                                            label='Maximum Noticed Period'
                                            name='NoticedPeriod'
                                            value={NoticedPeriod}
                                            onChange={this.onChangeField}
                                        >
                                            <option value="" > -- select an option -- </option>
                                            {['No Notice, Able To Join As Soon As Possible', '1 Weeks', '2 Weeks', '3 Weeks', '1 Month', '2 Month', '3 Month', 'More then 3 Month'].map((option, index) =>
                                                <option key={`Op_NoticedPeriod_${index}`} value={option}>{option}</option>
                                            )}
                                        </Select>
                                    </FormControl>

                                    <center>
                                        <Button
                                            variant='contained'
                                            style={{ width: '60%', margin: '1% 0 3% 0' }}
                                            onClick={this.SearchData}
                                        >
                                            <SearchIcon /> Search
                                        </Button>
                                        <Button
                                            variant='contained'
                                            color='secondary'
                                            style={{ width: '5%', margin: '1% 0 3% 2%' }}
                                            onClick={this.ResetSearch}
                                        >
                                            <RestartAltIcon />
                                        </Button>
                                    </center>
                                </Paper>
                            </Collapse>
                        </Fragment>

                        <div>
                            <h3
                                onClick={this.handleShortList}
                                style={{ display: 'flex', alignContent: 'center', verticalAlign: 'center', cursor: 'pointer' }}
                            >
                                Short List {isShortList ? <ExpandLess /> : <ExpandMore />}
                            </h3>
                            <Collapse in={isShortList} timeout="auto" unmountOnExit>
                                <CoTable
                                    TableId={'ShortList'}
                                    HeadData={HeadData2}
                                    Data={BodyData3}
                                    isHeadLoading={isListPendaftarLoading}
                                    isBodyLoading={isListPendaftarLoading}
                                    isPagination={BodyData3.length > RowDataInOnePage ? true : false}
                                    MaxRowOnPage={RowDataInOnePage}
                                    isPaginationLoading={isListPendaftarLoading}
                                    PaginationCounter={
                                        BodyData3.length / RowDataInOnePage > Math.floor(BodyData3.length / RowDataInOnePage) ?
                                            Math.floor(BodyData3.length / RowDataInOnePage) + 1 :
                                            BodyData3.length / RowDataInOnePage
                                    }
                                />
                            </Collapse>
                        </div>

                        <div
                            style={{ margin: '0 0 5% 0' }}
                        >
                            <h3
                                onClick={this.handlePendaftarOpen}
                                style={{ display: 'flex', alignContent: 'center', verticalAlign: 'center', cursor: 'pointer' }}
                            >
                                Applicant{isPendaftarOpen ? <ExpandLess /> : <ExpandMore />}
                            </h3>
                            <Collapse in={isPendaftarOpen} timeout="auto" unmountOnExit>
                                <CoTable
                                    TableId={'ACCMPP'}
                                    HeadData={HeadData2}
                                    Data={BodyData2}
                                    isHeadLoading={isListPendaftarLoading}
                                    isBodyLoading={isListPendaftarLoading}
                                    isPagination={BodyData2.length > RowDataInOnePage ? true : false}
                                    MaxRowOnPage={RowDataInOnePage}
                                    isPaginationLoading={isListPendaftarLoading}
                                    PaginationCounter={
                                        BodyData2.length / RowDataInOnePage > Math.floor(BodyData2.length / RowDataInOnePage) ?
                                            Math.floor(BodyData2.length / RowDataInOnePage) + 1 :
                                            BodyData2.length / RowDataInOnePage
                                    }
                                />
                            </Collapse>
                        </div>
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    JobActionId: state.Job.JobActionId,
    isListPendaftarLoading: state.Job.isListPendaftarLoading,
    ListPendaftar: state.Job.ListPendaftar,
    isPreRegisterLoading: state.Job.isPreRegisterLoading,
    PreRegisterData: state.Job.PreRegisterData,
})

export default connect(mapStateToProps, { get_List_Pendaftar, get_Account_Action_Id, get_PreRegisterData })(ListPendaftar)