import React, { Fragment } from 'react'

import { useDispatch, connect } from 'react-redux'
import { get_Activation_Code, Activation_Code } from '../../../Store/Actions/Auth.Action'

import { TextField, Button } from '@mui/material'

const ActivationModal = ({ Email, isOneStep, ...props }) => {
    const dispatch = useDispatch()

    const [ActivationInput, setActivationInput] = React.useState('')
    const [isAsk, setisAsk] = React.useState(false)

    const UseMailFunction = () => {
        dispatch(get_Activation_Code())
        setisAsk(true)
    }

    return (
        <div
            style={{
                border: '0px solid red', opacity: '1'
            }}
        >
            <div
            // style={{ border: '1px solid ', }}
            >
                <center>
                    <h1
                        style={{ color: 'black', margin: '0 0 5vh 0', fontFamily: 'Montserrat, Segoe UI', }}
                    >
                        Please Check {Email ? Email : 'mail'} for activation code, {isAsk ? <Fragment>Confirmation Code Already Sent, Please Wait</Fragment> : <Fragment>or click <span style={{ cursor: 'pointer', color: 'blacksmoke' }} onClick={(e) => UseMailFunction()}><u>here</u></span> to send new one</Fragment>}
                    </h1>
                    <TextField
                        label='Activation Code'
                        variant='standard'
                        value={ActivationInput}
                        onChange={(e) => setActivationInput(e.target.value)}
                        style={{ margin: '2vh 0 5vh 0', color: 'black', borderColor: 'black', width: '20%' }}
                        InputLabelProps={{
                            style: { color: 'black', }
                        }}
                        InputProps={{
                            disableUnderline: true,
                            style: { color: 'black', borderBottom: '1px solid black' }
                        }}
                    /><br />
                    <Button
                        variant='outlined'
                        size='large'
                        style={{ color: 'black', borderColor: 'black', width: '60%', margin: '2vh 0 5vh 0', fontFamily: 'Montserrat, Segoe UI', boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px', }}
                        onClick={(e) => dispatch(Activation_Code(ActivationInput, isOneStep, props.User))}
                    >
                        <b>
                            Confirm
                        </b>
                    </Button>
                </center>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    User: state.Auth.User
})

export default connect(mapStateToProps)(ActivationModal)