import React from 'react'

import { connect } from 'react-redux'
import { Add_Pertanyaan_Interview } from '../../../../../../Store/Actions/Setting.Action'

import { TextField, FormControl, InputLabel, Select, Button, CircularProgress } from '@mui/material'


class SettingPertanyaanAdd extends React.Component {
    state = {
        Nama: '',
        isNamaError: false,
        Jenis: '',
        Deskripsi: ''
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    changeToError = (e, value) => {
        this.setState({ isNamaError: value })
    }

    formOnSubmit = (e) => {
        e.preventDefault()
        this.props.Add_Pertanyaan_Interview(this.state.Nama, this.state.Jenis, this.state.Deskripsi)
    }

    render() {
        const {
            Nama,
            isNamaError,
            Jenis,
            Deskripsi,
        } = this.state

        const isPertanyaanActionAddLoading = this.props.isPertanyaanActionAddLoading
        const isPertanyaanExist = this.props.isPertanyaanExist

        const OpJenis = ['All', 'Department', 'HR']

        return (

            <form
                onSubmit={this.formOnSubmit}
            >
                <div
                    style={{ margin: '0 5% 0 5%' }}
                >
                    <TextField
                        label='Nama'
                        type='text'
                        name='Nama'
                        value={Nama}
                        required
                        onInvalid={(e) => this.changeToError(e, true)}
                        onChange={this.onChangeField}
                        style={{ width: '100%', margin: '1% 0 1% 0' }}
                        error={isPertanyaanExist || isNamaError ? true : false}
                        helperText={isPertanyaanExist ? 'Pertanyaan Sudah Ada' : isNamaError ? 'Nama Harus diisi' : ''}
                    />
                    <FormControl
                        style={{ width: '100%', margin: '1% 0 1% 0' }}
                        variant="filled"
                        required
                    >
                        <InputLabel
                            shrink
                        >
                            Jenis
                        </InputLabel>
                        <Select
                            native
                            style={{ backgroundColor: 'white' }}
                            onChange={this.onChangeField}
                            name='Jenis'
                            value={Jenis}
                        >
                            <option value="" disabled> -- select an option -- </option>
                            {OpJenis.map((option, index) =>
                                <option key={`OpJenis_${index}`} value={option}>{option}</option>
                            )}
                        </Select>
                    </FormControl>
                    <TextField
                        label='Deskripsi'
                        type='text'
                        name='Deskripsi'
                        value={Deskripsi}
                        onChange={this.onChangeField}
                        style={{ width: '100%', margin: '1% 0 1% 0' }}
                    />
                </div>
                <center>
                    <Button
                        variant='contained'
                        size='large'
                        style={{ border: '0px solid red', width: "85%", margin: '3% 0 2% 0' }}
                        type='submit'
                        disabled={isPertanyaanActionAddLoading ? true : false}
                    >
                        {isPertanyaanActionAddLoading ? <CircularProgress /> : 'Add Pertanyaan'}
                    </Button>
                </center>
            </form>
        )
    }
}

const mapStateToProps = state => ({
    isPertanyaanActionAddLoading: state.Setting.isPertanyaanActionAddLoading,
    isPertanyaanExist: state.Setting.isPertanyaanExist,
})

export default connect(mapStateToProps, { Add_Pertanyaan_Interview })(SettingPertanyaanAdd)