import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_MPP_Jobdesk } from '../../../../../../Store/Actions/MPP.Action'

import { Stepper, Step, StepLabel, Button, Paper, FormControl, InputLabel, Select, Skeleton } from '@mui/material'

import Form from './Form'

import { isLevelQualified } from '../../../../../../Global'

class PengajuanJobdesk extends React.Component {
    state = {
        StepValue: 0,
        MaxStep: 3,
        MPP: '',
        MPPId: '',
        MPPDetail: null,
        isUseTemplate: false,
        Template: '',
        TemplateId: '',
        TemplateData: ''
    }

    componentDidMount() {
        this.props.get_MPP_Jobdesk()
    }

    handleNext = () => {
        if (this.state === this.state.MaxStep - 1) {
            this.setState({ StepValue: this.state.MaxStep - 1 })
        } else {
            this.setState({ StepValue: this.state.StepValue + 1 })
        }
    }
    handleBack = () => {
        if (this.state === 0) {
            this.setState({ StepValue: 0 })
        } else {
            this.setState({ StepValue: this.state.StepValue - 1 })
        }
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    SelectFormOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    SelectMPPFormOnChange = (e) => {
        const MPPJobdesk = this.props.MPPJobdesk
        const newMPPDetail = MPPJobdesk.find(item => item.MPPListId === Number(e.target.value))
        this.setState({ MPP: newMPPDetail.MPPListId })
        this.setState({ MPPId: newMPPDetail.Id })
        this.setState({ MPPDetail: newMPPDetail })
    }

    SelectTemplateFormOnChange = (e) => {
        this.setState({ isUseTemplate: e.target.value })
        if (e.target.value === 'false' || e.target.value === false) {
            this.setState({ Template: '' })
            this.setState({ TemplateId: '' })
            this.setState({ TemplateData: '' })
        }
    }

    SelectMPPTemplateFormOnChange = (e) => {
        const MPPJobdesk = this.props.MPPJobdesk
        const newMPPDetail = MPPJobdesk.find(item => item.MPPListId === Number(e.target.value))
        this.setState({ Template: newMPPDetail.MPPListId })
        this.setState({ TemplateId: newMPPDetail.JobdeskId })
        this.setState({ TemplateData: newMPPDetail })
    }

    render() {
        const steps = ['MPP Select', 'Template', 'Job Description Form']
        const {
            StepValue,
            MPP,
            isUseTemplate,
            Template
        } = this.state

        const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }

        const MPPJobdesk = this.props.MPPJobdesk
        const isMPPJobdeskLoading = this.props.isMPPJobdeskLoading
        const isMPPActionGetDataLoading = this.props.isMPPActionGetDataLoading

        const User = this.props.User
        const isAdmin = User?.isAdmin ? User.isAdmin : false
        const Level = User?.Level ? User.Level : ''

        const UnUsedMPP = MPPJobdesk ? MPPJobdesk.filter((item, index) => (
            item.JobdeskId === null
            && (isAdmin || isLevelQualified(Level, item?.LevelId ? item.LevelId : ''))
        )) : []

        const UsedMPP = MPPJobdesk ? MPPJobdesk.filter((item, index) => (
            item.JobdeskId !== null
        )) : []

        const FormSelection = () => {
            return (
                <Fragment>
                    {isMPPJobdeskLoading ?
                        <Fragment>
                            <Skeleton
                                height={'10vh'}
                            />
                        </Fragment> :
                        <Fragment>
                            <FormControl
                                style={st_textfield}
                                variant="filled"
                                required
                            >
                                <InputLabel
                                    shrink
                                >
                                    MPP
                                </InputLabel>
                                <Select
                                    native
                                    style={{ backgroundColor: 'white' }}
                                    // onChange={(e) => this.SelectFormOnChange(e)}
                                    onChange={(e) => this.SelectMPPFormOnChange(e)}
                                    label="MPP"
                                    name='MPP'
                                    value={MPP}
                                >
                                    <option value="" disabled> -- select an option -- </option>
                                    {UnUsedMPP.map((option, index) =>
                                        <option key={`UnUsedMPP_${index}`} value={option.MPPListId}>{`(${option.Id}) \t - \t ${option.Tahun} \t - \t ${option.DepNama} \t - \t ${option.LevNama} \t ${option.Sub} \t - \t ${new Date(2022, option.BulanBuka, 1).toLocaleString('default', { month: 'long' })} \t - \t ${option.StatusKaryawan} \t, MPP : \t ${option.Jumlah}`}</option>
                                        // <option key={`MPPJobdesk_${index}`} value={option.Id}>{`(${option.Id}) \t - \t ${option.Tahun} \t ${option.LevNama} \t ${option.Sub} \t ${option.BulanBuka} \t ${option.StatusKaryawan} \t ${option.Jumlah}`}</option>
                                    )}
                                </Select>
                            </FormControl>
                        </Fragment>}
                </Fragment>
            )
        }

        const TemplateSelection = () => {
            return (
                <Fragment>
                    {isMPPJobdeskLoading ?
                        <Fragment>
                            <Skeleton
                                height={'10vh'}
                            />
                        </Fragment> :
                        <Fragment>
                            <FormControl
                                style={st_textfield}
                                variant="filled"
                                required
                            >
                                <InputLabel
                                    shrink
                                >
                                    Use Existing Template If the Job is exactly the same
                                </InputLabel>
                                <Select
                                    native
                                    style={{ backgroundColor: 'white' }}
                                    onChange={(e) => this.SelectTemplateFormOnChange(e)}
                                    label="Use Existing Template If the Job is exactly the same"
                                    name='isUseTemplate'
                                    value={isUseTemplate}
                                >
                                    <option value="" disabled> -- select an option -- </option>
                                    {[{ name: 'Yes', value: true }, { name: 'No', value: false }].map((option, index) =>
                                        <option key={`MPPJobdesk_${index}`} value={option.value}>{option.name}</option>
                                    )}
                                </Select>
                            </FormControl>
                            {isUseTemplate === 'true' || isUseTemplate === true ?
                                <Fragment>
                                    <FormControl
                                        style={st_textfield}
                                        variant="filled"
                                        required
                                    >
                                        <InputLabel
                                            shrink
                                        >
                                            Select MPP Template
                                        </InputLabel>
                                        <Select
                                            native
                                            style={{ backgroundColor: 'white' }}
                                            // onChange={(e) => this.SelectFormOnChange(e)}
                                            onChange={(e) => this.SelectMPPTemplateFormOnChange(e)}
                                            label="Select MPP Template"
                                            name='Template'
                                            value={Template}
                                        >
                                            <option value="" disabled> -- select an option -- </option>
                                            {UsedMPP.map((option, index) =>
                                                <option key={`UsedMPP_${index}`} value={option.MPPListId}>{`(${option.Id}) \t - \t ${option.Tahun} \t - \t ${option.DepNama} \t - \t ${option.LevNama} \t ${option.Sub} \t - \t ${new Date(2022, option.BulanBuka, 1).toLocaleString('default', { month: 'long' })} \t - \t ${option.StatusKaryawan} \t : \t ${option.Jumlah}`}</option>
                                                // <option key={`MPPJobdesk_${index}`} value={option.Id}>{`(${option.Id}) \t - \t ${option.Tahun} \t ${option.LevNama} \t ${option.Sub} \t ${option.BulanBuka} \t ${option.StatusKaryawan} \t ${option.Jumlah}`}</option>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Fragment>
                                : <Fragment></Fragment>}
                        </Fragment>
                    }
                </Fragment>
            )
        }

        return (
            <Fragment>
                <Stepper activeStep={StepValue}>
                    {steps.map((label, index) => {
                        const stepProps = {}
                        const labelProps = {}
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        )
                    })}
                </Stepper>
                <Paper
                    style={{ padding: '5%' }}
                >

                    {StepValue === 0 ?
                        <FormSelection /> :
                        StepValue === 1 ?
                            <TemplateSelection /> :
                            <Form
                                MPPSelectId={this.state.MPPId}
                                MPPSelectDetail={this.state.MPPDetail}
                                isUseTemplate={this.state.isUseTemplate}
                                TemplateId={this.state.TemplateId}
                                TemplateData={this.state.TemplateData}
                            />
                    }
                </Paper>

                <center>
                    <Button
                        variant='contained'
                        size='large'
                        color="error"
                        disabled={StepValue === 0 || isMPPActionGetDataLoading ? true : false}
                        onClick={this.handleBack}
                        style={{ margin: '5% 10% 5% 10%', width: '10vw' }}
                    >
                        Back
                    </Button> <Button
                        variant='contained'
                        size='large'
                        disabled={
                            StepValue === 0 ?
                                isMPPJobdeskLoading || !this.state.MPP ?
                                    true
                                    : false
                                : StepValue === 1 ?
                                    isUseTemplate === true || isUseTemplate === 'true' ?
                                        Template ?
                                            false
                                            : true
                                        : false
                                    : StepValue === this.state.MaxStep - 1 ?
                                        true
                                        : false
                        }
                        onClick={this.handleNext}
                        style={{ margin: '5% 10% 5% 10%', width: '10vw' }}
                    >
                        Next
                    </Button>
                </center>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isMPPActionGetDataLoading: state.MPP.isMPPActionGetDataLoading,
    isMPPJobdeskLoading: state.MPP.isMPPJobdeskLoading,
    MPPJobdesk: state.MPP.MPPJobdesk,
    User: state.Auth.User,
})

export default connect(mapStateToProps, { get_MPP_Jobdesk })(PengajuanJobdesk)