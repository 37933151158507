import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Konfirm_Final_Interview_User } from '../../../../../../Store/Actions/Job.Action'
import { Create_Warning_Messages } from '../../../../../../Store/Actions/Messages.Actions'

import { Tabs, Tab, FormControl, InputLabel, Select, TextField, FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material'

import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'

import DetailPosisi from './Sub/DetailPosisi'
import JobdeskDetail from '../../../Jobdesk/Modal/Detail'
import DetailKaryawan from './Sub/DetailKaryawan'
import PersonaltyTestDetail from '../PersonaltyTest/Detail'
import ReviewPenilaianInterviewHR from './Sub/ReviewPenilaianInterviewHR'
import ReviewPenilaianInterviewUser from './Sub/ReviewPenilaianInterviewUser'

import CoTable from '../../../../../Containers/Table'


class FinalPenilianKandidat extends React.Component {
    state = {
        TabValue: 0,
        KeputusanHasil: '',
        Keterangan: '',
        isAdditionalInterview: false,
        isAlternativeTest: false,
        AdditionalInterviewList: [
            {
                Uraian: '',
                Point: 0,
                Keterangan: '',
            }
        ],
        AlternativeTest: [
            {
                Uraian: '',
                Point: 0,
                Keterangan: '',
            }
        ],
    }

    handleTabChange = (e, newTabValue) => {
        this.setState({ TabValue: newTabValue })
    }

    SelectFormOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    OnChangeCheckbox = E => {
        this.setState({ [E.target.name]: !this.state[E.target.name] })
    }

    AddDetailList = () => {
        const AdditionalInterviewList = this.state.AdditionalInterviewList

        if (AdditionalInterviewList.length === 0 ||
            (AdditionalInterviewList[AdditionalInterviewList.length - 1].Uraian &&
                AdditionalInterviewList[AdditionalInterviewList.length - 1].Point !== 0)
        ) {
            const newData = { Uraian: '', Point: 0, Keterangan: '' }
            AdditionalInterviewList.push(newData)
            this.setState({ AdditionalInterviewList: AdditionalInterviewList })
        } else {
            this.props.Create_Warning_Messages(null, 'mohon lengkapi proses pengisian terlebih dahulu')
        }
    }

    DeleteDetailList = (Index) => {
        const AdditionalInterviewList = this.state.AdditionalInterviewList
        AdditionalInterviewList.splice(Index, 1)
        this.setState({ AdditionalInterviewList: AdditionalInterviewList })
    }

    OnChangeMapItem = (e, Index, Order) => {
        const AdditionalInterviewList = this.state.AdditionalInterviewList
        if (Order === 1) {
            AdditionalInterviewList[Index].Uraian = e.target.value
        } else if (Order === 2) {
            AdditionalInterviewList[Index].Keterangan = e.target.value
        } else {

        }
        this.setState({ AdditionalInterviewList: AdditionalInterviewList })
    }

    onChangeCheckboxMap = (e, index, value) => {
        const AdditionalInterviewList = this.state.AdditionalInterviewList
        AdditionalInterviewList[index].Point = value
        this.setState({ AdditionalInterviewList: AdditionalInterviewList })
    }

    AddDetailList2 = () => {
        const AlternativeTest = this.state.AlternativeTest

        if (AlternativeTest.length === 0 ||
            (AlternativeTest[AlternativeTest.length - 1].Uraian &&
                AlternativeTest[AlternativeTest.length - 1].Point !== 0)
        ) {
            const newData = { Uraian: '', Point: 0, Keterangan: '' }
            AlternativeTest.push(newData)
            this.setState({ AlternativeTest: AlternativeTest })
        } else {
            this.props.Create_Warning_Messages(null, 'mohon lengkapi proses pengisian terlebih dahulu')
        }
    }

    DeleteDetailList2 = (Index) => {
        const AlternativeTest = this.state.AlternativeTest
        AlternativeTest.splice(Index, 1)
        this.setState({ AlternativeTest: AlternativeTest })
    }

    OnChangeMapItem2 = (e, Index, Order) => {
        const AlternativeTest = this.state.AlternativeTest
        if (Order === 1) {
            AlternativeTest[Index].Uraian = e.target.value
        } else if (Order === 2) {
            AlternativeTest[Index].Keterangan = e.target.value
        } else {

        }
        this.setState({ AlternativeTest: AlternativeTest })
    }

    onChangeCheckboxMap2 = (e, index, value) => {
        const AlternativeTest = this.state.AlternativeTest
        AlternativeTest[index].Point = value
        this.setState({ AlternativeTest: AlternativeTest })
    }

    formOnSubmit = (e) => {
        e.preventDefault()
        const { isAdditionalInterview, isAlternativeTest } = this.state

        const { JobActionId, AccountActionId } = this.props

        this.props.Konfirm_Final_Interview_User(
            JobActionId,
            AccountActionId,
            this.state.KeputusanHasil,
            this.state.Keterangan,
            isAdditionalInterview ? this.state.AdditionalInterviewList : [],
            isAlternativeTest ? this.state.AlternativeTest : []
        )
    }

    render() {

        const TabData = [
            { TabLabel: 'Detail Posisi', TabPanel: <DetailPosisi /> },
            { TabLabel: 'Jobdesk Detail', TabPanel: <JobdeskDetail /> },
            { TabLabel: 'Detail Karyawan', TabPanel: <DetailKaryawan /> },
            { TabLabel: 'Personalty Tes', TabPanel: <PersonaltyTestDetail isJobListId={true} /> },
            { TabLabel: 'Penilaian Interview HR', TabPanel: <ReviewPenilaianInterviewHR /> },
            { TabLabel: 'Penilaian Interview User', TabPanel: <ReviewPenilaianInterviewUser /> },
        ]

        const {
            TabValue,
            KeputusanHasil,
            Keterangan,
            isAdditionalInterview,
            isAlternativeTest,
            AdditionalInterviewList,
            AlternativeTest,
        } = this.state

        const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }
        const st_icon = { color: 'grey', fontSize: '180%', margin: '0 0 0 1%', cursor: 'pointer' }

        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Description',
                width: '35%',
            },
            {
                Nama: '1',
                width: '5%',
            },
            {
                Nama: '2',
                width: '5%',
            },
            {
                Nama: '3',
                width: '5%',
            },
            {
                Nama: '4',
                width: '5%',
            },
            {
                Nama: '5',
                width: '5%',
            },
            {
                Nama: 'Note',
                width: '15%',
            },
            {
                Nama: 'Add',
                width: '10%',
            },
            {
                Nama: 'Delete',
                width: '10%',
            },
        ]

        const BodyData = AdditionalInterviewList.map((item, index) => {
            return {
                index: index + 1,
                Pertanyaan: (
                    <TextField
                        value={AdditionalInterviewList[index].Uraian}
                        style={{ width: '90%' }}
                        required
                        label={`Description ${index + 1}`}
                        onChange={(e) => this.OnChangeMapItem(e, index, 1)} 
                    />
                ),
                Point1: (
                    <Checkbox
                        checked={AdditionalInterviewList[index].Point === 1 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap(e, index, 1)}
                    />
                ),
                Point2: (
                    <Checkbox
                        checked={AdditionalInterviewList[index].Point === 2 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap(e, index, 2)}
                    />
                ),
                Point3: (
                    <Checkbox
                        checked={AdditionalInterviewList[index].Point === 3 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap(e, index, 3)}
                    />
                ),
                Point4: (
                    <Checkbox
                        checked={AdditionalInterviewList[index].Point === 4 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap(e, index, 4)}
                    />
                ),
                Point5: (
                    <Checkbox
                        checked={AdditionalInterviewList[index].Point === 5 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap(e, index, 5)}
                    />
                ),
                Keterangan: (
                    <TextField
                        value={AdditionalInterviewList[index].Keterangan}
                        style={{ width: '90%' }}
                        label={`Notes ${index + 1}`}
                        onChange={(e) => this.OnChangeMapItem(e, index, 2)}
                    />
                ),
                Tambah: (
                    <AddCircleRoundedIcon
                        onClick={this.AddDetailList}
                        style={st_icon}
                    />
                ),
                Hapus: index === 0 ? '' : (
                    <DeleteOutlineRoundedIcon
                        onClick={(e) => this.DeleteDetailList(index)
                        }
                        style={st_icon}
                    />
                ),
            }
        })

        const HeadData2 = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Test',
                width: '35%',
            },
            {
                Nama: '1',
                width: '5%',
            },
            {
                Nama: '2',
                width: '5%',
            },
            {
                Nama: '3',
                width: '5%',
            },
            {
                Nama: '4',
                width: '5%',
            },
            {
                Nama: '5',
                width: '5%',
            },
            {
                Nama: 'Note',
                width: '15%',
            },
            {
                Nama: 'Add',
                width: '10%',
            },
            {
                Nama: 'Delete',
                width: '10%',
            },
        ]

        const BodyData2 = AlternativeTest.map((item, index) => {
            return {
                index: index + 1,
                Pertanyaan: (
                    <TextField
                        value={AlternativeTest[index].Uraian}
                        style={{ width: '90%' }}
                        required
                        label={`Test ${index + 1}`}
                        onChange={(e) => this.OnChangeMapItem2(e, index, 1)}
                    />
                ),
                Point1: (
                    <Checkbox
                        checked={AlternativeTest[index].Point === 1 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap2(e, index, 1)}
                    />
                ),
                Point2: (
                    <Checkbox
                        checked={AlternativeTest[index].Point === 2 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap2(e, index, 2)}
                    />
                ),
                Point3: (
                    <Checkbox
                        checked={AlternativeTest[index].Point === 3 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap2(e, index, 3)}
                    />
                ),
                Point4: (
                    <Checkbox
                        checked={AlternativeTest[index].Point === 4 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap2(e, index, 4)}
                    />
                ),
                Point5: (
                    <Checkbox
                        checked={AlternativeTest[index].Point === 5 ? true : false}
                        onChange={(e) => this.onChangeCheckboxMap2(e, index, 5)}
                    />
                ),
                Keterangan: (
                    <TextField
                        value={AlternativeTest[index].Keterangan}
                        style={{ width: '90%' }}
                        label={`Notes ${index + 1}`}
                        onChange={(e) => this.OnChangeMapItem2(e, index, 2)}
                    />
                ),
                Tambah: (
                    <AddCircleRoundedIcon
                        onClick={this.AddDetailList2}
                        style={st_icon}
                    />
                ),
                Hapus: index === 0 ? '' : (
                    <DeleteOutlineRoundedIcon
                        onClick={(e) => this.DeleteDetailList2(index)
                        }
                        style={st_icon}
                    />
                ),
            }
        })

        return (
            <Fragment>

                <Tabs
                    value={TabValue}
                    onChange={(e, newValue) => this.handleTabChange(e, newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={TabData.length > 6 ? "scrollable" : null}
                    scrollButtons="auto"
                // centered={TabData.length > 6 ? false : true}
                >

                    {TabData.map((item, index) => (
                        <Tab key={`Tabs${index}`} label={item.TabLabel} />
                    ))}

                </Tabs>

                <div
                    style={{ margin: '5% 0 5% 0' }}
                >
                    {TabValue === 0 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 1 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 2 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 3 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 4 ? TabData[TabValue].TabPanel : null}
                    {TabValue === 5 ? TabData[TabValue].TabPanel : null}
                </div>

                <form
                    onSubmit={this.formOnSubmit}
                >
                    <h2>
                        Action
                    </h2>
                    <h3>
                        Additional Interview
                    </h3>
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox color='primary' onChange={this.OnChangeCheckbox} name="isAdditionalInterview" value={isAdditionalInterview} checked={isAdditionalInterview} />}
                            label="Additional Interview"
                        />
                    </FormGroup>
                    {isAdditionalInterview ?
                        <Fragment>
                            <CoTable
                                TableId={'AdditionalInterview'}
                                HeadData={HeadData}
                                Data={BodyData}
                            // isHeadLoading={isPertanyaanLoading}
                            // isBodyLoading={isPertanyaanLoading}
                            />
                        </Fragment> :
                        <Fragment>
                        </Fragment>}
                    <h3>
                        Alternative Test
                    </h3>
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox color='primary' onChange={this.OnChangeCheckbox} name="isAlternativeTest" value={isAlternativeTest} checked={isAlternativeTest} />}
                            label="Alternative Test"
                        />
                    </FormGroup>
                    {isAlternativeTest ?
                        <Fragment>
                            <CoTable
                                TableId={'isAlternativeTest'}
                                HeadData={HeadData2}
                                Data={BodyData2}
                            // isHeadLoading={isPertanyaanLoading}
                            // isBodyLoading={isPertanyaanLoading}
                            />
                        </Fragment> :
                        <Fragment>
                        </Fragment>}
                    <h3>
                        Keputusan Akhir
                    </h3>
                    <FormControl style={st_textfield} variant="filled" required >
                        <InputLabel shrink >Keputusan Hasil Interview</InputLabel>
                        <Select native style={{ backgroundColor: 'white' }} onChange={(e) => this.SelectFormOnChange(e)} label="Keputusan Hasil Interview" name='KeputusanHasil' value={KeputusanHasil} >
                            <option value="" disabled> -- select an option -- </option>
                            {['Accept', 'Reject'].map((option, index) =>
                                <option key={index} value={option}>{option}</option>
                            )}
                        </Select>
                    </FormControl>
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        onChange={this.onChangeField}
                        type='texts'
                        label='Keterangan'
                        name='Keterangan'
                        value={Keterangan}
                    />
                    <center>
                        <Button
                            variant='contained'
                            style={{ width: '60%', margin: '1% 0 3% 0' }}
                            type='submit'
                        >
                            Submit
                        </Button>
                    </center>
                </form>

            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
})

export default connect(mapStateToProps, { Create_Warning_Messages, Konfirm_Final_Interview_User })(FinalPenilianKandidat)
