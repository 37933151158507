import React, { Fragment } from 'react'

import { Tabs, Tab } from '@mui/material'

import DetailKaryawan from './DetailKaryawan'
import Detail from './Detail'
import Submit from './Submit'

const DetailPenilianKandidat = ({ isKonfirm }) => {
    const [TabValue, setTabValue] = React.useState(0)

    const handleTabChange = (e, newTabValue) => {
        setTabValue(newTabValue)
    }

    const TabData = [
        { TabLabel: isKonfirm ? 'Submit' : 'Detail', TabPanel: isKonfirm ? <Submit /> : <Detail /> },
        { TabLabel: 'Detail Karyawan', TabPanel: <DetailKaryawan isDownload={true} /> },
    ]

    return (
        <Fragment>

            <Tabs
                value={TabValue}
                onChange={(e, newValue) => handleTabChange(e, newValue)}
                indicatorColor="primary"
                textColor="primary"
                variant={TabData.length > 6 ? "scrollable" : null}
                scrollButtons="auto"
            // centered={TabData.length > 6 ? false : true}
            >

                {TabData.map((item, index) => (
                    <Tab key={`Tabs${index}`} label={item.TabLabel} />
                ))}

            </Tabs>

            <div
                style={{ margin: '5% 0 5% 0' }}
            >
                {TabValue === 0 ? TabData[TabValue].TabPanel : null}
                {TabValue === 1 ? TabData[TabValue].TabPanel : null}
                {TabValue === 3 ? TabData[TabValue].TabPanel : null}
            </div>

        </Fragment >
    )
}

export default DetailPenilianKandidat