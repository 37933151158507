import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Assign_MCU } from '../../../../../../Store/Actions/Job.Action'

import { TextField, Button } from '@mui/material'

class Assign extends React.Component {
    state = {
        AssignDate: ''
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    formOnSubmit = (e) => {
        e.preventDefault()

        const JobActionId = this.props.JobActionId
        const AccountActionId = this.props.AccountActionId

        this.props.Assign_MCU(JobActionId, AccountActionId, this.state.AssignDate)
    }

    render() {
        const st_textfield_non = { marginTop: '10px', marginBottom: '10px', width: '100%' }

        const {
            AssignDate,
        } = this.state

        return (
            <Fragment>
                <form
                    onSubmit={this.formOnSubmit}
                >
                    <TextField
                        style={st_textfield_non}
                        variant='outlined'
                        required
                        type='date'
                        label='AssignDate'
                        name='AssignDate'
                        value={AssignDate}
                        onChange={this.onChangeField}
                        InputLabelProps={{ shrink: true }}
                    />

                    <center>
                        <Button
                            variant='contained'
                            style={{ margin: '10% 0 5% 0', width: '60%' }}
                            type='submit'
                        >
                            Submit
                        </Button>
                    </center>
                </form>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
})

export default connect(mapStateToProps, { Assign_MCU })(Assign)