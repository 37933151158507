import React from 'react'

import { Autocomplete, TextField, CircularProgress, Button } from '@mui/material'

const AddParticipan = ({ ParticipanData, ParticipanDataLoading, DataId, isLoading, sendFunction }) => {
    const [Participan, setParticipan] = React.useState(null)
    return (
        <div
            style={{ margin: '1% 0 1% 0', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '0px solid green' }}
        >
            <Autocomplete
                // multiple
                value={Participan}
                onChange={(event, newParticipan) => {
                    setParticipan(newParticipan)
                }}

                // options={ParticipanData}
                options={ParticipanData.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))}
                // getOptionLabel={option => typeof option === 'string' ? option : option.NamaJenisBarang}
                getOptionLabel={option => option.Nama}
                // groupBy={(option) => option.Kepemilikan}
                style={{ width: '60%', border: '0px solid red' }}
                disabled={ParticipanDataLoading || isLoading ? true : false}
                renderInput={(params) => (
                    <TextField
                        // style={{ width: '80%', border: '1px solid blue' }}
                        name='Participan'
                        label={ParticipanDataLoading || isLoading ? <CircularProgress /> : 'Search Nama'}
                        variant="outlined"
                        disabled={ParticipanDataLoading || isLoading ? true : false}
                        {...params}
                    />
                )}
            />
            <Button
                variant='contained'
                // size='large'
                style={{ width: '15%', margin: '0 0 0 10%' }}
                onClick={(e) => sendFunction(DataId, Participan)}
                disabled={ParticipanDataLoading || isLoading ? true : false}
            >
                {ParticipanDataLoading || isLoading ? <CircularProgress /> : 'Add'}
            </Button>
        </div>
    )
}

export default AddParticipan