import React, { Fragment } from "react"

import useMediaQuery from '@mui/material/useMediaQuery'
import { InitState } from '../../../../Style'

import FullJobSave from './JobSave/FullJobSave'
import HalfJobSave from './JobSave/HalfJobSave'

const Header = () => {
    const MinWindowFirst = useMediaQuery(`(min-width:${InitState.Units.minWidth_first}px)`)
    return (
        <Fragment>
            {MinWindowFirst ?
                <Fragment>
                    <FullJobSave />
                </Fragment>
                : <Fragment>
                    <HalfJobSave />
                </Fragment>}
        </Fragment>
    )
}

export default Header