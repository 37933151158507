import * as XLSX from "xlsx";

import { isDev } from "../../Global";

import Store from "../Store";

import {
  Create_Success_Messages,
  Create_Error_Messages,
} from "./Messages.Actions";

import {
  AUTH_USER_EXPIRED,
  // AUTH_RELOADE_PAGE,
} from "./Type.Action";

export const Get_IpAddres = () => {
  try {
    // api development
    // const IpAddress = '10.131.68.140' tergantung ip address
    // const IpAddress = "127.0.0.1";
    // const Port = "5000";
    // return `http://${IpAddress}${Port ? `:${Port}` : ""}`;
    // api development

    //api live connection
    return `https://erecruitmentapi.azurewebsites.net`;
    //api live connection
  } catch (err) {
    if (isDev()) {
      console.log("🚀 ~ file: Base.Action.js ~ line 7 ~ err", err);
    }
  }
};

export const defaultHeader = () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return config;
};

export const tokenConfig = (getState, OriginalToken) => {
  // GET TOKEN FROM STATE
  const Token = OriginalToken ? OriginalToken : getState().Auth.Token;

  // HEADERS
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // if token, add header
  if (Token) {
    config.headers["x-auth-token"] = Token;
  }

  return config;
};

export const tokenConfigMultipleForm = (getState) => {
  const Token = getState().Auth.Token;
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      // 'Content-Type':'multipart/form-data;boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL'
    },
  };
  if (Token) {
    config.headers["x-auth-token"] = `${Token}`;
  }
  return config;
};

export const ErrorHandler = (ErrorData, FunctionName = "") => {
  try {
    if (ErrorData.message === "Network Error") {
      Store.dispatch(Create_Error_Messages(500, "Tidak Terhubung"));
    } else if (ErrorData.response.status === 406) {
      window.location.href = "/login";
      // setTimeout(() => window.location.href = '/login', 1)
      Store.dispatch(Create_Error_Messages(406, "User Expired"));
      Store.dispatch({ type: AUTH_USER_EXPIRED });
      // window.location.href = '/login'
      // Store.dispatch({
      //     type: AUTH_RELOADE_PAGE
      // })
    } else if (ErrorData.response.data.msg) {
      Store.dispatch(Create_Error_Messages(400, ErrorData.response.data.msg));
    } else {
      Store.dispatch(Create_Error_Messages(400, "Proses Gagal"));
      console.log(
        `🚀 ~ file: Base.Action.js ~ line 60 ~ ErrorHandler ~ FunctionName:`,
        FunctionName
      );
      console.log(
        `🚀 ~ file: Base.Action.js ~ line 86 ~ ErrorHandler ~ err`,
        ErrorData
      );
    }
    return null;
  } catch (err) {
    Store.dispatch(Create_Error_Messages(400, "Proses Gagal"));
    console.log(
      `🚀 ~ file: Base.Action.js ~ line 60 ~ ErrorHandler ~ FunctionName:`,
      FunctionName
    );
    console.log(
      `🚀 ~ file: Base.Action.js ~ line 86 ~ ErrorHandler ~ err`,
      err
    );
    return null;
  }
};

export const Create_Excel_File = (
  Data,
  FileName = "MyExcel.xlsx",
  ComplexData = false
) => {
  try {
    if (!Data) {
      // eslint-disable-next-line
      throw {
        msg: "No Data",
      };
    }

    const newData = [];

    // eslint-disable-next-line
    Data.map((item, index) => {
      const newItem = {};
      // eslint-disable-next-line
      Object.keys(item).map((item_item, index_index) => {
        Object.assign(newItem, {
          [item_item]: item[item_item]?.display
            ? typeof item[item_item].display === "object"
              ? item[item_item].value
              : item[item_item].display
            : item[item_item].value,
        });
      });
      newData.push(newItem);
    });

    const UsedData = ComplexData ? newData : Data;

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(UsedData);

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, FileName);

    Store.dispatch(Create_Success_Messages(200, "Success"));

    return true;
  } catch (err) {
    console.log(`🚀 ~ file: Base.Action.js ~ line 126 ~ err`, err);
    Store.dispatch(Create_Error_Messages("Error"));
    return false;
  }
};
