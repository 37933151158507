import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_List_Kontrak_Detail } from '../../../../../../Store/Actions/Job.Action'

import { Skeleton } from '@mui/material'

class DetailKontrak extends React.Component {
    componentDidMount() {
        const { JobActionId, AccountActionId } = this.props
        this.props.get_List_Kontrak_Detail(JobActionId, AccountActionId)
    }
    render() {
        const isListKontrakDetailLoading = this.props.isListKontrakDetailLoading
        const ListKontrakDetail = this.props.ListKontrakDetail
        
        return (
            <Fragment>
                {isListKontrakDetailLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5,].map((item) =>
                            <Skeleton
                                key={`DetailMenunggu_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <h2>Kontrak</h2>
                        <div
                            style={{
                                margin: '1% 0 1% 0', padding: '1%', border: `1px solid ${ListKontrakDetail?.KoStatus ? ListKontrakDetail.KoStatus === 'Accept' ? 'Green' :
                                    'red' : 'black'}`
                            }}
                        >
                            <center>
                                <h2
                                    style={{
                                        color: ListKontrakDetail?.KoStatus ? ListKontrakDetail.KoStatus === 'Accept' ? 'Green' :
                                            'red' : 'black'
                                    }}
                                >
                                    Status : {ListKontrakDetail?.KoStatus ? ListKontrakDetail.KoStatus : 'Status'}
                                </h2>
                            </center>
                            <h4>
                                Diupdate Pada:<br />
                                {ListKontrakDetail?.KoDate ? new Date(ListKontrakDetail.KoDate).toLocaleString(['ban', 'id']) : ''}<br /><br />
                                Oleh:<br />
                                {ListKontrakDetail?.BiodataNama ? ListKontrakDetail.BiodataNama : ''}<br /><br />
                                Keterangan:<br />
                                {ListKontrakDetail?.KoKet ? ListKontrakDetail.KoKet : ''}<br />
                            </h4>
                        </div>
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isListKontrakDetailLoading: state.Job.isListKontrakDetailLoading,
    ListKontrakDetail: state.Job.ListKontrakDetail,
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
})

export default connect(mapStateToProps, { get_List_Kontrak_Detail })(DetailKontrak)