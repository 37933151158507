import React from 'react'

import MoBiodata from './Modal/Biodata/Biodata1'

const FillingBiodata = ({ isOneStep, ...props }) => {
    return (
        <div>
            <MoBiodata isOneStep={isOneStep} />
        </div>
    )
}

export default FillingBiodata