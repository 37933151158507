import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_MPP_Data, Delete_MPP } from '../../../../../../Store/Actions/Setting.Action'

import { FormGroup, FormControlLabel, Checkbox, Button, CircularProgress } from '@mui/material'

import Detail from '../../../../../Containers/Dashboard/Setting/MPP/Detail'

class SettingMPPDelete extends React.Component {
    state = {
        isConfirm: false,
    }
    componentDidUpdate(prevProps) {
        if (this.props.MPPActionId !== prevProps.MPPActionId) {
            this.props.get_MPP_Data(this.props.MPPActionId)
        }
    }
    componentDidMount() {
        const { MPPActionId } = this.props
        if (MPPActionId !== null) {
            this.props.get_MPP_Data(MPPActionId)
        }
    }

    OnChangeCheckbox = E => {
        this.setState({ [E.target.name]: !this.state[E.target.name] })
    }

    onClickDelete(Id) {
        this.props.Delete_MPP(Id)
    }
    render() {
        const MPPActionId = this.props.MPPActionId
        const isMPPActionGetDetailLoading = this.props.isMPPActionGetDetailLoading
        const MPPDetail = this.props.MPPDetail
        const {
            isConfirm
        } = this.state
        const isMPPDeleteLoading = this.props.isMPPDeleteLoading
        return (
            <Fragment>
                <Detail Data={MPPDetail} isLoading={isMPPActionGetDetailLoading} />
                <div
                    style={{ margin: '1%' }}
                >
                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox color='primary' onChange={this.OnChangeCheckbox} name="isConfirm" value={isConfirm} disabled={isMPPDeleteLoading ? true : false} />}
                            label="I'm aware of the risks and I know what I'm doing"
                        />
                    </FormGroup>
                    <center>
                        <Button
                            variant='contained'
                            size='large'
                            color='error'
                            disabled={!isConfirm || isMPPDeleteLoading ? true : false}
                            onClick={() => this.onClickDelete(MPPActionId)}
                        >
                            {isMPPDeleteLoading ? <CircularProgress /> : 'Delete'}
                        </Button>
                    </center>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isMPPActionGetDetailLoading: state.Setting.isMPPActionGetDetailLoading,
    isMPPDeleteLoading: state.Setting.isMPPDeleteLoading,
    MPPActionId: state.Setting.MPPActionId,
    MPPDetail: state.Setting.MPPDetail,
})

export default connect(mapStateToProps, { get_MPP_Data, Delete_MPP })(SettingMPPDelete)
