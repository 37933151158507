import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_List_PreBoarding_Detail } from '../../../../../../Store/Actions/Job.Action'
import { download_Foto, download_KTP, download_KK, download_NPWP, download_BCA, download_BPJSKesehatan, download_BPJSKetenagakerjaan, download_Ijazah, download_Sertifikat, download_CV } from '../../../../../../Store/Actions/Account.Action'

// import { Skeleton, Checkbox, TextField, Button } from '@mui/material'
import { Skeleton, Checkbox, Button } from '@mui/material'

class DetailPreBoarding extends React.Component {
    componentDidMount() {
        const { JobActionId, AccountActionId } = this.props
        this.props.get_List_PreBoarding_Detail(JobActionId, AccountActionId)
    }
    render() {

        const isListPreBoardingDetailLoading = this.props.isListPreBoardingDetailLoading
        const ListPreBoardingDetail = this.props.ListPreBoardingDetail
        const Data = ListPreBoardingDetail ? ListPreBoardingDetail.Data : {}
        const Biodata = ListPreBoardingDetail ? ListPreBoardingDetail.Biodata : {}

        // const st_textfield_disable = { marginTop: '10px', marginBottom: '10px', width: '100%', backgroundColor: '#E0E0E0' }

        return (
            <Fragment>
                {isListPreBoardingDetailLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5,].map((item) =>
                            <Skeleton
                                key={`DetailMenunggu_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <h2>PreBoarding</h2>
                        <div
                            style={{
                                margin: '1% 0 1% 0', padding: '1%', border: `1px solid ${Data?.PBStatus ? Data.PBStatus === 'Selesai' ? 'Green' :
                                    'orange' : 'black'}`
                            }}
                        >
                            <center>
                                <h2
                                    style={{
                                        color: Data?.PBStatus ? Data.PBStatus === 'Selesai' ? 'Green' :
                                            'orange' : 'black'
                                    }}
                                >
                                    Status : {Data?.PBStatus ? Data.PBStatus : 'Status'}
                                </h2>
                            </center>
                            <h4>
                                Diupdate Pada:<br />
                                {Data?.PBDate ? new Date(Data.PBDate).toLocaleString(['ban', 'id']) : ''}<br /><br />
                                Oleh:<br />
                                {Data?.BiodataNama ? Data.BiodataNama : ''}<br /><br />
                            </h4>
                        </div>
                        <h2>
                            Data:
                        </h2>
                        <Checkbox
                            checked={Biodata ? Biodata.NamaLengkap ? true : false : false}
                            disabled
                        />
                        Nama <br />
                        <Checkbox
                            checked={Biodata ? Biodata.TempatLahir ? true : false : false}
                            disabled
                        />
                        TempatLahir <br />
                        <Checkbox
                            checked={Biodata ? Biodata.TanggalLahir ? true : false : false}
                            disabled
                        />
                        Tanggal Lahir <br />
                        <Checkbox
                            checked={Biodata ? Biodata.AlamatKTP ? true : false : false}
                            disabled
                        />
                        Alamat <br />
                        <Checkbox
                            checked={Biodata ? Biodata.Hp ? true : false : false}
                            disabled
                        />
                        No Tlp <br />
                        <Checkbox
                            checked={Biodata ? Biodata.KTP ? true : false : false}
                            disabled
                        />
                        KTP <br />
                        <Checkbox
                            checked={Biodata ? Biodata.KK ? true : false : false}
                            disabled
                        />
                        KK <br />
                        <Checkbox
                            checked={Biodata ? Biodata.NPWP ? true : false : false}
                            disabled
                        />
                        NPWP <br />
                        <Checkbox
                            checked={Biodata ? Biodata.BPJSKesehatan ? true : false : false}
                            disabled
                        />
                        BPJS Kesehatan <br />
                        <Checkbox
                            checked={Biodata ? Biodata.BPJSKetenagakerjaan ? true : false : false}
                            disabled
                        />
                        BPJS Ketenagakerjaan <br />
                        <Checkbox
                            checked={Biodata ? Biodata.BCA ? true : false : false}
                            disabled
                        />
                        BCA <br />
                        <Checkbox
                            checked={Biodata ? Biodata.Foto ? true : false : false}
                            disabled
                        />
                        Foto <br />
                        <h2>
                            Input Data:
                        </h2>

                        <Checkbox
                            name='Foto'
                            checked={Biodata ? Biodata.FileFoto : false}
                            disabled
                        />
                        Foto <br />
                        <Button
                            style={{ marginLeft: '2vw' }}
                            disabled={Biodata ? Biodata.FileFoto ? false : true : true}
                            onClick={() => this.props.download_Foto(this.props.AccountActionId, Biodata.NamaLengkap)}
                        >
                            Download
                        </Button>< br />

                        <Checkbox
                            name='KTP'
                            checked={Biodata ? Biodata.FileKTP : false}
                            disabled
                        />
                        KTP <br />
                        <Button
                            style={{ marginLeft: '2vw' }}
                            disabled={Biodata ? Biodata.FileKTP ? false : true : true}
                            onClick={() => this.props.download_KTP(this.props.AccountActionId, Biodata.NamaLengkap)}
                        >
                            Download
                        </Button>< br />

                        <Checkbox
                            name='KK'
                            checked={Biodata ? Biodata.FileKartuKeluarga : false}
                            disabled
                        />
                        KK <br />
                        <Button
                            style={{ marginLeft: '2vw' }}
                            disabled={Biodata ? Biodata.FileKartuKeluarga ? false : true : true}
                            onClick={() => this.props.download_KK(this.props.AccountActionId, Biodata.NamaLengkap)}
                        >
                            Download
                        </Button>< br />

                        <Checkbox
                            name='NPWP'
                            checked={Biodata ? Biodata.FileNPWP : false}
                            disabled
                        />
                        NPWP <br />
                        <Button
                            style={{ marginLeft: '2vw' }}
                            disabled={Biodata ? Biodata.FileNPWP ? false : true : true}
                            onClick={() => this.props.download_NPWP(this.props.AccountActionId, Biodata.NamaLengkap)}
                        >
                            Download
                        </Button>< br />

                        <Checkbox
                            name='BCA'
                            checked={Biodata ? Biodata.FileRekeningBCA : false}
                            disabled
                        />
                        BCA <br />
                        <Button
                            style={{ marginLeft: '2vw' }}
                            disabled={Biodata ? Biodata.FileRekeningBCA ? false : true : true}
                            onClick={() => this.props.download_BCA(this.props.AccountActionId, Biodata.NamaLengkap)}
                        >
                            Download
                        </Button>< br />

                        <Checkbox
                            name='BPJSKesehatan'
                            checked={Biodata ? Biodata.FileBPJSKesehatan : false}
                            disabled
                        />
                        BPJS Kesehatan <br />
                        <Button
                            style={{ marginLeft: '2vw' }}
                            disabled={Biodata ? Biodata.FileBPJSKesehatan ? false : true : true}
                            onClick={() => this.props.download_BPJSKesehatan(this.props.AccountActionId, Biodata.NamaLengkap)}
                        >
                            Download
                        </Button>< br />

                        <Checkbox
                            name='BPJSKetenagakerjaan'
                            checked={Biodata ? Biodata.FileBPJSKetenagakerjaan : false}
                            disabled
                        />
                        BPJS Ketenagakerjaan <br />
                        <Button
                            style={{ marginLeft: '2vw' }}
                            disabled={Biodata ? Biodata.FileBPJSKetenagakerjaan ? false : true : true}
                            onClick={() => this.props.download_BPJSKetenagakerjaan(this.props.AccountActionId, Biodata.NamaLengkap)}
                        >
                            Download
                        </Button>< br />

                        <Checkbox
                            name='Ijazah'
                            checked={Biodata ? Biodata.FileIjazah : false}
                            disabled
                        />
                        Ijazah <br />
                        <Button
                            style={{ marginLeft: '2vw' }}
                            disabled={Biodata ? Biodata.FileIjazah ? false : true : true}
                            onClick={() => this.props.download_Ijazah(this.props.AccountActionId, Biodata.NamaLengkap)}
                        >
                            Download
                        </Button>< br />

                        <Checkbox
                            name='Sertifikast'
                            checked={Biodata ? Biodata.FileSertifikasi : false}
                            disabled
                        />
                        Sertifikasi <br />
                        <Button
                            style={{ marginLeft: '2vw' }}
                            disabled={Biodata ? Biodata.FileSertifikasi ? false : true : true}
                            onClick={() => this.props.download_Sertifikat(this.props.AccountActionId, Biodata.NamaLengkap)}
                        >
                            Download
                        </Button>< br />
                        <Checkbox
                            name='Sertifikast'
                            checked={true}
                            disabled
                        />
                        Curriculum vitae  <br />
                        <Button
                            style={{ marginLeft: '2vw' }}
                            // disabled={Biodata ? Biodata.FileSertifikasi ? false : true : true}
                            onClick={() => this.props.download_CV(this.props.AccountActionId, Biodata.NamaLengkap)}
                        >
                            Download
                        </Button>< br />
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isListPreBoardingDetailLoading: state.Job.isListPreBoardingDetailLoading,
    ListPreBoardingDetail: state.Job.ListPreBoardingDetail,
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
})

export default connect(mapStateToProps, { get_List_PreBoarding_Detail, download_Foto, download_KTP, download_KK, download_NPWP, download_BCA, download_BPJSKesehatan, download_BPJSKetenagakerjaan, download_Ijazah, download_Sertifikat, download_CV })(DetailPreBoarding)