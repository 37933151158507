import React, { Fragment } from 'react'

import { Skeleton, Grid, TextField } from '@mui/material'

const Account = ({ isLoading, Data, DisableEdit, Edit, Edit2, Edit3 }) => {
    const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%', backgroundColor: '#E0E0E0' }
    return (
        <Fragment>
            {isLoading ?
                <Fragment>
                    {[1, 2, 3].map((item) =>
                        <Skeleton
                            key={`Account_Skeleton_${item}`}
                            height={'5vh'}
                        />
                    )}
                </Fragment> :
                <Fragment>
                    {!DisableEdit ?
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="baseline"
                        >
                            {Edit}
                        </Grid>
                        : <Fragment></Fragment>}
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        disabled
                        type='text'
                        label='User Name'
                        name='UserName'
                        value={Data?.UserName ? Data.UserName : ''}
                        InputProps={{
                            style: { fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }
                        }}
                    />
                    {!DisableEdit ?
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="baseline"
                        >
                            {Edit2}
                        </Grid>
                        : <Fragment></Fragment>}
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        disabled
                        type='text'
                        label='Email'
                        name='Email'
                        value={Data?.Email ? Data.Email : ''}
                        InputProps={{
                            style: { fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }
                        }}
                    />
                    {!DisableEdit ?
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="baseline"
                        >
                            {Edit3}
                        </Grid>
                        : <Fragment></Fragment>}
                </Fragment>}
        </Fragment>
    )
}

export default Account