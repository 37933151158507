import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_List_Interview_User_Detail } from '../../../../../../../Store/Actions/Job.Action'

import ReviewInterviewUser from '../../../../../../Containers/Job/InterviewUser/Review/ReviewInterviewUser'

class PenilaianInterviewUser extends React.Component {
    componentDidMount() {
        const { JobActionId, AccountActionId } = this.props
        this.props.get_List_Interview_User_Detail(JobActionId, AccountActionId)
    }

    render() {

        const isListInterviewUserDetailLoading = this.props.isListInterviewUserDetailLoading
        const ListInterviewUserDetail = this.props.ListInterviewUserDetail
        const Data = ListInterviewUserDetail?.Data ? ListInterviewUserDetail.Data : null
        const AssessorData = ListInterviewUserDetail?.AssessorData ? ListInterviewUserDetail.AssessorData : []
        const ListAdditional = ListInterviewUserDetail?.ListAdditional ? ListInterviewUserDetail.ListAdditional : []
        const ListAlternative = ListInterviewUserDetail?.ListAlternative ? ListInterviewUserDetail.ListAlternative : []


        return (
            <Fragment>
                <ReviewInterviewUser
                    isLoading={isListInterviewUserDetailLoading}
                    Data={Data}
                    AssessorData={AssessorData}
                    ListAdditional={ListAdditional}
                    ListAlternative={ListAlternative}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isListInterviewUserDetailLoading: state.Job.isListInterviewUserDetailLoading,
    ListInterviewUserDetail: state.Job.ListInterviewUserDetail,
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
})

export default connect(mapStateToProps, { get_List_Interview_User_Detail })(PenilaianInterviewUser)