import React from 'react'

import { connect } from 'react-redux'

import { Create_Error_Messages } from '../Store/Actions/Messages.Actions'

import Store from '../Store/Store'

import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ component: Component, Auth, SecurityType, RoleList = [], Child, ...rest }) => (
    <Route {...rest} render={props => {
        if (!Auth.isAuth) {
            const URLLocation = window.location.href
            const URLLocationLC = String(URLLocation).toLocaleLowerCase()
            const URLDB = [
                'http://localhost:3000',
                'https://career.id.etanabiotech.com',
                'https://erecruit.azurewebsites.net'
            ]
            const UsedURL = URLDB.find((element) => {
                return URLLocation.includes(element)
            })
            const PrefLocation = UsedURL ? URLLocationLC.split(UsedURL)[1] : ''
            return <Redirect
                to={{
                    pathname: "/login",
                    state: { PrefLocation: PrefLocation }
                }}
            />

        } else {

            const User = Auth.User

            if (User) {
                if (SecurityType) {

                    const isPendaftar = User.isPendaftar
                    const isKaryawan = User.isKaryawan
                    const isAdmin = User.isAdmin
                    const Role = User.Role
                    const isRoleAllow = RoleList.length === 0 ? true : RoleList.some(r => Role.includes(r))

                    if (SecurityType === 'Privat' && !isPendaftar) {
                        Store.dispatch(
                            Create_Error_Messages(null, 'anda harus masuk dengan akun PENDAFTAR untuk mengakses halaman terkait')
                        )
                        return <Redirect to="/" />
                    } else if (SecurityType === 'Privacry' && !(isKaryawan || isAdmin)) {
                        Store.dispatch(
                            Create_Error_Messages(null, 'anda harus masuk dengan akun KARYAWAN untuk mengakses halaman terkait')
                        )
                        return <Redirect to="/" />
                    } else if (SecurityType === 'SuperPrivacry' && !isAdmin) {
                        Store.dispatch(
                            Create_Error_Messages(null, 'anda harus masuk dengan akun ADMIN untuk mengakses halaman terkait')
                        )
                        return <Redirect to="/" />
                    } else if (!isAdmin && !isRoleAllow) {
                        Store.dispatch(
                            Create_Error_Messages(null, 'Role tidak memenuhi kriteria')
                        )
                        return <Redirect to="/" />
                    } else {
                        return <Component Component={Child ? <Child /> : null} {...props} />
                    }
                } else {
                    return <Component Component={Child ? <Child /> : null}  {...props} />
                }
            }
        }
    }} />
)

const mapStateToProps = state => ({
    Auth: state.Auth
})

export default connect(mapStateToProps)(PrivateRoute)