import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_MPP_View_Implementasi } from '../../../../../../../Store/Actions/MPP.Action'

import { Skeleton, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'

class ImplementasiMPP extends React.Component {
    componentDidMount() {
        const { MPPActionId } = this.props
        if (MPPActionId !== null) {
            this.props.get_MPP_View_Implementasi(MPPActionId)
        }
    }

    render() {
        const User = this.props.User
        const isAdmin = User ? User.isAdmin : false
        const Role = User ? User.Role : []
        const Department = User ? User.Department : []
        const AccessorMPP = '1105'
        const isAccessorMPP = Role.includes(AccessorMPP)
        const MPPActionId = this.props.MPPActionId
        const isMPPViewImplementasiLoading = this.props.isMPPViewImplementasiLoading
        const MPPViewImplementasiData = this.props.MPPViewImplementasi ? this.props.MPPViewImplementasi : []
        const MPPViewImplementasi = isAdmin || isAccessorMPP ? MPPViewImplementasiData : MPPViewImplementasiData.filter((item) => (
            Department.find((newItem) => item.DepartemenNama === newItem.DepartemenNama) ? true : false
        ))

        const st_BorderLeft = { borderLeft: '1px solid black' }
        const st_BorderRight = { borderRight: '1px solid black' }

        return (
            <Fragment>
                {/* {JSON.stringify(MPPViewImplementasi)} */}
                {isMPPViewImplementasiLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5].map((item) =>
                            <Skeleton
                                key={`ImplementasiMPP_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <center>
                            <h2>
                                Implementasi MPP Pada Tahun {MPPActionId}
                            </h2>
                        </center>
                        <Table
                            stickyHeader
                            style={{ margin: '0 0 5% 0' }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align='center'
                                        colSpan={3}
                                    />
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        Januari
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        Februari
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        Maret
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        April
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        Mei
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        Juni
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        Juli
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        Agustus
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        September
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        Oktober
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        November
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                        colSpan={2}
                                    >
                                        Desember
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Departemen
                                    </TableCell>
                                    <TableCell>
                                        Level
                                    </TableCell>
                                    <TableCell>
                                        Sub
                                    </TableCell>
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell>
                                    <TableCell>
                                        Outsource
                                    </TableCell> */}
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell>
                                    <TableCell>
                                        Outsource
                                    </TableCell> */}
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell>
                                    <TableCell>
                                        Outsource
                                    </TableCell> */}
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell>
                                    <TableCell>
                                        Outsource
                                    </TableCell> */}
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell>
                                    <TableCell>
                                        Outsource
                                    </TableCell> */}
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell>
                                    <TableCell>
                                        Outsource
                                    </TableCell> */}
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell>
                                    <TableCell>
                                        Outsource
                                    </TableCell> */}
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell>
                                    <TableCell>
                                        Outsource
                                    </TableCell> */}
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell>
                                    <TableCell>
                                        Outsource
                                    </TableCell> */}
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell>
                                    <TableCell>
                                        Outsource
                                    </TableCell> */}
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell>
                                    <TableCell>
                                        Outsource
                                    </TableCell> */}
                                    <TableCell>
                                        Permanent
                                    </TableCell>
                                    <TableCell>
                                        Kontrak
                                    </TableCell>
                                    {/* <TableCell>
                                        Intern
                                    </TableCell>
                                    <TableCell>
                                        Outsource
                                    </TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {MPPViewImplementasi.map((item, index) =>
                                    <TableRow
                                        hover
                                        key={`MPPViewImplementasi_${index}`}
                                    >
                                        <TableCell>
                                            {item.DepartemenNama}
                                        </TableCell>
                                        <TableCell>
                                            {item.LevelNama}
                                        </TableCell>
                                        <TableCell>
                                            {item.Sub}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 0 && (MPPViewImplementasi[index].StatusKaryawan === 'Permanen' || MPPViewImplementasi[index].StatusKaryawan === 'Permanent') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 0 && (MPPViewImplementasi[index].StatusKaryawan === 'Kontrak' || MPPViewImplementasi[index].StatusKaryawan === 'Contract') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        {/* <TableCell>
                                            {MPPViewImplementasi[index].Month === 0 && MPPViewImplementasi[index].StatusKaryawan === 'Intern' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell>
                                            {MPPViewImplementasi[index].Month === 0 && MPPViewImplementasi[index].StatusKaryawan === 'Outsource' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 1 && (MPPViewImplementasi[index].StatusKaryawan === 'Permanen' || MPPViewImplementasi[index].StatusKaryawan === 'Permanent') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 1 && (MPPViewImplementasi[index].StatusKaryawan === 'Kontrak' || MPPViewImplementasi[index].StatusKaryawan === 'Contract') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        {/* <TableCell>
                                            {MPPViewImplementasi[index].Month === 1 && MPPViewImplementasi[index].StatusKaryawan === 'Intern' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell>
                                            {MPPViewImplementasi[index].Month === 1 && MPPViewImplementasi[index].StatusKaryawan === 'Outsource' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 2 && (MPPViewImplementasi[index].StatusKaryawan === 'Permanen' || MPPViewImplementasi[index].StatusKaryawan === 'Permanent') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 2 && (MPPViewImplementasi[index].StatusKaryawan === 'Kontrak' || MPPViewImplementasi[index].StatusKaryawan === 'Contract') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        {/* <TableCell>
                                            {MPPViewImplementasi[index].Month === 2 && MPPViewImplementasi[index].StatusKaryawan === 'Intern' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell>
                                            {MPPViewImplementasi[index].Month === 2 && MPPViewImplementasi[index].StatusKaryawan === 'Outsource' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 3 && (MPPViewImplementasi[index].StatusKaryawan === 'Permanen' || MPPViewImplementasi[index].StatusKaryawan === 'Permanent') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 3 && (MPPViewImplementasi[index].StatusKaryawan === 'Kontrak' || MPPViewImplementasi[index].StatusKaryawan === 'Contract') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        {/* <TableCell>
                                            {MPPViewImplementasi[index].Month === 3 && MPPViewImplementasi[index].StatusKaryawan === 'Intern' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell>
                                            {MPPViewImplementasi[index].Month === 3 && MPPViewImplementasi[index].StatusKaryawan === 'Outsource' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 4 && (MPPViewImplementasi[index].StatusKaryawan === 'Permanen' || MPPViewImplementasi[index].StatusKaryawan === 'Permanent') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 4 && (MPPViewImplementasi[index].StatusKaryawan === 'Kontrak' || MPPViewImplementasi[index].StatusKaryawan === 'Contract') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        {/* <TableCell>
                                            {MPPViewImplementasi[index].Month === 4 && MPPViewImplementasi[index].StatusKaryawan === 'Intern' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell>
                                            {MPPViewImplementasi[index].Month === 4 && MPPViewImplementasi[index].StatusKaryawan === 'Outsource' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 5 && (MPPViewImplementasi[index].StatusKaryawan === 'Permanen' || MPPViewImplementasi[index].StatusKaryawan === 'Permanent') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 5 && (MPPViewImplementasi[index].StatusKaryawan === 'Kontrak' || MPPViewImplementasi[index].StatusKaryawan === 'Contract') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        {/* <TableCell>
                                            {MPPViewImplementasi[index].Month === 5 && MPPViewImplementasi[index].StatusKaryawan === 'Intern' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell>
                                            {MPPViewImplementasi[index].Month === 5 && MPPViewImplementasi[index].StatusKaryawan === 'Outsource' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 6 && (MPPViewImplementasi[index].StatusKaryawan === 'Permanen' || MPPViewImplementasi[index].StatusKaryawan === 'Permanent') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 6 && (MPPViewImplementasi[index].StatusKaryawan === 'Kontrak' || MPPViewImplementasi[index].StatusKaryawan === 'Contract') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        {/* <TableCell>
                                            {MPPViewImplementasi[index].Month === 6 && MPPViewImplementasi[index].StatusKaryawan === 'Intern' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell>
                                            {MPPViewImplementasi[index].Month === 6 && MPPViewImplementasi[index].StatusKaryawan === 'Outsource' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 7 && (MPPViewImplementasi[index].StatusKaryawan === 'Permanen' || MPPViewImplementasi[index].StatusKaryawan === 'Permanent') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 7 && (MPPViewImplementasi[index].StatusKaryawan === 'Kontrak' || MPPViewImplementasi[index].StatusKaryawan === 'Contract') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        {/* <TableCell>
                                            {MPPViewImplementasi[index].Month === 7 && MPPViewImplementasi[index].StatusKaryawan === 'Intern' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell>
                                            {MPPViewImplementasi[index].Month === 7 && MPPViewImplementasi[index].StatusKaryawan === 'Outsource' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 8 && (MPPViewImplementasi[index].StatusKaryawan === 'Permanen' || MPPViewImplementasi[index].StatusKaryawan === 'Permanent') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 8 && (MPPViewImplementasi[index].StatusKaryawan === 'Kontrak' || MPPViewImplementasi[index].StatusKaryawan === 'Contract') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        {/* <TableCell>
                                            {MPPViewImplementasi[index].Month === 8 && MPPViewImplementasi[index].StatusKaryawan === 'Intern' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell>
                                            {MPPViewImplementasi[index].Month === 8 && MPPViewImplementasi[index].StatusKaryawan === 'Outsource' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 9 && (MPPViewImplementasi[index].StatusKaryawan === 'Permanen' || MPPViewImplementasi[index].StatusKaryawan === 'Permanent') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 9 && (MPPViewImplementasi[index].StatusKaryawan === 'Kontrak' || MPPViewImplementasi[index].StatusKaryawan === 'Contract') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        {/* <TableCell>
                                            {MPPViewImplementasi[index].Month === 9 && MPPViewImplementasi[index].StatusKaryawan === 'Intern' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell>
                                            {MPPViewImplementasi[index].Month === 9 && MPPViewImplementasi[index].StatusKaryawan === 'Outsource' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 10 && (MPPViewImplementasi[index].StatusKaryawan === 'Permanen' || MPPViewImplementasi[index].StatusKaryawan === 'Permanent') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 10 && (MPPViewImplementasi[index].StatusKaryawan === 'Kontrak' || MPPViewImplementasi[index].StatusKaryawan === 'Contract') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        {/* <TableCell>
                                            {MPPViewImplementasi[index].Month === 10 && MPPViewImplementasi[index].StatusKaryawan === 'Intern' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell>
                                            {MPPViewImplementasi[index].Month === 10 && MPPViewImplementasi[index].StatusKaryawan === 'Outsource' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell> */}
                                        <TableCell
                                            align='center'
                                            style={st_BorderLeft}
                                        >
                                            {MPPViewImplementasi[index].Month === 11 && (MPPViewImplementasi[index].StatusKaryawan === 'Permanen' || MPPViewImplementasi[index].StatusKaryawan === 'Permanent') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            style={{ ...st_BorderLeft, ...st_BorderRight }}
                                        >
                                            {MPPViewImplementasi[index].Month === 11 && (MPPViewImplementasi[index].StatusKaryawan === 'Kontrak' || MPPViewImplementasi[index].StatusKaryawan === 'Contract') ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        {/* <TableCell>
                                            {MPPViewImplementasi[index].Month === 11 && MPPViewImplementasi[index].StatusKaryawan === 'Intern' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell>
                                        <TableCell>
                                            {MPPViewImplementasi[index].Month === 11 && MPPViewImplementasi[index].StatusKaryawan === 'Outsource' ? MPPViewImplementasi[index].Jumlah : ''}
                                        </TableCell> */}
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    User: state.Auth.User,
    MPPActionId: state.MPP.MPPActionId,
    isMPPViewImplementasiLoading: state.MPP.isMPPViewImplementasiLoading,
    MPPViewImplementasi: state.MPP.MPPViewImplementasi,
})

export default connect(mapStateToProps, { get_MPP_View_Implementasi })(ImplementasiMPP)
