import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_Job_List_Full, get_Job_Action_Id } from '../../../../../../Store/Actions/Job.Action'

import { Skeleton, ListItemButton, Grid, Divider, } from '@mui/material'

import WorkIcon from '@mui/icons-material/Work'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt'
import LocationOnIcon from '@mui/icons-material/LocationOn'

import GenericModals from '../../../../../Containers/GenericModals'
import CoTable from '../../../../../Containers/Table'

import ModalListRecom from '../../Modal/Job/Recommended/ListRecom'

import { isLevelQualified } from '../../../../../../Global'

class Recommended extends React.Component {

    componentDidMount() {
        this.props.get_Job_List_Full()
    }

    onClickActionId = (Id) => {
        this.props.get_Job_Action_Id(Id)
    }
    render() {
        const isJobFullListLoading = this.props.isJobFullListLoading
        const JobFullList = this.props.JobFullList
        const User = this.props.User
        const isAdmin = User.isAdmin
        const Department = User?.Department ? User.Department : []
        const Level = User?.Level ? User.Level : ''

        const OpJobFullList = isAdmin ?
            JobFullList
            : JobFullList.filter((item) => Department.find((newItem) =>
                newItem.DepartemenId === item.DepartemenId && isLevelQualified(Level, item?.LevelId ? item.LevelId : '')
            ))

        const DetailStyle = { border: '0px solid red', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '30%' }
        const DetailLogoStyle = { marginRight: '2%' }

        const MaxRowOnPage = 5

        const HeadData = [
            {
                Nama: '',
                width: '100%',
            },
        ]

        const BodyData = OpJobFullList.map((item, index) => {
            return {
                Detail: {
                    value: item,
                    display: (
                        <Fragment
                            key={index}
                        >
                            <ListItemButton>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <div
                                        style={{ border: '0px solid red', width: '80%', padding: '0 0 0 1%' }}
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="flex-start"
                                            alignItems="stretch"
                                            style={{ border: '0px solid blue', padding: '0 0 3% 0' }}
                                        >
                                            <b>
                                                No: {`${item.JobId}.${item.Id}`}
                                                <span
                                                    style={{ margin: '0 0 0 30px', color: item.Status === 'Active' ? 'green' : item.Status === 'On Hold' ? 'orange' : 'black' }}
                                                >
                                                    Status: {item.Status}
                                                </span>
                                            </b>
                                            <h2
                                                style={{ border: '0px solid red' }}
                                            >
                                                {item.JobListNama}
                                            </h2>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="flex-start"
                                                style={{ border: '0px solid red' }}
                                            >
                                                <div
                                                    style={DetailStyle}
                                                >
                                                    <WorkIcon
                                                        style={DetailLogoStyle}
                                                    />
                                                    {item.DepartemenNama}
                                                </div>
                                                <div
                                                    style={DetailStyle}
                                                >
                                                    <SignalCellularAltIcon
                                                        style={DetailLogoStyle}
                                                    />
                                                    {item.LevelNama}
                                                </div>
                                                <div
                                                    style={DetailStyle}
                                                >
                                                    <LocationOnIcon
                                                        style={DetailLogoStyle}
                                                    />
                                                    {item.LokasiNama}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div
                                        style={{ border: '0px solid red', width: '10%' }}
                                    >
                                        <center>
                                            <GenericModals
                                                size='xl'
                                                header='List Rekomendasi HR'
                                                body={<ModalListRecom />}
                                                Buttonvariant='contained'
                                                Buttoncolor='primary'
                                                ButtononClickeven={() => this.onClickActionId(item.JobId)}
                                                Buttonlabel={'List'}
                                                Buttonstyle={{ width: '100%' }}
                                            />
                                        </center>
                                    </div>
                                </Grid>
                            </ListItemButton>
                            <Divider />
                        </Fragment>
                    )
                }
            }
        })

        return (
            <Fragment>
                {isJobFullListLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5,].map((item) =>
                            <Skeleton
                                key={`ListPendaftar_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <h2
                            style={{ borderBottom: '1px solid black' }}
                        >
                            Recommended
                        </h2>

                        <div
                            style={{ margin: '0 0 5% 0' }}
                        >
                            <CoTable
                                TableId={'Job_Recommended'}
                                HeadData={HeadData}
                                Data={BodyData}
                                isHeadLoading={isJobFullListLoading}
                                isBodyLoading={isJobFullListLoading}
                                isPagination={BodyData.length > MaxRowOnPage ? true : false}
                                MaxRowOnPage={MaxRowOnPage}
                                isPaginationLoading={isJobFullListLoading}
                                PaginationCounter={
                                    BodyData.length / MaxRowOnPage > Math.floor(BodyData.length / MaxRowOnPage) ?
                                        Math.floor(BodyData.length / MaxRowOnPage) + 1 :
                                        BodyData.length / MaxRowOnPage
                                }
                                isHeading={true}
                                CustomFilter={[
                                    { Nama: 'Status', Keyword: '' },
                                    { Nama: 'JobListNama', Keyword: '' },
                                    { Nama: 'DepartemenNama', Keyword: '' },
                                    { Nama: 'LevelNama', Keyword: '' },
                                    { Nama: 'LokasiNama', Keyword: '' },
                                ]}
                            />
                        </div>

                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    User: state.Auth.User,
    isJobFullListLoading: state.Job.isJobFullListLoading,
    JobFullList: state.Job.JobFullList,
})

export default connect(mapStateToProps, { get_Job_List_Full, get_Job_Action_Id })(Recommended)