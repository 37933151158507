import React from 'react'

import { connect } from 'react-redux'

import { Forget_Password, Forget_Password_Activation } from '../../../Store/Actions/Auth.Action'

import { Redirect } from 'react-router-dom'

import { Grid, Paper, TextField, Button, InputAdornment } from '@mui/material'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import Logo from '../../../Img/Etana_logo.png'

class ForgetPassword extends React.Component {
    state = {
        Email: '',
        ActivationInput: '',
        NewPassword: '',
        ConfirmNewPassword: '',
        isShowNewPassword: false,
        isShowConfirmNewPassword: false,
        isGoToLogin: false,
        isActivating: false,
    }

    onShowNewPassword = () => {
        this.setState({ isShowNewPassword: !this.state.isShowNewPassword })
    }
    onShowConfirmNewPassword = () => {
        this.setState({ isShowConfirmNewPassword: !this.state.isShowConfirmNewPassword })
    }
    goToLogin = () => {
        this.setState({ isGoToLogin: !this.state.isGoToLogin })
    }
    goToActivating = () => {
        this.setState({ isActivating: !this.state.isGoToLogin })
    }
    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    formOnSubmit = (e) => {
        e.preventDefault()
        this.goToActivating()
        this.props.Forget_Password(this.state.Email)
    }
    formOnSubmitActivation = (e) => {
        e.preventDefault()
        const isPasswordOk = this.state.NewPassword.length >= 6 && /[a-z]/.test(this.state.NewPassword) && /[A-Z]/.test(this.state.NewPassword) ? true : false
        if (this.state.NewPassword === this.state.ConfirmNewPassword && isPasswordOk) {
            this.props.Forget_Password_Activation(this.state.Email, this.state.ActivationInput, this.state.NewPassword)
        }
    }
    render() {
        if (this.props.isAuth && this.props.Token) {
            return <Redirect to="/" />
        }

        const isMobileView = this.props.isMobileView

        const {
            Email,
            ActivationInput,
            NewPassword,
            ConfirmNewPassword,
            isShowNewPassword,
            isShowConfirmNewPassword,
            isGoToLogin,
            isActivating,
        } = this.state
        return (
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                {isGoToLogin ? <Redirect to="/login" /> : <div></div>}
                <Paper
                    style={{ width: isMobileView ? '70%' : '30%', margin: '2% 0 2% 0', border: '0px solid red', padding: '2vh 3vw 2vh 3vw', fontFamily: 'Montserrat, Segoe UI', fontSize: isMobileView ? '16px' : '1.2vw' }}
                >
                    <h1>
                        Forget Password
                    </h1>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <img
                            src={Logo}
                            style={{ height: '30%', width: '30%', padding: '1% 1% 1% 1%', margin: '2% 0 5% 0', border: '0px solid red' }}
                            alt="Logo"
                        />
                    </Grid>
                    {!isActivating ?
                        <form
                            onSubmit={this.formOnSubmit}
                        >
                            <div
                                style={{ margin: '0% 5% 0 5%' }}
                            >
                                <label>
                                    <b>Email</b>
                                </label><br />
                                <TextField
                                    type='text'
                                    name='Email'
                                    size='small'
                                    value={Email}
                                    onChange={this.onChangeField}
                                    placeholder='example@mail.com'
                                    style={{ width: '100%', margin: '1% 0 1% 0' }}
                                    InputProps={{
                                        style: { fontFamily: 'Montserrat, Segoe UI', fontSize: isMobileView ? '16px' : '1.2vw' }
                                    }}
                                /><br />
                            </div>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-start"
                            >
                                <label
                                    style={{ color: '#00a3df', cursor: 'pointer', marginTop: '2vh' }}
                                    onClick={this.goToActivating}
                                >
                                    I Have Activation Code
                                </label><br />
                            </Grid>
                            <center>
                                <Button
                                    variant='contained'
                                    size='large'
                                    style={{ border: '0px solid red', width: "85%", margin: '3vh 0 2vh 0', backgroundColor: '#00a3df', textTransform: 'none', fontFamily: 'Montserrat, Segoe UI', fontSize: isMobileView ? '16px' : '1.2vw' }}
                                    type='submit'
                                >
                                    Forget Password
                                </Button>
                            </center>
                        </form> :
                        <form
                            onSubmit={this.formOnSubmitActivation}
                        >
                            <div
                                style={{ margin: '0% 5% 0 5%' }}
                            >
                                <label>
                                    <b>Email</b>
                                </label><br />
                                <TextField
                                    type='text'
                                    name='Email'
                                    size='small'
                                    value={Email}
                                    onChange={this.onChangeField}
                                    placeholder='example@mail.com'
                                    style={{ width: '100%', margin: '1% 0 1% 0' }}
                                    InputProps={{
                                        style: { fontFamily: 'Montserrat, Segoe UI', fontSize: isMobileView ? '16px' : '1.2vw' }
                                    }}
                                /><br /> <br /> <br /> <br />
                                Please Check Your Email for Activation Code <br /> <br />
                                <label>
                                    <b>Activation Code</b>
                                </label><br />
                                <TextField
                                    type='text'
                                    name='ActivationInput'
                                    size='small'
                                    variant='standard'
                                    value={ActivationInput}
                                    onChange={this.onChangeField}
                                    placeholder='B1s$'
                                    style={{ width: '100%', margin: '1% 0 1% 0' }}
                                    InputProps={{
                                        style: { fontFamily: 'Montserrat, Segoe UI', fontSize: isMobileView ? '16px' : '1.2vw' }
                                    }}
                                /><br />
                                <label>
                                    <b>Password</b>
                                </label><br />
                                <TextField
                                    type={isShowNewPassword ? 'text' : 'password'}
                                    name='NewPassword'
                                    size='small'
                                    value={NewPassword}
                                    onChange={this.onChangeField}
                                    placeholder='******'
                                    style={{ width: '100%', margin: '1% 0 1% 0' }}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment
                                                position="end"
                                                style={{ color: '#00a3df', cursor: 'pointer', fontSize: isMobileView ? '16px' : '2vw'}}
                                                onClick={() => this.onShowNewPassword()}
                                            >
                                                {isShowNewPassword ?
                                                    <VisibilityIcon />
                                                    : <VisibilityOffIcon />
                                                }
                                            </InputAdornment>,
                                        style: { fontFamily: 'Montserrat, Segoe UI', fontSize: isMobileView ? '16px' : '1.2vw' }
                                    }}
                                /><br />

                                <div
                                    style={{ margin: '0 0 0 5%', display: NewPassword.length > 0 ? 'block' : 'none' }}
                                >
                                    <span style={{ color: NewPassword.length >= 6 ? 'green' : 'red' }}>
                                        {NewPassword.length >= 6 ? '✔ Password More Then 6 Character' : '✖ Password More Then 6 Character'}
                                    </span> <br />
                                    <span style={{ color: /[a-z]/.test(NewPassword) ? 'green' : 'red' }}>
                                        {/[a-z]/.test(NewPassword) ? '✔ Password Has 1 or More Lowercase Character' : '✖ Password Has 1 or More Lowercase Character'}
                                    </span> <br />
                                    <span style={{ color: /[A-Z]/.test(NewPassword) ? 'green' : 'red' }}>
                                        {/[A-Z]/.test(NewPassword) ? '✔ Password Has 1 or More Uppercase Character' : '✖ Password Has 1 or More Uppercase Character'}
                                    </span> <br />
                                </div>

                                <label>
                                    <b>Confirm Password</b>
                                </label><br />
                                <TextField
                                    type={isShowConfirmNewPassword ? 'text' : 'password'}
                                    name='ConfirmNewPassword'
                                    size='small'
                                    value={ConfirmNewPassword}
                                    onChange={this.onChangeField}
                                    placeholder='******'
                                    style={{ width: '100%', margin: '1% 0 1% 0' }}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment
                                                position="end"
                                                style={{ color: '#00a3df', cursor: 'pointer', fontSize: isMobileView ? '16px' : '2vw' }}
                                                onClick={() => this.onShowConfirmNewPassword()}
                                            >
                                                {isShowConfirmNewPassword ?
                                                    <VisibilityIcon />
                                                    : <VisibilityOffIcon />
                                                }
                                            </InputAdornment>,
                                        style: { fontFamily: 'Montserrat, Segoe UI', fontSize: isMobileView ? '16px' : '1.2vw' }
                                    }}
                                /><br />
                                <div
                                    style={{ margin: '0 0 0 5%', display: ConfirmNewPassword.length > 0 ? 'block' : 'none' }}
                                >
                                    <span style={{ color: ConfirmNewPassword === NewPassword ? 'green' : 'red' }}>
                                        {ConfirmNewPassword === NewPassword ? '✔ Password and Confirm Password must be the same' : '✖ Password and Confirm Password must be the same'}
                                    </span> <br />
                                </div>
                            </div>
                            <center>
                                <Button
                                    variant='contained'
                                    size='large'
                                    style={{ border: '0px solid red', width: "85%", margin: '3vh 0 2vh 0', backgroundColor: '#00a3df', textTransform: 'none', fontFamily: 'Montserrat, Segoe UI', fontSize: isMobileView ? '16px' : '1.2vw' }}
                                    type='submit'
                                >
                                    Set a New Password
                                </Button>
                            </center>
                        </form>}
                    <div
                        style={{ margin: '0 5% 0 5%', alignItems: 'center', textAlign: 'center', color: '#7e7e7e' }}
                    >
                        <p>
                            Back to <span
                                style={{ color: '#00a3df', cursor: 'pointer' }}
                                onClick={this.goToLogin}
                            >
                                Log in
                            </span>
                        </p>
                    </div>
                </Paper>
            </Grid >
        )
    }
}

const mapStateToProps = state => ({
})

export default connect(mapStateToProps, { Forget_Password, Forget_Password_Activation })(ForgetPassword)