import React, { Fragment } from "react"

import useMediaQuery from '@mui/material/useMediaQuery'
import { InitState } from '../../../../../Style'

import FullBiodataExtend from './BiodataExtend/FullBiodataExtend'
import HalfBiodataExtend from './BiodataExtend/HalfBiodataExtend'

const BiodataExtend = () => {
    const MinWindowFirst = useMediaQuery(`(min-width:${InitState.Units.minWidth_first}px)`)
    return (
        <Fragment>
            {MinWindowFirst ?
                <Fragment>
                    <FullBiodataExtend />
                </Fragment>
                : <Fragment>
                    <HalfBiodataExtend />
                </Fragment>}
        </Fragment>
    )
}

export default BiodataExtend