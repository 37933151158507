import React from 'react'

import Profile from '../../../../../Layouts/Account/ProfileExtend'

const DetailPosisi = ({ DisableEdit, isView, isDownload }) => {
    return (
        <div>
            <Profile
                DisableEdit={DisableEdit ? DisableEdit : true}
                isView={isView ? isView : true}
                isDownload={isDownload ? isDownload : false}
            />
        </div>
    )
}

export default DetailPosisi