import React from 'react'

import Profile from '../../../../../../Layouts/Account/Profile'

const DetailPosisi = () => {
    return (
        <div>
            <Profile DisableEdit={true} isView={true} />
        </div>
    )
}

export default DetailPosisi