import React, { Fragment } from "react"
import { useEffect } from "react"

import { useDispatch, connect } from "react-redux"
import { get_Report_PR_Realization } from '../../../../../Store/Actions/Report.Action'

import CoTable from "../../../../Containers/Table"

const PRRealization = ({ ...props }) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(get_Report_PR_Realization())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isReportPRRealizationLoading = props.isReportPRRealizationLoading
    const ReportPRRealization = props.ReportPRRealization

    const Data = ReportPRRealization

    const st_TableHead = { color: 'white', backgroundColor: '#00a3df' }
    const st_TableBorderHead = { borderTop: '1px solid black', borderRight: '0px solid black', borderBottom: '1px solid black', borderLeft: '1px solid black', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }
    const st_TableBorder = { borderLeft: '1px solid black', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }


    const HeadData = [
        {
            Nama: 'No',
            width: '5%',
            KeyName: 'index',
            SortingType: 'Number',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Departemen',
            width: '5%',
            KeyName: 'DepartemenNama',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Job Title',
            width: '5%',
            KeyName: 'Title',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Level',
            width: '5%',
            KeyName: 'LevelNama',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Contract Type',
            width: '5%',
            KeyName: 'ContractType',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Year',
            width: '5%',
            KeyName: 'TahunBuka',
            SortingType: 'Number',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Bulan Buka',
            width: '5%',
            KeyName: 'BulanBuka',
            SortingType: 'Number',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'PR Available',
            width: '5%',
            KeyName: 'PRAvailable',
            SortingType: 'Number',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'PR Pending',
            width: '25%',
            KeyName: 'PRPending',
            SortingType: 'Number',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'PR Used',
            width: '5%',
            KeyName: 'PRUsed',
            SortingType: 'Number',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'MPP Number',
            width: '5%',
            KeyName: 'MPPNumber',
            SortingType: 'Number',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'MPP List Number',
            width: '5%',
            KeyName: 'MPPListNumber',
            SortingType: 'Number',
            style: { ...st_TableHead, ...st_TableBorderHead },
            BodyStyle: { ...st_TableBorder }
        },
        {
            Nama: 'Job Desc Number',
            width: '5%',
            KeyName: 'JobDescNumber',
            SortingType: 'Number',
            style: { ...st_TableHead, ...st_TableBorderHead, ...{ borderRight: '1px solid black' } },
            BodyStyle: { ...st_TableBorder, ...{ borderRight: '1px solid black' } }
        },
    ]

    const BodyData = Data ? Data.map((item, index) => {
        return {
            index: {
                value: index + 1
            },
            DepartemenNama: {
                value: item?.DepartemenNama ? item.DepartemenNama : ' '
            },
            Title: {
                value: item?.Title ? item.Title : ' '
            },
            LevelNama: {
                value: item?.LevelNama ? item.LevelNama : ' '
            },
            ContractType: {
                value: item?.MPPListStatusKaryawan ? item.MPPListStatusKaryawan : ' '
            },
            TahunBuka: {
                value: item?.TahunBuka ? item.TahunBuka : ' '
            },
            BulanBuka: {
                display: item ?
                    item.BulanBuka === 0 ? "Jan"
                        : item.BulanBuka === 1 ? "Feb"
                            : item.BulanBuka === 2 ? "Mar"
                                : item.BulanBuka === 3 ? "Apr"
                                    : item.BulanBuka === 4 ? "May"
                                        : item.BulanBuka === 5 ? "Jun"
                                            : item.BulanBuka === 6 ? "Jul"
                                                : item.BulanBuka === 7 ? "Aug"
                                                    : item.BulanBuka === 8 ? "Sep"
                                                        : item.BulanBuka === 9 ? "Oct"
                                                            : item.BulanBuka === 10 ? "Nov"
                                                                : item.BulanBuka === 11 ? "Dec"
                                                                    : item.BulanBuka
                    : ' ',
                value: item?.BulanBuka ? item.BulanBuka : ' '
            },
            PRAvailable: {
                value: item?.JobdeskSubmissionNumber ? item?.PRAvailable ? `${((Number(item.PRAvailable) - item.PRUsed ? Number(item.PRUsed) : 0) - item.PRPending ? Number(item.PRPending) : 0)}` : ' ' : ' '
            },
            PRPending: {
                value: item?.JobdeskSubmissionNumber ? item?.PRPending ? item.PRPending : ' ' : ' '
            },
            PRUsed: {
                value: item?.JobdeskSubmissionNumber ? item?.PRUsed ? item.PRUsed : ' ' : ' '
            },
            MPPNumber: {
                value: item?.MPPSubmissionNumber ? item.MPPSubmissionNumber : ' '
            },
            MPPListNumber: {
                value: item?.MPPListSubmissionNumber ? item.MPPListSubmissionNumber : ' '
            },
            JobDescNumber: {
                value: item?.JobdeskSubmissionNumber ? item.JobdeskSubmissionNumber : ' '
            },
        }
    }) : []

    return (
        <Fragment>
            <CoTable
                TableId={'PRRealization'}
                HeadData={HeadData}
                Data={BodyData}
                isHeadLoading={isReportPRRealizationLoading}
                isBodyLoading={isReportPRRealizationLoading}
                isPagination={BodyData.length > 5 ? true : false}
                isPaginationLoading={isReportPRRealizationLoading}
                isHeading={true}
                isDownload={true}
                ComplexDataDownload={true}
            />
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    isReportPRRealizationLoading: state.Report.isReportPRRealizationLoading,
    ReportPRRealization: state.Report.ReportPRRealization,
})

export default connect(mapStateToProps)(PRRealization)
