import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_MPP_Data } from '../../../../../../Store/Actions/Setting.Action'

import Detail from '../../../../../Containers/Dashboard/Setting/MPP/Detail'

class SettingMPPView extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.MPPActionId !== prevProps.MPPActionId) {
            this.props.get_MPP_Data(this.props.MPPActionId)
        }
        // if (this.props.MPPActionCounter !== prevProps.MPPActionCounter) {
        //     this.props.get_MPP_Data(this.props.MPPActionId)
        // }
    }
    componentDidMount() {
        const { MPPActionId } = this.props
        if (MPPActionId !== null) {
            this.props.get_MPP_Data(MPPActionId)
        }
    }

    render() {
        const isMPPActionGetDetailLoading = this.props.isMPPActionGetDetailLoading
        const MPPDetail = this.props.MPPDetail

        return (
            <Fragment>
                <Detail Data={MPPDetail} isLoading={isMPPActionGetDetailLoading} />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isMPPActionGetDetailLoading: state.Setting.isMPPActionGetDetailLoading,
    MPPActionId: state.Setting.MPPActionId,
    MPPDetail: state.Setting.MPPDetail,
})

export default connect(mapStateToProps, { get_MPP_Data })(SettingMPPView)
