import React, { Fragment } from "react";

import { connect } from "react-redux";

import {
  get_List_Interview_HR,
  get_Job_Action_Id,
} from "../../../../Store/Actions/Job.Action";
import { get_Account_Action_Id } from "../../../../Store/Actions/Account.Action";
import { get_Jobdesk_Action_Id } from "../../../../Store/Actions/Jobdesk.Action";

import { Collapse } from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import GenericModals from "../../../Containers/GenericModals";
import CoTable from "../../../Containers/Table";
import DetailPenilianKandidat from "./Modal/Interview/DetailPenilianKandidat";
import ReviewPenilianKandidatHR from "./Modal/Interview/ReviewPenilianKandidatHR";
import EditPenilianKandidatHR from "./Modal/Interview/EditPenilianKandidatHR";

class InterviewHR extends React.Component {
  state = {
    MenungguOpen: true,
    SelesaiOpen: false,
  };

  componentDidMount() {
    this.props.get_List_Interview_HR(this.props.User.Email);
  }

  handleMenungguOpen = () => {
    this.setState({ MenungguOpen: !this.state.MenungguOpen });
  };

  handleSelesaiOpen = () => {
    this.setState({ SelesaiOpen: !this.state.SelesaiOpen });
  };

  onClickActionId = (JobId, AccountId, JobdeskId) => {
    this.props.get_Job_Action_Id(JobId);
    this.props.get_Account_Action_Id(AccountId);
    this.props.get_Jobdesk_Action_Id(JobdeskId);
  };

  render() {
    // const JobActionId = this.props.JobActionId
    const User = this.props.User;
    const isAdmin = User?.isAdmin ? User.isAdmin : false;
    const isListInterviewHRLoading = this.props.isListInterviewHRLoading;
    const ListInterviewHR = this.props.ListInterviewHR;

    const HeadData = [
      {
        Nama: "No",
        width: "5%",
      },
      {
        Nama: "No Registrasi",
        width: "10%",
      },
      {
        Nama: "Nama Kandidat",
        width: "10%",
      },
      {
        Nama: "Posisi Diajukan",
        width: "10%",
      },
      {
        Nama: "Detail Pengajuan",
        width: "10%",
      },
    ];

    const FilterdtInterviewHR = ListInterviewHR
      ? ListInterviewHR.filter((item, index) => item.HrIId === null)
      : [];

    const BodyData = FilterdtInterviewHR.map((item, index) => {
      return {
        index: index + 1,
        Nomor: item.Id,
        Nama: item.BiodataNama,
        Posisi: item.JobListNama,
        Detail: (
          <GenericModals
            size="xl"
            header="Detail Penilian Kandidat"
            body={<DetailPenilianKandidat isHR={true} />}
            Buttonvariant="contained"
            Buttoncolor="primary"
            ButtononClickeven={() =>
              this.onClickActionId(
                item.JobListId,
                item.AccountId,
                item.JobdeskId
              )
            }
            Buttonlabel={"Detail"}
          />
        ),
      };
    });

    const HeadData2 = [
      {
        Nama: "No",
        width: "5%",
      },
      {
        Nama: "No Registrasi",
        width: "10%",
      },
      {
        Nama: "Nama Kandidat",
        width: "10%",
      },
      {
        Nama: "Posisi Diajukan",
        width: "10%",
      },
      {
        Nama: "Status",
        width: "10%",
      },
      {
        Nama: "Detail Pengajuan",
        width: "10%",
      },
      {
        Nama: "Edit",
        width: "10%",
      },
    ];

    const FilterdtInterviewHR2 = ListInterviewHR
      ? ListInterviewHR.filter((item, index) => item.HrIId !== null)
      : [];

    const BodyData2 = FilterdtInterviewHR2.map((item, index) => {
      return {
        index: index + 1,
        Nomor: item.Id,
        Nama: item.BiodataNama,
        Posisi: item.JobListNama,
        Status: (
          <span
            style={{
              color: item?.SIStatus
                ? item.SIStatus === "Accept"
                  ? "green"
                  : item.SIStatus === "Reject"
                  ? "red"
                  : "orange"
                : "black",
            }}
          >
            {item.SIStatus}
          </span>
        ),
        Detail: (
          <GenericModals
            size="xl"
            header="Detail Penilian Kandidat"
            body={<ReviewPenilianKandidatHR isHR={true} />}
            Buttoncolor="primary"
            ButtononClickeven={() =>
              this.onClickActionId(
                item.JobListId,
                item.AccountId,
                item.JobdeskId
              )
            }
            Buttonlabel={"Detail"}
          />
        ),
        Edit: (
          <GenericModals
            size="xl"
            header="Edit"
            body={<EditPenilianKandidatHR />}
            Buttoncolor="secondary"
            Buttondisabled={isAdmin ? false : true}
            ButtononClickeven={() =>
              this.onClickActionId(
                item.JobListId,
                item.AccountId,
                item.JobdeskId
              )
            }
            Buttonlabel={"Edit"}
          />
        ),
      };
    });

    const { MenungguOpen, SelesaiOpen } = this.state;

    const RowDataInOnePage = 5;

    return (
      <Fragment>
        <h2>List Interview HR</h2>
        <h3
          onClick={this.handleMenungguOpen}
          style={{
            display: "flex",
            alignContent: "center",
            verticalAlign: "center",
            cursor: "pointer",
          }}
        >
          Menunggu Penilaian{MenungguOpen ? <ExpandLess /> : <ExpandMore />}
        </h3>
        <Collapse in={MenungguOpen} timeout="auto" unmountOnExit>
          <CoTable
            TableId={"InterviewHR_Menunggu"}
            HeadData={HeadData}
            Data={BodyData}
            isHeadLoading={isListInterviewHRLoading}
            isBodyLoading={isListInterviewHRLoading}
            isPagination={BodyData.length > RowDataInOnePage ? true : false}
            MaxRowOnPage={RowDataInOnePage}
            isPaginationLoading={isListInterviewHRLoading}
            PaginationCounter={
              BodyData.length / RowDataInOnePage >
              Math.floor(BodyData.length / RowDataInOnePage)
                ? Math.floor(BodyData.length / RowDataInOnePage) + 1
                : BodyData.length / RowDataInOnePage
            }
          />
        </Collapse>
        <div style={{ margin: "0 0 5% 0" }}>
          <h3
            onClick={this.handleSelesaiOpen}
            style={{
              display: "flex",
              alignContent: "center",
              verticalAlign: "center",
              cursor: "pointer",
            }}
          >
            Selesai diNilai{SelesaiOpen ? <ExpandLess /> : <ExpandMore />}
          </h3>
          <Collapse in={SelesaiOpen} timeout="auto" unmountOnExit>
            <CoTable
              TableId={"InterviewHR_Selesai"}
              HeadData={HeadData2}
              Data={BodyData2}
              isHeadLoading={isListInterviewHRLoading}
              isBodyLoading={isListInterviewHRLoading}
              isPagination={BodyData2.length > RowDataInOnePage ? true : false}
              MaxRowOnPage={RowDataInOnePage}
              isPaginationLoading={isListInterviewHRLoading}
              PaginationCounter={
                BodyData2.length / RowDataInOnePage >
                Math.floor(BodyData2.length / RowDataInOnePage)
                  ? Math.floor(BodyData2.length / RowDataInOnePage) + 1
                  : BodyData2.length / RowDataInOnePage
              }
            />
          </Collapse>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  User: state.Auth.User,
  isListInterviewHRLoading: state.Job.isListInterviewHRLoading,
  ListInterviewHR: state.Job.ListInterviewHR,
});

export default connect(mapStateToProps, {
  get_List_Interview_HR,
  get_Job_Action_Id,
  get_Account_Action_Id,
  get_Jobdesk_Action_Id,
})(InterviewHR);
