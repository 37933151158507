import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_List_PKWT_Detail } from '../../../../../../Store/Actions/Job.Action'

import { Skeleton } from '@mui/material'

class DetailPKWT extends React.Component {
    componentDidMount() {
        const { JobActionId, AccountActionId } = this.props
        this.props.get_List_PKWT_Detail(JobActionId, AccountActionId)
    }
    render() {
        const isListPKWTDetailLoading = this.props.isListPKWTDetailLoading
        const ListPKWTDetail = this.props.ListPKWTDetail

        return (
            <Fragment>
                {isListPKWTDetailLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5,].map((item) =>
                            <Skeleton
                                key={`DetailMenunggu_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <div
                        style={{ fontFamily: 'Montserrat, Segoe UI', }}
                    >
                        <h2>PKWT/PKWTT</h2>
                        <div
                            style={{
                                margin: '1% 0 1% 0', padding: '1%', border: `1px solid ${ListPKWTDetail?.PKStatus ? ListPKWTDetail.PKStatus === 'PKWT' ? 'Green' :
                                    'blue' : 'black'}`
                            }}
                        >
                            <center>
                                <h2
                                    style={{
                                        color: ListPKWTDetail?.PKStatus ? ListPKWTDetail.PKStatus === 'PKWT' ? 'Green' :
                                            'blue' : 'black'
                                    }}
                                >
                                    Status : {ListPKWTDetail?.PKStatus ? ListPKWTDetail.PKStatus : 'Status'}
                                </h2>
                            </center>
                            <h4>
                                Diupdate Pada:<br />
                                {ListPKWTDetail?.PKDate ? new Date(ListPKWTDetail.PKDate).toLocaleString(['ban', 'id']) : ''}<br /><br />
                                Oleh:<br />
                                {ListPKWTDetail?.BiodataNama ? ListPKWTDetail.BiodataNama : ''}<br /><br />
                                Join Date:<br />
                                {ListPKWTDetail?.PKJoinDate ? new Date(ListPKWTDetail.PKJoinDate).toLocaleDateString() : ''}<br /><br />
                                {ListPKWTDetail?.PKStatus === 'PKWT' ?
                                    <Fragment>
                                        End Date:<br />
                                        {ListPKWTDetail?.PKEndDate ? new Date(ListPKWTDetail.PKEndDate).toLocaleDateString() : ''}<br /><br />
                                    </Fragment> :
                                    <Fragment></Fragment>}
                                Sub:<br />
                                {this.props?.AdjSub ? this.props.AdjSub : ''}<br /><br />
                                Level:<br />
                                {this.props?.AdjLevelNama ? this.props.AdjLevelNama : ''}<br /><br />
                                Golongan:<br />
                                {this.props?.AdjGol ? this.props.AdjGol : ''}<br /><br />
                                Contract Duration:<br />
                                {this.props?.AdjCoDur ? this.props.AdjCoDur : 0} Month<br /><br />
                                Keterangan:<br />
                                {ListPKWTDetail?.PKKet ? ListPKWTDetail.PKKet : ''}<br />
                            </h4>
                        </div>
                    </div>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isListPKWTDetailLoading: state.Job.isListPKWTDetailLoading,
    ListPKWTDetail: state.Job.ListPKWTDetail,
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
})

export default connect(mapStateToProps, { get_List_PKWT_Detail })(DetailPKWT)