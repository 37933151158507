import React, { Fragment } from "react"

import { connect } from 'react-redux'

import { get_Job_Data_URL, is_Job_Applied, User_Apply_Job, is_Job_Saved, User_Save_Job } from '../../../../Store/Actions/Job.Action'
import { Create_Warning_Messages } from '../../../../Store/Actions/Messages.Actions'

import { Redirect, useParams } from 'react-router-dom'

import { Skeleton, Button, Grid, Paper } from '@mui/material'
import * as Style from '../../../../Style'

import GenericModals from '../../../Containers/GenericModals'
import DaftarKerja from '../DaftarKerja'

import FavoriteIcon from '@mui/icons-material/Favorite'

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />
}

class JobDetail extends React.Component {
    state = {
        URLId: '',
        isGoToLogin: false,
        // isGoToDaftarKerja: false
    }

    componentDidMount() {
        const UrlId = this.props.params.Id
        if (UrlId !== null) {
            this.props.get_Job_Data_URL(UrlId)
            const isAuth = this.props.isAuth
            const Token = this.props.Token
            if (isAuth && Token) {
                this.props.is_Job_Saved(UrlId)
                this.props.is_Job_Applied(UrlId)
            }
        }
    }

    goToLogin = () => {
        this.setState({ isGoToLogin: true })
    }

    // goToDaftarKerja = () => {
    //     const URLId = this.props.params.Id
    //     this.setState({ URLId: URLId })
    //     // this.setState({ isGoToDaftarKerja: true })
    // }
    DaftarKerja = () => {
        const UrlId = this.props.params.Id
        if (UrlId !== null) {
            this.props.User_Apply_Job(UrlId)
        } else {
            this.props.Create_Warning_Messages(null, 'Job tidak ditemukan')
        }
    }

    handleSaveButton = () => {
        const UrlId = this.props.params.Id
        if (UrlId !== null) {
            this.props.User_Save_Job(UrlId)
        } else {
            this.props.Create_Warning_Messages(null, 'Job tidak ditemukan')
        }
    }

    render() {
        const isAuth = this.props.isAuth
        // const Token = this.props.Token
        const User = this.props.User
        const isPendaftar = User?.isPendaftar ? User.isPendaftar : false
        // const isBiodataComplete = User ? User.isBiodataComplete : null
        const isJobActionDataLoading = this.props.isJobActionDataLoading
        const JobActionData = this.props.JobActionData
        const JobActionDeskripsi = this.props.JobActionDeskripsi
        const JobActionPersyaratan = this.props.JobActionPersyaratan
        const isJobSaveLoading = this.props.isJobSaveLoading
        const isJobSave = this.props.isJobSave
        const isJobApplyLoading = this.props.isJobApplyLoading
        const isJobApply = this.props.isJobApply

        // const isUnBiodata = isBiodataComplete === false && isPendaftar === true ? true : false

        const {
            isGoToLogin,
            // isGoToDaftarKerja
        } = this.state

        const URLId = this.props.params.Id

        return (
            <Fragment>
                {isJobActionDataLoading || isJobSaveLoading || isJobApplyLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((item) =>
                            <div
                                style={{ display: 'flex', border: '0px solid red', margin: '0', padding: '0', justifyContent: 'center' }}
                                key={`isJobActionDataLoading_${item}`}
                            >
                                <Skeleton
                                    height={item === 1 ? '20vh' : item === 2 || item === 6 || item === 10 ? '15vh' : '5vh'}
                                    width={'100%'}
                                    style={{ display: 'flex' }}
                                />
                            </div>
                        )}
                    </Fragment> :
                    <Fragment>
                        {/* {isUnBiodata ? <Redirect to="/profile" /> : <div></div>} */}
                        {isGoToLogin ? <Redirect to="/login" /> : <div></div>}
                        {/* {isGoToDaftarKerja ? <Redirect to={`/Jobdetail/${URLId}/daftar`} /> : <div></div>} */}
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ border: '0px solid red', margin: '0 15vw 0 15vw', width: '70vw' }}
                        >
                            <div
                                style={{ border: '0px solid red', width: '45%', padding: '1px', color: '#03045e', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                            >
                                <h1>{JobActionData?.Nama ? JobActionData.Nama : 'Judul'} </h1>
                            </div>
                            <div
                                style={{ border: '0px solid red', width: '10%', padding: '1px' }}
                            >
                                {isPendaftar ?
                                    <Button
                                        variant={isJobSave ? "contained" : "outlined"}
                                        color="primary"
                                        onClick={this.handleSaveButton}
                                        disabled={isAuth === true || isAuth === 'true' ? false : true}
                                        style={{ padding: '10%', borderRadius: '8px', width: '100%', fontSize: '1.2vw' }}
                                    >
                                        <FavoriteIcon
                                            style={{ margin: '0 5% 0 0' }}
                                        /> {isJobSave ? "Unsave" : "Save"}
                                    </Button> :
                                    <Fragment>
                                    </Fragment>}
                            </div>
                        </Grid>
                        <Paper
                            style={{ border: '0px solid red', boxShadow: 'none', backgroundColor: "rgb(249, 249, 249)", padding: '10px 15vw 10px 15vw', height: '8vh', display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, Segoe UI' }}
                        >
                            <div
                                style={{ padding: '0 1% 0 1%', borderRight: '1px solid black', fontSize: '1.2vw' }}
                            >
                                Department : {JobActionData?.DepartemenNama ? JobActionData.DepartemenNama : ''}
                            </div>
                            <div
                                style={{ padding: '0 1% 0 1%', borderRight: '1px solid black', fontSize: '1.2vw' }}
                            >
                                Level : {JobActionData?.LevelNama ? JobActionData.LevelNama : ''}
                            </div>
                            <div
                                style={{ padding: '0 1% 0 1%', fontSize: '1.2vw' }}
                            >
                                Location : {JobActionData?.LokasiNama ? JobActionData.LokasiNama : ''}
                            </div>
                        </Paper>
                        <p
                            style={{ textAlign: 'center', color: Style.Color.Text.secondary, margin: '0 15vw 0 15vw', fontSize: '1.2vw' }}
                        >
                            {JobActionData?.About ? JobActionData.About : ''}
                        </p>
                        <h2
                            style={{ margin: '5vh 15vw 0 15vw', color: '#03045e', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                        >
                            Job Description :
                        </h2>
                        <ul
                            style={{ margin: '0 15vw 0 15vw', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                        >
                            {JobActionDeskripsi.length === 0 ?
                                <Fragment>
                                </Fragment> :
                                JobActionDeskripsi.map((item, index) => (
                                    <li
                                        key={(`JobDetail_JobActionDeskripsi_${index}`)}
                                        style={{ color: Style.Color.Text.secondary }}
                                    >
                                        <p>
                                            {item.Deskripsi}
                                        </p>
                                    </li>
                                ))}
                        </ul>
                        <h2
                            style={{ margin: '5vh 15vw 0 15vw', color: '#03045e', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                        >
                            Requirements :
                        </h2>
                        <ul
                            style={{ margin: '0 15vw 0 15vw', fontFamily: 'Montserrat, Segoe UI', fontSize: '1.2vw' }}
                        >
                            {JobActionPersyaratan.length === 0 ?
                                <Fragment>
                                </Fragment> :
                                JobActionPersyaratan.map((item, index) => (
                                    <li
                                        key={(`JobDetail_JobActionPersyaratan ${index}`)}
                                        style={{ color: Style.Color.Text.secondary }}
                                    >
                                        <p>
                                            {item.Persyaratan}
                                        </p>
                                    </li>
                                ))}
                        </ul>
                        <center>
                            {isJobApply ?
                                <Button
                                    variant={"outlined"}
                                    color={'success'}
                                    style={{ padding: '1vh 1vw 1vh 1vw', margin: '2% 0 5% 0', width: '60%', borderRadius: '10px', textTransform: 'none', fontSize: '1.2vw' }}
                                    onClick={() => null}
                                >
                                    Application being reviewed
                                </Button>
                                : <GenericModals
                                    size='l'
                                    Buttonvariant='contained'
                                    header='Apply'
                                    body={<DaftarKerja URLId={URLId} />}
                                    Buttoncolor='primary'
                                    ButtononClickeven={() => null}
                                    Buttonlabel={'Apply'}
                                    Buttonstyle={{ padding: '1vh 1vw 1vh 1vw', margin: '2% 0 5% 0', width: '60%', borderRadius: '10px', textTransform: 'none', fontSize: '1.2vw' }}
                                />}
                        </center>
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isAuth: state.Auth.isAuth,
    Token: state.Auth.Token,
    User: state.Auth.User,
    JobActionId: state.Job.JobActionId,
    isJobActionDataLoading: state.Job.isJobActionDataLoading,
    JobActionData: state.Job.JobActionData,
    JobActionDeskripsi: state.Job.JobActionDeskripsi,
    JobActionPersyaratan: state.Job.JobActionPersyaratan,
    isJobSaveLoading: state.Job.isJobSaveLoading,
    isJobSave: state.Job.isJobSave,
    isJobApplyLoading: state.Job.isJobApplyLoading,
    isJobApply: state.Job.isJobApply,
})

export default connect(mapStateToProps, { Create_Warning_Messages, get_Job_Data_URL, is_Job_Applied, User_Apply_Job, is_Job_Saved, User_Save_Job })(withParams(JobDetail))