// import React, { Fragment } from 'react'

// import { TextField, Skeleton } from '@mui/material'

// const DetailMPP = ({ Data, MPPList, MPPListItem, isLoading }) => {
//     const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%', backgroundColor: '#E0E0E0' }
//     const st_divaddtextfield = { display: 'flex', alignItems: 'center', margin: '0 0 2% 2%' }
//     const st_TextSmall = { width: '20%', margin: '0 1% 0 0', backgroundColor: '#E0E0E0' }
//     return (
//         <Fragment>
//             {isLoading ?
//                 <Fragment>
//                     <Skeleton
//                         height={'10vh'}
//                         width={'100%'}
//                     />
//                 </Fragment> :
//                 <Fragment>
//                     <h3>
//                         Informasi MPP
//                     </h3>
//                     <div
//                         style={{
//                             margin: '1% 0 1% 0', padding: '1%', border: `1px solid ${Data?.Status ? Data.Status === 'Submitted' ? 'Orange' :
//                                 Data.Status === 'Accept' ? 'Green' : 'Red' : 'black'}`
//                         }}
//                     >
//                         <center>
//                             <h2
//                                 style={{
//                                     color: `${Data?.Status ? Data.Status === 'Submitted' ? 'Orange' :
//                                         Data.Status === 'Accept' ? 'Green' : 'Red' : 'black'}`
//                                 }}
//                             >
//                                 Status : {Data?.Status ? Data.Status : 'Status'}
//                             </h2>
//                         </center>
//                         {Data?.Status ? Data.Status !== 'Submitted' ?
//                             <h4>
//                                 Oleh:<br />
//                                 {Data?.AccesorNama ? Data.AccesorNama : ''}<br /><br />
//                                 {/* {Data?.AccBioNama ? Data.AccBioNama : ''}<br /><br /> */}
//                                 Pada Tanggal:<br />
//                                 {Data?.AccDate ? new Date(Data.AccDate).toLocaleString(['ban', 'id']) : ''}<br /><br />
//                                 Keterangan:<br />
//                                 {Data?.AccKet ? Data.AccKet : ''}<br />
//                             </h4> :
//                             <Fragment>
//                             </Fragment> :
//                             <Fragment>
//                             </Fragment>
//                         }
//                     </div>
//                     <h3>
//                         Informasi Pengajuan MPP
//                     </h3>
//                     <TextField
//                         style={st_textfield}
//                         variant='outlined'
//                         type='text'
//                         label='Nomer Pengajuan'
//                         name='Id'
//                         disabled
//                         value={Data?.Id ? Data.Id : 'Id'}
//                     />
//                     <TextField
//                         style={st_textfield}
//                         variant='outlined'
//                         type='text'
//                         label='Department'
//                         name='Department'
//                         disabled
//                         value={Data?.DepNama ? Data.DepNama : 'DepNama'}
//                     />
//                     <TextField
//                         style={st_textfield}
//                         variant='outlined'
//                         type='text'
//                         label='Level'
//                         name='Level'
//                         disabled
//                         value={Data?.LevNama ? Data.LevNama : 'LevNama'}
//                     />
//                     <TextField
//                         style={st_textfield}
//                         variant='outlined'
//                         type='text'
//                         label='Sub'
//                         name='Sub'
//                         disabled
//                         value={Data?.Sub ? Data.Sub : 'Sub'}
//                     />
//                     <TextField
//                         style={st_textfield}
//                         variant='outlined'
//                         type='text'
//                         label='Tahun'
//                         name='Tahun'
//                         disabled
//                         value={Data?.Tahun ? Data.Tahun : 'Tahun'}
//                     />
//                     <TextField
//                         style={st_textfield}
//                         variant='outlined'
//                         type='text'
//                         label='Jenis'
//                         name='Jenis'
//                         disabled
//                         value={Data?.Jenis ? Data.Jenis : 'Jenis'}
//                     />

//                     <h3>
//                         Jumlah MPP
//                     </h3>
//                     {MPPList ? MPPList.map((item, index) => (
//                         <div
//                             style={st_divaddtextfield}
//                             key={`MPPList_${index}`}
//                         >
//                             <TextField
//                                 style={st_TextSmall}
//                                 variant='outlined'
//                                 type='text'
//                                 label='Waktu Pembukaan'
//                                 name='WaktuPembukaan'
//                                 disabled
//                                 value={item.BulanBuka || item.BulanBuka === 0 ? new Date(2022, item.BulanBuka, 1).toLocaleString('default', { month: 'long' }) : ''}
//                             />
//                             <TextField
//                                 style={st_TextSmall}
//                                 variant='outlined'
//                                 type='text'
//                                 label='StatusKaryawan'
//                                 name='StatusKaryawan'
//                                 disabled
//                                 value={item.StatusKaryawan ? item.StatusKaryawan : ''}
//                             />
//                             <TextField
//                                 style={st_TextSmall}
//                                 variant='outlined'
//                                 type='number'
//                                 label='Jumlah'
//                                 name='Jumlah'
//                                 disabled
//                                 value={item.Jumlah ? item.Jumlah : ''}
//                             />
//                         </div>
//                     )) :
//                         <Fragment>
//                         </Fragment>}
//                     <h3>
//                         Jumlah Item
//                     </h3>
//                     {MPPListItem ? MPPListItem.map((item, index) => (
//                         <div
//                             style={st_divaddtextfield}
//                             key={`MPPListItem_${index}`}
//                         >
//                             <TextField
//                                 style={st_TextSmall}
//                                 variant='outlined'
//                                 type='text'
//                                 label='Waktu Pembukaan'
//                                 name='WaktuPembukaan'
//                                 disabled
//                                 value={item.BulanBuka || item.BulanBuka === 0 ? new Date(2022, item.BulanBuka, 1).toLocaleString('default', { month: 'long' }) : ''}
//                             />
//                             <TextField
//                                 style={st_TextSmall}
//                                 variant='outlined'
//                                 type='text'
//                                 label='Item'
//                                 name='Item'
//                                 disabled
//                                 value={item.ItemsNama ? item.ItemsNama : ''}
//                             />
//                             <TextField
//                                 style={st_TextSmall}
//                                 variant='outlined'
//                                 type='number'
//                                 label='Jumlah'
//                                 name='Jumlah'
//                                 disabled
//                                 value={item.Jumlah ? item.Jumlah : ''}
//                             />
//                         </div>
//                     )) :
//                         <Fragment>
//                         </Fragment>}
//                     <h3>
//                         Data Pendukung
//                     </h3>
//                     <TextField
//                         style={st_textfield}
//                         variant='outlined'
//                         type='text'
//                         label='Pembuat'
//                         name='Pembuat'
//                         disabled
//                         value={Data?.BioNama ? Data.BioNama : 'BioNama'}
//                     />
//                     <TextField
//                         style={st_textfield}
//                         variant='outlined'
//                         type='text'
//                         label='Tanggal Pembuatan'
//                         name='TanggalPembuatan'
//                         disabled
//                         value={Data?.TanggalPembuatan ? new Date(Data.TanggalPembuatan).toLocaleString(['ban', 'id']) : 'TanggalPembuatan'}
//                     />
//                     <TextField
//                         style={st_textfield}
//                         variant='outlined'
//                         type='text'
//                         label='Keterangan'
//                         name='Keterangan'
//                         disabled
//                         value={Data?.Keterangan ? Data.Keterangan : 'Keterangan'}
//                     />
//                 </Fragment>}
//         </Fragment >

//     )
// }
// export default DetailMPP

import React, { Fragment } from 'react'

import { TextField, Skeleton } from '@mui/material'

const DetailMPP = ({ Data, MPPList, isLoading }) => {
    const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%', backgroundColor: '#E0E0E0' }
    const st_divaddtextfield = { display: 'flex', alignItems: 'center', margin: '0 0 2% 2%' }
    const st_TextSmall = { width: '20%', margin: '0 1% 0 0', backgroundColor: '#E0E0E0' }
    console.log(Data);
    return (
        <Fragment>
            {isLoading ?
                <Fragment>
                    <Skeleton
                        height={'10vh'}
                        width={'100%'}
                    />
                </Fragment> :
                <Fragment>
                    <div
                        style={{
                            margin: '1% 0 1% 0', padding: '1%', border: `1px solid ${Data?.Status ? Data.Status === 'Submitted' || Data.Status === 'Submitted' ? 'Orange' :
                                Data.Status === 'Accept' ? 'Green' : 'Red' : 'black'}`
                        }}
                    >
                        <center>
                            <h2
                                style={{
                                    color: `${Data?.Status ? Data.Status === 'Submitted' || Data.Status === 'Submitted' ? 'Orange' :
                                        Data.Status === 'Accept' ? 'Green' : 'Red' : 'black'}`
                                }}
                            >
                                Status : {Data?.Status ? Data.Status : 'Status'}
                            </h2>
                        </center>
                        {Data?.Status ? Data.Status !== 'Submitted' && Data.Status !== 'Submitted' ?
                            <h4>
                                By:<br />
                                {Data?.AccesorNama ? Data.AccesorNama : ''}<br /><br />
                                {/* {Data?.AccBioNama ? Data.AccBioNama : ''}<br /><br /> */}
                                Date:<br />
                                {Data?.AccDate ? new Date(Data.AccDate).toLocaleString(['ban', 'id']) : ''}<br /><br />
                                Notes:<br />
                                {Data?.AccKet ? Data.AccKet : ''}<br />
                            </h4> :
                            <Fragment>
                            </Fragment> :
                            <Fragment>
                            </Fragment>
                        }
                    </div>
                    <h3>
                        MPP Submission
                    </h3>
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        label='MPP Number'
                        name='Id'
                        disabled
                        value={Data?.Id ? Data.Id : 'Id'}
                    />
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        label='Department'
                        name='Department'
                        disabled
                        value={Data?.DepNama ? Data.DepNama : 'DepNama'}
                    />
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        label='Level'
                        name='Level'
                        disabled
                        value={Data?.LevNama ? Data.LevNama : 'LevNama'}
                    />
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        label='Job Title'
                        name='Sub'
                        disabled
                        value={Data?.Sub ? Data.Sub : 'Sub'}
                    />
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        label='Year'
                        name='Tahun'
                        disabled
                        value={Data?.Tahun ? Data.Tahun : 'Tahun'}
                    />
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        label='Type'
                        name='Jenis'
                        disabled
                        value={Data?.Jenis ? Data.Jenis : 'Jenis'}
                    />
                    {Data?.Jenis === 'Additional' &&
                        <TextField
                            style={st_textfield}
                            variant='outlined'
                            type='text'
                            label='Reason'
                            name='Reason'
                            disabled
                            value={Data?.Reason ? Data.Reason : 'Reason'}
                        />
                    }

                    <h3>
                        MPP Details
                    </h3>
                    {MPPList ? MPPList.map((item, index) => (
                        <Fragment
                            key={`MPPList_${index}`}
                        >
                            <div
                                style={st_divaddtextfield}
                            >
                                <span
                                    style={{ marginRight: '1%' }}
                                >
                                    {`MPP ${index + 1}`}
                                </span>
                                <TextField
                                    style={st_TextSmall}
                                    variant='outlined'
                                    type='text'
                                    label='Month'
                                    name='WaktuPembukaan'
                                    disabled
                                    value={item.BulanBuka || item.BulanBuka === 0 ? new Date(2022, item.BulanBuka, 1).toLocaleString('default', { month: 'long' }) : ''}
                                />
                                <TextField
                                    style={st_TextSmall}
                                    variant='outlined'
                                    type='text'
                                    label='Employee Status'
                                    name='StatusKaryawan'
                                    disabled
                                    value={item.StatusKaryawan ? item.StatusKaryawan : ''}
                                />
                                <TextField
                                    style={st_TextSmall}
                                    variant='outlined'
                                    type='number'
                                    label='Quantity'
                                    name='Jumlah'
                                    disabled
                                    value={item.Jumlah ? item.Jumlah : ''}
                                />
                            </div>
                            {MPPList[index].Items ? MPPList[index].Items.map((item_item, index_index) =>
                                <div
                                    style={{ ...st_divaddtextfield, marginLeft: '10%' }}
                                    key={`MPPList_Item_${index}_${index_index}`}
                                >
                                    <span
                                        style={{ marginRight: '1%' }}
                                    >
                                        {`MPP ${index + 1} Item ${index_index + 1}`}
                                    </span>
                                    <TextField
                                        style={st_TextSmall}
                                        variant='outlined'
                                        type='text'
                                        label='Month'
                                        name='WaktuPembukaan'
                                        disabled
                                        value={item_item.BulanBuka || item_item.BulanBuka === 0 ? new Date(2022, item_item.BulanBuka, 1).toLocaleString('default', { month: 'long' }) : ''}
                                    />
                                    <TextField
                                        style={st_TextSmall}
                                        variant='outlined'
                                        type='text'
                                        label='Item'
                                        name='Item'
                                        disabled
                                        value={item_item.ItemsNama ? item_item.ItemsNama : ''}
                                    />
                                    <TextField
                                        style={st_TextSmall}
                                        variant='outlined'
                                        type='number'
                                        label='Quantity'
                                        name='Jumlah'
                                        disabled
                                        value={item_item.Jumlah ? item_item.Jumlah : ''}
                                    />
                                </div>
                            ) :
                                <Fragment>
                                </Fragment>}
                        </Fragment>
                    )) :
                        <Fragment>
                        </Fragment>}
                    {/* {MPPListItem ? MPPListItem.map((item, index) => (
                        <div
                            style={st_divaddtextfield}
                            key={`MPPListItem_${index}`}
                        >
                            <TextField
                                style={st_TextSmall}
                                variant='outlined'
                                type='text'
                                label='Waktu Pembukaan'
                                name='WaktuPembukaan'
                                disabled
                                value={item.BulanBuka || item.BulanBuka === 0 ? new Date(2022, item.BulanBuka, 1).toLocaleString('default', { month: 'long' }) : ''}
                            />
                            <TextField
                                style={st_TextSmall}
                                variant='outlined'
                                type='text'
                                label='Item'
                                name='Item'
                                disabled
                                value={item.ItemsNama ? item.ItemsNama : ''}
                            />
                            <TextField
                                style={st_TextSmall}
                                variant='outlined'
                                type='number'
                                label='Jumlah'
                                name='Jumlah'
                                disabled
                                value={item.Jumlah ? item.Jumlah : ''}
                            />
                        </div>
                    )) :
                        <Fragment>
                        </Fragment>} */}
                    <h3>
                        Additional Notes
                    </h3>
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        label='Owner'
                        name='Pembuat'
                        disabled
                        value={Data?.BioNama ? Data.BioNama : 'BioNama'}
                    />
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        label='Submission Date'
                        name='TanggalPembuatan'
                        disabled
                        value={Data?.TanggalPembuatan ? new Date(Data.TanggalPembuatan).toLocaleString(['ban', 'id']) : ''}
                    />
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        label='Note'
                        name='Keterangan'
                        disabled
                        value={Data?.Keterangan ? Data.Keterangan : 'Keterangan'}
                    />
                </Fragment>}
        </Fragment >

    )
}
export default DetailMPP