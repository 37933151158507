import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Load_Division_List, get_Division_Action_Id } from '../../../../Store/Actions/Setting.Action'

import CoTable from '../../../Containers/Table'
import GenericModals from '../../../Containers/GenericModals'

import Add from './Modal/Division/Add'
import Edit from './Modal/Division/Edit'
import View from './Modal/Division/View'
import Delete from './Modal/Division/Delete'

class SettingDivision extends React.Component {
    componentDidMount() {
        this.props.Load_Division_List()
    }
    onClickActionId(Id) {
        this.props.get_Division_Action_Id(Id)
    }
    render() {
        const DivisionList = this.props.DivisionList
        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Id',
                width: '15%',
            },
            {
                Nama: 'Nama',
                width: '30%',
            },
            {
                Nama: 'Deskripsi',
                width: '30%',
            },
            {
                Nama: 'View',
                width: '10%',
            },
            {
                Nama: 'Edit',
                width: '10%',
            },
            {
                Nama: 'Delete',
                width: '10%',
            },
        ]
        const BodyData = DivisionList ? DivisionList.map((item, index) => {
            return {
                index: index + 1,
                Id: item.Id,
                Name: item.Nama,
                Deskripsi: item.Deskripsi,
                View: (
                    <GenericModals
                        size='xl'
                        header='View Division'
                        body={<View />}
                        Buttoncolor='primary'
                        ButtononClickeven={() => this.onClickActionId(item.Id)}
                        Buttonlabel={'view'}
                    />
                ),
                Edit: (
                    <GenericModals
                        size='xl'
                        header='Edit Division'
                        body={<Edit />}
                        Buttoncolor='warning'
                        ButtononClickeven={() => this.onClickActionId(item.Id)}
                        Buttonlabel={'edit'}
                    />
                ),
                Delete: (
                    <GenericModals
                        size='m'
                        header='Delete Form'
                        body={<Delete />}
                        Buttoncolor='error'
                        ButtononClickeven={() => this.onClickActionId(item.Id)}
                        Buttonlabel={'Delete'}
                    />
                )
            }
        }) : []
        const isDivisionLoading = this.props.isDivisionLoading
        return (
            <Fragment>
                <h2>
                    Division Setting
                </h2>

                <center>
                    <GenericModals
                        size='m'
                        header='Tambah Division'
                        body={<Add />}
                        Buttoncolor='primary'
                        Buttonstyle={{ width: '90%' }}
                        ButtononClickeven={() => null}
                        Buttonlabel={'+ Division'}
                    />
                </center>

                <CoTable
                    TableId='DivisionSetting'
                    HeadData={HeadData}
                    isHeadLoading={HeadData ? false : true}
                    Data={BodyData}
                    isBodyLoading={isDivisionLoading}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isDivisionLoading: state.Setting.isDivisionLoading,
    DivisionList: state.Setting.DivisionList,
})

export default connect(mapStateToProps, { Load_Division_List, get_Division_Action_Id })(SettingDivision)
