import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_List_Interview_HR_Detail } from '../../../../../../../Store/Actions/Job.Action'

import { Skeleton, Checkbox, TextField } from '@mui/material'

import CoTable from '../../../../../../Containers/Table'

class PenilaianInterviewHR extends React.Component {
    componentDidMount() {
        const { JobActionId, AccountActionId } = this.props
        this.props.get_List_Interview_HR_Detail(JobActionId, AccountActionId)
    }

    render() {

        const isListInterviewHRDetailLoading = this.props.isListInterviewHRDetailLoading
        const ListInterviewHRDetail = this.props.ListInterviewHRDetail
        const Detail = ListInterviewHRDetail?.Data ? ListInterviewHRDetail.Data : null
        const List = ListInterviewHRDetail?.List ? ListInterviewHRDetail.List : []
        const ListPerilaku = ListInterviewHRDetail?.ListPerilaku ? ListInterviewHRDetail.ListPerilaku : []
        // const ListTeknik = ListInterviewHRDetail?.ListTeknik ? ListInterviewHRDetail.ListTeknik : []

        const HeadData = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Pertanyaan',
                width: '35%',
            },
            {
                Nama: '1',
                width: '5%',
            },
            {
                Nama: '2',
                width: '5%',
            },
            {
                Nama: '3',
                width: '5%',
            },
            {
                Nama: '4',
                width: '5%',
            },
            {
                Nama: '5',
                width: '5%',
            },
            {
                Nama: 'Keterangan',
                width: '20%',
            },
        ]

        const BodyData = List.map((item, index) => {
            return {
                index: index + 1,
                Pertanyaan: item.Nama,
                Point1: (
                    <Checkbox
                        checked={List[index].Point === 1 || List[index].Point === '1' ? true : false}
                        disabled
                    />
                ),
                Point2: (
                    <Checkbox
                        checked={List[index].Point === 2 || List[index].Point === '2' ? true : false}
                        disabled
                    />
                ),
                Point3: (
                    <Checkbox
                        checked={List[index].Point === 3 || List[index].Point === '3' ? true : false}
                        disabled
                    />
                ),
                Point4: (
                    <Checkbox
                        checked={List[index].Point === 4 || List[index].Point === '4' ? true : false}
                        disabled
                    />
                ),
                Point5: (
                    <Checkbox
                        checked={List[index].Point === 5 || List[index].Point === '5' ? true : false}
                        disabled
                    />
                ),
                Keterangan: item.Keterangan
            }
        })

        const HeadData2 = [
            {
                Nama: 'No',
                width: '5%',
            },
            {
                Nama: 'Pertanyaan',
                width: '35%',
            },
            {
                Nama: 'Patokan Point 1',
                width: '5%',
            },
            {
                Nama: 'Patokan Point 2',
                width: '5%',
            },
            {
                Nama: 'Patokan Point 3',
                width: '5%',
            },
            {
                Nama: ' ',
                width: '10%',
            },
            {
                Nama: '1',
                width: '5%',
            },
            {
                Nama: '2',
                width: '5%',
            },
            {
                Nama: '3',
                width: '5%',
            },
            {
                Nama: 'Keterangan',
                width: '20%',
            },
        ]

        const BodyData2 = ListPerilaku.map((item, index) => {
            return {
                index: index + 1,
                Pertanyaan: item.Nama,
                PatokanPoint1: (
                    <Checkbox
                        checked={ListPerilaku[index].PatokanPoint === 1 ? true : false}
                        disabled
                    />
                ),
                PatokanPoint2: (
                    <Checkbox
                        checked={ListPerilaku[index].PatokanPoint === 2 ? true : false}
                        disabled
                    />
                ),
                PatokanPoint3: (
                    <Checkbox
                        checked={ListPerilaku[index].PatokanPoint === 3 ? true : false}
                        disabled
                    />
                ),
                Space: ' ',
                Point1: (
                    <Checkbox
                        checked={ListPerilaku[index].Point === 1 || ListPerilaku[index].Point === '1' ? true : false}
                        disabled
                    />
                ),
                Point2: (
                    <Checkbox
                        checked={ListPerilaku[index].Point === 2 || ListPerilaku[index].Point === '2' ? true : false}
                        disabled
                    />
                ),
                Point3: (
                    <Checkbox
                        checked={ListPerilaku[index].Point === 3 || ListPerilaku[index].Point === '3' ? true : false}
                        disabled
                    />
                ),
                Keterangan: (
                    <TextField
                        style={{ backgroundColor: '#E0E0E0' }}
                        variant='outlined'
                        disabled
                        type='text'
                        label={`Keterangan ${index + 1}`}
                        name='InterviewDate'
                        value={ListPerilaku[index].Keterangan}
                    />
                ),
            }
        })

        // const HeadData3 = [
        //     {
        //         Nama: 'No',
        //         width: '5%',
        //     },
        //     {
        //         Nama: 'Pertanyaan',
        //         width: '35%',
        //     },
        //     {
        //         Nama: 'Patokan Point 1',
        //         width: '5%',
        //     },
        //     {
        //         Nama: 'Patokan Point 2',
        //         width: '5%',
        //     },
        //     {
        //         Nama: 'Patokan Point 3',
        //         width: '5%',
        //     },
        //     {
        //         Nama: ' ',
        //         width: '10%',
        //     },
        //     {
        //         Nama: '1',
        //         width: '5%',
        //     },
        //     {
        //         Nama: '2',
        //         width: '5%',
        //     },
        //     {
        //         Nama: '3',
        //         width: '5%',
        //     },
        //     {
        //         Nama: 'Keterangan',
        //         width: '20%',
        //     },
        // ]

        // const BodyData3 = ListTeknik.map((item, index) => {
        //     return {
        //         index: index + 1,
        //         Pertanyaan: item.Nama,
        //         PatokanPoint1: (
        //             <Checkbox
        //                 checked={ListTeknik[index].PatokanPoint === 1 ? true : false}
        //                 disabled
        //             />
        //         ),
        //         PatokanPoint2: (
        //             <Checkbox
        //                 checked={ListTeknik[index].PatokanPoint === 2 ? true : false}
        //                 disabled
        //             />
        //         ),
        //         PatokanPoint3: (
        //             <Checkbox
        //                 checked={ListTeknik[index].PatokanPoint === 3 ? true : false}
        //                 disabled
        //             />
        //         ),
        //         Space: ' ',
        //         Point1: (
        //             <Checkbox
        //                 checked={ListTeknik[index].Point === 1 || ListTeknik[index].Point === '1' ? true : false}
        //                 disabled
        //             />
        //         ),
        //         Point2: (
        //             <Checkbox
        //                 checked={ListTeknik[index].Point === 2 || ListTeknik[index].Point === '2' ? true : false}
        //                 disabled
        //             />
        //         ),
        //         Point3: (
        //             <Checkbox
        //                 checked={ListTeknik[index].Point === 3 || ListTeknik[index].Point === '3' ? true : false}
        //                 disabled
        //             />
        //         ),
        //         Keterangan: (
        //             <TextField
        //                 style={{ backgroundColor: '#E0E0E0' }}
        //                 variant='outlined'
        //                 disabled
        //                 type='text'
        //                 label={`Keterangan ${index + 1}`}
        //                 name='InterviewDate'
        //                 value={ListTeknik[index].Keterangan}
        //             />
        //         ),
        //     }
        // })

        const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%', backgroundColor: '#E0E0E0' }

        return (
            <Fragment>
                {isListInterviewHRDetailLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5,].map((item) =>
                            <Skeleton
                                key={`PenilaianInterviewHR_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <h2>
                            Tanggal & Waktu Interview
                        </h2>
                        <h3
                            style={{ marginLeft: '2%' }}
                        >
                            {Detail?.InterviewDate ? new Date(Detail.InterviewDate).toLocaleString(['ban', 'id']) : ''}
                        </h3>
                        <h2>
                            Form Penilaian
                        </h2>
                        <CoTable
                            TableId={'PenilaianInterviewHR_Penilaian'}
                            HeadData={HeadData}
                            Data={BodyData}
                            isHeadLoading={isListInterviewHRDetailLoading}
                            isBodyLoading={isListInterviewHRDetailLoading}
                        />
                        <h2>
                            Job Profile Assessment
                        </h2>
                        <h3>
                            Perilaku
                        </h3>
                        <CoTable
                            TableId={'PenilaianInterviewUser_Penilaian_Perilaku'}
                            HeadData={HeadData2}
                            Data={BodyData2}
                            isHeadLoading={isListInterviewHRDetailLoading}
                            isBodyLoading={isListInterviewHRDetailLoading}
                        />
                        {/* <h3>
                            Teknik
                        </h3>
                        <CoTable
                            TableId={'PenilaianInterviewUser_Penilaian_Teknik'}
                            HeadData={HeadData3}
                            Data={BodyData3}
                            isHeadLoading={isListInterviewHRDetailLoading}
                            isBodyLoading={isListInterviewHRDetailLoading}
                        /> */}
                        <h2>
                            Keputusan Hasil Interview
                        </h2>
                        <TextField
                            style={st_textfield}
                            variant='outlined'
                            disabled
                            type='texts'
                            label='Keputusan Hasil Interview'
                            name='KeputusanHasil'
                            value={Detail?.HrIStatus ? Detail.HrIStatus : ''}
                        />
                        <TextField
                            style={st_textfield}
                            variant='outlined'
                            type='texts'
                            label='Keterangan'
                            name='Keterangan'
                            disabled
                            value={Detail?.Keterangan ? Detail.Keterangan : ''}
                        />
                        <TextField
                            style={st_textfield}
                            variant='outlined'
                            type='texts'
                            label='Asesor'
                            name='Asesor'
                            disabled
                            value={Detail?.AsesorNama ? Detail.AsesorNama : ''}
                        />
                        <TextField
                            style={st_textfield}
                            variant='outlined'
                            type='texts'
                            label='Waktu Input'
                            name='WaktuInput'
                            disabled
                            value={Detail?.Time ? new Date(Detail.Time).toLocaleString(['ban', 'id']) : ''}
                        />
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isListInterviewHRDetailLoading: state.Job.isListInterviewHRDetailLoading,
    ListInterviewHRDetail: state.Job.ListInterviewHRDetail,
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
})

export default connect(mapStateToProps, { get_List_Interview_HR_Detail })(PenilaianInterviewHR)