import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { Add_Account } from '../../../../../../Store/Actions/Setting.Action'
import { Create_Warning_Messages } from '../../../../../../Store/Actions/Messages.Actions'

import { TextField, Switch, Button, CircularProgress } from '@mui/material'

class SettingCreateUser extends React.Component {
    state = {
        Email: '',
        UserName: '',
        Password: '',
        isPelamar: false,
        isKaryawan: true,
        isAdmin: false,
        // isActivated: true,
        // isBiodataCompleate: true,
    }

    onChangeField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onChangeSwitch = (e) => {
        if (e.target.name === 'isPelamar') {
            this.setState({
                isKaryawan: false,
                isAdmin: false
            })
        } else if (e.target.name === 'isKaryawan' && this.state.isKaryawan === true) {
            this.setState({
                isPelamar: false,
                isAdmin: false
            })
        } else if (e.target.name === 'isKaryawan') {
            this.setState({ isPelamar: false })
        }
        this.setState({ [e.target.name]: !this.state[e.target.name] })
    }

    formOnSubmit = (e) => {
        e.preventDefault()
        if (this.state.isPelamar || this.state.isKaryawan || this.state.isAdmin) {
            this.props.Add_Account(
                this.state.Email,
                this.state.UserName,
                this.state.Password,
                this.state.isPelamar,
                this.state.isKaryawan,
                this.state.isAdmin,
            )
        } else {
            this.props.Create_Warning_Messages(null, 'Role Harus diinput')
        }
    }

    render() {
        const {
            Email,
            UserName,
            Password,
            isPelamar,
            isKaryawan,
            isAdmin,
            // isActivated,
            // isBiodataCompleate,
        } = this.state
        const isAddUserLoading = this.props.isAddUserLoading

        const st_textfield = { marginTop: '10px', marginBottom: '10px', width: '100%' }
        return (
            <Fragment>
                <form
                    onSubmit={this.formOnSubmit}
                >
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='email'
                        label='Email'
                        name='Email'
                        required
                        value={Email}
                        onChange={this.onChangeField}
                    />
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='text'
                        label='User Name'
                        name='UserName'
                        required
                        value={UserName}
                        onChange={this.onChangeField}
                    />
                    <TextField
                        style={st_textfield}
                        variant='outlined'
                        type='password'
                        label='Password'
                        name='Password'
                        required
                        value={Password}
                        onChange={this.onChangeField}
                    />
                    <label>
                        <b>
                            Akun Pelamar?
                        </b>
                    </label><br />
                    <label>
                        Tidak
                    </label>
                    <Switch
                        style={{ color: isPelamar ? 'green' : 'red' }}
                        name='isPelamar'
                        checked={isPelamar}
                        onClick={this.onChangeSwitch}
                    />
                    <label>
                        Ya
                    </label><br /><br />
                    <label>
                        <b>
                            Akun Karyawan?
                        </b>
                    </label><br />
                    <label>
                        Tidak
                    </label>
                    <Switch
                        style={{ color: isKaryawan ? 'green' : 'red' }}
                        name='isKaryawan'
                        checked={isKaryawan}
                        onClick={this.onChangeSwitch}
                    />
                    <label>
                        Ya
                    </label><br /><br />
                    <label>
                        <b>
                            Akun Admin?
                        </b>
                    </label><br />
                    <label>
                        Tidak
                    </label>
                    <Switch
                        style={{ color: isAdmin ? 'green' : 'red' }}
                        name='isAdmin'
                        disabled={isKaryawan ? false : true}
                        checked={isAdmin}
                        onClick={this.onChangeSwitch}
                    />
                    <label>
                        Ya
                    </label><br /><br />
                    {/* <label>
                        <b>
                            Akun ter-aktifasi?
                        </b>
                    </label><br />
                    <label>
                        Tidak
                    </label>
                    <Switch
                        style={{ color: isActivated ? 'green' : 'red' }}
                        name='isActivated'
                        checked={isActivated}
                        onClick={this.onChangeSwitch}
                    />
                    <label>
                        Ya
                    </label><br /><br /> */}
                    {/* <label>
                        <b>
                            Akun ter-konfirmasi Biodata?
                        </b>
                    </label><br />
                    <label>
                        Tidak
                    </label>
                    <Switch
                        style={{ color: isBiodataCompleate ? 'green' : 'red' }}
                        name='isBiodataCompleate'
                        checked={isBiodataCompleate}
                        onClick={this.onChangeSwitch}
                    />
                    <label>
                        Ya
                    </label> */}
                    <center>
                        <Button
                            variant='contained'
                            style={{ width: '90%', margin: '5% 0 5% 0' }}
                            type='submit'
                            disabled={isAddUserLoading ? true : false}
                        >
                            {isAddUserLoading ? <CircularProgress /> : 'Tambah User'}
                        </Button>
                    </center>
                </form>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isAddUserLoading: state.Setting.isAddUserLoading,
})

export default connect(mapStateToProps, { Create_Warning_Messages, Add_Account })(SettingCreateUser)