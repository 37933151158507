import React from 'react'

import ProfileExtend from '../../../../Account/ProfileExtend'

const DetailPosisi = () => {
    return (
        <div>
            <ProfileExtend DisableEdit={true} isView={true} />
        </div>
    )
}

export default DetailPosisi