import {
    ACCOUNT_ACTION_GET_ID,
    //* BIODATA
    ACCOUNT_BIODATA_LOADING,
    ACCOUNT_BIODATA_LOADED,
    ACCOUNT_BIODATA_LOADING_DONE,
    //* ACCOUNT
    ACCOUNT_ACCOUNT_LOADING,
    ACCOUNT_ACCOUNT_LOADED,
    ACCOUNT_ACCOUNT_LOADING_DONE,
    //* PENDIDIKAN
    ACCOUNT_PENDIDIKAN_FORMAL_LOADING,
    ACCOUNT_PENDIDIKAN_FORMAL_LOADED,
    ACCOUNT_PENDIDIKAN_FORMAL_LOADING_DONE,
    ACCOUNT_PENDIDIKAN_UNFORMAL_LOADING,
    ACCOUNT_PENDIDIKAN_UNFORMAL_LOADED,
    ACCOUNT_PENDIDIKAN_UNFORMAL_LOADING_DONE,
    //* KETERAMPILAN
    ACCOUNT_KETERAMPILAN_BAHASA_LOADING,
    ACCOUNT_KETERAMPILAN_BAHASA_LOADED,
    ACCOUNT_KETERAMPILAN_BAHASA_LOADING_DONE,
    ACCOUNT_KETERAMPILAN_KOMPUTER_LOADING,
    ACCOUNT_KETERAMPILAN_KOMPUTER_LOADED,
    ACCOUNT_KETERAMPILAN_KOMPUTER_LOADING_DONE,
    ACCOUNT_KETERAMPILAN_RELEVANT_LOADING,
    ACCOUNT_KETERAMPILAN_RELEVANT_LOADED,
    ACCOUNT_KETERAMPILAN_RELEVANT_LOADING_DONE,
    //* KELUARGA
    ACCOUNT_KELUARGA_LOADING,
    ACCOUNT_KELUARGA_LOADED,
    ACCOUNT_KELUARGA_LOADING_DONE,
    //* KERJA
    ACCOUNT_KERJA_RIWAYAT_LOADING,
    ACCOUNT_KERJA_RIWAYAT_LOADED,
    ACCOUNT_KERJA_RIWAYAT_LOADING_DONE,
    ACCOUNT_KERJA_REFERENSI_LOADING,
    ACCOUNT_KERJA_REFERENSI_LOADED,
    ACCOUNT_KERJA_REFERENSI_LOADING_DONE,
    ACCOUNT_KERJA_KERJA_LOADING,
    ACCOUNT_KERJA_KERJA_LOADED,
    ACCOUNT_KERJA_KERJA_LOADING_DONE,
    //* PERTANYAAN
    ACCOUNT_PERTANYAAN_LOADING,
    ACCOUNT_PERTANYAAN_LOADED,
    ACCOUNT_PERTANYAAN_LOADING_DONE,
    //* RIWAYAT
    ACCOUNT_RIWAYAT_LOADING,
    ACCOUNT_RIWAYAT_LOADED,
    ACCOUNT_RIWAYAT_LOADING_DONE,
    //* CREATEACCOUNT
    ACCOUNT_CREATEACCOUNT_LOADING,
    ACCOUNT_CREATEACCOUNT_LOADED,
    ACCOUNT_CREATEACCOUNT_ERROR_EMAIL_USED,
    ACCOUNT_CREATEACCOUNT_ERROR_USERNAME_USED,
} from '../Actions/Type.Action'

const initialState = {
    AccountActionId: null,
    //* BIODATA
    isBiodataLoading: false,
    Biodata: null,
    //* ACCOUNT
    isAccountLoading: false,
    Account: null,
    //* PENDIDIKAN
    isPendidikanFormalLoading: false,
    PendidikanFormal: [],
    isPendidikanUnFormalLoading: false,
    PendidikanUnFormal: [],
    //* KETERAMPILAN
    isKeterampilanBahasaLoading: false,
    KeterampilanBahasa: [],
    isKeterampilanKomputerLoading: false,
    KeterampilanKomputer: [],
    isKeterampilanRelevantLoading: false,
    KeterampilanRelevant: [],
    //* KELUARGA
    isKeluargaLoading: false,
    Keluarga: null,
    //* KERJA
    isKerjaRiwayatLoading: false,
    KerjaRiwayat: null,
    isKerjaReferensiLoading: false,
    KerjaReferensi: null,
    isKerjaKerjaLoading: false,
    KerjaKerja: null,
    //* PERTANYAAN
    isPertanyaanLoading: false,
    Pertanyaan: [],
    //* RIWAYAT
    isRiwayatLoading: false,
    Riwayat: [],
    //* CREATEACCOUNT
    isCreateAccountLoading: false,
    isCreateAccountEmailError: false,
    isCreateAccountUsernameError: false,
}

// eslint-disable-next-line
export default function (state = initialState, action) {
    switch (action.type) {
        case ACCOUNT_ACTION_GET_ID:
            return {
                ...state,
                AccountActionId: action.payload
            }
        //* BIODATA
        case ACCOUNT_BIODATA_LOADING:
            return {
                ...state,
                isBiodataLoading: true
            }
        case ACCOUNT_BIODATA_LOADED:
            return {
                ...state,
                Biodata: action.payload
            }
        case ACCOUNT_BIODATA_LOADING_DONE:
            return {
                ...state,
                isBiodataLoading: false
            }
        //* ACCOUNT
        case ACCOUNT_ACCOUNT_LOADING:
            return {
                ...state,
                isAccountLoading: true
            }
        case ACCOUNT_ACCOUNT_LOADED:
            return {
                ...state,
                Account: action.payload
            }
        case ACCOUNT_ACCOUNT_LOADING_DONE:
            return {
                ...state,
                isAccountLoading: false
            }
        //* PENDIDIKAN
        case ACCOUNT_PENDIDIKAN_FORMAL_LOADING:
            return {
                ...state,
                isPendidikanFormalLoading: true,
            }
        case ACCOUNT_PENDIDIKAN_FORMAL_LOADED:
            return {
                ...state,
                PendidikanFormal: action.payload
            }
        case ACCOUNT_PENDIDIKAN_FORMAL_LOADING_DONE:
            return {
                ...state,
                isPendidikanFormalLoading: false,
            }
        case ACCOUNT_PENDIDIKAN_UNFORMAL_LOADING:
            return {
                ...state,
                isPendidikanUnFormalLoading: true,
            }
        case ACCOUNT_PENDIDIKAN_UNFORMAL_LOADED:
            return {
                ...state,
                PendidikanUnFormal: action.payload
            }
        case ACCOUNT_PENDIDIKAN_UNFORMAL_LOADING_DONE:
            return {
                ...state,
                isPendidikanUnFormalLoading: false,
            }
        //* KETERAMPILAN
        case ACCOUNT_KETERAMPILAN_BAHASA_LOADING:
            return {
                ...state,
                isKeterampilanBahasaLoading: true
            }
        case ACCOUNT_KETERAMPILAN_BAHASA_LOADED:
            return {
                ...state,
                KeterampilanBahasa: action.payload
            }
        case ACCOUNT_KETERAMPILAN_BAHASA_LOADING_DONE:
            return {
                ...state,
                isKeterampilanBahasaLoading: false
            }
        case ACCOUNT_KETERAMPILAN_KOMPUTER_LOADING:
            return {
                ...state,
                isKeterampilanKomputerLoading: true
            }
        case ACCOUNT_KETERAMPILAN_KOMPUTER_LOADED:
            return {
                ...state,
                KeterampilanKomputer: action.payload
            }
        case ACCOUNT_KETERAMPILAN_KOMPUTER_LOADING_DONE:
            return {
                ...state,
                isKeterampilanKomputerLoading: false
            }
        case ACCOUNT_KETERAMPILAN_RELEVANT_LOADING:
            return {
                ...state,
                isKeterampilanRelevantLoading: true
            }
        case ACCOUNT_KETERAMPILAN_RELEVANT_LOADED:
            return {
                ...state,
                KeterampilanRelevant: action.payload
            }
        case ACCOUNT_KETERAMPILAN_RELEVANT_LOADING_DONE:
            return {
                ...state,
                isKeterampilanRelevantLoading: false
            }
        //* KELUARGA
        case ACCOUNT_KELUARGA_LOADING:
            return {
                ...state,
                isKeluargaLoading: true,
            }
        case ACCOUNT_KELUARGA_LOADED:
            return {
                ...state,
                Keluarga: action.payload
            }
        case ACCOUNT_KELUARGA_LOADING_DONE:
            return {
                ...state,
                isKeluargaLoading: false,
            }
        //* KERJA
        case ACCOUNT_KERJA_RIWAYAT_LOADING:
            return {
                ...state,
                isKerjaRiwayatLoading: true,
            }
        case ACCOUNT_KERJA_RIWAYAT_LOADED:
            return {
                ...state,
                KerjaRiwayat: action.payload,
            }
        case ACCOUNT_KERJA_RIWAYAT_LOADING_DONE:
            return {
                ...state,
                isKerjaRiwayatLoading: false,
            }
        case ACCOUNT_KERJA_REFERENSI_LOADING:
            return {
                ...state,
                isKerjaReferensiLoading: true,
            }
        case ACCOUNT_KERJA_REFERENSI_LOADED:
            return {
                ...state,
                KerjaReferensi: action.payload,
            }
        case ACCOUNT_KERJA_REFERENSI_LOADING_DONE:
            return {
                ...state,
                isKerjaReferensiLoading: false,
            }
        case ACCOUNT_KERJA_KERJA_LOADING:
            return {
                ...state,
                isKerjaKerjaLoading: true,
            }
        case ACCOUNT_KERJA_KERJA_LOADED:
            return {
                ...state,
                KerjaKerja: action.payload,
            }
        case ACCOUNT_KERJA_KERJA_LOADING_DONE:
            return {
                ...state,
                isKerjaKerjaLoading: false,
            }
        //* PERTANYAAN
        case ACCOUNT_PERTANYAAN_LOADING:
            return {
                ...state,
                isPertanyaanLoading: true,
            }
        case ACCOUNT_PERTANYAAN_LOADED:
            return {
                ...state,
                Pertanyaan: action.payload,
            }
        case ACCOUNT_PERTANYAAN_LOADING_DONE:
            return {
                ...state,
                isPertanyaanLoading: false,
            }
        //* RIWAYAT
        case ACCOUNT_RIWAYAT_LOADING:
            return {
                ...state,
                isRiwayatLoading: true,
            }
        case ACCOUNT_RIWAYAT_LOADED:
            return {
                ...state,
                Riwayat: action.payload,
            }
        case ACCOUNT_RIWAYAT_LOADING_DONE:
            return {
                ...state,
                isRiwayatLoading: false,
            }
        //* CREATEACCOUNT
        case ACCOUNT_CREATEACCOUNT_LOADING:
            return {
                ...state,
                isCreateAccountLoading: true
            }
        case ACCOUNT_CREATEACCOUNT_LOADED:
            return {
                ...state,
                isCreateAccountLoading: false
            }
        case ACCOUNT_CREATEACCOUNT_ERROR_EMAIL_USED:
            return {
                ...state,
                isCreateAccountEmailError: action.payload
            }
        case ACCOUNT_CREATEACCOUNT_ERROR_USERNAME_USED:
            return {
                ...state,
                isCreateAccountUsernameError: action.payload
            }
        default:
            return state
    }
}