import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_PR_Data } from '../../../../../Store/Actions/PR.Action'

import CoDetailPR from '../../../../Containers/Dashboard/PR/DetailPR'
import PRDetailDownload from '../../../../Containers/Dashboard/PR/PRDetailDownload'

class DetailPR extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.PRActionId !== prevProps.PRActionId) {
            this.props.get_PR_Data(this.props.PRActionId)
        }
    }
    componentDidMount() {
        const { PRActionId } = this.props
        if (PRActionId !== null) {
            this.props.get_PR_Data(PRActionId)
        }
    }

    render() {
        const PRData = this.props.PRData
        const PRListItem = this.props.PRListItem
        const Skill = this.props.PRSkill
        const isPRActionGetDataLoading = this.props.isPRActionGetDataLoading

        const isDownload = false
        return (
            <Fragment>
                <CoDetailPR Data={PRData} PRListItem={PRListItem} Skill={Skill} isLoading={isPRActionGetDataLoading} />
                {isDownload ?
                    <Fragment>
                        <PRDetailDownload Data={PRData} PRListItem={PRListItem} isLoading={isPRActionGetDataLoading} />
                    </Fragment>
                    : <Fragment></Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    PRActionId: state.PR.PRActionId,
    isPRActionGetDataLoading: state.PR.isPRActionGetDataLoading,
    PRData: state.PR.PRData,
    PRListItem: state.PR.PRListItem,
    PRSkill: state.PR.PRSkill,
})

export default connect(mapStateToProps, { get_PR_Data })(DetailPR)