import React from 'react'

import DetailPengajuan from '../../../../../PR/Modal/DetailPR'

const Original = () => {
    return (
        <div>
            <DetailPengajuan />
        </div>
    )
}

export default Original