import React, { Fragment } from 'react'

import { connect } from 'react-redux'

import { get_List_GreetDay_Detail } from '../../../../../../Store/Actions/Job.Action'

import { Skeleton } from '@mui/material'

class DetailGreetDay extends React.Component {
    componentDidMount() {
        const { JobActionId, AccountActionId } = this.props
        this.props.get_List_GreetDay_Detail(JobActionId, AccountActionId)
    }
    render() {
        const isListGreetDayDetailLoading = this.props.isListGreetDayDetailLoading
        const ListGreetDayDetail = this.props.ListGreetDayDetail
        return (
            <Fragment>
                {isListGreetDayDetailLoading ?
                    <Fragment>
                        {[1, 2, 3, 4, 5,].map((item) =>
                            <Skeleton
                                key={`DetailMenunggu_Skeleton_${item}`}
                                height={'5vh'}
                            />
                        )}
                    </Fragment> :
                    <Fragment>
                        <h2>GreatDay</h2>
                        <div
                            style={{
                                margin: '1% 0 1% 0', padding: '1%', border: `1px solid ${ListGreetDayDetail?.GDStatus ? ListGreetDayDetail.GDStatus === 'Selesai' ? 'Green' :
                                    'orange' : 'black'}`
                            }}
                        >
                            <center>
                                <h2
                                    style={{
                                        color: ListGreetDayDetail?.GDStatus ? ListGreetDayDetail.GDStatus === 'Selesai' ? 'Green' :
                                            'orange' : 'black'
                                    }}
                                >
                                    Status : {ListGreetDayDetail?.GDStatus ? ListGreetDayDetail.GDStatus : 'Status'}
                                </h2>
                            </center>
                            <h4>
                                Diupdate Pada:<br />
                                {ListGreetDayDetail?.GDDate ? new Date(ListGreetDayDetail.GDDate).toLocaleString(['ban', 'id']) : ''}<br /><br />
                                Oleh:<br />
                                {ListGreetDayDetail?.BiodataNama ? ListGreetDayDetail.BiodataNama : ''}<br /><br />
                                Keterangan:<br />
                                {ListGreetDayDetail?.GDKet ? ListGreetDayDetail.GDKet : ''}<br />
                            </h4>
                        </div>
                    </Fragment>}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isListGreetDayDetailLoading: state.Job.isListGreetDayDetailLoading,
    ListGreetDayDetail: state.Job.ListGreetDayDetail,
    JobActionId: state.Job.JobActionId,
    AccountActionId: state.Account.AccountActionId,
})

export default connect(mapStateToProps, { get_List_GreetDay_Detail })(DetailGreetDay)