import axios from 'axios'

import {
    AUTH_RELOADE_PAGE,
    ////// PR
    PR_USER_PR_LOADING,
    PR_USER_PR_LOADED,
    PR_USER_PR_LOADING_DONE,
    PR_ADD_LOADING,
    PR_ADD_LOADING_DONE,
    PR_ACTION_GET_ID,
    PR_ACTION_GET_DATA_LOADING,
    PR_ACTION_GET_DATA_LOADED,
    PR_ACTION_GET_DATA_LOADING_DONE,
    PR_GET_UNCONFIRM_LIST_LOADING,
    PR_GET_UNCONFIRM_LIST_LOADED,
    PR_GET_UNCONFIRM_LIST_LOADING_DONE,
    PR_GET_CONFIRM_LIST_LOADING,
    PR_GET_CONFIRM_LIST_LOADED,
    PR_GET_CONFIRM_LIST_LOADING_DONE,
    PR_KONFIRM_LOADING,
    PR_KONFIRM_LOADING_DONE,
} from './Type.Action'

import {
    Create_Error_Messages,
    Create_Success_Messages
} from './Messages.Actions'

import { Get_IpAddres, tokenConfig, ErrorHandler } from './Base.Action'

import { Error_Messages_Standardization } from '../../Global'

export const get_PR_User_Pengajuan = () => async (dispatch, getState) => {
    try {
        dispatch({ type: PR_USER_PR_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/pr/user`, tokenConfig(getState))
        if (Data) {
            dispatch({
                type: PR_USER_PR_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: PR_USER_PR_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: PR_USER_PR_LOADING_DONE })
        ErrorHandler(err, 'get_PR_User_Pengajuan')
    }
}

export const Add_PR = (
    MPPId,
    MPPListId,
    NamaPosisi,
    LevelId,
    CostCenter,
    UserLaporId,
    TipePegawai,
    ContractDuration,
    JumlahBawahan,
    PosisiDibutuhkan,
    LokasiKerja,
    isBaruOrMenggantikan,
    ReplacementName,
    UraianPekerjaan,
    UsiaMin,
    UsiaMax,
    JenisKelamin,
    Pendidikan,
    Jurusan,
    Bahasa,
    PengalamanTahun,
    Catatan,
    AccountId,
    LevelIdPemohon,
    DepartmenIdPemohon,
    ItemData,
    Skill,
    Reason,
    Reason2,
    ExpectedMonth,
    ExpectedWeek
) => async (dispatch, getState) => {
    try {
        dispatch({ type: PR_ADD_LOADING })

        const IpAddres = Get_IpAddres()

        const body = JSON.stringify({
            MPPId,
            MPPListId,
            NamaPosisi,
            LevelId,
            CostCenter,
            UserLaporId,
            TipePegawai,
            ContractDuration,
            JumlahBawahan,
            PosisiDibutuhkan,
            LokasiKerja,
            isBaruOrMenggantikan,
            ReplacementName,
            UraianPekerjaan,
            UsiaMin,
            UsiaMax,
            JenisKelamin,
            Pendidikan,
            Jurusan,
            Bahasa,
            PengalamanTahun,
            Catatan,
            AccountId,
            LevelIdPemohon,
            DepartmenIdPemohon,
            ItemData,
            Skill,
            Reason,
            Reason2,
            ExpectedMonth,
            ExpectedWeek
        })
        const Data = await axios.post(`${IpAddres}/pr/add`, body, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({ type: PR_ADD_LOADING_DONE })
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `PR Berhasil dibuat`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: PR_ADD_LOADING_DONE })
        ErrorHandler(err, 'Add_PR')
    }
}

export const get_PR_Action_Id = (Id) => (dispatch) => {
    dispatch({
        type: PR_ACTION_GET_ID,
        payload: Id
    })
}

export const get_PR_Data = (Id) => async (dispatch, getState) => {
    try {
        dispatch({ type: PR_ACTION_GET_DATA_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/pr/detail/${Id}`, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({
                type: PR_ACTION_GET_DATA_LOADED,
                payload: Data.data
            })
            dispatch({ type: PR_ACTION_GET_DATA_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'get_PR_Data')
        dispatch({ type: PR_ACTION_GET_DATA_LOADING_DONE })
    }
}

export const get_PR_UnConfirm = () => async (dispatch, getState) => {
    try {
        dispatch({ type: PR_GET_UNCONFIRM_LIST_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/pr/unconfirm`, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({
                type: PR_GET_UNCONFIRM_LIST_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: PR_GET_UNCONFIRM_LIST_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: PR_GET_UNCONFIRM_LIST_LOADING_DONE })
        ErrorHandler(err, 'get_PR_UnConfirm')
    }
}

export const get_PR_Confirm = () => async (dispatch, getState) => {
    try {
        dispatch({ type: PR_GET_CONFIRM_LIST_LOADING })
        const IpAddres = Get_IpAddres()

        const Data = await axios.get(`${IpAddres}/pr/confirm`, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({
                type: PR_GET_CONFIRM_LIST_LOADED,
                payload: Data.data.Data
            })
            dispatch({ type: PR_GET_CONFIRM_LIST_LOADING_DONE })
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak ditemukan')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: PR_GET_CONFIRM_LIST_LOADING_DONE })
        ErrorHandler(err, 'get_PR_Confirm')
    }
}

export const Konfirm_PR = (PRId, Action, Keterangan, PRData, PRListItem) => async (dispatch, getState) => {
    try {
        dispatch({ type: PR_KONFIRM_LOADING })
        if (!PRId || !Action) {
            const ErrorResponse = Error_Messages_Standardization(400, 'Form Tidak Lengkap')
            dispatch(Create_Error_Messages(400, 'Form Tidak Lengkap'))
            throw ErrorResponse
        }
        const isMenunggu = Action === 'Submitted' ? true : false

        const IpAddres = Get_IpAddres()
        const body = JSON.stringify({ PRId, Action, Keterangan, PRData, PRListItem })
        const Data = isMenunggu ? { status: 200 } : await axios.post(`${IpAddres}/pr/konfirm`, body, tokenConfig(getState))

        if (Data.status === 200) {
            dispatch({ type: PR_KONFIRM_LOADING_DONE })
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `PR Berhasil dikonfirm`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        dispatch({ type: PR_KONFIRM_LOADING_DONE })
        ErrorHandler(err, 'Konfirm_PR')
    }
}


export const Update_PR = (
    Id,
    ContractDuration,
    LokasiNama,
    ReplacementName,
    JenisKelamin,
    Bahasa,
    PengalamanTahun,
    Catatan,
) => async (dispatch, getState) => {
    try {

        const IpAddres = Get_IpAddres()

        const body = JSON.stringify({
            Id,
            ContractDuration,
            LokasiNama,
            ReplacementName,
            JenisKelamin,
            Bahasa,
            PengalamanTahun,
            Catatan,
        })
        const Data = await axios.post(`${IpAddres}/pr/update`, body, tokenConfig(getState))
        if (Data.status === 200) {
            dispatch({ type: AUTH_RELOADE_PAGE })
            dispatch(Create_Success_Messages(200, `PR Berhasil dibuat`))
        } else {
            const ErrorResponse = Error_Messages_Standardization(400, 'Data tidak lengkap')
            throw ErrorResponse
        }
    } catch (err) {
        ErrorHandler(err, 'Update_PR')
    }
}